import React, { Component } from 'react';
import {Router, Route, BrowserRouter, Switch, Link} from 'react-router-dom'
import { Divider, Header, Icon,Dimmer,Loader,Table,Modal,Button,Form,Input,Image,Label,Rating,Segment,Grid} from 'semantic-ui-react';
import './LoadPage.css';

class BuscarRuta extends Component{
  render(){
    return(
      <div id="Contenedor">
      <div className="layer d-flex ai-c jc-c bg-gradient">
        <div className="logo">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
              <g fill="none" fillRule="evenodd">
                <path
                  fill="#FD6D6D"
                  d="M243.554 281.528L92.712 431.89c-3.808 3.795-3.585 10.135.625 13.483C136.322 479.535 190.747 500 250.002 500c59.252 0 113.678-20.465 156.665-54.63 4.213-3.347 4.438-9.687.628-13.482l-150.846-150.36c-3.56-3.55-9.332-3.55-12.895 0"/>

                <path
                  className="ray-1"
                  fill="#FD7854"
                  d="M133.989 328.142c-10.767-15.68-18.415-33.628-22.06-53.013-.823-4.373-4.49-7.623-8.953-7.623H9.201c-5.392 0-9.747 4.67-9.145 10.013 5.415 48.027 24.428 91.928 53.128 127.825 3.36 4.205 9.735 4.425 13.547.625l66.328-66.118c3.16-3.15 3.453-8.034.93-11.71"/>

                <path
                  className="ray-2"
                  fill="#FE8D26"
                  d="M133.048 158.529L66.725 92.426c-3.812-3.8-10.187-3.578-13.545.625C24.483 128.943 5.478 172.844.058 220.866c-.605 5.342 3.752 10.012 9.145 10.012h93.777c4.463 0 8.133-3.25 8.953-7.622 3.645-19.385 11.28-37.335 22.045-53.018 2.522-3.677 2.227-8.562-.93-11.71"/>

                <path
                  className="ray-3"
                  fill="#FF9E00"
                  d="M231.63 102.646V9.173c0-5.377-4.687-9.717-10.047-9.117-48.18 5.402-92.227 24.35-128.24 52.957-4.215 3.35-4.438 9.705-.625 13.505l66.32 66.105c3.16 3.148 8.063 3.44 11.748.925a139.871 139.871 0 0 1 53.195-21.98c4.387-.817 7.65-4.475 7.65-8.922"/>


                <path
                  className="ray-4"
                  fill="#FF9E00"
                  d="M406.658 53.016C370.65 24.406 326.605 5.458 278.422.056c-5.36-.6-10.047 3.74-10.047 9.117v93.475c0 4.448 3.262 8.103 7.65 8.923a139.844 139.844 0 0 1 53.19 21.977c3.685 2.515 8.588 2.223 11.748-.925l66.322-66.105c3.81-3.8 3.59-10.152-.627-13.502"/>

                <path
                  className="ray-5"
                  fill="#FE8D26"
                  d="M397.024 230.88h93.774c5.393 0 9.748-4.673 9.145-10.016-5.414-48.02-24.427-91.92-53.122-127.81-3.36-4.204-9.735-4.425-13.545-.627l-66.322 66.105c-3.16 3.147-3.453 8.032-.93 11.707 10.762 15.683 18.404 33.633 22.05 53.016.82 4.372 4.487 7.625 8.95 7.625"/>


                <path
                  className="ray-6"
                  fill="#FD7E5B"
                  d="M388.074 275.13c-3.646 19.385-11.283 37.34-22.05 53.022-2.523 3.675-2.23 8.56.93 11.71l66.32 66.105c3.812 3.8 10.187 3.578 13.547-.625 28.697-35.894 47.707-79.797 53.122-127.822.603-5.342-3.752-10.012-9.144-10.012h-93.775c-4.46 0-8.13 3.25-8.95 7.622"/>

              </g>
            </svg>
        </div>
      </div>

      </div>

    )
  }
}


export default BuscarRuta;

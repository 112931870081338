import React, { Component } from 'react';
import BarraLateral from './sideBarAdmin.js';
import { Divider,Segment,Label,Dropdown, Header,Image, Icon,Table,Modal,Button,Form,Dimmer, Loader} from 'semantic-ui-react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {ref,firebaseAuth} from '../../const.js';
import firebase from 'firebase'
import axios from 'axios';
import {Idioma} from '../../strings/RutasSellerText.js';
import AddIcon from '@material-ui/icons/Add';
import {Direccion} from '../../strings/peticiones.js';
import organizacionArray from '../OrganizarRutas.js';
import Fab from '@material-ui/core/Fab';
import Script from 'react-load-script';
import SearchBar from 'material-ui-search-bar'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import XLSX from 'xlsx';
import { DatePicker } from 'material-ui-pickers';

import TypeMarLCL from './ComponentType/TypeMarLCL.js';
import TypeMarFCL from './ComponentType/TypeMarFCL.js';
import TypeMarBBulk from './ComponentType/TypeMarBBulk.js';
import TypeTruckFCL from './ComponentType/TypeTruckFcl.js';
import TypeTruckLTL from './ComponentType/TypeTruckLTL.js';
import TypeTruckFTL from './ComponentType/TypeTruckFTL.js';
import TypeFerroFcl from './ComponentType/TypeFerroFcl.js';
import TypeFerroWagon from './ComponentType/TypeFerroWagon.js';
import TypeAereo from './ComponentType/TypeAereo.js';
import Particles from 'react-particles-js';
import './styleAdmin/rutasAdmin.css';

class RutasAdmin extends Component{
  constructor(){
    super()

  }


  render(){
    return(

      <div>
      <BarraLateral/>
      <RutasDetalle/>
      </div>
    )
  }
}



class RutasDetalle extends Component{
  constructor(){
    super();
    var date = new Date();

    this.state={
      valueTabs:0,
      rutas:[],
      RutasActivas:[],
      RutasNoActivas:[],
      type:0,
      Status:true,
      perfil:{},
      usuarios:[],
      textType:0,
    }
      this.setLengthRutas = this.setLengthRutas.bind(this);

  }

  UNSAFE_componentWillMount(){
    let self =this;
    let perfiles=[];
    axios.post(Direccion+`/totalUsuarios`,
      {tipoPeticion:'Normalizar',tipo:'Vendedor'})
      .then(res => {
        perfiles=res.data;
          self.setState({
            usuarios:perfiles
          })
        })

  }
  setLengthRutas(lengthRutas, type){
      console.log(lengthRutas, type);
    };

  selectUser=(e,{value})=>{
    this.setState({userToSearch:value,type:parseInt(this.state.type)+1},()=>{this.setState({type:parseInt(this.state.type)-1})})
  }
  handleChangeTabtype=(e, {value})=>{
    let typeText = value;
    this.setState({ type: value,typeText:typeText});
  };

  render(){
    const { open, dimmer } = this.state
    let text=Idioma('ES');
    let type = this.state.type;
    return(

      <div className='header-rutas RutasAdmin'>
      <div style={{position:'relative',height:'200px'}}>
        <Particles style={{backgroundImage:'url(../imagenes/head5.jpg)', backgroundSize:'cover',backgroundPosition:'center', position:'absolute',height:'500px'}}
             params={{
               "particles": {
                   "number": {
                       "value": 80
                   },
                   "size": {
                       "value": 2
                   }
               },
               "interactivity": {
                   "events": {
                       "onhover": {
                           "enable": true,
                           "mode": "repulse"
                       }
                   }
               }
           }} />
      <div>
        <Divider horizontal style={{paddingTop:'4vh'}}>
          <Header as='h3' style={{position:'relative',color:'white'}}>
              <i class="material-icons" style={{ fontSize: '2em' }}>language </i>
              {text.titulo}
          </Header>
        </Divider>
        <Dropdown
            placeholder='Select user'
            fluid
            selection
            style={{width:'50%',margin:'5px auto'}}
            options={this.state.usuarios}
            onChange={this.selectUser}
            value={this.state.userToSearch}
        />

        <Dropdown
            placeholder='Select rate type'
            fluid
            selection
            style={{width:'50%',margin:'5px auto'}}
            options={[
              {key: 0, value:0 ,icon:<i class="material-icons">directions_boat</i>,content:'Ocean (LCL)',text:'Ocean (LCL)'},
              {key: 1, value:1 ,icon:<i class="material-icons">directions_boat</i>,content:'Ocean (FCL)',text:'Ocean (FCL)'},
              {key: 2, value:2 ,icon:<i class="material-icons">directions_boat</i>,content:'Ocean (BREAK BULK)',text:'Ocean (BREAK BULK)'},
              {key: 3, value:3 ,icon:<i class="material-icons">local_shipping</i>,content:'Truck (FCL)',text:'Truck (FCL)'},
              {key: 4, value:4 ,icon:<i class="material-icons">local_shipping</i>,content:'Truck (LTL)',text:'Truck (LTL)'},
              {key: 5, value:5 ,icon:<i class="material-icons">local_shipping</i>,content:'Truck (FTL)',text:'Truck (FTL)'},
              {key: 6, value:6 ,icon:<i class="material-icons">train</i>,content:'F.F.C.C (FCL)',text:'F.F.C.C (FCL)'},
              {key: 7, value:7 ,icon:<i class="material-icons">train</i>,content:'F.F.C.C (WAGONS)',text:'F.F.C.C (WAGONS)'},
              {key: 8, value:8 ,icon:<i class="material-icons">airplanemode_active</i>,content:'AIR',text:'AIR'},

            ]}
            onChange={this.handleChangeTabtype}
            value={this.state.typeText}
        />

        </div></div>
        <div>
        {/* {this.state.Status?
          <div>
          { this.state.type===0?
            <div>
            <TypeMarLCL usuario={this.state.userToSearch} returnLengthRutas={this.setLengthRutas} />

              </div>:
              <div></div>
          }
          { this.state.type===1?
            <div>
            <TypeMarFCL returnLengthRutas={this.setLengthRutas} usuario={this.state.userToSearch}/>
              </div>:
              <div></div>
          }
          { this.state.type===2?
            <div>
              <TypeMarBBulk returnLengthRutas={this.setLengthRutas} usuario={this.state.userToSearch}/>
              </div>:
              <div></div>
          }
           { this.state.type===3?
            <div>
              <TypeTruckFCL returnLengthRutas={this.setLengthRutas} usuario={this.state.userToSearch}/>
              </div>:
              <div></div>
          }
          { this.state.type===4?
            <div>
              <TypeTruckLTL returnLengthRutas={this.setLengthRutas} usuario={this.state.userToSearch}/>
              </div>:
              <div></div>
          }
          { this.state.type===5?
            <div>
            <TypeTruckFTL returnLengthRutas={this.setLengthRutas} usuario={this.state.userToSearch}/>
              </div>:
              <div></div>
          }
          { this.state.type===6?
            <div>
            <TypeFerroFcl returnLengthRutas={this.setLengthRutas} usuario={this.state.userToSearch}/>
              </div>:
              <div></div>
          }
          { this.state.type===7?
            <div>
              <TypeFerroWagon returnLengthRutas={this.setLengthRutas} usuario={this.state.userToSearch}/>
              </div>:
              <div></div>
          }
          { this.state.type===8?
            <div>
            <TypeAereo returnLengthRutas={this.setLengthRutas} usuario={this.state.userToSearch}/>

              </div>:
              <div></div>
          }
          </div>  :<div></div>

        } */}
        <p style={{fontSize:'3rem',textAlign:'center',marginTop:'50px'}}>  En reparacion</p>
        </div>
      </div>






    )
  }
}








export default RutasAdmin;

import React, { Component } from 'react';
import { Divider, Header} from 'semantic-ui-react';
import Particles from 'react-particles-js';



class BuscarRuta extends Component{
  render(){
    return(
      <div>
        <div className="div-principal-Catalogo" >
          <div style={{position:'relative'}}>
            <Particles style={{backgroundImage:'url(../imagenes/directorio.jpg)', backgroundSize:'cover',backgroundPosition:'100% 0%', position:'absolute',height:'500px'}}
              params={{
                   "particles": {
                       "number": {
                           "value": 80
                       },
                       "size": {
                           "value": 2
                       }
                   },
                   "interactivity": {
                       "events": {
                           "onhover": {
                               "enable": true,
                               "mode": "repulse"
                           }
                       }
                   }
               }}
            />
            <div>
               <Divider horizontal style={{padding:'5vh'}}>
                 <Header as='h3' style={{ position:'relative',color:'white'}}>
                     <i className="material-icons" style={{ fontSize: '2em' ,position:'relative',color:'white'}}>book </i>
                    No Premium
                 </Header>
               </Divider>


            </div>
          </div>
          <div id="ImgBlocked" style={{width:"50%",margin:"0px auto"}}>
            <div style ={{backgroundImage:'url(../imagenes/notCross.png)',backgroundRepeat: "no-repeat",backgroundSize: "cover",padding: "210px 0px",margin: "0 auto"}}></div>
            <h2>With your current plan you cannot enter this section, buy a Premium plan</h2>
          </div>
        </div>
      </div>

    )
  }
}





export default BuscarRuta;

export default function ValidarItemsArchivo(ArrayRutas,tipoRuta,tipoArchivo){
  let validado = true;
  let errorMessage='';

  let ArrayRutasAux = ArrayRutas.slice(2);
  ArrayRutas.slice(2).forEach(function (it,index,array) {
    let respuesta = ValidarTipo(it.length);
    if (respuesta.key === 'Delete') {
      ArrayRutasAux.splice(index,1);
    }else{
      if (tipoRuta !== respuesta.key) {
        validado=false;
        errorMessage=respuesta.displayName;
      }
    }
  })
  let ArrayOrganizado = OrganizarRutas(ArrayRutasAux,tipoArchivo);
  return({status:validado,errorMessage:errorMessage,ArrayRutas:ArrayOrganizado});
}

function ValidarTipo(length){
    switch (length) {
      case 0:
        return {key:'Delete',displayName:''}
      break;
      case 21:
        return {key:'LCL',displayName:'corresponds to a file type LCL'}
      break;
      case 22:
        return {key:'FCL',displayName:'corresponds to a file type FCL'}
      break;
      case 17:
        //BreakBulk, Wagons y FCL-Truck' tienen 17 columnas
        return {key:'BWT',displayName:'corresponds to a file type Break Bulk, Wagons or FCL-Truck'}
      break;
      case 23:
        return {key:'Aereo',displayName:'corresponds to a file type Air'}
      break;
      case 18:
        return {key:'FCL-Ferro',displayName:'corresponds to a file type F.F.C.C (FCL)'}
      break;
      case 19:
        return {key:'FTL-Truck',displayName:'corresponds to a file type Truck (FTL)'}
      break;
      case 16:
        return {key:'LTL-Truck',displayName:'corresponds to a file type Truck (LTL)'}
      break;
      default:
        return {key:'404',displayName:'The file does not correspond to a CEIF rates file'}
      break;
    }
}



 function OrganizarRutas(rutasArray,tipo){
     let arrayOrganizado=[];
     //21
     if (tipo === "LCL") {
           for(var i=0; i<=rutasArray.length; i++){
           if(rutasArray[i] !== undefined && rutasArray[i].length>0){
               arrayOrganizado.push({
                 PaisOrigen:rutasArray[i][0],
                 PaisDestino:rutasArray[i][1],
                 Origen:rutasArray[i][2],
                 Destino:rutasArray[i][3],
                 Trafico:rutasArray[i][4],
                 Servicio:rutasArray[i][5],
                 Frecuencia:rutasArray[i][6],
                 Tiempo:rutasArray[i][7],
                 Tarifa:rutasArray[i][8],
                 Insurance:rutasArray[i][9],
                 PickUp:rutasArray[i][10],
                 EbsWM:rutasArray[i][11],
                 TsVgm:rutasArray[i][12],
                 RecargoIMO:rutasArray[i][13],
                 Stuffing:rutasArray[i][14],
                 Ams:rutasArray[i][15],
                 PtfWM:rutasArray[i][16],
                 Vgm:rutasArray[i][17],
                 DocFee:rutasArray[i][18],
                 Unstuffing:rutasArray[i][19],
                 InlandTransport:rutasArray[i][20],

               })
             }
           }
           return(arrayOrganizado);
         }
    //22
     else if (tipo === "FCL") {
         for(var i=0; i<=rutasArray.length; i++){
         if(rutasArray[i] !== undefined  && rutasArray[i].length>0){

             arrayOrganizado.push({
               PaisOrigen:rutasArray[i][0],
               PaisDestino:rutasArray[i][1],
               Origen:rutasArray[i][2],
               Destino:rutasArray[i][3],
               Trafico:rutasArray[i][4],
               Servicio:rutasArray[i][5],
               ContainerType:rutasArray[i][6],
               ContainerSize:rutasArray[i][7],
               Tarifa:rutasArray[i][8],
               Insurance:rutasArray[i][9],
               RecargoIMO:rutasArray[i][10],
               PickUp:rutasArray[i][11],
               BlFee:rutasArray[i][12],
               Thc:rutasArray[i][13],
               Buc:rutasArray[i][14],
               Dre:rutasArray[i][15],
               Cnr:rutasArray[i][16],
               Isp:rutasArray[i][17],
               Ams:rutasArray[i][18],
               TerminalHandling:rutasArray[i][19],
               ContainerCleaning:rutasArray[i][20],
               Inland:rutasArray[i][21],
             })
           }
         }
         return(arrayOrganizado);
       }
    //17
     else if (tipo === "BBulk") {
         for(var i=0; i<=rutasArray.length; i++){
         if(rutasArray[i] !== undefined && rutasArray[i].length>0){

             arrayOrganizado.push({
               PaisOrigen:rutasArray[i][0],
               PaisDestino:rutasArray[i][1],
               Origen:rutasArray[i][2],
               Destino:rutasArray[i][3],
               Trafico:rutasArray[i][4],
               Servicio:rutasArray[i][5],
               PickUp:rutasArray[i][6],
               Tarifa:rutasArray[i][7],
               Insurance:rutasArray[i][8],
               RecargoIMO:rutasArray[i][9],
               DocFee:rutasArray[i][10],
               ThcOrigen:rutasArray[i][11],
               ExportDocs:rutasArray[i][12],
               ThcDestino:rutasArray[i][13],
               StoragePday:rutasArray[i][14],
               CraneTariff:rutasArray[i][15],
               Inland:rutasArray[i][16],
             })
           }
         }
         return(arrayOrganizado);
       }
    //23
     else if (tipo === "Aereo") {
           for(var i=0; i<=rutasArray.length; i++){
           if(rutasArray[i] !== undefined && rutasArray[i].length>0){
               arrayOrganizado.push({
                 PaisOrigen:rutasArray[i][0],
                 PaisDestino:rutasArray[i][1],
                 Origen:rutasArray[i][2],
                 Destino:rutasArray[i][3],
                 Trafico:rutasArray[i][4],
                 Servicio:rutasArray[i][5],
                 TarifaAerea45:rutasArray[i][6],
                 TarifaAerea100:rutasArray[i][7],
                 TarifaAerea300:rutasArray[i][8],
                 TarifaAerea500:rutasArray[i][9],
                 TarifaAerea1000:rutasArray[i][10],
                 Insurance:rutasArray[i][11],
                 RecargoIMO:rutasArray[i][12],
                 PickUp:rutasArray[i][13],
                 Ams:rutasArray[i][14],
                 Fsc:rutasArray[i][15],
                 Tax:rutasArray[i][16],
                 HandlingOrigen:rutasArray[i][17],
                 DocFee:rutasArray[i][18],
                 AirportTaxes:rutasArray[i][19],
                 HandlingDestino:rutasArray[i][20],
                 Unstuffing:rutasArray[i][21],
                 LocalDelivery:rutasArray[i][22],
               })
             }
           }
           return(arrayOrganizado);
         }
    //16
     else if (tipo === "LTL-Truck") {
           for(var i=0; i<=rutasArray.length; i++){
           if(rutasArray[i] !== undefined && rutasArray[i].length>0){
               arrayOrganizado.push({
                 PaisOrigen:rutasArray[i][0],
                 PaisDestino:rutasArray[i][1],
                 Origen:rutasArray[i][2],
                 Destino:rutasArray[i][3],
                 Trafico:rutasArray[i][4],
                 Servicio:rutasArray[i][5],
                 Frecuencia:rutasArray[i][6],
                 Tiempo:rutasArray[i][7],
                 Tarifa:rutasArray[i][8],
                 Insurance:rutasArray[i][9],
                 PickUp:rutasArray[i][10],
                 HandlingOrigen:rutasArray[i][11],
                 HandlingDestino:rutasArray[i][12],
                 RecargoIMO:rutasArray[i][13],
                 DocFee:rutasArray[i][14],
                 LocalDelivery:rutasArray[i][15],
               })
             }
           }
           return(arrayOrganizado);
         }
    //17
     else if (tipo === "FCL-Truck") {
         for(var i=0; i<=rutasArray.length; i++){
         if(rutasArray[i] !== undefined  && rutasArray[i].length>0){

             arrayOrganizado.push({
               PaisOrigen:rutasArray[i][0],
               PaisDestino:rutasArray[i][1],
               Origen:rutasArray[i][2],
               Destino:rutasArray[i][3],
               Trafico:rutasArray[i][4],
               Servicio:rutasArray[i][5],
               ContainerType:rutasArray[i][6],
               ContainerSize:rutasArray[i][7],
               Frecuencia:rutasArray[i][8],
               Tiempo:rutasArray[i][9],
               Tarifa:rutasArray[i][10],
               Insurance:rutasArray[i][11],
               HandlingOrigen:rutasArray[i][12],
               HandlingDestino:rutasArray[i][13],
               RecargoIMO:rutasArray[i][14],
               DocFee:rutasArray[i][15],
               LocalDelivery:rutasArray[i][16],
             })
           }
         }
         return(arrayOrganizado);
       }
    //19
     else if (tipo === "FTL-Truck") {

         for(var i=0; i<=rutasArray.length; i++){
           if(rutasArray[i] !== undefined  && rutasArray[i].length>0){
               arrayOrganizado.push({
                 PaisOrigen:rutasArray[i][0],
                 PaisDestino:rutasArray[i][1],
                 Origen:rutasArray[i][2],
                 Destino:rutasArray[i][3],
                 Trafico:rutasArray[i][4],
                 Servicio:rutasArray[i][5],
                 ContainerType:rutasArray[i][6],
                 ContainerSize:rutasArray[i][7],
                 Frecuencia:rutasArray[i][8],
                 Tiempo:rutasArray[i][9],
                 TarifaSingle:rutasArray[i][10],
                 TarifaFull:rutasArray[i][11],
                 Insurance:rutasArray[i][12],
                 PickUp:rutasArray[i][13],
                 HandlingOrigen:rutasArray[i][14],
                 HandlingDestino:rutasArray[i][15],
                 RecargoIMO:rutasArray[i][16],
                 DocFee:rutasArray[i][17],
                 LocalDelivery:rutasArray[i][18],
               })
             }
           }
           return(arrayOrganizado);
         }
    //18
     else if (tipo === "FCL-Ferro") {
         for(var i=0; i<=rutasArray.length; i++){
           if(rutasArray[i] !== undefined  && rutasArray[i].length>0){
                 arrayOrganizado.push({
                   PaisOrigen:rutasArray[i][0],
                   PaisDestino:rutasArray[i][1],
                     Origen:rutasArray[i][2],
                     Destino:rutasArray[i][3],
                     Trafico:rutasArray[i][4],
                     Servicio:rutasArray[i][5],
                     ContainerType:rutasArray[i][6],
                     ContainerSize:rutasArray[i][7],
                     Frecuencia:rutasArray[i][8],
                     Tiempo:rutasArray[i][9],
                     Tarifa:rutasArray[i][10],
                     Insurance:rutasArray[i][11],
                     PickUp:rutasArray[i][12],
                     HandlingOrigen:rutasArray[i][13],
                     HandlingDestino:rutasArray[i][14],
                     RecargoIMO:rutasArray[i][15],
                     DocFee:rutasArray[i][16],
                     LocalDelivery:rutasArray[i][17],
                   })
                 }
               }
               return(arrayOrganizado);
         }
    //17
     else if (tipo === "Wagon") {
           for(var i=0; i<=rutasArray.length; i++){
             if(rutasArray[i] !== undefined  && rutasArray[i].length>0){
                   arrayOrganizado.push({
                        PaisOrigen:rutasArray[i][0],
                        PaisDestino:rutasArray[i][1],
                         Origen:rutasArray[i][2],
                         Destino:rutasArray[i][3],
                         Trafico:rutasArray[i][4],
                         Servicio:rutasArray[i][5],
                         ContainerType:rutasArray[i][6],
                         Frecuencia:rutasArray[i][7],
                         Tiempo:rutasArray[i][8],
                         Tarifa:rutasArray[i][9],
                         Insurance:rutasArray[i][10],
                         PickUp:rutasArray[i][11],
                         HandlingOrigen:rutasArray[i][12],
                         HandlingDestino:rutasArray[i][13],
                         RecargoIMO:rutasArray[i][14],
                         DocFee:rutasArray[i][15],
                         LocalDelivery:rutasArray[i][16],
                       })
                     }
                   }
                   return(arrayOrganizado);
                 }

 }

import React, { Component } from 'react';
import Usuarios from '../componentes/ComponentsAdmin/usuariosAdmin.js'
import EnviosAdmin from '../componentes/ComponentsAdmin/enviosAdmin.js'
import RutasAdmin from '../componentes/ComponentsAdmin/rutasAdmin.js'
import PagosAdmin from '../componentes/ComponentsAdmin/pagosAdmin.js'
import ComisionesAdmin from '../componentes/ComponentsAdmin/comisionesAdmin.js'

import Analitics from '../componentes/ComponentsAdmin/analiticsAdmin.js';
import axios from 'axios';
import {Direccion} from '../strings/peticiones.js';
import { Route, BrowserRouter, Switch,} from 'react-router-dom'
import firebase from 'firebase'



class AdminSide extends Component{
  constructor(){
    super()
    this.state = {
      Status:false,
      openPaquetes:false,
    }

  }

  UNSAFE_componentWillMount=()=>{
    let perfil=[];
    axios.post(Direccion+`/tomarUsuario`,
      {id:firebase.auth().currentUser.uid,tipo:'Vendedor'})
      .then(res => {
        perfil=res.data;
        console.log(perfil);
      })

  }

  close = () => this.setState({ openPaquetes: false })


  render(){
    return(

      <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/admin" component={Analitics}/>
          <Route path="/admin/Rutas" component={RutasAdmin}/>
          <Route path="/admin/Envios" component={EnviosAdmin}/>
          <Route path="/admin/usuarios" component={Usuarios}/>
          <Route path="/admin/pagos" component={PagosAdmin}/>
          <Route path="/admin/comisiones" component={ComisionesAdmin}/>

        </Switch>
      </BrowserRouter>



      </div>
    )
  }
}

export default AdminSide;

import React, { Component,useState, useEffect,useRef } from 'react';
import './Carousel.css';



export default function Carousel(props){
  const [itemActivo,setItemActivo] = useState(0);
  useEffect(()=>{
    setItemActivo(props.itemActivo);
  },[props.itemActivo])

  return(
    <div className='carouselContent'>
      <div className='carousel' >
        <div onClick={()=>setItemActivo(0)} className={itemActivo == 0?'square big blue':'square small blue'}>
            <div className={itemActivo == 0?'tituloItem':'tituloItem oculto'} >
              <p className='title_carousel'>Get Instant Rates</p>
              <p className='subtitle_carousel'>Search from over 120K Ocean, Air and Truck Freight quotes and get the latest international shipping rates from any carrier in seconds.</p>
            </div>
            <div>
              <img className='carouselImage' src='../imagenes/person1.png'/>
            </div>
        </div>
        <div onClick={()=>setItemActivo(1)} className={itemActivo == 1?'square big orange':'square small orange'}>
          <div className={itemActivo == 1?'tituloItem':'tituloItem oculto'} >
            <p className='title_carousel'>Access to CEIF Logistics Network</p>
            <p className='subtitle_carousel'>CEIF Logistics Network offers you a unique opportunity to interact with our family members personally through our business plattform.</p>
          </div>
          <div>
              <img className='carouselImage' src='../imagenes/person2.png'/>
            </div>
        </div>
        <div onClick={()=>setItemActivo(2)} className={itemActivo == 2?'square big blue':'square small blue'}>
          <div className={itemActivo == 2?'tituloItem':'tituloItem oculto'} >
            <p className='title_carousel'>Freight Networking</p>
            <p className='subtitle_carousel'>Agents constantly need to be in contact with other agents around the world, in order to make freight happen efficiently and meet international demands.</p>
          </div>
          <div>
              <img className='carouselImage' src='../imagenes/person3.png'/>
            </div>
        </div>
      </div>
    </div>
  )
}

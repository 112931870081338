import React, { Component } from 'react';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import {Router, Route, BrowserRouter, Switch, Link} from 'react-router-dom'
import { Message,Menu,Popup,Header,Icon } from 'semantic-ui-react';
import Badge from '@material-ui/core/Badge';
import ListaNotificaciones from './listaNotificacionesAdmin.js';
import axios from 'axios';
import {Direccion} from '../../strings/peticiones.js';
import firebase from 'firebase'

class BarraLateral extends Component{
  constructor(){
    super()
    this.state = {
      verificado: firebase.auth().currentUser.emailVerified,
      notificaciones:[]
    }

  }

  UNSAFE_componentWillMount(){
   this.notificaciones();
  }

  notificaciones(){
    var data;
    var self=this;
    axios.post(Direccion+'/getNotificaciones',{id:firebase.auth().currentUser.uid,tipo:'Admin'})
    .then(res=>{
      data=res.data;
      localStorage.setItem('notificaciones', JSON.stringify(data));
      this.setState({notificaciones:data.reverse()})
    })
  }
  handleItemClick=e=>{

  }
  cerrar(){
    this.setState({
      verificado:true
    })
  }

  render(){
    return(
<div>

<Menu pointing style={{fontSize:'15px'}}>
  <Link to="/admin/">
    <Menu.Item name='home' />
  </Link>
  <Link to="/admin/Rutas">
    <Menu.Item name='Rutas'/>
  </Link>

  <Link to="/admin/Envios">
    <Menu.Item name='Envios'/>
  </Link>
  <Link to="/admin/usuarios">
    <Menu.Item name='Usuarios'/>
  </Link>
  <Link to="/admin/pagos">
    <Menu.Item name='Pagos'/>
  </Link>
    <Link to="/admin/comisiones">
    <Menu.Item name='Configuraciones'/>
  </Link>

  <Header as='h4' icon='settings' content='Administrador' style={{marginTop:'11px',marginLeft:'30vh'}} />

  <Menu.Menu position='right'>
    <Menu.Item>
      <Popup
        style={{maxWidth:'85vh',height: 'auto',maxHeight: '65vh',overflowY: 'scroll'}}
         trigger={<Badge badgeContent={this.state.notificaciones.length} color="secondary">
        <i class="material-icons">
        notifications
        </i>
        </Badge>}
      content={<ListaNotificaciones datos={this.state.notificaciones}/>}
      on='click'
       />
    </Menu.Item>
  </Menu.Menu>

<Menu.Menu>
  <Menu.Item
    onClick={() => this.handleItemClick(firebase.auth().signOut() )}
  >
    <Icon name='sign-out'></Icon>
    <p>Log out</p>

  </Menu.Item>

  </Menu.Menu>

</Menu>


</div>
)
}
}


export default BarraLateral;

import React, { Component } from 'react';
import {Pagination} from 'semantic-ui-react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import '../styleSeller/ShowRutas.css';
import {ref,firebaseAuth} from '../../../const.js';
import firebase from 'firebase'
import axios from 'axios';
import {Idioma} from '../../../strings/RutasSellerText.js';
import {Direccion} from '../../../strings/peticiones.js';
import DeterminarImagen from '../../scripts/imagenes.js';
import RutasIndividualesFCLFerro from '../ComponentType/TypeFerroFcl.js';

import AlertSnack from '../../notificacionCard.js';

class ShowRutaFCLFerro extends Component{
  constructor(){
    super();
    this.state={
      verRutasPorSlot:false,
      rutaModificar:{},
      RutasActivas:[],
      RutasNoActivas:[],
    }
    this.TablaSlots = React.createRef();
  }
  setRutaSelect=(ruta)=>{
    this.props.setRutaSelect(ruta.SlotData);
    this.setState({verRutasPorSlot:!this.state.verRutasPorSlot,rutaModificar:ruta})
  }
  regresarBack=()=>{
    this.setState({verRutasPorSlot:!this.state.verRutasPorSlot,rutaModificar:{}})
    this.GetRutas();
    this.props.regresarBack();
  }

  componentDidMount(){
    this.GetRutas(true);
  }
  GetRutas=(e)=>{
    let self=this;
    axios.post(Direccion+'/tomar-ruta',{idUser:firebaseAuth.currentUser.uid, type:'FCL-Ferro'})
    .then(res=>{
      console.log(res.data);
      if (res.data.status === 'OK') {
        self.setState({
          RutasActivas:res.data.rutas.activas,
          RutasNoActivas:res.data.rutas.noActivas
        })
        this.TablaSlots.current.RenderItemsTable();
      }
    })

  };
  render(){
    return(
      <div>
        {this.state.verRutasPorSlot?
          <RutasIndividualesFCLFerro text={Idioma('ES')} ruta={this.state.rutaModificar}  regresarBack={this.regresarBack}/>
          :
          <TablaSlots ref={this.TablaSlots} RutasNoActivas={this.state.RutasNoActivas} RutasActivas={this.state.RutasActivas}  verRutas={this.setRutaSelect}/>
        }
      </div>
    )
  }
}



class TablaSlots extends Component{
  constructor(){
    super();
    this.state={
      valueTabs:0,
      open: false,
      dimmer:true,
      active:false,
      rutas:[],
      RutasActivas:[],
      RutasNoActivas:[],
      type:0,
      currentPageActivas:1,
      currentPageNoActivas:1,
      PerPageActivas:21,
      PerPageNoActivas:21,
      currentTodosActivas:[],
      currentTodosNoActivas:[],
      renderCompanyActivas:null,
      renderCompanyNoActivas:null,
      pageNumbersActivas:0,
      pageNumbersNoActivas:0,
       openAlert:false,
      AlertMessage:'',
      AlertType:'',
      RefRenderItemsTable:this.RenderItemsTable.bind(this),
    }
  }

  handleOpenFecha = () => this.setState({ openFecha: true })
  handleChangeTab=(event, valueTabs)=>{
    this.setState({ valueTabs });
  };
  handlePaginationChangeActivo=(e,index)=>{
    this.setState({currentPageActivas:index.activePage}, () => {
      this.RenderItemsTable();
    })
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.RutasActivas !== prevState.RutasActivas) {
      return ({RutasActivas: nextProps.RutasActivas ,RutasNoActivas: nextProps.RutasNoActivas }) // <- this is setState equivalent
    }
    return null
  }


  RenderItemsTable=()=>{
    const indexOfLastTodoActivas = this.state.currentPageActivas * this.state.PerPageActivas;
    const indexOfLastTodoNoActivas = this.state.currentPageNoActivas * this.state.PerPageNoActivas;
    const indexOfFirstTodoActivas = indexOfLastTodoActivas - this.state.PerPageActivas;
    const indexOfFirstTodoNoActivas = indexOfLastTodoNoActivas - this.state.PerPageNoActivas;

    const currentTodosActivas = this.state.RutasActivas.slice(indexOfFirstTodoActivas, indexOfLastTodoActivas);
    const currentTodosNoActivas = this.state.RutasNoActivas.slice(indexOfFirstTodoNoActivas, indexOfLastTodoNoActivas);

    const renderCompanyActivas = currentTodosActivas.map((it,index)=>{
     return(<Item status={1} verRutas={this.props.verRutas}  item={it} key={index}/>)
    })
    const renderCompanyNoActivas = currentTodosNoActivas.map((it,index)=>{
      return(<Item status={0} verRutas={this.props.verRutas}  item={it} key={index}/>)
    })
    const pageNumbersActivas =  Math.ceil(this.state.RutasActivas.length / this.state.PerPageActivas);
    const pageNumbersNoActivas =  Math.ceil(this.state.RutasNoActivas.length / this.state.PerPageNoActivas);
    this.setState({
      pageNumbersActivas:Math.ceil(this.state.RutasActivas.length / this.state.PerPageActivas),
       pageNumbersNoActivas:Math.ceil(this.state.RutasNoActivas.length / this.state.PerPageNoActivas),
       renderCompanyActivas:renderCompanyActivas,
       renderCompanyNoActivas:renderCompanyNoActivas,
    })

  }


  render(){
    const { open, dimmer } = this.state
    let text=Idioma('ES');
    let type = this.state.type;
    return(

        <div className='header-rutasMar'>
          <AlertSnack open={this.state.openAlert} handleClose={this.handleCloseAlert} message={this.state.AlertMessage} type={this.state.AlertType}/>
          <div>

            <Tabs value={this.state.valueTabs} onChange={this.handleChangeTab} style={{width: '100%',display: 'flex',justifyContent: 'center'}}  indicatorColor="secondary" textColor="primary">
              <Tab icon={<i class="material-icons">verified_user</i>}
                label={
                   <React.Fragment>
                    Active rates<br />
                    <span style={{ fontSize: "smaller", margin:'0px auto', textAlign:'center' }}>{this.state.RutasActivas.length}</span>
                  </React.Fragment>
                }>
              </Tab>
              <Tab icon ={<i class="material-icons">update</i>}
                label={
                   <React.Fragment>
                    Expired rates<br />
                    <span style={{ fontSize: "smaller", margin:'0px auto', textAlign:'center' }}>{this.state.RutasNoActivas.length}</span>
                  </React.Fragment>
                }>
              </Tab>
            </Tabs>
            <br/>

            {this.state.valueTabs===0?
              <div style={{overflowY:'auto'}}>
                <div className='contentItemsPadre'>
                  {this.state.renderCompanyActivas}
                </div>
                 <div id='Paginacion'>
                  <Pagination
                   onPageChange={this.handlePaginationChangeActivo}
                   totalPages={this.state.pageNumbersActivas}
                   activePage={this.state.currentPageActivas}
                    />
                  </div>
              </div>
            :<div></div>
            }
            {this.state.valueTabs===1?
              <div style={{overflowY:'auto'}}>
                <div className='contentItemsPadre'>
                  {this.state.renderCompanyNoActivas}
                </div>
                 <div id='Paginacion'>
                  <Pagination
                   onPageChange={this.handlePaginationChangeActivo}
                   totalPages={this.state.pageNumbersActivas}
                   activePage={this.state.currentPageActivas}
                    />
                  </div>
                  <div className='messgeContent'>
                    <p>All slots are eliminated after 3 months of expiration</p>
                  </div>
              </div>
              :<div></div>
            }
        </div>

      </div>
    )
  }
}

class Item extends Component{
  constructor(){
    super()
    this.state={
      key:'',
      Expiracion:'',
      open:false,
      Modificar:''
    }
  }
  render(){
    let imagen=DeterminarImagen.DeterminarImagenSimple(this.props.item.SlotData.tipoRuta);
    return(
      <div key={this.props.key} onClick={()=>this.props.verRutas(this.props.item)} className='CardItem'>
        <div className='imgCardItem'><img src={'../imagenes/'+imagen}/></div>
        <div className='infoCardItem left'>
          <h3>Name</h3>
          <p>{this.props.item.SlotData.NameSlot}</p>
        </div>
        <div className='infoCardItem left'>
          <h3>ID Packages</h3>
          <p>{this.props.item.SlotData.IdSlot}</p>
        </div>
        <div className='infoCardItem center'>
          <h3>Valid From</h3>
          <p>{this.props.item.SlotData.FechaIniciacion}</p>
        </div>
        <div className='infoCardItem center'>
          <h3>Expirate date</h3>
          <p>{this.props.item.SlotData.Expiracion}</p>
        </div>
        <div className='infoCardItem center'>
          <h3>Total rates</h3>
          <p>{this.props.item.SlotData.CantidadRutas}</p>
        </div>
        <div className={this.props.status===0?'indicadorStatus expired':'indicadorStatus active'}></div>
      </div>
    )
  }
}


export default ShowRutaFCLFerro;

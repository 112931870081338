var axios = require('axios');
var FinalizadoProceso =require('./filtrarProcesoFinalizados.js');


async function TodasAnaliticas(Direccion,perfiles){
  let rutas=[];
  var promesa=new Promise(function(resolve,reject){
    let lclPromise= GetDatos(Direccion,perfiles);
    lclPromise.then(function(done){
      let activasLcl=done.lcl.activas.length!==undefined?done.lcl.activas.length:0;
      let activasFcl=done.fcl.activas.length!==undefined?done.fcl.activas.length:0;
      let noActivasLcl=done.lcl.noActivas.length!==undefined?done.lcl.noActivas.length:0;
      let noActivasFcl=done.fcl.noActivas.length!==undefined?done.fcl.noActivas.length:0;
      let activas=parseInt(activasLcl)+ parseInt(activasFcl)+  parseInt(done.rutasBBulk)+
          parseInt(done.rutasAereo)+ parseInt(done.rutasFclTruck)+ parseInt(done.rutasLtlTruck)+
          parseInt(done.rutasFclFerro)+ parseInt(done.rutasWagon)+ parseInt(done.rutasFtlTruck);

      let noActivas=parseInt(noActivasLcl)+parseInt(noActivasFcl)+ parseInt(activasFcl)+ parseInt(done.rutasBBulkNA)+
          parseInt(done.rutasAereoNA)+ parseInt(done.rutasFclTruckNA)+ parseInt(done.rutasLtlTruckNA)+
          parseInt(done.rutasFclFerroNA)+ parseInt(done.rutasWagonNA)+ parseInt(done.rutasFtlTruckNA);

      let enviosActivos=done.enviosProceso.length!==undefined?done.enviosProceso.length:0;
      let enviosTerminados=done.enviosTerminados.length!==undefined?done.enviosTerminados.length:0;
        resolve(rutas= {activas:activas,noActivas:noActivas,enviosProceso:enviosActivos,enviosTerminados:enviosTerminados,nombre:done.nombre});
    })

  })
  return promesa;

}

async function GetDatos(Direccion,perfiles){

  let datos=[];
  var d =new Date();
  var mes= parseInt(d.getMonth()+1);
  var dia=d.getDate();
    if (mes <10) {
        mes = "0"+mes;
      }
    if (dia<10) {
      dia = "0"+dia;
    }
 var fecha=d.getFullYear()+'-'+mes +'-'+dia;

  let self=this;
  let rutasLclActivas=[];
  let rutasLclNoActivas=[];

  let rutasFcl=[];
  let rutasFclNoActivas=[];

  let rutasBBulk=[];
  let rutasAereo=[];
  let rutasFclTruck=[];
  let rutasLtlTruck=[];
  let rutasFclFerro=[];
  let rutasWagon=[];
  let rutasFtlTruck=[];


    let rutasBBulkNA=[];
    let rutasAereoNA=[];
    let rutasFclTruckNA=[];
    let rutasLtlTruckNA=[];
    let rutasFclFerroNA=[];
    let rutasWagonNA=[];
    let rutasFtlTruckNA=[];

  for(var i=0; i<perfiles.length;i++){
      let resLcl= await axios.post(Direccion+'/tomar-ruta',{user:perfiles[i].key, type:0});
        rutasLclActivas=rutasLclActivas.concat(resLcl.data.activas);
        rutasLclNoActivas=rutasLclNoActivas.concat(resLcl.data.noActivas);
      let resFcl= await axios.post(Direccion+'/tomar-ruta',{user:perfiles[i].key,type:1});
        rutasFcl= rutasFcl.concat(resFcl.data.activas);
        rutasFclNoActivas=rutasFclNoActivas.concat(resFcl.data.noActivas);

        let resBB= await axios.post(Direccion+'/tomar-ruta',{user:perfiles[i].key,type:2});
          rutasBBulk= rutasBBulk.concat(resBB.data.activas);
          rutasBBulkNA= rutasBBulkNA.concat(resBB.data.noActivas);

        let resAereo= await axios.post(Direccion+'/tomar-ruta',{user:perfiles[i].key,type:3});
          rutasAereo= rutasAereo.concat(resAereo.data.activas);
          rutasAereoNA= rutasAereoNA.concat(resAereo.data.noActivas);

        let resFclT= await axios.post(Direccion+'/tomar-ruta',{user:perfiles[i].key,type:4});
            rutasFclTruck= rutasFclTruck.concat(resFclT.data.activas);
            rutasFclTruckNA= rutasFclTruckNA.concat(resFclT.data.noActivas);

        let resLtlT= await axios.post(Direccion+'/tomar-ruta',{user:perfiles[i].key,type:5});
          rutasLtlTruck= rutasLtlTruck.concat(resLtlT.data.activas);
          rutasLtlTruckNA= rutasLtlTruckNA.concat(resLtlT.data.noActivas);

        let resFclF= await axios.post(Direccion+'/tomar-ruta',{user:perfiles[i].key,type:6});
          rutasFclFerro= rutasFclFerro.concat(resFclF.data.activas);
          rutasFclFerroNA= rutasFclFerroNA.concat(resFclF.data.noActivas);

        let resW= await axios.post(Direccion+'/tomar-ruta',{user:perfiles[i].key,type:7});
          rutasWagon= rutasWagon.concat(resW.data.activas);
          rutasWagonNA= rutasWagonNA.concat(resW.data.noActivas);

        let resFtlT= await axios.post(Direccion+'/tomar-ruta',{user:perfiles[i].key,type:8});
          rutasFtlTruck= rutasFtlTruck.concat(resFtlT.data.activas);
          rutasFtlTruckNA= rutasFtlTruckNA.concat(resFtlT.data.noActivas);

      let promise1=await axios.post(Direccion+`/tomarEnviosVendedor`,{data:{usuario:perfiles[i].key,inicio:d.getFullYear()+'-01-01',fin:fecha}});
        datos= datos.concat(promise1.data);

  }


  let datosFiltrados=FinalizadoProceso.cantidadDiferencia(datos);

    return {lcl:{activas:rutasLclActivas,noActivas:rutasLclNoActivas},fcl:{activas:rutasFcl,noActivas:rutasFclNoActivas},
    rutasBBulk:rutasBBulk.length,rutasAereo:rutasAereo.length,rutasFclTruck:rutasFclTruck.length,rutasLtlTruck:rutasLtlTruck.length,rutasFclFerro:rutasFclFerro.length,
    rutasWagon:rutasWagon.length,rutasFtlTruck:rutasFtlTruck.length,

    rutasBBulkNA:rutasBBulkNA.length,rutasAereoNA:rutasAereoNA.length,rutasFclTruckNA:rutasFclTruckNA.length,rutasLtlTruckNA:rutasLtlTruckNA.length,
    rutasFclFerroNA:rutasFclFerroNA.length,rutasWagonNA:rutasWagonNA.length,rutasFtlTruckNA:rutasFtlTruckNA.length,

    enviosTerminados:datosFiltrados.terminados,enviosProceso:datosFiltrados.proceso,nombre:'Admin'};

};



export default TodasAnaliticas;


const espanol={
  titulo:'My profile',
  mensajeDatos:{
    header:'You have still not completed your profile',
    descripcion:'You can do it later if you want'
  },

  card1:{
    titulo:'General information',
    email:'Email',
    pais:'Country',
    idiomas:'languages',
    descripcion:'About us',
    CompanyName:'Company Name',
    FirstName:'Name',
    LastName:'Last name',
    Title:'Title',
    Mobil:'Mobil',
    Email:'Email',
    Phone:'Phone',
    Fax:'Fax',
    Website:'Website',
    EstablishmentYear:'Establishment Year',
    NoEmployees:'No. of employees',
    NameOwners:'Name of company owner(s)',
    Address:'Address',
    City:'City',
    PostaleCode:'Postal Code',
    State:'Province/State',
    Contry:'Contry',
  },
  botones:{
    aceptar:'Accept',
    cancelar:'Cancel',
    modificar:'Update'
  },
  modalConfirmar:{
    mensajeConfirmacion:'Are you sure you make these changes?',
    encabezado:'Update profile'
  },
  cancelarSuscripcion:{
    mensajeConfirmacion:'Are you sure you want to cancel your subscription?',
    encabezado:'Cancel subscription'
  }
};

export function Idioma(idioma){
  if(idioma==='ES'){
    return espanol;
  }
}

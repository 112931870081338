import React, { Component } from 'react';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import {Router, Route, BrowserRouter, Switch, Link} from 'react-router-dom'
import { Divider,List, Header,Dimmer,Loader, Icon, Table, Card, Image,Button,Modal, Form } from 'semantic-ui-react';
import './styleUser/paquetesUser.css';
import firebase from 'firebase'
import BarraLateral from './sideBar.js';
import {Direccion} from '../../strings/peticiones.js';
import axios from 'axios';

import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);




class PaquetesUsuario extends Component{
  constructor(){
    super()

  }
  render(){
    return(
      <div className='profile-content'>
      <BarraLateral/>
      <PaquetesDetalle/>
      </div>
    )
  }
}


class PaquetesDetalle extends Component{

  constructor(){
    super()
      this.state = {
        comprar:false,
        InfoPaquete:{},
        Paquete1Enable:false,
        Paquete2Enable:false,
        Paquete3Enable:false,
        Cards:[],
        existCard:false,
        cardSelect:false,
        idCard:'',
        cvc: '',
        expiry: '',
      }
  }


  state = { open: false }

  UNSAFE_componentWillMount=()=>{
    let perfil=[];
    var self=this;
    axios.post(Direccion+`/tomarSuscripcion`,
      {idUser:firebase.auth().currentUser.uid})
      .then(res => {
        if (res.data.suscripcionName === 'Basic') {
          self.setState({
            Paquete1Enable:true,
            })
        }
      })
  }


  // Suscribir=()=>{
  //   let self =this;
  //   self.setState({
  //     loader: true,
  //     dimmerActive:true,
  //     existCard:false,
  //   });
  //   axios.post(Direccion+'/pagarSuscripcion',{idUser:firebase.auth().currentUser.uid,tipo:"Cliente",idCard:this.state.idCard,
  //   expiry:this.state.expiry,cvv:this.state.cvc,idPaquete:parseInt(this.state.idPaquete)})
  //   .then(res=>{
  //     if(res.data==='OK'){
  //       self.setState({
  //         dimmerActive: false,
  //         loader: false,
  //       });
  //       window.location.reload();
  //     }
  //     else if(res.data === "P101"){
  //       self.setState({
  //         dimmerActive: false,
  //         loader: false,
  //       });
  //       alert("complete your profile information");
  //     }
  //   })
  // }

  tomarFree=()=>{
    let self =this;
    self.setState({
      loader: true,
      dimmerActive:true,
      existCard:false,
    });
    axios.post(Direccion+'/FreeSuscripcion',{NuevaSuscripcion:true,idUser:firebase.auth().currentUser.uid,tipo:"Cliente",idPaquete:1})
    .then(res=>{
      if(res.data==='OK'){
        self.setState({
          dimmerActive: false,
          loader: false,
           openAlert:true,
           AlertMessage:'subscription updated successfully',
           AlertType:'positive',
        });
        setTimeout(function() {window.location.reload();}, 3000);
      }
      else if(res.data === "P101"){
        self.setState({
          dimmerActive: false,
          loader: false,
           openAlert:true,
           AlertMessage:'complete your profile information',
           AlertType:'warning',
        });
      }else if(res.data === "340"){
        self.setState({
          dimmerActive: false,
          loader: false,
           openAlert:true,
           AlertMessage:'you are Banned',
           AlertType:'error',
        });
      }
      else{
        self.setState({
          dimmerActive: false,
          loader: false,
           openAlert:true,
           AlertMessage:'something went wrong',
           AlertType:'error',
        });
      }
    })
  }

  show=(paquete,e)=>{
    var self=this;
    axios.post(Direccion+`/tomarCards`,
      {idUser:firebase.auth().currentUser.uid,tipo:"Cliente"})
      .then(res => {
        if (res.data !=0 && res.data.length >0) {
          self.setState({
            Cards:res.data,
            existCard:true,
            idPaquete:paquete,
          })
        }
        else {
          self.setState({
            comprar: true,
          })
        }
    })
  }
  handleInputChange = (e) => {
    const target = e.target;
    if (target.name === 'expiry') {
      var value=target.value;
      if (/^([0-9-/])*$/.test(target.value) ){
          if(value.length===2 ){
            this.setState({
              [target.name]:value.replace(/ |\//g, '')+'/',
            });
          }
          else{
            this.setState({
              [target.name]:value,
            });
          }
      }
    }else if (target.name === 'cvc'){
      var value=target.value;
      if (/^([0-9])*$/.test(target.value)){
        this.setState({
          [target.name]:value,
        });
      }

    }
  }//cierre handle

  selectCard = (key) => this.setState({ idCard:key,cardSelect: true });
  returnCard = () => this.setState({ cardSelect: false });
  close = () => this.setState({ comprar: false });
  close2 = () => this.setState({ existCard: false });
  comprar = () => this.setState({ comprar: true,existCard: false });

  render(){
    const { open, dimmer } = this.state
    return(

      <div>
      <Dimmer page id="DimmerSuscripcion" active={this.state.dimmerActive}>
       <Loader size='large'>Loading...</Loader>
       </Dimmer>
      <Divider horizontal style={{paddingTop:'4vh', }}>
        <Header as='h3'>
          <i class="material-icons" style={{ fontSize: '2em' }}>card_membership</i>
           CEIF Premium Plans
        </Header>
      </Divider>

      <Modal  size='small' dimmer={this.state.dimmerDos} open={this.state.existCard} onClose={this.close2}>
        <Header icon='archive' content='Use existing card' />
        <Modal.Content>
          {this.state.cardSelect?
        <div>
          <Form.Group id="ModalExpiracion">
            <Form.Input
                type='tel'
                name="expiry"
                placeholder='Expiration date'
                value={this.state.expiry}
                onChange={this.handleInputChange}
                maxLength={5}
              onFocus={this.handleInputFocus}
              />
            <Form.Input
                  type='tel'
                  name="cvc"
                  placeholder='Security code'
                  value={this.state.cvc}
                  onChange={this.handleInputChange}
                  maxLength={3}
                onFocus={this.handleInputFocus}/>
          </Form.Group>
        </div>:
        <div>
          {  this.state.Cards.map((it,index,key)=>{
            return(<ListaCard  GetCardsCallback={this.selectCard} datos={it}/>)
            })
          }
        </div>
      }
        </Modal.Content>
        <Modal.Actions>
        <Button  color='red' inverted onClick={this.close2}>
          <Icon name='remove' />'Cancel'
        </Button>
        <Button color='green' inverted  disabled={!this.state.expiry||!this.state.cvc} onClick={this.Suscribir}>
          <Icon name='checkmark' /> "Accept"
        </Button>
        </Modal.Actions>
      </Modal>
      <Modal basic size='small' dimmer={this.state.dimmerDos} open={this.state.comprar} onClose={this.comprar}>
        <Header icon='archive' content='Register a card' />
        <Modal.Content>
          <p>in order to buy a membership, you must first register a credit or debit card</p>
        </Modal.Content>
        <Modal.Actions>
        <Button color='green' inverted   onClick={this.comprar}>
          <Icon name='checkmark' /> "Accept"
        </Button>
        </Modal.Actions>
      </Modal>



        <div className='header-perfil'>
          <Card.Group style={{marginLeft: 'auto', marginRight: 'auto',textAlign: 'center'}}>

            <Card style={{textAlign:"center", width:'20%', minWidth:'250px', margin:' 1em 2.5em'}}>
              <Card.Content>
                <div className="ImgPaqueteContent">
                  <Image size='small' style={{display:'block', marginLeft: 'auto', marginRight: 'auto',textAlign: 'center'}}src='../imagenes/Premium1.png' />
                </div>

                <Card.Header>Basic</Card.Header>
                <Card.Meta>access to the CEIF platform</Card.Meta>


                <div className="contentPrice">
                  <Card.Header id="PricePaquete" >$0.00</Card.Header>
                  <Card.Header id="PriceDivisa" >USD/year*</Card.Header>
                </div>

              </Card.Content>
              <Card.Content extra>
                <Button  id='ButtonCompra' style={{width:'100%',backgroundColor:'rgb(232, 133, 10)'}}secondary disabled={this.state.Paquete1Enable} circular={true} onClick={this.tomarFree}>Buy Now</Button>
              </Card.Content>
            </Card>
          </Card.Group>
        </div>

      </div>
    )
  }
}



class ListaCard extends Component{
  constructor(){
    super()

  }

  selected=()=>{
  this.props.GetCardsCallback(this.props.datos.key);
  }

  render(){
    return(

      <div>
        <List  id="listaCards" animated verticalAlign='middle'>

          <List.Item onClick={this.selected} id="ItemCards">

            {this.props.datos.displayNumber.substring(0, 1) === '4'?
              <div>
              <Image  size='tiny' src='../imagenes/visa.png' />
              </div>:
              <div>
              <Image size='tiny' src={'../imagenes/Mastercard.png'}  />
              </div>
            }
            <List.Content id='ContentCards'>
              <List.Header>{this.props.datos.displayNumber}</List.Header>
              <List.Description>{this.props.datos.HolderCuenta}</List.Description>
            </List.Content>
          </List.Item>
        </List>
      </div>
    )
  }
}



export default PaquetesUsuario;

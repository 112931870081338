
function DeterminarMensaje(error,){
let mensaje = '';
switch(error){
  case 'P101':
    mensaje = 'your profile information is incomplete';

  break;
  case 'P1':
    mensaje = 'your country is not well written';

  break;
  case '101':
    mensaje = 'expiration date or cvv is incorrect';

  break;
  case '202':
    mensaje = 'payment error';

  break;
  case '505':
    mensaje = 'the subscription does not exist';
  break;
  default:
  mensaje = 'unknown error';
  break;
}
  return mensaje;

}



exports.DeterminarMensaje=DeterminarMensaje;

import React, { Component } from 'react';
import axios from 'axios';
import FinalizadoProceso from '../scripts/filtrarProcesoFinalizados.js';

import {Direccion} from '../../strings/peticiones.js';
import { Menu,Form, Segment,Divider,Image,Item,Header,Icon,Modal,Rating,Grid,Card,Button,Label } from 'semantic-ui-react'
import BarraLateral from './sideBarAdmin.js';
import firebase from 'firebase'
import Particles from 'react-particles-js';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import './styleAdmin/pagosAdmin.css';

class pagosAdmin extends Component{
  constructor(){
    super()

  }


  render(){
    return(

      <div>
      <BarraLateral/>
      <RutasDetalle/>
      </div>
    )
  }
}



class RutasDetalle extends Component{
  constructor(){
    super();

    this.state={
      tarifaFija:'',
      tarifaMinima:'',
      tarifaCeif:'',
      tarifaPagos:'',
      UsuarioAdmin:'',
      PassAdminNew:'',
      loading:false,
    }
  }

  UNSAFE_componentWillMount(){
      this.getComisionesAdmin();
      this.getUserAdmin();
  }

  getComisionesAdmin=()=>{
    let datos= {};
    let self = this;
    self.setState({loading:true,})
    try{
      axios.post(Direccion+`/getComisionesAdmin`,{idUser:firebase.auth().currentUser.uid})
        .then(res => {
          datos = res.data;
          self.setState({
            tarifaFija:datos.tarifaFija!=undefined?datos.tarifaFija:'0',
            tarifaMinima:datos.tarifaMinima!=undefined?datos.tarifaMinima:'0',
            tarifaCeif:datos.tarifaCeif!=undefined?datos.tarifaCeif:'0',
            tarifaPagos:datos.tarifaPagos!=undefined?datos.tarifaPagos:'0',
            loading:false,
          })
        })
    }catch(e){
      console.log(e);
    }
  }


  updateComisiones=()=>{
    let datos= {};
    let self = this;
    self.setState({loading:true,})
      try{
      axios.post(Direccion+`/updateComisionesAdmin`,{tarifaFija:this.state.tarifaFija,tarifaMinima:this.state.tarifaMinima,tarifaCeif:this.state.tarifaCeif,tarifaPagos:this.state.tarifaPagos})
        .then(res => {
          datos=res.data;
          console.log(datos);
          self.setState({
            loading:false,
          })
        })
    }catch(e){
      console.log(e);
    }
  }

  getUserAdmin=()=>{
    let datos= {};
    let self = this;
    self.setState({loading:true,})
    try{
      axios.post(Direccion+`/getUserAdmin`,{codigoValidacion:'C1E2IFC8'})
        .then(res => {
          console.log(res.data);
          datos = res.data;
          self.setState({
            UsuarioAdmin:datos.UsuarioAdmin,
          })
        })
    }catch(e){
      console.log(e);
    }
  }

updatePassWordAdmin=()=>{
  var user = firebase.auth().currentUser;
  let self = this;
  if (this.state.PassAdminNew.length >6) {
    user.updatePassword(this.state.PassAdminNew).then(function() {
      alert('contraseña actualizada');
      self.setState({PassAdminNew:''});
    }).catch(function(error) {
      alert('error : '+error);
      self.setState({PassAdminNew:''});
    });
  }
  else{
    alert('contraseña mayor a 6 digitos');
  }
}

  onChangetarifaFija=(e)=>{
    this.setState({
      tarifaFija:e.target.value
    })
  }
  onChangetarifaMinima=(e)=>{
    this.setState({
      tarifaMinima:e.target.value
    })
  }
  onChangetarifaCeif=(e)=>{
    this.setState({
      tarifaCeif:e.target.value
    })
  }
  onChangetarifaPagos=(e)=>{
    this.setState({
      tarifaPagos:e.target.value
    })
  }



  render(){
    return(
      <div className='header-rutas'>
        <div style={{position:'relative',height:'200px'}}>
          <div  className='headerBanner'>
            <i class="material-icons" style={{ fontSize: '2em' }}>attach_money </i>
            Configuraciones
          </div>
          <div style={{position:'relative',height:'200px'}}>
            <Particles style={{backgroundImage:'url(../imagenes/head5.jpg)', backgroundSize:'cover',backgroundPosition:'center', position:'absolute',height:'500px'}}
                 params={{
                   "particles": {
                       "number": {
                           "value": 80
                       },
                       "size": {
                           "value": 2
                       }
                   },
                   "interactivity": {
                       "events": {
                           "onhover": {
                               "enable": true,
                               "mode": "repulse"
                           }
                       }
                   }
               }} />
          </div>
        </div>

        <div style={{margin:'0px auto'}}>
           <Form style={{ textAlign:'center'}}>
            <h3>Comisiones Activas</h3>
              <Form.Group style={{ textAlign:'center',justifyContent:'center'}}>
                <Form.Input fluid label="Tarifa fija"  value={this.state.tarifaFija} onChange={this.onChangetarifaFija} readonly/>
                <Form.Input fluid label="Tarifa minima"  value={this.state.tarifaMinima} onChange={this.onChangetarifaMinima}  readonly/>
                <Form.Input fluid label="Tarifa Ceif"  value={this.state.tarifaCeif} onChange={this.onChangetarifaCeif}  readonly/>
                <Form.Input fluid label="Tarifa Pagos"  value={this.state.tarifaPagos} onChange={this.onChangetarifaPagos}  readonly/>
              </Form.Group>
               <Button color='orange' onClick={this.updateComisiones} loading={this.state.loading} disable={this.state.loading} type='submit'>Actualizar</Button>
          </Form>
        </div>
        <Divider/>
        <div style={{margin:'0px auto'}}>
           <Form style={{ textAlign:'center'}}>
            <h3>Cuenta admin</h3>
              <Form.Group style={{ textAlign:'center',justifyContent:'center'}}>
                <Form.Input disabled fluid label="Usuario"  value={this.state.UsuarioAdmin}  readonly/>
                <Form.Input fluid label="Nueva Contraseña"  value={this.state.PassAdminNew} onChange={(e)=>this.setState({PassAdminNew:e.target.value})}  readonly/>
              </Form.Group>
              {!this.state.PassAdminNew.length?
                <div></div>
                :
                <Button color='orange' onClick={this.updatePassWordAdmin} loading={this.state.loading} disable={this.state.loading || !this.state.PassAdminNew} type='submit'>Actualizar contraseña</Button>
              }
          </Form>
        </div>
      </div>
    )
  }
}


class CardRecibos extends Component{

  constructor(){
    super();

    this.state ={
      openModal:false,
      openConfirm:false,
      datosBancarios:[]
    }
  }


   show=()=>{
    this.setState({
      openModal:!this.state.openModal,
    })
  }
AceptarPagoBefore=()=>{
    this.setState({
      openConfirm:!this.state.openConfirm,
    })
  }

  AceptarPagoAfter=()=>{
       axios.post(Direccion+`/AprobarPagoAdminRecibo`,{idEnvio:this.props.datos.idTransaccion,idUser:this.props.datos.Cliente,idVendedor:this.props.datos.Vendedor,Precio:this.props.datos.precio})
        .then(res => {
          if (res.data === 'OK') {
            window.location.reload();
          }
        })
  }

  render(){
    let datos = this.props.datos;
    let datosBancarios=this.state.datosBancarios;
    return(
      <div>
        <Card onClick={this.show} style={{borderRadius:'2vh', width:'90%', minWidth:'250px', margin:' 1em 2.5em'}}>
          <Card.Content>
              <div style={{width:"100%", display:"inline-flex"}}>
                <div style={{ width:"25%"}}>
                  <Card.Meta>Amount</Card.Meta>
                  <Card.Header>{datos.precio}</Card.Header><br/>
                  <Card.Meta>id Transaction</Card.Meta>
                  <Card.Header>{datos.idTransaccion}</Card.Header><br/>
                </div>
                <div style={{marginLeft:"10%", width:"30%"}}>
                  <Card.Meta>Status</Card.Meta>
                  <Card.Header>{datos.Status}</Card.Header><br/>

                  <Card.Meta>payment request date</Card.Meta>
                  <Card.Header>{datos.FechaSolicitudPago}</Card.Header><br/>
                </div>
              </div>
          </Card.Content>

        </Card>

            <Modal  open={this.state.openModal} onClose={this.show}>
              <div style={{margin:'0px auto', textAlign:'center'}}>
                <div style={{width:"100%", margin:'0px auto',textAlign:'center'}}>
                  <div>
                    <Header.Subheader>Amount</Header.Subheader>
                    <Header>{datos.precio}</Header><br/>
                  </div>
                  <div>
                    <Header.Subheader>Transaction</Header.Subheader>
                    <Header>{datos.idTransaccion}</Header><br/>
                  </div>
                </div>
                <div style={{margin:'0px auto', width:'40%',marginBottom: '50px',backgroundColor: '#f1f1f1',padding: '20px',borderRadius: '10px',}}>
                  <img style={{margin:'0px auto', width:'100%'}} src={datos.FotoReciboPago} />
                 <Button color='orange' inverted  onClick={() => window.location.href=datos.FotoReciboPago}>
                   <Icon name='download' /> "Descargar Recibo"
                 </Button>
                </div>
           </div>
           <Modal.Actions>
             <Button  color='red' inverted onClick={this.show}>
               <Icon name='remove' />'Cancel'
             </Button>
             <Button color='green' inverted  onClick={this.AceptarPagoBefore}>
               <Icon name='checkmark' /> "Aprobar pago"
             </Button>
           </Modal.Actions>
         </Modal>


            <Modal basic open={this.state.openConfirm} onClose={this.AceptarPagoBefore}>
            <div style={{margin:'0px auto', textAlign:'center'}}>
              Are you sure you want to approve this payment?
           </div>
           <Modal.Actions>
             <Button  color='red' inverted onClick={this.AceptarPagoBefore}>
               <Icon name='remove' />'Cancel'
             </Button>
             <Button color='green' inverted  onClick={this.AceptarPagoAfter}>
               <Icon name='checkmark' /> "Aprobar"
             </Button>
           </Modal.Actions>
         </Modal>
      </div>
    )
  }
}


class CardPagosUser extends Component{

  constructor(){
    super();
    this.state={
      bancarios:[]
    }
  }
  UNSAFE_componentWillMount(){
    axios.post(Direccion+`/tomarBanks`,{idUser:this.props.datos.idVendedor,tipo:this.props.datos.tipoUsuario})
     .then(res => {
       if (res.data !==false ) {
         this.setState({datosBancarios:res.data})
       }
     })
  }

   show=()=>{
    this.props.selectCard({datos:this.props.datos,datosBancarios:this.state.datosBancarios});

  }

  render(){

    let datos = this.props.datos;
    console.log(datos.PagoOrigen);
    return(

      <div>

        <Card onClick={this.show} style={{borderRadius:'2vh', width:'90%', minWidth:'250px', margin:' 1em 2.5em'}}>
          <Card.Content>

              <div style={{width:"100%", display:"inline-flex"}}>

                <div style={{ width:"25%"}}>
                  <Card.Meta>Transaction ID</Card.Meta>
                  <Card.Header>{datos.key}</Card.Header><br/>

                  <Card.Meta>date payment</Card.Meta>
                  <Card.Header>{datos.dateTime}</Card.Header><br/>

                  <Card.Meta>Payment type</Card.Meta>
                  <Card.Header>
                  {datos.PagoOrigen === 1?'Envio'
                    :datos.PagoOrigen === 2?'Suscripcion'
                    :datos.PagoOrigen === 3?'Cancelacion'
                    :''}
                  </Card.Header><br/>

                </div>

                <div style={{marginLeft:"10%", width:"30%"}}>
                  <Card.Meta>Amount</Card.Meta>
                  <Card.Header>${datos.Amount} USD</Card.Header><br/>

                  <Card.Meta>Id Product</Card.Meta>
                  <Card.Header>{datos.idRecibo}</Card.Header><br/>
                </div>
                <div style={{marginLeft:'auto',marginRight:'0'}}>
                  {datos.Pagado?
                    <div className='IconoRight'>
                    <Icon.Group size='big'>
                        <Icon style={{marginLeft:'23%'}} color='teal' size='big' name='circle outline' />
                        <Icon style={{marginLeft:'23%'}} color='teal'  name='checkmark' />
                    </Icon.Group>
                    <h4 style={{marginRight:'2%',marginTop:'-2%',color:'teal'}}>Completed</h4>

                    </div>:
                    <div className='IconoRight'>
                        <Icon style={{marginLeft:'25%'}} color='grey' size='big'  name='payment' />
                        <h4 style={{marginTop:'-2%', color:'grey'}}>Pending</h4>
                    </div>
                  }
                  {datos.PagoType === 'abono'?
                    <Icon style={{marginLeft:'23%'}} color='red' size='big'  name='angle down' />:<Icon style={{marginLeft:'23%'}} color='teal' size='big'  name='angle up' />
                  }
                </div>
              </div>
          </Card.Content>

        </Card>
      </div>
    )
  }
}


export default pagosAdmin;

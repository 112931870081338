import React, { Component } from 'react';
import { Message,Menu,Popup,Icon } from 'semantic-ui-react';
import {Router, Route, BrowserRouter, Switch, Link} from 'react-router-dom'
import './MenuPublico.css';

class MenuPublico extends Component{
  constructor(){
    super()
    this.state = {

    }
  }

  render(){
    return(
      <div className='MenuContent'>
        <div className='Logo'>
          <Link to="/">
            <img src='../imagenes/ceif-logo.png'/>
          </Link>
        </div>
        <div className='MenuItemContent'>
          <ul className='col5'>
            <li>
              <Link to="/About">
                  <p>About Us</p>
              </Link>
            </li>
            <li>
              <Link to="/Membership">
                  <p>Membership</p>
              </Link>
            </li>
            <li>
              <Link to="/Faqs">
                  <p>FAQ’s</p>
              </Link>
            </li>
            <li>
              <Link to="/Tutorials">
                  <p>Tutorials</p>
              </Link>
            </li>
            <li>
              <Link to="/Contact">
                  <p>Contact Us</p>
              </Link>
            </li>
          </ul>
        </div>
        <div className='Menu rigth'>
          <div className='btnLogin'>
            <Link to="/login">
              <p>Sign in</p>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default MenuPublico;

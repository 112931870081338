import React, { Component,useState, useEffect } from 'react';
import MenuPublico from '../ComponentesGenerales/Menus/MenuPublico.js';
import Footer from '../ComponentesGenerales/Footer/Footer.js';
import {Icon,Rating} from 'semantic-ui-react';
import './Catalogo.css';
import BarraLateral from '../ComponentsSeller/sideBar.js';

const imagenPortada = 'https://blogthinkbig.com/wp-content/uploads/sites/4/2018/07/V%C3%ADa-L%C3%A1ctea-portada-espacio-universo.jpg?resize=1200%2C428'
const imagenPerfil ='https://le-cdn.website-editor.net/87a89659c39d444d8263e7f319955774/dms3rep/multi/opt/CEIF+Logo+Facebook-320w.png'

export default function PerfilPublico (props){
  useEffect(()=>{
    // props.match.params.id
  })
  return(
    <div className='PerfilPublico'>
      <BarraLateral/>
      <section className='seccion0'>
        <div className='imagenPortadaPublico' style={{backgroundImage:`url(${imagenPortada})`}}>
          <div className='ContentImagenPerfilPublico'>
            <div className='imagenPerfilPublico' style={{backgroundImage:`url(${imagenPerfil})`}}></div>
          </div>
        </div>
        <div className='contentInfoHeader'>
          <h3>Nombre</h3>
          <p>Ubat.com</p>
            <div className='contentPerfilHeaterItem'>
              <div className='perfilHeaderITem'>
                <Icon color='red' name='marker'/>
                <p>Jalisco, Mexico</p>
              </div>
              <div className='perfilHeaderITem'>
                <Icon color='red' name='facebook'/>
                <p>@Ubat_Mx</p>
              </div>
              <div className='perfilHeaderITem'>
                <Icon color='red' name='linkedin'/>
                <p>@Ubat_Mx</p>
              </div>
            </div>
        </div>
      </section>
      <section className='seccion1'>
        <div className='ContentContenido'>
          <div className='seccionLeft'>
            <div className='infoSeccion'>
              <div className='perfilHeaderITem'>
                <Icon color='red' name='phone'/>
                <p>33134032883</p>
              </div>
              <div className='perfilHeaderITem'>
                <Icon color='red' name='user'/>
                <p>CEO Emmanuel Garcia Martinez</p>
              </div>
              <div className='perfilHeaderITem'>
                <Icon color='red' name='mail'/>
                <p>emgarcia24@hotmail.com</p>
              </div>
              <div className='rateContent'>
                <p className='numberRate'>4.5</p>
                <div className='contentStart'>
                  <Rating icon='star' defaultRating={4.5} maxRating={5} />
                  <p>Gold user</p>
                </div>
              </div>
            </div>


              <div className='btnSendMessage'>
                Send Message
              </div>
          </div>
          <div className='seccionRight'>

          </div>
        </div>
      </section>
    </div>

  )
}

import React, { Component } from 'react';
import {Router, Route, BrowserRouter, Switch, Link} from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';
import axios from 'axios';
import {Direccion,DireccionFront} from '../../strings/peticiones.js';
import FaceIcon from '@material-ui/icons/Face';
import Particles from 'react-particles-js';
import Badge from '@material-ui/core/Badge';
import DeterminarImagen from '../scripts/imagenes.js';

import {Fieldset} from 'primereact/fieldset';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Divider, Header, Icon,Rating,Dimmer,List,Loader,Table,Accordion,Modal,Button,Dropdown,Popup,Label,Grid,Form,Input,Image,Segment,Message} from 'semantic-ui-react';
import { Card } from 'semantic-ui-react'
import { DatePicker } from 'material-ui-pickers';
import BarraLateral from './sideBarAdmin.js';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FinalizadoProceso from '../scripts/filtrarProcesoFinalizados.js';
import FiltrarTiemposStatus from '../scripts/fechasStatus.js'



import firebase from 'firebase'



const styles = theme=>({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  }

})




class EnviosAdmin extends Component{
  constructor(){
    super()
    var date = new Date();
    let month = date.getMonth()+1;
    let day = date.getDate();
      if (month <10) {
          month = "0"+month;
        }
      if (day<10) {
        day = "0"+day;
      }

    this.state={
      valueTabs:0,
      open: false,
      dimmer:true,
      selectedDate: date,
      fechaFormulario1:'2019-01-01',
      fechaFormulario2:date.getFullYear()+'-'+month +'-'+day,
      pagados:[],
      proceso:[],
      cancelados:[],
      usuario:{},
      userToSearch:'',
      usuarios:[],
      todosEnvios:[],
      resultadoBusqueda:[],
      searching:false,
    }
  }

  UNSAFE_componentWillMount(){
    let self =this;
    let perfiles=[];
    axios.post(Direccion+`/totalUsuarios`,
      {tipoPeticion:'Normalizar',tipo:'Vendedor'})
      .then(res => {
        perfiles=res.data;
        console.log(perfiles);
          self.setState({
            usuarios:perfiles
          })
        })
    this.getEnvios();
  }

  selectUser=(e,{value})=>{
    console.log(value);
    this.setState({userToSearch:value},()=>{
      this.getEnvios();
    })
  }

  getEnvios(){
    try{
      var notificaciones=JSON.parse(localStorage.getItem('notificaciones'));
      let datos;
      let self=this;
      let pagos=[];
      this.setState({
        searching:true,
      })
      axios.post(Direccion+`/tomarEnviosVendedor`,{data:{usuario:this.state.userToSearch,inicio:this.state.fechaFormulario1,fin:this.state.fechaFormulario2}})
          .then(res => {
          datos=res.data;
          console.log(datos);
          pagos=FinalizadoProceso.cantidadDiferencia(datos);
          self.setState({
            todosEnvios:datos,
            pagados:pagos.terminados,
            proceso:pagos.proceso,
            cancelados:pagos.cancelados,
            notificaciones:notificaciones,
            searching:false,
          })
        })
    }catch(e){
      console.log(e);
    }
  }


  handleDateChange=(date)=>{

    let fecha=date.target.value;

     this.setState({
       fechaFormulario1:fecha
     })
   }
   handleDateChange2=(date)=>{
     let fecha=date.target.value;

      this.setState({
        fechaFormulario2:fecha
      })
    }

   handleChangeTab=(event, valueTabs)=>{
       this.setState({ valueTabs });
     };

  buscarEnvio=(e)=>{
       let textToSearch = e.target.value;
       let EnvioEncontrado= [];
       if (e.target.value.length === 0) {
         this.setState({
           busquedaActiva:false,
         })
       }else{
         this.state.todosEnvios.forEach((it)=>{
           let contenedor = it.contenedor=== 'Aereo'?'Air': it.contenedor;

          if(textToSearch===it.idTransaccion || it.origen.includes(textToSearch) || it.destino.includes(textToSearch) || contenedor.includes(textToSearch) ||textToSearch===it.origen||textToSearch===it.destino ||textToSearch===contenedor ){
            EnvioEncontrado.push(it);
          }
          else if(this.compareTwoStrings(textToSearch,it.origen)>0.3 || this.compareTwoStrings(textToSearch,it.destino)>0.3 || this.compareTwoStrings(textToSearch,contenedor)>0.3){
            EnvioEncontrado.push(it);
          }
         })
         this.setState({
           resultadoBusqueda:EnvioEncontrado,
           busquedaActiva:true,
         })
       }
     }
  compareTwoStrings=(strA,strB)=>{
         for(var result = 0, i = strA.length; i--;){
             if(typeof strB[i] === 'undefined' || strA[i] === strB[i]);
             else if(strA[i].toLowerCase() === strB[i].toLowerCase())
                 result++;
             else
                 result += 4;
         }
         return 1 - (result + 4*Math.abs(strA.length - strB.length))/(2*(strA.length+strB.length));
     }
  render(){
    const { classes } = this.props;

    return(


      <div className="div-principal-envios-usuario">

        <BarraLateral/>
        <div className='paticulasContent' >
          <Particles id='Paticulas' style={{backgroundImage:'url(https://ceif.app/imagenes/envios.jpg)', backgroundSize:'cover',backgroundPosition:'center',height:'600px',position:'absolute'}}
               params={{
                 "particles": {
                     "number": {
                         "value": 80
                     },
                     "size": {
                         "value": 2
                     }
                 },
                 "interactivity": {
                     "events": {
                         "onhover": {
                             "enable": true,
                             "mode": "repulse"
                         }
                     }
                 }
             }} />
           <div className='contentTitulo'>
             <div className='titulo'>
                 <i class="material-icons" style={{ fontSize: '2em' }}>directions_boat </i>
                 <h3>Operations</h3>
             </div>
           </div>
          <div>
            <div className="contentBuscador">
              <Dropdown
                  placeholder='Select user'
                  fluid
                  selection
                  options={this.state.usuarios}
                  style={{width:'80%',margin:'15px auto'}}
                  onChange={this.selectUser}
                  value={this.state.userToSearch}
                />
              <div className="Buscador" >

                <input type="text" onChange={this.handleDateChange} placeholder='From' onFocus={(e)=> e.currentTarget.type = "date"} onBlur={(e)=> {e.currentTarget.type = "text"; e.currentTarget.placeholder = "From"}}/>
                <input type="text" onChange={this.handleDateChange2} placeholder='to' onFocus={(e)=> e.currentTarget.type = "date"} onBlur={(e)=> {e.currentTarget.type = "text"; e.currentTarget.placeholder = "to"}}/>
                <div  className='btnBuscarFecha' onClick={this.getEnvios.bind(this)} >Search</div>
              </div>
              <div className='buscadorPorID'>
                <input  onChange={this.buscarEnvio} type="text" placeholder="Search..."/>
              </div>
            </div>
          </div>
        </div>

        <Tabs value={this.state.valueTabs} onChange={this.handleChangeTab} variant="fullWidth"  indicatorColor="secondary" textColor="primary">
            <Tab icon={<i class="material-icons">compare_arrows</i>} label="In transit" ></Tab>
            <Tab icon ={<i class="material-icons">done_outline</i>} label="Completed"></Tab>
            <Tab icon ={<i class="material-icons">highlight_off</i>} label="Canceled"></Tab>

        </Tabs>
        {this.state.searching?
          <div>
            <div className="spinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          </div>
          :
          <div>
            {this.state.resultadoBusqueda && this.state.busquedaActiva?
              <div>
                {
                  this.state.resultadoBusqueda.map((it,key)=>{
                    return(<CardEnviosUser  usuario={this.state.usuario} totalData={this.state.resultadoBusqueda} notificaciones={this.state.notificaciones} recargarDatos={this.getEnvios.bind(this)} datos={it} key={key} />)
                  })
                }
              </div>:
              <div>
                {this.state.valueTabs===0?
                  <div>
                  {
                    this.state.proceso.map((it,key)=>{
                      return(<CardEnviosUser usuario={this.state.usuario} notificaciones={this.state.notificaciones} datos={it} key={key}/>)
                    })

                  }
                  </div>
                  :
                  <div></div>
                }
                {this.state.valueTabs===1?
                  <div>
                  {
                    this.state.pagados.map((it,key)=>{
                      return(<CardEnviosUser usuario={this.state.usuario} notificaciones={this.state.notificaciones} datos={it} key={key}/>)
                    })

                  }
                  </div>
                  :
                  <div></div>
                }
                {this.state.valueTabs===2?
                  <div>
                  {
                    this.state.cancelados.map((it,key)=>{
                      return(<CardEnviosUser usuario={this.state.usuario} notificaciones={this.state.notificaciones} datos={it} key={key}/>)
                    })

                  }
                  </div>
                  :
                  <div></div>
                }
              </div>
            }
          </div>
        }
      </div>

    )
  }
}


class CardEnviosUser extends Component{

  constructor(){
    super();

    this.state={
      open: false,
      openfinalizado: false,
      dimmer:true,
      files: [],
      badge:true,
      num:0,
      Cards:[],
      usuario:{},
      openconfirm:false,
      CardIndex:0,
      confirmRate:false,
      rate:0,
      Pagado:false,
    }

    this.UsingCard=this.UsingCard.bind(this);

  }

  UNSAFE_componentWillMount(){
  this.Inicio();
  }


  Inicio=()=>{
    var num=0;
    let self = this;
    let perfil ={};
    console.log(this.props.datos);
    self.setState({
      Pagado:this.props.datos.pagado,
    })

          axios.post(Direccion+`/tomarCards`,
            {Paquete:null,idClienteOpenPay:this.props.usuario,})
            .then(res => {
              if (res.data.length !=0 && res.data.length >0) {
                self.setState({
                  Cards:res.data,
                })
              }
              else {
                self.setState({
                  Cards:[],
                })
              }

          })

    this.props.notificaciones.forEach((it)=>{
      if(it.idTransaccion===this.props.datos.idTransaccion){
        num++;
      }
    });
    if(num>0){
      this.setState({
        badge:false,
        num:num
      })
    }
  }

  searchId(){
    this.props.notificaciones.forEach((it)=>{
      if(it.idTransaccion===this.props.datos.idTransaccion){
        axios.post(Direccion+`/RefreshNotificacion`,{idNotificacion:it.key,tipo:'Cliente'})
          .then(res => {
            var datos=res.data;
            if(datos!='OK'){
              alert('error');
            }
          })
      }
    })
  }

  confimar=()=>{
    this.setState({  openconfirm: true, })
  }


  UsingCard=(index)=>{
    this.setState({  CardIndex: index, })
  }

  Pagar=()=>{
    this.setState({  dimmerActive: true,openconfirm:false,open:false })
    let self =this;
    axios.post(Direccion+`/PagarEnvio`,
      {Cliente:firebase.auth().currentUser,DatosEnvio:this.props.datos,TarjetaPagar:this.state.Cards[this.state.CardIndex].id})
      .then(res => {
        if (res.data === "OK") {
          self.Inicio();
          this.setState({  dimmerActive: false, })
          window.location.reload()

        }
        else {
          alert(res.data);
          this.setState({  dimmerActive: false, })

        }
    })
  }

  Calificar=()=>{
    let self = this;
    this.setState({  dimmerActive: true,confirmRate:false, open:false })
    axios.post(Direccion+`/ConfirmarPago`,
      {idClienteOpenPay:this.props.usuario,Cliente:firebase.auth().currentUser,DatosEnvio:this.props.datos})
      .then(res => {
        if (res.data === "OK") {
          this.setState({  dimmerActive: false, })

        }
        else {
          this.setState({  dimmerActive: false, })

          alert(res.data);
        }
    })
  }



  show = dimmer => () =>{
    this.setState({ dimmer, open: true,badge:true })
    this.searchId();
  }
  showFinalizado = dimmer => () => this.setState({ dimmer, openfinalizado: true })

  close = () =>{
     this.setState({ open: false, openfinalizado: false });

  }

  handleRate =(e, { rating, maxRating })=>{
    this.setState({ confirmRate: true, rate:rating});
  }

  close2 = () =>{
     this.setState({ openconfirm: false,});
  }


  closeRate= () =>{
     this.setState({  confirmRate: false,});
  }
  render(){
    const { open, dimmer } = this.state


    let datos=this.props.datos;
    let archivos=datos.archivos;
    let statusList=datos.StatusList;
    let solicitudPago=datos.solicitudPago===true?true:false;
    let arrayArchivos;
    let ListaStatus;
    if (datos.Comisiones === undefined) {datos.Comisiones = 0}
    var PrecioSNComision = (parseFloat(datos.precio) - parseFloat(datos.Comisiones)).toFixed(2);

    if(archivos!=null){
      arrayArchivos = Object.keys(archivos).map(key => {
        let ar = archivos[key]
        ar.key = key
        return ar
      })
    }
    else{
      arrayArchivos=[];
    }

    if(statusList!=null){
      ListaStatus = Object.keys(statusList).map(key => {
        let ar = statusList[key]
        ar.key = key
        return ar
      })
    }
    else{
      ListaStatus=[];
    }

    let TipoMedida = datos.Medidas?datos.Medidas:datos.containerSize;

    let imagen=DeterminarImagen.DeterminarImagen(datos.contenedor,TipoMedida,datos.containerType);

    return(

      <div>

      <Dimmer style={{height:'200%'}} id="DimmerSuscripcion" active={this.state.dimmerActive}>
       <Loader size='large'>Loading...</Loader>
       </Dimmer>
      <Modal  id={"ModalPay"} dimmer={this.state.dimmerDos} open={this.state.openconfirm} onClose={this.close2}>
        <Modal.Header id={"ModalPayHeader"}>
          <Icon name='archive' />
        Usar Tarjeta ya existente
        </Modal.Header>
        <Modal.Content id={"ModalPayContent"}>
        <p id={"ModalPayP"}>
          Selecciona la tarjeta para realizar el pago?
        </p>
        <List selection id={"ModalPayList"} >
        {
          this.state.Cards.map((it,index,key)=>{
            return(<ListaCard index={index} UsingCallback={this.UsingCard} datos={it} />  )

          })

        }

        </List>

        </Modal.Content>
        <Modal.Actions>
          <Button id={'ModalPayButtonCancel'} color='red' inverted onClick={this.close2}>
            <Icon name='remove' />Cancelar
          </Button>
          <Button positive id={'ModalPayButtonAcept'}  onClick={this.Pagar}>
            <Icon name='checkmark' /> Aceptar
          </Button>
        </Modal.Actions>
      </Modal>


      <Modal  id={"ModalPay"} dimmer={this.state.dimmerDos} open={this.state.confirmRate} onClose={this.closeRate}>
        <Modal.Header id={"ModalPayHeader"}>
          <Icon name='archive' />
        Calificar Vendedor
        </Modal.Header>
        <Modal.Content id={"ModalPayContent"}>
        <p style={{marginLeft:"25%"}}>
          Seguro que esta es tu calificacion?
        </p>
        <Rating style={{marginLeft:'33%'}} icon='star' size='huge' maxRating={5} rating={this.state.rate}  />
        </Modal.Content>
        <Modal.Actions>
          <Button id={'ModalPayButtonCancel'} color='red' inverted onClick={this.closeRate}>
            <Icon name='remove' />Cancelar
          </Button>
          <Button positive id={'ModalPayButtonAcept'}  onClick={this.Calificar}>
            <Icon name='checkmark' /> Aceptar
          </Button>
        </Modal.Actions>
      </Modal>



      <Card onClick={this.show(true)} style={{textDecoration:'none',fontSize:'.9em', borderRadius:'2vh', width:'95%', minWidth:'250px', margin:' 1em 2.5em'}}>
          <div className='TransaccionID'>
            <p>Transaction : {datos.idTransaccion}</p>
          </div>
          <Card.Content id='contentCardVenddor'>

            <div className='contentImgEnvio'>
              {datos.contenedor==='LCL'||datos.contenedor==='LTL-Truck'||datos.contenedor==='Aereo'?
                <img src={DireccionFront+'/imagenes/pallet.png'} />
                :
                <img src={DireccionFront+'/imagenes/'+imagen} />
              }
            </div>

            <div className='InfoContent'>
              <div className='TitleEnvio'>
                <span>
                  {datos.contenedor === 'Aereo'?'Air':datos.contenedor==='FCL-Ferro'?'FCL-FFCC':datos.contenedor}
                </span>
                <span>
                  Freigth
                </span>
                <span>
                  {datos.origen+ '   ->  '}
                </span>
                <span>
                  {datos.destino}
                </span>
              </div>
              <div className='descriptionContent'>
                <p className='ContenidoEnvio'>Contain: {datos.contenido} {datos.IMOCargo?<span className="CargoIMO" >IMO</span>:''} </p>
                <div className='ContentPriceEnvio'>

                  <span className='PagoEnvio'>{'$'+PrecioSNComision+' USD'}</span>
                  <span className='TipoPagoEnvio'>{datos.Payment}</span>
                </div>
                <div className='MedidasEnvio'>
                  <span className='CantidadEnvio'>
                  Quality/Measure:
                  </span>
                  <span className='ContenidoPeso'>
                  {datos.cantidad} x {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?datos.medidasLargo+'cm  + '+datos.medidasAncho+'cm  + '+datos.medidasAlto+'cm':datos.containerSize+'  '+datos.containerType}
                  </span>
                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='PesoEnvio'>Weight:</span>:''}
                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='ContenidoPeso'>{datos.Peso+' kg'}</span>:''}
                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='PesoEnvio'>CBM:</span>:''}
                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='ContenidoPeso'>{parseFloat(datos.CBM).toFixed(2)+' CBMs'}</span>:''}
                </div>
              </div>
            </div>
            <div className='StatusContentEnvio'>
                {datos.Status === 'Peticion'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon  color='grey' size='big'  name='payment' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>Pending</h4>
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                  </div>:<div></div>
                }
                {datos.Status === 'Aceptado'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon  color='blue' size='big'  name='check' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>Accepted</h4>
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                  </div>:<div></div>
                }
                {datos.Status === 'Proceso'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon  color='yellow' size='big'  name='ship' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>in Process</h4>
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                    <div className="clear"></div>
                    {datos.solicitudPago?
                      <div style={{margin:'0px auto',textAlign:'center'}}>
                      <Icon  color='blue' size='big'  name='money' />
                      <h4 style={{marginTop:'0px', color:'grey'}}>Payment required</h4>
                      </div>:<div></div>
                    }

                  </div>:<div></div>
                }
                {datos.Status === 'Pagado'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon color='green' size='big'  name='payment' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>Paid out</h4>
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                  </div>:<div></div>
                }
                {datos.Status === 'Finalizado'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon  color='green' size='big'  name='computer' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>Payment released</h4>
                    {!datos.pagado?
                      <div>
                      <Icon  color='yellow' size='large'  name='clock outline' />
                      <h4 style={{marginTop:'0px', color:'grey'}}>CEIF is preparing your payment</h4>
                      </div>:<div></div>
                    }
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                  </div>:<div></div>
                }
                {datos.Status === 'Cancelado'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon  color='red' size='big'  name='ban' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>Cancelled</h4>
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                  </div>:<div></div>
                }
            </div>

          </Card.Content>
          {datos.Cancelacion?
            <div className='CancelarcionMsj'>
              <Card.Header>You have {datos.Cancelacion} hrs for cancellation without any cancellation charge</Card.Header>
              <Card.Meta>otherwise you will be charged {datos.MontoCancelacion} dlls</Card.Meta>
            </div>:
            <div className='CancelarcionMsj'>
            <Card.Meta style={{fontSize:'15px'}}>Your shipment has not yet been accepted, you can cancel without charges</Card.Meta>
            </div>
          }
      </Card>

      <Modal dimmer={this.state.dimmer} open={this.state.open} onClose={this.close} style={{width:'80%'}}>
        <div className='TransaccionID'>
          <p>Transaction : {datos.idTransaccion}</p>
        </div>
        <Card.Content id='contentCardModalVendedor'>

          <div className='contentImgEnvio'>
          {datos.contenedor==='LCL'||datos.contenedor==='LTL-Truck'||datos.contenedor==='Aereo'?
            <img src={DireccionFront+'/imagenes/pallet.png'} />
              :
              <img src={DireccionFront+'/imagenes/'+imagen} />
            }
          </div>

          <div className='InfoContent'>
            <div className='TitleEnvio'>
              <span>
              {datos.contenedor === 'Aereo'?'Air':datos.contenedor==='FCL-Ferro'?'FCL-FFCC':datos.contenedor}
              </span>
              <span>
                Freigth
              </span>
              <span>
                {datos.origen+ '   ->  '}
              </span>
              <span>
                {datos.destino}
              </span>
            </div>
            <div className='descriptionContent'>
              <div>
                <p className='ContenidoEnvio'>Contain: {datos.contenido}</p>{datos.IMOCargo?<span className='CargoIMO'>IMO</span>:''}
              </div>
              <div className='ContentPriceEnvioModal'>
                <span className='PagoEnvioModal'>{'$'+PrecioSNComision+' USD'}</span>
                <span className='TipoPagoEnvio'>{datos.Payment}</span>
              </div>
              <div className='MedidasEnvio'>
                <span className='CantidadEnvio'>
                Quality/Measure:
                </span>
                <span className='ContenidoPeso'>
                  {datos.cantidad} x {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?datos.medidasLargo+'cm  + '+datos.medidasAncho+'cm  + '+datos.medidasAlto+'cm':datos.containerSize+'  '+datos.containerType}
                </span>
                {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='PesoEnvio'>Weight:</span>:''}
                {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='ContenidoPeso'>{datos.Peso+' kg'}</span>:''}
                {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='PesoEnvio'>CBM:</span>:''}
                {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='ContenidoPeso'>{parseFloat(datos.CBM).toFixed(2)+' CBMs'}</span>:''}
              </div>
              </div>
          </div>
        </Card.Content>
        <Divider/>
        <div className="StatusEnvioUsuario">
          <div className="statusContainer">
              <span className='routeShipping'>
              <span className='routeIcon'>
                <Icon  name='shipping'className={datos.objServicios.PO?'icono active':'icono'}  />
              </span>
              <span className={datos.objServicios.PO?'line active':'line'}>
                <Icon id={datos.objServicios.PO?'dot-left-active':'dot-left'} name='dot circle outline' />
                <Icon id={datos.objServicios.CO?'dot-right-active-blue':'dot-right'} name='dot circle outline' />
              </span>
              </span>
              <span className='routeShipping'>
                <span className='routeIcon'>
                <Icon  name='ship' className='icono activeBlue'  />
                </span>
                <span className='line activeBlue'></span>
              </span>
              <span className='routeShipping'>
                <span className='routeIcon'>
                <Icon  name='shipping'className={datos.objServicios.PD?'icono active':'icono'}  />
                </span>
                <span className={datos.objServicios.PD?'line active':'line'}>
               <Icon id={datos.objServicios.CD?'dot-left-active-blue':'dot-left'} name='dot circle outline' />
               <Icon id={datos.objServicios.PD?'dot-right-active':'dot-right'} name='dot circle outline' />
               </span>
              </span>
          </div>
          <div className="StatusTags">
            <div className={datos.objServicios.PO?'itemStatusUsuario activo':'itemStatusUsuario'}>
              <p>Local Pick Up</p>
            </div>
            <div className={datos.objServicios.CO?'itemStatusUsuario activo':'itemStatusUsuario'}>
              <p>Origin Charges</p>
            </div>
            <div className={datos.objServicios.CD?'itemStatusUsuario activo':'itemStatusUsuario'}>
              <p>Dest. Charges</p>
            </div>
            <div className={datos.objServicios.PD?'itemStatusUsuario activo':'itemStatusUsuario'}>
              <p>Local Delivery</p>
            </div>
          </div>
        </div>


        {datos.Status != 'Peticion'?
          <div>
            <Divider/>
            <Card.Content>
              <Fieldset style={{marginTop:'30px'}} className="Seccion2Modal" legend="BL details" toggleable={true}>

                 <div style={{width:"100%",minWidth:'900px',maxHeight:'20vh', display:"inline-flex"}}>
                   <div style={{paddingLeft:"9%",minWidth:'20vh'}}>
                   <Card.Meta className='labelMini'>BL Number</Card.Meta>
                   <Card.Header>{datos.numeroBL}</Card.Header><br/>
                   </div>

                   <div style={{paddingLeft:"9%",minWidth:'20vh'}}>
                     <Card.Meta className='labelMini'>Vessel name</Card.Meta>
                     <Card.Header>{datos.nombreBarco}</Card.Header><br/>
                   </div>

                   <div style={{paddingLeft:"9%",minWidth:'25vh'}}>
                   <Card.Meta className='labelMini'>Voyage</Card.Meta>
                   <Card.Header>{datos.numeroViaje}</Card.Header><br/>
                   </div>

                   <div style={{paddingLeft:"9%",minWidth:'25vh'}}>
                   <Card.Meta className='labelMini'>Booking ref</Card.Meta>
                   <Card.Header>{datos.numeroBooking}</Card.Header><br/>
                   </div>

                 </div>

              </Fieldset>
            </Card.Content>
            <Divider/>
          </div>
          :<div></div>
        }

        <div className="content-section implementation">
          <Fieldset legend="Booking details" toggleable={true}>
              <Card.Content style={{marginLeft:'2%'}}>
                <div className="Booking-details"  style={{width:"100%",maxHeight:'20vh'}}>
                  <h4>Booking details</h4>
                  <div style={{width:"100%",maxHeight:'20vh',display:"inline-flex"}}>
                    <div style={{paddingLeft:"4%",width:'22%'}}>
                      <Card.Meta className='labelMini'>Cargo description</Card.Meta>
                      <Card.Header>{datos.nombreProducto}</Card.Header><br/>
                    </div>
                    <div style={{paddingLeft:"4%",width:'22%'}}>
                      <Card.Meta className='labelMini'>Pick-up date</Card.Meta>
                      <Card.Header>{datos.fechaRecoleccion}</Card.Header><br/>
                    </div>
                    <div style={{paddingLeft:"4%",width:'22%'}}>
                      <Card.Meta className='labelMini'>Comments</Card.Meta>
                      <Card.Header>{datos.observacionesContacto}</Card.Header><br/>
                    </div>
                    <div style={{paddingLeft:"4%",width:'22%'}}>
                      <Card.Meta className='labelMini'>Hs Code</Card.Meta>
                      <Card.Header>{datos.hsCode}</Card.Header><br/>
                    </div>
                  </div>
                </div>
                <div className="Shipper-details" style={{width:"100%",maxHeight:'20vh'}}>
                  <h4>Shipper details</h4>
                  <div style={{width:"100%",maxHeight:'20vh', display:"inline-flex"}}>
                    <div style={{paddingLeft:"4%",width:'22%'}}>
                      <Card.Meta className='labelMini'>Company´s name</Card.Meta>
                      <Card.Header>{datos.NombreShipper}</Card.Header><br/>

                      <Card.Meta className='labelMini'>Contact name</Card.Meta>
                      <Card.Header>{datos.contactNameShipper}</Card.Header><br/>
                    </div>
                    <div style={{paddingLeft:"4%",width:'22%'}}>
                      <Card.Meta className='labelMini'>Phone </Card.Meta>
                      <Card.Header>{datos.TelefonoShipper}</Card.Header><br/>

                      <Card.Meta className='labelMini'>Email</Card.Meta>
                      <Card.Header>{datos.EmailShipper}</Card.Header><br/>
                    </div>
                    <div style={{paddingLeft:"4%",width:'22%'}}>
                      <Card.Meta className='labelMini'>Address</Card.Meta>
                      <Card.Header>{datos.addressShipper}</Card.Header><br/>

                      <Card.Meta className='labelMini'>Vat/TAX ID/RFC</Card.Meta>
                      <Card.Header>{datos.taxIdShipper}</Card.Header><br/>
                    </div>
                  </div>
                </div>
                <div className="Consignee-details" style={{width:"100%",maxHeight:'20vh'}}>
                  <h4>Consignee details</h4>
                  <div style={{width:"100%",maxHeight:'20vh', display:"inline-flex"}}>
                    <div style={{paddingLeft:"4%",width:'22%'}}>
                      <Card.Meta className='labelMini'>Company´s name</Card.Meta>
                      <Card.Header>{datos.NombreConsig}</Card.Header><br/>
                    </div>
                    <div style={{paddingLeft:"4%",width:'22%'}}>
                      <Card.Meta className='labelMini'>Contact name</Card.Meta>
                      <Card.Header>{datos.contactNameConsignee}</Card.Header><br/>
                    </div>
                    <div style={{paddingLeft:"4%",width:'22%'}}>
                      <Card.Meta className='labelMini'>Phone </Card.Meta>
                      <Card.Header>{datos.TelefonoConsig}</Card.Header><br/>
                    </div>
                    <div style={{paddingLeft:"4%",width:'22%'}}>
                      <Card.Meta className='labelMini'>Email</Card.Meta>
                      <Card.Header>{datos.EmailConsig}</Card.Header><br/>
                    </div>
                    <div style={{paddingLeft:"4%",width:'22%'}}>
                      <Card.Meta className='labelMini'>Address</Card.Meta>
                      <Card.Header>{datos.addressConsignee}</Card.Header><br/>
                    </div>
                  </div>
                </div>
                <div className="Notify" style={{width:"100%",maxHeight:'20vh'}}>
                  <h4>Notify:</h4>
                  <div style={{width:"100%",maxHeight:'20vh', display:"inline-flex"}}>
                    <div style={{paddingLeft:"4%",width:'22%'}}>
                      <Card.Meta className='labelMini'>Name</Card.Meta>
                      <Card.Header>{datos.NombreNotificar}</Card.Header><br/>
                    </div>
                    <div style={{paddingLeft:"4%",width:'22%'}}>
                      <Card.Meta className='labelMini'>Phone </Card.Meta>
                      <Card.Header>{datos.TelefonoNotificar}</Card.Header><br/>
                    </div>
                    <div style={{paddingLeft:"4%",width:'22%'}}>
                      <Card.Meta className='labelMini'>Email</Card.Meta>
                      <Card.Header>{datos.EmailNotificar}</Card.Header><br/>
                    </div>
                  </div>
                </div>
                <Divider/>
                <div className="cancelContent">
                  {datos.Status === 'Peticion'?
                    <div>
                        <div className='contentBtnCancelacion'>
                          <Button id="btnAceptBooking"  primary >Accept Booking</Button>
                        </div>
                    </div>:<div></div>
                  }
                  {datos.Status === 'Aceptado'?
                      <div>
                        <Message icon>
                          <Icon name='check' color='green' accept />
                          <Message.Content>
                            <Message.Header>lets do it</Message.Header>
                            you can start managing the shipment
                          </Message.Content>
                        </Message>
                      </div>:<div></div>
                  }
                  {datos.solicitudPago?
                    <div style={{width:'90%'}}>
                    <Divider></Divider>
                      <Grid columns={2} stackable textAlign='center'>
                        <Grid.Row verticalAlign='middle'>
                          <Grid.Column>
                            <img src={DireccionFront+'/imagenes/bank.png'} style={{width:'60%'}}/>
                          </Grid.Column>
                          <Grid.Column>
                            <div>

                              {datos.Status === 'Proceso'?
                                  <div>
                                    <Card style={{width:'100%'}}>
                                      <Card.Content>
                                        <Card.Meta>{datos.nombreBanco}</Card.Meta>
                                        <Card.Header>{datos.bankPago}</Card.Header>
                                        <Card.Header>{datos.holderCuenta}</Card.Header>
                                        <Card.Description><p><strong>Amount:</strong> ${PrecioSNComision} USD<br/></p></Card.Description>
                                      </Card.Content>
                                    </Card>
                                    <Message icon>
                                      <Icon name='circle notched' loading />
                                      <Message.Content>
                                        <Message.Header>Payment Will be requested {datos.fechaSolicitudPago}</Message.Header>
                                          Payment deadline {datos.DiasPago} days to complete your payment.
                                      </Message.Content>
                                    </Message>
                                  </div>:<div></div>
                              }
                              {datos.Status === 'Pagado'?
                                <div>
                                  <Card style={{width:'100%'}}>
                                    <Card.Content>
                                      <Card.Meta>CEIF</Card.Meta>
                                      <Card.Header>Your money is safe with us</Card.Header>
                                      <Card.Description><p><strong> we are waiting for the users rating </strong></p></Card.Description>
                                      <Card.Description><p><strong> To release the payment </strong></p></Card.Description>

                                    </Card.Content>
                                  </Card>
                                </div>:<div></div>
                              }
                              {datos.Status === 'Finalizado'?
                                <div>
                                  {this.props.datos.Rate?
                                    <div>
                                    {datos.pagado?
                                      <Card style={{width:'100%'}}>
                                        <Card.Content>
                                          <Card.Meta>CEIF</Card.Meta>
                                          <Card.Header>Your payment has been successfully transferred</Card.Header>
                                          <Card.Description><p><strong>Remember that payments take 3 to 5 business days to be reflected in your account.</strong></p></Card.Description>
                                          <Card.Description><p>For more information about deposits and commissions visit:</p></Card.Description>
                                          <Card.Description><p><a>Deposits and Commissions</a></p></Card.Description>

                                            <Icon color='teal' size='huge' style={{marginLeft:'2%'}} name='checkmark' />

                                        </Card.Content>
                                      </Card>:
                                       <Card style={{width:'100%'}}>
                                        <Card.Content>
                                          <Card.Meta>CEIF</Card.Meta>
                                          <Card.Header>Your payment has succesfuly confirmed</Card.Header>
                                          <Card.Description><p><strong>The customer has qualified and your payment will be released</strong></p></Card.Description>
                                          <Card.Description><p>For more information about deposits and commissions visit:</p></Card.Description>
                                          <Card.Description><p><a>Deposits and Commissions</a></p></Card.Description>

                                            <Icon color='teal' size='huge' style={{marginLeft:'2%'}} name='checkmark' />

                                        </Card.Content>
                                      </Card>
                                    }
                                    </div>:
                                    <div>
                                      <Card style={{width:'100%'}}>
                                        <Card.Content>
                                          <Card.Meta>CEIF</Card.Meta>
                                          <Card.Header>Your money is safe with us</Card.Header>
                                          <Card.Description><p><strong>Rank the operation</strong></p></Card.Description>
                                          <Rating icon='star' size='huge' maxRating={5}  />

                                        </Card.Content>
                                      </Card>
                                    </div>
                                  }
                                </div>:<div></div>
                              }
                            </div>
                          </Grid.Column>
                        </Grid.Row>

                      </Grid>
                    </div>
                    :<div></div>
                  }
                </div>
              </Card.Content>

            </Fieldset>
        </div>

        {datos.Status != 'Peticion'?
          <div style={{width:'95%',padding:'3vh'}}>


            <AcordionStatus data={ListaStatus}/>
            <label style={{textAlign:'left',margin:'20px 99px 0'}}> Files</label>
            <div style={{width:'85%',padding:'3vh'}}>
             <Grid columns={2}>

               <Grid.Column style={{overflowY:'scroll'}} width={12}>

                 {
                   arrayArchivos.map((it,key)=>{
                     return(<Chips data={it}/>)
                   })

                 }



               </Grid.Column>
               <Grid.Column width={4}>
               <Image style={{width:'100%',paddingLeft:'10%'}}  src={DireccionFront+'/imagenes/world.png'} />
               </Grid.Column>
             </Grid>
            </div>
          </div>
          :
          <div></div>
        }

        <Modal.Actions>

          <Button
            positive
            icon='checkmark'
            labelPosition='right'
            content="Accept"
            onClick={this.close}
          />
        </Modal.Actions>
      </Modal>



      </div>
    )
  }
}

class AcordionStatus extends Component{
  state = { activeIndex: 1,datos:[{Tipo:'',FechaHora:'',TiempoProceso:'',TiempoAcumulado:'',Status:'',Causa:'',Observaciones:''}]}

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }
  UNSAFE_componentWillMount(){
    let self=this;
    axios.post(Direccion+'/getHours')
    .then(res=>{
      var datos=FiltrarTiemposStatus(this.props.data.reverse(),res.data);
        self.setState({
          datos:datos
        })

    })
  }

  render(){
    const { activeIndex } = this.state
    var data=this.state.datos;
    var length=data.length-1;
    return(
      <Accordion style={{width:'100%', marginTop:'30px'}} styled>
        <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
        <Icon name='dropdown' />

          <Table sortable celled fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Cargo type</Table.HeaderCell>
                <Table.HeaderCell>Date-Time</Table.HeaderCell>
                <Table.HeaderCell>Time in process</Table.HeaderCell>
                <Table.HeaderCell>Elapsed time</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Reason</Table.HeaderCell>
                <Table.HeaderCell>Comments</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
           <Table.Row>
             <Table.Cell>{data[0].Tipo}</Table.Cell>
             <Table.Cell>{data[0].FechaHora}</Table.Cell>
             <Table.Cell>{data[0].TiempoProceso} Hrs</Table.Cell>
             <Table.Cell>{data[0].TiempoAcumulado} Hrs</Table.Cell>
             <Table.Cell>{data[0].Status}</Table.Cell>
             <Table.Cell>{data[0].Causa}</Table.Cell>
             <Table.Cell>{data[0].Observaciones}</Table.Cell>
           </Table.Row>
           </Table.Body>

          </Table>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>

        <Table sortable celled fixed>
          <Table.Body>
          {
            data.map((it,key)=>{
              if(key>0){
                return(
                  <Table.Row>
                    <Table.Cell>{it.Tipo}</Table.Cell>
                    <Table.Cell>{it.FechaHora}</Table.Cell>
                    <Table.Cell>{it.TiempoProceso} Hrs</Table.Cell>
                    <Table.Cell>{it.TiempoAcumulado} Hrs</Table.Cell>
                    <Table.Cell>{it.Status}</Table.Cell>
                    <Table.Cell>{it.Causa}</Table.Cell>
                    <Table.Cell>{it.Observaciones}</Table.Cell>
                  </Table.Row>

                )
            }
            })
          }


          </Table.Body>
        </Table>
        </Accordion.Content>

      </Accordion>
    )
  }
}


class Chips extends Component{

  constructor(){
    super();
  }

  handleDelete() {
    window.open(this.props.data.archivoUrl);
  }

  render(){
    let nombre=this.props.data.nombre.length>28?this.props.data.nombre.substring(0,27):this.props.data.nombre;
    return(

      <Chip
        icon={<Icon name='file text' size='large' style={{color:'white'}}/> }
        label={nombre}
        clickable
        style={{width:'40vh',marginBottom:'1vh'}}
        color="primary"
        onDelete={this.handleDelete.bind(this)}
        deleteIcon={<Icon name='cloud download' size='large' style={{color:'white'}} />}
      />
    )
  }
}



EnviosAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
};



class ListaCard extends Component{
  constructor(){
    super()
    this.state = {
    datos:[],
    dimmerActive:false,
    idClienteOpenPay:'',
    }
    this.selected=this.selected.bind(this);



  }

  UNSAFE_componentWillMount=()=>{

  }

  selected=(e,value)=>{
    console.log(this.props);
    this.props.UsingCallback(this.props.index);


  }


  render(){
    return(



        <List.Item  onClick={this.selected.bind(this)}  id="ItemCards">

        {this.props.datos.brand === 'visa'?
          <div>
          <Image  size='tiny' src='../imagenes/visa.png' />
          </div>:
          <div>
          {this.props.datos.brand === 'masterCard'?
            <div>
            <Image size='tiny' src={'../imagenes/Mastercard.png'}  />

            </div>:
            <div>
            <Image size='tiny' src={'../imagenes/AmericanExpress.png'}  />

            </div>
          }
          </div>

        }
          <List.Content id='ContentCards'>


            <List.Header >{this.props.datos.card_number}</List.Header>
            <List.Description >{this.props.datos.type}</List.Description>
          </List.Content>
        </List.Item>

    )
  }
}



export default withStyles(styles)(EnviosAdmin);

import React, { Component } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Link} from 'react-router-dom'

class ListaNotificaciones extends Component{
  constructor(){
    super()
    this.state = {
    }

  }

  render(){
    return(
      <List>

      {
        this.props.datos.map((it,key)=>{
          return(<React.Fragment><Notificacion datos={it} key={key}/>
          <Divider variant="inset" component="li" /></React.Fragment>)
        })

      }



    </List>

    )
  }
}

class Notificacion extends Component{

  constructor(){
    super();
  }


  render(){
    var datos=this.props.datos;
    var imagen;
    var direccion;
    var mensaje;
    if(datos.tipo!='solicitudRegistro'||datos.tipo!='solicitudCambioCuenta'){
      mensaje=" — Esto en tu envio "+datos.idTransaccion;
    }
    else{
      mensaje='Apruebalo o rechazalo';
    }
    if(datos.tipo==='pago'){
        imagen='../imagenes/payReq.png';
        direccion='/admin/pagos';
    }
    else if(datos.tipo==='archivo'){
      imagen='../imagenes/files.png';
      direccion='/seller/Envios';

    }
    else if(datos.tipo==='status'){
      imagen='../imagenes/status.png';
      direccion='/seller/Envios';

    }
    else if(datos.tipo==='booking'){
      imagen='../imagenes/bldetails.png';
      direccion='/seller/Envios';

    }
    else if (datos.tipo==='solicitudRegistro') {
      imagen='../imagenes/request.png';
      direccion='/admin/usuarios';
    }
    else if(datos.tipo==='solicitudCambioCuenta'){
      imagen='../imagenes/request.png';
      direccion='/admin/usuarios';
    }

    return(
      <Link to={direccion}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Remy Sharp" src={imagen} />
          </ListItemAvatar>
          <ListItemText
            primary={datos.descripcionEspañol}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"

                  color="textPrimary"
                >
                { datos.hora+' '+datos.fecha}
                </Typography>
                {
                  mensaje
                }

              </React.Fragment>
            }
          />
        </ListItem>
      </Link>

    )
  }
}


export default ListaNotificaciones;


const espanol={
  titulo:'Rates',
  dimmer:"Upload data",
  Sections:{
    maritimo01:"Ocean (LCL)",
    maritimo02:"Ocean (FCL)",
    maritimo03:"Ocean (BREAK BULK)",
    aereo:"Air",
    ferro:"F.F.C.C",
    terre:"Truck",
  },
  RutaSimple:{
    origen:"Origin",
    destino:"Destination",
    origenCity:"City origin",
    destinoCity:"City destination",
    trafico:"Trafic",
    servicio:"Service",
    frecuencia:"Frequency",
    Frecuencia:"Frequency",
    tiempo:"T. time",
    Tarifa:'Rate (USD)',
    TarifaSingle:'Single Rate',
    TarifaFull:'Full Rate',
    tarifaMin:"Rate min (USD)",
    tarifaWM:"Rate W/M(USD)",
    Insurance:"Insurance",
    PickUp:"PickUp",
    EbsWM:"Ebs W/M",
    TsVgm:"TS VGM(USD)",
    RecargoIMO:"IMO Surcharge",
    Stuffing:"Stuffing",
    Ams:"Ams",
    Bl:"Bl",
    PtfWM:"Ptf W/M",
    Vgm:"Vgm",
    OceanFreight:"Ocean Freight",
    AdminFee:"Admin Fee",
    ElectTrans:"Elect Trans",
    DocFee:"Doc Fee",
    PortTransFee:"Port Trans Fee",
    CustomsFee:"Customs Fee",
    InlandTransport:"Inland Transport",
    expiracion:"Expiration date",
    bttnAgregar:"Agregar",
    pais:"Country",
    CraneTariff:'Crane Tariff',
    TarifaAerea:'Air Rates',
    ThcOrigen:'Origin Thc',
    ThcDestino:'Destination Thc',
    Fsc:'Fsc',
    Tax:'Tax',
    HandlingOrigin:'Handling Origin',
    HandlingOrigen:'Origin Handling',
    HandlingDestino:'Destination Handling',
    LocalDelivery:'Local Delivery',
    Awb:'Awb',
    AirFreight:'Air Freight',
    CustomFee:'Custom Fee',
    ImportDuties:'Import Duties',
    AirportTaxes:'Airport Taxes',
    Handling:'Handling',
    Unstuffing:'Unstuffing',
    CargoDelivery:'Cargo Delivery',
    OnCarriage:'On Carriage',
    ExportDocs:'Export Docs',
    ThcMx:' Thc MX',
    StoragePday:'Storage p/day',
    Inland:'Inland',
    ContainerType:'Container Type',
    ContainerSize:'Container Size',
    BlFee:'Bl Fee',
    Thc:'Thc',
    Buc:'Buc',
    Dre:'Dre',
    Cnr:'Cnr',
    Isp:'Isp',
    ImportCustomFee:'Import Custom Fee',
    TerminalHandling:'Terminal Handling',
    ContainerCleaning:'Container Cleaning',
  },
  ButtonsExcel:{
    subir:"Load file excel",
    bajar:"Download form"
  },
  Tab:{
    Activas:'Active rates',
    NoActivas:'Expired rates'
  },
  HeaderTable:{

  },

};

export function Idioma(idioma){
  if(idioma === 'ES'){
    return espanol;
  }
}

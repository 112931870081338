import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import {ref,firebaseAuth} from '../const.js'
import firebase from 'firebase'
import {Idioma} from '../strings/loginText.js';
import axios from 'axios';
import {Direccion} from '../strings/peticiones.js';
//css
import './login.css';
import { Header,Button,Transition, Icon,Checkbox,Divider,Dimmer,Loader,Modal,Form, Card,Image } from 'semantic-ui-react';

//Alert

import { loadReCaptcha, ReCaptcha } from "react-recaptcha-google";
import AlertSnack from './notificacionCard.js';

import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);




const styles = {
  title: {
    cursor: 'pointer',
  },
  button:{
    color:'#2C434B'
  }
};

class Login extends Component{
  constructor(){
    super()
    this.state={
      clase:true,
      mail:'',
      password:'',
      name:'',
      drimmerActivo:false,
      registroUno:true,
      registroDos:false,
      text:{},
      openPaquetes:false,
      user:{},
      recaptchaCode:'',
      ready: false,
      openAlert:false,
      AlertMessage:'',
      AlertType:'',
      recuperarPassWord:false,

    }
    this.setState({
      text:Idioma('ES'),
    })
  }


  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.autenticar();
    }
  };

 autenticar =() =>{
   this.setState({
     drimmerActivo:true
   })
   let self = this;
         firebaseAuth.signInWithEmailAndPassword(this.state.email, this.state.password)
         .then(function(user){
           console.log(user);
           self.setState({
             drimmerActivo:false
           })
           if (self.props.callbackCLoseWindow) {
             self.props.callbackCLoseWindow();
           }
           var a = user;
         })
         .catch(function(error){
           var errorCode = error.code;
           var errorMessage = error.message;
           if (errorCode === 'auth/wrong-password') {
                  self.setState({
                     drimmerActivo:false,
                     openAlert:true,AlertType: 'error',titleAlert:"Error!",AlertMessage:'Your password is not correct, please try again.'

                   })
               }
           else if(errorCode==='auth/user-not-found'){
             self.setState({
               drimmerActivo:false,
               openAlert:true,AlertType: 'error',titleAlert:"Error!",AlertMessage:'User does not exist, create an account to enter'
             })
           }
           else {
                   alert(errorMessage);
                 }
            console.log(error);
         });
  }


  handleClick=()=>{
    this.setState({
      clase:!this.state.clase
    })
  }

  TipoRegistro =()=>{

    this.setState({ tipoUsuario:'Cliente' })
  }

  RegistroUno =()=>{

    axios.post(Direccion+`/know_user_email`,
      {mail:this.state.mail})
      .then(res => {
        if (res.data.correo === this.state.mail) {
          this.setState({
            openAlert:true,AlertType: 'error',titleAlert:"Error!",AlertMessage:'The user already exists, try another email'
          })
        }
        else{
          if (this.state.tipoUsuario === 'Vendedor') {
            this.setState({ registroUno:false })
          }
          else {
            this.setState({ registroDos:true })

          }

        }
      })
  }
  TipoRegistro2 =()=>{
    this.setState({ tipoUsuario:'Vendedor' })
  }

  CloseForm =()=>{
    this.setState({
       registroDos:false,
       registroUno:true,
       clase:!this.state.clase,
       mail:'',
       password:'',
    })
  }

  CloseAlert =()=>{
    this.setState({
       openAlert:false,
    })
  }


  resetAlert = ()=> this.setState({ openAlert:false,titleAlert: '', AlertMessage:'', AlertType:'',});
  sendEmailVerify=()=>{
    let self = this;
    this.setState({loading:true})
    if (this.state.emailRecover) {
      firebase.auth().sendPasswordResetEmail(this.state.emailRecover).then(function() {
        self.setState({registro:false,loading:false,openAlert:true,AlertType: 'success',titleAlert:"Success!",AlertMessage:'We sent an email to reset your password'})
      }).catch(function(error) {
        self.setState({loading:false,openAlert:true,AlertType: 'error',titleAlert:"Error!",AlertMessage:'Somethings wrong, write your email well'})
      });
    }else{
      self.setState({loading:false,openAlert:true,AlertType: 'error',titleAlert:"Error!",AlertMessage:'write your email'})

    }
  }

  render(){
    let text=Idioma('ES');
    let filtro=this.state.tipoUsuario==='Cliente'?'none':'grayscale(100%)';
    let filtro2=this.state.tipoUsuario==='Vendedor'?'none':'grayscale(100%)';
    let claseActual=this.state.clase ? 'cont':'cont s--signup';
    let open = this.state.openAlert;
    return(

      <div className='fondo' style={{marginTop:'5vh'}}>
      <Dimmer active={this.state.drimmerActivo} page>
      <Loader>
        <Header as='h2' icon inverted>
          Loading ....
        </Header>
      </Loader>
      </Dimmer>

        <div className={claseActual}>
          {this.state.recuperarPassWord?
            <div className="form sign-in">
              <div>
                <Icon onClick={()=>this.setState({recuperarPassWord:!this.state.recuperarPassWord})} name='arrow left'></Icon>
              </div>
              <h2 style={{margin:'0px auto'}}>Recover password</h2>
              <label>
                <span>Your email</span>
                <input type="email"  onChange={(e)=>this.setState({emailRecover:e.target.value})} value={this.state.emailRecover}/>
              </label>
              <button type="button" onClick={this.sendEmailVerify}className="submit">Recover</button>
            </div>
            :
            <div onKeyPress={this.handleKeyPress} className="form sign-in">
              <img className='logoLogin' src='./imagenes/LandingPage/logo.png'/>

              <h2 style={{margin:'0px auto'}}>Wellcome</h2>
              <label>

                <span>Email</span>
                <input type="email"  onChange={(e)=>this.setState({email:e.target.value})} value={this.state.email}/>
              </label>
              <label>
                <span>Password</span>
                <input type="password" onChange={(e)=>this.setState({password:e.target.value})} value={this.state.password}/>
              </label>
              <label >
                <span onClick={()=>this.setState({recuperarPassWord:!this.state.recuperarPassWord})} style={{color:'#50D2DA',cursor:'pointer'}}>Forgot your password?</span>
              </label>
              <button type="button" onClick={this.autenticar}className="submit">Login</button>
            </div>
          }


        <div className="sub-cont">
          <div className="img">
            <div className="img__text m--up">
              <h2 style={{color:'white',fontWeight:'700',margin:'0',marginBottom:'2vh',fontSize:'23px'}}>No Member yet?</h2>
              <p style={{color:'white'}}>Apply for membership and join our family</p>
            </div>
            <div className="img__text m--in">
              <h2 style={{color:'white',fontWeight:'700',margin:'0',marginBottom:'2vh',fontSize:'23px'}}>Already a member?</h2>
              <p style={{color:'white'}} >Are you a Family Member, Please Login, We miss you!</p>
            </div>

            <div className="img__btn" style={{width:'144px'}} onClick={this.handleClick}>
              <span className="m--up" >Apply now</span>
              <span className="m--in" onClick={this.handleClick}>Login</span>
            </div>
          </div>
            <div className="form sign-up">
              <h2>Be a part of our family</h2>

              <label>
                <span>Email</span>
                <input className="inputRegister" type="email" onChange={(e)=>this.setState({mailRegistro:e.target.value})} value={this.state.mailRegistro} />
              </label>
              <label>
                <span>Password</span>
                <input className="inputRegister" type="password" onChange={(e)=>this.setState({pass:e.target.value})} value={this.state.pass}/>
              </label>
              <Card.Group style={{marginTop:'2em',marginLeft:'5em'}}>
                <Card className="cardButton" onClick={this.TipoRegistro} style={{width:'140px', minWidth:'140px', margin:' 0em 2.5em'}}>
                  <Image src='./imagenes/comprador.png' style={{filter: filtro}} />
                  <Card.Content>
                    <Card.Header>CEIF Cargo User</Card.Header>
                  </Card.Content>
                </Card>
                <Card className="cardButton" onClick={this.TipoRegistro2} style={{width:'140px', minWidth:'140px', margin:' 0em 2.5em'}}>
                  <Image src='./imagenes/vendedor.png' style={{filter: filtro2}}/>
                  <Card.Content>
                    <Card.Header>CEIF Forwarder</Card.Header>
                  </Card.Content>
                </Card>
              </Card.Group>
              <button type="button" onClick={this.RegistroUno.bind(this)}className="submit2"
              disabled={!this.state.mailRegistro||!this.state.pass||!this.state.tipoUsuario}>Apply for Membership</button>

            </div>
            </div>

            <LoginFormulario Plataforma={this.state.checkedPlataforma} Directorio={this.state.checkedDirectorio} correo={this.state.mailRegistro} callbackCerrar={this.CloseForm}  tipo={this.state.tipoUsuario} openDos={this.state.registroDos} open={!this.state.registroUno}  pass={this.state.pass} />
        </div>
        <AlertSnack openAlert={this.state.openAlert} titleAlert={this.state.titleAlert} resetAlert={this.resetAlert} AlertMessage={this.state.AlertMessage} AlertType={this.state.AlertType}/>
      </div>

    )
  }
}


class LoginFormulario extends Component{
  constructor(){
    super()
    this.state = {
      modalReview:false,
      CompanyName:'',
      FirstName:'',
      LastName:'',
      Title:'',
      Mobil:'',
      Email:'',
      Phone:'',
      Fax:'',
      Website:'',
      EstablishmentYear:'',
      NoEmployees:'',
      NameOwners:'',
      Address:'',
      City:'',
      PostaleCode:'',
      State:'',
      Contry:'',
      CompanyRef1:'',
      ContactRef1:'',
      EmailRef1:'',
      CompanyRef2:'',
      ContactRef2:'',
      EmailRef2:'',
      CompanyRef3:'',
      ContactRef3:'',
      EmailRef3:'',
      RelationShip:'',
      open:false,
      dimmerActive:false,
      checkedPoliticas:false,
      checkedCondiciones:false,
      openAlert:false,
      AlertMessage:'',
      AlertType:'',
      ready:false,

    }
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  UNSAFE_componentWillMount=()=>{
    this.setState({
      open:this.props.open,
    })

  }
  recaptcha = React.createRef();


  handleChangePoliticas =()=>{
    this.setState({ checkedPoliticas:!this.state.checkedPoliticas })
  }
  handleChangeCondiciones =()=>{
    this.setState({ checkedCondiciones:!this.state.checkedCondiciones })
  }

  recargar(){
    window.location.reload();
  }
  registrar=()=>{
    let self= this;
    self.setState({
      dimmerActive:true,
    })
    if(this.state.ready){
      if (this.props.tipo === 'Vendedor') {
        axios.post(Direccion+`/pre-registro`,
          {mailRegistro:this.props.correo,clave:this.props.pass,tipo:this.props.tipo,CompanyName:this.state.CompanyName,
            FirstName:this.state.FirstName, LastName:this.state.LastName, Title:this.state.Title,  Mobil:this.state.Mobil,
            Email:this.props.correo, Phone:this.state.Phone, Fax:this.state.Fax, Website:this.state.Website,
            EstablishmentYear:this.state.EstablishmentYear, NoEmployees:this.state.NoEmployees, NameOwners:this.state.NameOwners,
            Address:this.state.Address, City:this.state.City, PostaleCode:this.state.PostaleCode, State:this.state.State,
            Contry: this.state.Contry, CompanyRef1:this.state.CompanyRef1,ContactRef1:this.state.ContactRef1,
            EmailRef1:this.state.EmailRef1, CompanyRef2:this.state.CompanyRef2, ContactRef2:this.state.ContactRef2,
            EmailRef2:this.state.EmailRef2, CompanyRef3:this.state.CompanyRef3, ContactRef3:this.state.ContactRef3,
            EmailRef3:this.state.EmailRef3, RelationShip:this.state.RelationShip,checkedPoliticas:this.state.checkedPoliticas,checkedCondiciones:this.state.checkedCondiciones,

          })


          .then(res => {
            if(res.data === "OK"){
              this.props.callbackCerrar();

              self.setState({
                nombre: "",
                tipoUsuario:"",
                CompanyName:'',
                FirstName:'',
                LastName:'',
                Title:'',
                Mobil:'',
                Email:'',
                Phone:'',
                Fax:'',
                Website:'',
                EstablishmentYear:'',
                NoEmployees:'',
                NameOwners:'',
                Address:'',
                City:'',
                PostaleCode:'',
                State:'',
                Contry:'',
                CompanyRef1:'',
                ContactRef1:'',
                EmailRef1:'',
                CompanyRef2:'',
                ContactRef2:'',
                EmailRef2:'',
                CompanyRef3:'',
                ContactRef3:'',
                EmailRef3:'',
                RelationShip:'',
                dimmerActive:false,
                modalReview:true
              })

            }
            else{
              self.setState({
                dimmerActive:false,
                openAlert:true,
                AlertMessage:'Ups, something went wrong ',
                AlertType:'warning',
              })
            }
          })
      }
      else if (this.props.tipo === 'Cliente') {
        let self= this;
        axios.post(Direccion+`/registro`,
          {mailRegistro:this.props.correo,clave:this.props.pass,tipo:this.props.tipo,
            CompanyName:this.state.CompanyName,FirstName:this.state.FirstName, LastName:this.state.LastName,  Mobil:this.state.Mobil,
            Email:this.props.correo, Address:this.state.Address, City:this.state.City, PostaleCode:this.state.PostaleCode, State:this.state.State,
            Contry: this.state.Contry, checkedPoliticas:this.state.checkedPoliticas, checkedCondiciones:this.state.checkedCondiciones
          })


          .then(res => {
            if(res.data === "OK"){
              this.props.callbackCerrar();
              self.setState({
                nombre: "",
                tipoUsuario:"",
                CompanyName:'',
                FirstName:'',
                LastName:'',
                Title:'',
                Mobil:'',
                Email:'',
                Address:'',
                City:'',
                PostaleCode:'',
                State:'',
                Contry:'',
                dimmerActive:false,
              })
            }
            else{
              if (res.data.errorMessage) {
                self.setState({
                  dimmerActive:false,
                  openAlert:true,
                  titleAlert:'Warning!',
                  AlertMessage:res.data.errorMessage,
                  AlertType:'warning',
                })
              }else{
                self.setState({
                  dimmerActive:false,
                  openAlert:true,
                  titleAlert:'Error!',
                  AlertType:'error',
                })
              }

            }
          })
      }
    }
    else{
      this.setState({
        dimmerActive:false,
        openAlert:true,
        AlertMessage:'you need to complete the reCAPTCHA',
        AlertType:'warning',
      })
    }


  }



    handleRefCompanyName=(e)=>{
      var valor=e.target.value;
      this.setState({
        CompanyName:valor
      })
    }
    handleRefFirstName=(e)=>{
      var valor=e.target.value;
      this.setState({
        FirstName:valor
      })
    }
    handleRefLastName=(e)=>{
      var valor=e.target.value;
      this.setState({
        LastName:valor
      })
    }
    handleRefTitle=(e)=>{
      var valor=e.target.value;
      this.setState({
        Title:valor
      })
    }
    handleRefMobil=(e)=>{
      var valor=e.target.value;
      this.setState({
        Mobil:valor
      })
    }
    handleRefEmail=(e)=>{
      var valor=e.target.value;
      this.setState({
        Email:valor
      })
    }
    handleRefPhone=(e)=>{
      var valor=e.target.value;
      this.setState({
        Phone:valor
      })
    }
    handleRefFax=(e)=>{
      var valor=e.target.value;
      this.setState({
        Fax:valor
      })
    }
    handleRefWebsite=(e)=>{
      var valor=e.target.value;
      this.setState({
        Website:valor
      })
    }
    handleRefEstablishmentYear=(e)=>{
      var valor=e.target.value;
      this.setState({
        EstablishmentYear:valor
      })
    }
    handleRefNoEmployees=(e)=>{
      var valor=e.target.value;
      this.setState({
        NoEmployees:valor
      })
    }
    handleRefNameOwners=(e)=>{
      var valor=e.target.value;
      this.setState({
        NameOwners:valor
      })
    }
    handleRefAddress=(e)=>{
      var valor=e.target.value;
      this.setState({
        Address:valor
      })
    }
    handleRefCity=(e)=>{
      var valor=e.target.value;
      this.setState({
        City:valor
      })
    }
    handleRefPostaleCode=(e)=>{
      var valor=e.target.value;
      this.setState({
        PostaleCode:valor
      })
    }
    handleRefState=(e)=>{
      var valor=e.target.value;
      this.setState({
        State:valor
      })
    }
    handleRefContry=(e)=>{
      var valor=e.target.value;
      this.setState({
        Contry:valor
      })
    }
    handleRefCompanyRef1=(e)=>{
      var valor=e.target.value;
      this.setState({
        CompanyRef1:valor
      })
    }
    handleRefContactRef1=(e)=>{
      var valor=e.target.value;
      this.setState({
        ContactRef1:valor
      })
    }
    handleRefEmailRef1=(e)=>{
      var valor=e.target.value;
      this.setState({
        EmailRef1:valor
      })
    }
    handleRefCompanyRef2=(e)=>{
      var valor=e.target.value;
      this.setState({
        CompanyRef2:valor
      })
    }
    handleRefContactRef2=(e)=>{
      var valor=e.target.value;
      this.setState({
        ContactRef2:valor
      })
    }
    handleRefEmailRef2=(e)=>{
      var valor=e.target.value;
      this.setState({
        EmailRef2:valor
      })
    }
    handleRefCompanyRef3=(e)=>{
      var valor=e.target.value;
      this.setState({
        CompanyRef3:valor
      })
    }
    handleRefContactRef3=(e)=>{
      var valor=e.target.value;
      this.setState({
        ContactRef3:valor
      })
    }
    handleRefEmailRef3=(e)=>{
      var valor=e.target.value;
      this.setState({
        EmailRef3:valor
      })
    }
    close=()=>{
      this.props.callbackCerrar();
    }
    componentDidMount() {
      loadReCaptcha();
      // console.log(window.grecaptcha);
      // this.recaptcha.current.reset();
    }
    onLoadRecaptcha() {
      if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback(recaptchaToken) {
      // Here you will get the final recaptchaToken!!!
      console.log(recaptchaToken);
      console.log(this.state);
      this.setState({ready:true})
    }
    resetAlert = ()=> this.setState({ openAlert:false,titleAlert: '', AlertMessage:'', AlertType:'',});

  render(){
    let text=Idioma('ES');
    return(
      <div>
      <Dimmer id="DimmerRegistro" active={this.state.dimmerActive}>
       <Loader size='large'>Upload data</Loader>
       </Dimmer>
       <AlertSnack openAlert={this.state.openAlert} titleAlert={this.state.titleAlert} resetAlert={this.resetAlert} AlertMessage={this.state.AlertMessage} AlertType={this.state.AlertType}/>

      <Modal style={{marginLeft:"3%"}}  open={this.props.open} onClose={this.close}>
      <div >
        <h2>Before Login, please tell us more about you</h2>
        <div className="FormInputs">
          <Form id="FormRegistro" >
          <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
            <Form.Input  onChange={this.handleRefCompanyName} value={this.state.CompanyName}  label={text.registro.RegistroForm.CompanyName}  placeholder="" />
            <Form.Input  onChange={this.handleRefFirstName} value={this.state.FirstName}  label={text.registro.RegistroForm.FirstName}  placeholder="" />
            <Form.Input  onChange={this.handleRefLastName} value={this.state.LastName}  label={text.registro.RegistroForm.LastName}  placeholder="" />
          </Form.Group>
          <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
            <Form.Input  onChange={this.handleRefTitle} value={this.state.Title}  label={text.registro.RegistroForm.Title}  placeholder="" />
            <Form.Input  onChange={this.handleRefMobil} value={this.state.Mobil}  label={text.registro.RegistroForm.Mobil}  placeholder="" />
            <Form.Input  value={this.props.email}  label={text.registro.RegistroForm.Email} disable placeholder="" />
          </Form.Group>
          <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
            <Form.Input  onChange={this.handleRefPhone} value={this.state.Phone}  label={text.registro.RegistroForm.Phone}  placeholder="" />
            <Form.Input  onChange={this.handleRefFax} value={this.state.Fax}  label={text.registro.RegistroForm.Fax}  placeholder="" />
            <Form.Input  onChange={this.handleRefWebsite} value={this.state.Website}  label={text.registro.RegistroForm.Website}  placeholder="" />
          </Form.Group>
          <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
            <Form.Input  onChange={this.handleRefEstablishmentYear} value={this.state.EstablishmentYear}  label={text.registro.RegistroForm.EstablishmentYear}  placeholder="" />
            <Form.Input  onChange={this.handleRefNoEmployees} value={this.state.NoEmployees}  label={text.registro.RegistroForm.NoEmployees}  placeholder="" />
            <Form.Input  onChange={this.handleRefNameOwners} value={this.state.NameOwners}  label={text.registro.RegistroForm.NameOwners}  placeholder="" />
          </Form.Group>
          <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
            <Form.Input  onChange={this.handleRefAddress} value={this.state.Address}  label={text.registro.RegistroForm.Address}  placeholder="" />
            <Form.Input  onChange={this.handleRefCity} value={this.state.City}  label={text.registro.RegistroForm.City}  placeholder="" />
            <Form.Input  onChange={this.handleRefPostaleCode} value={this.state.PostaleCode}  label={text.registro.RegistroForm.PostaleCode}  placeholder="" />
          </Form.Group>
          <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
            <Form.Input  onChange={this.handleRefState} value={this.state.State}  label={text.registro.RegistroForm.State}  placeholder="" />
            <div className='field'>
              <label>Country</label>
              <div className='ui fluid input'>
                <select value={this.state.Contry} onChange={(e)=>this.setState({Contry:e.target.value})}  className='selectCountry'>
                  <option value="AF">Afghanistan</option>
                  <option value="AX">Åland Islands</option>
                  <option value="AL">Albania</option>
                  <option value="DZ">Algeria</option>
                  <option value="AS">American Samoa</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AI">Anguilla</option>
                  <option value="AQ">Antarctica</option>
                  <option value="AG">Antigua and Barbuda</option>
                  <option value="AR">Argentina</option>
                  <option value="AM">Armenia</option>
                  <option value="AW">Aruba</option>
                  <option value="AU">Australia</option>
                  <option value="AT">Austria</option>
                  <option value="AZ">Azerbaijan</option>
                  <option value="BS">Bahamas</option>
                  <option value="BH">Bahrain</option>
                  <option value="BD">Bangladesh</option>
                  <option value="BB">Barbados</option>
                  <option value="BY">Belarus</option>
                  <option value="BE">Belgium</option>
                  <option value="BZ">Belize</option>
                  <option value="BJ">Benin</option>
                  <option value="BM">Bermuda</option>
                  <option value="BT">Bhutan</option>
                  <option value="BO">Bolivia, Plurinational State of</option>
                  <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                  <option value="BA">Bosnia and Herzegovina</option>
                  <option value="BW">Botswana</option>
                  <option value="BV">Bouvet Island</option>
                  <option value="BR">Brazil</option>
                  <option value="IO">British Indian Ocean Territory</option>
                  <option value="BN">Brunei Darussalam</option>
                  <option value="BG">Bulgaria</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="KH">Cambodia</option>
                  <option value="CM">Cameroon</option>
                  <option value="CA">Canada</option>
                  <option value="CV">Cape Verde</option>
                  <option value="KY">Cayman Islands</option>
                  <option value="CF">Central African Republic</option>
                  <option value="TD">Chad</option>
                  <option value="CL">Chile</option>
                  <option value="CN">China</option>
                  <option value="CX">Christmas Island</option>
                  <option value="CC">Cocos (Keeling) Islands</option>
                  <option value="CO">Colombia</option>
                  <option value="KM">Comoros</option>
                  <option value="CG">Congo</option>
                  <option value="CD">Congo, the Democratic Republic of the</option>
                  <option value="CK">Cook Islands</option>
                  <option value="CR">Costa Rica</option>
                  <option value="CI">Côte d'Ivoire</option>
                  <option value="HR">Croatia</option>
                  <option value="CU">Cuba</option>
                  <option value="CW">Curaçao</option>
                  <option value="CY">Cyprus</option>
                  <option value="CZ">Czech Republic</option>
                  <option value="DK">Denmark</option>
                  <option value="DJ">Djibouti</option>
                  <option value="DM">Dominica</option>
                  <option value="DO">Dominican Republic</option>
                  <option value="EC">Ecuador</option>
                  <option value="EG">Egypt</option>
                  <option value="SV">El Salvador</option>
                  <option value="GQ">Equatorial Guinea</option>
                  <option value="ER">Eritrea</option>
                  <option value="EE">Estonia</option>
                  <option value="ET">Ethiopia</option>
                  <option value="FK">Falkland Islands (Malvinas)</option>
                  <option value="FO">Faroe Islands</option>
                  <option value="FJ">Fiji</option>
                  <option value="FI">Finland</option>
                  <option value="FR">France</option>
                  <option value="GF">French Guiana</option>
                  <option value="PF">French Polynesia</option>
                  <option value="TF">French Southern Territories</option>
                  <option value="GA">Gabon</option>
                  <option value="GM">Gambia</option>
                  <option value="GE">Georgia</option>
                  <option value="DE">Germany</option>
                  <option value="GH">Ghana</option>
                  <option value="GI">Gibraltar</option>
                  <option value="GR">Greece</option>
                  <option value="GL">Greenland</option>
                  <option value="GD">Grenada</option>
                  <option value="GP">Guadeloupe</option>
                  <option value="GU">Guam</option>
                  <option value="GT">Guatemala</option>
                  <option value="GG">Guernsey</option>
                  <option value="GN">Guinea</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="GY">Guyana</option>
                  <option value="HT">Haiti</option>
                  <option value="HM">Heard Island and McDonald Islands</option>
                  <option value="VA">Holy See (Vatican City State)</option>
                  <option value="HN">Honduras</option>
                  <option value="HK">Hong Kong</option>
                  <option value="HU">Hungary</option>
                  <option value="IS">Iceland</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IR">Iran, Islamic Republic of</option>
                  <option value="IQ">Iraq</option>
                  <option value="IE">Ireland</option>
                  <option value="IM">Isle of Man</option>
                  <option value="IL">Israel</option>
                  <option value="IT">Italy</option>
                  <option value="JM">Jamaica</option>
                  <option value="JP">Japan</option>
                  <option value="JE">Jersey</option>
                  <option value="JO">Jordan</option>
                  <option value="KZ">Kazakhstan</option>
                  <option value="KE">Kenya</option>
                  <option value="KI">Kiribati</option>
                  <option value="KP">Korea, Democratic People's Republic of</option>
                  <option value="KR">Korea, Republic of</option>
                  <option value="KW">Kuwait</option>
                  <option value="KG">Kyrgyzstan</option>
                  <option value="LA">Lao People's Democratic Republic</option>
                  <option value="LV">Latvia</option>
                  <option value="LB">Lebanon</option>
                  <option value="LS">Lesotho</option>
                  <option value="LR">Liberia</option>
                  <option value="LY">Libya</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lithuania</option>
                  <option value="LU">Luxembourg</option>
                  <option value="MO">Macao</option>
                  <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                  <option value="MG">Madagascar</option>
                  <option value="MW">Malawi</option>
                  <option value="MY">Malaysia</option>
                  <option value="MV">Maldives</option>
                  <option value="ML">Mali</option>
                  <option value="MT">Malta</option>
                  <option value="MH">Marshall Islands</option>
                  <option value="MQ">Martinique</option>
                  <option value="MR">Mauritania</option>
                  <option value="MU">Mauritius</option>
                  <option value="YT">Mayotte</option>
                  <option value="MX">Mexico</option>
                  <option value="FM">Micronesia, Federated States of</option>
                  <option value="MD">Moldova, Republic of</option>
                  <option value="MC">Monaco</option>
                  <option value="MN">Mongolia</option>
                  <option value="ME">Montenegro</option>
                  <option value="MS">Montserrat</option>
                  <option value="MA">Morocco</option>
                  <option value="MZ">Mozambique</option>
                  <option value="MM">Myanmar</option>
                  <option value="NA">Namibia</option>
                  <option value="NR">Nauru</option>
                  <option value="NP">Nepal</option>
                  <option value="NL">Netherlands</option>
                  <option value="NC">New Caledonia</option>
                  <option value="NZ">New Zealand</option>
                  <option value="NI">Nicaragua</option>
                  <option value="NE">Niger</option>
                  <option value="NG">Nigeria</option>
                  <option value="NU">Niue</option>
                  <option value="NF">Norfolk Island</option>
                  <option value="MP">Northern Mariana Islands</option>
                  <option value="NO">Norway</option>
                  <option value="OM">Oman</option>
                  <option value="PK">Pakistan</option>
                  <option value="PW">Palau</option>
                  <option value="PS">Palestinian Territory, Occupied</option>
                  <option value="PA">Panama</option>
                  <option value="PG">Papua New Guinea</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Peru</option>
                  <option value="PH">Philippines</option>
                  <option value="PN">Pitcairn</option>
                  <option value="PL">Poland</option>
                  <option value="PT">Portugal</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="QA">Qatar</option>
                  <option value="RE">Réunion</option>
                  <option value="RO">Romania</option>
                  <option value="RU">Russian Federation</option>
                  <option value="RW">Rwanda</option>
                  <option value="BL">Saint Barthélemy</option>
                  <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                  <option value="KN">Saint Kitts and Nevis</option>
                  <option value="LC">Saint Lucia</option>
                  <option value="MF">Saint Martin (French part)</option>
                  <option value="PM">Saint Pierre and Miquelon</option>
                  <option value="VC">Saint Vincent and the Grenadines</option>
                  <option value="WS">Samoa</option>
                  <option value="SM">San Marino</option>
                  <option value="ST">Sao Tome and Principe</option>
                  <option value="SA">Saudi Arabia</option>
                  <option value="SN">Senegal</option>
                  <option value="RS">Serbia</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leone</option>
                  <option value="SG">Singapore</option>
                  <option value="SX">Sint Maarten (Dutch part)</option>
                  <option value="SK">Slovakia</option>
                  <option value="SI">Slovenia</option>
                  <option value="SB">Solomon Islands</option>
                  <option value="SO">Somalia</option>
                  <option value="ZA">South Africa</option>
                  <option value="GS">South Georgia and the South Sandwich Islands</option>
                  <option value="SS">South Sudan</option>
                  <option value="ES">Spain</option>
                  <option value="LK">Sri Lanka</option>
                  <option value="SD">Sudan</option>
                  <option value="SR">Suriname</option>
                  <option value="SJ">Svalbard and Jan Mayen</option>
                  <option value="SZ">Swaziland</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="SY">Syrian Arab Republic</option>
                  <option value="TW">Taiwan, Province of China</option>
                  <option value="TJ">Tajikistan</option>
                  <option value="TZ">Tanzania, United Republic of</option>
                  <option value="TH">Thailand</option>
                  <option value="TL">Timor-Leste</option>
                  <option value="TG">Togo</option>
                  <option value="TK">Tokelau</option>
                  <option value="TO">Tonga</option>
                  <option value="TT">Trinidad and Tobago</option>
                  <option value="TN">Tunisia</option>
                  <option value="TR">Turkey</option>
                  <option value="TM">Turkmenistan</option>
                  <option value="TC">Turks and Caicos Islands</option>
                  <option value="TV">Tuvalu</option>
                  <option value="UG">Uganda</option>
                  <option value="UA">Ukraine</option>
                  <option value="AE">United Arab Emirates</option>
                  <option value="GB">United Kingdom</option>
                  <option value="US">United States</option>
                  <option value="UM">United States Minor Outlying Islands</option>
                  <option value="UY">Uruguay</option>
                  <option value="UZ">Uzbekistan</option>
                  <option value="VU">Vanuatu</option>
                  <option value="VE">Venezuela, Bolivarian Republic of</option>
                  <option value="VN">Viet Nam</option>
                  <option value="VG">Virgin Islands, British</option>
                  <option value="VI">Virgin Islands, U.S.</option>
                  <option value="WF">Wallis and Futuna</option>
                  <option value="EH">Western Sahara</option>
                  <option value="YE">Yemen</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                </select>
              </div>
            </div>
            <Form.Input  onChange={this.handleRefCompanyRef1} value={this.state.CompanyRef1}  label={text.registro.RegistroForm.CompanyRef1}  placeholder="" />
          </Form.Group>
          <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
            <Form.Input  onChange={this.handleRefContactRef1} value={this.state.ContactRef1}  label={text.registro.RegistroForm.ContactRef1}  placeholder="" />
            <Form.Input  onChange={this.handleRefEmailRef1} value={this.state.EmailRef1}  label={text.registro.RegistroForm.EmailRef1}  placeholder="" />
            <Form.Input  onChange={this.handleRefCompanyRef2} value={this.state.CompanyRef2}  label={text.registro.RegistroForm.CompanyRef2}  placeholder="" />
          </Form.Group>
          <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
            <Form.Input  onChange={this.handleRefContactRef2} value={this.state.ContactRef2}  label={text.registro.RegistroForm.ContactRef2}  placeholder="" />
            <Form.Input  onChange={this.handleRefEmailRef2} value={this.state.EmailRef2}  label={text.registro.RegistroForm.EmailRef2}  placeholder="" />
            <Form.Input  onChange={this.handleRefCompanyRef3} value={this.state.CompanyRef3}  label={text.registro.RegistroForm.CompanyRef3}  placeholder="" />
          </Form.Group>
          <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
            <Form.Input  onChange={this.handleRefContactRef3} value={this.state.ContactRef3}  label={text.registro.RegistroForm.ContactRef3}  placeholder="" />
            <Form.Input  onChange={this.handleRefEmailRef3} value={this.state.EmailRef3}  label={text.registro.RegistroForm.EmailRef3}  placeholder="" />
          </Form.Group>

          </Form>
          </div>
        </div>
        <div className='contentTerminos'>
            <div className='itemTerminos'>
              <Checkbox
                checked={this.state.checkedCondiciones}
                onChange={this.handleChangeCondiciones}
                value="Plataforma"
                color="primary"
              />
              <p >  I accept the <a>Terms and Condition </a> of use of CEIF Logistics Network  </p>
            </div>
            <div className='itemTerminos'>
              <Checkbox
              style={{width:'10%',marginTop:'-23px'}}
                checked={this.state.checkedPoliticas}
                onChange={this.handleChangePoliticas}
                value="Plataforma"
                color="primary"
                />
              <p>I understand and Accept the<a>Terms of the Privacy Policy</a></p>
            </div>
        </div>
        <div className='ContentreCAPTCHA'>
          <ReCaptcha
            style={{width:'35%',margin:'0px auto'}}
            ref={(el) => {this.captchaDemo = el;}}
            sitekey={'6LenA-YUAAAAAO8qlHk2Q7hqYwkIv-YeniKKHl26'}
            onloadCallback={this.onLoadRecaptcha}
            verifyCallback={this.verifyCallback}

          />
        </div>
        <Modal.Actions>
        <Button onClick={this.registrar}   positive
        disabled={!this.state.CompanyName||!this.state.checkedPoliticas||!this.state.FirstName||!this.state.LastName||!this.state.Title
        ||!this.state.Mobil||!this.state.Title||!this.state.Phone
        ||!this.state.Fax||!this.state.Website||!this.state.EstablishmentYear||!this.state.NoEmployees
        ||!this.state.NameOwners||!this.state.Address||!this.state.City||!this.state.PostaleCode
        ||!this.state.State||!this.state.Contry||!this.state.CompanyRef1||!this.state.ContactRef1||!this.state.EmailRef1
        ||!this.state.CompanyRef2||!this.state.ContactRef2||!this.state.EmailRef2||!this.state.CompanyRef3||!this.state.ContactRef3||!this.state.EmailRef3||!this.state.checkedCondiciones||!this.state.checkedPoliticas}>Sign in</Button>
        <Button  negative onClick={this.close}>Cancel</Button>
        </Modal.Actions>
      </Modal>

      <Modal style={{marginLeft:"3%"}}  open={this.props.openDos} onClose={this.close}>
      <div >
        <h2>Before Login, please tell us more about you</h2>
      <div className="FormInputsCliente">
      <Form id="FormRegistro" >
        <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
          <Form.Input  onChange={this.handleRefCompanyName} value={this.state.CompanyName}  label={text.registro.RegistroForm.CompanyName}  placeholder="" />
          <Form.Input  onChange={this.handleRefFirstName} value={this.state.FirstName} label={text.registro.RegistroForm.FirstName}  placeholder="" />
          <Form.Input  onChange={this.handleRefLastName} value={this.state.LastName}  label={text.registro.RegistroForm.LastName}  placeholder="" />
        </Form.Group>
        <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
          <Form.Input  onChange={this.handleRefMobil} value={this.state.Mobil}  label={text.registro.RegistroForm.Mobil}  placeholder="" />
          <Form.Input  onChange={this.handleRefPostaleCode} value={this.state.PostaleCode}  label={text.registro.RegistroForm.PostaleCode}  placeholder="" />
          <Form.Input  onChange={this.handleRefAddress} value={this.state.Address}  label={text.registro.RegistroForm.Address}  placeholder="" />

        </Form.Group>

        <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
          <Form.Input  onChange={this.handleRefCity} value={this.state.City}  label={text.registro.RegistroForm.City}  placeholder="" />
          <Form.Input  onChange={this.handleRefState} value={this.state.State}  label={text.registro.RegistroForm.State}  placeholder="" />
          <div className='field'>
            <label>Country</label>
            <div className='ui fluid input'>
              <select value={this.state.Contry} onChange={(e)=>this.setState({Contry:e.target.value})}  className='selectCountry'>
                <option value="AF">Afghanistan</option>
                <option value="AX">Åland Islands</option>
                <option value="AL">Albania</option>
                <option value="DZ">Algeria</option>
                <option value="AS">American Samoa</option>
                <option value="AD">Andorra</option>
                <option value="AO">Angola</option>
                <option value="AI">Anguilla</option>
                <option value="AQ">Antarctica</option>
                <option value="AG">Antigua and Barbuda</option>
                <option value="AR">Argentina</option>
                <option value="AM">Armenia</option>
                <option value="AW">Aruba</option>
                <option value="AU">Australia</option>
                <option value="AT">Austria</option>
                <option value="AZ">Azerbaijan</option>
                <option value="BS">Bahamas</option>
                <option value="BH">Bahrain</option>
                <option value="BD">Bangladesh</option>
                <option value="BB">Barbados</option>
                <option value="BY">Belarus</option>
                <option value="BE">Belgium</option>
                <option value="BZ">Belize</option>
                <option value="BJ">Benin</option>
                <option value="BM">Bermuda</option>
                <option value="BT">Bhutan</option>
                <option value="BO">Bolivia, Plurinational State of</option>
                <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                <option value="BA">Bosnia and Herzegovina</option>
                <option value="BW">Botswana</option>
                <option value="BV">Bouvet Island</option>
                <option value="BR">Brazil</option>
                <option value="IO">British Indian Ocean Territory</option>
                <option value="BN">Brunei Darussalam</option>
                <option value="BG">Bulgaria</option>
                <option value="BF">Burkina Faso</option>
                <option value="BI">Burundi</option>
                <option value="KH">Cambodia</option>
                <option value="CM">Cameroon</option>
                <option value="CA">Canada</option>
                <option value="CV">Cape Verde</option>
                <option value="KY">Cayman Islands</option>
                <option value="CF">Central African Republic</option>
                <option value="TD">Chad</option>
                <option value="CL">Chile</option>
                <option value="CN">China</option>
                <option value="CX">Christmas Island</option>
                <option value="CC">Cocos (Keeling) Islands</option>
                <option value="CO">Colombia</option>
                <option value="KM">Comoros</option>
                <option value="CG">Congo</option>
                <option value="CD">Congo, the Democratic Republic of the</option>
                <option value="CK">Cook Islands</option>
                <option value="CR">Costa Rica</option>
                <option value="CI">Côte d'Ivoire</option>
                <option value="HR">Croatia</option>
                <option value="CU">Cuba</option>
                <option value="CW">Curaçao</option>
                <option value="CY">Cyprus</option>
                <option value="CZ">Czech Republic</option>
                <option value="DK">Denmark</option>
                <option value="DJ">Djibouti</option>
                <option value="DM">Dominica</option>
                <option value="DO">Dominican Republic</option>
                <option value="EC">Ecuador</option>
                <option value="EG">Egypt</option>
                <option value="SV">El Salvador</option>
                <option value="GQ">Equatorial Guinea</option>
                <option value="ER">Eritrea</option>
                <option value="EE">Estonia</option>
                <option value="ET">Ethiopia</option>
                <option value="FK">Falkland Islands (Malvinas)</option>
                <option value="FO">Faroe Islands</option>
                <option value="FJ">Fiji</option>
                <option value="FI">Finland</option>
                <option value="FR">France</option>
                <option value="GF">French Guiana</option>
                <option value="PF">French Polynesia</option>
                <option value="TF">French Southern Territories</option>
                <option value="GA">Gabon</option>
                <option value="GM">Gambia</option>
                <option value="GE">Georgia</option>
                <option value="DE">Germany</option>
                <option value="GH">Ghana</option>
                <option value="GI">Gibraltar</option>
                <option value="GR">Greece</option>
                <option value="GL">Greenland</option>
                <option value="GD">Grenada</option>
                <option value="GP">Guadeloupe</option>
                <option value="GU">Guam</option>
                <option value="GT">Guatemala</option>
                <option value="GG">Guernsey</option>
                <option value="GN">Guinea</option>
                <option value="GW">Guinea-Bissau</option>
                <option value="GY">Guyana</option>
                <option value="HT">Haiti</option>
                <option value="HM">Heard Island and McDonald Islands</option>
                <option value="VA">Holy See (Vatican City State)</option>
                <option value="HN">Honduras</option>
                <option value="HK">Hong Kong</option>
                <option value="HU">Hungary</option>
                <option value="IS">Iceland</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IR">Iran, Islamic Republic of</option>
                <option value="IQ">Iraq</option>
                <option value="IE">Ireland</option>
                <option value="IM">Isle of Man</option>
                <option value="IL">Israel</option>
                <option value="IT">Italy</option>
                <option value="JM">Jamaica</option>
                <option value="JP">Japan</option>
                <option value="JE">Jersey</option>
                <option value="JO">Jordan</option>
                <option value="KZ">Kazakhstan</option>
                <option value="KE">Kenya</option>
                <option value="KI">Kiribati</option>
                <option value="KP">Korea, Democratic People's Republic of</option>
                <option value="KR">Korea, Republic of</option>
                <option value="KW">Kuwait</option>
                <option value="KG">Kyrgyzstan</option>
                <option value="LA">Lao People's Democratic Republic</option>
                <option value="LV">Latvia</option>
                <option value="LB">Lebanon</option>
                <option value="LS">Lesotho</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libya</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lithuania</option>
                <option value="LU">Luxembourg</option>
                <option value="MO">Macao</option>
                <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                <option value="MG">Madagascar</option>
                <option value="MW">Malawi</option>
                <option value="MY">Malaysia</option>
                <option value="MV">Maldives</option>
                <option value="ML">Mali</option>
                <option value="MT">Malta</option>
                <option value="MH">Marshall Islands</option>
                <option value="MQ">Martinique</option>
                <option value="MR">Mauritania</option>
                <option value="MU">Mauritius</option>
                <option value="YT">Mayotte</option>
                <option value="MX">Mexico</option>
                <option value="FM">Micronesia, Federated States of</option>
                <option value="MD">Moldova, Republic of</option>
                <option value="MC">Monaco</option>
                <option value="MN">Mongolia</option>
                <option value="ME">Montenegro</option>
                <option value="MS">Montserrat</option>
                <option value="MA">Morocco</option>
                <option value="MZ">Mozambique</option>
                <option value="MM">Myanmar</option>
                <option value="NA">Namibia</option>
                <option value="NR">Nauru</option>
                <option value="NP">Nepal</option>
                <option value="NL">Netherlands</option>
                <option value="NC">New Caledonia</option>
                <option value="NZ">New Zealand</option>
                <option value="NI">Nicaragua</option>
                <option value="NE">Niger</option>
                <option value="NG">Nigeria</option>
                <option value="NU">Niue</option>
                <option value="NF">Norfolk Island</option>
                <option value="MP">Northern Mariana Islands</option>
                <option value="NO">Norway</option>
                <option value="OM">Oman</option>
                <option value="PK">Pakistan</option>
                <option value="PW">Palau</option>
                <option value="PS">Palestinian Territory, Occupied</option>
                <option value="PA">Panama</option>
                <option value="PG">Papua New Guinea</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Peru</option>
                <option value="PH">Philippines</option>
                <option value="PN">Pitcairn</option>
                <option value="PL">Poland</option>
                <option value="PT">Portugal</option>
                <option value="PR">Puerto Rico</option>
                <option value="QA">Qatar</option>
                <option value="RE">Réunion</option>
                <option value="RO">Romania</option>
                <option value="RU">Russian Federation</option>
                <option value="RW">Rwanda</option>
                <option value="BL">Saint Barthélemy</option>
                <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                <option value="KN">Saint Kitts and Nevis</option>
                <option value="LC">Saint Lucia</option>
                <option value="MF">Saint Martin (French part)</option>
                <option value="PM">Saint Pierre and Miquelon</option>
                <option value="VC">Saint Vincent and the Grenadines</option>
                <option value="WS">Samoa</option>
                <option value="SM">San Marino</option>
                <option value="ST">Sao Tome and Principe</option>
                <option value="SA">Saudi Arabia</option>
                <option value="SN">Senegal</option>
                <option value="RS">Serbia</option>
                <option value="SC">Seychelles</option>
                <option value="SL">Sierra Leone</option>
                <option value="SG">Singapore</option>
                <option value="SX">Sint Maarten (Dutch part)</option>
                <option value="SK">Slovakia</option>
                <option value="SI">Slovenia</option>
                <option value="SB">Solomon Islands</option>
                <option value="SO">Somalia</option>
                <option value="ZA">South Africa</option>
                <option value="GS">South Georgia and the South Sandwich Islands</option>
                <option value="SS">South Sudan</option>
                <option value="ES">Spain</option>
                <option value="LK">Sri Lanka</option>
                <option value="SD">Sudan</option>
                <option value="SR">Suriname</option>
                <option value="SJ">Svalbard and Jan Mayen</option>
                <option value="SZ">Swaziland</option>
                <option value="SE">Sweden</option>
                <option value="CH">Switzerland</option>
                <option value="SY">Syrian Arab Republic</option>
                <option value="TW">Taiwan, Province of China</option>
                <option value="TJ">Tajikistan</option>
                <option value="TZ">Tanzania, United Republic of</option>
                <option value="TH">Thailand</option>
                <option value="TL">Timor-Leste</option>
                <option value="TG">Togo</option>
                <option value="TK">Tokelau</option>
                <option value="TO">Tonga</option>
                <option value="TT">Trinidad and Tobago</option>
                <option value="TN">Tunisia</option>
                <option value="TR">Turkey</option>
                <option value="TM">Turkmenistan</option>
                <option value="TC">Turks and Caicos Islands</option>
                <option value="TV">Tuvalu</option>
                <option value="UG">Uganda</option>
                <option value="UA">Ukraine</option>
                <option value="AE">United Arab Emirates</option>
                <option value="GB">United Kingdom</option>
                <option value="US">United States</option>
                <option value="UM">United States Minor Outlying Islands</option>
                <option value="UY">Uruguay</option>
                <option value="UZ">Uzbekistan</option>
                <option value="VU">Vanuatu</option>
                <option value="VE">Venezuela, Bolivarian Republic of</option>
                <option value="VN">Viet Nam</option>
                <option value="VG">Virgin Islands, British</option>
                <option value="VI">Virgin Islands, U.S.</option>
                <option value="WF">Wallis and Futuna</option>
                <option value="EH">Western Sahara</option>
                <option value="YE">Yemen</option>
                <option value="ZM">Zambia</option>
                <option value="ZW">Zimbabwe</option>
              </select>
            </div>
          </div>
        </Form.Group>


        <div style={{width:'60%',margin:'0px auto'}}>
        <div style={{display:'inline-flex',marginTop:'30px',marginBottom:'20px'}} >
          <Checkbox
            checked={this.state.checkedCondiciones}
            onChange={this.handleChangeCondiciones}
            value="Plataforma"
            color="primary"
            style={{width:'10%',marginTop:'-23px'}}

            />
            <p style={{display:'inline-flex'}}>  I accept the <a style={{marginRight:'4px',marginLeft:'4px'}}>Terms and Condition </a> of use of CEIF Logistics Network  </p>
          </div>
        <div style={{display:'inline-flex',marginBottom:'50px'}} >
          <Checkbox
          style={{width:'10%',marginTop:'-23px'}}
            checked={this.state.checkedPoliticas}
            onChange={this.handleChangePoliticas}
            value="Plataforma"
            color="primary"
            />
          <p style={{display:'inline-flex'}}>I understand and accept the <a style={{marginRight:'4px',marginLeft:'4px'}}>Terms of the Privacy Policy </a></p>
        </div>
      </div>
      <div className='ContentreCAPTCHA'>
        <ReCaptcha
          ref={(el) => {this.captchaDemo = el;}}
          sitekey={'6LenA-YUAAAAAO8qlHk2Q7hqYwkIv-YeniKKHl26'}
          onloadCallback={this.onLoadRecaptcha}
          verifyCallback={this.verifyCallback}
        />
      </div>


      <div className="btnModalClientes">
        <Button positive type="button" onClick={this.registrar}className="submit2"
        disabled={!this.state.FirstName||!this.state.CompanyName||!this.state.checkedPoliticas||!this.state.LastName
        ||!this.state.Mobil||!this.state.Address||!this.state.City||!this.state.PostaleCode
        ||!this.state.State||!this.state.Contry||!this.state.checkedCondiciones||!this.state.checkedPoliticas}>Sign In</Button>
        <Button  negative onClick={this.close}>Cancelar</Button>
      </div>
        </Form>
        </div>
      </div>
      </Modal>


    <Transition visible={this.state.modalReview} animation='bounce' duration={700}>
      <Modal style={{marginLeft:"3%",width:'43%'}}  open={this.state.modalReview}>
        <React.Fragment>
          <img style={{maxWidth:'90%'}}src='../imagenes/review.png'/>
            <Header as='h4' icon textAlign='center' style={{color:'#009688'}}>
              <Header.Content>We are approving your registration, we will contact you by email</Header.Content>
            </Header>
            <button class="ui secondary button" onClick={this.recargar} style={{width:'93%',marginLeft:'2vh',marginRight:'auto',marginBottom:'4vh'}}>Agree</button>
        </React.Fragment>
      </Modal>
    </Transition>

</div>

)}
}
export default Login;

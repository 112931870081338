import React, { Component } from 'react';
import { Message,Menu,Icon,Popup } from 'semantic-ui-react';
import {Router, Route, BrowserRouter, Switch, Link} from 'react-router-dom'
import Badge from '@material-ui/core/Badge';
import firebase from 'firebase'
import ListaNotificaciones from './listaNotificaciones.js';
import axios from 'axios';
import {Direccion} from '../../strings/peticiones.js';

class BarraLateral extends Component{
  constructor(){
    super()
    this.state = {
      notificaciones:[],
      verificado: firebase.auth().currentUser.emailVerified,

    }
  }
  UNSAFE_componentWillMount(){

    this.notificaciones();

  }

  notificaciones(){
    var data;
    var self=this;
    axios.post(Direccion+'/getNotificaciones',{id:firebase.auth().currentUser.uid,tipo:'Cliente'})
    .then(res=>{
      console.log(res.data);
      data=res.data;
      localStorage.setItem('notificaciones', JSON.stringify(data));
      this.setState({notificaciones:data.reverse()})
    })
  }

  reenvioCorreo=()=>{
    var self=this;
    axios.post(Direccion+'/reenvioCorreo',{id:firebase.auth().currentUser.uid,email:firebase.auth().currentUser.email,name:firebase.auth().currentUser.displayName})
    .then(res=>{
      if (res.data == 'OK') {
        this.setState({
          enviado:true,
        });
      }
    })
  }

  cerrar(){
    this.setState({verificado:true})
  }

  render(){
    return(
      <div>

            <Menu pointing style={{fontSize:'15px'}}>
              <Link to="/">
                <Menu.Item name='home' />
              </Link>
              <Link to='/user'>
                <Menu.Item name='My profile'/>
              </Link>

              <Link to="/user/Pagos">
                <Menu.Item name='My accounting'/>
              </Link>
              <Link to="/user/Envios">
                <Menu.Item name='My operations'/>
              </Link>

              <Menu.Menu position='right'>
                <Menu.Item>
                  <Popup
                    style={{maxWidth:'85vh',height: 'auto',maxHeight: '65vh',overflowY: 'scroll'}}
                     trigger={<Badge badgeContent={this.state.notificaciones.length} color="secondary">
                    <i class="material-icons">
                    notifications
                    </i>
                    </Badge>}
                  content={<ListaNotificaciones datos={this.state.notificaciones}/>}
                  on='click'
                   />
                </Menu.Item>
              </Menu.Menu>

            <Menu.Menu>
              <Menu.Item
                onClick={() => (firebase.auth().signOut() )}
              >
                <Icon name='sign-in'></Icon>
                <p>Log out</p>

              </Menu.Item>
            </Menu.Menu>

            </Menu>
            { !this.state.verificado ?
              <Message negative onDismiss={this.cerrar.bind(this,'verificacion')}>
                <Message.Header>Your account is not verified yet</Message.Header>
                  <p>Please check your email and verify your account to join the Experience and benefits of CEIF
                   {!this.state.enviado?
                      <div>
                      <a onClick={this.reenvioCorreo}>resend e-mail</a>
                      </div>
                      :
                      <div>
                      <a>check your email, the email has already been sent.</a>
                      </div>
                    }</p>
              </Message>
              :
                <div></div>
            }
      </div>
    )
  }
}

export default BarraLateral;

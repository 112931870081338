import React, { Component } from 'react';
import './App.css';
import RouterPrincipal from './routers/routerMain.js';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';


class App extends Component {
  render() {
    return (
      <div style={{backgroundColor:'#fff'}}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <RouterPrincipal/>
      </MuiPickersUtilsProvider>

      </div>
    );
  }
}

export default App;

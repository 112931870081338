import React, { Component, useState,useEffect } from 'react';
import {Dropdown,Checkbox,Pagination,Icon} from 'semantic-ui-react';
import Script from 'react-load-script';
import Alert from '../../notificacionCard.js';
import ValidarItemsArchivo from '../../scripts/ValidarItemArchivo.js';
import XLSX from 'xlsx';
import axios from 'axios';
import {Direccion} from '../../../strings/peticiones.js';
import firebase from 'firebase'


export default function FormularioFCL (props){
  //Communts
  const [ArrayRows,setArrayRows] = useState([]);
  const [NameSlot,setNameSlot]= useState([]);

  const [FechaIniciacion,setFechaFrom] = useState('');
  const [Expiracion,setFechaExpiracion] = useState('');
  const [Destino, setDestino] = useState('');
  const [PaisOrigen, setPaisOrigen] = useState('');
  const [PaisDestino, setPaisDestino] = useState('');
  const [Origen, setOrigen] = useState('');
  const [Trafico, setTrafico] = useState('');
  const [Servicio, setServicio] = useState('');
  //Especific
  const [ContainerType, setContainerType] = useState('');
  const [ContainerSize, setContainerSize] = useState('');
  const [Tarifa, setTarifa] = useState('');
  const [Insurance, setInsurance] = useState('');
  const [RecargoIMO, setRecargoIMO] = useState('');
  const [PickUp, setPickUp] = useState('');
  const [BlFee, setBlFee] = useState('');
  const [Thc, setThc] = useState('');
  const [Buc, setBuc] = useState('');
  const [Dre, setDre] = useState('');
  const [Cnr, setCnr] = useState('');
  const [Isp, setIsp] = useState('');
  const [Ams, setAms] = useState('');
  const [TerminalHandling, setTerminalHandling] = useState('');
  const [ContainerCleaning, setContainerCleaning] = useState('');
  const [Inland, setInland] = useState('');


  const [openAlert, setopenAlert] = useState(false);
  const [titleAlert, settitleAlert] = useState('');
  const [AlertMessage, setAlertMessage] = useState('');
  const [AlertType, setAlertType] = useState('');
  const [loading, setLoading] = useState(false);
  const [inputOrigen,setInputPlaceOrigen] =useState(null);
  const [inputDestino,setInputPlaceDestino] =useState(null);

  //Paginador
  const [currentPageActivas,setcurrentPageActivas] = useState(1);
  const [PerPageActivas,setPerPageActivas] = useState(100);
  const [currentTodosActivas,setcurrentTodosActivas] = useState([]);
  const [renderCompanyActivas,setrenderCompanyActivas] = useState(null);
  const [pageNumbersActivas,setpageNumbersActivas] = useState(0);

  useEffect(()=>{
    RenderItemsTable();
  },[ArrayRows,currentPageActivas])

  const RenderItemsTable=()=>{
    const indexOfLastTodoActivas = currentPageActivas * PerPageActivas;
    const indexOfFirstTodoActivas = indexOfLastTodoActivas - PerPageActivas;
    const currentTodosActivas = ArrayRows.slice(indexOfFirstTodoActivas, indexOfLastTodoActivas);
    const renderCompanyActivas = currentTodosActivas.map((it,key)=>{
     return(<InputTable index={key} BorrarItemRutas={BorrarItemRutas} key={key}  it={it} />)
    })
    setpageNumbersActivas(Math.ceil(ArrayRows.length / PerPageActivas))
    setrenderCompanyActivas(renderCompanyActivas)
  }

  const handlePaginationChangeActivo=(e,index)=>{
    setcurrentPageActivas(index.activePage);
  }
   const ContainerTypeDrop =[{key:'ST',text:'ST',value:'ST'},{key:'HQ',text:'HQ',value:'HQ'},{key:'RF',text:'RF',value:'RF'},{key:'OT',text:'OT',value:'OT'},{key:'FR',text:'FR',value:'FR'}];
   const ContainerSizeDrop =[{key:'20',text:'20',value:'20'},{key:'40',text:'40',value:'40'}];

   const nuevoItemRuta = () => {
    let ArrayRutas = ArrayRows;
    if (Destino&&PaisOrigen&&PaisDestino&&Origen&&Trafico&&Servicio&&ContainerType&&ContainerSize&&Tarifa&&Insurance&&RecargoIMO&&PickUp&&BlFee&&Thc&&Buc&&Dre&&Cnr&&Isp&&Ams&&TerminalHandling&&ContainerCleaning&&Inland) {
    let newArray =  ArrayRutas.concat([{Destino:Destino,PaisOrigen:PaisOrigen,PaisDestino:PaisDestino,Origen:Origen,Trafico:Trafico,Servicio:Servicio,ContainerType:ContainerType,ContainerSize:ContainerSize,Tarifa:Tarifa,Insurance:Insurance,RecargoIMO:RecargoIMO,PickUp:PickUp,BlFee:BlFee,Thc:Thc,Buc:Buc,Dre:Dre,Cnr:Cnr,Isp:Isp,Ams:Ams,TerminalHandling:TerminalHandling,ContainerCleaning:ContainerCleaning,Inland:Inland,}])
      setArrayRows(newArray);
      resentStates(false);
    }else{
      setopenAlert(true);
      settitleAlert('Error!');
      setAlertMessage('fill in all the fields');
      setAlertType('error');
    }

   };
   const resentStates=(refrestAll)=>{
     setDestino("");
     setPaisOrigen("");
     setPaisDestino("");
     setOrigen("");
     setTrafico("");
     setServicio("");
     setContainerType("");
     setContainerSize("");
     setTarifa("");
     setInsurance("");
     setRecargoIMO("");
     setPickUp("");
     setBlFee("");
     setThc("");
     setBuc("");
     setDre("");
     setCnr("");
     setIsp("");
     setAms("");
     setTerminalHandling("");
     setContainerCleaning("");
     setInland("");
     if (inputOrigen) {inputOrigen.value = '';}
     if (inputDestino) {inputDestino.value = '';}
     if (refrestAll) {
       setArrayRows([]);
       setNameSlot('');
     }
   }
   const fileOpen=()=>{
         var input = document.getElementById('file-input');
         input.click();
     }
   const  handleFile=(e)=>{
    setLoading(true);
          if (e.target.files[0]) {
            var oFile = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                data = new Uint8Array(data);
                var workbook = XLSX.read(data, {type: 'array'});
                var result = {};
                workbook.SheetNames.forEach(function (sheetName) {
                    var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1});
                    if (roa.length) result[sheetName] = roa;
                });
                if (result.tariffs) {
                   let respuesta = ValidarItemsArchivo(result.tariffs,'FCL','FCL');
                   if (respuesta.status) {
                     let ArrayAux = ArrayRows;

                     if (ArrayAux.length>0) {
                      let newArray =  ArrayAux.concat(respuesta.ArrayRutas)
                       setArrayRows(newArray);
                     }else{
                       setArrayRows(respuesta.ArrayRutas)
                     }
                     setLoading(false);

                   }else{
                     setopenAlert(true);
                     settitleAlert('Error!');
                     setAlertMessage(respuesta.errorMessage);
                     setAlertType('error');
                     setLoading(false);

                   }
                }
                else {
                  setopenAlert(true);
                  settitleAlert('Error!');
                  setAlertMessage('Please do not change the name of the sheet or upload a correct file   .xlsx');
                  setAlertType('error');
                  setLoading(false);

                }
            };
            reader.readAsArrayBuffer(oFile);
          }
      }

   const onFocus=(e)=>{
      e.currentTarget.type = "date";
  }
   const onBlur=(e)=>{
      e.currentTarget.type = "text";
  }
   const resetAlert =()=>{
     setopenAlert(false);
     settitleAlert('');
     setAlertMessage('');
     setAlertType('');
   }

   const SubirRutas=()=>{
     setLoading(true);
     if (FechaIniciacion && Expiracion && NameSlot) {
       axios.post(Direccion+`/Rutas`,{NuevasRutas:ArrayRows,NameSlot:NameSlot,FechaIniciacion:FechaIniciacion,FechaIniciacion:FechaIniciacion,Expiracion:Expiracion, type:'FCL',User:firebase.auth().currentUser.uid})
         .then(res => {
           console.log(res.data);

           if (res.data === 'OK') {
             resentStates(true);
             setLoading(false);
             setopenAlert(true);
             settitleAlert('Success!');
             setAlertMessage('rates uploaded successfully');
             setAlertType('success');
             props.regresarBack();

           }
         }).catch((e)=>{
           console.log(e);
           setLoading(false);
           setopenAlert(true);
           settitleAlert('Error!');
           setAlertMessage('Somethings wrong');
           setAlertType('error');
         })
     }else{
       setLoading(false);
       setopenAlert(true);
       settitleAlert('Error!');
       setAlertMessage('Put a Name, start date and an expiration date');
       setAlertType('error');
       setLoading(false);
     }

   }
   const BorrarItemRutas=(index)=>{
     let ArrayAux = ArrayRows;
     setArrayRows(ArrayAux.slice(0, index).concat(ArrayAux.slice(index + 1)))
   }
   return(
     <div className='TableContent'>
       <Alert openAlert={openAlert} titleAlert={titleAlert} resetAlert={resetAlert} AlertMessage={AlertMessage} AlertType={AlertType}/>
       <div className='actionsBtnsContent'>
          <div className='actionsBtns left'>
            <div className='inputDate'>
              <input type="text"  placeholder='Name of packages' onChange={(e)=>setNameSlot(e.target.value)} style={{width: "170px"}}  />
            </div>
            <div className='inputDate'>
              <input type="text"  onFocus={onFocus} onBlur={onBlur} onChange={(e)=>setFechaFrom(e.target.value)} style={{width: "170px"}}  placeholder="Valid From" />
            </div>
            <div className='inputDate'>
              <input type="text" onFocus={onFocus} onBlur={onBlur} onChange={(e)=>setFechaExpiracion(e.target.value)} style={{width: "170px"}}  placeholder="Expiration date" />
            </div>
          </div>
          <div className='actionsBtns'>
            <div onClick={fileOpen} className='btn orange'>Upload file</div>
            <input onClick={(e) =>{e.target.value = ''}} onChange={handleFile} id="file-input" type="file" name="name" style={{display:" none"}} />
            <div onClick={() => window.location.href="https://firebasestorage.googleapis.com/v0/b/exportaciones-e2444.appspot.com/o/Formatos%2FFormatToTarOcean-FCL%20v0.3.xlsx?alt=media&token=42b00a2e-303f-41ca-b99c-e71e30128c3b"} className='btn orange'>Download file</div>
            {loading?
              <div  className='btn positive'><Icon loading name='spinner'/></div>
              :
              <div onClick={SubirRutas} className='btn positive'>Upload rates</div>
            }
          </div>
        </div>

       <table className='FormContent FCL'>
         <thead>
           <tr className='Header'>
             <th className='headerItem'><p className='tituloInput'>Origin Country</p></th>
             <th className='headerItem'><p className='tituloInput'>Destination Country</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.origenCity}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.destinoCity}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.trafico}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.servicio}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.ContainerType}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.ContainerSize}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.Tarifa}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.Insurance}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.RecargoIMO}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.PickUp}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.BlFee}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.Thc}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.Buc}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.Dre}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.Cnr}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.Isp}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.Ams}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.TerminalHandling}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.ContainerCleaning}</p></th>
             <th className='headerItem'><p className='tituloInput'>{props.text.RutaSimple.Inland}</p></th>
           </tr>
         </thead>
         <tbody>

           {renderCompanyActivas?renderCompanyActivas:''}

           <tr className='FormRow FCL'>

             <td className='inputRuta FCL'>
               <SelectPais Pais={PaisOrigen} handleRefPais={(e)=>setPaisOrigen(e.target.value)} />
             </td>
             <td className='inputRuta FCL'>
               <SelectPais Pais={PaisDestino} handleRefPais={(e)=>setPaisDestino(e.target.value)} />
             </td>
             <td className='inputRuta FCL inputOrigen'>
               <InputPlaceOrigen value={Origen} returnPlace={(e,input)=>{setOrigen(e); setInputPlaceOrigen(input);}} refres={true}/>
             </td>
             <td className='inputRuta FCL inputDestino'>
               <InputPlaceDestino value={Destino} returnPlace={(e,input)=>{setDestino(e); setInputPlaceDestino(input);}} Origen={false}/>
             </td>
             <td className='inputRuta FCL'>
               <input onChange={(e)=>setTrafico(e.target.value)} value={Trafico} type='text'/>
             </td>
             <td className='inputRuta FCL'>
               <input onChange={(e)=>setServicio(e.target.value)} value={Servicio} type='text'/>
             </td>
             <td className='inputRuta FCL'>
               <Dropdown
                  selection
                   icon='dropdown'
                   options={ContainerTypeDrop}
                   value={ContainerType}
                   onChange={(e,{value})=>setContainerType(value)}
                   text={ContainerType}
                 />
             </td>
             <td className='inputRuta FCL'>
               <Dropdown
                  selection
                   icon='dropdown'
                   options={ContainerSizeDrop}
                   value={ContainerSize}
                   onChange={(e,{value})=>setContainerSize(value)}
                   text={ContainerSize}
                 />
             </td>
             <td className='inputRuta FCL'>
               <input onChange={(e)=>setTarifa(e.target.value)} value={Tarifa} type='text'/>
             </td>
             <td className='inputRuta FCL'>
               <input onChange={(e)=>setInsurance(e.target.value)} value={Insurance} type='text'/>
             </td>
             <td className='inputRuta FCL'>
               <input onChange={(e)=>setRecargoIMO(e.target.value)} value={RecargoIMO} type='text'/>
             </td>
             <td className='inputRuta FCL'>
               <input onChange={(e)=>setPickUp(e.target.value)} value={PickUp} type='text'/>
             </td>
             <td className='inputRuta FCL'>
               <input onChange={(e)=>setBlFee(e.target.value)} value={BlFee} type='text'/>
             </td>
             <td className='inputRuta FCL'>
               <input onChange={(e)=>setThc(e.target.value)} value={Thc} type='text'/>
             </td>
             <td className='inputRuta FCL'>
               <input onChange={(e)=>setBuc(e.target.value)} value={Buc} type='text'/>
             </td>
             <td className='inputRuta FCL'>
               <input onChange={(e)=>setDre(e.target.value)} value={Dre} type='text'/>
             </td>
             <td className='inputRuta FCL'>
               <input onChange={(e)=>setCnr(e.target.value)} value={Cnr} type='text'/>
             </td>
             <td className='inputRuta FCL'>
               <input onChange={(e)=>setIsp(e.target.value)} value={Isp} type='text'/>
             </td>
             <td className='inputRuta FCL'>
               <input onChange={(e)=>setAms(e.target.value)} value={Ams} type='text'/>
             </td>
             <td className='inputRuta FCL'>
               <input onChange={(e)=>setTerminalHandling(e.target.value)} value={TerminalHandling} type='text'/>
             </td>
             <td className='inputRuta FCL'>
               <input onChange={(e)=>setContainerCleaning(e.target.value)} value={ContainerCleaning} type='text'/>
             </td>
             <td className='inputRuta FCL'>
               <input onChange={(e)=>setInland(e.target.value)} value={Inland} type='text'/>
             </td>
             <div className='Action row'>
               <div onClick={nuevoItemRuta} className='btn icon positive'><Icon name='save'/></div>
             </div>
           </tr>
         </tbody>

       </table>
       <div className='PaginacionFormulario'>
        <Pagination
         onPageChange={(e,index)=>handlePaginationChangeActivo(e,index)}
         totalPages={pageNumbersActivas}
         activePage={currentPageActivas}
          />
        </div>
     </div>

   )
 }

const SelectPais=(props )=>{
  return(
    <select  className='selectCountry' id="country" name="country" value={props.Pais} onChange={(e)=>props.handleRefPais(e)} >
      <option  selected value="">Select country</option>
       <option value="Afganistan">Afghanistan</option>
       <option value="Albania">Albania</option>
       <option value="Algeria">Algeria</option>
       <option value="American Samoa">American Samoa</option>
       <option value="Andorra">Andorra</option>
       <option value="Angola">Angola</option>
       <option value="Anguilla">Anguilla</option>
       <option value="Antigua & Barbuda">Antigua & Barbuda</option>
       <option value="Argentina">Argentina</option>
       <option value="Armenia">Armenia</option>
       <option value="Aruba">Aruba</option>
       <option value="Australia">Australia</option>
       <option value="Austria">Austria</option>
       <option value="Azerbaijan">Azerbaijan</option>
       <option value="Bahamas">Bahamas</option>
       <option value="Bahrain">Bahrain</option>
       <option value="Bangladesh">Bangladesh</option>
       <option value="Barbados">Barbados</option>
       <option value="Belarus">Belarus</option>
       <option value="Belgium">Belgium</option>
       <option value="Belize">Belize</option>
       <option value="Benin">Benin</option>
       <option value="Bermuda">Bermuda</option>
       <option value="Bhutan">Bhutan</option>
       <option value="Bolivia">Bolivia</option>
       <option value="Bonaire">Bonaire</option>
       <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
       <option value="Botswana">Botswana</option>
       <option value="Brazil">Brazil</option>
       <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
       <option value="Brunei">Brunei</option>
       <option value="Bulgaria">Bulgaria</option>
       <option value="Burkina Faso">Burkina Faso</option>
       <option value="Burundi">Burundi</option>
       <option value="Cambodia">Cambodia</option>
       <option value="Cameroon">Cameroon</option>
       <option value="Canada">Canada</option>
       <option value="Canary Islands">Canary Islands</option>
       <option value="Cape Verde">Cape Verde</option>
       <option value="Cayman Islands">Cayman Islands</option>
       <option value="Central African Republic">Central African Republic</option>
       <option value="Chad">Chad</option>
       <option value="Channel Islands">Channel Islands</option>
       <option value="Chile">Chile</option>
       <option value="China">China</option>
       <option value="Christmas Island">Christmas Island</option>
       <option value="Cocos Island">Cocos Island</option>
       <option value="Colombia">Colombia</option>
       <option value="Comoros">Comoros</option>
       <option value="Congo">Congo</option>
       <option value="Cook Islands">Cook Islands</option>
       <option value="Costa Rica">Costa Rica</option>
       <option value="Cote DIvoire">Cote DIvoire</option>
       <option value="Croatia">Croatia</option>
       <option value="Cuba">Cuba</option>
       <option value="Curaco">Curacao</option>
       <option value="Cyprus">Cyprus</option>
       <option value="Czech Republic">Czech Republic</option>
       <option value="Denmark">Denmark</option>
       <option value="Djibouti">Djibouti</option>
       <option value="Dominica">Dominica</option>
       <option value="Dominican Republic">Dominican Republic</option>
       <option value="East Timor">East Timor</option>
       <option value="Ecuador">Ecuador</option>
       <option value="Egypt">Egypt</option>
       <option value="El Salvador">El Salvador</option>
       <option value="Equatorial Guinea">Equatorial Guinea</option>
       <option value="Eritrea">Eritrea</option>
       <option value="Estonia">Estonia</option>
       <option value="Ethiopia">Ethiopia</option>
       <option value="Falkland Islands">Falkland Islands</option>
       <option value="Faroe Islands">Faroe Islands</option>
       <option value="Fiji">Fiji</option>
       <option value="Finland">Finland</option>
       <option value="France">France</option>
       <option value="French Guiana">French Guiana</option>
       <option value="French Polynesia">French Polynesia</option>
       <option value="French Southern Ter">French Southern Ter</option>
       <option value="Gabon">Gabon</option>
       <option value="Gambia">Gambia</option>
       <option value="Georgia">Georgia</option>
       <option value="Germany">Germany</option>
       <option value="Ghana">Ghana</option>
       <option value="Gibraltar">Gibraltar</option>
       <option value="Great Britain">Great Britain</option>
       <option value="Greece">Greece</option>
       <option value="Greenland">Greenland</option>
       <option value="Grenada">Grenada</option>
       <option value="Guadeloupe">Guadeloupe</option>
       <option value="Guam">Guam</option>
       <option value="Guatemala">Guatemala</option>
       <option value="Guinea">Guinea</option>
       <option value="Guyana">Guyana</option>
       <option value="Haiti">Haiti</option>
       <option value="Hawaii">Hawaii</option>
       <option value="Honduras">Honduras</option>
       <option value="Hong Kong">Hong Kong</option>
       <option value="Hungary">Hungary</option>
       <option value="Iceland">Iceland</option>
       <option value="Indonesia">Indonesia</option>
       <option value="India">India</option>
       <option value="Iran">Iran</option>
       <option value="Iraq">Iraq</option>
       <option value="Ireland">Ireland</option>
       <option value="Isle of Man">Isle of Man</option>
       <option value="Israel">Israel</option>
       <option value="Italy">Italy</option>
       <option value="Jamaica">Jamaica</option>
       <option value="Japan">Japan</option>
       <option value="Jordan">Jordan</option>
       <option value="Kazakhstan">Kazakhstan</option>
       <option value="Kenya">Kenya</option>
       <option value="Kiribati">Kiribati</option>
       <option value="Korea North">Korea North</option>
       <option value="Korea Sout">Korea South</option>
       <option value="Kuwait">Kuwait</option>
       <option value="Kyrgyzstan">Kyrgyzstan</option>
       <option value="Laos">Laos</option>
       <option value="Latvia">Latvia</option>
       <option value="Lebanon">Lebanon</option>
       <option value="Lesotho">Lesotho</option>
       <option value="Liberia">Liberia</option>
       <option value="Libya">Libya</option>
       <option value="Liechtenstein">Liechtenstein</option>
       <option value="Lithuania">Lithuania</option>
       <option value="Luxembourg">Luxembourg</option>
       <option value="Macau">Macau</option>
       <option value="Macedonia">Macedonia</option>
       <option value="Madagascar">Madagascar</option>
       <option value="Malaysia">Malaysia</option>
       <option value="Malawi">Malawi</option>
       <option value="Maldives">Maldives</option>
       <option value="Mali">Mali</option>
       <option value="Malta">Malta</option>
       <option value="Marshall Islands">Marshall Islands</option>
       <option value="Martinique">Martinique</option>
       <option value="Mauritania">Mauritania</option>
       <option value="Mauritius">Mauritius</option>
       <option value="Mayotte">Mayotte</option>
       <option value="Mexico">Mexico</option>
       <option value="Midway Islands">Midway Islands</option>
       <option value="Moldova">Moldova</option>
       <option value="Monaco">Monaco</option>
       <option value="Mongolia">Mongolia</option>
       <option value="Montserrat">Montserrat</option>
       <option value="Morocco">Morocco</option>
       <option value="Mozambique">Mozambique</option>
       <option value="Myanmar">Myanmar</option>
       <option value="Nambia">Nambia</option>
       <option value="Nauru">Nauru</option>
       <option value="Nepal">Nepal</option>
       <option value="Netherland Antilles">Netherland Antilles</option>
       <option value="Netherlands">Netherlands (Holland, Europe)</option>
       <option value="Nevis">Nevis</option>
       <option value="New Caledonia">New Caledonia</option>
       <option value="New Zealand">New Zealand</option>
       <option value="Nicaragua">Nicaragua</option>
       <option value="Niger">Niger</option>
       <option value="Nigeria">Nigeria</option>
       <option value="Niue">Niue</option>
       <option value="Norfolk Island">Norfolk Island</option>
       <option value="Norway">Norway</option>
       <option value="Oman">Oman</option>
       <option value="Pakistan">Pakistan</option>
       <option value="Palau Island">Palau Island</option>
       <option value="Palestine">Palestine</option>
       <option value="Panama">Panama</option>
       <option value="Papua New Guinea">Papua New Guinea</option>
       <option value="Paraguay">Paraguay</option>
       <option value="Peru">Peru</option>
       <option value="Phillipines">Philippines</option>
       <option value="Pitcairn Island">Pitcairn Island</option>
       <option value="Poland">Poland</option>
       <option value="Portugal">Portugal</option>
       <option value="Puerto Rico">Puerto Rico</option>
       <option value="Qatar">Qatar</option>
       <option value="Republic of Montenegro">Republic of Montenegro</option>
       <option value="Republic of Serbia">Republic of Serbia</option>
       <option value="Reunion">Reunion</option>
       <option value="Romania">Romania</option>
       <option value="Russia">Russia</option>
       <option value="Rwanda">Rwanda</option>
       <option value="St Barthelemy">St Barthelemy</option>
       <option value="St Eustatius">St Eustatius</option>
       <option value="St Helena">St Helena</option>
       <option value="St Kitts-Nevis">St Kitts-Nevis</option>
       <option value="St Lucia">St Lucia</option>
       <option value="St Maarten">St Maarten</option>
       <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
       <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
       <option value="Saipan">Saipan</option>
       <option value="Samoa">Samoa</option>
       <option value="Samoa American">Samoa American</option>
       <option value="San Marino">San Marino</option>
       <option value="Sao Tome & Principe">Sao Tome & Principe</option>
       <option value="Saudi Arabia">Saudi Arabia</option>
       <option value="Senegal">Senegal</option>
       <option value="Seychelles">Seychelles</option>
       <option value="Sierra Leone">Sierra Leone</option>
       <option value="Singapore">Singapore</option>
       <option value="Slovakia">Slovakia</option>
       <option value="Slovenia">Slovenia</option>
       <option value="Solomon Islands">Solomon Islands</option>
       <option value="Somalia">Somalia</option>
       <option value="South Africa">South Africa</option>
       <option value="Spain">Spain</option>
       <option value="Sri Lanka">Sri Lanka</option>
       <option value="Sudan">Sudan</option>
       <option value="Suriname">Suriname</option>
       <option value="Swaziland">Swaziland</option>
       <option value="Sweden">Sweden</option>
       <option value="Switzerland">Switzerland</option>
       <option value="Syria">Syria</option>
       <option value="Tahiti">Tahiti</option>
       <option value="Taiwan">Taiwan</option>
       <option value="Tajikistan">Tajikistan</option>
       <option value="Tanzania">Tanzania</option>
       <option value="Thailand">Thailand</option>
       <option value="Togo">Togo</option>
       <option value="Tokelau">Tokelau</option>
       <option value="Tonga">Tonga</option>
       <option value="Trinidad & Tobago">Trinidad & Tobago</option>
       <option value="Tunisia">Tunisia</option>
       <option value="Turkey">Turkey</option>
       <option value="Turkmenistan">Turkmenistan</option>
       <option value="Turks & Caicos Is">Turks & Caicos Is</option>
       <option value="Tuvalu">Tuvalu</option>
       <option value="Uganda">Uganda</option>
       <option value="United Kingdom">United Kingdom</option>
       <option value="Ukraine">Ukraine</option>
       <option value="United Arab Erimates">United Arab Emirates</option>
       <option value="United States of America">United States of America</option>
       <option value="Uraguay">Uruguay</option>
       <option value="Uzbekistan">Uzbekistan</option>
       <option value="Vanuatu">Vanuatu</option>
       <option value="Vatican City State">Vatican City State</option>
       <option value="Venezuela">Venezuela</option>
       <option value="Vietnam">Vietnam</option>
       <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
       <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
       <option value="Wake Island">Wake Island</option>
       <option value="Wallis & Futana Is">Wallis & Futana Is</option>
       <option value="Yemen">Yemen</option>
       <option value="Zaire">Zaire</option>
       <option value="Zambia">Zambia</option>
       <option value="Zimbabwe">Zimbabwe</option>
    </select>
  )
}


class InputPlaceOrigen extends Component {
  constructor(props) {
    super(props);
    this.state={
      OrigenListener:'',
      Origen:'',
    }
  }

  handleScriptLoad = () => {
         let options = {types: ['(cities)']};
         // Initialize Google Autocomplete
         /*global google*/
         let Origen = new google.maps.places.Autocomplete(document.getElementById('autocompleteOrigen'),options );
         Origen.addListener('place_changed',this.handlePlaceSelectOrigen);
        this.setState({
          OrigenListener:Origen,
        })

   }

   handlePlaceSelectOrigen=()=> {
        if (this.state.OrigenListener) {
          let DireccionOrigen = this.state.OrigenListener.getPlace();
          let DirEspecificaOrigen = DireccionOrigen.address_components;
          this.props.returnPlace(DireccionOrigen.formatted_address,document.getElementById('autocompleteOrigen'))

        }
    }


  render(){

    return(
      <div>
        <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyCnvzp9aB0tY2tM1YTyACl95z1y3xjL-_4&libraries=places" onLoad={this.handleScriptLoad}/>
        <input  id="autocompleteOrigen" type='text'/>
      </div>
    )
  }
}


class InputPlaceDestino extends Component {
  constructor(props) {
    super(props);
    this.state={
      DestinoListener:'',
      Destino:'',
    }
  }

  handleScriptLoad = () => {
         let options = {types: ['(cities)']};
         // Initialize Google Autocomplete
         /*global google*/

         let Destino = new google.maps.places.Autocomplete(document.getElementById('autocompleteDestino'),options );
         Destino.addListener('place_changed',this.handlePlaceSelectDestino);
        this.setState({
          DestinoListener:Destino,
        })

   }



    handlePlaceSelectDestino=()=> {
       if (this.state.DestinoListener) {
         let DireccionDestino = this.state.DestinoListener.getPlace();
         let DirEspecificaDestino = DireccionDestino.address_components;
         this.props.returnPlace(DireccionDestino.formatted_address,document.getElementById('autocompleteDestino'))
       }
     }

  render(){

    return(
      <div>
        <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyCnvzp9aB0tY2tM1YTyACl95z1y3xjL-_4&libraries=places" onLoad={this.handleScriptLoad}/>
          <input  id="autocompleteDestino" type='text'/>
      </div>
    )
  }
}

const InputTable =(props)=>{

  return(
    <tr key={props.index} className='FormRow FCL'>

      <td className='inputRuta FCL'>
        <input disabled value={props.it.PaisOrigen}  type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.PaisDestino}  type='text'/>
      </td>
      <td className='inputRuta FCL inputOrigen'>
        <input disabled value={props.it.Origen} id="autocompleteOrigen" type='text'/>
      </td>
      <td className='inputRuta FCL inputDestino'>
        <input disabled value={props.it.Destino} id="autocompleteDestino" type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.Trafico} type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.Servicio} type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.ContainerType} type='text'/>

      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.ContainerSize} type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.Tarifa} type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.Insurance} type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.RecargoIMO} type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.PickUp} type='text'/>

      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.BlFee} type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.Thc} type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.Buc} type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.Dre} type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.Cnr} type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.Isp} type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.Ams} type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.TerminalHandling} type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.ContainerCleaning} type='text'/>
      </td>
      <td className='inputRuta FCL'>
        <input disabled value={props.it.Inland} type='text'/>
      </td>
      <div className='Action row'>
        <div onClick={()=>props.BorrarItemRutas(props.index)} className='btn icon negative'><Icon  name='close'/></div>
      </div>
    </tr>
  )
}

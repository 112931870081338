import React, { Component,useState, useEffect } from 'react';
import MenuPublico from '../ComponentesGenerales/Menus/MenuPublico.js';
import Footer from '../ComponentesGenerales/Footer/Footer.js';
import {Icon} from 'semantic-ui-react';
import './LandingStyle.css';
import Header from '../ComponentesGenerales/landing/header-landing.js';
import PublicNavbar from '../ComponentesGenerales/Menus/MenuPublicov2.js';

export default function Contact (){
  return(
    <div className='Contact'>
      <PublicNavbar/>
      <Header video='./imagenes/LandingPage/video/contact2.mp4' texto1='Contact Us'/>
      <section className='seccion0'>
        <div className='FormContent'>
          <div className='TituloContent'>
            <p className='tituloContact'>Get in touch</p>
            <p style={{color:'black'}}>For more information, fill in your contact details and we’ll get back to you. </p>
          </div>
          <div className='Form'>
            <div className='FormItem'>
              <p>Name</p>
              <input type='text'/>
            </div>
            <div className='FormItem'>
              <p>Company</p>
              <input type='text'/>
            </div>
            <div className='FormItem'>
              <p>Email</p>
              <input type='text'/>
            </div>
            <div className='FormItem'>
              <p>Message</p>
              <textarea type='text'/>
            </div>
            <div className='buttonSend'>
              Get in touch
            </div>
          </div>

        </div>
        
      </section>
      <Footer/>

    </div>

  )
}

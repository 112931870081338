import React,{Component} from 'react'
import {Route, BrowserRouter, Redirect, Switch} from 'react-router-dom'
import AdminRoute from './routerAdmin.js'
import AdminSide from './routerAdministracion.js';
import BuscarRuta from '../componentes/ComponentsUser/BuscarRutasUsuario.js'
import BuscarRutaIframe from '../componentes/ComponentsUser/BuscarRutasUsuario_iframe.js'
import About from '../componentes/LandingPage/About.js'
import Membership from '../componentes/LandingPage/Membership.js'
import Contact from '../componentes/LandingPage/Contact.js';
import Faqs from '../componentes/LandingPage/Faqs.js';

import Login from '../componentes/login.js'
import {firebaseAuth} from '../const.js'
import UserRoutes from './routerUser.js'
import confirmacionEmail from '../componentes/ConfirmarEmail.js'
import LoadPage from '../componentes/LoadPage.js'
import { install } from '@material-ui/styles';
import axios from 'axios';
import {Direccion} from '../strings/peticiones.js';
import firebase from 'firebase'
import $ from 'jquery';
//Alert
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-186009436-1');
ReactGA.pageview(window.location.pathname + window.location.search);


function PrivateAdministracion({component: Component,mode, authed,user, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => authed === true &&   mode==='3'
        ? <Component {...props} />
        : <Redirect to={{pathname: '/seller' , state: {from: props.location}}} />}
    />
  )
}

function PrivateRouteSeller ({component: Component,mode, authed,user, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => authed === true &&  mode === "1"
        ? <Component {...props} />
        : <Redirect to={{pathname: '/user' , state: {from: props.location}}} />}
    />
  )
}
function PrivateRouteUser ({component: Component,mode, authed,user, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => authed === true &&  mode === "2"
        ? <Component {...props} />
        : <Redirect to={{pathname: '/login' , state: {from: props.location}}} />}
    />
  )
}

function PublicRoute ({component: Component, authed, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => authed === false
        ? <Component {...props} />
        : <Redirect to='/admin' />}
    />
  )
}

class Routes extends Component{
  constructor(){
    super()
    this.state={
        autenticado:false,
        loading:true,
        user:'',
        mode:"",
        title:"",
        message:"",
        openAlert:false,
      }
  }
  componentDidMount(){
    install();
    this.removeListener=firebaseAuth.onAuthStateChanged((user)=>{
      if(user){
        axios.post(Direccion+`/know_seller`,{email: user.email,id:user.uid })
          .then(res => {
            if(res.data.id){
                this.setState({
                  user:user.email,
                  autenticado:true,
                  loading:false,
                  mode:res.data.id.toString(),
                })
            }
            else {
               this.setState({autenticado:false,loading:false,openAlert:true,message:res.data.message,title:res.data.title})
              firebase.auth().signOut();
            }
          }).catch(function(e){
            console.log(e);
          })
      }
      else{
        this.setState({
          autenticado:false,
          loading:false
        })
      }
    })

    var idleTime = 0;
    $(document).ready(function () {
      //Increment the idle time counter every minute.
      setInterval(function(){
        idleTime++;
        if (idleTime > 1000) { // 20 minutes
          firebase.auth().signOut();
        }
      }, 60000); // 1 minute
      //Zero the idle timer on mouse movement.

      $(this).mousemove(function (e) {
          idleTime = 0;
      });
      $(this).keypress(function (e) {
          idleTime = 0;
      });
    });
  }


  componentWillUnmount(){
    this.removeListener()
  }

  handleClose=()=>{
    this.setState({openAlert:false});
  }
  render(){
    let open = this.state.openAlert;
    return this.state.loading === true ? <LoadPage/> :(
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/"  render={(props)=>{
              ReactGA.pageview('/');
              return(<BuscarRuta {...props} mode={this.state.mode}/>)
            }}/>
            <Route exact path="/id/:idPreCotizacion"  render={(props)=>{
              ReactGA.pageview('/precotizacion');
              return(<BuscarRuta {...props} mode={this.state.mode}/>)
            }}/>
            <Route exact path="/About" component={About}/>
            <Route exact path="/Membership" component={Membership}/>
            <Route exact path="/Contact" component={Contact}/>
            <Route exact path="/Faqs" component={Faqs}/>

            <Route exact path="/cotizador"  component={()=><BuscarRutaIframe mode={this.state.mode} />}/>
            <PublicRoute authed={this.state.autenticado} path="/login" component={Login}/>

            <PrivateAdministracion mode={this.state.mode} user={this.state.user } authed={this.state.autenticado} path="/admin" component={AdminSide}/>
            <PrivateRouteSeller mode={this.state.mode} user={this.state.user } authed={this.state.autenticado} path="/seller" component={AdminRoute}/>
            <PrivateRouteUser mode={this.state.mode} user={this.state.user } authed={this.state.autenticado} path="/user" component={UserRoutes}/>
            <Route path="/confirm_email" component={confirmacionEmail}/>
            <Route render={()=><h3> Error 404 </h3>}/>
          </Switch>
        </BrowserRouter>
        <Alerta title={this.state.title} handleClose = {this.handleClose} open={open} message={this.state.message}/>
     </div>
    );
  }
}
class Alerta extends Component{
  constructor(){
    super()
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose=()=>{
    this.props.handleClose();
  }
    render(){
      return (
          <div>
             <Dialog
               open={this.props.open}
               onClose={this.handleClose}
               aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description"
             >
               <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
               <DialogContent>
                 <DialogContentText id="alert-dialog-description">
                   {this.props.message}
                 </DialogContentText>
               </DialogContent>
               <DialogActions>
                 <Button onClick={this.handleClose} color="primary" autoFocus>
                   Agree
                 </Button>
               </DialogActions>
             </Dialog>
         </div>
      )}
}


export default Routes;

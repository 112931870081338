import React, { Component } from 'react';

import { Divider, Header, Icon, Table, Modal,Grid,Message, Button, Form, Input, Card} from 'semantic-ui-react';
import Particles from 'react-particles-js';
import FinalizadoProceso from '../scripts/filtrarProcesoFinalizados.js';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import axios from 'axios';
import {Direccion} from '../../strings/peticiones.js';
import firebase from 'firebase'
import BarraLateral from './sideBar.js';




import './styleSeller/pagosVendedor.css'


class PagosVendedor extends Component{
  constructor(){
    super()
    var date = new Date();
    let month = date.getMonth()+1;
    let day = date.getDate();
    let year=date.getFullYear();

      if (month <10) {
          month = "0"+month;
        }
      if (day<10) {
        day = "0"+day;
      }
    this.state={
      valueTabs:0,
      selectedDate: date,
      fechaFormulario1:'2019-01-01',
      fechaFormulario2:date.getFullYear()+'-'+month +'-'+day,
      pagos:[],
      valoresGrafica:{totalProceso:0,totalPagados:0,valores:[]},
      mesGrafica:month,
      anioGrafica:year,

    }
  }

    UNSAFE_componentWillMount(){
      this.getEnvios();

    }

  getEnvios(){
    try{
      let datos;
      let self=this;
      let pagos=[];
      var valoresGraph;
      var date = new Date();
      let month = date.getMonth()+1;
      let year=date.getFullYear();

      axios.post(Direccion+`/tomarPagos`,{data:{usuario:firebase.auth().currentUser.uid,inicio:this.state.fechaFormulario1,fin:this.state.fechaFormulario2}})
        .then(res => {
          datos=res.data;
          pagos=FinalizadoProceso.cantidadDiferenciaPagos(datos);
          valoresGraph=FinalizadoProceso.Cuentas(pagos.proceso,pagos.terminados,month,year);

          self.setState({
            pagados:pagos.terminados,
            proceso:pagos.proceso,
            mesGrafica:month,
            anioGrafica:year,
            valoresGrafica:valoresGraph
          })
        })
    }catch(e){
      console.log(e);
    }
  }

  handleChangeMesMas(){
    var mesActual=this.state.mesGrafica;
    var anioActual=this.state.anioGrafica;
    var mesCambio;
    var anioCambio;
    if(mesActual===12){
      mesCambio=1
      anioCambio=parseInt(anioActual)+1;
      var valoresGraph=FinalizadoProceso.Cuentas(this.state.proceso,this.state.pagados,mesCambio,anioCambio);
      this.setState({
        mesGrafica:mesCambio,
        anioGrafica:anioCambio,
        valoresGrafica:valoresGraph
      })
    }
    else{
      mesCambio=parseInt(mesActual)+1;
      var valoresGraph=FinalizadoProceso.Cuentas(this.state.proceso,this.state.pagados,mesCambio,anioActual);

      this.setState({
        mesGrafica:mesCambio,
        valoresGrafica:valoresGraph
      })
    }

  }
  handleChangeMesMenos(){
    var mesActual=this.state.mesGrafica;
    var anioActual=this.state.anioGrafica;
    var mesCambio;
    var anioCambio;
    if(mesActual===1){
      mesCambio=12
      anioCambio=parseInt(anioActual)-1;
      var valoresGraph=FinalizadoProceso.Cuentas(this.state.proceso,this.state.pagados,mesCambio,anioCambio);

      this.setState({
        mesGrafica:mesCambio,
        anioGrafica:anioCambio,
        valoresGrafica:valoresGraph

      })
    }
    else{
      mesCambio=parseInt(mesActual)-1;
      var valoresGraph=FinalizadoProceso.Cuentas(this.state.proceso,this.state.pagados,mesCambio,anioActual);
      this.setState({
        mesGrafica:mesCambio,
        valoresGrafica:valoresGraph

      })
    }
  }

  handleChangeTab=(event, valueTabs)=>{
      this.setState({ valueTabs });
    };


    handleDateChange=(date)=>{

      let fecha=date.target.value;

       this.setState({
         fechaFormulario1:fecha
       })
     }
     handleDateChange2=(date)=>{
       let fecha=date.target.value;

        this.setState({
          fechaFormulario2:fecha
        })
      }


  render(){

  var meses=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    return(

      <div className="div-principal-pagos-vendedor">

        <BarraLateral/>
        <div style={{position:'relative'}}>
          <Particles style={{backgroundImage:'url(../imagenes/accounting.jpg)',backgroundSize:'cover',backgroundPosition:'100% -19%', position:'absolute',height:'500px'}}
               params={{
                 "particles": {
                     "number": {
                         "value": 40
                     },
                     "size": {
                         "value": 2
                     }
                 },
                 "interactivity": {
                     "events": {
                         "onhover": {
                             "enable": true,
                             "mode": "repulse"
                         }
                     }
                 }
             }} />
        <div>
        <Divider horizontal style={{paddingTop:'100px',width:'100%'}}>
          <Header as='h3'style={{position:'relative',color:'white'}}>
              <i class="material-icons" style={{ fontSize: '2em' }}>payment</i>
              My accounting
          </Header>
        </Divider>



            <div className="contentBuscador">
              <div className="Buscador" >
                <input type="text" onChange={this.handleDateChange} placeholder='From' onFocus={(e)=> e.currentTarget.type = "date"} onBlur={(e)=> {e.currentTarget.type = "text"; e.currentTarget.placeholder = "From"}}/>
                <input type="text" onChange={this.handleDateChange2} placeholder='to' onFocus={(e)=> e.currentTarget.type = "date"} onBlur={(e)=> {e.currentTarget.type = "text"; e.currentTarget.placeholder = "to"}}/>
                <div  className='btnBuscarFecha' onClick={this.getEnvios.bind(this)} >Search</div>
              </div>
            </div>
          </div>
        </div>

        <div style={{width:"50%"}}>

          <Tabs value={this.state.valueTabs} onChange={this.handleChangeTab}variant="fullWidth"  indicatorColor="secondary" textColor="primary">
            <Tab icon={<i class="material-icons">compare_arrows</i>} label="Due payments" ></Tab>
            <Tab icon ={<i class="material-icons">done_outline</i>} label="Paid invoices"></Tab>
          </Tabs>


          {this.state.valueTabs===0&&this.state.proceso!=undefined?
          <div className="div-tab-pagos-en-proceso">

            <div className="div-card-pagos-vendedor">
              {
                this.state.proceso.map((it,key)=>{
                  return(<CardPagosVendedor datos={it}/>)
                })
              }
            </div>
          </div>
          :
          <div></div>
        }

          {this.state.valueTabs===1 && this.state.pagados!=undefined?
          <div className="div-tab-pagos-en-proceso">

            <div className="div-card-pagos-vendedor">

            {
              this.state.pagados.map((it,key)=>{
                return(<CardPagosVendedor datos={it}/>)
              })

            }

            </div>

          </div>
          :
          <div></div>
        }
        </div>

        <div className="div-card-ventas-mes-vendedor">
          <h4 style={{width:'80%',marginLeft:'auto',marginRight:'auto'}}> {this.state.anioGrafica}</h4>

          {/* <CryptoCard
            currencyName={'income of '+meses[this.state.mesGrafica-1]}
            currencyPrice={'$'+this.state.valoresGrafica.totalPagados +' USD'}
            icon={<img src="https://dolar.wilkinsonpc.com.co/img/icono-graficos-dolar.png"/>}
            currencyShortName='Future payments'
            trend={'$'+this.state.valoresGrafica.totalProceso}
            trendDirection={1}
            chartData={this.state.valoresGrafica.valores}
          /> */}

          <Button icon labelPosition='left' onClick={this.handleChangeMesMenos.bind(this)} style={{width:'45%'}}>
            <Icon name='angle left' />
            last month
          </Button>
          <Button icon labelPosition='right' onClick={this.handleChangeMesMas.bind(this)}style={{width:'45%'}}>
            Next month
            <Icon name='angle right' />
          </Button>
        </div>
      </div>
    )
  }
}

class CardPagosVendedor extends Component{

  constructor(){
    super();

    this.state={
      open: false,
      dimmer:true,
    }
  }



  show = dimmer => () => this.setState({ dimmer, open: true })

  close = () => this.setState({ open: false })

  render(){
    var datos=this.props.datos;
    if (datos.Comision === undefined) {datos.Comision = 0}
    var PrecioSNComision = (parseFloat(datos.Amount) - parseFloat(datos.Comision)).toFixed(2);
    return(

      <div className="div-principal-card-pagos-vendedor" >

        <Card  style={{borderRadius:'2vh', width:'90%', minWidth:'250px', margin:' 1em 2.5em'}}>
          <Card.Content>

          <div style={{width:"100%", display:"inline-flex"}}>

            <div style={{ width:"25%"}}>
              <Card.Meta>ID Transaction</Card.Meta>
              <Card.Header>{datos.key}</Card.Header><br/>

              <Card.Meta>Date</Card.Meta>
              <Card.Header>{datos.dateTime}</Card.Header><br/>

            </div>

            <div style={{marginLeft:"10%", width:"30%"}}>
              <Card.Meta>Amount</Card.Meta>
              <Card.Header>${PrecioSNComision} USD</Card.Header><br/>
              <Card.Meta>Reason</Card.Meta>
              {datos.PagoOrigen === 1?
                <div>
                 <Card.Header>rate payment</Card.Header><br/>
                </div>
                :datos.PagoOrigen === 2?
                <div>
                 <Card.Header>subscription payment</Card.Header><br/>
                </div>
                :datos.PagoOrigen === 3?
                <div>
                 <Card.Header>rate cancellation payment</Card.Header><br/>
                </div>
                :
                <div>
                 <Card.Header></Card.Header><br/>
                </div>

              }
            </div>

            <div style={{marginLeft:"10%"}}>
              <Card.Meta>Card number</Card.Meta>
              <Card.Header>{datos.displayNumber}</Card.Header><br/>

              <Card.Meta>Description</Card.Meta>
              <Card.Header>{datos.Descripcion}</Card.Header><br/>
            </div>

            <div className='IconoRight'>
            {datos.PagoType === 'abono'?
              <Icon style={{marginLeft:'23%'}} color='teal' size='big'  name='angle up' />:<Icon style={{marginLeft:'23%'}} color='red' size='big'  name='angle down' />
            }

            </div>

          </div>

          </Card.Content>

        </Card>
      </div>
    )
  }
}


export default PagosVendedor;

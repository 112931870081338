function SubirDato(direccion,firebaseStorage,archivo){
var downloadURL;
const ref=firebaseStorage.ref(direccion);
const task=ref.put(archivo);
var promise = new Promise(
        function(resolve,reject){
            task.on('state_changed',function(snapshot){

            },(error) =>{
              alert(error);
            },()=>{
              task.snapshot.ref.getDownloadURL().then(function(dURL) {
                resolve(downloadURL= dURL);
              });

            })
        })
        return promise;
}


exports.SubirDato=SubirDato;

import './header-landing.css';

const Header=(props)=>{
 
    return(
        <section className='firstContainer'>
             <video  loop autoPlay muted>
                 <source
                 src={props.video}
                 />
                 Your browser does not support the video tag.
             </video> 
             <div className='videoOverlay' style={{background:props.background}}></div>  
                 <div className='text'>
                         <h1 style={{color:props.letras}}>{props.texto1}</h1>
                         <p style={{color:props.letras}}>{props.texto2}</p>
                 </div>
         </section> 

             )

}
export default Header;
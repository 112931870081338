
function DeterminarImagen(transporte,medidas,tipo){
var imagen;
switch(transporte){
  case 'FCL':
    if(medidas==='20'){
      imagen='container-20.png';
    }
    else if(medidas==='40'){
      imagen='container-40.png';
    }
    else{
      imagen='container-20.png';
    }
  break;

  case 'BBulk':
    imagen='vessel.png';
  break;
  case 'FCL-Truck':
    if(medidas==='20'&& tipo==='ST'){
      imagen='/camiones/20fcl.png';
    }
    else if(medidas==='40'&& tipo==='ST'){
      imagen='/camiones/40fcl.png';
    }
    else if(medidas==='40'&& tipo==='HQ'){
      imagen='/camiones/40-hq-fcl.png';
    }
    else if(medidas==='20'&& tipo==='REF'){
      imagen='/camiones/20-fcl-ref.png';
    }
    else if(medidas==='40'&& tipo==='REF'){
      imagen='/camiones/40fcl-ref.png';
    }else{
      imagen='/camiones/20fcl.png';
    }
  break;
  case 'FTL-Truck':
    if(tipo==='DRY'){
      imagen='/camiones/53-ftl.png';
    }
    else if (tipo==='REF'){
      imagen='/camiones/53-ftl-ref.png';
    }
    else if(tipo==='PLAT'){
      imagen='/camiones/53-ftl-plat.png'
    }else{
      imagen='/camiones/20fcl.png';
    }
  break;
  case 'FCL-Ferro':

    if(medidas==='20'){
      imagen='/camiones/fclFerro-20.png';
    }
    else if(medidas==='40'){
      imagen='/camiones/fclFerro-40.png';
    }else{
      imagen='/camiones/fclFerro-20.png';
    }

  break;
  case 'Wagon':

    if(tipo==='Dry'){
      imagen='/camiones/wagon01.png';
    }
    else if(tipo==='Tank'){
      imagen='/camiones/wagon02.png';
    }
    else if(tipo==='Flat'){
      imagen='/camiones/wagon05.png';
    }
    else if(tipo==='Hopper'){
      imagen='/camiones/wagon04.png';
    }else{
      imagen='/camiones/wagon01.png';
    }

  break;

  default:
  imagen='pallet.png'
  break;

}
  return imagen;
}

function DeterminarImagenSimple(transporte,){
var imagen;
switch(transporte){
  case 'FCL':
    imagen='container-20.png';
  break;

  case 'BBulk':
    imagen='vessel.png';
  break;
  case 'FCL-Truck':
    imagen='/camiones/20fcl.png';

  break;
  case 'FTL-Truck':
    imagen='/camiones/53-ftl.png';
  break;
  case 'FCL-Ferro':
    imagen='/camiones/fclFerro-20.png';
  break;
  case 'Wagon':
    imagen='/camiones/wagon01.png';
  break;

  default:
  imagen='pallet.png'
  break;
}
  return imagen;
}



exports.DeterminarImagen=DeterminarImagen;
exports.DeterminarImagenSimple=DeterminarImagenSimple;

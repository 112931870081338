import React, { Component,useState, useEffect } from 'react';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import {Router, Route, BrowserRouter, Switch, Link} from 'react-router-dom'
import { Divider, Header,Step,Accordion, Icon,Menu,Table,Modal,Button,Form,Rating,Dimmer,Loader,Input,Card,Image,Label,Segment,Grid} from 'semantic-ui-react';
import './styleUser/BuscarRuta.css';
import StepperStatus from './StepperStatusRutaPublica.js'
import 'filepond/dist/filepond.min.css';
import { FilePond } from 'react-filepond';
import BarraLateral from './sideBar.js';
import BarraLateralVendedor from '../ComponentsSeller/sideBar.js';
import BarraLateralAdmin from '../ComponentsAdmin/sideBarAdmin.js';
import MenuPublico from '../ComponentesGenerales/Menus/MenuPublico.js';
import axios from 'axios';
// import TipoEnvio from './ventanaTipoEnvio.js';
import firebase from 'firebase'
import {Idioma} from '../../strings/BuscarRutaText.js';
import {Direccion,DireccionFront} from '../../strings/peticiones.js';
import Script from 'react-load-script';
// import TipoEnvioSinContenedor from './ventanaTipoSinConte.js';
// import TipoEnvioTerrestre from './ventanaTipoTerrestre.js';
// import TipoEnvioFerro from './ventanaTipoFerro.js';
import Particles from 'react-particles-js';
import DeterminarImagen from '../scripts/imagenes.js';
import Login from '../login.js'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import AlertSnack from '../notificacionCard.js';
import ReactGA from 'react-ga';
import CalculadorCBM from '../scripts/CalculadorCBM.js';


ReactGA.pageview(window.location.pathname + window.location.search);


const responsiveCarousel = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


class BuscarRuta extends Component{
  constructor(){
    super()
    this.state={
      openVentana:false,
      openVentanaSinContenedor:false,
      medioTransporte:"",
      cityOrigen: [],
      queryOrigen: '',
      cityDestino: [],
      queryDestino: '',
      Destino: '',
      Orgien: '',
      OrigenSec:'',
      DestinoSec:'',
      buscando:false,
      rutasEncontradas:[],
      openVentanaTerrestre:false,
      openVentanaFerro:false,
      noEncontrado:false,
      openAlert:false,
      AlertMessage:'',
      AlertType:'',
      RutasRandom:[],
      confirmarPrecotizacion:false,
      lan:navigator.language || navigator.userLanguage,

    }
    this.handleScriptLoad= this.handleScriptLoad.bind(this);

  }

  handleScriptLoad() {
   // Declare Options For Autocomplete
   console.log('entro');
     var options = {
       types: ['(cities)'],
       };

   // Initialize Google Autocomplete
   /*global google*/

     let Origen = new google.maps.places.Autocomplete(document.getElementById('autocompleteOrigen'),options );
     Origen.addListener('place_changed',this.handlePlaceSelect);

     let Destino = new google.maps.places.Autocomplete(document.getElementById('autocompleteDestino'),options );
     Destino.addListener('place_changed',this.handlePlaceSelect2);
     this.setState({
       Destino:Destino,
       Origen:Origen
     });
   }

   handlePlaceSelect=()=> {

      // Extract City From Address Object
      // Check if address is valid
      if (this.state.Origen ) {
        let DireccionOrigen = this.state.Origen.getPlace();
        let DirEspecificaOrigen = DireccionOrigen.address_components;


        let OrigenSec = document.getElementById('autocompleteOrigen').value;
        this.setState(
          {
            OrigenSec:OrigenSec,
            cityOrigen: DireccionOrigen.address_components,
            queryOrigen: this.BlockDirecciones(DirEspecificaOrigen),
          }
        );
      }

  }
  handlePlaceSelect2=()=> {
    try{
      // Extract City From Address Object
   // Check if address is valid
      if (this.state.Destino) {

          let DireccionDestino = this.state.Destino.getPlace();
          let DirEspecificaDestino = DireccionDestino.address_components;
          let DestinoSec = document.getElementById('autocompleteDestino').value;

          this.setState(
            {
              DestinoSec:DestinoSec,
              cityDestino: DireccionDestino.address_components,
              queryDestino: this.BlockDirecciones(DirEspecificaDestino),
            }
          );
        }
      }catch(e){
        alert("select a place in the autocomplet");
      }

    }

  BlockDirecciones=(ArrayAux)=>{
    let stringExt = "";
    for (var i = 0; i < ArrayAux.length; i++) {
      stringExt += ArrayAux[i].long_name+",";
    }
    return stringExt;
  }


  abrirTipo=()=>{
    if (this.state.medioTransporte) {
      if (this.state.medioTransporte === "mar") {
        this.setState({
          openVentana:true
        })
      }
       else if(this.state.medioTransporte ==="Aereo") {
        this.setState({
          openVentanaSinContenedor:true
        })
      }
      else if(this.state.medioTransporte==='carretera'){
        this.setState({
          openVentanaTerrestre:true
        })
      }
      else if(this.state.medioTransporte==='ferrocaril'){
        this.setState({
          openVentanaFerro:true
        })
      }

    }
    else {

      this.setState({
          openAlert:true,
          AlertMessage:'Select a means of transport',
          AlertType:'warning',
        })
    }

  }
  callbackVentana=(value)=>{
      //aqui es llegada de los objetos de busqueda para peticion de el server
      //la condicional es porque recibe dos tipos de objetos
      //objeto con la descripcion y un objeto = {false}
      //este ultimo por si el usuario cierra la ventana sin llenar ni un campo o cancela
    if(value){
    var a = value;
    a["Origen"]= this.state.queryOrigen;
    a["Destino"]= this.state.queryDestino;
    a["Transporte"]= this.state.medioTransporte;
    this.setState({
      openVentana:false,
      openVentanaSinContenedor:false,
      openVentanaTerrestre:false,
      openVentanaFerro:false,
      ObjBusqueda: a,
    })

    }
    else {
      this.setState({
        openVentanaSinContenedor:false,
        openVentana:false,
        openVentanaTerrestre:false,
        openVentanaFerro:false
      })
    }
  }
  GuardarPrecotizacion=()=>{
    this.setState({rutasEncontradas:[],buscando:false,noEncontrado:false,})
    try{
      let self=this;
      if (this.ValidarPeticion()) {
        let busqueda=this.state.ObjBusqueda;
        let cotizaciones=[];
        busqueda["Origen"] = this.state.OrigenSec;
        busqueda["Destino"] = this.state.DestinoSec;
        busqueda["Transporte"] = this.state.medioTransporte;
         this.setState({buscando:true})
        axios.post(Direccion+`/guardar-precotizacion`,{data:busqueda,type:this.state.medioTransporte,OrigenBlock:this.state.cityOrigen,DestinoBlock:this.state.cityDestino,DestinoSec:this.state.DestinoSec,OrigenSec:this.state.OrigenSec,emailPreCotizacion:this.state.emailPreCotizacion })
        .then(res => {
          if (res.data.status ==='OK') {
            // window.open("https://ceif.app/id/"+res.data.idPrecotizacion, "_blank");
            this.setState({buscando:false,confirmarPrecotizacion:false,emailPreCotizacion:''})
            window.open(DireccionFront+"/id/"+res.data.idPreCotizacion, "_blank");
          }
          else{
             this.setState({
              openAlert:true,
              AlertMessage:'Error! something went wrong',
              AlertType:'error',
              buscando:false,
              noEncontrado:true,
            })
          }
        })
      }

    }catch(e){
      console.log(e);
    }

  }

  ValidarPeticion=()=>{
    if (!this.state.medioTransporte) {
      this.setState({
        openAlert:true,
        AlertMessage:'select a means of transport ',
        AlertType:'warning',
      })
      return false;
    }
    else if(!this.state.OrigenSec){
      this.setState({
        openAlert:true,
        AlertMessage:'select an origin',
        AlertType:'warning',
      })
      return false;

    }
    else if(!this.state.DestinoSec){
      this.setState({
        openAlert:true,
        AlertMessage:'select a destination',
        AlertType:'warning',
      })
      return false;

    }else{
      return true;

    }
  }



 handleCloseAlert=()=>{this.setState({openAlert:false,})}
  resetAlert = ()=> this.setState({ openAlert:false,titleAlert: '', AlertMessage:'', AlertType:''});
  goToCeif=()=>{
    window.open("https://ceif.app/login", "_blank");
  }
  render(){
    let text=Idioma('ES').textos;
    const { classes } = this.props;
    return(
      <div className="ParentCotizador" >
        <div className="ContentCotizadorIframe" >
          <div className='header'>
            <Menu pointing style={{fontSize:'15px',backgroundColor:'rgba(243,49,49,1)'}}>
              <Menu.Menu position='right'>
                  <Link to="/login">
                    <Menu.Item style={{color:'#fff',fontWeight:'900'}}>
                      Login | Register
                    </Menu.Item>
                  </Link>
              </Menu.Menu>
            </Menu>
          </div>
           <div style={{position:'relative'}}>
             <Particles style={{backgroundImage:'url(../imagenes/ceifBuscarRutas.jpg)', backgroundSize:'cover',backgroundPosition:'bottom', position:'absolute',}}
                params={{
                  "particles": {
                      "number": {
                          "value": 40
                      },
                      "size": {
                          "value": 3
                      }
                  },
                  "interactivity": {
                      "events": {
                          "onhover": {
                              "enable": true,
                              "mode": "repulse"
                          }
                      }
                  }
                 }}
             />
             <div >
               <AlertSnack openAlert={this.state.openAlert} resetAlert={this.resetAlert} titleAlert={this.state.titleAlert}  AlertMessage={this.state.AlertMessage} AlertType={this.state.AlertType}/>
               <div className='btnGrupoTipo'>
                 <div className={this.state.medioTransporte==="Aereo"?'btnTipo activo air':'btnTipo'} onClick={()=>this.setState({medioTransporte:'Aereo'})} >
                   <div className={this.state.medioTransporte==="Aereo"?'contentIcon activo air':'contentIcon air'}>
                     <div className='iconTipo air' ><img src='../imagenes/icons/air_icon.svg' /></div> <p>{text.tipoEnvioBotones.aire}</p>
                   </div>
                 </div>
                 <div className={this.state.medioTransporte==="mar"?'btnTipo activo mar':'btnTipo'} onClick={()=>this.setState({medioTransporte:'mar'})} >
                   <div className={this.state.medioTransporte==="mar"?'contentIcon activo mar':'contentIcon mar'}>
                     <div className='iconTipo' ><img src='../imagenes/icons/ocean_icon.svg' /></div> <p>{text.tipoEnvioBotones.mar}</p>
                   </div>
                 </div>
                 <div className={this.state.medioTransporte==="carretera"?'btnTipo activo carretera':'btnTipo'} onClick={()=>this.setState({medioTransporte:'carretera'})} >
                   <div className={this.state.medioTransporte==="carretera"?'contentIcon activo carretera':'contentIcon carretera'}>
                     <div className='iconTipo' ><img src='../imagenes/icons/carre_icon.svg' /></div> <p>{text.tipoEnvioBotones.carretera}</p>
                   </div>
                 </div>
                 <div className={this.state.medioTransporte==="ferrocaril"?'btnTipo activo ferrocaril':'btnTipo'} onClick={()=>this.setState({medioTransporte:'ferrocaril'})} >
                   <div className={this.state.medioTransporte==="ferrocaril"?'contentIcon activo ferrocaril':'contentIcon ferrocaril'}>
                     <div className='iconTipo' ><img src='../imagenes/icons/ferro_icon.svg' /></div> <p>{text.tipoEnvioBotones.ferrocaril}</p>
                   </div>
                 </div>
               </div>

                <div id="inputFiltro">
                  <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyCnvzp9aB0tY2tM1YTyACl95z1y3xjL-_4&libraries=places" onLoad={this.handleScriptLoad}/>
                  <Input onChange={(e)=>this.setState({OrigenSec:e.target.value})} icon='map marker alternate' error={this.state.OrigenError} id="autocompleteOrigen" placeholder={text.inputs.origen}  />
                  <Input onChange={(e)=>this.setState({DestinoSec:e.target.value})} icon='map marker alternate' error={this.state.DestinoError}  id="autocompleteDestino" placeholder={text.inputs.destino}/>
                  <Input icon='box' placeholder={text.inputs.tipo} onClick={this.abrirTipo} readOnly/>
                </div>

                <br/>
                <Button onClick={()=>this.setState({confirmarPrecotizacion:true})} style={{width:'50%',marginTop:'5vh',marginLeft:'25%',marginBottom:'7vh',position:'relative', backgroundColor:'rgb(232, 133, 10)'}} secondary circular={true}>{text.botones.buscar}</Button>
              </div>
            </div>
             {/* <TipoEnvio callBackVentana={this.callbackVentana} open={this.state.openVentana}/>
             <TipoEnvioSinContenedor callBackVentana={this.callbackVentana} open={this.state.openVentanaSinContenedor}/>
             <TipoEnvioTerrestre callBackVentana={this.callbackVentana} open={this.state.openVentanaTerrestre}/>
             <TipoEnvioFerro callBackVentana={this.callbackVentana} open={this.state.openVentanaFerro}/> */}
           <Dimmer page active={this.state.buscando} page>
           <Loader>
             <Header as='h2' icon inverted>
               Searching ....
             </Header>
           </Loader>
           </Dimmer>
           <Modal onClose={()=>this.setState({confirmarPrecotizacion:true})} open={this.state.confirmarPrecotizacion}>
             <Modal.Content>
               <div className='confirmWindow'>
                 <img src='../imagenes/mail.png'></img>
                 <div className='formModal'>
                   <h3>Enter an email to receive future discounts, promotions or information related to this rate</h3>
                   <input onChange={(e)=>this.setState({emailPreCotizacion:e.target.value})}></input>
                   <div onClick={this.GuardarPrecotizacion} className='btnModal positive'>Send email and view rate</div>
                 </div>
               </div>
               <div onClick={this.GuardarPrecotizacion} className='btnModal skiping'>Skip and view rate</div>
             </Modal.Content>
           </Modal>
        </div>

      </div>

    )
  }
}




export default BuscarRuta;

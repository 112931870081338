import React, { Component } from 'react';
import { Divider,Segment,Label,Popup,Grid, Header,Pagination, Icon,Table,Modal,Button,Form,Dimmer, Loader, Dropdown} from 'semantic-ui-react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import '../../ComponentsSeller/styleSeller/rutasVendedor.css';
import {ref,firebaseAuth} from '../../../const.js';
import firebase from 'firebase'
import axios from 'axios';
import {Idioma} from '../../../strings/RutasSellerText.js';
import AddIcon from '@material-ui/icons/Add';
import {Direccion} from '../../../strings/peticiones.js';
import organizacionArray from '../../OrganizarRutas.js';
import Fab from '@material-ui/core/Fab';
import Script from 'react-load-script';
import SearchBar from 'material-ui-search-bar'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import XLSX from 'xlsx';
import { DatePicker } from 'material-ui-pickers';

class TypeMar extends Component{
  constructor(){
    super()

  }


  render(){
    return(

      <div>
      <RutasDetalle usuario={this.props.usuario}/>
      </div>
    )
  }
}


const ContainerType =[
  {key:'ST',text:'ST',value:'ST'},
  {key:'HQ',text:'HQ',value:'HQ'},
  {key:'RF',text:'RF',value:'RF'},
  {key:'OT',text:'OT',value:'OT'},
  {key:'FR',text:'FR',value:'FR'}
];
const ContainerSize =[
  {key:'20',text:'20',value:'20'},
  {key:'40',text:'40',value:'40'}
];

class RutasDetalle extends Component{
  constructor(){
    super();
    var date = new Date();

    this.state={
      valueTabs:0,
      open: false,
      openFecha: false,

      dimmer:true,
      active:false,

      file:{},
      rutas:[],
      rutasOpen:false,
      RutasActivas:[],
      RutasNoActivas:[],
      type:0,
      currentPageActivas:1,
      currentPageNoActivas:1,
      PerPageActivas:21,
      PerPageNoActivas:21,
      currentTodosActivas:[],
      currentTodosNoActivas:[],

    }

        this.GetRutas = this.GetRutas.bind(this);
        this.handleOpenFecha = this.handleOpenFecha.bind(this);


  }

  UNSAFE_componentWillMount(){
    this.GetRutas(true);

  }


  GetRutas=(e)=>{
    let self=this;
    let rutas=[];
    let usuario=this.props.usuario;
    axios.post(Direccion+'/tomar-ruta',{user:usuario, type:1})
    .then(res=>{
      rutas=res.data;
      if (res.data.activas) {
        self.setState({
          RutasActivas:rutas.activas,
          RutasNoActivas:rutas.noActivas
        })
      }
      else if(res.data.activas.length===0 && res.data.activas.length>0 ) {
        self.setState({
          RutasActivas:[],
          RutasNoActivas:rutas.noActivas,

        })

      }
      else if (res.data.activas.length===0 && res.data.activas.length>0) {
        self.setState({
          RutasNoActivas:[],
          RutasActivas:rutas.activas,

        })
      }
      else if (res.data.activas.length===0 && res.data.activas.length===0) {
        console.log(res.data);
        self.setState({
          RutasNoActivas:[],
          RutasActivas:[],
        })
      }

    })

  };


  show = dimmer => () => this.setState({ dimmer, open: true })
  close = () => this.setState({ open: false })
  closeFecha = () => this.setState({ openFecha: false })
  handleOpenFecha = () => this.setState({ openFecha: true })

  rutaclose = () => this.setState({ rutasOpen: false,rutas:[], })


    handleChangeTab=(event, valueTabs)=>{
      this.setState({ valueTabs });
    };
    handleChangeTabtype=(event, type)=>{
      this.setState({ type });
    };


  handleRefNuevaExpiracion=(e)=>{


        var valor=e.target.value;
        this.setState({
          NuevaExpiracion:valor,
        })
      }

  NuevaFecha=()=>{
        axios.post(Direccion+`/ActualizarRutas`,
          {Ruta:this.state.RutaActualizar,idUser:this.props.usuario,type:'FCL',Modificar:this.state.Modificar,NuevaFechaExpiracion:this.state.NuevaExpiracion})
          .then(res => {
            if (res.data === 'OK') {
              this.setState({
                NuevaExpiracion:'',
                openFecha:'',
              })
              this.GetRutas();

            }
          })

      }

  handleOpenFecha=(value,Ruta)=>{
        console.log("vengo de regreso");
        console.log(value);
        this.setState({
          Modificar:value,
          openFecha:true,
          RutaActualizar:Ruta
        })
    }

  render(){
    const { open, dimmer } = this.state
    let text=Idioma('ES');
    let type = this.state.type;
    const indexOfLastTodoActivas = this.state.currentPageActivas * this.state.PerPageActivas;
    const indexOfLastTodoNoActivas = this.state.currentPageNoActivas * this.state.PerPageNoActivas;

    const indexOfFirstTodoActivas = indexOfLastTodoActivas - this.state.PerPageActivas;
    const indexOfFirstTodoNoActivas = indexOfLastTodoNoActivas - this.state.PerPageNoActivas;

    const currentTodosActivas = this.state.RutasActivas.slice(indexOfFirstTodoActivas, indexOfLastTodoActivas);
    const currentTodosNoActivas = this.state.RutasNoActivas.slice(indexOfFirstTodoNoActivas, indexOfLastTodoNoActivas);

    const renderCompanyActivas = currentTodosActivas.map((it,key)=>{
     return(<Item handleOpenFecha={this.handleOpenFecha} ActualizarCallback={this.GetRutas} fila={it} value={0}/>)
    })
    const renderCompanyNoActivas = currentTodosNoActivas.map((it,key)=>{
      return(<Item handleOpenFecha={this.handleOpenFecha} ActualizarCallback={this.GetRutas} fila={it} value={1}/>)
    })

    const pageNumbersActivas =  Math.ceil(this.state.RutasActivas.length / this.state.PerPageActivas);
    const pageNumbersNoActivas =  Math.ceil(this.state.RutasNoActivas.length / this.state.PerPageNoActivas);

    return(

      <div className='header-rutasMar'>
        <Dimmer active={this.state.active}>
          <Loader size='large'>{text.dimmer}</Loader>
        </Dimmer>



          <Tabs value={this.state.valueTabs} onChange={this.handleChangeTab}variant="fullWidth"  indicatorColor="secondary" textColor="primary">
            <Tab icon={<i class="material-icons">update</i>}
              label={
                 <React.Fragment>
                  Active rates<br />
                  <span style={{ fontSize: "smaller", margin:'0px auto', textAlign:'center' }}>{this.state.RutasActivas.length}</span>
                </React.Fragment>
              }>
            </Tab>
            <Tab icon ={<i class="material-icons">verified_user</i>}
              label={
                 <React.Fragment>
                  Expired rates<br />
                  <span style={{ fontSize: "smaller", margin:'0px auto', textAlign:'center' }}>{this.state.RutasNoActivas.length}</span>
                </React.Fragment>
              }>
            </Tab>
          </Tabs>
            <br/>

            {this.state.valueTabs===0?
              <div>
                <div className='contentTable'>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{text.RutaSimple.pais}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.origen}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.destino}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.trafico}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.servicio}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.ContainerType}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.ContainerSize}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Tarifa}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Insurance}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.PickUp}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.BlFee}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Thc}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Buc}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Dre}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Cnr}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Isp}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Ams}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.OceanFreight}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.ImportCustomFee}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.ImportDuties}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.TerminalHandling}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.ContainerCleaning}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Inland}</Table.HeaderCell>

                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {renderCompanyActivas}
                    </Table.Body>
                  </Table>
                </div>
                <div id='Paginacion'>
                 <Pagination
                    onPageChange={(e,index)=>this.setState({currentPageActivas:index.activePage})}
                    defaultActivePage={1}
                    totalPages={pageNumbersActivas}
                    activePage={this.state.currentPageActivas}
                   />
               </div>
             </div>
            :<div></div>
            }
            {this.state.valueTabs===1?
              <div>
                <div className='contentTable'>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{text.RutaSimple.pais}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.origen}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.destino}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.trafico}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.servicio}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.ContainerType}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.ContainerSize}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Tarifa}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Insurance}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.PickUp}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.BlFee}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Thc}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Buc}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Dre}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Cnr}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Isp}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Ams}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.OceanFreight}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.ImportCustomFee}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.ImportDuties}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.TerminalHandling}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.ContainerCleaning}</Table.HeaderCell>
                          <Table.HeaderCell>{text.RutaSimple.Inland}</Table.HeaderCell>

                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {renderCompanyActivas}
                    </Table.Body>
                  </Table>
                </div>
                <div id='Paginacion'>
                 <Pagination
                    onPageChange={(e,index)=>this.setState({currentPageActivas:index.activePage})}
                    defaultActivePage={1}
                    totalPages={pageNumbersActivas}
                    activePage={this.state.currentPageActivas}
                   />
               </div>
              </div>
              :<div></div>
            }

        </div>


    )
  }
}
class ItemModal extends Component{


  render(){
    console.log(this.props.fila);
    return(
      <Table.Row >
        <Table.Cell >{this.props.fila.Pais}</Table.Cell>
        <Table.Cell >{this.props.fila.Origen}</Table.Cell>
        <Table.Cell >{this.props.fila.Destino}</Table.Cell>
        <Table.Cell >{this.props.fila.Trafico}</Table.Cell>
        <Table.Cell >{this.props.fila.Servicio} </Table.Cell>
        <Table.Cell>{this.props.fila.ContainerType}</Table.Cell>
        <Table.Cell>{this.props.fila.ContainerSize}</Table.Cell>
        <Table.Cell>{this.props.fila.Tarifa}</Table.Cell>
        <Table.Cell>{this.props.fila.Insurance}</Table.Cell>
        <Table.Cell>{this.props.fila.RecargoIMO}</Table.Cell>
        <Table.Cell>{this.props.fila.PickUp}</Table.Cell>
        <Table.Cell>{this.props.fila.BlFee}</Table.Cell>
        <Table.Cell>{this.props.fila.Thc}</Table.Cell>
        <Table.Cell>{this.props.fila.Buc}</Table.Cell>
        <Table.Cell>{this.props.fila.Dre}</Table.Cell>
        <Table.Cell>{this.props.fila.Cnr}</Table.Cell>
        <Table.Cell>{this.props.fila.Isp}</Table.Cell>
        <Table.Cell>{this.props.fila.Ams}</Table.Cell>
        <Table.Cell>{this.props.fila.TerminalHandling}</Table.Cell>
        <Table.Cell>{this.props.fila.ContainerCleaning}</Table.Cell>
        <Table.Cell>{this.props.fila.Inland}</Table.Cell>
      </Table.Row>
    )
  }
}


class Item extends Component{

  constructor(){
    super()

    this.state={
      key:'',
      Expiracion:'',
      open:false,
      Modificar:''
    }

  }


UNSAFE_componentWillMount=()=>{

}


Modificar=(value,evento)=>{

console.log(value);
if (value!='Activar') {

  axios.post(Direccion+`/ActualizarRutas`,
    {Ruta:this.props.fila,idUser:firebaseAuth.currentUser.uid,type:'FCL',Modificar:value,NuevaFechaExpiracion:false})
    .then(res => {
      if (res.data === 'OK') {
        this.returnCallback();

      }
    })
}
else {
  console.log("voy de regreso");

this.props.handleOpenFecha(value,this.props.fila);

}
}





returnCallback=()=>{
  this.props.ActualizarCallback(false);

}

close = () => this.setState({ open: false })

  render(){
    const style = {
      borderRadius: '1em',
      opacity: 0.7,
      padding: '2em',
      marginLeft:'2%',
    }
    return(

      <Popup style={style} inverted trigger={  <Table.Row >
        <Table.Cell >{this.props.fila.Pais}</Table.Cell>
        <Table.Cell >{this.props.fila.Origen}</Table.Cell>
        <Table.Cell >{this.props.fila.Destino}</Table.Cell>
        <Table.Cell >{this.props.fila.Trafico}</Table.Cell>
        <Table.Cell >{this.props.fila.Servicio} </Table.Cell>
        <Table.Cell>{this.props.fila.ContainerType}</Table.Cell>
        <Table.Cell>{this.props.fila.ContainerSize}</Table.Cell>
        <Table.Cell>{this.props.fila.Tarifa}</Table.Cell>
        <Table.Cell>{this.props.fila.Insurance}</Table.Cell>
        <Table.Cell>{this.props.fila.RecargoIMO}</Table.Cell>
        <Table.Cell>{this.props.fila.PickUp}</Table.Cell>
        <Table.Cell>{this.props.fila.BlFee}</Table.Cell>
        <Table.Cell>{this.props.fila.Thc}</Table.Cell>
        <Table.Cell>{this.props.fila.Buc}</Table.Cell>
        <Table.Cell>{this.props.fila.Dre}</Table.Cell>
        <Table.Cell>{this.props.fila.Cnr}</Table.Cell>
        <Table.Cell>{this.props.fila.Isp}</Table.Cell>
        <Table.Cell>{this.props.fila.Ams}</Table.Cell>
        <Table.Cell>{this.props.fila.TerminalHandling}</Table.Cell>
        <Table.Cell>{this.props.fila.ContainerCleaning}</Table.Cell>
        <Table.Cell>{this.props.fila.Inland}</Table.Cell>

        </Table.Row>} flowing hoverable>
      <Grid  divided columns={2}>
      {this.props.value===0?

        <Grid.Column onClick={this.Modificar.bind(this,'Desactivar')} style={{height:'20%'}} textAlign='center'>
          <Header as='h4'>Desactivar</Header>
            <Icon  name='edit' />
        </Grid.Column>

        :
          <Grid.Column onClick={this.Modificar.bind(this,'Activar')} style={{height:'20%'}} textAlign='center'>
            <Header as='h4'>Activar</Header>
              <Icon  name='edit' />
          </Grid.Column>

      }


    <Grid.Column onClick={this.Modificar.bind(this,'Eliminar')}  style={{height:'20%'}} textAlign='center'>
      <Header as='h4'>Eliminar</Header>
      <Icon  name='delete' />

    </Grid.Column>
    </Grid>
  </Popup>






    )
  }
}

export default TypeMar;

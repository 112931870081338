import React, { Component } from 'react';
import Icon from "@material-ui/core/Icon";
import BarraLateral from './sideBarAdmin.js';
import TodasAnaliticas from '../scripts/analiticas-admin.js';
// core components
import GridItem from "../card/GridItem.jsx";
import GridContainer from "../card/GridContainer.jsx";
import {ref,firebaseAuth} from '../../const.js';
import {Direccion,DireccionFront} from '../../strings/peticiones.js';
import axios from 'axios';

import firebase from 'firebase'

import CardComponent from "../card.js"


class Analitics extends Component{
  constructor(){
    super()
    this.state={
      notificaciones:[],
      RutasActivas:0,
      RutasNoActivas:0,
      RutasListas:true,
      BookingsTerminados:0,
      BookingsProceso:0,
      BookingListos:false,
      Vendedores:0,
      Clientes:0,
      UsuariosListos:false,

    }
  }

  componentDidMount(){
    this.getUsuarios();
    // this.getRutas();
    this.getBooking();
  }
  getUsuarios=()=>{
    let self = this;
    axios.post(Direccion+`/tomarTotalUsuarios-analitics`,{idUser:firebase.auth().currentUser.uid})
    .then(res => {
      if (res.data.status === 'OK') {
        self.setState({
          Vendedores:res.data.Vendedores,
          Clientes:res.data.Clientes,
          UsuariosListos:true,
        })
      }else{
        console.log('error');
      }
    }).catch(err=>{
      console.log(err);
    })
  }
  getRutas=()=>{
    let self = this;
    axios.post(Direccion+`/tomarRutasAdmin-analitics`,{idUser:firebase.auth().currentUser.uid})
    .then(res => {
      if (res.data.status === 'OK') {
        self.setState({
          RutasActivas:res.data.RutasActivas,
          RutasNoActivas:res.data.RutasNoActivas,
          RutasListas:true,
        })
      }else{
        console.log('error');
      }
    }).catch(err=>{
      console.log(err);
    })
  }
  getBooking=()=>{
    let self = this;

    axios.post(Direccion+`/tomarBookingAdmin-analitics`,{idUser:firebase.auth().currentUser.uid})
    .then(res => {
      if (res.data.status === 'OK') {
        self.setState({
          BookingsTerminados:res.data.BookingsTerminados,
          BookingsProceso:res.data.BookingsProceso,
          BookingListos:true
        })
      }else{
        console.log('error');
      }
    }).catch(err=>{
      console.log(err);
    })
  }
   getNotificaciones=()=>{
     var notificaciones=JSON.parse(localStorage.getItem('notificaciones'));
     this.setState({
       notificaciones:notificaciones?notificaciones.length:0,
     })
  }
  render(){
    return(
      <div>
        <BarraLateral/>
        <div style={{padding:'5vh'}}>
            <GridContainer>
              <GridItem xs={120} sm={6} md={5}>
                <CardComponent icono='mail'
                colorheader="warning" coloricon="rose"
                titulo={this.state.nombre}
                dato='Hello'
                saludo={true}
                notificaciones={this.state.notificaciones}/>
              </GridItem>

              {this.state.RutasListas?
                <GridItem xs={12} sm={6} md={3}>
                  <CardComponent icono='swap_calls'
                  colorheader="warning" coloricon="success"
                  titulo='Active rates'
                  dato={this.state.RutasActivas}/>
                </GridItem>
                :
                <div className="spinner">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              }
              {this.state.RutasListas?
                <GridItem xs={12} sm={6} md={3}>
                  <CardComponent colorheader="warning" coloricon="danger"
                  icono='priority_high'
                  titulo='Expired rates'
                  dato={this.state.RutasNoActivas}/>
                </GridItem>
                :
                <div className="spinner">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              }
              {this.state.BookingListos?
                <GridItem xs={12} sm={6} md={3}>
                  <CardComponent colorheader="warning" coloricon="info"
                  icono='directions_boat'
                  titulo='Operations in transit'
                  dato={this.state.BookingsProceso}/>
                </GridItem>
                :
                <div className="spinner">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              }
              {this.state.BookingListos?
                <GridItem xs={12} sm={6} md={3}>
                  <CardComponent colorheader="warning" coloricon="primary"
                  icono='edit_attributes'
                  titulo='Operations completed'
                  dato={this.state.BookingsTerminados}/>
                </GridItem>
                :
                <div className="spinner">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              }

              {this.state.UsuariosListos?
                <GridItem xs={12} sm={6} md={3}>
                  <CardComponent colorheader="warning" coloricon="warning"
                  icono='nature_people'
                  titulo='registered forwarders'
                  dato={this.state.Vendedores}/>
                </GridItem>
                :
                <div className="spinner">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              }
              {this.state.UsuariosListos?
                <GridItem xs={12} sm={6} md={3}>
                  <CardComponent icono='accessibility'
                  colorheader="warning" coloricon="success"
                  titulo='registered cargo users'
                  dato={this.state.Clientes}/>
                </GridItem>
                :
                <div className="spinner">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              }


            </GridContainer>

        </div>
      </div>
    )
  }
}


export default Analitics;

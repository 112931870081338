import React, { Component, useState } from 'react';
import { Divider,Segment,Label,Popup,Grid, Header,Pagination, Icon,Button,Form,Dimmer, Loader, Dropdown} from 'semantic-ui-react';
import './styleSeller/rutasVendedor.css';
import {ref,firebaseAuth} from '../../const.js';
import firebase from 'firebase'
import axios from 'axios';
import {Idioma} from '../../strings/RutasSellerText.js';
import {Direccion} from '../../strings/peticiones.js';
import Script from 'react-load-script';
import AlertSnack from '../notificacionCard.js';
import FormularioWagon from './FormulariosRutas/FormularioWagon.js'
import FormularioFCLFerro from './FormulariosRutas/FormularioFCLFerro.js'
import FormularioFCLTruck from './FormulariosRutas/FormularioFCLTruck.js'
import FormularioLTLTruck from './FormulariosRutas/FormularioLTLTruck.js'
import FormularioFTLTruck from './FormulariosRutas/FormularioFTLTruck.js'
import FormularioFCL from './FormulariosRutas/FormularioFCL.js'
import FormularioLCL from './FormulariosRutas/FormularioLCL.js'
import FormularioAereo from './FormulariosRutas/FormularioAereo.js'
import FormularioBBulk from './FormulariosRutas/FormularioBBulk.js'

class TypeMar extends Component{
  constructor(props){
    super(props)
  }
  render(){
    return(

      <div>
      <RutasDetalle type={this.props.type} handleReturn={this.props.handleReturn} />
      </div>
    )
  }
}


class RutasDetalle extends Component{
  constructor(props){
    super(props);
    this.state={
      tipoRuta:0,
    }
  }

  componentDidMount(){
    this.setState({
      tipoRuta:this.props.type,
    })
  }

  regresarBack=()=>{
    this.props.handleReturn(this.state.tipoRuta);
  }
  handleScriptLoad() {
      // Declare Options For Autocomplete
        var options = {
          types: ['(cities)'],
          };

      // Initialize Google Autocomplete
      /*global google*/

        let Origen = new google.maps.places.Autocomplete(document.getElementById('autocompleteOrigen'),options );
        Origen.addListener('place_changed',this.handlePlaceSelect);

        let Destino = new google.maps.places.Autocomplete(document.getElementById('autocompleteDestino'),options );
        Destino.addListener('place_changed',this.handlePlaceSelect2);
        console.log(Origen);
        this.setState({
          Destino:Destino,
          Origen:Origen
        });

  }
  handlePlaceSelect() {
       if (this.state.Origen ) {
         let DireccionOrigen = this.state.Origen.getPlace();
         let DirEspecificaOrigen = DireccionOrigen.address_components;
         this.setState(
           {
             cityOrigen: DirEspecificaOrigen[0].long_name,
             queryOrigen: DireccionOrigen.formatted_address,
           }
         );
       }

   }


  handlePlaceSelect2() {
      // Extract City From Address Objec
      // Check if address is valid

      if (this.state.Destino) {

        let DireccionDestino = this.state.Destino.getPlace();
        let DirEspecificaDestino = DireccionDestino.address_components;

        this.setState(
          {
            cityDestino: DirEspecificaDestino[0].long_name,
            queryDestino: DireccionDestino.formatted_address,

          }
        );

      }
    }
  NuevaFecha=()=>{
        axios.post(Direccion+`/ActualizarRutas`,
          {Tipo:'Vendedor',Ruta:this.state.RutaActualizar,idUser:firebaseAuth.currentUser.uid,type:'FCL',Modificar:this.state.Modificar,NuevaFechaExpiracion:this.state.NuevaExpiracion})
          .then(res => {
            if (res.data === 'OK') {
              this.setState({
                NuevaExpiracion:'',
                openFecha:'',
              })
              this.GetRutas();

            }else if(res.data === '340'){
              window.location.reload();
            }
          })
      }
  render(){
    let text=Idioma('ES');

    return(
      <div className='headerContent'>

        <Dropdown
            placeholder='Select rate type'
            fluid
            selection
            style={{width:'50%',margin:'5px auto'}}
            options={[
              {key: 0, value:0 ,icon:<i style={{marginRight: '10px'}} class="material-icons">directions_boat</i>,content:'Ocean (LCL)',text:'Ocean (LCL)'},
              {key: 1, value:1 ,icon:<i style={{marginRight: '10px'}} class="material-icons">directions_boat</i>,content:'Ocean (FCL)',text:'Ocean (FCL)'},
              {key: 2, value:2 ,icon:<i style={{marginRight: '10px'}} class="material-icons">directions_boat</i>,content:'Ocean (BREAK BULK)',text:'Ocean (BREAK BULK)'},
              {key: 3, value:3 ,icon:<i style={{marginRight: '10px'}} class="material-icons">local_shipping</i>,content:'Truck (FCL)',text:'Truck (FCL)'},
              {key: 4, value:4 ,icon:<i style={{marginRight: '10px'}} class="material-icons">local_shipping</i>,content:'Truck (LTL)',text:'Truck (LTL)'},
              {key: 5, value:5 ,icon:<i style={{marginRight: '10px'}} class="material-icons">local_shipping</i>,content:'Truck (FTL)',text:'Truck (FTL)'},
              {key: 6, value:6 ,icon:<i style={{marginRight: '10px'}} class="material-icons">train</i>,content:'F.F.C.C (FCL)',text:'F.F.C.C (FCL)'},
              {key: 7, value:7 ,icon:<i style={{marginRight: '10px'}} class="material-icons">train</i>,content:'F.F.C.C (WAGONS)',text:'F.F.C.C (WAGONS)'},
              {key: 8, value:8 ,icon:<i style={{marginRight: '10px'}} class="material-icons">airplanemode_active</i>,content:'AIR',text:'AIR'},
            ]}
            onChange={(e, {value})=>this.setState({tipoRuta:value})}
            value={this.state.tipoRuta}
        />
        <div className='bttnAgregar pending' onClick={()=>this.props.handleReturn(this.state.tipoRuta)}>
          <Icon name='arrow left'/>
        </div>

        {this.state.tipoRuta === 0?
          <FormularioLCL regresarBack={this.regresarBack}  text={text}/>
        :this.state.tipoRuta === 1?
          <FormularioFCL regresarBack={this.regresarBack}  text={text}/>
        :this.state.tipoRuta === 2?
          <FormularioBBulk regresarBack={this.regresarBack}  text={text}/>
          :this.state.tipoRuta === 3?
          <FormularioFCLTruck regresarBack={this.regresarBack}  text={text}/>
          :this.state.tipoRuta === 4?
          <FormularioLTLTruck regresarBack={this.regresarBack}  text={text}/>
          :this.state.tipoRuta === 5?
          <FormularioFTLTruck regresarBack={this.regresarBack}  text={text}/>
          :this.state.tipoRuta === 6?
          <FormularioFCLFerro regresarBack={this.regresarBack}  text={text}/>
          :this.state.tipoRuta === 7?
          <FormularioWagon regresarBack={this.regresarBack}  text={text}/>
          :this.state.tipoRuta === 8?
          <FormularioAereo regresarBack={this.regresarBack}  text={text}/>
          :
          <div/>
        }

      </div>
    )
  }
}




export default TypeMar;

import React, { Component } from 'react';
import BarraLateral from './sideBar.js';
import { Divider,Dropdown,Segment,Label, Header,Image, Icon,Table,Modal,Button,Form,Dimmer, Loader} from 'semantic-ui-react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import './styleSeller/rutasVendedor.css';
import {ref,firebaseAuth} from '../../const.js';
import firebase from 'firebase'
import axios from 'axios';
import {Idioma} from '../../strings/RutasSellerText.js';
import AddIcon from '@material-ui/icons/Add';
import {Direccion} from '../../strings/peticiones.js';
import organizacionArray from '../OrganizarRutas.js';
import Fab from '@material-ui/core/Fab';
import Script from 'react-load-script';
import SearchBar from 'material-ui-search-bar'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import XLSX from 'xlsx';
import { DatePicker } from 'material-ui-pickers';
import ShowRutaLCL from './ShowRutas/showRutaLCL.js';
import ShowRutaFCL from './ShowRutas/showRutaFCL.js';
import ShowRutaBBulk from './ShowRutas/showRutaBBulk.js';
import ShowRutaTruckFCL  from './ShowRutas/showRutaFCLTruck.js';
import ShowRutaTruckLTL  from './ShowRutas/showRutaLTLTruck.js';
import ShowRutaTruckFTL  from './ShowRutas/showRutaFTLTruck.js';
import ShowRutaFerroFCL  from './ShowRutas/showRutaFCLFerro.js';
import ShowRutaWagon  from './ShowRutas/showRutaWagon.js';
import ShowRutaAereo  from './ShowRutas/showRutaAereo.js';

import NuevaRuta from './NuevaRuta.js'
import Particles from 'react-particles-js';
import DeterminarImagen from '../scripts/imagenes.js';
import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);



class RutasVendedor extends Component{
  constructor(){
    super()
  }
  render(){
    return(

      <div>
      <BarraLateral/>
      <RutasDetalle/>
      </div>
    )
  }
}

class RutasDetalle extends Component{
  constructor(){
    super();
    var date = new Date();

    this.state={
      valueTabs:0,
      rutas:[],
      RutasActivas:[],
      RutasNoActivas:[],
      type:0,
      user:firebaseAuth.currentUser.uid,
      Status:false,
      Loader:true,
      perfil:{},
      rutaSelect:{},
      isRutaSelect:false,
      imgRuta:'',
    }
  }

  setRutaSelect=(e)=>{
    let imagen=DeterminarImagen.DeterminarImagenSimple(e.tipoRuta);
    this.setState({rutaSelect:e,imgRuta:imagen,isRutaSelect:true})
  }
  regresarBack=()=>{
    this.setState({rutaSelect:{},imgRuta:'',isRutaSelect:false})

  }

  UNSAFE_componentWillMount(){
    let perfil={};
    let self=this;
    axios.post(Direccion+`/tomarSuscripcion`,{idUser:firebase.auth().currentUser.uid,})
      .then(res => {
        if (res.data != '404') {
          if (res.data.status === 'activo') {
            self.setState({
              Status:true,
              Loader:false,
            });
          }
          else{
            self.setState({
              Loader:false,
              Status:false,
            });
          }
        }
        else {
          self.setState({
            Loader:false,
            Status:false,
          });
        }
      })

    }

    handleChangeTabtype=(e, {value})=>{
      this.setState({ type: value});
    };

  render(){
    let text=Idioma('ES');
    let type = this.state.type;

    return(

      <div className='header-rutas'>
        <div style={{position:'relative',height:'200px'}}>
          <Particles style={{backgroundImage:'url(../imagenes/buscar-rutas.jpg)', backgroundSize:'cover',backgroundPosition:'center', position:'absolute',height:'200px'}}
               params={{
                 "particles": {
                     "number": {
                         "value": 40
                     },
                     "size": {
                         "value": 2
                     }
                 },
                 "interactivity": {
                     "events": {
                         "onhover": {
                             "enable": true,
                             "mode": "repulse"
                         }
                     }
                 }
             }} />
          <div className='tituloSeccion'>
            <i class="material-icons">language </i>
            <p>{text.titulo}</p>
          </div>
        </div>

        {this.state.Loader?
          <div>
            <div className="spinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          </div>:
          <div>
          {!this.state.Status?
            <div style={{marginLeft:'2%'}}>
              <Image style={{width:'50%',marginLeft:'26%'}}  src='../imagenes/notCross.png' />
              <div style={{textAlign:'center'}}>
                <Header>We are sorry, you can't access to this module for the following reasons</Header>
                <p>You have not veryfied your account</p>
                <p>Your plan doesn't include this module</p>
              </div>
            </div>:
            <div>
              {this.state.nuevaRuta?
                <NuevaRuta type={this.state.type} handleReturn={(e)=>this.setState({nuevaRuta:!this.state.nuevaRuta,type:e?e:0})}/>
                :
                <div className='headerContent'>
                  {this.state.isRutaSelect?
                    <div>
                      <div className='CardItem'>
                        <div className='imgCardItem'><img src={'../imagenes/'+this.state.imgRuta}/></div>
                        <div className='infoCardItem left'>
                          <h3>Name</h3>
                          <p>{this.state.rutaSelect.NameSlot}</p>
                        </div>
                        <div className='infoCardItem left'>
                          <h3>ID packages</h3>
                          <p>{this.state.rutaSelect.IdSlot}</p>
                        </div>
                        <div className='infoCardItem center'>
                          <h3>Valid From</h3>
                          <p>{this.state.rutaSelect.FechaIniciacion}</p>
                        </div>
                        <div className='infoCardItem center'>
                          <h3>Expirate date</h3>
                          <p>{this.state.rutaSelect.Expiracion}</p>
                        </div>
                        <div className='infoCardItem center'>
                          <h3>Total rates</h3>
                          <p>{this.state.rutaSelect.CantidadRutas}</p>
                        </div>
                      </div>
                    </div>
                    :
                    <div>

                      <Dropdown
                          placeholder='Select rate type'
                          fluid
                          selection
                          style={{width:'50%',margin:'5px auto'}}
                          options={[
                            {key: 0, value:0 ,icon:<i class="material-icons">directions_boat</i>,content:'Ocean (LCL)',text:'Ocean (LCL)'},
                            {key: 1, value:1 ,icon:<i class="material-icons">directions_boat</i>,content:'Ocean (FCL)',text:'Ocean (FCL)'},
                            {key: 2, value:2 ,icon:<i class="material-icons">directions_boat</i>,content:'Ocean (BREAK BULK)',text:'Ocean (BREAK BULK)'},
                            {key: 3, value:3 ,icon:<i class="material-icons">local_shipping</i>,content:'Truck (FCL)',text:'Truck (FCL)'},
                            {key: 4, value:4 ,icon:<i class="material-icons">local_shipping</i>,content:'Truck (LTL)',text:'Truck (LTL)'},
                            {key: 5, value:5 ,icon:<i class="material-icons">local_shipping</i>,content:'Truck (FTL)',text:'Truck (FTL)'},
                            {key: 6, value:6 ,icon:<i class="material-icons">train</i>,content:'F.F.C.C (FCL)',text:'F.F.C.C (FCL)'},
                            {key: 7, value:7 ,icon:<i class="material-icons">train</i>,content:'F.F.C.C (WAGONS)',text:'F.F.C.C (WAGONS)'},
                            {key: 8, value:8 ,icon:<i class="material-icons">airplanemode_active</i>,content:'AIR',text:'AIR'},
                          ]}
                          onChange={this.handleChangeTabtype}
                          value={this.state.type}
                      />
                      <div className='bttnAgregar positive' onClick={()=>this.setState({nuevaRuta:!this.state.nuevaRuta})}>
                        Add rates
                      </div>
                    </div>
                  }
                  { this.state.type===0?
                    <ShowRutaLCL regresarBack={this.regresarBack} setRutaSelect={(e)=>this.setRutaSelect(e)}/>
                  :this.state.type===1?
                    <ShowRutaFCL regresarBack={this.regresarBack} setRutaSelect={(e)=>this.setRutaSelect(e)}/>
                  :this.state.type===2?
                    <ShowRutaBBulk regresarBack={this.regresarBack} setRutaSelect={(e)=>this.setRutaSelect(e)}/>
                  :this.state.type===3?
                    <ShowRutaTruckFCL regresarBack={this.regresarBack} setRutaSelect={(e)=>this.setRutaSelect(e)}/>
                  :this.state.type===4?
                    <ShowRutaTruckLTL regresarBack={this.regresarBack} setRutaSelect={(e)=>this.setRutaSelect(e)}/>
                  :this.state.type===5?
                    <ShowRutaTruckFTL regresarBack={this.regresarBack} setRutaSelect={(e)=>this.setRutaSelect(e)}/>
                  :this.state.type===6?
                    <ShowRutaFerroFCL regresarBack={this.regresarBack} setRutaSelect={(e)=>this.setRutaSelect(e)}/>
                  :this.state.type===7?
                    <ShowRutaWagon regresarBack={this.regresarBack} setRutaSelect={(e)=>this.setRutaSelect(e)}/>
                  :this.state.type===8?
                    <ShowRutaAereo regresarBack={this.regresarBack} setRutaSelect={(e)=>this.setRutaSelect(e)}/>

                  :
                  <div></div>
                  }
                </div>
              }
            </div>
          }
          </div>
        }
      </div>
    )
  }
}








export default RutasVendedor;

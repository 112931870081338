
function cantidadDiferencia(datos){
  var terminados=[];
  var proceso=[];
  var cancelados=[];
  datos.forEach((it)=>{
    let PagoCeif = it.Status==='Finalizado' && !it.pagado;
    if(it.Status==='Finalizado' && it.pagado){
        terminados.push(it);
    }
    else if(it.Status==='Peticion'|| it.Status==='Proceso' || it.Status==='Aceptado'|| it.Status==='Pagado' || PagoCeif ){
      proceso.push(it);
    }
    else if(it.Status==='Cancelado'){
      cancelados.push(it);
    }

  });

  return {terminados:terminados,proceso:proceso,cancelados:cancelados};
}


function cantidadDiferenciaPagos(datos){
  var terminados=[];
  var proceso=[];
  datos.forEach((it)=>{
    if(it.Status==='Finalizado'){
        terminados.push(it);
    }
    else if(it.Status==='Recibido' ){
      proceso.push(it);
    }
  });

  return {terminados:terminados,proceso:proceso};
}



function PagosAdmin(datos){
  console.log(datos);
   var terminados=[];
  var proceso=[];
  datos.forEach((it)=>{
    if(it.Status==='Finalizado'){
        terminados.push(it);
    }
    else if(it.Status==='Recibido' && it.PagoOrigen === 3){
      proceso.push(it);
    }
    else if(it.Status==='Recibido' && it.RateEnvio){
      proceso.push(it);
    }
  });
  return {terminados:terminados,proceso:proceso};
}

function Cuentas(proceso,pagados,mes,año){
  if(mes<10){
    mes='0'+mes;
  }
  var total=0;
  var totalProceso=0;
  var valores=[];
  if(pagados!==undefined){
    pagados.forEach((it)=>{
      if(it.dateTime.substring(0,7)=== año+'-'+mes){
        valores.push(it.Amount);
        if (it.PagoType === 'retiro') {
          total=total-parseInt(it.Amount);
        }
        else if(it.PagoType === 'abono'){
          total=total+parseInt(it.Amount);
        }
        else{
          total=total;
        }

      }
    })
  }
  else{
    total=0;
  }
  if(proceso!==undefined){
    proceso.forEach((it)=>{
      if(it.dateTime.substring(0,7)===año+'-'+mes){
        totalProceso=totalProceso+parseInt(it.Amount);
      }
    })
  }
  else{
    totalProceso=0;
  }
  return {totalProceso:totalProceso,totalPagados:total,valores:valores};
}

exports.Cuentas=Cuentas;
exports.PagosAdmin=PagosAdmin;
exports.cantidadDiferencia=cantidadDiferencia;
exports.cantidadDiferenciaPagos=cantidadDiferenciaPagos;

import React, { Component } from 'react';
import { Dropdown,Divider,Header, Icon, Table, Card,Radio, Image,Button,Modal, Form,Segment,Menu,Checkbox , Input, Label} from 'semantic-ui-react';
import {Idioma} from '../../../../strings/BuscarRutaText.js';
import Switch from '@material-ui/core/Switch';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CalculadorCBM from '../../../scripts/CalculadorCBM.js';

//css
import '../../../ComponentsUser/styleUser/ventanaTruck.css';



const medidas =[
  {key:'cm',text:'cm',value:'cm'}
];
const medidasT =[
  {key:'mfc',text:'mfc',value:'mfc'},
  {key:'cft',text:'cft',value:'cft'}
];


const pesos =[
  {key:'kg',text:'kg',value:'kg'}
]

const valoresCbm=[
  {key:'CBM',text:'CBM',value:'CBM'}
]

class TipoTerrestre extends Component{
  constructor(props){
    super(props)
    this.state={
      open:this.props.open,
      activeItem:'FCL',
      tipoContainer:'',
      TipoContenido:'',
      Payment:'prepaid',
      listo:false,
      incoterm:'',
      seguro:false,
      openSeguro:false,
      montoSeguro:'',
      container:'20fcl.png',
      container2:'53-ftl.png',
      opcionFTL:'Single',
      typeTruckSelect:false,
      ContainerSize:"",
      ServicioPO:false,
      ServicioCO:false,
      ServicioCD:false,
      ServicioPD:false,
      IMOCargo:false,

      ArrayUnidades:[],
      AccordionSeccion1:true,
      AccordionSeccion2:false,
      AccordionSeccion2:false,
      UnidadesTotal:0,
      PesoTotal:0.00,
      CBMTotal:0.00,
    }
  }

  callbackItem=(value)=>{this.setState({TipoContenido:value})}

  RegresarObjeto=()=>{

    var objPeticion = {}
    let objServicios = {PO:this.state.ServicioPO,CO:this.state.ServicioCO,CD:this.state.ServicioCD,PD:this.state.ServicioPD,}

    if (this.state.TipoContenido) {
      switch (this.state.activeItem) {
        case "FCL":
            switch (this.state.tipoContainer) {
              case "20":
                objPeticion={ objServicios:objServicios,IMOCargo:this.state.IMOCargo,Seguro:this.state.seguro,payment:this.state.Payment,montoSeguro:this.state.montoSeguro,contenido:this.state.TipoContenido, Contenedor:"FCL-Truck",Medidas:"20",ContainerType:'ST',Incoterm:this.state.incoterm}
                this.setState({
                  listo:true
                })
                  this.props.callBackVentana(objPeticion);
                break;
              case "40":
              objPeticion={objServicios:objServicios,IMOCargo:this.state.IMOCargo,Seguro:this.state.seguro,payment:this.state.Payment,montoSeguro:this.state.montoSeguro,contenido:this.state.TipoContenido, Contenedor:"FCL-Truck",ContainerType:'ST',Medidas:"40",Incoterm:this.state.incoterm}
              this.setState({
                listo:true
              })
                this.props.callBackVentana(objPeticion);
                break;
              case "40hq":
              objPeticion={objServicios:objServicios,IMOCargo:this.state.IMOCargo,Seguro:this.state.seguro,payment:this.state.Payment,montoSeguro:this.state.montoSeguro,contenido:this.state.TipoContenido, Contenedor:"FCL-Truck",Medidas:"40",ContainerType:'HQ',Incoterm:this.state.incoterm}
              this.setState({
                listo:true
              })
                this.props.callBackVentana(objPeticion);
                break;

                case "20Ref":
                objPeticion={objServicios:objServicios,IMOCargo:this.state.IMOCargo,Seguro:this.state.seguro,payment:this.state.Payment,montoSeguro:this.state.montoSeguro,contenido:this.state.TipoContenido, Contenedor:"FCL-Truck",Medidas:"20",ContainerType:'REF',Incoterm:this.state.incoterm}
                this.setState({
                  listo:true
                })
                  this.props.callBackVentana(objPeticion);
                  break;

                  case "40Ref":
                  objPeticion={objServicios:objServicios,IMOCargo:this.state.IMOCargo,Seguro:this.state.seguro,payment:this.state.Payment,montoSeguro:this.state.montoSeguro,contenido:this.state.TipoContenido, Contenedor:"FCL-Truck",Medidas:"40",ContainerType:'REF',Incoterm:this.state.incoterm}
                  this.setState({
                    listo:true
                  })
                    this.props.callBackVentana(objPeticion);
                    break;
            }

          break;
        case "LTL":
        if (this.state.ArrayUnidades.length>0) {
            let respuestaCalculador = CalculadorCBM.CalcularCBMTotal(this.state.ArrayUnidades);
            if (respuestaCalculador.status === 'OK' && respuestaCalculador.CBMTotal>0 && respuestaCalculador.PesoTotal>0) {
              let nuevoArrayITems = CalculadorCBM.CalcularCBMArray(this.state.ArrayUnidades);
              if (nuevoArrayITems.status === 'OK') {
                objPeticion={IMOCargo:this.state.IMOCargo,Seguro:this.state.seguro,payment:this.state.Payment,montoSeguro:this.state.montoSeguro,contenido:this.state.TipoContenido,Contenedor:"LTL-Truck",CBM:respuestaCalculador.CBMTotal,ArrayUnidades:nuevoArrayITems.ArrayCBM,Peso:respuestaCalculador.PesoTotal,objServicios:objServicios}
                this.setState({listo:true})
                this.props.callBackVentana(objPeticion);
              }
            }else{
              alert("Add at least item with valid measurements and weight");
            }
          }
          else {
            alert("Please add at least one item");
          }
          break;
        case "FTL":
        if (this.state.ContainerSize && this.state.tipoContainerFtl) {
          objPeticion={ContainerFull:this.state.opcionFTL,objServicios:objServicios,IMOCargo:this.state.IMOCargo,Seguro:this.state.seguro,payment:this.state.Payment,montoSeguro:this.state.montoSeguro,contenido:this.state.TipoContenido, Contenedor:"FTL-Truck",Medidas:this.state.ContainerSize,ContainerType:this.state.tipoContainerFtl,Incoterm:this.state.incoterm,opcionFTL:this.state.opcionFTL}
          this.setState({
            listo:true
          })
            this.props.callBackVentana(objPeticion);
        }
        else {
          alert("Please select container size and container type");
        }
          break;
        default:

    }
    }
    else {
      alert("Please select cargo category");
    }
  }

  handleItemClick = (e, name ) =>{ this.setState({ activeItem: name })}

  tipoContenedor=(e,tipo)=>{this.setState({container:e,tipoContainer:tipo})}

  tipoContenedor2=(e,tipo)=>{
    this.setState({
      container2:e,
      tipoContainerFtl:tipo,
      typeTruckSelect:true,
    })
  }
  TruckSize=(tipo)=>{this.setState({ContainerSize:tipo})}

  close = () => {
    this.setState({
      open:false
    })
      this.props.callBackVentana(false);
 }



  handleChangeOpcionFTL= () => this.setState({ opcionFTL: !this.state.opcionFTL })

  handleChangeSeguro = name => event => {
   if(this.state.seguro){
     this.setState({seguro:false,openSeguro:true});
   }
   else{
   this.setState({seguro:true,openSeguro:true});
    }
  }

  handleCloseSeguro=()=>{this.setState({openSeguro:false})}

  handleChangePayment=(e,index)=>{this.setState({Payment: e})}
  CargoServicios=(Cargo,e)=>{
    if (Cargo === 'PO') {
      this.setState({ServicioPO:!this.state.ServicioPO})
    }else if (Cargo === 'CO') {
      this.setState({ServicioCO:!this.state.ServicioCO})
    }else if (Cargo === 'CD') {
      this.setState({ServicioCD:!this.state.ServicioCD})
    }else if (Cargo === 'PD') {
      this.setState({ServicioPD:!this.state.ServicioPD})
    }
  }
  onChangeMedida=(posicion,valor,index)=>{
    let ArrayUnidadesAux = this.state.ArrayUnidades;
    ArrayUnidadesAux[index][posicion] = valor;
    let respuestaCalculador = CalculadorCBM.CalcularCBMTotal(ArrayUnidadesAux);
    this.setState({ArrayUnidades:ArrayUnidadesAux,UnidadesTotal:respuestaCalculador.UnidadesTotal,PesoTotal:respuestaCalculador.PesoTotal,CBMTotal:respuestaCalculador.CBMTotal?respuestaCalculador.CBMTotal:''})
  }
  EliminarItem=(index)=>{
    let ArrayUnidadesAux = this.state.ArrayUnidades;
    ArrayUnidadesAux.splice(index, 1);
    let respuestaCalculador = CalculadorCBM.CalcularCBMTotal(ArrayUnidadesAux);
    this.setState({ArrayUnidades:ArrayUnidadesAux,UnidadesTotal:respuestaCalculador.UnidadesTotal,PesoTotal:respuestaCalculador.PesoTotal,CBMTotal:respuestaCalculador.CBMTotal?respuestaCalculador.CBMTotal:''})
  }
  addItemVacio=()=>{
    let ArrayUnidadesAux = this.state.ArrayUnidades;
    ArrayUnidadesAux.push({Unidades:1,Lenght:'',Width:'',Height:'',Weight:''})
    this.setState({ArrayUnidades:ArrayUnidadesAux})
  }

  handleMontoSeguro = (e, { value }) => this.setState({ montoSeguro: value })
  handleBorrarSeguro=()=>{this.setState({montoSeguro:'',openSeguro:false})}
  render(){
    const { activeItem } = this.state;
    const { checkBox } = this.state
    let text=Idioma('ES').textos;
    let lista=Idioma('ES').lista;
    let {container}= this.state;
    let {container2}= this.state;

    return(

      <Modal id='ModalTerrestre' open={this.props.open} onClose={this.close}>
        <div className='ContenidoPadre'>
          <div  className='AccordionItem'>
            <div onClick={()=>this.setState({AccordionSeccion1:!this.state.AccordionSeccion1})} className='AccordionHeader'>
                <div className='tituloModal'>Cargo categories and measurement</div>
                <Icon className='AccordionIcon' name='dropdown' />
              </div>
              <div className={this.state.AccordionSeccion1?'divider':'divider collapse'}/>

              {this.state.AccordionSeccion1?
                <section className='seccion1'>
                  <div className='dropdownVentana'>
                    <Dropdown value={this.state.TipoContenido}
                      onChange={(e,{ name, value })=>this.setState({TipoContenido:value,errorCargo:false})}
                      placeholder='Cargo categories'
                      clearable
                      selection
                      error={!this.state.TipoContenido && this.state.errorCargo}
                      style={!this.state.errorCargo?{border:'1px solid #ff9347'}:{textAlign:'left'}}
                      fluid
                      className='icon'
                      options={lista}
                    />
                  </div>

                  <div  className='MenuVentana'>
                      <div className={activeItem === 'FCL'?'itemMenu Fcl Activo':'itemMenu Fcl'} onClick={e => this.setState({activeItem: "FCL"})} >
                        <h4>Containers (FCL)</h4>
                      </div>
                      <div className={activeItem === 'LTL'?'itemMenu Lcl Activo':'itemMenu Lcl'} onClick={e => this.setState({activeItem: "LTL"})} >
                        <h4>Boxes (LTL)</h4>
                      </div>
                      <div className={activeItem === 'FTL'?'itemMenu Bbulk Activo':'itemMenu Bbulk'} onClick={e => this.setState({activeItem: "FTL"})} >
                        <h4>Trucks (FTL)</h4>
                      </div>
                  </div>
                  <div className='contenido-menu'>
                    {activeItem=== 'FCL'?
                      <div className='uiForm'>
                        <img className='imgContenido Contenedor' src={'../imagenes/camiones/'+container} />
                        <div className='botonesFcl'>
                          <div className={this.state.tipoContainer === '20'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ container:'20fcl.png',tipoContainer:'20'})}>{'20´ST'}</div>
                          <div className={this.state.tipoContainer === '40'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ container:'40fcl.png',tipoContainer:'40'})}>{'40´ST'}</div>
                          <div className={this.state.tipoContainer === '40hq'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ container:'40-hq-fcl.png',tipoContainer:'40hq'})}>{'40´HQ'}</div>
                          <div className={this.state.tipoContainer === '20Ref'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ container:'20fcl-ref.png',tipoContainer:'20Ref'})}>{'20´REF'}</div>
                          <div className={this.state.tipoContainer === '40Ref'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ container:'40fcl-ref.png',tipoContainer:'40Ref'})}>{'40´REF'}</div>
                        </div>
                      </div>
                    : activeItem==='LTL'?
                      <div className='uiForm'>
                        <img className='imgContenidoPallet' src={'../imagenes/pallet.png'}/>
                        <div className='FormContent'>
                          <div onClick={this.addItemVacio} className='btnAddItem'>
                            <Icon  name='plus' />
                            <p style={{color:'#fff'}}>Add items</p>
                          </div>

                          {
                            this.state.ArrayUnidades.map((it,index)=>{
                              return(<ItemBoxMedidas EliminarItem={this.EliminarItem} it={it} index={index} onChangeMedida={this.onChangeMedida}/>)
                            })
                          }
                          <div className='divider'/>
                          <div className='formTotal'>
                            <p><span>Total units : </span>{this.state.UnidadesTotal} </p>
                            <p><span>Total CBMs : </span>{this.state.CBMTotal} </p>
                            <p><span>Total Weight : </span>{this.state.PesoTotal} </p>
                          </div>
                        </div>
                      </div>
                     :
                     <div className='uiForm'>
                       <img className='imgContenido Contenedor' src={'../imagenes/camiones/'+container2} />
                       {!this.state.typeTruckSelect?
                         <div className='botonesFcl'>
                           <div className={this.state.tipoContainerFtl === 'DRY'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ container2:'53-ftl.png',tipoContainerFtl:'DRY',typeTruckSelect:true})}>DRY</div>
                           <div className={this.state.tipoContainerFtl === 'PLAT'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ container2:'53-ftl-plat.png',tipoContainerFtl:'PLAT',typeTruckSelect:true})}>PLAT</div>
                           <div className={this.state.tipoContainerFtl === 'REF'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ container2:'53-ftl-ref.png',tipoContainerFtl:'REF',typeTruckSelect:true})}>REF</div>
                         </div>
                         :
                         <div className='botonesFcl'>
                           <div  className="return left float" onClick={()=>this.setState({typeTruckSelect:false})}>
                             <Icon disabled name='angle left' />
                           </div>
                           <div className='verticalBtnsContent'>
                             <div className={this.state.ContainerSize === '1Ton'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ ContainerSize:'1Ton'})}>1 ton</div>
                             <div className={this.state.ContainerSize === '3Ton'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ ContainerSize:'3Ton'})}>3 tons</div>
                             <div className={this.state.ContainerSize === '3.5Ton'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ ContainerSize:'3.5Ton'})}>3.5 tons</div>

                           </div>
                           <div className='verticalBtnsContent'>
                             <div className={this.state.ContainerSize === 'Th'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ ContainerSize:'Th'})}>Thorton</div>
                             <div className={this.state.ContainerSize === '48'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ ContainerSize:'48'})}>48 in</div>
                             <div className={this.state.ContainerSize === '53'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ ContainerSize:'53'})}>53 in</div>

                           </div>

                         </div>
                       }

                     </div>
                    }
                  </div>
                </section>
                :<div></div>
              }

          </div>
          <div  className='AccordionItem'>
              <div onClick={()=>this.setState({AccordionSeccion3:!this.state.AccordionSeccion3})} className='AccordionHeader'>
                  <div className='tituloModal'>Shipment</div>
                  <Icon className='AccordionIcon' name='dropdown' />
                </div>
                <div className={this.state.AccordionSeccion3?'divider':'divider collapse'}/>
                {this.state.AccordionSeccion3?
                  <section className='seccion3'>

                    <div className='containerContenido'>

                      <div className='CargoShipping'>
                        <div onClick={this.CargoServicios.bind(this,"PO")} className={this.state.ServicioPO?'CardCargo active':'CardCargo'}>
                          <Icon  name='shipping' />
                          <Card.Content>
                            Local PickUp
                          </Card.Content>
                        </div>
                        <div onClick={this.CargoServicios.bind(this,"CO")}  className={this.state.ServicioCO?'CardCargo active':'CardCargo'}>
                          <Icon  name='shipping' />
                          <Card.Content>
                            Origin Charges
                          </Card.Content>
                        </div>
                        <div  className='CardCargo active'>
                          <Icon  name='shipping' />
                          <Card.Content>
                            Ocean Freight
                          </Card.Content>
                        </div>
                        <div onClick={this.CargoServicios.bind(this,"CD")}  className={this.state.ServicioCD?'CardCargo active':'CardCargo'}>
                          <Icon  name='shipping' />
                          <Card.Content>
                            Destination Charges
                          </Card.Content>
                        </div>
                        <div onClick={this.CargoServicios.bind(this,"PD")}  className={this.state.ServicioPD?'CardCargo active':'CardCargo'}>
                          <Icon  name='shipping' />
                          <Card.Content>
                            Local Delivery
                          </Card.Content>
                        </div>
                      </div>
                      <div className="statusContainer">
                          <span className='routeShipping'>
                          <span className='routeIcon'>
                            <Icon  name='shipping' className={this.state.ServicioPO?'icono active':'icono'}  />
                          </span>
                          <span className={this.state.ServicioPO?'line active':'line'}>
                            <Icon id={this.state.ServicioPO?'dot-left-active':'dot-left'} name='dot circle outline' />
                            <Icon id={this.state.ServicioCO?'dot-right-active-blue':'dot-right'} name='dot circle outline' />
                          </span>
                          </span>
                          <span className='routeShipping'>
                            <span className='routeIcon'>
                            <Icon  name='ship' className='icono activeBlue'  />
                            </span>
                            <span className='line activeBlue'></span>
                          </span>
                          <span className='routeShipping'>
                            <span className='routeIcon'>
                            <Icon  name='shipping' className={this.state.ServicioPD?'icono active':'icono'}  />
                            </span>
                            <span className={this.state.ServicioPD?'line active':'line'}>
                             <Icon id={this.state.ServicioCD?'dot-left-active-blue':'dot-left'} name='dot circle outline' />
                             <Icon id={this.state.ServicioPD?'dot-right-active':'dot-right'} name='dot circle outline' />
                           </span>
                          </span>
                      </div>
                      <div className='CargoInsurace'>
                        <Button.Group  >
                          <Button onClick={this.handleChangePayment.bind(this,'prepaid')} positive={this.state.Payment==='prepaid'? true: false} style={{width:'40%'}}>prepaid</Button>
                          <Button.Or />
                          <Button onClick={this.handleChangePayment.bind(this,'collect')} positive={this.state.Payment==='collect'? true: false}  style={{width:'40%'}}>collect</Button>
                        </Button.Group>
                          <FormControlLabel
                            id='SwitchInsurace'
                            control={<Switch checked={this.state.seguro}
                            onChange={this.handleChangeSeguro('checked')}
                            value="checked"/>}
                            label={text.ventanaCarga.seguro}
                            labelPlacement="bottom"
                         />
                         <FormControlLabel
                           id='SwitchImo'
                           control={<Switch checked={this.state.IMOCargo}
                           onChange={()=>this.setState({IMOCargo:!this.state.IMOCargo})}
                           value="checked"/>}
                           label={"Cargo IMO"}
                           labelPlacement="bottom"
                        />
                      </div>
                    </div>
                  </section>
                  :<div></div>
                }
            </div>

        </div>

        <Modal.Actions>

        <Button
          negative
          style={{width:'35%'}}
          icon='cancel'
          labelPosition='left'
          content={text.botones.cancelar}
          onClick={this.close}
        />

          <Button
            positive
            style={{width:'35%'}}
            icon='checkmark'
            labelPosition='right'
            content={text.botones.aceptar}
            onClick={this.RegresarObjeto}
          />
        </Modal.Actions>
        <Dialog
          open={this.state.openSeguro}
          onClose={this.handleCloseSeguro}
          aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">{text.ventanaCarga.colocaMonto}</DialogTitle>
              <DialogContent>
              <Form>
              <Form.Input onChange={this.handleMontoSeguro} value={this.state.montoSeguro} type='number' placeholder='Monto' width={10}  />
              </Form>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleBorrarSeguro} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleCloseSeguro} color="primary" autoFocus>
                  Accept
                </Button>
              </DialogActions>
        </Dialog>
      </Modal>
    )
  }
}


class ItemBoxMedidas extends Component{
  constructor(props){
    super(props)
    this.state={
      container:"",
      TipoContenido:"",
      active:false,
      Unidades:1,
      Lenght:'',
      Width:'',
      Height:'',
      Weight:'',
    }
  }
  componentDidMount(){
    this.setState({
      Unidades:this.props.it.Unidades,
      Lenght:this.props.it.Lenght,
      Width:this.props.it.Width,
      Height:this.props.it.Height,
      Weight:this.props.it.Weight,
    })
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.it === prevState.iterador) {
      return ({ Unidades:parseFloat(nextProps.it.Unidades).toFixed(2),Lenght:parseFloat(nextProps.it.Lenght).toFixed(2),Width:parseFloat(nextProps.it.Width).toFixed(2),Height:parseFloat(nextProps.it.Height).toFixed(2),Weight:parseFloat(nextProps.it.Weight).toFixed(2),})
    }
    return null
  }
  onChangeLocal=(posicion,valor,index)=>{
    this.props.onChangeMedida(posicion,valor,index)
    this.setState({[posicion]:valor})
  }
  render(){
    return(
      <div className='itemFormConent' >
        <input value={this.state.Unidades} onChange={(e)=>this.onChangeLocal('Unidades',e.target.value,this.props.index)} type='number' placeholder="units" />
        <input value={this.state.Lenght} onChange={(e)=>this.onChangeLocal('Lenght',e.target.value,this.props.index)} type='number' placeholder="Lenght" />
        <input value={this.state.Width} onChange={(e)=>this.onChangeLocal('Width',e.target.value,this.props.index)} type='number' placeholder="Width" />
        <input value={this.state.Height} onChange={(e)=>this.onChangeLocal('Height',e.target.value,this.props.index)} type='number' placeholder="Height" />
        <div className='medidaText' style={{marginRight: '20px'}} >Cm</div>
        <input value={this.state.Weight} onChange={(e)=>this.onChangeLocal('Weight',e.target.value,this.props.index)} type='number' placeholder="Weight" />
        <div className='medidaText' >Kg</div>
        <div onClick={()=>this.props.EliminarItem(this.props.index)} className='btnEliminate item'>
          <Icon  name='delete'/>
        </div>
      </div>

    )
  }
}


export default TipoTerrestre;

import React, { Component,useState, useEffect } from 'react';
import MenuPublico from '../ComponentesGenerales/Menus/MenuPublico.js';
import Footer from '../ComponentesGenerales/Footer/Footer.js';
import {Icon} from 'semantic-ui-react';
import './LandingStyle.css';
import Header from '../ComponentesGenerales/landing/header-landing.js';
import PublicNavbar from '../ComponentesGenerales/Menus/MenuPublicov2.js';

export default function Membership (){
  const [vistaMember,setVistaMember] = useState(0)
  return(
    <div className='Membership'>
     <PublicNavbar />
      <Header video='./imagenes/LandingPage/video/membership.mp4' texto1='Join our exclusive network family'texto2='Our platform is aimed at Small and Medium Freight Forwarders, who want to grow their sales and number of monthly operations, using an interactive tool through which they can manage all their shipments more efficiently, using a minimum amount of resources.' />

      <section className='seccion1'>
        {vistaMember == 0?
          <MembershipBenefit/>
          :vistaMember == 1?
          <ReferralRewards/>
          :vistaMember == 2?
          <MembershipProcess/>
          :vistaMember==3?
          <MembershipAplication/>
          :vistaMember == 5?
          <MembershipRules/>
          :vistaMember == 6?
          <MembershipTerminos/>
          :null
        }
        <div className='member-content'>
          <div onClick={()=>setVistaMember(0)} className={vistaMember == 0?'member-item activo':'member-item'}>
            <p>Membership Benefits</p>
          </div>
          <div onClick={()=>setVistaMember(1)} className={vistaMember == 1?'member-item activo':'member-item'}>
          <p>  Referral & Rewards Program</p>
          </div>
          <div onClick={()=>setVistaMember(2)} className={vistaMember == 2?'member-item activo':'member-item'}>
            <p>Membership Process</p>
          </div>
          <div onClick={()=>setVistaMember(3)} className={vistaMember == 3?'member-item activo':'member-item'}>
            <p>Membership Application</p>
          </div>
          <div onClick={()=>setVistaMember(4)} className={vistaMember == 4?'member-item activo':'member-item'}>
            <p>Membership Fee</p>
          </div>
          <div onClick={()=>setVistaMember(5)} className={vistaMember == 5?'member-item activo':'member-item'}>
            <p>Membership Rules</p>
          </div>
          <div onClick={()=>setVistaMember(6)} className={vistaMember == 6?'member-item activo':'member-item'}>
            <p>Terms and Conditions</p>
          </div>
        </div>
      </section>
      <Footer/>
    </div>

  )
}


function MembershipBenefit (){
  return(
    <div className='contentMembership'>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>Why to be a part of our family?</p>
        <p style={{fontSize:'1.1rem'}}>
          CEIF Logistics Network is a specialized organization with a secure solution for any Freight Forwarder that wants to simplify its sales processes, reaching a greater number of potential users through our CEIF Freight App, the most powerful and dynamic platform for Freight Forwarders.
          <br/>
          Our family is based on a secure network of companies, through which multi-cooperative relationships have been established to strengthen the presence of our associates in the international market.
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>  CEIF Freight App </p>
        <p style={{fontSize:'1.1rem'}}>
          CEIF Freight App is a multi-task global platform that offers the integration of processes that allows the users to simplify the execution of their logistics operations and manage their resources effectively, helping to better address the complexities of the logistics industry while dramatically increasing productivity, reducing costs and mitigating risks through a global integrated software platform.
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}> CEIF Financial Protection</p>
        <p style={{fontSize:'1.1rem'}}>
          Our Platform offers you the Financial Protection Plan, with which you are sure that all your transactions with our members are safe and protects you against any fraudulent operation.
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>1 on 1 Family Member Interaction</p>
        <p style={{fontSize:'1.1rem'}}>
          CEIF Logistics Network offers you a unique opportunity to interact with our family members personally through our business plattform .
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>Annual Meeting Participation</p>
        <p style={{fontSize:'1.1rem'}}>
          All certified members are aplicable to participate in our annual meeting. CEIF annual meetings allow members the opportunity to meet like-minded companies, owners and decision makers of independent logistics companies from around the world at one place.
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>Instant Rates </p>
        <p style={{fontSize:'1.1rem'}}>
          Through our CEIF Management Tool platform you can create a free access to the user profile, through which you can immediately access our rates, giving a wide coverage on routes with the best prices.
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>CEIF Logistics Network Logo Usage</p>
        <p style={{fontSize:'1.1rem'}}>
          Our Family Members have the rights to use and display the our logo in their Websites, Email Signature, Advertising and public communications to indicate their affiliation to our Network.
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>CEIF Referral & Rewards Program</p>
        <p style={{fontSize:'1.1rem'}}>
          CEIF Logistics Network is a unique freight forwarding concept where it’s members are eligible to earn money, incentives & rewards after joining the network.
          <br/>
          It is simple, our members are the network promoters. Our purpose is to motivate our members to have their business partners because the only way to add trustworthy & reliable agents is to have our own members to refer the partners they have previously worked with.
          <br/>
          In the process, when they introduce a paid referral, they earn a commission and also increase their yearly profit shares.
        </p>
      </div>
    </div>

  )
}

function ReferralRewards (){
  return(
    <div className='contentMembership'>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>Who else gives you money for your support? </p>
        <p style={{fontSize:'1.1rem'}}>
          <strong>CEIF Logistics Network</strong> is a unique freight forwarding concept where it’s members are eligible to earn money, incentives & rewards after joining the network.
          <br/>
          <br/>
          It is simple, our members are the network promoters. Our purpose is to motivate our members to have their business partners because the only way to add trustworthy & reliable agents is to have our own members to refer the partners they have previously worked with.
          <br/>
          <br/>
          In the process, when they introduce a paid referral, they earn a commission and also increase their yearly profit shares.
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>Primary Rewards Program</p>
        <p style={{fontSize:'1.1rem'}}>
          • Referral Commission: Our members can earn commissions for every new paid member. Members can earn up to $100 USD for every new member they refer. There is no limit on how much you earn on our referral program.
          <br/>
          <br/>
          • The Members VIP Nomination Award: This nomination will be carried out through an annual consultation, in which our members will promote any member who considers that their service has been outstanding.
          <br/>
          <br/>

          • The CEIF Network Nomination Award: This nomination will be carried out by our organization, and users of our CEIF Freight App will be evaluated. The criteria to qualify will be:
          <br/>
          <br/>

                <span> - Best rating for performance in operations carried out through the platform.</span>

                 <span>- Greater number of operations through our platform </span>
           <br/>
           <br/>
          • Video Testimony: The member whose video testimony is published on our website will receive $100 USD.
          <br/>
          <br/>
          • Loyalty Reward: This recognition will be given to our members from the second year of renewal. From the 5th year and every 5 years, for loyalty they can win prizes and discounts.﻿ during our annual meeting.﻿
          </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>CEIF Referrals & Rewards Clauses</p>
        <p style={{fontSize:'1.1rem'}}>
          - This promotion applies exclusively to members with purchased and paid membership.
          - This promotion does not apply to basic or free memberships, with the exception of the referral commissions, these apply to any member, and in case of having basic membership, the bonus may apply for the membership upgrade.
          - Monetary rewards may be applied at the time of membership renewal or the cash bonus will be delivered by bank transfer the 3rd week of January, beginning in 2021.
          - Each of the nominations will receive a recognition and a surprise award during our annual conference.
        </p>
      </div>
    </div>
  )
}
 function MembershipAplication(){
   return(
    <iframe width="100%"  src="https://www.youtube.com/embed/LTG7y7OkqDg" title="CEIF Logistics Network   How to Register" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
   )
 }

function MembershipProcess (){
  return(
    <div className='contentMembership'>
      <div className='itemMembership'>
        <p style={{fontSize:'1.1rem'}}>
          <strong>CEIF Logistics Network</strong> is an organization that seeks to manage members that meet the highest standards in service and an excellent reputation, that is why we offer exceptional membership benefits to guarantee higher quality and added value services for our members, therefore, We accept exclusively highly experimented and reputable members agents to continue with our higher quality standards.
          <br/>
          The following steps describe our membership selection and approval process:
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>Filling the application form  </p>
        <p style={{fontSize:'1.1rem'}}>
          Companies interested in applying for membership as a certified agent of CEIF Logistics Network must fill out our easy online application form that can be found by clicking on the top of our official page in the "Register" link or by accessing directly to our addres at:
          <a href='https://ceif.app/'>https://ceif.app/</a>
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}> Screening of Candidates</p>
        <p style={{fontSize:'1.1rem'}}>

          After sending the application form, the admission commission of CEIF Logistics Network will evaluate the fulfillment of the requirements that guarantee the reputation and the experience in the logistic field of at least two years, and verify if the applicant company is in the list black with some financial protection system.
          Reviews of admission applications take place every Monday during the admission commission meeting.<br/>
          The response time is 5 business days from the date of review by the admission commission.
          <br/>
          <br/>
        <strong>  Note: Before and During this process, no payment will be requested to the candidate.</strong>

        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>Application Screening Results</p>
        <p style={{fontSize:'1.1rem'}}>
          Once the candidate's evaluation is completed, the admission commission will send a formal notification via email giving notice of the results of their application.
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>Payment and Activation</p>
        <p style={{fontSize:'1.1rem'}}>
          If the membership application is approved, the payment form will be enabled to do the payment through our system or by Bank International Wire Transfer. In the case of the free account members, the access to our platform and benefits will be activated immediately after the aproval of the membership.
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>Directory Listing</p>
        <p style={{fontSize:'1.1rem'}}>
          All our Certified Members will be listed in our directory and will have immediate access to interact with other members of the network. We strongly recommend you update your profile with all the information of the company including contacts, telephone numbers, emails, etc.
        </p>
      </div>
    </div>
  )
}

function MembershipRules (){
  return(
    <div className='contentMembership'>
      <div className='itemMembership'>
        <p style={{fontSize:'1.1rem'}}>
          <strong>
            The main objective of CEIF Logistics Network is to maintain an environment of participation based on values ​​of respect and trust. That is why we have created a basic guide easy to understand with the codes of ethics and good practices. Each member of CEIF Logistics Network agrees with the codes of ethics and good practices of our organization, and has agreed to report any breach by other members to the central office of CEIF Logistics Network.
            <br/>
            Any member that does not comply with the codes of ethics and good practices can be expelled from the network without the right to be part of our family, according to the terms and conditions of use of our organization.
          </strong>
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>Proper communication </p>
        <p style={{fontSize:'1.1rem'}}>
          For <strong>CEIF Logistics Network</strong>, respect is a basic principle, for this reason, family members should address themselves at all times with respect and cordiality in communication with other members of the family in any of the communication methods they choose to interact.
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>Mutual Co-operation</p>
        <p style={{fontSize:'1.1rem'}}>
          All members of the CEIF Logistics Network family must actively cooperate in the requirements of other members of the family, regarding Freight Forwarding and matters related to international cargo in any of its modalities. In case of not being able to attend the request of any member of the family, it is important to notify them within a maximum period of 24 hrs.
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>Effective Comunication</p>
        <p style={{fontSize:'1.1rem'}}>
          We want communication to flow in the best way, that is why in order to optimize response times it is essential that the family member requesting any RFQ provide all the information necessary to obtain a quote in a timely manner. Likewise, the family member responsible for the issuance of shipping documents at origin, must provide all shipping documentation in a timely manner to avoid any delay in the processes at the ports of destination.
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>CEIF Logistics Network Logo Usage</p>
        <p style={{fontSize:'1.1rem'}}>
          Member is allowed to use CEIF logo on its website, emails, printed documents, business cards and promotional materials during its membership period.
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>User names & Passwords</p>
        <p style={{fontSize:'1.1rem'}}>
          Family Members are the only responsible for the misuse that can be made with their passwords and access to our CEIF Management Tool platform and the operations made in their name, delisting from any legal liability to CEIF and Management,
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>User Profile information</p>
        <p style={{fontSize:'1.1rem'}}>
          Every family member is committed to keep update their profile information, and must inform to CEIF Logistics Network of any change on their organization.
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>CEIF Freight App</p>
        <p style={{fontSize:'1.1rem'}}>
          1.- All the users of the CEIF Freight App must update their rates within 5 previous days to the expiration date.
          2.- All the users of the CEIF Freight App must keep update bank information
          3.- All the users of the CEIF Freight App must inform to the administrator of any malfunction of the system.
          4.-  The user name and password is personal and will not be used by a third party or individual different to the contractor family member.
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>Violation Report</p>
        <p style={{fontSize:'1.1rem'}}>
          All members are required to inform the management of CEIF Logistics Network about any abnormal situation with any member of the family or any fraudulent situation that could jeopardize the good name of our organization and / or family members.
        </p>
      </div>
      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>Terms & Conditions of Use</p>
        <p style={{fontSize:'1.1rem'}}>
          The rules established by the Administration that regulates the actions, the business, the management process, the performance and the behaviors of the Members in the CEIF Logistics Network. It refers to the Terms and Conditions of use as well as the Ethics Codes, the same rules that are accepted by the Member in advance and are subject to modifications at any time by the Administration without prior notice.
          We strongly recommend reading them carefully.
        </p>
      </div>
    </div>
  )
}

function MembershipTerminos (){
  return(
    <div className='contentMembership'>

      <div className='itemMembership'>
        <p style={{color:'#F46363',fontSize:'1.5rem'}}>CEIF Logistics Network Terms and Conditions of use CODES and ETHICS </p>
        <p  style={{fontSize:'1.1rem'}}>
          1. DEFINITIONS
<br/>
          The name of the agent network is CEIF LOGISTICS NETWORK serves through website address; www.ceif-logistics.network / www.ceif.app which is owned, managed and published by CEIF LOGISTIC SERVICES LLC in Claymont, DE, USA but completely owned by CEIGHT INTL LOGISTIC SERVICES S.C. in Mexico, for that reason this contract will be executed under Mexican Laws.
          <br/>

           A) Management: CEIF, Board of Directors.
           <br/>

          B) CEIF : CEIF Logistics Network
          <br/>

          C) Member: The company which fully paid up requested fees.
          <br/>

          D) AGM: Annual General Meeting or Annual Conference.
          <br/>

          E) CFP: CEIF Financial Protection Program provided under the brand name CEIF Protection.
          <br/>

          F) Database: All information about business including email addresses, logo and any kind of records.
          <br/>

          G) Codes and Ethics: The rules settled by Management which regulates Member’s actions, business, handling process, performance and behaviors in CEIF. It refers to the rules which are accepted by Member in advance and subject to amendment at any time by Management without prior notification.
          <br/>

          H) CMT: CEIF Management Tool
          <br/>

          2. AIMS AND OBJECTIVES
          <br/>

          2.1 To gather and represent international freight forwarders, shipping and logistics companies under CEIF Logistics Network name.
          <br/>

          2.2 To promote Member and provide business opportunities to Member.
          <br/>

          2.3 To bring new business partners to Member from different countries.
          <br/>

          2.4 To provide reliable and strong agents from all over the world to Member.
          <br/>

          2.5 To keep Member’s service quality on higher side while it is cooperating under CEIF umbrella.
          <br/>

          2.6 To regulate and standardize Member’s business flow for highest level performance.
          <br/>

          2.7 To simplify our members operation processes, through our platform CEIF Management Tool.
          <br/>

          2.8 To help our members to increase the participation of their business in the global market.
          <br/>

          3. OWNERSHIP OF DATABASE
          <br/>

          3.1 Member agrees that the data submitted to CEIF becomes part of Management’s Database. Member acknowledges and agrees that Management owns the Database and the compilation of all Members’ data set forth in Database, all forms distributed by Management for use by its members and all reports generated by Management.
          <br/>

          3.2 Member agrees that it will not contest management’s ownership of the Database or the compilation of data set forth therein, forms or reports, or Management’s sole right to any copyright interests in Database, forms or reports.
          <br/>

          4. MEMBERSHIP BENEFITS
          <br/>

          4.1 Member is entitled to usual privileges incident to membership in CEIF, including announcements, newsletters and the opportunity to attend AGM, may have benefits of CFP as stated in section 19.
          <br/>

          4.2 Member is allowed to use CEIF logo on its website, emails, printed documents, business cards and promotional materials during its membership period.
          <br/>

          4.3 Member is listed at www.ceif-logistics.network with its company name and address, logo, contact person’s details and introduction text or company biography.
          <br/>

          4.4 Member has username and password for the members only area and/or social platform of agent network to share information and ideas with other Members. (if available)
          <br/>

          4.5 Member works with other members based on codes and ethics which aim to provide highest level standards.
          <br/>

          5. FEES
          <br/>

          5.1 One time registration fee is paid only one time at the beginning of membership period.
          <br/>

          5.2 Membership fee is usd 1,100 for headquarter or 1st office .
          <br/>

          5.3 Membership fee is usd 350 per branch office.
          <br/>

          5.4 Payment Protection Program fee is paid as indicated in section 19.
          <br/>

          5.5 Member accepts that fees are payable for a 12 month period from the date of acceptance and usd 50.00 bank processing fee is added to top of the fees if funds sent via bank transfer.
          <br/>

          5.6 In the event that payment is not received within 30 days of billing or the day of membership renewal, usd 150 surcharge is applied and member shall be considered in default and membership revoked if full payment (inclusive of the surcharge) is not received within next ten (10) working days.
          <br/>

          5.7 Fees are non refundable under any circumstance.
          <br/>

          5.8 Fees are non transferable to any party.
          <br/>

          6. INDEMNIFICATION
          <br/>

          6.1 Member hereby agrees to indemnify and hold harmless Management and each of its directors, officers, employees and agents from all litigation, damages, claims, settlement payments, liabilities, actions, causes of action, and reasonable costs or expenses (including attorneys' fees and expenses), incurred, suffered, sustained or required to be paid by Management or any of the indemnitees listed above by reason of, or resulting from, a claim by any third party based upon an implied or express allegation that (a) any representation or warranty made by Member was not correct or accurate, or (b) Member breached any provision of this Codes and Ethics.
          <br/>

          7. TERMINATION AND REVOCATION OF MEMBERSHIP
          <br/>

          7.1 Management has the right and the authority to terminate membership without declare any reason. Fees will not be refunded in any circumstance.
          <br/>

          7.2 Regardless of the manner of termination of membership, the provisions contained in sections 3 and 6 of this Codes and Ethics shall survive expiration of Member’s membership in CEIF.
          <br/>

          7.3 Member must remove the CEIF logo from its website, emails and printed documents once its membership is terminated.
          <br/>

          7.4 Member may terminate CEIF membership at anytime. Fees will not be refunded in any circumstance.
          <br/>

          7.5 Notice to terminate membership must be given by Member 3 months prior to the expiry date of membership.
          <br/>

          7.6 No reference about CEIF can be used in the case of termination of Membership.
          <br/>

          7.7 Management can update and inform Members about the reason of termination of Membership.
          <br/>

          8. COMMUNICATIONS
          <br/>

          8.1 Member must have internet access, e-mail capability, operative website and must be fully protected by anti-virus software.
          <br/>

          8.2 Member is responsible for advising CEIF about any updates and changes to their company profiles and/or contact details.
          <br/>

          8.3 Member uses English as communication language in all emails.
          <br/>

          8.4 Member automatically gives permission to Management to use any material about it for promotion by submitting company news, photos, logos or press releases.
          <br/>

          8.5 Member is invited to use CEIF logo on letterheads, business cards and its website during its membership.
          <br/>

          8.6 Member agrees to report any displeasure regarding communication with other CEIF members.
          <br/>

          8.7 Member agrees to respond emails in maximum 24 hours.
          <br/>

          9. LEGAL LIABILITY
          <br/>

          9.1 Member accepts that Management shall not be responsible for any damages or legal claims.
          <br/>

          9.2 Member accepts that Management shall not be responsible for any debt, obligation or liability of a Member.
          <br/>

          9.3 Member accepts that the governing law of CEIF shall be that of Mexico General Law of Commerce.
          <br/>

          9.5 Member irrevocably agrees that the courts of Mexico shall be exclusive jurisdiction to settle any dispute which may arise out of, under, or in connection with this codes and ethics, use of website www.ceif-logistics.network / www.ceif.app and any other information.
          <br/>

          9.6 Member accepts that Management cannot be hold responsible for any errors, omissions, unpaid invoice(s), misunderstandings or claims arising from this codes and ethics.
          <br/>

          9.7 Members accepts that the acceptance of these terms and conditions represent the written acceptance of the contract of use of CEIF Logistics Network and / or CEIF Management Tool, and are subject to the application of any penalty for the breach of the rules of operation.
          <br/>

          9.8 Members accepts that they are the only responsible for the misuse that can be made with their passwords and access to our CEIF Management Tool platform and the operations made in their name, delisting from any legal liability to CEIF and Management,
          <br/>

          9.9 accepts Members that any booking generated through our platform CMT represents a liability with the Members incorporated to the tool, and any cancellation after the next 12 hrs will cause a penalty charge of $50.00 USD in addition to the charges generated by the carrier, airline or any supplier involved in the process.
          <br/>

          9.10 Members accepts that the rates published by their company in our CMT platform are their responsibility, and CEIF will not be legally responsible for any error or omission as well as any loss as a result of any operation.
          <br/>

          9.11 Members accepts that the CFP will only apply to those members that have contracted the service, the members that do not contract the CFP will be without any protection, without any liability to CEIF.
          <br/>

          10. FUTURE MEMBERSHIP AGREEMENTS, CODES AND ETHICS
          <br/>

          Member acknowledges and agrees that, as a condition to its continued membership in CEIF, Management shall have the right, if it revises its standard membership agreement, codes and ethics, to require Member to enter into the then-current version of membership agreement, code and ethics.
          <br/>

          11. RETROACTIVE APPLICATION
          <br/>

          In the event that Member participated as a member of CEIF prior to the date of this codes and ethics, Member agrees that all provisions of this codes and ethics shall apply to its membership in CEIF during such prior period.
          <br/>

          12. MISCELLANEOUS
          <br/>

          12.1 This codes and ethics supersedes all prior codes and ethics and constitutes the entire Agreement between the Member and Management regarding the subject matter hereof. This codes and ethics shall be binding upon and inure to the benefit of Management, CEIF, Member and their successors and assigns.
          <br/>

          12.2 All rights, title and interest in the name of CEIF owned by Management and cannot be used by Member without Management permission.
          <br/>

          12.3 Noncompliance or disregard to codes and ethics will result in disciplinary action such as cancellation of membership and being black listed in any platform, debt recovery agency website or database including www.ceif-logistics.network / www.ceif-tool.net.
          <br/>

          12.4 CEIF Codes and Ethics are formed to provide operational flexibility, quality and trust among CEIF members.
          <br/>

          12.5 CEIF is independent from any Member or individual that publishes their details and services in this website.
          <br/>

          13. OPERATION, DOCUMENTATION AND MARKETING PROCESS
          <br/>

          13.1 Member agrees to exchange only real net/net charges and rates.
          <br/>

          13.2 Free hand shipments are handled as free of charge.
          <br/>

          13.3 Handling fee and profit share must be mentioned and agreed between members prior to shipments.
          <br/>

          13.4 For free hand shipments, cargo origin Member should take confirmation from destination Member if the destination Member accepts mentioned shipment and for required conditions prior to shipment.
          <br/>

          13.5 Cargo origin Member should take confirmation from destination Member regarding documents such as MBL/HBL, MAWB/HAWB, CMR, ATR, Certificate of Origin if prepared documents match with destination country requirements and regulations.
          <br/>

          13.6 Destination Member should check draft documents, request modification if needed and confirm before vessel sailed or flight/truck departure to the cargo origin Member.
          <br/>

          13.7 Documents such as CMR, MBL/HBL and MAWB/HAWB must be prepared regarding destination Member’s instructions. Members should agree on documentation prior to vessel, flight and truck departure.
          <br/>

          13.8 Destination Member is responsible for collection of the original Bill Of Lading issued by the other party and all freight charges in freight collect shipments before releasing cargo to consignee (unless otherwise agreed).
          <br/>

          13.9 Destination Member must send “Arrival Notices” to consignee and/or notify party on HBL, HAWB, CMR and provide a good collaboration with them for a smooth delivery. Ocean carrier’s B/L cannot be released to any third party.
          <br/>

          13.10 Cargo originating Member must send Pre-Alert message by e-mail or fax with CMR, MBL/HBL and MAWB/HAWB copies via email to destination Member.
          <br/>

          13.11 Pre-Alert message must be sent by email or fax to destination Member in 48 hours after vessel sailed and in 24 hours after flight and truck departure.
          <br/>

          13.12 CMT Members commits to keep their rates up to date and be monitoring expiration dates since the platform is programmed to block expired rates.

          <br/>

          14. ACCOUNTING and SETTLEMENT
          <br/>

          14.1 All payments/accounts must be settled within 30 days from the date of invoice (unless otherwise agreed) or earlier in case of larger amounts.
          <br/>

          14.2 Awaiting payment from anyone such as client, customer, shipper and consignee are not a reason and an excuse for non-payment to another member unless these were agreed conditions prior to the trade.
          <br/>

          14.3 Payments (TT/WT) should be in the currency quoted. (Unless otherwise agreed)
          <br/>

          14.4 All bank charges occurring on the side of the beneficiary for remittance are the responsibility of the beneficiary.
          <br/>

          14.5 Payer should arrange remittance with all bank charges including intermediary bank charges and amount should arrive beneficiary account as on the invoice.
          <br/>

          15. DISPUTES AND ARBITRATION
          <br/>

          15.1 Member must follow Case and Claim Management process.
          <br/>

          15.2 All Claims are handled as indicated in Case and Claim Management process.
          <br/>

          15.3 Management is involved in the quality of cooperation among its Members. Problems regarding reliability or financial issues must be reported immediately to Management in order to take the required actions.
          <br/>

          15.4 Management can act as an independent arbitration facilitator for discrete and speedy resolution of claims between members.
          <br/>

          15.5 Any claim between members related to their cooperation is settled by Management mediation.
          <br/>

          15.6 Member agreed to accept Management’s decision as final decision and member will make payment or pay penalty accordingly if it is required by Management.
          <br/>

          16. DISCIPLINARY PROCEDURES
          <br/>

          16.1 Management has the right to list Member in blacklists or make Member black listed in any platform.
          <br/>

          16.2 Management has the right to inform all members about the delinquent and default party.
          <br/>

          16.3 Management has the right to send warning message to the companies in its database.
          <br/>

          16.4 Management has right to inform other private and official organization, associations and agencies about a blacklisted or delinquent member.
          <br/>

          17. ANNUAL CONFERENCE PARTICIPATION
          <br/>

          17.1 Member accepts that joining annual general meeting at least one time in each two year is mandatory.
          <br/>

          17.2 Member accepts to confirm its firm annual general meeting participation at latest one (1) month prior to event date. Member may not be allowed to join annual general meeting in case of late notification to Management.
          <br/>

          17.3 Member accepts that in case it is not able to attend annual general meeting, fees are totally non refundable and non transferable to any other Member or party.
          <br/>

          17.4 Management will arrange annual conference each year in a country regarding its operational capabilities. In case of any failure of arranging annual general meeting, Management cannot be held responsible for any expense, error or omissions by Member and fees are totally non-refundable.
          <br/>

          17.5 Member accepts that Management has the right to cancel annual general meeting or change the location and date by prior notice through email or fax.
          <br/>

          18. ACTIVITY REPORT, MONITORING AND EVALUATION
          <br/>

          18.1 Member should transfer at least 30% of its's business to fellow Members. Agents's performance is evaluated annually by score cards or activity reports. High performance and great attention to fellow agents are required.
          <br/>

          18.2 Management may want Member to prove that its stable and solvent. Member accepts to provide financial reports and information about the firm.
          <br/>

          18.3 Management may require any survey documents and information from Member to perform random quality controls.
          <br/>

          18.4 Providing monthly Activity Report is mandatory and Member must provide it with all required details on the date assigned and regarding the given guideline.
          <br/>

          18.5 Activity Report must contain following information such as invoice numbers, invoice amounts, invoice date, payer name, equipment types, departure port, destination port, M/BL and H/BL numbers.
          <br/>

          19. CEIF FINANCIAL PROTECTION (PAYMENT PROTECTION PROGRAM)
          <br/>

          19.1 CEIF Protection is the name of Payment Protection Program.
          <br/>

          19.2 Participation to CEIF Protection is mandatory in CEIF Logistics Network
          <br/>

          19.3 CEIF Management Tool users are exempts of the CEIF Protection Participation as long as they use our payment tool as default payment method.
          <br/>

          19.4 Members must follow the stated clauses in here to get benefit from CEIF Protection.
          <br/>

          19.5 CEIF Members are allowed to choose Protection Group from A to D.
          <br/>

          19.6 Payment Protection Program Contribution Table
          *Member can choose only one protection group;
           Protection Group Head Office Only Head Office & One Branch Additional Branch
           A usd 500 usd 750 usd 500
           B usd 750 usd 1000 usd 500
           C usd 1000 usd 1250 usd 500
           D usd 1500 usd 1750 usd 500
           <br/>

          19.7 Payment Protection Program Compensation
           Protection Group Planned Maximum Per Incident Compensation Limit
           A 5% of Actual CEIF Protection Budget
           B 10% of Actual CEIF Protection Budget
           C 15% of Actual CEIF Protection Budget
           D 20% of Actual CEIF Protection Budget
           <br/>

          *Incident defined as unpaid invoices related to the concerned shipment.
          <br/>

          19.8 CEIF Protection covers unpaid invoices for the handling of freight transactions such as air freight, sea freight, land freight, inland transportation, customs clearance service fee and local port charges.
          <br/>

          19.9 CEIF Protection is not a cargo or freight liability insurance. Customs penalties, warehouse charges, storage charges, demurrage charges, value of goods, duties/taxes, errors, omissions and bankruptcy of a member are not covered by CEIF Protection.
          <br/>

          19.10 Cargo Claim is not applicable in this program. Each member can obtain the cargo insurance and third party liability insurance separately.
          <br/>

          19.11 In order to receive payment from Payment Protection Program, the recipient must agree to sign any future recoveries of the debt over to the Management and to fully cooperate with CEIF in instituting collection and/or legal recovery of such debt.
          <br/>

          19.12 Activity Report must be submitted to Management in first ten days of every month.
          <br/>

          19.13 CEIF Protection covers only the invoices submitted in Activity Report to Management on time.
          <br/>

          19.14 Both origin and destination agent must be the member of CEIF.
          <br/>

          19.15 Members should ensure that they are dealing with an office that is enrolled to CEIF and CEIF Protection; Offices that are NOT enrolled are NOT protected under CEIF Protection.
          <br/>

          19.16 Fees are non-refundable and non-transferable.
          <br/>

          19.17 Shipper and Consignee agents on MBL and MAWB must be the member of same agent network organization and must be the companies who are covered by CEIF Protection.
          <br/>

          19.18 Debtor and creditor companies shown on an invoice must be the member of same agent network organization, should hold valid membership and must be the companies who are enrolled to CEIF Protection. Member agent is to be monitored by the network management via activity report.
          <br/>

          19.19 Claim is not accepted if the creditor fails to report to the network management within 60 days from the date of invoice.
          <br/>

          19.20 Creditor must report the default within 60 days from the date of invoice through opening “Claim for Unpaid Invoice”.
          <br/>

          19.21 Management cannot be held responsible and does not accept any financial liability for commercial or financial obligations that are not met by Member.
          <br/>

          NOTICE AND DISCLAIMER: CEIF LOGISTICS NETWORK LOCATED AT MEXICO DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE OR USE, ANY WARRANTY OF COMPATIBILITY BETWEEN THE ORGANIZATIONS, SITES OR SOFTWARE OR DATA PROVIDED AND EQUIPMENT OR SOFTWARE OWNED OR USED BY MEMBER, AND ANY IMPLIED WARRANTIES ARISING FROM COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE. MEMBER AGREES THAT CEIF LOGISTICS NETWORK. SHALL NOT BE LIABLE FOR ANY LOSSES OR DAMAGES, INCLUDING WITHOUT LIMITATION ANY LOSS OF USE, LOSS OF DATA, LOST PROFITS, LOSS OF BUSINESS, OR OTHER INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR OTHER DAMAGES, EVEN IF CEIF LOGISTICS NETWORK HAS BEEN INFORMED OF THEIR POSSIBILITY.
        </p>
      </div>

    </div>
  )
}

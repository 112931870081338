import React, { Component } from 'react';
import {Direccion} from '../strings/peticiones.js';
import firebase from 'firebase'
import './confirmarEmail.css';
import { Divider, Header,Loader,Rating,List,Dimmer,Label, Icon, Table, Card, Image,Button,Modal, Form,Message,Input } from 'semantic-ui-react';
import axios from 'axios';
import Particles from 'react-particles-js';

class Confirmar extends Component{

  constructor(){
    super()
      this.state = {
        idUser:'',
        dimmerActive:false,
        confirm:false,
        wrong:false,
      }

  }

   onChangeIdUser=(e)=>{
    this.setState({
      idUser:e.target.value
    })
  }

  VerifyID=()=>{
    let self = this;
    axios.post(Direccion+`/confirm_email`,{id:this.state.idUser})
      .then(res => {
        console.log(res.data);
          if (res.data === 'cuentaVerificada') {
              self.setState({
                confirm : true,
                dimmerActive: false,
              })
          }
          else{
             self.setState({
                confirm : false,
                wrong : true,
                dimmerActive: false,
              })
          }
      })
  }


  render(){


    return(

      <div>
          <Dimmer page active={this.state.dimmerActive}>
           <Loader size='large'>Verify</Loader>
          </Dimmer>
        <div style={{position:'relative',height:'30vh',marginBottom:'3vh'}}>
        <Particles style={{backgroundImage:'url(../imagenes/head7.jpg)', backgroundSize:'cover',backgroundPosition:'center', position:'absolute',height:'500px'}}
        params={{
          "particles": {
            "number": {
              "value": 40
            },
            "size": {
              "value": 2
            }
          },
          "interactivity": {
            "events": {
              "onhover": {
                "enable": true,
                "mode": "repulse"
              }
            }
          }
        }} />
          <Divider horizontal style={{paddingTop:'14vh'}}>
          <Header as='h3' style={{position:'relative',color:'white'}}>
          <i class="material-icons" style={{ fontSize: '2em' }}>verified_user</i>
          Confirm Account
          </Header>
          </Divider>
        </div>
        <div className='header-perfil'>

          <Card.Group>

          {!this.state.confirm?
            <Card style={{width:'60%', minWidth:'250px', margin:' 0px auto'}}>
              <Card.Content>
                <Header style={{margin:'10px auto',textAlign:'center'}} >Enter the ID you received by mail to verify</Header>

                <Input style={{margin:'10px auto', display:'block'}} value={this.state.idUser} onChange={this.onChangeIdUser}  readonly/>

                <Button style={{margin:'10px auto',display:'block'}} positive onClick={this.VerifyID}>
                    Verify
                </Button>
                {this.state.wrong?
                  <p style={{margin:'10px auto',display:'block',color:'#ff6000',textAlign:'center'}}>enter a correct ID</p>:<div></div>
                }

              </Card.Content>
            </Card>:
               <div className="success-modal">
                <div className="anim">
                    <div className="container">
                        <svg width="580" height="400" xmlns="http://www.w3.org/2000/svg">
                         <g>
                          <circle id="circle" r="80" id="svg_1" cy="191.5" cx="294" stroke-width="10" stroke="#fff" fill="none"/>
                          <g id="popGroup">
                           <line stroke="#fff" stroke-linecap="butt" stroke-linejoin="null" id="svg_2" y2="77.981489" x2="291.5" y1="98" x1="291.5" fill-opacity="null" stroke-opacity="null" stroke-width="5" fill="none"/>
                          <line transform="rotate(45 375.49999999999994,123.99074554443358) " stroke="#fff" stroke-linecap="butt" stroke-linejoin="null" id="svg_3" y2="113.981489" x2="375.5" y1="134" x1="375.5" fill-opacity="null" stroke-opacity="null" stroke-width="5" fill="none"/>
                          <line transform="rotate(90 398.50000000000006,198.99075317382815) " stroke="#fff" stroke-linecap="butt" stroke-linejoin="null" id="svg_4" y2="188.981489" x2="398.5" y1="209" x1="398.5" fill-opacity="null" stroke-opacity="null" stroke-width="5" fill="none"/>
                          <line transform="rotate(135 363.5,274.99075317382807) " stroke="#fff" stroke-linecap="butt" stroke-linejoin="null" id="svg_5" y2="264.981489" x2="363.5" y1="285" x1="363.5" fill-opacity="null" stroke-opacity="null" stroke-width="5" fill="none"/>
                          <line transform="rotate(-180 285.5,299.99072265625) " stroke="#fff" stroke-linecap="butt" stroke-linejoin="null" id="svg_6" y2="289.981489" x2="285.5" y1="310" x1="285.5" fill-opacity="null" stroke-opacity="null" stroke-width="5" fill="none"/>
                          <line transform="rotate(-135 201.49999999999997,268.99072265625) " stroke="#fff" stroke-linecap="butt" stroke-linejoin="null" id="svg_7" y2="258.981489" x2="201.5" y1="279" x1="201.5" fill-opacity="null" stroke-opacity="null" stroke-width="5" fill="none"/>
                          <line transform="rotate(-90 183.50000000000003,197.99075317382812) " stroke="#fff" stroke-linecap="butt" stroke-linejoin="null" id="svg_8" y2="187.981489" x2="183.5" y1="208" x1="183.5" fill-opacity="null" stroke-opacity="null" stroke-width="5" fill="none"/>
                          <line stroke="#fff" transform="rotate(-45 207.49999999999997,127.99075317382812) " stroke-linecap="butt" stroke-linejoin="null" id="svg_9" y2="117.981489" x2="207.5" y1="138" x1="207.5" fill-opacity="null" stroke-opacity="null" stroke-width="5" fill="none"/>
                         </g>
                           <g id="tick">
                           <line id="tick1" stroke="#fff" stroke-linecap="null" stroke-linejoin="null" id="svg_10" y2="218.000001" x2="291.500001" y1="200" x1="263.5" fill-opacity="null" stroke-opacity="null" stroke-width="10" fill="none"/>
                           <line id="tick2" transform="rotate(10.907037734985352 306.99999999999994,186.49999999999983) " stroke="#fff" stroke-linecap="null" stroke-linejoin="null" id="svg_11" y2="156.999997" x2="319.500001" y1="216" x1="294.5" fill-opacity="null" stroke-opacity="null" stroke-width="10" fill="none"/>
                          </g>
                        </g>
                    </svg>
                    </div>
                </div>
                <div className="info">
                    <div className="title">Account successfully verified!</div>
                    <div className="text">Welcome to CEIF Logistic Network!</div>
                    <a href="/"  className="continue"><i className="ion-android-arrow-forward"></i> Continue</a>
                </div>
            </div>

          }

          </Card.Group>
        </div>

      </div>
    )
  }
}



export default Confirmar;

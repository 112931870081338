import React, { Component } from 'react';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import {Router, Route, BrowserRouter, Switch, Link,} from 'react-router-dom'
import { Message,Menu,Popup,Form,Modal,Icon,Header,Dimmer,List,Image,Loader,Button } from 'semantic-ui-react';
import Badge from '@material-ui/core/Badge';
import ListaNotificaciones from './listaNotificacionesVendedor.js';
import axios from 'axios';
import {Direccion} from '../../strings/peticiones.js';
import firebase from 'firebase'

import AlertSnack from '../notificacionCard.js';


class BarraLateral extends Component{
  constructor(){
    super()
    this.state = {
      verificado: firebase.auth().currentUser.emailVerified,
      notificaciones:[],
      enviado:false,
      diasSuscripcion:'',
      title:"",
      message:"",
      openAlert:false,
      openModalPagarSuscripcion:false,
      openPopUpFree:false,
      UserCatalogo : true,
      paqueteID : "",
    }
  }

  UNSAFE_componentWillMount(){
    this.getSuscription();
    this.aununcioSuscripcion();
    this.StatusUsuario();

  }
  aununcioSuscripcion(){
    let data;
    axios.post(Direccion+'/mensajeSuscripcion',{id:firebase.auth().currentUser.uid})
    .then(res=>{
      data=res.data;
      this.notificaciones(data);
    })
  }
  getSuscription=()=>{
    let perfil=[];
    var self=this;
    axios.post(Direccion+`/tomarSuscripcion`,
      {idUser:firebase.auth().currentUser.uid})
      .then(res => {
        if (res.data === '404') {
        }else{
          this.setState({
            paqueteID:res.data.suscripcionId,
          })
          if(res.data.suscripcionId != 1 && res.data.suscripcionId != 2){
            this.setState({
              UserCatalogo:false,
              paqueteID:res.data.suscripcionId,
            })
          }
          if (res.data.suscripcionId === 1 && !window.sessionStorage.Visita) {
            this.setState({
              openPopUpFree:true,
            })
            window.sessionStorage.Visita = true;
          }
        }
      })
  }

  notificaciones(dias){
    var data;
    var self=this;
    axios.post(Direccion+'/getNotificaciones',{id:firebase.auth().currentUser.uid,tipo:'Vendedor'})
    .then(res=>{
      data=res.data;
      localStorage.setItem('notificaciones', JSON.stringify(data));
      this.setState({notificaciones:data.reverse(),diasSuscripcion:dias})
    })
  }

  StatusUsuario(){
    var self=this;
    axios.post(Direccion+'/StatusUsuario',{idUser:firebase.auth().currentUser.uid,tipo:'Vendedor'})
    .then(res=>{
      if (!res.data.Status) {
         this.setState({openAlert:true,message:res.data.message,title:res.data.title})
         setTimeout(function() {
          firebase.auth().signOut();
        }, 2500);

     }
   })
  }
  reenvioCorreo=()=>{
    var self=this;
    axios.post(Direccion+'/reenvioCorreo',{id:firebase.auth().currentUser.uid,email:firebase.auth().currentUser.email,name:firebase.auth().currentUser.displayName})
    .then(res=>{
      if (res.data === 'OK') {
        this.setState({
          enviado:true,
        });
      }
    })
  }
  PagarSuscripcionModal=()=>{
    this.setState({
        openModalPagarSuscripcion:true,
      })
  }
  handleCloseSuscripcion=()=>{
    this.setState({
        openModalPagarSuscripcion:false,
      })
  }
  cerrar(e){
    if(e==='membresia'){
      this.setState({
        diasSuscripcion:false
      })
    }
    else if(e==='verificacion'){
      this.setState({
        verificado:true
      })
    }
  }

  handleItemClick=()=>{
    firebase.auth().signOut();
  }
  resetAlert = ()=> this.setState({ openAlert:false,titleAlert: '', AlertMessage:'', AlertType:'',});


  render(){
    return(
<div>

<Menu pointing style={{fontSize:'15px'}}>
  <Link to="/seller">
    <Menu.Item name='home' />
  </Link>

  {this.state.UserCatalogo?
    <div style={{opacity:0.5}}><Menu.Item name='Rates'/></div>:
    <Link to='/seller/Rutas'>
      <Menu.Item name='Rates'/>
    </Link>
  }
  <Link to="/seller/Perfil">
    <Menu.Item name='Profile'/>
  </Link>
  {this.state.UserCatalogo?
    <div style={{opacity:0.5}} ><Menu.Item name='Operations'/></div>:
    <Link to="/seller/Envios">
      <Menu.Item name='Operations'/>
    </Link>
  }
  {this.state.UserCatalogo?
    <div style={{opacity:0.5}} ><Menu.Item name='Operations'/></div>:
    <Link to="/seller/Bookings">
      <Menu.Item name='Bookings'/>
    </Link>
  }

  <Link to="/seller/Pagos">
    <Menu.Item name='Accounting'/>
  </Link>

  <Link to="/seller/Catalogo">
    <Menu.Item name='Directory'/>
  </Link>

  <Menu.Menu position='right'>
    <Link to="/">
      <Menu.Item >
        <Icon name='search' ></Icon>
      </Menu.Item>
    </Link>

    <Menu.Item>
      <Popup
        style={{maxWidth:'85vh',height: 'auto',maxHeight: '65vh',overflowY: 'scroll'}}
         trigger={<Badge badgeContent={this.state.notificaciones.length} color="secondary">
        <i className="material-icons">
        notifications
        </i>
        </Badge>}
      content={<ListaNotificaciones datos={this.state.notificaciones}/>}
      on='click'
       />
    </Menu.Item>
  </Menu.Menu>
<Menu.Menu>
  <Menu.Item
    onClick={this.handleItemClick}
  >
    <Icon name='sign-in'></Icon>
    <p>Log out</p>

  </Menu.Item>
  </Menu.Menu>

</Menu>
{this.state.paqueteID==="2"?
  <div style={{margin: '0 auto',width:' 210px',textAlign: 'center',backgroundColor: '#d6a142',color: '#fff',marginTop: '-15px',borderBottomLeftRadius: '10px',borderBottomRightRadius: '10px'}}>
    <h3>CEIF Protection Plan</h3>
  </div>:
  <div></div>
}
    { !this.state.verificado ?
      <Message negative onDismiss={this.cerrar.bind(this,'verificacion')}>
        <Message.Header>Your account is not verified yet</Message.Header>
          <p>Please check your email and verify your account to join the Experience and benefits of CEIF
           {!this.state.enviado?
              <div>
              <a onClick={this.reenvioCorreo}>resend e-mail</a>
              </div>
              :
              <div>
              <a>check your email, the email has already been sent.</a>
              </div>
            }</p>
      </Message>
      :
        <div></div>
    }
    { this.state.diasSuscripcion !=false?
      <Message color='orange' onDismiss={this.cerrar.bind(this,'membresia')}>
        <Message.Header>{this.state.diasSuscripcion}</Message.Header>
          <p><a onClick={this.PagarSuscripcionModal.bind()}>Update your membership</a></p>
      </Message>
      :
        <div></div>
    }
    <Modal size={'tiny'} open={this.state.openPopUpFree} onClose={()=>{this.setState({openPopUpFree:false})}}>
      <Modal.Header>Get Premium</Modal.Header>
      <Modal.Content>
        <p style={{textAlign:'center', fontSize:'24px', margin:'5px auto'}}>You have a FREE account </p>
        <p style={{textAlign:'center', fontSize:'24px', margin:'5px auto'}}>If you want to take a premium account</p>
        <p style={{textAlign:'center', fontSize:'24px', margin:'5px auto'}}>click on the following link</p>
        <a style={{textAlign:'center',fontSize:'19px', margin:'5px auto'}} href="https://ceif.app/seller/Paquetes">GET PREMIUM</a>
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          icon='checkmark'
          onClick={()=>{this.setState({openPopUpFree:false})}}
          labelPosition='right'
          content='OK'
        />
      </Modal.Actions>
    </Modal>
    <AlertSnack openAlert={this.state.openAlert} titleAlert={this.state.titleAlert} resetAlert={this.resetAlert} AlertMessage={this.state.AlertMessage} AlertType={this.state.AlertType}/>
    <PagarSuscripcion handleClose = {this.handleCloseSuscripcion} open={this.state.openModalPagarSuscripcion} />
</div>
)
}
}

class PagarSuscripcion extends Component{
  constructor(){
    super()
  this.state = {
      suscripcion:'' ,
      Cards:[],
      selectCard:false,
      CardIndex:'',
    }
    this.handleClose = this.handleClose.bind(this);
    this.getSuscription();
    this.GetCards();
  }
  handleClose=()=>{
    this.props.handleClose();
  }

  getSuscription=()=>{
    let perfil=[];
    var self=this;
    axios.post(Direccion+`/tomarSuscripcion`,
      {idUser:firebase.auth().currentUser.uid})
      .then(res => {
        if (res.data === '404') {
          this.handleClose();

        }else{
            self.setState({
              suscripcion:res.data,
            })
        }
      })
  }

  GetCards=()=>{
    var self=this;
    axios.post(Direccion+`/tomarCards`,
      {idUser:firebase.auth().currentUser.uid,tipo:"Vendedor",})
      .then(res => {
        if (res.data.length !=0 && res.data.length >0) {
          self.setState({
            Cards:res.data,
          })
        }
        else {
          self.setState({
            Cards:[],
          })
        }
    })
  }

  PagarSsucripcionRenovada=()=>{
    let self =this;
    axios.post(Direccion+'/pagarSuscripcion',{NuevaSuscripcion:false,idUser:firebase.auth().currentUser.uid,tipo:"Vendedor",idCard:this.state.CardIndex,
      expiry:this.state.expiry,cvv:this.state.cvc,idPaquete:parseInt(this.state.suscripcion.suscripcionId)})
    .then(res=>{
      if(res.data==='OK'){
        self.setState({
          dimmerActive: false,
          loader: false,
        });
        window.location.reload();
      }
      else if(res.data === "P101"){
        self.setState({
          dimmerActive: false,
          loader: false,
        });
        alert("complete your profile information");
      }else if(res.data === "340"){
        window.location.reload();
      }
      else{
        self.setState({
          dimmerActive: false,
          loader: false,
        });
      }
    })

  }

    handleInputChange = (e) => {
    const target = e.target;
    if (target.name === 'expiry') {
      var value=target.value;
      if (/^([0-9-/])*$/.test(target.value) ){
          if(value.length===2 ){
            this.setState({
              [target.name]:value.replace(/ |\//g, '')+'/',
            });
          }
          else{
            this.setState({
              [target.name]:value,
            });
          }
      }
    }else if (target.name === 'cvc'){
      var value=target.value;
      if (/^([0-9])*$/.test(target.value)){
        this.setState({
          [target.name]:value,
        });
      }

    }
  }//cierre handle



   UsingCard=(key)=>{
    this.setState({  CardIndex: key, selectCard:true, })
  }

    render(){
      return (
        <Modal open={this.props.open} onClose={this.handleClose}>
         <Header icon='archive' content='update your CEIF plan' />
          <Modal.Content>
            <h2 style={{margin:'0px auto'}}>{this.state.suscripcion.suscripcionName}</h2>
            <Modal.Description style={{margin:'15px auto', textAlign:'center'}}>
              <p>Confirm your payment by entering your expiration date and your CVV, remember that CEIF for security reasons does not save your expiration date and your CVV.</p>
            </Modal.Description>
             <div style={{margin:'0px auto'}}>
              {this.state.Cards.length>0?
                <div>
                     {this.state.selectCard?
                       <div>
                         <Form.Group id="FormExpiry" >
                         <Form.Input
                             id='inputExpiry'
                             type='tel'
                             name="expiry"
                             placeholder='Expiration date'
                             value={this.state.expiry}
                             onChange={this.handleInputChange}
                             maxLength={5}
                           onFocus={this.handleInputFocus}
                           />
                         <Form.Input
                               id='inputExpiry'
                               type='tel'
                               name="cvc"
                               placeholder='Security code'
                               value={this.state.cvc}
                               onChange={this.handleInputChange}
                               maxLength={3}
                             onFocus={this.handleInputFocus}/>
                         </Form.Group>
                       </div>:

                       <div>
                         {  this.state.Cards.map((it,index,key)=>{
                           return(<ListaCard  GetCardsCallback={this.UsingCard} datos={it}/>)
                           })
                         }
                       </div>
                     }
               </div>
               :<div>
                 <p id={"ModalPayP"}>You have to register a card if you want to pay by this means</p>
               </div>
              }
             </div>
          </Modal.Content>

         <Modal.Actions>
          <Button onClick={this.close2} negative>
             <Icon name='remove' /> Cancel
          </Button>
         {this.state.expiry && this.state.cvc?
          <Button positive    disabled={!this.state.expiry||!this.state.cvc} onClick={this.PagarSsucripcionRenovada}>
           <Icon name='checkmark' /> "Accept"
         </Button>:
         <div></div>
         }
         </Modal.Actions>
       </Modal>
      )}
  }







class ListaCard extends Component{
  constructor(){
    super()
    this.state = {
    datos:[],
    dimmerActive:false,
    }
  }

  selected=()=>{
    this.props.GetCardsCallback(this.props.datos.key);
  }

  render(){
    return(

      <div>
      <Dimmer page active={this.state.dimmerActive}>
       <Loader size='large'>Loading...</Loader>
       </Dimmer>
      <List  id="listaCards" animated verticalAlign='middle'>

        <List.Item onClick={this.selected} id="ItemCards">

        {this.props.datos.displayNumber.substring(0, 1) === '4'?
          <div>
          <Image  size='tiny' src='../imagenes/visa.png' />
          </div>:
          <div>
          <Image size='tiny' src={'../imagenes/Mastercard.png'}  />
          </div>
        }
          <List.Content id='ContentCards'>
            <List.Header>{this.props.datos.displayNumber}</List.Header>
            <List.Description>{this.props.datos.HolderCuenta}</List.Description>
          </List.Content>
        </List.Item>
      </List>
      </div>
    )
  }
}

export default BarraLateral;

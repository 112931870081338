import React, { Component } from 'react';
import Analitics from '../componentes/ComponentsSeller/analiticas.js'
import EnviosVendedor from '../componentes/ComponentsSeller/EnviosVendedor.js'
import Bookingscomponent from '../componentes/ComponentsSeller/BookingsVendedor.js'
import PerfilPublico from '../componentes/Catalogo/PerfilPublico.js'
import PagosVendedor from '../componentes/ComponentsSeller/PagosVendedor.js'
import PerfilVendedor from '../componentes/ComponentsSeller/PerfilVendedor.js'
import RutasVendedor from '../componentes/ComponentsSeller/RutasVendedor.js'
import PaquetesVendedor from '../componentes/ComponentsSeller/PaquetesVendedor.js'
import Catalogo from '../componentes/Catalogo/Catalogo.js'
import BlockedPage from '../componentes/BlockedSeccion.js'
import axios from 'axios';
import {Direccion} from '../strings/peticiones.js';
import {Route, Switch,} from 'react-router-dom'
import firebase from 'firebase'

class SellerSide extends Component{
  constructor(){
    super()
    this.state = {
      Status:true,
      openPaquetes:false,
      UserCatalogo:true,
    }

  }

  UNSAFE_componentWillMount=()=>{
    let perfil=[];

    var self=this;
    axios.post(Direccion+`/tomarUsuario`,
      {id:firebase.auth().currentUser.uid,tipo:'Vendedor'})
      .then(res => {
        perfil=res.data;
        if (perfil.Cuenta==="Free" && perfil.Cuenta===undefined && perfil.Cuenta==='none' ) {
          self.setState({
            Status:false,
            openPaquetes:true,
          })
        }
      })
      this.getSuscription();
  }


  getSuscription=()=>{
    var self=this;
    axios.post(Direccion+`/tomarSuscripcion`,
      {idUser:firebase.auth().currentUser.uid})
      .then(res => {
        if(res.data.suscripcionId !== 1 && res.data.suscripcionId !== 2){
          self.setState({
            UserCatalogo:false,
          })
        }
      })
  }
  render(){
    let carga ;
    let Rutacomponent = !this.state.UserCatalogo?RutasVendedor:BlockedPage;
    let Envioscomponent = !this.state.UserCatalogo?EnviosVendedor:BlockedPage;
    if (!this.state.Status) {

      carga = <PaquetesVendedor/>;
    }else {
      carga =<Switch>
                    <Route exact path="/seller" component={Analitics}/>
                    <Route path="/seller/Perfil/" component={PerfilVendedor}/>
                    <Route path="/seller/Pagos/" component={PagosVendedor}/>
                    <Route path="/seller/Rutas/" component={Rutacomponent}/>
                    <Route path="/seller/Envios/" component={Envioscomponent}/>
                    <Route path="/seller/Bookings/" component={Bookingscomponent}/>
                    <Route path="/seller/Bookings/:id" component={Bookingscomponent}/>
                    <Route path="/seller/Envios/:id" component={Envioscomponent}/>
                    <Route path="/seller/Paquetes/" component={PaquetesVendedor}/>
                    <Route path="/seller/Catalogo/:id" component={PerfilPublico}/>

                    <Route path="/seller/Catalogo" component={Catalogo}/>
                  </Switch>;
    }
    return(
      <div>
        {carga}
      </div>
    )
  }
}

export default SellerSide;

import React, { Component,useState, useEffect } from 'react';
import './Buscador.css';
import axios from 'axios';
import TipoEnvio from './VentanasParaCotizar/ventanaTipoEnvio.js';
import firebase from 'firebase'
import {Idioma} from '../../../strings/BuscarRutaText.js';
import {Direccion,DireccionFront} from '../../../strings/peticiones.js';
import TipoEnvioSinContenedor from './VentanasParaCotizar/ventanaTipoSinConte.js';
import TipoEnvioTerrestre from './VentanasParaCotizar/ventanaTipoTerrestre.js';
import TipoEnvioFerro from './VentanasParaCotizar/ventanaTipoFerro.js';
import AlertSnack from '../../notificacionCard.js';
import {Icon} from 'semantic-ui-react';
import Script from 'react-load-script';



export default function  Buscador (props){

  const [loading,setLoading] = useState(false);
  const [tipoTransporte,setTipoTransporte] = useState(0);
  const [OrigenSec,setOrigenSec] = useState('');
  const [DestinoSec,setDestinoSec] = useState('');
  const [objOrigen,setObjOrigen] = useState('');
  const [objDestino,setObjDestino] = useState('');
  const [cityOrigen,setCityOrigen] = useState(null);
  const [cityDestino,setCityDestino] = useState(null);
  const [queryOrigen,setQueryOrigen] = useState(null);
  const [queryDestino,setQueryDestino] = useState(null);
  const [rutasEncontradas,setRutasEncontradas] = useState([]);
  const [buscando,setBuscando] = useState(false);
  const [noEncontrado,setNoEncontrado] = useState(false);
  const [ObjBusqueda,setObjBusqueda] = useState(false);
  const [openModalDetalles,setOpenModalDetalles] = useState(false);

  const [openAlert,setOpenAlert] = useState(false);
  const [titleAlert,setTitleAlert] = useState('');
  const [AlertMessage,setAlertMessage] = useState('');
  const [AlertType,setAlertType] = useState('');

  useEffect(()=>{
    handleScriptLoad();
  },[])
  const handleScriptLoad=()=> {
     var options = {types: ['(cities)']};
     let Origen = new window.google.maps.places.Autocomplete(document.getElementById('autocompleteOrigen'),options );
     Origen.addListener('place_changed',handlePlaceOrigen);

     let Destino = new window.google.maps.places.Autocomplete(document.getElementById('autocompleteDestino'),options );
     Destino.addListener('place_changed',handlePlaceDestino);
     setObjOrigen(Origen)
     setObjDestino(Destino)
   }

  const handlePlaceOrigen=()=> {
    try{
      if (objOrigen ) {
        let DireccionOrigen = objOrigen.getPlace();
        let DirEspecificaOrigen = DireccionOrigen.address_components;
        let OrigenSec = document.getElementById('autocompleteOrigen').value;
        setOrigenSec(OrigenSec);
        setCityOrigen(DireccionOrigen.address_components);
        setQueryOrigen(BlockDirecciones(DirEspecificaOrigen));
      }
    }catch(e){
    }
  }

  const handlePlaceDestino=()=> {
      try{
        if (objDestino) {
          let DireccionDestino = objDestino.getPlace();
          let DirEspecificaDestino = DireccionDestino.address_components;
          let DestinoSec = document.getElementById('autocompleteDestino').value;

          setDestinoSec(DestinoSec);
          setCityDestino(DireccionDestino.address_components);
          setQueryDestino(BlockDirecciones(DirEspecificaDestino));
        }
      }catch(e){
      }
  }

  const BlockDirecciones=(ArrayAux)=>{
      let stringExt = "";
      for (var i = 0; i < ArrayAux.length; i++) {
        stringExt += ArrayAux[i].long_name+",";
      }
      return stringExt;
    }

  const BuscarRutas=()=>{
    setRutasEncontradas([]);
    setBuscando(false);
    setNoEncontrado(false);

    try{
      if (ValidarPeticion()) {
        let busqueda=ObjBusqueda;
        let cotizaciones=[];
        busqueda["Origen"] = OrigenSec;
        busqueda["Destino"] = DestinoSec;
        busqueda["Transporte"] = tipoTransporte === 0?'mar':tipoTransporte === 1?'Aereo':tipoTransporte === 2?'carretera':'ferrocaril';
         setBuscando(true);
        axios.post(Direccion+`/cotizacion`,{data:busqueda,type:tipoTransporte,OrigenBlock:cityOrigen,DestinoBlock:cityDestino,DestinoSec:DestinoSec,OrigenSec:OrigenSec })
        .then(res => {
          if (res.data) {
            if (res.data.length>0) {
              setRutasEncontradas(res.data);
              props.callBackRutasEncontradas(res.data);
              setBuscando(false);
              setNoEncontrado(false);
            }else{
              setBuscando(false);
              setNoEncontrado(true);
              setOpenAlert(true);
              setTitleAlert('Ups!');
              setAlertMessage('We not found rates');
              setAlertType('warning');
            }
          }
          else{
            setBuscando(false);
            setNoEncontrado(true);
            setOpenAlert(true);
            setTitleAlert('Ups!');
            setAlertMessage('We not found rates');
            setAlertType('warning');
          }
        })
      }

    }catch(e){
      console.log(e);
    }
  }

  const ValidarPeticion=()=>{
    if(!OrigenSec){
      setOpenAlert(true);
      setTitleAlert('Ups!');
      setAlertMessage('Select an origin');
      setAlertType('warning');
      return false;

    }
    else if(!DestinoSec){
      setOpenAlert(true);
      setTitleAlert('Ups!');
      setAlertMessage('Select a destination');
      setAlertType('warning');
      return false;

    }else{
      return true;

    }
  }
  const resetAlert = ()=> {
    setOpenAlert(false);
    setAlertMessage('');
    setAlertType('');
  };
  const callbackVentana=(value)=>{
      //aqui es llegada de los objetos de busqueda para peticion de el server
      //la condicional es porque recibe dos tipos de objetos
      //objeto con la descripcion y un objeto = {false}
      //este ultimo por si el usuario cierra la ventana sin llenar ni un campo o cancela
    if(value){
    var a = value;
    a["Origen"]= queryOrigen;
    a["Destino"]= queryDestino;
    a["Transporte"] = tipoTransporte === 0?'mar':tipoTransporte === 1?'Aereo':tipoTransporte === 2?'carretera':'ferrocaril';
    setOpenModalDetalles(false);
    setObjBusqueda(a);
    }
    else {
      setOpenModalDetalles(false);
    }
  }

  return(
    <div style={{width:'80%',margin:'0px auto'}}>
      <MenuTipoTransporte value={tipoTransporte} onChange={(value)=>setTipoTransporte(value)}/>
      <div className='ContentInputsBuscador'>
        <div className='InputsBuscador'>
          <div className='inputBuscador'>
            <input id='autocompleteOrigen' onChange={(e)=>setOrigenSec(e.target.value)} placeholder='Origin ...'/>
            <Icon name='marker'/>
          </div>
          <div className='SeparadorInput circulo'></div>
          <div className='inputBuscador'>
            <input id='autocompleteDestino' onChange={(e)=>setDestinoSec(e.target.value)} placeholder='Destination ...'/>
            <Icon name='marker'/>
          </div>
          <div className='SeparadorInput'></div>
          <div style={{cursor:'pointer'}} onClick={()=>setOpenModalDetalles(true)} className='inputBuscador'>
            <input style={{cursor:'pointer'}}  disabled placeholder='Cargo type ...' />
            <Icon name='boxes'/>
          </div>
          <div onClick={()=>BuscarRutas()} className='contentBtnSearch'>
            <div className='btnSearch'>
              <Icon style={{color:'#fff',position:'relative',right:'inherit',top:'inherit',transform: 'translateY(0%)',marginRight:'10px'}} loading={buscando} name={buscando?'spinner':'search'}/>
              {buscando?'':'Search'}
            </div>
          </div>
        </div>
      </div>
      <AlertSnack openAlert={openAlert} resetAlert={resetAlert} titleAlert={titleAlert}  AlertMessage={AlertMessage} AlertType={AlertType}/>
      <TipoEnvio callBackVentana={callbackVentana} open={openModalDetalles && tipoTransporte === 0?true:false}/>
      <TipoEnvioSinContenedor callBackVentana={callbackVentana} open={openModalDetalles && tipoTransporte === 1?true:false}/>
      <TipoEnvioTerrestre callBackVentana={callbackVentana} open={openModalDetalles && tipoTransporte === 2?true:false}/>
      <TipoEnvioFerro callBackVentana={callbackVentana} open={openModalDetalles && tipoTransporte === 3?true:false}/>
    </div>
  )
}

function MenuTipoTransporte (props){
  const [tipoTransporte,setTipoTransporte] = useState(0);
  useEffect(()=>{
    setTipoTransporte(props.value)
  },[props.value])
  return(
    <div className='contentIcon'>
      <div onClick={()=>props.onChange(0)} className={tipoTransporte === 0?'iconBuscador activo blue':'iconBuscador blue'}>
        {tipoTransporte === 0?
          <img src='../imagenes/icons/ocean_icon_2_white.svg' />
          :
          <img src='../imagenes/icons/ocean_icon_2.svg' />
        }
        <p className={tipoTransporte === 0?'textIconBuscador activo':'textIconBuscador azul'} >Ocean</p>
      </div>
      <div onClick={()=>props.onChange(1)} className={tipoTransporte === 1?'iconBuscador activo red':'iconBuscador red'}>
        {tipoTransporte === 1?
          <img src='../imagenes/icons/air_icon_2_white.svg' />
          :
          <img src='../imagenes/icons/air_icon_2.svg' />
        }
        <p className={tipoTransporte === 1?'textIconBuscador activo':'textIconBuscador red'} >Air</p>
      </div>
      <div onClick={()=>props.onChange(2)} className={tipoTransporte === 2?'iconBuscador activo green':'iconBuscador green'}>
        {tipoTransporte === 2?
          <img src='../imagenes/icons/carre_icon_2_white.svg' />
          :
          <img src='../imagenes/icons/carre_icon_2.svg' />
        }
        <p className={tipoTransporte === 2?'textIconBuscador activo':'textIconBuscador green'} >Truck</p>
      </div>
      <div onClick={()=>props.onChange(3)} className={tipoTransporte === 3?'iconBuscador activo grey':'iconBuscador grey'}>
        <img src='../imagenes/icons/ferro_icon_2.svg' />
        <p className={tipoTransporte === 3?'textIconBuscador activo':'textIconBuscador grey'} >F.F.C.C</p>
      </div>
    </div>
  )
}


const espanol={
  titulo:'Search rates',

};

export function Idioma(idioma){
  if(idioma==='ES'){
    return {textos:espanol};
  }
}

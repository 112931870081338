import React, { Component } from 'react';
import BarraLateral from './sideBar.js';
import axios from 'axios';
import {Router, Route, BrowserRouter, Switch, Link} from 'react-router-dom'
import Particles from 'react-particles-js';
import PagoTarjeta from '../newCard.js';
import Script from 'react-load-script';
import {ref,firebaseAuth} from '../../const.js';
import firebase from 'firebase'
import {Idioma} from '../../strings/perfilText.js';
import {Direccion} from '../../strings/peticiones.js';
import {SubirDato} from '../uploadData.js';
import { Divider, Header,Rating,Loader,List,Dimmer,Label,Input, Icon, Table, Card, Image,Button,Modal, Form,Message } from 'semantic-ui-react';
import './styleSeller/perfilVendedor.css';
import { FilePond } from 'react-filepond';
import AlertSnack from '../notificacionCard.js';
import GetCountry from '../scripts/getCountryString.js';



class PerfilVendedor extends Component{
  constructor(){
    super();
  }
  render(){
    return(

      <div>
      <BarraLateral/>
      <div className='profile-content'>
        <PerfilDetalle/>
      </div>
      </div>
    )
  }
}


class PerfilDetalle extends Component{
  constructor(){
    super()
      this.state = {
        file:null,
        open: false,
         openDos:false,
         openBancaria:false,
         perfil:[],
         mensaje:false,
         imagePreviewUrl:'https://react.semantic-ui.com/images/avatar/large/rachel.png',
         idiomas:'',
         Contry:'',
         descripcion:'',
         CompanyName:'',
         Website:'',
         Phone:'',
         Address:'',
         PostaleCode:'',
         City:'',
         State:'',
         FirstName:'',
         LastName:'',
         Mobil:'',
         Email:'',
         Title:'',
         Cuenta:'',
         idCliente:'',
         idSuscripcion:'',
         dimmerActive:false,
         Cards:[],
         Banks:{},
         Titular:'',
         Clabe:'',
         openVentana:'',
         Status:'',
         files:[],
         fileOK:false,
         LoaderStatus:0,
         statusBank:'',
         openAlert:false,
         AlertMessage:'',
         AlertType:'',
         actualizado:false,
        }
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
        this.loaderBank = React.createRef();
  }
  UNSAFE_componentWillMount(){

      this.getUser();
      this.GetCards();
      this.GetBanks();
      this.PeriodoPagar();
  }

  getUser =()=>{
    let perfil=[];
    var self=this;
    axios.post(Direccion+`/tomarUsuario`,
      {id:firebase.auth().currentUser.uid,tipo:'Vendedor'})
      .then(res => {
        perfil=res.data;
        if (res.data != '340') {
          let rate=0;
        if (perfil.Rate) {
           rate = parseInt(perfil.Rate.PromedioStar);
        }
        if(perfil.idiomas==='none'||perfil.Contry==='none'||perfil.descripcion==='none'){
          self.setState({open:true,mensaje:true,})
        }
          self.setState({
            perfil:perfil,
            avatar:perfil.avatar==='none'? 'https://firebasestorage.googleapis.com/v0/b/exportaciones-e2444.appspot.com/o/artDesign%2Fyourlogo.png?alt=media&token=e6b64658-0588-433f-add4-56e72504edfc':perfil.avatar,
            imagePreviewUrl:perfil.avatar==='none'? 'https://firebasestorage.googleapis.com/v0/b/exportaciones-e2444.appspot.com/o/artDesign%2Fyourlogo.png?alt=media&token=e6b64658-0588-433f-add4-56e72504edfc':perfil.avatar,
            idiomas:perfil.idiomas,
            descripcion:perfil.descripcion,
            CompanyName:perfil.CompanyName,
            Website:perfil.Website,
            Phone:perfil.Phone,
            Address:perfil.Address,
            PostaleCode:perfil.PostaleCode,
            City:perfil.City,
            State:perfil.State,
            Contry:perfil.Contry,
            FirstName:perfil.FirstName,
            LastName:perfil.LastName,
            Mobil:perfil.Mobil,
            correo:perfil.correo,
            Title:perfil.Title,
            Rate:rate,
            cityOrigen: [],
            queryOrigen: '',
            PaisOrigen:'',
          });

        }
      })
  }

  handleScriptLoad() {
   // Declare Options For Autocomplete
     var options = {
       types: ['(cities)'],
       };

   // Initialize Google Autocomplete
   /*global google*/

     let PaisOrigen = new google.maps.places.Autocomplete(document.getElementById('autocompletePais'),options );
     PaisOrigen.addListener('place_changed',this.handlePlaceSelect);

     this.setState({
       PaisOrigen:PaisOrigen
     });

   }

   handlePlaceSelect() {

     try{

      // Extract City From Address Object
      // Check if address is valid
      if (this.state.PaisOrigen ) {
        let DireccionOrigen = this.state.PaisOrigen.getPlace();
        let DirEspecificaOrigen = DireccionOrigen.address_components;
        this.setState(
          {
            cityOrigen: DireccionOrigen.address_components,
            queryOrigen: DireccionOrigen.formatted_address,
          }
        );
      }
      }catch(e){
        alert("selecciona un lugar del autocompletador");
      }

  }


  GetCards=()=>{
    var self=this;
    this.setState({
      openVentana:false,
    })
    axios.post(Direccion+`/tomarCards`,
      {idUser:firebase.auth().currentUser.uid,tipo:"Vendedor",})
      .then(res => {
        if (res.data.length !=0 && res.data.length >0) {
          self.setState({
            Cards:res.data,
            openVentana:false,

          })
        }
        else {
          self.setState({
            Cards:[],
            openVentana:false,

          })
        }
    })
  }

  GetBanks=()=>{
    var self=this;
    axios.post(Direccion+`/tomarBanks`,
      {idUser:firebase.auth().currentUser.uid,tipo:"Vendedor",})
      .then(res => {
        if (res.data) {
          self.setState({
            Banks:res.data,
            statusBank:res.data.Status
          })
        }
    })
  }
  NuevaBank=()=>{
    var self=this;
    self.setState({
      dimmerActive:true,
      openBancaria: false,
      actualizado:true,
    })


    axios.post(Direccion+`/NuevaBanks`,
      {idUser:firebase.auth().currentUser.uid,tipo:'Vendedor',PaisOrigen:self.state.queryOrigen,NombreTitular:self.state.NombreTitular,DireccionTitular:self.state.DireccionTitular,
        BancoName:self.state.Banco,DireccionBanco:self.state.DireccionBanco,CodigoSwift:self.state.CodigoSwift,Routing:self.state.Routing,Iban:self.state.Iban})
      .then(res => {
        if (res.data === 'OK') {
          self.setState({
            PaisOrigen:'',
            NombreTitular:'',
            DireccionTitular:'',
            Routing:'',
            Iban:'',
            Banco:'',
            DireccionBanco:'',
            CodigoSwift:'',
            dimmerActive:false,
            actualizado:false,
          })
          this.GetBanks();
        }else if(res.data === '340'){
              window.location.reload();
         }else {
          self.setState({
            dimmerActive:false,
            openBancaria: true,
          })
        }
    })
  }
  PeriodoPagar=()=>{
    var self =this;
      axios.post(Direccion+`/tomarSuscripcion`,{idUser:firebase.auth().currentUser.uid,})
        .then(res => {
          if (res.data != '404') {
            let dt = new Date(res.data.dateFin);
            self.setState({
              Cuenta: 'Premium '+res.data.suscripcionName,
              PeriodoPagar:dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + dt.getDate(),
              Status:res.data.status,
            });
          }
          else {
            self.setState({
              Cuenta:'none',
              PeriodoPagar:'00-00',
            });
          }
        }) // then
  } // funcion PeriodoPagar
  onChangePaísAccount=(e)=>{
        this.setState({
          PaísAccount:e.target.value
        })
  }

  nuevaImagen=(event)=>{
    let reader = new FileReader();
       let file = event.target.files[0];
        reader.onloadend = (e) => {
          this.setState({
            file: file,
            imagePreviewUrl: reader.result,
          });
        }
        reader.readAsDataURL(file);
  }


  actualizar=()=>{
    var self=this;

    self.setState({
      dimmerActive: true,
    });
    self.close2();
    self.close();
    if (this.state.file) {

      let avatar=SubirDato('usuarios/'+firebase.auth().currentUser.uid+'/avatar',firebase.storage(),this.state.file);
      avatar.then(function(done){
        axios.post(Direccion+'/actualizarUsuario',{
        id:firebase.auth().currentUser.uid,
        tipo:'Vendedor',
        Contry:self.state.Contry,
        idiomas:self.state.idiomas,
        descripcion:self.state.descripcion,
        CompanyName:self.state.CompanyName,
        Website:self.state.Website,
        Phone:self.state.Phone,
        Address:self.state.Address,
        PostaleCode:self.state.PostaleCode,
        City:self.state.City,
        State:self.state.State,
        FirstName:self.state.FirstName,
        LastName:self.state.LastName,
        Mobil:self.state.Mobil,
        correo:self.state.correo,
        Title:self.state.Title,
        avatar:done})
        .then(res=>{
          if(res.data==='OK'){
            self.setState({
              dimmerActive: false,
              openAlert:true,
              titleAlert:'Success',
               AlertMessage:'Information has been updated',
               AlertType:'success',
            });
            self.getUser();

          }
          else{
             self.setState({
              dimmerActive: false,
              openAlert:true,
              titleAlert:'Error',
              AlertMessage:'something went wrong',
              AlertType:'Error',
            });
          }
        })
      })
    }
    else {
      var self=this;

      axios.post(Direccion+'/actualizarUsuario',{id:firebase.auth().currentUser.uid,tipo:'Vendedor',
      Contry:self.state.Contry,
      idiomas:self.state.idiomas,
      descripcion:self.state.descripcion,
      CompanyName:self.state.CompanyName,
      Website:self.state.Website,
      Phone:self.state.Phone,
      Address:self.state.Address,
      PostaleCode:self.state.PostaleCode,
      City:self.state.City,
      State:self.state.State,
      FirstName:self.state.FirstName,
      LastName:self.state.LastName,
      Mobil:self.state.Mobil,
      correo:self.state.correo,
      Title:self.state.Title,})
      .then(res=>{
        if(res.data==='OK'){
         self.setState({
              dimmerActive: false,
              openAlert:true,
              titleAlert:'Success',
               AlertMessage:'Information has been updated',
               AlertType:'success',
            });

        }
        else{
          self.setState({
              dimmerActive: false,
               openAlert:true,
               titleAlert:'Error',
              AlertMessage:'something went wrong',
              AlertType:'Error',
            });

        }
      })

    }

  }

  confirmar=()=>{
    this.setState({
      openDos:true,
    })
  }

  cancelarSuscripcion=()=>{
    this.setState({
      openconfirm:true,
    })
  }

  handleBorrarSuscripcion=()=>{
    var self =this;
    axios.post(Direccion+`/detenerSuscripcion`,
    {idUser:firebase.auth().currentUser.uid,tipo:"Vendedor"})
    .then(res => {
      if (res.data === "OK") {
        this.setState({
           openAlert:true,
           titleAlert:'Success',
           AlertMessage:'your subscription has stopped',
           AlertType:'success',
        })
        setTimeout(function() {window.location.reload();}, 3000);

      }
    })
  }

  callbackVentana=()=>{
    this.setState({
      openVentana:false,
    })
  }
  NuevaCardModal=()=>{
    this.setState({
      openVentana:true,
    })
  }

  upLoadFile=()=>{
    var self =this;
      this.setState({
        LoaderStatus:1,
      })
      let FotoBancaria=SubirDato('usuarios/'+firebase.auth().currentUser.uid+'/FotoBancaria',firebase.storage(),this.state.files[0]);
      FotoBancaria.then(function(done){
        axios.post(Direccion+`/actualizarFotoBancaria`,{idUser:firebase.auth().currentUser.uid,tipo:'Vendedor',FotoBancaria:done})
          .then(res => {
            if (res.data === 'OK') {
             self.setState({
                LoaderStatus:2,
                fileOK:false,
                openAlert:true,
                titleAlert:'Success',
                 AlertMessage:'file uploaded successfully',
                 AlertType:'success',
              })
             self.GetBanks();
            }else if(res.data === '340'){
              self.setState({
                LoaderStatus:2,
                fileOK:false,
                openAlert:true,
                titleAlert:'Error',
                 AlertMessage:'something went wrong',
                 AlertType:'Error',
              })
            }

          })
      });

    }

   show = dimmer => () => this.setState({ dimmer, open: true })
   showBancaria = dimmer => () => this.setState({ dimmer, openBancaria: true })
   close = () => this.setState({ open: false,imagePreviewUrl:this.state.avatar })
   close2 = () => this.setState({ openDos: false })
   closeBancaria = () => this.setState({ openBancaria: false })

   closeconfirm = dimmerConfirm => this.setState({ openconfirm: false, dimmerConfirm })
   handleCloseAlert=()=>{this.setState({openAlert:false,})}
   resetAlert = ()=> this.setState({ openAlert:false,titleAlert: '', AlertMessage:'', AlertType:'',});
   handleKeyPress = (e) => {
     if (e.charCode === 32 || e.charCode === 13) {
       // Prevent the default action to stop scrolling when space is pressed
       e.preventDefault()
       this.actualizar();
     }
   }

  render(){
    let text=Idioma('ES');
    let rate = this.state.Rate;
    const { open, dimmer,openDos,openconfirm,openBancaria,dimmerDos,dimmerConfirm } = this.state;
    const {mensaje}=this.state;
    let pais = GetCountry(this.state.Contry);

    return(

      <div>
      <Dimmer page active={this.state.dimmerActive}>
       <Loader size='large'>Upload data</Loader>
       </Dimmer>
       <div style={{position:'relative',height:'30vh',marginBottom:'3vh'}}>
         <Particles style={{backgroundImage:'url(../imagenes/head7.jpg)', backgroundSize:'cover',backgroundPosition:'center', position:'absolute',height:'500px'}}
              params={{
                "particles": {
                    "number": {
                        "value": 40
                    },
                    "size": {
                        "value": 2
                    }
                },
                "interactivity": {
                    "events": {
                        "onhover": {
                            "enable": true,
                            "mode": "repulse"
                        }
                    }
                }
            }} />
       <div>
       <Divider horizontal style={{paddingTop:'14vh'}}>
         <Header as='h3' style={{position:'relative',color:'white'}}>
           <i class="material-icons" style={{ fontSize: '2em' }}>person</i>
            {text.titulo}
         </Header>
       </Divider>
       </div>
       </div>
      <AlertSnack openAlert={this.state.openAlert} titleAlert={this.state.titleAlert} resetAlert={this.resetAlert} AlertMessage={this.state.AlertMessage} AlertType={this.state.AlertType}/>

      <div className='header-perfil'>

        <Card.Group>

          <Card style={{width:'40%', minWidth:'250px', margin:' 1em 2.5em'}}>
            <Card.Content>
            <Card.Header>{text.card1.titulo}</Card.Header><br/>
              <Image id='imgPerfil' floated='right' size='small' style={{marginLeft: 'auto',marginRight: 'auto',textAlign: 'center'}}src={this.state.avatar} />

              <Card.Meta>{text.card1.CompanyName}</Card.Meta>
              <Card.Header>{this.state.CompanyName}</Card.Header><br/>
              <Card.Meta>{text.card1.email}</Card.Meta>
              <Card.Header>{this.state.correo}</Card.Header><br/>
              <Card.Meta>{text.card1.pais}</Card.Meta>
              <Card.Header>{pais}</Card.Header><br/>
              <Card.Meta>{text.card1.idiomas}</Card.Meta>
              <Card.Header>{this.state.idiomas}</Card.Header><br/>
              <Card.Description>
              {this.state.descripcion}
              </Card.Description>
              <div>
              {this.state.Rate?
                <div id='perfilRate'>
                <Card.Header>Ranking</Card.Header><br/>
                <div style={{display:'inline-flex'}}>
                <Rating size='huge' disabled icon='star' rating={rate} maxRating={5} />
                <Card.Meta>{parseFloat(this.state.perfil.Rate.PromedioStar).toFixed(1)}</Card.Meta>
                </div>
                <Divider/>
                <div style={{display:'grid'}}>
                <div style={{display:'inline-flex'}}>
                <Rating size='small' disabled icon='star' rating={5} maxRating={5} />
                <Card.Meta>: {this.state.perfil.Rate.fivestar}</Card.Meta>
                </div>
                <div style={{display:'inline-flex'}}>
                <Rating size='small' disabled icon='star' rating={4} maxRating={5} />
                <Card.Meta>: {this.state.perfil.Rate.fourstar}</Card.Meta>
                </div>
                <div style={{display:'inline-flex'}}>
                <Rating size='small' disabled icon='star' rating={3} maxRating={5} />
                <Card.Meta>: {this.state.perfil.Rate.threestar}</Card.Meta>
                </div>
                <div style={{display:'inline-flex'}}>
                <Rating size='small' disabled icon='star' rating={2} maxRating={5} />
                <Card.Meta>: {this.state.perfil.Rate.twostar}</Card.Meta>
                </div>
                <div style={{display:'inline-flex'}}>
                <Rating size='small' disabled icon='star' rating={1} maxRating={5} />
                <Card.Meta>: {this.state.perfil.Rate.onestar}</Card.Meta>
                </div>
                </div>

                </div>:
                <div>
                </div>

              }


              </div>
            </Card.Content>

            <Card.Content extra>
              <Button  style={{width:'100%',backgroundColor:'rgb(232, 133, 10)'}}secondary circular={true} onClick={this.show(true)}>{text.botones.modificar}</Button>
            </Card.Content>
          </Card>

            <Card style={{width:'40%', minWidth:'250px',margin:' 1em 2.5em'}}>
            <Card.Content>
              <Icon onClick={this.NuevaCardModal} circular style={{position:'absolute', right:'3%'}} name='add' size='large'/>
              <Card.Header>Active Credit / Debit Cards</Card.Header>
              <br/>

                {  this.state.Cards.map((it,index,key)=>{
                  return(<ListaCard  idioma={text} index={index} GetCardsCallback={this.GetCards} cliente={this.state.id}  datos={it}/>)
                  })
                }

            </Card.Content>
            <br/>
            <Card.Content>
              <Card.Header>Banking Information</Card.Header>
              <Label as='a' color={this.state.statusBank ==='Pending'?'yellow':this.state.statusBank ==='Rejected'?'red' :'teal'} ribbon='right'>
                {this.state.statusBank ==='Rejected'?'Rejected, be sure to upload bank voucher':this.state.statusBank}
              </Label>

              <br/>

              {!this.state.Banks.FotoCuenta?
                <div className='contentFotoCuentaRequierd'>
                  <Label as='a' color='yellow' ribbon='right'>
                   Bank voucher is required
                  </Label>
                </div>:''
              }
              {this.state.Banks.Status='Activada'?
                <div>
                  <ul className="col3" id="rowBank">
                    <li>
                      <Card.Meta>Account Beneficiary</Card.Meta>
                      <Card.Description>{this.state.Banks.NombreTitular}</Card.Description>
                    </li>
                    <li>
                      <Card.Meta>Country of Origin</Card.Meta>
                      <Card.Description>{this.state.Banks.PaisOrigen}</Card.Description>
                    </li>
                    <li>
                      <Card.Meta>Address of Beneficiary</Card.Meta>
                      <Card.Description>{this.state.Banks.DireccionTitular}</Card.Description>
                    </li>
                    <div className="clear"></div>
                  </ul>

                  <ul className="col3" id="rowBank">
                    <li>
                      <Card.Meta>SWIFT code</Card.Meta>
                      <Card.Description>{this.state.Banks.CodigoSwift}</Card.Description>
                    </li>
                    <li>
                      <Card.Meta>Iban code</Card.Meta>
                      <Card.Description>{this.state.Banks.Iban}</Card.Description>
                    </li>
                    <li>
                      <Card.Meta>Routing code</Card.Meta>
                      <Card.Description>{this.state.Banks.Routing}</Card.Description>
                    </li>
                    <div className="clear"></div>
                  </ul>
                  <ul className="col3" id="rowBank">
                    <li>
                      <Card.Meta>Bank </Card.Meta>
                      <Card.Description>{this.state.Banks.BancoName}</Card.Description>
                    </li>
                    <li>
                    <Card.Meta>Address of Bank</Card.Meta>
                    <Card.Description>{this.state.Banks.DireccionBanco}</Card.Description>
                    </li>
                    <div className="clear"></div>
                  </ul>
                </div>:
                <div>
                 <Card.Meta>Your bank account is not active yet</Card.Meta>
                  <Card.Description>We are checking that everything is fine with your bank account, it will be activated shortly</Card.Description>
                </div>

              }

            </Card.Content>
            <div className='filepond--contenido'>

              {this.state.Banks.FotoCuenta?
                <div></div>:
                <FilePond ref={ref => this.pond = ref}
                 files={this.state.files}
                 allowMultiple={true}
                 maxFiles={1}
                 onaddfile={() => {
                     // Set current file objects to this.state
                     this.setState({
                         fileOK: true,
                     });
                 }}
                 labelIdle="Add files"
                 onupdatefiles={(fileItems) => {
                     // Set current file objects to this.state
                     this.setState({
                         files: fileItems.map(fileItem => fileItem.file)
                     });
                 }}>
               </FilePond>
              }

               {this.state.fileOK?
                <div className='btnUploadContent' onClick={this.upLoadFile}>
                    <div  className={this.state.LoaderStatus===0?'loaderBank':this.state.LoaderStatus===1?'loaderBankSpiner':'loaderBankActive'} ref={this.loaderBank}></div>
                    {this.state.LoaderStatus===2?
                      <div className='iconDone'>
                        <i class="material-icons" style={{ fontSize: '2em', color:'#21ba45' }}>done_outline</i>
                      </div>
                      :
                       <div>
                        <i class="material-icons" style={{ fontSize: '2em' }}>cloud_upload</i><p>Click to Upload file</p>
                      </div>
                    }



                </div>
                :
                <div></div>
              }
            </div>
            <Card.Content extra >

              <Button  style={{width:'100%',backgroundColor:'rgb(232, 133, 10)'}}secondary circular={true} onClick={this.showBancaria(true)}>{text.botones.modificar}</Button>
            </Card.Content>
          </Card>

        {this.state.Cuenta != "Free"?
          <div>
          <Card style={{width:'45%',textAlign:'center', minWidth:'300px',margin:' 1em 2.5em'}}>
          <Card.Content>
            <Card.Header >My plan</Card.Header>
            <Card.Meta >{this.state.Cuenta}</Card.Meta>
            <hr />
            <br/>
              <Card.Description>
              Currently your plan is <strong> {this.state.Cuenta}.</strong>
              <br/>
              </Card.Description>
              <Card.Description>
            <strong> Expiration date : {this.state.PeriodoPagar}</strong>
              </Card.Description>
          </Card.Content>

          <Card.Content extra >

            <Button onClick={this.cancelarSuscripcion} style={{width:'45%',float:'left'}}primary disabled={this.state.Status != 'activo' && this.state.Status} circular={true}>Cancel </Button>
            <Link to="/seller/Paquetes">

              <Button  style={{width:'47%',backgroundColor:'rgb(232, 133, 10)'}}secondary circular={true}>Upgrade</Button>
            </Link>
          </Card.Content>
          </Card>
          </div>:
          <div>

                    <Card style={{width:'40%', minWidth:'250px',margin:' 1em 2.5em'}}>
                    <Card.Content>
                      <Card.Header style={{marginLeft:'35%'}}>My plan</Card.Header>
                      <Card.Meta style={{marginLeft:'43%'}}>Free</Card.Meta>
                      <hr />
                      <br/>
                        <Card.Description>
                        You currently have a Free plan. With free plan,
                        You can only upload 3 routes and you can view all public routes
                        </Card.Description>
                    </Card.Content>

                    <Card.Content extra >
                    <Link to="/seller/Paquetes">

                      <Button  style={{width:'100%'}}secondary circular={true}>Suscribirse</Button>
                    </Link>
                    </Card.Content>
                  </Card>
          </div>
        }

        </Card.Group>


        <div>


        <Modal  size='small' dimmer={dimmerDos} open={openDos} onClose={this.close2}>
          <Header icon='archive' content={text.modalConfirmar.encabezado} />
          <Modal.Content>
            <p>
            {text.modalConfirmar.mensajeConfirmacion}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button  color='red' inverted onClick={this.close2}>
              <Icon name='remove' /> {text.botones.cancelar}
            </Button>
            <Button color='green' inverted  onClick={this.actualizar}>
              <Icon name='checkmark' /> {text.botones.acpetar}
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal  size='small' dimmer={dimmerConfirm} open={openconfirm} onClose={this.closeconfirm}>
          <Header icon='archive' content={text.cancelarSuscripcion.encabezado} />
          <Modal.Content>
            <p>
            {text.cancelarSuscripcion.mensajeConfirmacion}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button  color='red' inverted onClick={this.closeconfirm}>
              <Icon name='remove' /> {text.botones.cancelar}
            </Button>
            <Button color='green' inverted  onClick={this.handleBorrarSuscripcion}>
              <Icon name='checkmark' /> {text.botones.acpetar}
            </Button>
          </Modal.Actions>
        </Modal>


        <Modal dimmer={dimmer} open={open} onClose={this.close}>
        <Message visible={mensaje} info>
            <Message.Header>{text.mensajeDatos.header}</Message.Header>
            <p>{text.mensajeDatos.descripcion}</p>
        </Message>
          <Modal.Header>{text.card1.titulo}</Modal.Header>
          <Modal.Content image>

          <div style={{position:'absolute'}}>
          <Image id='imgPerfilModal'  circular wrapped size='small' src={this.state.imagePreviewUrl}></Image>
          <input type='file'style={{fontSize:'13px',width:'70%'}} onChange={this.nuevaImagen.bind(this)}/>
          </div>

          <div style={{position:'absolute',top:'45%'}}>
          {this.state.perfil.Rate?
            <div id='perfilRateModal'>
              <Card.Header>Ranking</Card.Header><br/>
              <div style={{display:'inline-flex'}}>
                <Rating size='huge' disabled icon='star' rating={rate} maxRating={5} />
                <Card.Meta>{parseFloat(this.state.perfil.Rate.PromedioStar).toFixed(1)}</Card.Meta>
              </div>
              <Divider/>
              <div style={{display:'grid'}}>
                <div style={{display:'inline-flex'}}>
                  <Rating size='small' disabled icon='star' rating={5} maxRating={5} />
                  <Card.Meta>: {this.state.perfil.Rate.fivestar}</Card.Meta>
                </div>
                <div style={{display:'inline-flex'}}>
                  <Rating size='small' disabled icon='star' rating={4} maxRating={5} />
                  <Card.Meta>: {this.state.perfil.Rate.fourstar}</Card.Meta>
                </div>
                <div style={{display:'inline-flex'}}>
                  <Rating size='small' disabled icon='star' rating={3} maxRating={5} />
                  <Card.Meta>: {this.state.perfil.Rate.threestar}</Card.Meta>
                </div>
                <div style={{display:'inline-flex'}}>
                  <Rating size='small' disabled icon='star' rating={2} maxRating={5} />
                  <Card.Meta>: {this.state.perfil.Rate.twostar}</Card.Meta>
                </div>
                <div style={{display:'inline-flex'}}>
                  <Rating size='small' disabled icon='star' rating={1} maxRating={5} />
                  <Card.Meta>: {this.state.perfil.Rate.onestar}</Card.Meta>
                </div>
              </div>
            </div>:
            <div>
            </div>
          }
          </div>
            <Form style={{marginLeft:'30%', textAlign:'center'}}>
            <h3>Company info</h3>

              <Form.Group >
                <Form.Input fluid label={text.card1.CompanyName}  value={this.state.CompanyName} onChange={(e)=>this.setState({CompanyName:e.target.value})} readonly/>
                <Form.Input fluid label={text.card1.Website}  value={this.state.Website} onChange={(e)=>this.setState({Website:e.target.value})}  readonly/>

              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Input fluid label={text.card1.Phone}  value={this.state.Phone}  onChange={(e)=>this.setState({Phone:e.target.value})}/>
                <Form.Input fluid label={text.card1.Address}  value={this.state.Address} onChange={(e)=>this.setState({Address:e.target.value})} />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Input fluid label={text.card1.PostaleCode}  value={this.state.PostaleCode} onChange={(e)=>this.setState({PostaleCode:e.target.value})}/>
                <Form.Input fluid label={text.card1.City}  value={this.state.City} onChange={(e)=>this.setState({City:e.target.value})} />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Input fluid label={text.card1.State}  value={this.state.State} onChange={(e)=>this.setState({State:e.target.value})}/>
                <div className='field'>
                  <label>Country</label>
                  <div className='ui fluid input'>
                    <select value={this.state.Contry} onChange={(e)=>this.setState({Contry:e.target.value})} className='selectCountry'>
                      <option value="AF">Afghanistan</option>
                      <option value="AX">Åland Islands</option>
                      <option value="AL">Albania</option>
                      <option value="DZ">Algeria</option>
                      <option value="AS">American Samoa</option>
                      <option value="AD">Andorra</option>
                      <option value="AO">Angola</option>
                      <option value="AI">Anguilla</option>
                      <option value="AQ">Antarctica</option>
                      <option value="AG">Antigua and Barbuda</option>
                      <option value="AR">Argentina</option>
                      <option value="AM">Armenia</option>
                      <option value="AW">Aruba</option>
                      <option value="AU">Australia</option>
                      <option value="AT">Austria</option>
                      <option value="AZ">Azerbaijan</option>
                      <option value="BS">Bahamas</option>
                      <option value="BH">Bahrain</option>
                      <option value="BD">Bangladesh</option>
                      <option value="BB">Barbados</option>
                      <option value="BY">Belarus</option>
                      <option value="BE">Belgium</option>
                      <option value="BZ">Belize</option>
                      <option value="BJ">Benin</option>
                      <option value="BM">Bermuda</option>
                      <option value="BT">Bhutan</option>
                      <option value="BO">Bolivia, Plurinational State of</option>
                      <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                      <option value="BA">Bosnia and Herzegovina</option>
                      <option value="BW">Botswana</option>
                      <option value="BV">Bouvet Island</option>
                      <option value="BR">Brazil</option>
                      <option value="IO">British Indian Ocean Territory</option>
                      <option value="BN">Brunei Darussalam</option>
                      <option value="BG">Bulgaria</option>
                      <option value="BF">Burkina Faso</option>
                      <option value="BI">Burundi</option>
                      <option value="KH">Cambodia</option>
                      <option value="CM">Cameroon</option>
                      <option value="CA">Canada</option>
                      <option value="CV">Cape Verde</option>
                      <option value="KY">Cayman Islands</option>
                      <option value="CF">Central African Republic</option>
                      <option value="TD">Chad</option>
                      <option value="CL">Chile</option>
                      <option value="CN">China</option>
                      <option value="CX">Christmas Island</option>
                      <option value="CC">Cocos (Keeling) Islands</option>
                      <option value="CO">Colombia</option>
                      <option value="KM">Comoros</option>
                      <option value="CG">Congo</option>
                      <option value="CD">Congo, the Democratic Republic of the</option>
                      <option value="CK">Cook Islands</option>
                      <option value="CR">Costa Rica</option>
                      <option value="CI">Côte d'Ivoire</option>
                      <option value="HR">Croatia</option>
                      <option value="CU">Cuba</option>
                      <option value="CW">Curaçao</option>
                      <option value="CY">Cyprus</option>
                      <option value="CZ">Czech Republic</option>
                      <option value="DK">Denmark</option>
                      <option value="DJ">Djibouti</option>
                      <option value="DM">Dominica</option>
                      <option value="DO">Dominican Republic</option>
                      <option value="EC">Ecuador</option>
                      <option value="EG">Egypt</option>
                      <option value="SV">El Salvador</option>
                      <option value="GQ">Equatorial Guinea</option>
                      <option value="ER">Eritrea</option>
                      <option value="EE">Estonia</option>
                      <option value="ET">Ethiopia</option>
                      <option value="FK">Falkland Islands (Malvinas)</option>
                      <option value="FO">Faroe Islands</option>
                      <option value="FJ">Fiji</option>
                      <option value="FI">Finland</option>
                      <option value="FR">France</option>
                      <option value="GF">French Guiana</option>
                      <option value="PF">French Polynesia</option>
                      <option value="TF">French Southern Territories</option>
                      <option value="GA">Gabon</option>
                      <option value="GM">Gambia</option>
                      <option value="GE">Georgia</option>
                      <option value="DE">Germany</option>
                      <option value="GH">Ghana</option>
                      <option value="GI">Gibraltar</option>
                      <option value="GR">Greece</option>
                      <option value="GL">Greenland</option>
                      <option value="GD">Grenada</option>
                      <option value="GP">Guadeloupe</option>
                      <option value="GU">Guam</option>
                      <option value="GT">Guatemala</option>
                      <option value="GG">Guernsey</option>
                      <option value="GN">Guinea</option>
                      <option value="GW">Guinea-Bissau</option>
                      <option value="GY">Guyana</option>
                      <option value="HT">Haiti</option>
                      <option value="HM">Heard Island and McDonald Islands</option>
                      <option value="VA">Holy See (Vatican City State)</option>
                      <option value="HN">Honduras</option>
                      <option value="HK">Hong Kong</option>
                      <option value="HU">Hungary</option>
                      <option value="IS">Iceland</option>
                      <option value="IN">India</option>
                      <option value="ID">Indonesia</option>
                      <option value="IR">Iran, Islamic Republic of</option>
                      <option value="IQ">Iraq</option>
                      <option value="IE">Ireland</option>
                      <option value="IM">Isle of Man</option>
                      <option value="IL">Israel</option>
                      <option value="IT">Italy</option>
                      <option value="JM">Jamaica</option>
                      <option value="JP">Japan</option>
                      <option value="JE">Jersey</option>
                      <option value="JO">Jordan</option>
                      <option value="KZ">Kazakhstan</option>
                      <option value="KE">Kenya</option>
                      <option value="KI">Kiribati</option>
                      <option value="KP">Korea, Democratic People's Republic of</option>
                      <option value="KR">Korea, Republic of</option>
                      <option value="KW">Kuwait</option>
                      <option value="KG">Kyrgyzstan</option>
                      <option value="LA">Lao People's Democratic Republic</option>
                      <option value="LV">Latvia</option>
                      <option value="LB">Lebanon</option>
                      <option value="LS">Lesotho</option>
                      <option value="LR">Liberia</option>
                      <option value="LY">Libya</option>
                      <option value="LI">Liechtenstein</option>
                      <option value="LT">Lithuania</option>
                      <option value="LU">Luxembourg</option>
                      <option value="MO">Macao</option>
                      <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                      <option value="MG">Madagascar</option>
                      <option value="MW">Malawi</option>
                      <option value="MY">Malaysia</option>
                      <option value="MV">Maldives</option>
                      <option value="ML">Mali</option>
                      <option value="MT">Malta</option>
                      <option value="MH">Marshall Islands</option>
                      <option value="MQ">Martinique</option>
                      <option value="MR">Mauritania</option>
                      <option value="MU">Mauritius</option>
                      <option value="YT">Mayotte</option>
                      <option value="MX">Mexico</option>
                      <option value="FM">Micronesia, Federated States of</option>
                      <option value="MD">Moldova, Republic of</option>
                      <option value="MC">Monaco</option>
                      <option value="MN">Mongolia</option>
                      <option value="ME">Montenegro</option>
                      <option value="MS">Montserrat</option>
                      <option value="MA">Morocco</option>
                      <option value="MZ">Mozambique</option>
                      <option value="MM">Myanmar</option>
                      <option value="NA">Namibia</option>
                      <option value="NR">Nauru</option>
                      <option value="NP">Nepal</option>
                      <option value="NL">Netherlands</option>
                      <option value="NC">New Caledonia</option>
                      <option value="NZ">New Zealand</option>
                      <option value="NI">Nicaragua</option>
                      <option value="NE">Niger</option>
                      <option value="NG">Nigeria</option>
                      <option value="NU">Niue</option>
                      <option value="NF">Norfolk Island</option>
                      <option value="MP">Northern Mariana Islands</option>
                      <option value="NO">Norway</option>
                      <option value="OM">Oman</option>
                      <option value="PK">Pakistan</option>
                      <option value="PW">Palau</option>
                      <option value="PS">Palestinian Territory, Occupied</option>
                      <option value="PA">Panama</option>
                      <option value="PG">Papua New Guinea</option>
                      <option value="PY">Paraguay</option>
                      <option value="PE">Peru</option>
                      <option value="PH">Philippines</option>
                      <option value="PN">Pitcairn</option>
                      <option value="PL">Poland</option>
                      <option value="PT">Portugal</option>
                      <option value="PR">Puerto Rico</option>
                      <option value="QA">Qatar</option>
                      <option value="RE">Réunion</option>
                      <option value="RO">Romania</option>
                      <option value="RU">Russian Federation</option>
                      <option value="RW">Rwanda</option>
                      <option value="BL">Saint Barthélemy</option>
                      <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                      <option value="KN">Saint Kitts and Nevis</option>
                      <option value="LC">Saint Lucia</option>
                      <option value="MF">Saint Martin (French part)</option>
                      <option value="PM">Saint Pierre and Miquelon</option>
                      <option value="VC">Saint Vincent and the Grenadines</option>
                      <option value="WS">Samoa</option>
                      <option value="SM">San Marino</option>
                      <option value="ST">Sao Tome and Principe</option>
                      <option value="SA">Saudi Arabia</option>
                      <option value="SN">Senegal</option>
                      <option value="RS">Serbia</option>
                      <option value="SC">Seychelles</option>
                      <option value="SL">Sierra Leone</option>
                      <option value="SG">Singapore</option>
                      <option value="SX">Sint Maarten (Dutch part)</option>
                      <option value="SK">Slovakia</option>
                      <option value="SI">Slovenia</option>
                      <option value="SB">Solomon Islands</option>
                      <option value="SO">Somalia</option>
                      <option value="ZA">South Africa</option>
                      <option value="GS">South Georgia and the South Sandwich Islands</option>
                      <option value="SS">South Sudan</option>
                      <option value="ES">Spain</option>
                      <option value="LK">Sri Lanka</option>
                      <option value="SD">Sudan</option>
                      <option value="SR">Suriname</option>
                      <option value="SJ">Svalbard and Jan Mayen</option>
                      <option value="SZ">Swaziland</option>
                      <option value="SE">Sweden</option>
                      <option value="CH">Switzerland</option>
                      <option value="SY">Syrian Arab Republic</option>
                      <option value="TW">Taiwan, Province of China</option>
                      <option value="TJ">Tajikistan</option>
                      <option value="TZ">Tanzania, United Republic of</option>
                      <option value="TH">Thailand</option>
                      <option value="TL">Timor-Leste</option>
                      <option value="TG">Togo</option>
                      <option value="TK">Tokelau</option>
                      <option value="TO">Tonga</option>
                      <option value="TT">Trinidad and Tobago</option>
                      <option value="TN">Tunisia</option>
                      <option value="TR">Turkey</option>
                      <option value="TM">Turkmenistan</option>
                      <option value="TC">Turks and Caicos Islands</option>
                      <option value="TV">Tuvalu</option>
                      <option value="UG">Uganda</option>
                      <option value="UA">Ukraine</option>
                      <option value="AE">United Arab Emirates</option>
                      <option value="GB">United Kingdom</option>
                      <option value="US">United States</option>
                      <option value="UM">United States Minor Outlying Islands</option>
                      <option value="UY">Uruguay</option>
                      <option value="UZ">Uzbekistan</option>
                      <option value="VU">Vanuatu</option>
                      <option value="VE">Venezuela, Bolivarian Republic of</option>
                      <option value="VN">Viet Nam</option>
                      <option value="VG">Virgin Islands, British</option>
                      <option value="VI">Virgin Islands, U.S.</option>
                      <option value="WF">Wallis and Futuna</option>
                      <option value="EH">Western Sahara</option>
                      <option value="YE">Yemen</option>
                      <option value="ZM">Zambia</option>
                      <option value="ZW">Zimbabwe</option>
                    </select>
                  </div>
                </div>
              </Form.Group>
              <Form.Group widths='equal'>
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Input fluid label={text.card1.FirstName}  value={this.state.FirstName} onChange={(e)=>this.setState({FirstName:e.target.value})}/>
                <Form.Input fluid label={text.card1.LastName}  value={this.state.LastName} onChange={(e)=>this.setState({LastName:e.target.value})} />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Input fluid label={text.card1.Mobil}  value={this.state.Mobil} onChange={(e)=>this.setState({Mobil:e.target.value})}/>
                <Form.Input  disabled fluid label={text.card1.Email}  value={this.state.correo} onChange={(e)=>this.setState({correo:e.target.value})} />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Input fluid label={text.card1.idiomas}  value={this.state.idiomas} onChange={(e)=>this.setState({idiomas:e.target.value})} />
                <Form.Input fluid label={text.card1.Title}  value={this.state.Title} onChange={(e)=>this.setState({Title:e.target.value})}/>
              </Form.Group>
              <Form.TextArea label={text.card1.descripcion} value={this.state.descripcion} onChange={(e)=>this.setState({descripcion:e.target.value})}/>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={this.close}>
              {text.botones.cancelar}
            </Button>
            <Button
              positive
              focus
              icon='checkmark'
              labelPosition='right'
              content={text.botones.aceptar}
              onClick={this.confirmar}
              onKeyPress={this.handleKeyPress}
            />
          </Modal.Actions>
        </Modal>



        <Modal id='ModalBank' dimmer={dimmer} open={openBancaria} onClose={this.closeBancaria}>
        <Modal.Header>"New Bank account"</Modal.Header>
          <Modal.Content >
           <ul className="col2">
                <li >
                  <label>City/State/Contry</label>
                  <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyCnvzp9aB0tY2tM1YTyACl95z1y3xjL-_4&libraries=places" onLoad={this.handleScriptLoad}/>
                  <Input  icon='map marker alternate' autocomplete="false" id="autocompletePais"   />
                    <p className='selecLabelAutocomplete' >select an autofill value</p>

                </li>
                <li>
                <Form.Input  label={"Name account holder"}  value={this.state.NombreTitular} onChange={(e)=>this.setState({NombreTitular:e.target.value})}  readonly/>
                </li>
                <li>
                  <Form.Input  label={"Holder's address"}  value={this.state.DireccionTitular} onChange={(e)=>this.setState({DireccionTitular:e.target.value})}  readonly/>
                </li>
                <li>
                  <Form.Input  label={"Name of the bank"}  value={this.state.Banco} onChange={(e)=>this.setState({Banco:e.target.value})}  readonly/>
                </li>
                <li>
                  <Form.Input  label={"Bank address"}  value={this.state.DireccionBanco} onChange={(e)=>this.setState({DireccionBanco:e.target.value})}  readonly/>
                </li>
                <li>
                  <Form.Input  label={"SWIFT"}  value={this.state.CodigoSwift} onChange={(e)=>this.setState({CodigoSwift:e.target.value})}  readonly/>
                </li>
                <li>
                  <Form.Input  label={"Routing in case of US Account"}  value={this.state.Routing} onChange={(e)=>this.setState({  Routing:e.target.value})}  readonly/>
                </li>
                <li>
                  <Form.Input  label={"IBAN"}  value={this.state.Iban} onChange={(e)=>this.setState({  Iban:e.target.value})}  readonly/>
                </li>
                <div className="clear"></div>
             </ul>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={this.close}>
              {text.botones.cancelar}
            </Button>
            <Button
              positive
              icon='checkmark'
              disabled={(!this.state.queryOrigen
                ||!this.state.NombreTitular||!this.state.DireccionTitular
                ||!this.state.Routing||!this.state.Iban||!this.state.Banco
                ||!this.state.DireccionBanco||!this.state.CodigoSwift) || this.state.actualizado}
              loading={this.state.actualizado}
              labelPosition='right'
              content={text.botones.aceptar}
              onClick={this.NuevaBank}
            />
          </Modal.Actions>
        </Modal>

          <PagoTarjeta  tipo={"Vendedor"} callbackGetCards={this.GetCards} callBackVentana={this.callbackVentana} open={this.state.openVentana}/>
      </div>
      </div>
      </div>
    )
  }
}

class ListaCard extends Component{
  constructor(){
    super()
    this.state = {
    datos:[],
    dimmerActive:false,
    openAlert:false,
    titleAlert:'',
    AlertMessage:'',
    AlertType:'',
    }
  }


  selected=()=>{
    this.setState({
      dimmerActive:true,
    });
      var self =this;
      axios.post(Direccion+`/EliminarCards`,
        {idUser:firebase.auth().currentUser.uid, tipo:"Vendedor", IdCard:this.props.datos.key})
        .then(res => {
          if (res.data === "OK") {
            this.setState({
              dimmerActive:false,
              openAlert:true,
              titleAlert:'Success',
              AlertMessage:'card removed',
              AlertType:'success',
            });
            setTimeout(function() {self.returnCallback();}, 2000);

          }else if(res.data === "340"){
            this.setState({
              dimmerActive:false,
              openAlert:true,
              titleAlert:'Error',
              AlertMessage:'something went wrong',
              AlertType:'error',
            });
          }

        })

  }
  returnCallback=()=>{
    this.props.GetCardsCallback();
  }
   handleCloseAlert=()=>{
      this.setState({
        openAlert:false,
      })
    }

  render(){
    return(

      <div>
      <Dimmer page active={this.state.dimmerActive}>
       <Loader size='large'>Loading...</Loader>
       </Dimmer>
      <List  id="listaCards" animated verticalAlign='middle'>

        <List.Item onClick={this.selected} id="ItemCards">

        {this.props.datos.displayNumber.substring(0, 1) === '4'?
          <div>
          <Image  size='tiny' src='../imagenes/visa.png' />
          </div>:
          <div>
          <Image size='tiny' src={'../imagenes/Mastercard.png'}  />
          </div>
        }
          <List.Content id='ContentCards'>
            <List.Header>{this.props.datos.displayNumber}</List.Header>
            <List.Description>{this.props.datos.HolderCuenta}</List.Description>
          </List.Content>
        </List.Item>
      </List>
      <AlertSnack open={this.state.openAlert} handleClose={this.handleCloseAlert} message={this.state.AlertMessage} type={this.state.AlertType}/>

      </div>
    )
  }
}






export default PerfilVendedor;

import React, { Component, useState,useEffect } from 'react';
import {Dropdown,Checkbox,Icon,Pagination} from 'semantic-ui-react';
import Script from 'react-load-script';
import Alert from '../../notificacionCard.js';
import ValidarItemsArchivo from '../../scripts/ValidarItemArchivo.js';
import XLSX from 'xlsx';
import axios from 'axios';
import {Direccion} from '../../../strings/peticiones.js';
import firebase from 'firebase'


export default class RutasPorSlot extends Component{
  constructor(props) {
    super(props)
    this.state={
      ArrayRuta:[],
      currentPageActivas:1,
      PerPageActivas:2,
      currentTodosActivas:[],
      renderCompanyActivas:null,
      pageNumbersActivas:0,
      openAlert:false,
      titleAlert: '',
      AlertMessage:'',
      AlertType:'',
    }
  }
  componentDidMount(){
    this.RenderItemsTable();
  }
  RenderItemsTable=()=>{
    let rutas = this.OrganizarRuta(this.props.ruta);
    const indexOfLastTodoActivas = this.state.currentPageActivas * this.state.PerPageActivas;
    const indexOfFirstTodoActivas = indexOfLastTodoActivas - this.state.PerPageActivas;
    const currentTodosActivas = rutas.slice(indexOfFirstTodoActivas, indexOfLastTodoActivas);
    const renderCompanyActivas = currentTodosActivas.map((it,key)=>{
     return(<Item key={key}  it={it} />)
    })
    this.setState({
       pageNumbersActivas:Math.ceil(rutas.length / this.state.PerPageActivas),
       renderCompanyActivas:renderCompanyActivas,
    })
  }

  OrganizarRuta=(ruta)=>{
    let ArrayRuta = [];
    for (let n in ruta) {
      if (n != 'tipoRuta' && n != 'SlotData') {
        ArrayRuta.push(ruta[n])
      }
    }
    return ArrayRuta;
  }
  handlePaginationChangeActivo=(e,index)=>{
    this.setState({currentPageActivas:index.activePage}, () => {
      this.RenderItemsTable();
    })
  }
  deleteSlot=()=>{
    axios.post(Direccion+`/borrar-ruta`,{type:'Wagon',idUser:firebase.auth().currentUser.uid,IdSlot:this.props.ruta.SlotData.IdSlot})
      .then(res => {
        if (res.data.status === 'OK') {
          this.props.regresarBack();
        }
      })
  }
  resetAlert = ()=> this.setState({ openAlert:false,titleAlert: '', AlertMessage:'', AlertType:'',});

  render(){
    return(
      <div className='TableContent'>
        <Alert openAlert={this.state.openAlert} titleAlert={this.state.titleAlert} resetAlert={this.resetAlert} AlertMessage={this.state.AlertMessage} AlertType={this.state.AlertType}/>
        <div className='actionsBtnsContent'>
           <div className='actionsBtns right'>
             <div className='btn pending' onClick={()=>this.props.regresarBack()}>
               <Icon name='arrow left'/>
             </div>
             <div onClick={this.deleteSlot} className='btn negative'>Delete slot</div>
           </div>
         </div>

        <table className='FormContent Wagon'>
          <thead>
            <tr className='Header'>
              <th className='headerItem'><p className='tituloInput'>Origin Country</p></th>
              <th className='headerItem'><p className='tituloInput'>Destination Country</p></th>
              <th className='headerItem'><p className='tituloInput'>{this.props.text.RutaSimple.origenCity}</p></th>
              <th className='headerItem'><p className='tituloInput'>{this.props.text.RutaSimple.destinoCity}</p></th>
              <th className='headerItem'><p className='tituloInput'>{this.props.text.RutaSimple.trafico}</p></th>
              <th className='headerItem'><p className='tituloInput'>{this.props.text.RutaSimple.servicio}</p></th>
              <th className='headerItem'><p className='tituloInput'>{this.props.text.RutaSimple.ContainerType}</p></th>
              <th className='headerItem'><p className='tituloInput'>{this.props.text.RutaSimple.frecuencia}</p></th>
              <th className='headerItem'><p className='tituloInput'>{this.props.text.RutaSimple.tiempo}</p></th>
              <th className='headerItem'><p className='tituloInput'>{this.props.text.RutaSimple.Tarifa}</p></th>
              <th className='headerItem'><p className='tituloInput'>{this.props.text.RutaSimple.Insurance}</p></th>
              <th className='headerItem'><p className='tituloInput'>{this.props.text.RutaSimple.PickUp}</p></th>
              <th className='headerItem'><p className='tituloInput'>{this.props.text.RutaSimple.HandlingOrigen}</p></th>
              <th className='headerItem'><p className='tituloInput'>{this.props.text.RutaSimple.HandlingDestino}</p></th>
              <th className='headerItem'><p className='tituloInput'>{this.props.text.RutaSimple.DocFee}</p></th>
              <th className='headerItem'><p className='tituloInput'>{this.props.text.RutaSimple.LocalDelivery}</p></th>

            </tr>
          </thead>
          <tbody>
            {this.state.renderCompanyActivas}
          </tbody>
        </table>
        <div id='Paginacion'>
         <Pagination
          onPageChange={this.handlePaginationChangeActivo}
          totalPages={this.state.pageNumbersActivas}
          activePage={this.state.currentPageActivas}
           />
         </div>
      </div>
    )
  }
}

class Item extends Component{
  constructor(props) {
    super(props);
  }
  render(){
    return(

      <tr key={this.props.key} className='FormRow Wagon'>
        <td className='inputRuta Wagon'>
          <input disabled value={this.props.it.PaisOrigen}  type='text'/>
        </td>
        <td className='inputRuta Wagon'>
          <input disabled value={this.props.it.PaisDestino}  type='text'/>
        </td>
        <td className='inputRuta Wagon inputOrigen'>
          <input disabled value={this.props.it.Origen} type='text'/>
        </td>
        <td className='inputRuta Wagon inputDestino'>
          <input disabled value={this.props.it.Destino}  type='text'/>
        </td>
        <td className='inputRuta Wagon'>
          <input disabled value={this.props.it.Trafico} type='text'/>
        </td>
        <td className='inputRuta Wagon'>
          <input disabled value={this.props.it.Servicio} type='text'/>
        </td>
        <td className='inputRuta Wagon'>
          <input disabled value={this.props.it.ContainerType} type='text'/>

        </td>

        <td className='inputRuta Wagon'>
          <input disabled value={this.props.it.Frecuencia} type='text'/>
        </td>
        <td className='inputRuta Wagon'>
          <input disabled value={this.props.it.Tiempo} type='text'/>
        </td>
        <td className='inputRuta Wagon'>
          <input disabled value={this.props.it.Tarifa} type='text'/>
        </td>
        <td className='inputRuta Wagon'>
          <input disabled value={this.props.it.Insurance} type='text'/>
        </td>
        <td className='inputRuta Wagon'>
          <input disabled value={this.props.it.PickUp} type='text'/>
        </td>
        <td className='inputRuta Wagon'>
          <input disabled value={this.props.it.HandlingOrigen} type='text'/>
        </td>
        <td className='inputRuta Wagon'>
          <input disabled value={this.props.it.HandlingDestino} type='text'/>
        </td>

        <td className='inputRuta Wagon'>
          <input disabled value={this.props.it.DocFee} type='text'/>
        </td>
        <td className='inputRuta Wagon'>
          <input disabled value={this.props.it.LocalDelivery} type='text'/>
        </td>
      </tr>
    )
  }
}

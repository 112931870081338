import React, { Component } from 'react';
import {Dimmer,Loader,Label, Icon, Table, Card, Image,Button,Modal, Form,Message,Input } from 'semantic-ui-react';
import firebase from 'firebase'
import {Direccion} from '../strings/peticiones.js';
import axios from 'axios';
import 'react-credit-cards/es/styles-compiled.css';
import Cards from 'react-credit-cards';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
class PagoTarjeta extends Component{
  constructor(){
    super()

    this.state = {
      cvc: '',
      expiry: '',
      focus: '',
      name: '',
      number: '',
      max:16,
      openAlert:false,
      AlertMessage:'',
      AlertType:'',
    };
    this.addCard=this.addCard.bind(this);

  }

  handleInputFocus = (e) => {
    const target = e.target;

    this.setState({
      focused: target.name,
    });
  };

  addCard(){ // se enviara al server (falta agregar then
   
    console.log(this.props);
    axios.post(Direccion+'/NuevaCard',
      {idUser:firebase.auth().currentUser.uid,tipo:this.props.tipo,Holder:this.state.name,CardNumber:this.state.number,Expiration:this.state.expiry,CVV:this.state.cvc})
      .then(res=>{
        console.log(res.data);
        if (res.data==="OK") {
           this.setState({
            openAlert:true,
            AlertMessage:'card added successfully',
            AlertType:'positive',
          })
          this.props.callbackGetCards();
          
        }else if (res.data==="340"){
          window.location.reload();
        }
      })
  }

  handleInputChange = (e) => {
    const target = e.target;

    if (target.name === 'number') {
      var value=target.value;
      var max=16;
      if (/^([0-9])*$/.test(target.value)){
        if(value.length>1){
           if(value[0]==='3'&&value[1]==='4'||value[0]==='3'&&value[1]==='7'){
            max=15;
          }
        }
        this.setState({
          [target.name]: target.value.replace(/ /g, ''),
          max:max
        });

    }

    }
    else if (target.name === 'expiry') {
      var value=target.value;
      if (/^([0-9-/])*$/.test(target.value) ){
          if(value.length===2 ){
            this.setState({
              [target.name]:value.replace(/ |\//g, '')+'/',
            });
          }
          else{
            this.setState({
              [target.name]:value,
            });
          }
    }

    }
  else if (target.name === 'name'){
    var value=target.value;
    if (!/^([0-9])*$/.test(target.value)){
      this.setState({
        [target.name]:value,
      });
    }

  }
  else if (target.name === 'cvc'){
    var value=target.value;
    if (/^([0-9])*$/.test(target.value)){
      this.setState({
        [target.name]:value,
      });
    }

  }
}//cierre handle

  close = () => {
    this.setState({
      name:'',
      number:'',
      expiry:'',
      cvc:'',

    },()=>{
      this.props.callBackVentana(false);
    })

    }

    handleCloseAlert=()=>{
      this.setState({
        openAlert:false,
      })
    }


  render(){
    var errorExpiracion=false;
    var errorNombre=false;
    const { name, number, expiry, cvc, focused,max } = this.state;

    return(
      <div>
      <Modal  open={this.props.open} onClose={this.close}>

      <Dimmer page id="DimmerSuscripcion" active={this.state.dimmerActive}>
       <Loader size='large'>Loading...</Loader>
       </Dimmer>

      <div style={{padding:'2vh',width:'65%',margin:'0px auto'}}>
       <div id="PaymentForm">
        <Cards
        number={number}
          name={name}
          expiry={expiry}
          cvc={cvc}
          focused={focused}
          callback={this.handleCallback}
        />
        <Form>
        <Form.Field style={{width:'80%',marginLeft:'auto',marginRight:'auto'}}>
        	<Input
            type="tel"
            name="number"
            value={this.state.number}
            placeholder="Card Number"
            onChange={this.handleInputChange}
            maxLength={max}
            onFocus={this.handleInputFocus}
          />
        </Form.Field>
        <Form.Field style={{width:'80%',marginLeft:'auto',marginRight:'auto'}}>
          <Input error={errorNombre}
            type='text'
            name="name"
            placeholder='Cardholder’s Name'
            onChange={this.handleInputChange}
            value={name}
            onFocus={this.handleInputFocus}/>

        </Form.Field>
        <Form.Group  style={{width:'80%',marginLeft:'auto',marginRight:'auto'}}>
          <Form.Input style={{width:'80%'}} error={errorExpiracion}
              type='tel'
              name="expiry"
              placeholder='Expiration date'
              value={this.state.expiry}
              onChange={this.handleInputChange}
              maxLength={5}
            onFocus={this.handleInputFocus}
            />

          <Form.Input style={{width:'80%'}} error={errorExpiracion}
                type='tel'
                name="cvc"
                placeholder='Security code'
                value={this.state.cvc}
                onChange={this.handleInputChange}
                maxLength={3}
              onFocus={this.handleInputFocus}/>
          </Form.Group>
          <Form.Group>
          <Button color='black'  style={{marginLeft:'auto',marginRight:'auto'}} onClick={this.addCard}>
            Add
          </Button>
          </Form.Group>
        </Form>
      </div>
      </div>
      </Modal>
      <AlertSnack open={this.state.openAlert} handleClose={this.handleCloseAlert} message={this.state.AlertMessage} type={this.state.AlertType}/>

      </div>
    )
  }
}



  function AlertSnack(props) {
    const { open,handleClose, message,type } = props;
    return (
      <div>
      {type === 'positive'?
        <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        key={'bottom','right'}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <SnackbarContent 
          style={{
            backgroundColor:' #4fbf86',
            border: 'solid 2px #35ad75',
            fontSize: '13px',
          }}
          message={
            <div>
            <Icon color='white' size='big' name='check circle outline' />
            <span id="message-id">{message}</span>
            </div>
          }
        />
     </Snackbar>

        :type === 'warning'?
        <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        key={'bottom','right'}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <SnackbarContent 
          style={{
            backgroundColor:' #d2c41f',
            border: '2px solid rgb(165, 167, 45)',
            fontSize: '13px',
          }}
          message={
            <div>
            <Icon color='white' size='big' name='warning circle' />
            <span id="message-id">{message}</span>
            <Icon style={{position:'absolute', top:'4px', right:'4px'}} onClick={handleClose} color='white' size='small' name='close' />

            </div>
          }
        />
     </Snackbar>

        :
        <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        key={'bottom','right'}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <SnackbarContent 
          style={{
            backgroundColor:' #e83232',
            border: 'solid 2px #8e1d1d',
            fontSize: '13px',
          }}
          message={
            <div>
            <Icon color='white' size='big' name='ban' />
            <span id="message-id">{message}</span>
            <Icon id='iconCloseAlert' onClick={handleClose} color='white' size='tiny' name='close' />

            </div>
          }
        />
     </Snackbar>

      } 
      </div>
    )}







export default PagoTarjeta;

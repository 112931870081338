import React, { Component } from 'react';
import { Divider, Header,Pagination, Icon,Modal,Image,Rating,Grid} from 'semantic-ui-react';
import './Catalogo.css';
import axios from 'axios';
import BarraLateral from '../ComponentsSeller/sideBar.js';
import Particles from 'react-particles-js';
import GetCountry from './../scripts/getCountryString.js';
import {Idioma} from '../../strings/CatalogoText.js';
import {Direccion} from '../../strings/peticiones.js';

import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);


class BuscarRutaPadre extends Component{
  constructor(){
    super()
    this.state={
      PaisFiltro:null,
      UsuarioCatalogo:[],
      NuevoCatalogo:[],
    }
  }
  componentDidMount(){
    let rutas=[];
    let self=this;
    axios.post(Direccion+'/TomarCatalogo')
    .then(res=>{
      rutas=res.data;
      self.setState({
        UsuarioCatalogo:rutas,
        NuevoCatalogo:rutas,
      })
    })
  }
  filterForPais=(pais)=>{
    if (pais !== 'none') {
      var nuevoArrayCatalogo = this.state.UsuarioCatalogo.filter(function (task) {
          return GetCountry(task.Contry) === pais;
      });
      this.setState({
        NuevoCatalogo:nuevoArrayCatalogo,
      })
    }else{
      this.setState({
        NuevoCatalogo:this.state.UsuarioCatalogo,
      })
    }

  }

  render(){
    return(
      <BuscarRuta filterForPais={this.filterForPais} directorio={this.state.NuevoCatalogo}/>
    )
  }
}

class BuscarRuta extends Component{
  constructor(){
    super()
    this.state={
      UsuarioCatalogo:[],
      currentPage:1,
      PerPage:21,
      currentTodos:[],
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
  if (nextProps.directorio !== prevState.UsuarioCatalogo) {
    return ({ UsuarioCatalogo: nextProps.directorio,currentPage:1 }) // <- this is setState equivalent
  }
  return null
}



    handlePaginationChange=(e,index)=>{
      this.setState({
        currentPage:index.activePage
      })
    }

    filterForPais=(e)=>{
      this.setState({PaisFiltro:e.target.value})
      this.props.filterForPais(e.target.value);
    }

  render(){
    let text=Idioma('ES');
    const indexOfLastTodo = this.state.currentPage * this.state.PerPage;
        const indexOfFirstTodo = indexOfLastTodo - this.state.PerPage;
        const currentTodos = this.state.UsuarioCatalogo.slice(indexOfFirstTodo, indexOfLastTodo);
        const renderCompany = currentTodos.map((it,key)=>{
          return(<CardCatalogo callbackPage={this.callbackPage} idioma={text} it={key+1}  datos={it}/>
          )
        })
        const pageNumbers =  Math.ceil(this.state.UsuarioCatalogo.length / this.state.PerPage);
    return(
      <div>
        <BarraLateral/>
        <div className="div-principal-Catalogo" >
          <div style={{position:'relative'}}>
            <Particles style={{backgroundImage:'url(../imagenes/directorio.jpg)', backgroundSize:'cover',backgroundPosition:'100% 0%', position:'absolute',height:'500px'}}
                   params={{
                     "particles": {
                         "number": {
                             "value": 40
                         },
                         "size": {
                             "value": 2
                         }
                     },
                     "interactivity": {
                         "events": {
                             "onhover": {
                                 "enable": true,
                                 "mode": "repulse"
                             }
                         }
                     }
                 }} />
             <div>
                 <div className='headerDirectorio'>
                   <i class="material-icons" style={{ fontSize: '2em' ,position:'relative',color:'white'}}>book </i>
                   <p>Members Directory</p>
                 </div>
              <div class="inputSelect">
                <SelectPais Pais={this.state.PaisFiltro} handleRefPais={this.filterForPais} />
              </div>
            </div>
          </div>
            <br/>
          <div className='ContentListaCards'>
            <ul className='col3'>
                {renderCompany}
                <div className='clear'></div>
            </ul>
          </div>

          <div className='Paginacion'>
            <Pagination
             onPageChange={this.handlePaginationChange}
             totalPages={pageNumbers}
             activePage={this.state.currentPage}
              />
          </div>
        </div>
      </div>

    )
  }
}

const SelectPais=(props )=>{
  return(
    <select  className='selectCountry' id="country" name="country" value={props.Pais} onChange={(e)=>props.handleRefPais(e)} >
      <option  selected value="none">All countries</option>
       <option value="Afganistan">Afghanistan</option>
       <option value="Albania">Albania</option>
       <option value="Algeria">Algeria</option>
       <option value="American Samoa">American Samoa</option>
       <option value="Andorra">Andorra</option>
       <option value="Angola">Angola</option>
       <option value="Anguilla">Anguilla</option>
       <option value="Antigua & Barbuda">Antigua & Barbuda</option>
       <option value="Argentina">Argentina</option>
       <option value="Armenia">Armenia</option>
       <option value="Aruba">Aruba</option>
       <option value="Australia">Australia</option>
       <option value="Austria">Austria</option>
       <option value="Azerbaijan">Azerbaijan</option>
       <option value="Bahamas">Bahamas</option>
       <option value="Bahrain">Bahrain</option>
       <option value="Bangladesh">Bangladesh</option>
       <option value="Barbados">Barbados</option>
       <option value="Belarus">Belarus</option>
       <option value="Belgium">Belgium</option>
       <option value="Belize">Belize</option>
       <option value="Benin">Benin</option>
       <option value="Bermuda">Bermuda</option>
       <option value="Bhutan">Bhutan</option>
       <option value="Bolivia">Bolivia</option>
       <option value="Bonaire">Bonaire</option>
       <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
       <option value="Botswana">Botswana</option>
       <option value="Brazil">Brazil</option>
       <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
       <option value="Brunei">Brunei</option>
       <option value="Bulgaria">Bulgaria</option>
       <option value="Burkina Faso">Burkina Faso</option>
       <option value="Burundi">Burundi</option>
       <option value="Cambodia">Cambodia</option>
       <option value="Cameroon">Cameroon</option>
       <option value="Canada">Canada</option>
       <option value="Canary Islands">Canary Islands</option>
       <option value="Cape Verde">Cape Verde</option>
       <option value="Cayman Islands">Cayman Islands</option>
       <option value="Central African Republic">Central African Republic</option>
       <option value="Chad">Chad</option>
       <option value="Channel Islands">Channel Islands</option>
       <option value="Chile">Chile</option>
       <option value="China">China</option>
       <option value="Christmas Island">Christmas Island</option>
       <option value="Cocos Island">Cocos Island</option>
       <option value="Colombia">Colombia</option>
       <option value="Comoros">Comoros</option>
       <option value="Congo">Congo</option>
       <option value="Cook Islands">Cook Islands</option>
       <option value="Costa Rica">Costa Rica</option>
       <option value="Cote DIvoire">Cote DIvoire</option>
       <option value="Croatia">Croatia</option>
       <option value="Cuba">Cuba</option>
       <option value="Curaco">Curacao</option>
       <option value="Cyprus">Cyprus</option>
       <option value="Czech Republic">Czech Republic</option>
       <option value="Denmark">Denmark</option>
       <option value="Djibouti">Djibouti</option>
       <option value="Dominica">Dominica</option>
       <option value="Dominican Republic">Dominican Republic</option>
       <option value="East Timor">East Timor</option>
       <option value="Ecuador">Ecuador</option>
       <option value="Egypt">Egypt</option>
       <option value="El Salvador">El Salvador</option>
       <option value="Equatorial Guinea">Equatorial Guinea</option>
       <option value="Eritrea">Eritrea</option>
       <option value="Estonia">Estonia</option>
       <option value="Ethiopia">Ethiopia</option>
       <option value="Falkland Islands">Falkland Islands</option>
       <option value="Faroe Islands">Faroe Islands</option>
       <option value="Fiji">Fiji</option>
       <option value="Finland">Finland</option>
       <option value="France">France</option>
       <option value="French Guiana">French Guiana</option>
       <option value="French Polynesia">French Polynesia</option>
       <option value="French Southern Ter">French Southern Ter</option>
       <option value="Gabon">Gabon</option>
       <option value="Gambia">Gambia</option>
       <option value="Georgia">Georgia</option>
       <option value="Germany">Germany</option>
       <option value="Ghana">Ghana</option>
       <option value="Gibraltar">Gibraltar</option>
       <option value="Great Britain">Great Britain</option>
       <option value="Greece">Greece</option>
       <option value="Greenland">Greenland</option>
       <option value="Grenada">Grenada</option>
       <option value="Guadeloupe">Guadeloupe</option>
       <option value="Guam">Guam</option>
       <option value="Guatemala">Guatemala</option>
       <option value="Guinea">Guinea</option>
       <option value="Guyana">Guyana</option>
       <option value="Haiti">Haiti</option>
       <option value="Hawaii">Hawaii</option>
       <option value="Honduras">Honduras</option>
       <option value="Hong Kong">Hong Kong</option>
       <option value="Hungary">Hungary</option>
       <option value="Iceland">Iceland</option>
       <option value="Indonesia">Indonesia</option>
       <option value="India">India</option>
       <option value="Iran">Iran</option>
       <option value="Iraq">Iraq</option>
       <option value="Ireland">Ireland</option>
       <option value="Isle of Man">Isle of Man</option>
       <option value="Israel">Israel</option>
       <option value="Italy">Italy</option>
       <option value="Jamaica">Jamaica</option>
       <option value="Japan">Japan</option>
       <option value="Jordan">Jordan</option>
       <option value="Kazakhstan">Kazakhstan</option>
       <option value="Kenya">Kenya</option>
       <option value="Kiribati">Kiribati</option>
       <option value="Korea North">Korea North</option>
       <option value="Korea Sout">Korea South</option>
       <option value="Kuwait">Kuwait</option>
       <option value="Kyrgyzstan">Kyrgyzstan</option>
       <option value="Laos">Laos</option>
       <option value="Latvia">Latvia</option>
       <option value="Lebanon">Lebanon</option>
       <option value="Lesotho">Lesotho</option>
       <option value="Liberia">Liberia</option>
       <option value="Libya">Libya</option>
       <option value="Liechtenstein">Liechtenstein</option>
       <option value="Lithuania">Lithuania</option>
       <option value="Luxembourg">Luxembourg</option>
       <option value="Macau">Macau</option>
       <option value="Macedonia">Macedonia</option>
       <option value="Madagascar">Madagascar</option>
       <option value="Malaysia">Malaysia</option>
       <option value="Malawi">Malawi</option>
       <option value="Maldives">Maldives</option>
       <option value="Mali">Mali</option>
       <option value="Malta">Malta</option>
       <option value="Marshall Islands">Marshall Islands</option>
       <option value="Martinique">Martinique</option>
       <option value="Mauritania">Mauritania</option>
       <option value="Mauritius">Mauritius</option>
       <option value="Mayotte">Mayotte</option>
       <option value="Mexico">Mexico</option>
       <option value="Midway Islands">Midway Islands</option>
       <option value="Moldova">Moldova</option>
       <option value="Monaco">Monaco</option>
       <option value="Mongolia">Mongolia</option>
       <option value="Montserrat">Montserrat</option>
       <option value="Morocco">Morocco</option>
       <option value="Mozambique">Mozambique</option>
       <option value="Myanmar">Myanmar</option>
       <option value="Nambia">Nambia</option>
       <option value="Nauru">Nauru</option>
       <option value="Nepal">Nepal</option>
       <option value="Netherland Antilles">Netherland Antilles</option>
       <option value="Netherlands">Netherlands (Holland, Europe)</option>
       <option value="Nevis">Nevis</option>
       <option value="New Caledonia">New Caledonia</option>
       <option value="New Zealand">New Zealand</option>
       <option value="Nicaragua">Nicaragua</option>
       <option value="Niger">Niger</option>
       <option value="Nigeria">Nigeria</option>
       <option value="Niue">Niue</option>
       <option value="Norfolk Island">Norfolk Island</option>
       <option value="Norway">Norway</option>
       <option value="Oman">Oman</option>
       <option value="Pakistan">Pakistan</option>
       <option value="Palau Island">Palau Island</option>
       <option value="Palestine">Palestine</option>
       <option value="Panama">Panama</option>
       <option value="Papua New Guinea">Papua New Guinea</option>
       <option value="Paraguay">Paraguay</option>
       <option value="Peru">Peru</option>
       <option value="Phillipines">Philippines</option>
       <option value="Pitcairn Island">Pitcairn Island</option>
       <option value="Poland">Poland</option>
       <option value="Portugal">Portugal</option>
       <option value="Puerto Rico">Puerto Rico</option>
       <option value="Qatar">Qatar</option>
       <option value="Republic of Montenegro">Republic of Montenegro</option>
       <option value="Republic of Serbia">Republic of Serbia</option>
       <option value="Reunion">Reunion</option>
       <option value="Romania">Romania</option>
       <option value="Russia">Russia</option>
       <option value="Rwanda">Rwanda</option>
       <option value="St Barthelemy">St Barthelemy</option>
       <option value="St Eustatius">St Eustatius</option>
       <option value="St Helena">St Helena</option>
       <option value="St Kitts-Nevis">St Kitts-Nevis</option>
       <option value="St Lucia">St Lucia</option>
       <option value="St Maarten">St Maarten</option>
       <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
       <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
       <option value="Saipan">Saipan</option>
       <option value="Samoa">Samoa</option>
       <option value="Samoa American">Samoa American</option>
       <option value="San Marino">San Marino</option>
       <option value="Sao Tome & Principe">Sao Tome & Principe</option>
       <option value="Saudi Arabia">Saudi Arabia</option>
       <option value="Senegal">Senegal</option>
       <option value="Seychelles">Seychelles</option>
       <option value="Sierra Leone">Sierra Leone</option>
       <option value="Singapore">Singapore</option>
       <option value="Slovakia">Slovakia</option>
       <option value="Slovenia">Slovenia</option>
       <option value="Solomon Islands">Solomon Islands</option>
       <option value="Somalia">Somalia</option>
       <option value="South Africa">South Africa</option>
       <option value="Spain">Spain</option>
       <option value="Sri Lanka">Sri Lanka</option>
       <option value="Sudan">Sudan</option>
       <option value="Suriname">Suriname</option>
       <option value="Swaziland">Swaziland</option>
       <option value="Sweden">Sweden</option>
       <option value="Switzerland">Switzerland</option>
       <option value="Syria">Syria</option>
       <option value="Tahiti">Tahiti</option>
       <option value="Taiwan">Taiwan</option>
       <option value="Tajikistan">Tajikistan</option>
       <option value="Tanzania">Tanzania</option>
       <option value="Thailand">Thailand</option>
       <option value="Togo">Togo</option>
       <option value="Tokelau">Tokelau</option>
       <option value="Tonga">Tonga</option>
       <option value="Trinidad & Tobago">Trinidad & Tobago</option>
       <option value="Tunisia">Tunisia</option>
       <option value="Turkey">Turkey</option>
       <option value="Turkmenistan">Turkmenistan</option>
       <option value="Turks & Caicos Is">Turks & Caicos Is</option>
       <option value="Tuvalu">Tuvalu</option>
       <option value="Uganda">Uganda</option>
       <option value="United Kingdom">United Kingdom</option>
       <option value="Ukraine">Ukraine</option>
       <option value="United Arab Erimates">United Arab Emirates</option>
       <option value="United States of America">United States of America</option>
       <option value="Uraguay">Uruguay</option>
       <option value="Uzbekistan">Uzbekistan</option>
       <option value="Vanuatu">Vanuatu</option>
       <option value="Vatican City State">Vatican City State</option>
       <option value="Venezuela">Venezuela</option>
       <option value="Vietnam">Vietnam</option>
       <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
       <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
       <option value="Wake Island">Wake Island</option>
       <option value="Wallis & Futana Is">Wallis & Futana Is</option>
       <option value="Yemen">Yemen</option>
       <option value="Zaire">Zaire</option>
       <option value="Zambia">Zambia</option>
       <option value="Zimbabwe">Zimbabwe</option>
    </select>
  )
}

class CardCatalogo extends Component{

  constructor(){
    super();

    this.state={
      open: false,
      dimmer:true,
    }
  }

  show = dimmer => () => this.setState({ dimmer, open: true })

  close = () => this.setState({ open: false })
  goToWebSite=()=>{
    let url = this.props.datos.Website;
    if (!url.match(/^[a-zA-Z]+:\/\//)){url = 'http://' + url;}
    window.open(url, '_blank');
  }


  render(){
    let text=this.props.idioma;
    let avatar= this.props.datos.avatar && this.props.datos.avatar !=='none'?this.props.datos.avatar:'../imagenes/work.png';

    let pais = GetCountry(this.props.datos.Contry);

    let rate = 0;
    if (this.props.datos.Rate) {

       rate = parseInt(this.props.datos.Rate.PromedioStar);
    }
    let CompanyName = this.props.datos.CompanyName.toLowerCase()
      CompanyName = CompanyName.charAt(0).toUpperCase() + CompanyName.slice(1);
    return(

      <li className='contentCardCatalogo'>

        <div className='CardCatalogo' onClick={ this.show(true)}>
          <div className='iconCheck'><Icon color='teal' name='check circle'/></div>

          <div className='contentAvatar'>
            <div className='triangulo'><img  alt="CardAvatar" src='/imagenes/trianguloCard.svg'/></div>
            <div className='avatar'><img  alt="CardAvatar" src={avatar}/></div>
          </div>


          <div className='CardContent'>
            <h3 className='titulo'>{CompanyName.substring(0, 35)}</h3>
            <p className='subtitulo'>{pais}</p>
            <div className='CardContent Down'>
              <div className='insignia'>
                <h3>{this.props.datos.Cuenta === 'Free' || this.props.datos.Cuenta === 0?'New':this.props.datos.Cuenta}</h3>
                <p>Member</p>
              </div>

            </div>
          </div>
          <div className='rateCard'>
            {rate?
              <Rating icon='star' defaultRating={rate} maxRating={5} />:
              <p>No qualification yet</p>
            }
          </div>
        </div>

          <Modal style={{width:'58%'}} dimmer={this.state.dimmer} open={this.state.open} onClose={this.close}>

            <Modal.Content image>
              <div style={{textAlign:'center',width:'30%'}}>
                <Image id="ImgCompany" wrapped  size='small' src={avatar} />
                <h4><a onClick={this.goToWebSite}> {this.props.datos.Website}</a></h4>
              </div>
              <div className='InfoCompany'>
                <Header as='h2'>
                  {this.props.datos.CompanyName}
                  <h3>
                  {pais}
                  </h3>
                </Header>
                <p style={{fontSize: '14px',textAlign: 'center'}}>{this.props.datos.descripcion}</p><br/>

              </div>
              <Rating maxRating={5} defaultRating={rate} icon='star' size='huge' />
            </Modal.Content>
            <Divider/>
            <Modal.Content  className='InfoCompany'>
              <Grid id='gridInfo' columns={4} stackable style={{padding:'2vh'}}>

                <Grid.Row verticalAlign='middle' style={{paddingTop:'0'}}>
                  <Grid.Column>
                  <p>State</p>
                  <h4>{this.props.datos.State.toUpperCase()}</h4>
                  </Grid.Column>
                  <Grid.Column>
                  <p>City</p>
                  <h4>{this.props.datos.City.toUpperCase()}</h4>
                  </Grid.Column>
                  <Grid.Column>
                  <p>Address</p>
                  <h4>{this.props.datos.Address.toUpperCase()}</h4>
                  </Grid.Column>
                  <Grid.Column>
                  <p>Postal Code</p>
                  <h4>{this.props.datos.PostaleCode.toUpperCase()}</h4>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row verticalAlign='middle'>
                  <Grid.Column>
                  <p>Phone</p>
                  <h4>{this.props.datos.Phone.toUpperCase()}</h4>
                  </Grid.Column>
                  <Grid.Column>
                  <p>Establishment year</p>
                  <h4>{this.props.datos.EstablishmentYear.toUpperCase()}</h4>
                  </Grid.Column>
                  <Grid.Column>
                  <p>N° Employees</p>
                  <h4>{this.props.datos.NoEmployees.toUpperCase()}</h4>
                  </Grid.Column>
                  <Grid.Column>
                  <p>Name owners</p>
                  <h4>{this.props.datos.NameOwners.toUpperCase()}</h4>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Content>
            <Divider/>

            <Modal.Content>
              <div className='InfoCompany'>
                    <Header  style={{marginBottom:'0'}}as='h2'>Contact Info</Header>
                  <Grid id='gridInfo' columns={4} stackable style={{padding:'2vh'}}>

                          <Grid.Row >
                            <Grid.Column>
                              <p>Name</p>
                              <h4>{this.props.datos.FirstName} {this.props.datos.LastName} </h4>
                            </Grid.Column>
                            <Grid.Column>
                              <p>Title</p>
                              <h4>{this.props.datos.Title}</h4>
                            </Grid.Column>
                            <Grid.Column>
                              <p>Mobil</p>
                              <h4>{this.props.datos.Mobil}</h4>
                            </Grid.Column>
                            <Grid.Column>
                              <p>Email</p>
                              <h4>{this.props.datos.correo}</h4>
                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row >
                            <Grid.Column>
                              <p>Name</p>
                              <h4>{this.props.datos.ContactRef1}</h4>
                            </Grid.Column>
                            <Grid.Column>
                              <p>Title</p>
                              <h4>{this.props.datos.CompanyRef1}</h4>
                            </Grid.Column>

                            <Grid.Column>
                              <p>Email</p>
                              <h4>{this.props.datos.EmailRef1}</h4>
                            </Grid.Column>
                          </Grid.Row>
                          </Grid>
                      </div>
            </Modal.Content>
          </Modal>

      </li>
    )
  }
}






export default BuscarRutaPadre;

var moment = require('moment');
var axios = require('axios');

 function FiltrarTiemposStatus(data,fecha){

    var dateTime = fecha;

    if(data.length>1){
        for(var i=0;i<data.length;i++){
          if(i===0){
            data[i].TiempoProceso=cantidadDiferencia(data[i+1].FechaHora,dateTime);
            data[i].TiempoAcumulado=cantidadDiferencia(data[data.length-1].FechaHora,dateTime);
          }
          else if(i===data.length-1){
            data[i].TiempoProceso=cantidadDiferencia(data[i].FechaHora,data[i-1].FechaHora);
            data[i].TiempoAcumulado=cantidadDiferencia(data[i].FechaHora,data[i-1].FechaHora);
          }
          else{
            data[i].TiempoProceso=cantidadDiferencia(data[i+1].FechaHora,data[i-1].FechaHora);
            data[i].TiempoAcumulado=cantidadDiferencia(data[data.length-1].FechaHora,data[i-1].FechaHora);
          }
        }
    }
    return data;

}

function cantidadDiferencia(fecha1,fecha2){
  var f = moment(fecha1);
  var f2 = moment(fecha2);
  return f2.diff(f, 'hours');
}
export default FiltrarTiemposStatus;

import React, { Component,useState, useEffect } from 'react';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import {Router, Route, BrowserRouter, Switch, Link} from 'react-router-dom'
import { Divider, Header,Step,Accordion, Icon,Table,Modal,Button,Form,Rating,Dimmer,Loader,Input,Card,Image,Label,Segment,Grid} from 'semantic-ui-react';
import './styleUser/BuscarRuta.css';
import './styleUser/rutasBuscadorNew.css';

import BarraLateral from './sideBar.js';
import BarraLateralVendedor from '../ComponentsSeller/sideBar.js';
import BarraLateralAdmin from '../ComponentsAdmin/sideBarAdmin.js';
import Buscador from '../ComponentesGenerales/Buscador/Buscador.js';
import Footer from '../ComponentesGenerales/Footer/Footer.js';
import CarouselPropio from '../ComponentesGenerales/Carousel/Carousel.js';
import PublicNavbar from '../ComponentesGenerales/Menus/MenuPublicov2.js';
import axios from 'axios';
import firebase from 'firebase'
import {Idioma} from '../../strings/BuscarRutaText.js';
import {Direccion,DireccionFront} from '../../strings/peticiones.js';
import Script from 'react-load-script';
import TextField from '@material-ui/core/TextField';
import Particles from 'react-particles-js';
import DeterminarImagen from '../scripts/imagenes.js';
import Login from '../login.js'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import AlertSnack from '../notificacionCard.js';
import CalculadorCBM from '../scripts/CalculadorCBM.js';

import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);


const responsiveCarousel = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


class BuscarRuta extends Component{
  constructor(){
    super()
    this.state={
      openVentana:false,
      openVentanaSinContenedor:false,
      medioTransporte:"",
      cityOrigen: [],
      queryOrigen: '',
      cityDestino: [],
      queryDestino: '',
      Destino: '',
      Orgien: '',
      OrigenSec:'',
      DestinoSec:'',
      buscando:false,
      rutasEncontradas:[],
      openVentanaTerrestre:false,
      openVentanaFerro:false,
      noEncontrado:false,
      openAlert:false,
      AlertMessage:'',
      AlertType:'',
      RutasRandom:[],
      lan:navigator.language || navigator.userLanguage,
      itemActivoCarousel:0,
    }

  }

  componentDidMount(){

    if (this.props.match) {
      if (this.props.match.params.idPreCotizacion) {
        this.TomarPrecotizacion(this.props.match.params.idPreCotizacion);
      }
    }
    this.BuscarRutaRandom();
  }
  TomarPrecotizacion=(idPreCotizacion)=>{
    this.setState({rutasEncontradas:[],buscando:false,noEncontrado:false,})
    try{
      let self=this;
         this.setState({buscando:true})
        axios.post(Direccion+`/cotizar-precotizacion`,{idPreCotizacion:idPreCotizacion})
        .then(res => {
          if (res.data) {
            if (res.data.length>0) {
              self.setState({
                rutasEncontradas:res.data,
                buscando:false,
                noEncontrado:false,
              })
            }else{
              self.setState({
                noEncontrado:true,
                buscando:false,
                openAlert:true,
                AlertMessage:'ups! we not found rates',
                AlertType:'warning',
              })
            }
          }
          else{
             this.setState({
               noEncontrado:true,
               buscando:false,
               openAlert:true,
               AlertMessage:'ups! we not found rates',
               AlertType:'warning',
            })
          }

        })
        .catch(err=>{
          this.setState({
            noEncontrado:true,
            buscando:false,
            openAlert:true,
            AlertMessage:'Error! something went wrong',
            AlertType:'error',
         })
        })


    }catch(e){
      console.log(e);
    }

  }

  BlockDirecciones=(ArrayAux)=>{
    let stringExt = "";
    for (var i = 0; i < ArrayAux.length; i++) {
      stringExt += ArrayAux[i].long_name+",";
    }
    return stringExt;
  }
  abrirTipo=()=>{
    if (this.state.medioTransporte) {
      if (this.state.medioTransporte === "mar") {
        this.setState({
          openVentana:true
        })
      }
       else if(this.state.medioTransporte ==="Aereo") {
        this.setState({
          openVentanaSinContenedor:true
        })
      }
      else if(this.state.medioTransporte==='carretera'){
        this.setState({
          openVentanaTerrestre:true
        })
      }
      else if(this.state.medioTransporte==='ferrocaril'){
        this.setState({
          openVentanaFerro:true
        })
      }

    }
    else {

      this.setState({
          openAlert:true,
          AlertMessage:'Select a means of transport',
          AlertType:'warning',
        })
    }

  }


  callbackVentana=(value)=>{
      //aqui es llegada de los objetos de busqueda para peticion de el server
      //la condicional es porque recibe dos tipos de objetos
      //objeto con la descripcion y un objeto = {false}
      //este ultimo por si el usuario cierra la ventana sin llenar ni un campo o cancela
    if(value){
    var a = value;
    a["Origen"]= this.state.queryOrigen;
    a["Destino"]= this.state.queryDestino;
    a["Transporte"]= this.state.medioTransporte;
    this.setState({
      openVentana:false,
      openVentanaSinContenedor:false,
      openVentanaTerrestre:false,
      openVentanaFerro:false,
      ObjBusqueda: a,
    })

    }
    else {
      this.setState({
        openVentanaSinContenedor:false,
        openVentana:false,
        openVentanaTerrestre:false,
        openVentanaFerro:false
      })
    }
  }
  BuscarRutaRandom=()=>{
    let self = this;
    axios.post(Direccion+`/TomarRutasRandom`,{})
    .then(res => {
      if (res.data.status === 'OK') {
        self.setState({
          RutasRandom:res.data.RutasRandom,
        })
      }
    }).catch(err=>{
      console.log(err);
    })
  }

  BuscarRutas=()=>{
    this.setState({rutasEncontradas:[],buscando:false,noEncontrado:false,})
    try{
      let self=this;
      if (this.ValidarPeticion()) {
        let busqueda=this.state.ObjBusqueda;
        let cotizaciones=[];
        busqueda["Origen"] = this.state.OrigenSec;
        busqueda["Destino"] = this.state.DestinoSec;
        busqueda["Transporte"] = this.state.medioTransporte;
         this.setState({buscando:true})
        axios.post(Direccion+`/cotizacion`,{data:busqueda,type:this.state.medioTransporte,OrigenBlock:this.state.cityOrigen,DestinoBlock:this.state.cityDestino,DestinoSec:this.state.DestinoSec,OrigenSec:this.state.OrigenSec })
        .then(res => {
          if (res.data) {
            if (res.data.length>0) {
              self.setState({
                rutasEncontradas:res.data,
                buscando:false,
                noEncontrado:false,
              })
            }else{
              self.setState({
                noEncontrado:true,
                buscando:false,
                openAlert:true,
                AlertMessage:'ups! we not found rates',
                AlertType:'warning',              })
            }
          }
          else{
             this.setState({
              openAlert:true,
              AlertMessage:'ups! we not found rates',
              AlertType:'warning',
              buscando:false,
              noEncontrado:true,

            })

          }

        })
      }

    }catch(e){
      console.log(e);
    }

  }

  ValidarPeticion=()=>{
    if (!this.state.medioTransporte) {
      this.setState({
        openAlert:true,
        AlertMessage:'select a means of transport aaaaa a a a ',
        AlertType:'warning',
      })
      return false;
    }
    else if(!this.state.OrigenSec){
      this.setState({
        openAlert:true,
        AlertMessage:'select an origin',
        AlertType:'warning',
      })
      return false;

    }
    else if(!this.state.DestinoSec){
      this.setState({
        openAlert:true,
        AlertMessage:'select a destination',
        AlertType:'warning',
      })
      return false;

    }else{
      return true;

    }
  }
  //
  // PreCotizarRuta=(ruta)=>{
  //   let medioTransporte = ruta.type==='LCL' || ruta.type==='FCL'|| ruta.type==='BBulk' ?'mar':ruta.type==='Aereo'?'Aereo':ruta.type==='Wagon' || ruta.type==='FCL-Ferro' ?'ferrocaril':'carretera';
  //   this.setState({
  //     OrigenSec:ruta.Origen,
  //     DestinoSec:ruta.Destino,
  //     medioTransporte:medioTransporte,
  //   })
  // }


  handleCloseAlert=()=>{this.setState({openAlert:false,})}
  resetAlert = ()=> this.setState({ openAlert:false,titleAlert: '', AlertMessage:'', AlertType:''});

  render(){
    let text=Idioma('ES').textos;
    const { classes } = this.props;
    return(
      <div className="div-principal-envios-vendedor" style={{backgroundColor:'#fff'}}>
        <div className='headerBuscadorLanding'>
          {firebase.auth().currentUser?
            <div>
              {this.props.mode === '1'?
                <BarraLateralVendedor/>

              :this.props.mode === '2'?
                <BarraLateral/>

              :this.props.mode === '3'?
                <BarraLateralAdmin/>

              :
                <PublicNavbar/>

              }
            </div>
            :
            <PublicNavbar />
          }
        </div>
        <div className='BuscadorRutas'>
        <section className='header-container'>
            <div className='text'>
              <h1>Search for Rates</h1>
              <p>Search from over 120K Ocean, Air and Truck Freight quotes and get the latest international shipping rates from any carrier in seconds.</p>
            </div>

            <div className='headerBuscadorContent'>
              <Buscador  callBackRutasEncontradas={(datos)=>this.setState({rutasEncontradas:datos})}/>
            </div>
        </section>
        </div>


   

        <div style={{margin:'5vh auto',width:'90%'}}>
          {this.state.noEncontrado && !this.state.buscando?
            <div className='ContentnotFount'>
              <img src='../imagenes/empyRate.png' style={{width:'25%',margin:'10px auto'}}/>
              <h2>There are no rates in this direction at the moment.</h2>
            </div>
            :
            <div>
              {this.state.rutasEncontradas.length<1?
              null
              :
              <div >
                <div >
                  <ul className='col6'>
                    {
                        this.state.rutasEncontradas.map((it,key)=>{
                          if(it.tipo==='DIRECTA'){
                            return(<CardRutaPublica ObjBusqueda={this.state.ObjBusqueda}  idioma={text} it={key+1}  datos={it.ruta} tipo={'DIRECTA'}/>)
                          }
                          else if(it.tipo==='ANIDADO'){
                            return(<CardRutaPublicaAnidada  ObjBusqueda={this.state.ObjBusqueda}  idioma={text} it={key+1}  datos={it} tipo={'ANIDADO'}/>)

                          }
                        })
                    }
                    <div className='clear'></div>
                  </ul>
                </div>
              </div>
              }
            </div>
          }
        </div>

          {this.state.RutasRandom.length>0?

            <div className='RutasRandomContent'>
              <div className='RutasRandomTittle'>
                <h3>New Rates</h3>
              </div>
                <Carousel
                  responsive={responsiveCarousel}
                  keyBoardControl={true}
                  autoPlay={this.props.deviceType !== "mobile" ? true : false}
                  swipeable={true}
                  draggable={true}
                  infinite={true}
                  customTransition="transform 10000ms linear"

                  removeArrowOnDeviceType={["tablet", "mobile",'desktop','superLargeDesktop']}

                  deviceType={this.props.deviceType}
                  itemClass="carousel-item-padding-40-px"
                  containerClass="carousel-container"

                  >
                  {
                    this.state.RutasRandom.map((it,key)=>{
                      return(<CardRandom PreCotizarRuta={this.PreCotizarRuta} ruta={it} key={key}/>)
                    })
                  }
              </Carousel>
            </div>
            :
            <div></div>
          }
          <section className='landingHome'>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',margin:'0 auto',marginTop:'30px'}}>
              <div style={{backgroundColor:'#F46363',width:'30px',height:'30px',borderRadius:'50%',marginBottom:'40px'}}></div>
              <p style={{fontSize: '1.8rem',fontWeight: '800',marginBottom: '10px',color:'#3E3D3D',textAlign:'center'}}>It’s your time to Grow, All you need is Switch to Digital</p>
              <p style={{fontSize:'1.2rem',width:'70%',textAlign:'center',marginTop:'30px'}}>We've created a Tech Digital Solution for Freight Forwarders that revolutionize the logistics industry and make freight handling processes easier, faster and less expensive.</p>
            </div>
            <div className='contentItemFreigt'>
              <div onClick={()=>this.setState({itemActivoCarousel:0})} className={this.state.itemActivoCarousel==0?'itemFreigt activo':'itemFreigt'} >
                <p>For Importers & exporters</p>
              </div>
              <div onClick={()=>this.setState({itemActivoCarousel:1})} className={this.state.itemActivoCarousel==1?'itemFreigt activo':'itemFreigt'} >
                <p>For Freight Forwarders</p>
              </div>
              <div onClick={()=>this.setState({itemActivoCarousel:2})} className={this.state.itemActivoCarousel==2?'itemFreigt activo':'itemFreigt'} >
                <p>For Freight Networks</p>
              </div>
            </div>

            <div>
             
              <CarouselPropio itemActivo={this.state.itemActivoCarousel}/>
            </div>
            <div className='grandiente' />
            <div className='grandiente naranja' />

            <div className='tituloContent'>
              <p className='titulo-seccion' >Our partners</p>
              <div className='underline'></div>
            </div>
            <div className='partnerContent'>
              <ul className='col4'>
                <li>
                  <img src='../imagenes/partner1.png' />
                </li>
                <li>
                  <img src='../imagenes/partner2.png' />
                </li>
                <li>
                  <img src='../imagenes/partner3.png' />
                </li>
                <li>
                  <img src='../imagenes/partner4.png' />
                </li>
                <div className='clear'></div>
              </ul>
            </div>
          </section>


         {/* <TipoEnvio callBackVentana={this.callbackVentana} open={this.state.openVentana}/>
         <TipoEnvioSinContenedor callBackVentana={this.callbackVentana} open={this.state.openVentanaSinContenedor}/>
         <TipoEnvioTerrestre callBackVentana={this.callbackVentana} open={this.state.openVentanaTerrestre}/>
         <TipoEnvioFerro callBackVentana={this.callbackVentana} open={this.state.openVentanaFerro}/> */}

         <Dimmer page active={this.state.buscando} >
         <Loader>
           <Header as='h2' icon inverted>
             Searching ....
           </Header>
         </Loader>
         </Dimmer>
         <Footer/>
      </div>

    )
  }
}


class CardRutaPublica extends Component{

  constructor(){
    super();
    var date = new Date();
    let month = date.getMonth()+1;
    let day = date.getDate();
      if (month <10) {
          month = "0"+month;
        }
      if (day<10) {
        day = "0"+day;
      }
    this.state={
      open: false,
      dimmer:true,
      ventana:1,
      fecha:date.getFullYear()+'-'+month +'-'+day,
      nombreProducto:'',
      NombreShipper:'',
      TelefonoShipper:'',
      EmailShipper:'',
      NombreConsig:'',
      TelefonoConsig:'',
      EmailConsig:'',
      NombreNotificar:'',
      TelefonoNotificar:'',
      EmailNotificar:'',
      observacionesContacto:'',
      openAlert:false,
      AlertMessage:'',
      AlertType:'',
      activeIndex: 1,
      btnDisable:false,
      btnLoading:false,
      verificadoMensaje:false
    }
  }

  resetAlert = ()=> this.setState({ openAlert:false,titleAlert: '', AlertMessage:'', AlertType:'',});

  show = dimmer => () => this.setState({ dimmer, open: true })

  close = () => this.setState({ open: false })
  cancelar = () => this.setState({ open: false,nombreProducto:'',NombreShipper:'',TelefonoShipper:'',EmailShipper:'',NombreConsig:'',TelefonoConsig:'',EmailConsig:'',NombreNotificar:'',TelefonoNotificar:'',EmailNotificar:'',observacionesContacto:'', })


  pasoAgendar=()=>{

    if(this.state.ventana===3){
      var detalles={nombreProducto:this.state.nombreProducto,fecha:this.state.fecha,NombreShipper:this.state.NombreShipper,TelefonoShipper:this.state.TelefonoShipper,EmailShipper:this.state.EmailShipper
        ,NombreConsig:this.state.NombreConsig,TelefonoConsig:this.state.TelefonoConsig,EmailConsig:this.state.EmailConsig
        ,NombreNotificar:this.state.NombreNotificar,TelefonoNotificar:this.state.TelefonoNotificar,EmailNotificar:this.state.EmailNotificar
        ,observaciones:this.state.observacionesContacto,hsCode:this.state.hsCode,contactNameShipper:this.state.contactNameShipper,
        addressShipper:this.state.addressShipper,taxIdShipper:this.state.taxIdShipper,
        contactNameConsignee:this.state.contactNameConsignee,addressConsignee:this.state.addressConsignee}

      if (!this.state.btnDisable) {
        this.setState({btnDisable:true,btnLoading:true,})
        axios.post(Direccion+`/booking`,{bookingInfo:this.props.ObjBusqueda, data:this.props.datos,user:firebase.auth().currentUser.uid,tipo:this.props.tipo,detalles:detalles})
          .then(res => {
            if(res.data==='OK'){
              this.setState({
                openAlert:true,
                AlertMessage:'we have booked your shipment, see you operations section',
                AlertType:'positive',
              })
              setTimeout(function() {window.location.href=DireccionFront+'/user/Envios';}, 6000);
            }
            else if(res.data==='909'){
              this.setState({
                openAlert:true,
                titleAlert:'Error!',
                AlertMessage:'You cannot book your own rate',
                AlertType:'warning',
              })
            }
            else{
              this.setState({
                openAlert:true,
                AlertMessage:'something went wrong',
                AlertType:'warning',
              })
            }
          })
      }

    }
    else{
      if(firebase.auth().currentUser.emailVerified){
        this.setState({
          ventana:3
        })
      }
      else{
        this.setState({
          verificadoMensaje:true
        })
      }
    }
  }
  onChange=(tipo,e)=>{
    switch (tipo) {
      case 'nombreProducto':
           this.setState({nombreProducto:e.target.value}) ;
        break;
     case 'NombreShipper':
           this.setState({NombreShipper:e.target.value});
           break;
     case 'TelefonoShipper':
             this.setState({TelefonoShipper:e.target.value});
             break;
     case 'EmailShipper':
           this.setState({EmailShipper:e.target.value});
           break;

     case 'NombreConsig':
            this.setState({NombreConsig:e.target.value});
             break;
     case 'TelefonoConsig':
            this.setState({TelefonoConsig:e.target.value});
             break;
     case 'EmailConsig':
            this.setState({EmailConsig:e.target.value});
             break;

    case 'NombreNotificar':
            this.setState({NombreNotificar:e.target.value});
            break;
    case 'TelefonoNotificar':
            this.setState({TelefonoNotificar:e.target.value});
            break;
    case 'EmailNotificar':
            this.setState({EmailNotificar:e.target.value});
            break;

     case 'observaciones':
       this.setState({observacionesContacto:e.target.value});
       break;

    case 'fecha':
      this.setState({fecha:e.target.value});
     break;

     case 'hsCode':
      this.setState({hsCode:e.target.value});
      break;

    case 'contactNameShipper':
      this.setState({contactNameShipper:e.target.value});
      break;
    case 'addressShipper':
        this.setState({addressShipper:e.target.value});
        break;
    case 'taxIdShipper':
          this.setState({taxIdShipper:e.target.value});
          break;
    case 'contactNameConsignee':
            this.setState({contactNameConsignee:e.target.value});
          break;

    case 'addressConsignee':
            this.setState({addressConsignee:e.target.value});
            break;
     default:

    }
  }
  handleCloseAlert=()=>{
    this.setState({
      openAlert:false,
    })
  }
  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = this.state.activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }
  resetAlert = ()=> this.setState({ openAlert:false,titleAlert: '', AlertMessage:'', AlertType:''});


  render(){
    let text=this.props.idioma;
    let icono=this.props.datos.Contenedor==='FCL'?'pallet':'box'
    let medidas='';

    if (this.props.datos.Medidas) {
      let medidas='';
    }
    let activo;
    if(this.state.ventana===1){
      activo=false;
    }
    else{
      if((this.state.nombreProducto&&this.state.NombreShipper&&this.state.TelefonoShipper &&this.state.EmailShipper &&this.state.NombreConsig&&
        this.state.TelefonoConsig&&this.state.EmailConsig&&this.state.NombreNotificar&&this.state.TelefonoNotificar&&this.state.EmailNotificar&&this.state.observacionesContacto&&
       this.state.fecha&&this.state.hsCode&&this.state.contactNameShipper&&this.state.addressShipper&&this.state.taxIdShipper&&this.state.contactNameConsignee&&
     this.state.addressConsignee && this.state.ventana != 1)){
        activo=false;
      }
      else{
        activo=true;
      }
    }
    const { classes } = this.props;
    let transporte = this.props.datos.Transporte==='mar'?'Ocean':this.props.datos.Transporte==='Aereo'?'air':this.props.datos.Transporte==='terrestre'?'land':'';
    let contendorAjustado = this.props.datos.Contenedor != 'Aereo'?this.props.datos.Contenedor:'box/pallets';
    let verificacion=this.state.verificadoMensaje;
    let respuestaCalculador = CalculadorCBM.CalcularCBMTotal(this.props.datos.ArrayUnidades);
    return(

      <li>

        <ItemCard datos={this.props.datos} text={text} show={this.show}/>
        <Modal style={{width:this.state.ventana===3?'80%':'auto'}} dimmer={this.state.dimmer} open={this.state.open} onClose={this.close}>
          {this.state.ventana===1?
            <div style={{padding:'5vh'}}>
              <AlertSnack openAlert={this.state.openAlert} titleAlert={this.state.titleAlert} resetAlert={this.resetAlert} AlertMessage={this.state.AlertMessage} AlertType={this.state.AlertType}/>
              <AlertSnack openAlert={verificacion} titleAlert={'Error'} resetAlert={this.resetAlert} AlertMessage={'Your account is not verified yet. Please check your email and verify your account to join the Experience and benefits of CEIF'} AlertType={'warning'}/>

              <Modal.Content image>
                      <Header as='h2' style={{width:'60%',marginLeft:'auto',marginRight:'auto'}}>
                        <Icon name='tag' />
                        <Header.Content>
                          {this.props.datos.Origen.toUpperCase()}-{this.props.datos.Destino.toUpperCase()}
                          <Header.Subheader>{text.CardRutaPublica.expiracion} {' '+this.props.datos.Expiracion}</Header.Subheader>
                        </Header.Content>
                      </Header>
                      <Divider/>
              </Modal.Content>

              <Modal.Content>
                    <Grid columns={2} stackable textAlign='center'>
                      <Grid.Row verticalAlign='middle'>
                        <Grid.Column style={{width:'60%'}}>
                        <Accordion style={{width:'100%', margin:'5px'}} styled>
                          <Accordion.Title active={this.state.activeIndex === 0} index={0} onClick={this.handleClick}>
                            <Icon name='dropdown' />
                            Shipping information
                          </Accordion.Title>
                          <Accordion.Content active={this.state.activeIndex === 0}>
                            <div className='StatusShipping'>
                              <div className='iconShipping'>
                                <Icon name='boxes' />
                                <p>{this.props.datos.Origen.toUpperCase()}</p>
                              </div>
                              <div className='lineShipping' ></div>
                              <div className='iconShipping'>
                                <Icon name='shipping fast' />
                                <p>{this.props.datos.Trafico}</p>
                              </div>
                              <div className='lineShipping' ></div>
                              <div className='iconShipping'>
                                <Icon name='dolly flatbed' />
                                <p>{this.props.datos.Destino.toUpperCase()}</p>
                              </div>
                            </div>
                            <div className="IcotermContent">
                              {text.CardRutaPublica.transporte} {' '+transporte} : {text.CardRutaPublica.tipo}{' '+contendorAjustado}

                            </div>
                          </Accordion.Content>
                          <Accordion.Title active={this.state.activeIndex === 1} index={1} onClick={this.handleClick}>
                            <Icon name='dropdown' />
                            Content information
                          </Accordion.Title>
                          <Accordion.Content active={this.state.activeIndex === 1}>
                          <div>
                            {this.props.datos.Contenedor === 'FCL' || this.props.datos.Contenedor ==='FCL-Truck' ||this.props.datos.Contenedor === 'Wagon'||this.props.datos.Contenedor === 'FTL-Truck'||this.props.datos.Contenedor === 'FCL-Ferro'?
                            <div className="measurementContent">
                                <div className='itemMeasu'>
                                  <Header style={{margin:'0px auto',textAlign:'center'}} size='medium'>Quantity</Header>

                                      <div className="LCLContent">
                                        <p>{this.props.datos.Cantidad}</p>
                                        <p>X</p>
                                          {parseInt(this.props.datos.Cantidad)>1?<Icon name='box' />:<Icon name='boxes' />}
                                      </div>
                                </div>
                                <div className='itemMeasu'>
                                  <Header style={{margin:'0px auto',textAlign:'center'}} size='medium'>Measurement (cm)</Header>
                                  <div className="contenedorMedidas">
                                      <div className="LCLContent">
                                        <p>{this.props.datos.containerSize?this.props.datos.containerSize:this.props.datos.Medidas}  {this.props.datos.containerType?this.props.datos.containerType:'none'}</p>
                                      </div>
                                  </div>
                                </div>
                                <div className='itemMeasu'>
                                  <Header style={{margin:'0px auto',textAlign:'center'}} size='medium'>CBMs</Header>
                                  <div className="LCLContent">
                                    <p>{this.props.datos.CBM || this.props.datos.CBM != "none"|| this.props.datos.CBM != "NaN"|| this.props.datos.CBM != NaN ?parseFloat(this.props.datos.CBM).toFixed(2):'none'}</p>
                                  </div>
                                </div>
                                <div className='itemMeasu'>
                                  <Header style={{margin:'0px auto',textAlign:'center'}} size='medium'>Weight (kg)</Header>
                                  <div className="LCLContent">
                                    <p>{this.props.datos.Peso?parseInt(this.props.datos.Peso):'none'}</p>
                                  </div>
                                </div>
                              </div>
                              :
                              <div className='TablaMedidas'>
                                  <div className='HeadersContent'>
                                    <div className='HeaderItem'>
                                      <h4>Quantity</h4>
                                    </div>
                                    <div className='HeaderItem'>
                                      <h4>Measurement (cm)</h4>
                                    </div>
                                    <div className='HeaderItem'>
                                      <h4>CBMs</h4>

                                    </div>
                                    <div className='HeaderItem'>
                                      <h4>Weight (kg)</h4>
                                    </div>
                                  </div>
                                  <div className='MedidasContent'>

                                    {this.props.datos.ArrayUnidades.map((it,index)=>{
                                      return(
                                        <div className='itemCellRow'>
                                          <div className='itemCell'>

                                            <p>{it.Unidades}</p>
                                          </div>
                                          <div className='itemCell'>
                                            <p>{it.Lenght} x {it.Width} x {it.Height} </p>
                                          </div>
                                          <div className='itemCell'>
                                              <p>{parseFloat(it.CBM).toFixed(2)}</p>
                                          </div>
                                          <div className='itemCell'>
                                              <p>{it.Weight?parseInt(it.Weight):'none'}</p>
                                          </div>
                                        </div>
                                      )
                                    })
                                    }
                                    <div className='itemCellRow'>
                                      <div className='itemCell'>
                                        <p style={{textAlign:'left'}} ><span>Total</span> {respuestaCalculador.UnidadesTotal}</p>
                                      </div>
                                      <div className='itemCell'>
                                        <p style={{textAlign:'left'}} >none</p>
                                      </div>
                                      <div className='itemCell'>
                                        <p style={{textAlign:'left'}} ><span>Total</span> {respuestaCalculador.CBMTotal}</p>
                                      </div>
                                      <div className='itemCell'>
                                        <p style={{textAlign:'left'}} ><span>Total</span> {respuestaCalculador.PesoTotal}</p>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            }
                          </div>

                          </Accordion.Content>
                          <Accordion.Title active={this.state.activeIndex === 2} index={2} onClick={this.handleClick}>
                            <Icon name='dropdown' />
                            Price information
                          </Accordion.Title>
                          <Accordion.Content active={this.state.activeIndex === 2}>
                              <div className="priceShow">
                                {parseFloat(this.props.datos.Precio).toFixed(2)} USD
                              </div>
                          </Accordion.Content>
                        </Accordion>

                        </Grid.Column>

                        <Grid.Column style={{width:'40%'}}>
                            <Image floated='right'style={{marginRight:"10%"}} size='medium' src='../imagenes/delivery.png' />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
              </Modal.Content>
            </div>
          :this.state.ventana===2?
            <div>
              <Login  authed={false}/>
            </div>
          :this.state.ventana===3?
            <FormularioDetallesBooking state={this.state} datos={this.props.datos} onChange={this.onChange} fecha={this.state.fecha} type={1}/>
          :
          <div></div>

          }
          <Step.Group attached='bottom'>
            <Step active={this.state.ventana===1}>
              <Icon name='truck' />
              <Step.Content>
                <Step.Title>Shipment details</Step.Title>
                <Step.Description>Shipping options</Step.Description>
              </Step.Content>
            </Step>

            <Step active={this.state.ventana===2}>
              <Icon name='address book' />
              <Step.Content>
                <Step.Title>Contact details</Step.Title>
                <Step.Description>Contact info  </Step.Description>
              </Step.Content>
            </Step>
          </Step.Group>

          <Modal.Actions>
            <Button color='red' onClick={this.cancelar}>
              {text.botones.cancelar}
            </Button>
            {firebase.auth().currentUser?
              <Button
                positive
                disabled={activo}
                icon='checkmark'
                labelPosition='right'
                content={text.botones.agendar}
                onClick={this.pasoAgendar}
              />
              :
              <Button
                positive
                icon='user'
                labelPosition='right'
                content={text.botones.singup}
                onClick={()=>this.setState({ventana:2})}
              />
            }
          </Modal.Actions>
        </Modal>
        <AlertSnack openAlert={this.state.openAlert} resetAlert={this.resetAlert} titleAlert={this.state.titleAlert}  AlertMessage={this.state.AlertMessage} AlertType={this.state.AlertType}/>

      </li>
    )
  }
}


class CardRutaPublicaAnidada extends Component{

  constructor(){
    super();
    var date = new Date();
    let month = date.getMonth()+1;
    let day = date.getDate();
      if (month <10) {
          month = "0"+month;
        }
      if (day<10) {
        day = "0"+day;
      }

    this.state={
      open: false,
      dimmer:true,
      ventana:1,
      fecha:date.getFullYear()+'-'+month +'-'+day,
      nombreProducto:'',
      NombreShipper:'',
      TelefonoShipper:'',
      EmailShipper:'',

      NombreConsig:'',
      TelefonoConsig:'',
      EmailConsig:'',

      NombreNotificar:'',
      TelefonoNotificar:'',
      EmailNotificar:'',
      observacionesContacto:'',
      openAlert:false,
      AlertMessage:'',
      AlertType:'',
      activeIndex: 1,
      btnDisable:false,
      btnLoading:false,

    }
  }

  show = dimmer => () => this.setState({ dimmer, open: true })

  close = () => this.setState({ open: false })
  cancelar = () => this.setState({ open: false,nombreProducto:'',NombreShipper:'',TelefonoShipper:'',EmailShipper:'',NombreConsig:'',TelefonoConsig:'',EmailConsig:'',NombreNotificar:'',TelefonoNotificar:'',EmailNotificar:'',observacionesContacto:'', })

  pasoAgendar=()=>{
    let self=this;
    if(this.state.ventana===3){
      this.setState({drimmerActivo:false,btnDisable:true,btnLoading:true});
      var detalles={
        nombreProducto:this.state.nombreProducto,
        fecha:this.state.fecha,
        NombreShipper:this.state.NombreShipper,
        TelefonoShipper:this.state.TelefonoShipper,
        EmailShipper:this.state.EmailShipper,
        NombreConsig:this.state.NombreConsig,
        TelefonoConsig:this.state.TelefonoConsig,
        EmailConsig:this.state.EmailConsig,
        NombreNotificar:this.state.NombreNotificar,
        TelefonoNotificar:this.state.TelefonoNotificar,
        EmailNotificar:this.state.EmailNotificar,
        observaciones:this.state.observacionesContacto,
         hsCode:this.state.hsCode,
         contactNameShipper:this.state.contactNameShipper,
        addressShipper:this.state.addressShipper,
        taxIdShipper:this.state.taxIdShipper,
        contactNameConsignee:this.state.contactNameConsignee,
        addressConsignee:this.state.addressConsignee}
    axios.post(Direccion+`/booking`,{bookingInfo:this.props.ObjBusqueda,data:this.props.datos,user:firebase.auth().currentUser.uid,tipo:this.props.tipo,detalles:detalles})
      .then(res => {
        console.log(res.data);
        if(res.data==='OK'){
          alert('we have booked your shipment, we will revert with confirmation and acceptance');
          window.location.href=DireccionFront+'/user/Envios';
          self.setState({drimmerActivo:false});
        }
        else if(res.data==='909'){
          alert('you cannot book your own rate');
        }
        else  {
          alert('something was wrong');
        }
      })
  }
    else{
      this.setState({
        ventana:3
      })
    }
  }
  pasos(){
    this.setState({
      ventana:1
    })
  }


  onChange=(tipo,e)=>{
    switch (tipo) {
      case 'nombreProducto':
           this.setState({nombreProducto:e.target.value}) ;
        break;
     case 'NombreShipper':
           this.setState({NombreShipper:e.target.value});
           break;
     case 'TelefonoShipper':
             this.setState({TelefonoShipper:e.target.value});
             break;
     case 'EmailShipper':
           this.setState({EmailShipper:e.target.value});
           break;

     case 'NombreConsig':
            this.setState({NombreConsig:e.target.value});
             break;
     case 'TelefonoConsig':
            this.setState({TelefonoConsig:e.target.value});
             break;
     case 'EmailConsig':
            this.setState({EmailConsig:e.target.value});
             break;

    case 'NombreNotificar':
            this.setState({NombreNotificar:e.target.value});
            break;
    case 'TelefonoNotificar':
            this.setState({TelefonoNotificar:e.target.value});
            break;
    case 'EmailNotificar':
            this.setState({EmailNotificar:e.target.value});
            break;

     case 'observaciones':
       this.setState({observacionesContacto:e.target.value});
       break;

    case 'fecha':
      this.setState({fecha:e.target.value});
     break;

     case 'hsCode':
      this.setState({hsCode:e.target.value});
      break;

    case 'contactNameShipper':
      this.setState({contactNameShipper:e.target.value});
      break;
    case 'addressShipper':
        this.setState({addressShipper:e.target.value});
        break;
    case 'taxIdShipper':
          this.setState({taxIdShipper:e.target.value});
          break;
    case 'contactNameConsignee':
            this.setState({contactNameConsignee:e.target.value});
          break;

    case 'addressConsignee':
            this.setState({addressConsignee:e.target.value});
            break;
     default:

    }
  }


  render(){
    let text=this.props.idioma;
    let icono=this.props.datos.origen.Contenedor==='FCL'?'pallet':'box'
    let icono2=this.props.datos.destino.Contenedor==='FCL'?'pallet':'box'
    let medidas = this.props.datos.origen.medidasAlto +" x "+this.props.datos.origen.medidasAncho+" x "+this.props.datos.origen.medidasAlto;
    let medidas2 = this.props.datos.destino.medidasAlto +" x "+this.props.datos.destino.medidasAncho+" x "+this.props.datos.destino.medidasAlto;

    let activo;
    if(this.state.ventana===1){
      activo=false;
    }
    else{
      if((!this.state.nombreProducto&&!this.state.NombreShipper&&!this.state.TelefonoShipper &&!this.state.EmailShipper &&!this.state.NombreConsig&&
        !this.state.TelefonoConsig&&!this.state.EmailConsig&&!this.state.NombreNotificar&&!this.state.TelefonoNotificar&&!this.state.EmailNotificar&&!this.state.observacionesContacto&&
       !this.state.fecha&&!this.state.hsCode&&!this.state.contactNameShipper&&!this.state.addressShipper&&!this.state.taxIdShipper&&!this.state.contactNameConsignee&&
     !this.state.addressConsignee && this.state.ventana != 1) || this.state.btnDisable){
        activo=true;
      }
      else{
        activo=false;
      }
    }
    return(

      <div style={{width:'90%',maxWidth:'80vh',paddingTop:'2vh',padding:'4vh'}}>

        <Card color='grey' fluid >
          <Card.Content>
          <Label as='a' tag color='red' floating> {this.props.it}</Label>
           <Image floated='right' style={{marginRight:"10%"}} size='small' src='../imagenes/work.png' />
            <Card.Header >{this.props.datos.origen.Origen.toUpperCase()}-{this.props.datos.destino.Destino.toUpperCase()}</Card.Header>
            <Card.Meta>{text.CardRutaPublica.expiracion} {' '+this.props.datos.origen.Expiracion+'-'+this.props.datos.destino.Expiracion}</Card.Meta>
            <Card.Description>{text.CardRutaPublica.trafico}{' '+this.props.datos.origen.Destino+'-'+this.props.datos.destino.Origen}</Card.Description>
          </Card.Content>
          <Card.Content>
          <Label  as='a' color='teal' tag>${this.props.datos.costoTotal}USD</Label>

          </Card.Content>
          <Card.Content extra>
            <div className='ui two buttons'>
              <Button onClick={this.show(true)} basic color='black'>
                {text.CardRutaPublica.mas}
              </Button>
            </div>
      </Card.Content>
    </Card>

        <Modal dimmer={this.state.dimmer} open={this.state.open} onClose={this.close}>
        {this.state.ventana===1?
          <div style={{padding:'5vh'}}>
          <Modal.Content image >
                  <Header as='h2' style={{paddingLeft:'25%'}}>
                    <Icon name='tag' />
                    <Header.Content>
                      {this.props.datos.origen.Origen.toUpperCase()}-{this.props.datos.origen.Destino.toUpperCase()}
                      <Header.Subheader>{text.CardRutaPublica.expiracion} {' '+this.props.datos.origen.Expiracion}</Header.Subheader>
                    </Header.Content>
                  </Header>
                  <Divider/>
          </Modal.Content>

          <Modal.Content>
                <Grid columns={2} stackable textAlign='center'>
                  <Grid.Row verticalAlign='middle'>
                    <Grid.Column>
                      <Header size='medium'> {text.CardRutaPublica.transporte} {' '+this.props.datos.origen.Transporte}</Header>
                      <Header size='medium'>{text.CardRutaPublica.tipo}{' '+this.props.datos.origen.Contenedor}</Header>
                      <Header as='h3' style={{paddingLeft:'25%'}}>
                        <Icon name={icono} />
                        <Header.Content>
                          {medidas}
                          <Header.Subheader> {this.props.datos.origen.Contenido}</Header.Subheader>
                        </Header.Content>
                      </Header>

                      <Label style={{fontSize:'3vh'}} as='a' color='teal' tag>${this.props.datos.origen.Precio}USD</Label>

                    </Grid.Column>

                    <Grid.Column>
                        <Image floated='right'style={{marginRight:"10%"}} size='small' src='../imagenes/delivery.png' />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
          </Modal.Content>

          <Modal.Content image>
                  <Header as='h2'  style={{width:'60%',marginLeft:'auto',marginRight:'auto'}}>
                    <Icon name='tag' />
                    <Header.Content>
                      {this.props.datos.destino.Origen.toUpperCase()}-{this.props.datos.destino.Destino.toUpperCase()}
                      <Header.Subheader>{text.CardRutaPublica.expiracion} {' '+this.props.datos.destino.Expiracion}</Header.Subheader>
                    </Header.Content>
                  </Header>
                  <Divider/>
          </Modal.Content>

          <Modal.Content>
                <Grid columns={2} stackable textAlign='center'>
                  <Grid.Row verticalAlign='middle'>
                    <Grid.Column>
                      <Header size='medium'> {text.CardRutaPublica.transporte} {' '+this.props.datos.destino.Transporte}</Header>
                      <Header size='medium'>{text.CardRutaPublica.tipo}{' '+this.props.datos.destino.Contenedor}</Header>

                      <Header as='h3' style={{paddingLeft:'25%'}}>
                        <Icon name={icono2} />
                        <Header.Content>
                          {medidas2}
                          <Header.Subheader> {this.props.datos.destino.Contenido}</Header.Subheader>
                        </Header.Content>
                      </Header>

                      <Label style={{fontSize:'3vh'}} as='a' color='teal' tag>${this.props.datos.destino.Precio}USD</Label>

                    </Grid.Column>

                    <Grid.Column>
                        <Image floated='right'style={{marginRight:"10%"}} size='small' src='../imagenes/delivery.png' />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
          </Modal.Content>
          </div>
          :
          <FormularioDetallesBooking exitoso={this.close} datos={this.props.datos}  onChange={this.onChange} fecha={this.state.fecha}type={2}/>
        }
          <Step.Group attached='bottom'>
          <Step active={this.state.ventana===1} onClick={this.pasos.bind(this)}>
            <Icon name='ship' />
            <Step.Content>
              <Step.Title>Shipment details</Step.Title>
              <Step.Description>Shipping options</Step.Description>
            </Step.Content>
          </Step>

          <Step active={this.state.ventana===2}>
            <Icon name='address book' />
            <Step.Content>
              <Step.Title>Contact details</Step.Title>
              <Step.Description>Contact info</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
          <Modal.Actions>
          <Label style={{fontSize:'3vh'}} as='a' color='orange' tag>${this.props.datos.costoTotal}USD</Label>
            <Button color='black' onClick={this.close}>
              {text.botones.cancelar}
            </Button>
            {firebase.auth().currentUser?
              <Button
                positive
                disabled={activo}
                icon='checkmark'
                labelPosition='right'
                content={text.botones.agendar}
                onClick={this.pasoAgendar}
              />
              :
              <Button
                positive
                disabled={activo}
                icon='checkmark'
                labelPosition='right'
                content={text.botones.singup}
                onClick={()=>this.setState({ventana:2})}
              />
            }

          </Modal.Actions>

        </Modal>
        <Dimmer active={this.state.drimmerActivo} page>
        <Loader>
          <Header as='h2' icon inverted>
            Loading ....
          </Header>
        </Loader>
        </Dimmer>
      </div>
    )
  }
}


class FormularioDetallesBooking extends Component{
  constructor(props){
    super(props);
    this.state = {
      ViewMedidas:false
    }
  }

  UNSAFE_componentWillMount(){
      var origen;
      var destino;
      var precio;
      var datos;

      if(this.props.type===1){
        origen=this.props.datos.Origen;
        destino=this.props.datos.Destino;
        precio=this.props.datos.Precio;
        datos=this.props.datos;
      }
      else{
        origen=this.props.datos.origen.Origen;
        destino=this.props.datos.destino.Destino;
        precio=parseInt(this.props.datos.destino.Precio)+parseInt(this.props.datos.origen.Precio);
        datos=this.props.datos.destino;
      }

      this.setState({
        origen:origen,
        destino:destino,
        precio:precio,
        datos:datos,
      })
  }

   onChange=(tipo,e)=>{
    this.props.onChange(tipo,e);
   }


  render(){
    var datos=this.state.datos;
    var destino=this.state.destino;
    var origen=this.state.origen;
    var precio=this.state.precio;
    let imagen=DeterminarImagen.DeterminarImagen(datos.Contenedor,datos.containerSize,datos.containerType);

    let respuestaCalculador = CalculadorCBM.CalcularCBMTotal(this.props.datos.ArrayUnidades);

    return(
      <div className='PadreContentBooking'>
        <div className='DescriptionContentBooking'>
          <div className='contentImgBookingForm'>
            {datos.Contenedor==='LCL'||datos.Contenedor==='LTL-Truck'||datos.Contenedor==='Aereo'?
              <img src={DireccionFront+'/imagenes/pallet.png'} />
              :
              <img src={DireccionFront+'/imagenes/'+imagen} />
            }
          </div>
          <div className='TablaMedidas'>
            <div  className='MedidasContent'>
              <div className='itemCellRow'>
                <div className='itemCell'>
                  <h4>Mode</h4>
                </div>
                <div className='itemCell'>
                  <p>{datos.Contenedor === 'Aereo'?'Air':datos.Contenedor}</p>
                </div>
              </div>
              <div className='itemCellRow'>
                <div className='itemCell'>
                  <h4>Cargo content</h4>
                </div>
                <div className='itemCell'>
                  <p>{datos.Contenido}</p>
                </div>
              </div>
              <div className='itemCellRow'>
                <div className='itemCell'>
                  <h4>Origin-Destination</h4>
                </div>
                <div className='itemCell'>
                  <p>{origen+'-'+destino}</p>
                </div>
              </div>
              <div className='itemCellRow'>
                <div className='itemCell'>
                  <h4>Insurance</h4>
                </div>
                <div className='itemCell'>
                  <p>{datos.Seguro?' $'+datos.MontoSeguro+'USD':'none' }</p>
                </div>
              </div>
              <div className='itemCellRow'>
                <div className='itemCell'>
                  <h4>Selected rate</h4>
                </div>
                <div className='itemCell'>
                  <p>{'$'+precio+' USD'}</p>
                </div>
              </div>
              <div className='itemCellRow'>
                <div className='itemCell'>
                  <h4>Weight/Meassure</h4>
                </div>
                  {this.props.datos.Contenedor === 'FCL' || this.props.datos.Contenedor ==='FCL-Truck' ||this.props.datos.Contenedor === 'Wagon'?
                  <div className='itemCell'>
                    <p>{this.props.datos.containerSize?this.props.datos.containerSize:' '}  {this.props.datos.containerType?this.props.datos.containerType:'none'}</p>
                  </div>

                  :
                  <div onClick={()=>this.setState({ViewMedidas:!this.state.ViewMedidas})} className='itemCell'>
                    {!this.state.ViewMedidas && this.props.datos.Contenedor === 'LCL'?
                      <p>Click to view all items</p>
                      :
                      <p>Click to hidden all items</p>
                    }
                  </div>
                  }
              </div>

            </div>
          </div>
          {this.props.datos.Contenedor === 'FCL' || this.props.datos.Contenedor ==='FCL-Truck' ||this.props.datos.Contenedor === 'Wagon'?
            <div></div>
            :
            <div  className='MedidasItemTable'>
              <div className='itemCellLarge'>
                <div className='TablaMedidas'>
                    <div className='HeadersContent'>
                      <div className='HeaderItem'>
                        <h4>Quantity</h4>
                      </div>
                      <div className='HeaderItem'>
                        <h4>Measurement (cm)</h4>
                      </div>
                      <div className='HeaderItem'>
                        <h4>CBMs</h4>

                      </div>
                      <div className='HeaderItem'>
                        <h4>Weight (kg)</h4>
                      </div>
                    </div>
                    {this.props.datos.Contenedor === 'LCL' || this.props.datos.Contenedor === 'LTL-Truck' ||this.props.datos.Contenedor === 'Aereo' && this.state.ViewMedidas?
                    <div className='MedidasContent'>

                      {this.props.datos.ArrayUnidades.map((it,index)=>{
                        return(
                          <div className='itemCellRow'>
                            <div className='itemCell'>

                              <p>{it.Unidades}</p>
                            </div>
                            <div className='itemCell'>
                              <p>{it.Lenght} x {it.Width} x {it.Height} </p>
                            </div>
                            <div className='itemCell'>
                                <p>{parseFloat(it.CBM).toFixed(2)}</p>
                            </div>
                            <div className='itemCell'>
                                <p>{it.Weight?parseInt(it.Weight):'none'}</p>
                            </div>
                          </div>
                        )
                      })
                      }
                      <div className='itemCellRow'>
                        <div className='itemCell'>
                          <p style={{textAlign:'left'}} ><span>Total</span> {respuestaCalculador.UnidadesTotal}</p>
                        </div>
                        <div className='itemCell'>
                          <p style={{textAlign:'left'}} >none</p>
                        </div>
                        <div className='itemCell'>
                          <p style={{textAlign:'left'}} ><span>Total</span> {respuestaCalculador.CBMTotal}</p>
                        </div>
                        <div className='itemCell'>
                          <p style={{textAlign:'left'}} ><span>Total</span> {respuestaCalculador.PesoTotal}</p>
                        </div>
                      </div>
                    </div>
                      :
                    <div className='MedidasContent'>
                      <div className='itemCellRow'>
                        <div className='itemCell'>
                          <p style={{textAlign:'left'}} ><span>Total</span> {respuestaCalculador.UnidadesTotal}</p>
                        </div>
                        <div className='itemCell'>
                          <p style={{textAlign:'left'}} >none</p>
                        </div>
                        <div className='itemCell'>
                          <p style={{textAlign:'left'}} ><span>Total</span> {respuestaCalculador.CBMTotal}</p>
                        </div>
                        <div className='itemCell'>
                          <p style={{textAlign:'left'}} ><span>Total</span> {respuestaCalculador.PesoTotal}</p>
                        </div>
                      </div>
                    </div>
                    }

                </div>
              </div>
            </div>
          }

        </div>
        <div className='FormularioContentBooking'>
            <div className='FormularioBookingHeader'>
              <p>
                Booking details
              </p>
            </div>
            <div className='Form groupBooking'>
              <div className='itemInput'>
                <span>Pick-up date</span>
                <input
                  style={{width: '100%'}}
                  type="date"
                  value={this.props.fecha}
                  min={new Date().toISOString().split("T")[0]}
                  onChange={this.onChange.bind(this,'fecha')}
                />
              </div>
              <input  placeholder='Cargo description'  value={this.props.state.nombreProducto} onChange={this.onChange.bind(this,'nombreProducto')}/>

            </div>
            <div className='Form groupBooking simple'>
              <input  placeholder='HS Code'  value={this.props.state.hsCode}  onChange={this.onChange.bind(this,'hsCode')}/>
            </div>
            <div className='FormularioBookingHeader'>
              <p>
                Shipper details
              </p>
            </div>
            <div className='Form groupBooking'>
              <input  placeholder='Company´s name'  value={this.props.state.nombreContacto}  onChange={this.onChange.bind(this,'NombreShipper')}/>
              <input  placeholder='Contact name'  value={this.props.state.contactNameShipper}  onChange={this.onChange.bind(this,'contactNameShipper')}/>
            </div>
            <div className='Form groupBooking'>
                <input  placeholder='Phone No.'  value={this.props.state.telefonoContacto}  onChange={this.onChange.bind(this,'TelefonoShipper')}/>
                <input  placeholder='email'  value={this.props.state.Email}  onChange={this.onChange.bind(this,'EmailShipper')}/>
            </div>
            <div className='Form groupBooking'>

              <input  placeholder='Address'  value={this.props.state.addressShipper}  onChange={this.onChange.bind(this,'addressShipper')}/>
              <input  placeholder='VAT/ TAX ID/ RFC'  value={this.props.state.taxIdShipper}  onChange={this.onChange.bind(this,'taxIdShipper')}/>
            </div>

            <div className='FormularioBookingHeader'>
              <p>
                Consignee Info
              </p>
            </div>
            <div className='Form groupBooking'>
              <input  placeholder='Company´s name'  value={this.props.state.nombreContacto}  onChange={this.onChange.bind(this,'NombreConsig')}/>
              <input  placeholder='Contact name'  value={this.props.state.contactNameConsignee}  onChange={this.onChange.bind(this,'contactNameConsignee')}/>
            </div>
            <div className='Form groupBooking'>
              <input  placeholder='Phone No.'  value={this.props.state.telefonoContacto}  onChange={this.onChange.bind(this,'TelefonoConsig')}/>
              <input  placeholder='Email'  value={this.props.state.Email}  onChange={this.onChange.bind(this,'EmailConsig')}/>
            </div>
            <div className='Form groupBooking'>
              <input  placeholder='Address'  value={this.props.state.addressConsignee}  onChange={this.onChange.bind(this,'addressConsignee')}/>
            </div>

            <div className='FormularioBookingHeader'>
              <p>
                  Notify party
              </p>
            </div>
            <div className='Form groupBooking'>
              <input  placeholder='Company´s name'  value={this.props.state.nombreContacto}  onChange={this.onChange.bind(this,'NombreNotificar')}/>
              <input  placeholder='Email'  value={this.props.state.Email}  onChange={this.onChange.bind(this,'EmailNotificar')}/>

            </div>
            <div className='Form groupBooking simple'>
              <input  placeholder='Phone No.'  value={this.props.state.telefonoContacto}  onChange={this.onChange.bind(this,'TelefonoNotificar')}/>
            </div>
            <textarea placeholder='Additional comments' value={this.props.state.observacionesContacto}  onChange={this.onChange.bind(this,'observaciones')}/>
        </div>

      </div>
      )
    }
  }


function CardRandom(props){
    const [imagenType, setImage] = useState('');

  useEffect(() => {
    let imagen=DeterminarImagen.DeterminarImagen(props.ruta.type,'','');
    setImage(imagen);
  },[])

  return(
    <div  className='itemRutaRandom'>
      <div className='iconType'><img src={DireccionFront+'/imagenes/'+imagenType} /></div>
      <div className='iconCheck'><Icon color='teal' name='check circle'/></div>
      <div className='dataRate'>
        <p>Origin</p>
        <h3>{props.ruta.Origen}</h3>
        <p>Destino</p>
        <h3>{props.ruta.Destino}</h3>
      </div>
      <div className='type'><h3>{props.ruta.type==='Aereo'?'Air':props.ruta.type}</h3></div>
      <div className='rate'>
        <Rating icon='star' defaultRating={5} maxRating={5} />
      </div>
    </div>
  );
};
function ItemCard(props){
  const [imagenSrc,setimagenSrc] = useState('');
  useEffect(()=>{
    if (props.datos.Contenedor === 'FCL') {
      setimagenSrc('fclImage.jpg');
    }else if (props.datos.Contenedor === 'LCL' || props.datos.Contenedor === 'LTL-TruckL') {
      setimagenSrc('lclImage.jpg');
    }else if (props.datos.Contenedor === 'BBulk') {
      setimagenSrc('bbulkImage.jpg');
    }else if (props.datos.Contenedor === 'Aereo') {
      setimagenSrc('aereoImage.jpg');
    }else if (props.datos.Contenedor === 'FCL-Truck') {
      setimagenSrc('truckFclImage.jpg');
    }else if (props.datos.Contenedor === 'FTL-Truck') {
      setimagenSrc('truckFtlImage.jpg');
    }else if (props.datos.Contenedor === 'Wagon' || props.datos.Contenedor === 'FCL-Ferro') {
      setimagenSrc('wagonsImage.jpg');
    }else{
      setimagenSrc('fclImage.jpg');
    }
  },[])
  return(
    <div onClick={props.show(true)} className='CardItemCotizacion'>
      <img src={DireccionFront+'/imagenes/'+imagenSrc} />
      <div className='CardItem-descripcion'>
        <div className='CarItem-descripcion-float'>
          <p>{props.datos.Servicio.substr(0, 20)}</p>
          {props.datos.Contenedor?<p>{props.datos.Contenedor}</p>:''}
          {props.datos.containerType && props.datos.containerSize?<p>{props.datos.containerSize} {props.datos.containerType}</p>:''}
        </div>
        <p className='titulo'>{props.datos.Origen.toUpperCase()}-{props.datos.Destino.toUpperCase()}</p>
        <p className='subtitulo'>{props.text.CardRutaPublica.PaisOrigen}: {' '+props.datos.PaisOrigen}</p>
        <p className='subtitulo'>{props.text.CardRutaPublica.PaisDestino}: {' '+props.datos.PaisDestino}</p>
        <p className='subtitulo'>{props.text.CardRutaPublica.trafico}: {' '+props.datos.Trafico}</p>
        <div className='precioContent'>
          <p className='precio'>${numberWithCommas(parseFloat(props.datos.Precio).toFixed(2))} USD</p>
        </div>
      </div>
    </div>
  )
}
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export default BuscarRuta;

import React, { Component,useState, useEffect } from 'react';
import MenuPublico from '../ComponentesGenerales/Menus/MenuPublico.js';
import Footer from '../ComponentesGenerales/Footer/Footer.js';
import {Icon} from 'semantic-ui-react';
import './LandingStyle.css';
import Header from '../ComponentesGenerales/landing/header-landing.js';
import PublicNavbar from '../ComponentesGenerales/Menus/MenuPublicov2.js';
export default function Faqs (){
  const [plataformActive, setPlataformActive] = useState(0);


  return(
    <div>
      <section className='seccion0'>
      <PublicNavbar/>
      <Header video='./imagenes/LandingPage/video/faqs.mp4' texto1='Need help?'texto2='Frequently Asked Questions.'/>
          <div className='FaqImgContent'>
            <div onClick={()=>setPlataformActive(0)} className={plataformActive == 0?'FaqItemMenu activo':'FaqItemMenu'}><img  src='../imagenes/LandingPage/ceipApp.png'/><p>CEIF App</p></div>
            <div onClick={()=>setPlataformActive(1)} className={plataformActive == 1?'FaqItemMenu activo':'FaqItemMenu'}><img  src='../imagenes/LandingPage/logisticNet.png'/><p>CEIF Logistic network</p></div>
          </div>
          {plataformActive == 0?
            <FaqsCeifApp/>
            :
            <FaqsNetWork/>
          }
      </section>
      <Footer/>
    </div>

  )
}

function FaqsCeifApp(){
  const [itemActivo, setItemActivo] = useState(null);

  return(
    <div className='ItemFaqContent'>
        <div onClick={()=>setItemActivo(0)} className='itemFaqs'><p>What is CEIF Freight App</p><Icon size={20} color='red' name={itemActivo == 0?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==0&&
          <div className='ItemDescripcionFaq'>
            CEIF Freight App is a shipping transportation management platform. It's easy to register and easy to use. Customers love it because it lets them handle all of their rates, quotations, bookings, freights in one place instead of going to a lot of different Freight Forwarders of Carriers.
          </div>
        }
        <div onClick={()=>setItemActivo(1)} className='itemFaqs'><p>How do I renew my subscription?</p><Icon size={20} color='red' name={itemActivo == 1?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==1&&
          <div className='ItemDescripcionFaq'>
             10 days before the expiration date of your membaership, your will receive an email, inviting you to renew your membership. You can pay by wiretransfer or through our CEIF Freight App.
          </div>
        }
        <div onClick={()=>setItemActivo(2)} className='itemFaqs'><p>What type of payment methods do you accept?</p><Icon size={20} color='red' name={itemActivo == 2?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==2&&
          <div className='ItemDescripcionFaq'>
            We accept the following payment methods:
            <br/>
            <br/>
            - Credit and Debit Cards
            <br/>
            <br/>
            - Wire transfers
            <br/>
            <br/>
            - ACH Payments
          </div>
        }
        <div onClick={()=>setItemActivo(3)} className='itemFaqs'><p>What Makes CEIF Freight App Special?</p><Icon size={20} color='red' name={itemActivo == 3?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==3&&
          <div className='ItemDescripcionFaq'>
            We’re a simple web based solution for a traditionally complicated and expensive business necessity. From the ground up, we’re built by shippers with the goal of delivering a TMS solution that provides ease of use while bringing savings of up to 10 – 30% on freight.
          </div>
        }
        <div onClick={()=>setItemActivo(4)} className='itemFaqs'><p>Who can use CEIF Freight App?</p><Icon size={20} color='red' name={itemActivo == 4?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==4&&
          <div className='ItemDescripcionFaq'>
            CEIF Freight App Is the completely FREE Tool for Medium & Small Freight Forwarders and any Importer & Exporter that need to quote logistic services efficiently and instantaneously comparing the best rates in the market and managing your shipments in one place.
          </div>
        }
        <div onClick={()=>setItemActivo(5)} className='itemFaqs'><p>Will CEIF share my rates with anyone? Is my info secure?</p><Icon size={20} color='red' name={itemActivo == 5?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==5&&
          <div className='ItemDescripcionFaq'>
            We are a completely neutral platform, and under no circumstances will we share your information or pricing with any other party. We strive to empower the shipper to take control of their Freight Network, not the other way around.
          </div>
        }
        <div onClick={()=>setItemActivo(6)} className='itemFaqs'><p>Do you support volume LTL, partial truckloads, full truckloads, flatbed or intermodal?</p><Icon size={20} color='red' name={itemActivo == 6?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==6&&
          <div className='ItemDescripcionFaq'>
            We do. We allow shippers to get spot quotes for these types of shipments from their multiple carriers and shippers reps as well as manage the execution of these shipments on CEIF Freight App
          </div>
        }
        <div onClick={()=>setItemActivo(7)} className='itemFaqs'><p>Am I locked into a contract if I sign up?</p><Icon size={20} color='red' name={itemActivo == 7?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==7&&
          <div className='ItemDescripcionFaq'>
            Am I locked into a contract if I sign up?
          </div>
        }
        <div onClick={()=>setItemActivo(8)} className='itemFaqs'><p>How long does it take to set up my account?</p><Icon size={20} color='red' name={itemActivo == 8?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==8&&
          <div className='ItemDescripcionFaq'>
            You can set up your account in under one minute and enjoy the benefits of CEIF Freight App immediately!
          </div>
        }
        <div onClick={()=>setItemActivo(9)} className='itemFaqs'><p>Can I upload rate sheets provided by my carriers?</p><Icon size={20} color='red' name={itemActivo == 9?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==9&&
          <div className='ItemDescripcionFaq'>
            Sure you can! You only need to use our formats and follow the instructions. You can also upload your trates manually.  You will see the rates when you use our Rates Search Engineor get a rate quote.
          </div>
        }
        <div onClick={()=>setItemActivo(10)} className='itemFaqs'><p>Can I process a shipment directly from CEIF Freight App?</p><Icon size={20} color='red' name={itemActivo == 10?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==10&&
          <div className='ItemDescripcionFaq'>
            Yes, you can process a shipment directly from CEIF Freight App. Once processed, you will be able to manage documents, Confirmation, Payments, etc.
          </div>
        }
        <div onClick={()=>setItemActivo(11)} className='itemFaqs'><p>Is there any tutorial for the CEIF Platform?</p><Icon size={20} color='red' name={itemActivo == 11?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==11&&
          <div className='ItemDescripcionFaq'>
            Yes, you can go to the "Tutorials" section on the main menu of this web site. There is one tutorial for Users and other tutorial for Freight Forwarders.
          </div>
        }
        <div onClick={()=>setItemActivo(12)} className='itemFaqs'><p>How do I get Rate or Quotations from CEIF Freight App?</p><Icon size={20} color='red' name={itemActivo == 12?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==12&&
          <div className='ItemDescripcionFaq'>
            All certified Members can interact with other Network's Members to obtain quotations. We also offer you the option to get instant rates by using our exclusive Tool (CEIF Freight App).
          </div>
        }
    </div>
  )
}

function FaqsNetWork(){
  const [itemActivo, setItemActivo] = useState(null);

  return(
    <div className='ItemFaqContent'>
        <div onClick={()=>setItemActivo(0)} className='itemFaqs'><p>What is a Logistics Network?</p><Icon size={20} color='red' name={itemActivo == 0?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==0&&
          <div className='ItemDescripcionFaq'>
            A Logistics Network is an organization integrated by a group of Freight Forwarders interested in increase productivity and explore different ways of approaching common business goals.
          </div>
        }
        <div onClick={()=>setItemActivo(1)} className='itemFaqs'><p> Who can Join CEIF Logistics Network?</p><Icon size={20} color='red' name={itemActivo == 1?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==1&&
          <div className='ItemDescripcionFaq'>
            Any Small or Medium Freight Forwarder who can prove at least 3 years working with overseas partners. We maintain very high standards to ensure that all of our members are reputable, reliable and experienced freight forwarding firms. We conduct an in-depth review of all applicants to ensure the company meets all of our standards.
          </div>
        }
        <div onClick={()=>setItemActivo(2)} className='itemFaqs'><p> What is CEIF Freight App?</p><Icon size={20} color='red' name={itemActivo == 2?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==2&&
          <div className='ItemDescripcionFaq'>
            CEIF Freight App is our electronic platform, where our Family Members can interact 1 on 1 to improve their operative processes.
          </div>
        }
        <div onClick={()=>setItemActivo(3)} className='itemFaqs'><p> Does CEIF Logistics Network offers any Financial Protection?</p><Icon size={20} color='red' name={itemActivo == 3?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==3&&
          <div className='ItemDescripcionFaq'>
            CEIF Logistics Network's family members can be protected against any fraudulent operation trough our payment platform and / or our Financial Protection Plan.
          </div>
        }
        <div onClick={()=>setItemActivo(4)} className='itemFaqs'><p> Does CEIF Logistics Network offers a Free Membership?</p><Icon size={20} color='red' name={itemActivo == 4?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==4&&
          <div className='ItemDescripcionFaq'>
            CEIF Logistics Network provides the freight forwarders with options to sign up for free or paid. The facility for free registrants is an access of company profile by shippers and facilities for paid registrants are the verification of company information and priority to obtain access to the Family Members Directory or the CEIF Freight App.
          </div>
        }
        <div onClick={()=>setItemActivo(5)} className='itemFaqs'><p> How do I get Rate or Quotations from CEIF Logistics Network?</p><Icon size={20} color='red' name={itemActivo == 5?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==5&&
          <div className='ItemDescripcionFaq'>
            All certified Members can interact with other Network's Members to obtain quotations. We also offer you the option to get instant rates by using our exclusive Tool (CEIF Freight App).
          </div>
        }
        <div onClick={()=>setItemActivo(6)} className='itemFaqs'><p> Is there any tutorial for the CEIF Platform?</p><Icon size={20} color='red' name={itemActivo == 6?'chevron circle up':'chevron circle down'}/></div>
        {itemActivo ==6&&
          <div className='ItemDescripcionFaq'>
            Yes, you can go to the "Tutorials" section on the main menu of this web site. There is one tutorial for Users and other tutorial for Freight Forwarders.

          </div>
        }
    </div>
  )
}

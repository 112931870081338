import React, { Component } from 'react';
import axios from 'axios';
import FinalizadoProceso from '../scripts/filtrarProcesoFinalizados.js';

import {Direccion} from '../../strings/peticiones.js';
import { Menu, Segment,Divider,Image,Item,Header,Icon,Modal,Rating,Grid,Card,Button,Label } from 'semantic-ui-react'
import BarraLateral from './sideBarAdmin.js';
import firebase from 'firebase'
import Particles from 'react-particles-js';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import './styleAdmin/pagosAdmin.css';

class pagosAdmin extends Component{
  constructor(){
    super()

  }


  render(){
    return(

      <div>
      <BarraLateral/>
      <RutasDetalle/>
      </div>
    )
  }
}



class RutasDetalle extends Component{
  constructor(){
    super();
      var date = new Date();
    let month = date.getMonth()+1;
    let day = date.getDate();
      if (month <10) {
          month = "0"+month;
        }
      if (day<10) {
        day = "0"+day;
      }
    this.state={
      type:0,
      selectedDate: date,
      fechaFormulario1:'2019-01-01',
      fechaFormulario2:date.getFullYear()+'-'+month +'-'+day,
      pagados:[],
      proceso:[],
      recibos:[],
      datoSeleccionado:{},
      datosBancarios:[],
      depositos:0,
      retiros:0,
      totalCuenta:0,
      retirosPendientes:0,
      openConfirmPayment:false,
    }
     this.getEnvios();
     this.getRecibos();
  }

  UNSAFE_componentWillMount(){
    let self =this;

  }

   getEnvios(){
    try{
      let datos;
      let self=this;
      let pagos=[];
      var valoresGraph;
      var date = new Date();
      let month = date.getMonth()+1;
      let year=date.getFullYear();

      axios.post(Direccion+`/tomarPagosAdmin`,{idUser:firebase.auth().currentUser.uid,inicio:this.state.fechaFormulario1,fin:this.state.fechaFormulario2})
        .then(res => {
          datos=res.data;
          console.log(datos);

            pagos=FinalizadoProceso.PagosAdmin(datos);
            let cuentaDepositos = this.getAmountTotal(pagos.terminados);
            let RetirosPendientes = this.getAmountPendientesRetiros(pagos.proceso);
            console.log(datos);
            console.log(RetirosPendientes);

          self.setState({
            pagados:pagos.terminados,
            proceso:pagos.proceso,
            depositos:cuentaDepositos.Depositos,
            retiros:cuentaDepositos.Retiros,
            totalCuenta:(parseFloat(cuentaDepositos.Depositos)-parseFloat(cuentaDepositos.Retiros)).toFixed(2),
            retirosPendientes:RetirosPendientes,
          })
        })
    }catch(e){
      console.log(e);
    }
  }

  getAmountTotal(datos){
    let Depositos = 0.00;
    let Retiros = 0.00;
    datos.forEach(function(it){

        if (it.PagoType === 'retiro') {

          Depositos += parseFloat(it.Amount);
        }else if(it.PagoType === 'abono'){
          Retiros +=parseFloat(it.Amount);
        }
    })
    return ({Depositos:Depositos.toFixed(2),Retiros:Retiros.toFixed(2) });
  }

  getAmountPendientesRetiros(datos){
    let Retiros = 0;
    datos.forEach(function(it){
          Retiros += parseFloat(it.Amount);
    })
    return (Retiros.toFixed(2));
  }

  getRecibos(){
    let self=this;
    axios.post(Direccion+`/tomarRecibosPagosAdmin`,{data:0})
    .then(res => {
      if (res.data) {
          self.setState({
            recibos:res.data,
          })
        }
    })
  }


  handleChangeTab=(event, type)=>{
    this.setState({ type });
  };

  handleDateChange=(date)=>{
      let fecha=date.target.value;
       this.setState({
         fechaFormulario1:fecha
       })
     }

  handleDateChange2=(date)=>{
       let fecha=date.target.value;
        this.setState({
          fechaFormulario2:fecha
        })
    }

    CardRigth=(datos)=>{
      console.log(datos);
        this.setState({
          datoSeleccionado:datos.datos,
          datosBancarios:datos.datosBancarios
        })
    }

    changeStatePago=()=>{
      let self=this;
      console.log(this.state.datoSeleccionado);
      axios.post(Direccion+`/updatePagoAdmin`,{idVendedor:this.state.datoSeleccionado.idVendedor,idTransaccion:this.state.datoSeleccionado.key,idEnvio:this.state.datoSeleccionado.idRecibo,TypePago:this.state.datoSeleccionado.PagoOrigen})
        .then(res => {
          console.log(res.data);
          if (res.data === 'OK') {
            this.getEnvios();
            this.setState({
            openConfirmPayment:false,
             datoSeleccionado:{}
            })
          }
          else if (res.data === '101') {
            this.getEnvios();
            this.setState({
              openConfirmPayment:false,

            })
          }
        })

    }
    AceptarDepositoVendedor=()=>{
        this.setState({
          openConfirmPayment:!this.state.openConfirmPayment,
        })
      }

  render(){

    var PrecioSNComision = (parseFloat(this.state.datoSeleccionado.Amount) - parseFloat(this.state.datoSeleccionado.Comision)).toFixed(2);

    return(
      <div className='header-rutas'>
        <div style={{position:'relative',height:'200px'}}>
          <div  className='headerBanner'>
            <i class="material-icons" style={{ fontSize: '2em' }}>language </i>
            Pagos
          </div>
        <div style={{position:'relative',height:'200px'}}>

          <Particles style={{backgroundImage:'url(../imagenes/head5.jpg)', backgroundSize:'cover',backgroundPosition:'center', position:'absolute',height:'500px'}}
               params={{
                 "particles": {
                     "number": {
                         "value": 80
                     },
                     "size": {
                         "value": 2
                     }
                 },
                 "interactivity": {
                     "events": {
                         "onhover": {
                             "enable": true,
                             "mode": "repulse"
                         }
                     }
                 }
             }} />

        </div>

      </div>
         <div style={{display:'inline-flex', width:'100%',justifyContent:'center'}}>
          <div style={{display:'block',textAlign: 'center',margin: '0px 40px'}}>
            <p>Depositos</p>
            <h1>{this.state.depositos}</h1>
          </div>
          <div style={{display:'block',textAlign: 'center',margin: '0px 40px'}}>
            <p>Retiros</p>
            <h1>{this.state.retiros}</h1>
          </div>
          <div style={{display:'block',textAlign: 'center',margin: '0px 40px'}}>
            <h1 style={{position: 'relative',top: '50%'}}>=</h1>
          </div>
          <div style={{display:'block',textAlign: 'center',margin: '0px 40px'}}>
            <p>Total en cuenta</p>
            <h1>{this.state.totalCuenta}</h1>

          </div>
         </div>

        <div className="div-fechas" style={{paddingBottom:'25px'}}>

          <ListItemText primary="From" style={{position:'relative'}}/>

          <TextField
            id="date"
            style={{backgroundColor:'white'}}
            onChange={this.handleDateChange}
            defaultValue={this.state.fechaFormulario1}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <ListItemText primary="To"  style={{position:'relative',color:'white'}}/>

          <TextField
            id="date"

            style={{backgroundColor:'white'}}
            type="date"
            defaultValue={this.state.fechaFormulario2}
            onChange={this.handleDateChange2}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div id="botonFiltrar">
            <Button  style={{marginLeft:'9%',position:'relative',paddingBottom:'10px',backgroundColor:'#e8850a'}}secondary  >Search</Button>
          </div>
        </div>
         <Grid columns={2} padded='vertically'>
          <Grid.Column width={9}>
           <Tabs value={this.state.type} onChange={this.handleChangeTab}variant="fullWidth"  indicatorColor="secondary" textColor="primary">
              <Tab icon={<i class="material-icons">compare_arrows</i>} label="Due payments" ></Tab>
              <Tab icon ={<i class="material-icons">done_outline</i>} label="Paid invoices"></Tab>
                <Tab icon ={this.state.recibos.length>0?<div className='floatLabel'>{this.state.recibos.length}</div>:<div><i class="material-icons">photo</i></div>} label="Payment vouchers"></Tab>
            </Tabs>

            {this.state.type===0?
              <div>
              {
                  this.state.proceso.map((it,key)=>{
                    return(<CardPagosUser selectCard={this.CardRigth}  datos={it}/>)
                  })

                }
              </div>
              :
              <div></div>
            }

            {this.state.type===1?
              <div>
              {
                this.state.pagados.map((it,key)=>{
                  return(<CardPagosUser selectCard={this.CardRigth} datos={it}/>)
                })

              }
              </div>
              :
              <div></div>
            }
             {this.state.type===2?
              <div>
              {
                this.state.recibos.map((it,key)=>{
                  return(<CardRecibos selectCard={this.CardModal} datos={it}/>)
                })
              }
              </div>
              :
              <div></div>
            }
          </Grid.Column>
          <Grid.Column width={7} style={{marginTop:'1vh'}}>

          {this.state.datoSeleccionado.key?
             <Card  id='CardDetallesCard'>
                <Card.Content>
                     {this.state.datoSeleccionado.Status === 'Finalizado'?
                        <div className='IconoRightDetalles'>
                          <Icon.Group size='big'>
                              <i class="material-icons" style={{ fontSize: '1em', color:'#10B5AF' }}>check_circle </i>
                          </Icon.Group>
                          <h4 style={{marginRight:'2%',marginTop:'-2%',color:'teal'}}>Completed</h4>

                          </div>:
                          <div className='IconoRightDetalles'>
                             <i class="material-icons" style={{ fontSize: '2em' }}>credit_card </i>
                              <h4 style={{marginTop:'-2%', color:'grey'}}>Pending</h4>

                        </div>
                      }
                    <div>
                      <div style={{ width:"100%",display:'inline-flex' }}>
                        <div id='inputIndividual'>
                          <Card.Meta>Transaction</Card.Meta>
                          <Card.Header>{this.state.datoSeleccionado.key}</Card.Header><br/>
                        </div>
                        <div id='inputIndividual'>
                          <Card.Meta>Product ID</Card.Meta>
                          <Card.Header>{this.state.datoSeleccionado.idRecibo}</Card.Header><br/>
                        </div>
                        <div id='inputIndividual'>
                          <Card.Meta>Date payment</Card.Meta>
                          <Card.Header>{this.state.datoSeleccionado.dateTime}</Card.Header><br/>
                        </div>

                      </div>
                      <div style={{ width:"100%",display:'inline-flex'}}>
                      <div id='inputIndividual'>
                       <Card.Meta>Seller</Card.Meta>
                       <Card.Header>{this.state.datoSeleccionado.idVendedor}</Card.Header><br/>
                     </div>
                        <div id='inputIndividual'>
                          <Card.Meta>Pago type</Card.Meta>
                          <Card.Header>{this.state.datoSeleccionado.PagoOrigen} </Card.Header><br/>
                        </div>
                        <div id='inputIndividual'>
                          <Card.Meta>User type</Card.Meta>
                          <Card.Header>{this.state.datoSeleccionado.tipoUsuario} </Card.Header><br/>
                        </div>
                        <div id='inputIndividual'>
                          <Card.Meta>Bank</Card.Meta>
                          <Card.Header>{this.state.datosBancarios.BancoName}</Card.Header><br/>
                        </div>
                      </div>

                      <div style={{width:"100%",display:'inline-flex'}}>
                        <div id='inputIndividual'>
                          <Card.Meta>Bank address</Card.Meta>
                          <Card.Header>{this.state.datosBancarios.DireccionBanco}</Card.Header><br/>
                        </div>
                        <div id='inputIndividual'>
                          <Card.Meta>Holder name</Card.Meta>
                          <Card.Header>{this.state.datosBancarios.NombreTitular}</Card.Header><br/>
                        </div>
                        <div id='inputIndividual'>
                          <Card.Meta>Holder's address</Card.Meta>
                          <Card.Header>{this.state.datosBancarios.DireccionTitular}</Card.Header><br/>
                        </div>
                      </div>
                      <div style={{width:"100%",display:'inline-flex'}}>
                       <div id='inputIndividual'>
                          <Card.Meta>Holder's Country </Card.Meta>
                          <Card.Header>{this.state.datosBancarios.BancoName}</Card.Header><br/>
                        </div>
                        <div id='inputIndividual'>
                          <Card.Meta>SWIFT code</Card.Meta>
                          <Card.Header>{this.state.datosBancarios.CodigoSwift}</Card.Header><br/>
                        </div>
                        <div id='inputIndividual'>
                          <Card.Meta>Routing code</Card.Meta>
                          <Card.Header>{this.state.datosBancarios.Routing}</Card.Header><br/>
                        </div>
                        <div id='inputIndividual'>
                          <Card.Meta>Iban code</Card.Meta>
                          <Card.Header>{this.state.datosBancarios.Iban}</Card.Header><br/>
                        </div>
                      </div>
                    </div>
                    <div style={{width:"100%",display:'inline-flex'}}>
                        <div id='inputIndividual'>
                          <Card.Meta>Net amount </Card.Meta>
                          <Card.Header>{this.state.datoSeleccionado.Amount}</Card.Header><br/>
                        </div>
                         <div id='inputIndividual'>
                          <Card.Meta>Commissions</Card.Meta>
                          <Card.Header>{this.state.datoSeleccionado.Comision}</Card.Header><br/>
                        </div>
                  </div>
                  <div style={{margin:'0px auto', textAlign:'center'}}>
                    <h4>Amount to pay</h4>
                    <h2 style={{margin:'10px', textAlign:'center'}}>$ {PrecioSNComision} USD</h2>
                  </div>
                </Card.Content>
                      {this.state.datoSeleccionado.Status === 'Finalizado'?
                          <div></div>
                          :
                          <div className='btnPagar'><Button onClick={this.AceptarDepositoVendedor} positive>Pagar</Button></div>
                        }
              </Card>
              :
              <Card></Card>
          }



          </Grid.Column>
        </Grid>

          <Modal basic open={this.state.openConfirmPayment} onClose={this.AceptarDepositoVendedor}>
             <div style={{margin:'0px auto', textAlign:'center'}}>
               Are you sure you want to approve this payment?
             </div>
            <Modal.Actions>
              <Button  color='red' inverted onClick={this.AceptarDepositoVendedor}>
                <Icon name='remove' />'Cancel'
              </Button>
              <Button color='green' inverted  onClick={this.changeStatePago}>
                <Icon name='checkmark' /> "Aprobar"
              </Button>
            </Modal.Actions>
          </Modal>

      </div>


    )
  }
}


class CardRecibos extends Component{

  constructor(){
    super();

    this.state ={
      openModal:false,
      openConfirm:false,
      datosBancarios:[],
      MensajeRechazo:'',
    }
  }


   show=()=>{
    this.setState({
      openModal:!this.state.openModal,
      denegar:false,
    })
  }
AceptarPagoBefore=()=>{
    this.setState({
      openConfirm:!this.state.openConfirm,
    })
  }


  AceptarPagoAfter=()=>{
       axios.post(Direccion+`/AprobarPagoAdminRecibo`,{idEnvio:this.props.datos.idTransaccion,idUser:this.props.datos.Cliente,idVendedor:this.props.datos.Vendedor,Precio:this.props.datos.precio})
        .then(res => {
          if (res.data === 'OK') {
            window.location.reload();
          }
          else{
            alert('error');
          }
        })
  }
  rechazarPago=()=>{
    console.log(this.state.MensajeRechazo.length);
    if(this.state.MensajeRechazo.length>=10){
      axios.post(Direccion+`/DenegarPagoAdminRecibo`,{idEnvio:this.props.datos.idTransaccion,idUser:this.props.datos.Cliente,MensajeRechazo:this.state.MensajeRechazo})
       .then(res => {
         if (res.data === 'OK') {
           window.location.reload();
         }
         else{
           alert('error');
         }
       })
    }
    else{
      alert('mensaje mayor a 10 caracteres')
    }

  }

  render(){
    let datos = this.props.datos;
    let datosBancarios=this.state.datosBancarios;
    return(
      <div>
        <Card onClick={this.show} style={{borderRadius:'2vh', width:'90%', minWidth:'250px', margin:' 1em 2.5em'}}>
          <Card.Content>
              <div style={{width:"100%", display:"inline-flex"}}>
                <div style={{ width:"25%"}}>
                  <Card.Meta>Amount</Card.Meta>
                  <Card.Header>{datos.precio}</Card.Header><br/>
                  <Card.Meta>id Transaction</Card.Meta>
                  <Card.Header>{datos.idTransaccion}</Card.Header><br/>
                </div>
                <div style={{marginLeft:"10%", width:"30%"}}>
                  <Card.Meta>Status</Card.Meta>
                  <Card.Header>{datos.Status}</Card.Header><br/>

                  <Card.Meta>payment request date</Card.Meta>
                  <Card.Header>{datos.FechaSolicitudPago}</Card.Header><br/>
                </div>
              </div>
          </Card.Content>
        </Card>

         <Modal  open={this.state.openModal} onClose={this.show}>
           {!this.state.denegar?
             <div style={{margin:'0px auto', textAlign:'center'}}>
               <div style={{width:"100%", margin:'0px auto',textAlign:'center'}}>
                 <div>
                   <Header.Subheader>Amount</Header.Subheader>
                   <Header>{datos.precio}</Header><br/>
                 </div>
                 <div>
                   <Header.Subheader>Transaction</Header.Subheader>
                   <Header>{datos.idTransaccion}</Header><br/>
                 </div>
               </div>
               <div style={{margin:'0px auto', width:'40%',marginBottom: '50px',backgroundColor: '#f1f1f1',padding: '20px',borderRadius: '10px',}}>
                 <img style={{margin:'0px auto', width:'100%'}} src={datos.FotoReciboPago} />
                <Button color='orange' inverted  onClick={() => window.location.href=datos.FotoReciboPago}>
                  <Icon name='download' /> "Descargar Recibo"
                </Button>
               </div>
             </div>:
             <div style={{margin:'20px auto', textAlign:'center'}}>
               <Header>Motivo de rechazo (ingles)</Header>

               <textarea onChange={(e) => this.setState({ MensajeRechazo: e.target.value })} type="text" className="Textmensaje" placeholder='Message'/>
               <div className='enviarRechazo' onClick={this.rechazarPago}>Enviar</div>
             </div>
           }

           <Modal.Actions>
             <Button  color='red' inverted onClick={this.show}>
               <Icon name='remove' />'Cancel'
             </Button>
             <Button  color='orange'  onClick={()=>this.setState({denegar:!this.state.denegar})}>
               <Icon name='remove' />'Denegar'
             </Button>
             <Button color='green'   onClick={this.AceptarPagoBefore}>
               <Icon name='checkmark' /> "Aprobar pago"
             </Button>
           </Modal.Actions>
         </Modal>

         <Modal basic open={this.state.openConfirm} onClose={this.AceptarPagoBefore}>
            <div style={{margin:'0px auto', textAlign:'center'}}>
              Are you sure you want to approve this payment?
            </div>
           <Modal.Actions>
             <Button  color='red' inverted onClick={this.AceptarPagoBefore}>
               <Icon name='remove' />'Cancel'
             </Button>
             <Button color='green' inverted  onClick={this.AceptarPagoAfter}>
               <Icon name='checkmark' /> "Aprobar"
             </Button>
           </Modal.Actions>
         </Modal>


      </div>
    )
  }
}


class CardPagosUser extends Component{

  constructor(){
    super();
    this.state={
      datosBancarios:{}
    }
  }
  UNSAFE_componentWillMount(){
    axios.post(Direccion+`/tomarBanks`,{idUser:this.props.datos.idVendedor,tipo:this.props.datos.tipoUsuario})
     .then(res => {
       if (res.data !==false ) {
         this.setState({datosBancarios:res.data})
       }
     })
  }

   show=()=>{
    this.props.selectCard({datos:this.props.datos,datosBancarios:this.state.datosBancarios});

  }

  render(){

    let datos = this.props.datos;
    console.log(datos);
    return(

      <div>

        <Card onClick={this.show} style={{borderRadius:'2vh', width:'90%', minWidth:'250px', margin:' 1em 2.5em'}}>
          <Card.Content>

              <div style={{width:"100%", display:"inline-flex"}}>

                <div style={{ width:"25%"}}>
                  <Card.Meta>Transaction ID</Card.Meta>
                  <Card.Header>{datos.key}</Card.Header><br/>

                  <Card.Meta>date payment</Card.Meta>
                  <Card.Header>{datos.dateTime}</Card.Header><br/>

                  <Card.Meta>Payment type</Card.Meta>
                  <Card.Header>
                  {datos.PagoOrigen === 1?'Envio'
                    :datos.PagoOrigen === 2?'Suscripcion'
                    :datos.PagoOrigen === 3?'Cancelacion'
                    :''}
                  </Card.Header><br/>

                </div>

                <div style={{marginLeft:"10%", width:"30%"}}>
                  <Card.Meta>Amount</Card.Meta>
                  <Card.Header>${datos.Amount} USD</Card.Header><br/>

                  <Card.Meta>Id Product</Card.Meta>
                  <Card.Header>{datos.idRecibo}</Card.Header><br/>

                </div>
                <div style={{marginLeft:'auto',marginRight:'0',textAlign:'center'}}>
                  {datos.Status === 'Finalizado'?
                    <div className='IconoRight'>
                      <Icon style={{marginLeft:'23%'}} color='teal'  name='checkmark' />

                    </div>:
                    <div>
                    {datos.PagoType === 'retiro'?
                      <div className='IconoRight'>
                          <Icon style={{marginLeft:'23%'}} color='teal'  name='checkmark' />
                      </div>
                      :
                      <div className='IconoRight'>
                        <Icon style={{marginLeft:'25%'}} color='grey' size='big'  name='payment' />
                        <h4 style={{marginTop:'-2%', color:'grey'}}>Pending</h4>
                      </div>

                    }

                   </div>
                  }
                  {datos.PagoType === 'abono'?
                    <Icon style={{margin:'0 auto'}} color='red' size='big'  name='angle down' />:<Icon style={{margin:'0 auto'}} color='teal' size='big'  name='angle up' />
                  }
                </div>
              </div>
          </Card.Content>

        </Card>
      </div>
    )
  }
}


export default pagosAdmin;

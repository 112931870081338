import React, { Component } from 'react';
import BarraLateral from './sideBar.js';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FaceIcon from '@material-ui/icons/Face';
import Chip from '@material-ui/core/Chip';
import FiltrarTiemposStatus from '../scripts/fechasStatus.js'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Fieldset} from 'primereact/fieldset';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Divider,Message,Rating, Header, Label,Icon,Table,Accordion,Modal,Button,Image,Dimmer,Loader,Form,Input,Grid, Placeholder, Segment } from 'semantic-ui-react';
import { Card } from 'semantic-ui-react'
import { DatePicker } from 'material-ui-pickers';
import {SubirDato} from '../uploadData.js';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import './styleSeller/enviosVendedor.css';
import Particles from 'react-particles-js';
import {Link} from 'react-router-dom'
import Badge from '@material-ui/core/Badge';
import FinalizadoProceso from '../scripts/filtrarProcesoFinalizados.js';
import ExpiracionPago from '../scripts/ExpiracionPago.js';
import DeterminarImagen from '../scripts/imagenes.js';
import DeterminarMensaje from '../scripts/TraductorErrores.js';
import axios from 'axios';
import {Direccion,DireccionFront} from '../../strings/peticiones.js';
import firebase from 'firebase'
import AlertSnack from '../notificacionCard.js';
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-google";
import CalculadorCBM from '../scripts/CalculadorCBM.js';


const styles = theme=>({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  }

})

class EnviosVendedor extends Component{


  constructor(){
    super()
    var date = new Date();
    let month = date.getMonth()+1;
    let day = date.getDate();
      if (month <10) {
          month = "0"+month;
        }
      if (day<10) {
        day = "0"+day;
      }

    this.state={
      valueTabs:0,
      open: false,
      dimmer:true,
      selectedDate: date,
      fechaFormulario1:'2019-01-01',
      fechaFormulario2:date.getFullYear()+'-'+month +'-'+day,
      pagados:[],
      proceso:[],
      cancelados:[],
      todosEnvios:[],
      resultadoBusqueda:[],

      Status:false,
      perfil:{},
      notificaciones:[],
      abrirVentanaIndividual:false,
      searching:false,
    }
  }
  UNSAFE_componentWillMount(){
    let perfil={};
    let self=this;
    var notificaciones=JSON.parse(localStorage.getItem('notificaciones'));
    axios.post(Direccion+'/tomarUsuario',{id:firebase.auth().currentUser.uid,tipo:'Vendedor'})
    .then(res=>{
      perfil=res.data;
        self.setState({
          Status:true,
          perfil:perfil,
          notificaciones:notificaciones
        })

        this.getEnvios();

    })

  }
  componentWillReceiveProps(e){
    let self=this;
    const valorParametrosVentana=e.location.pathname;
    let busquedaParam=self.BuscarParm(valorParametrosVentana);
    let EnvioAbrir=busquedaParam?self.ExistenciaDelParametro(busquedaParam,self.state.proceso):false;
    if(EnvioAbrir.value){
      let pagados=self.state.proceso;
      pagados.splice(EnvioAbrir.index,1);
      self.setState({abrirVentanaIndividual:EnvioAbrir.value,proceso:pagados})
    }
    else{
        let EnvioAbrir2=busquedaParam?self.ExistenciaDelParametro(busquedaParam,self.state.pagados):false;
        if(EnvioAbrir2.value){
          let pagados2=self.state.pagados;
          pagados2.splice(EnvioAbrir2.index,1);
          self.setState({abrirVentanaIndividual:EnvioAbrir2.value,pagados:pagados2})
        }
        else{
          let EnvioAbrir3=busquedaParam?self.ExistenciaDelParametro(busquedaParam,self.state.cancelados):false;
          if(EnvioAbrir3.value){
            let pagados3=self.state.cancelados;
            pagados3.splice(EnvioAbrir3.index,1);
            self.setState({abrirVentanaIndividual:EnvioAbrir3.value,cancelados:pagados3})
          }
        }
    }
  }

  BuscarParm=(parametroVentana)=>{
    let valor=parseInt(0);
    let i;
    let ban=false;
    for(i=0;i<parametroVentana.length;i++){

      if(parametroVentana[i]==='/'){
        valor=valor+parseInt(1);
      }
      if(valor===3){
        ban=true;
        break;
      }
    }
    if(ban){
      return parametroVentana.substring(i+parseInt(1),parametroVentana.length);
    }
    else return false
  }
  ExistenciaDelParametro=(p,data)=>{
    var value=false;
    for (var i=0;i<data.length;i++){
      if(data[i].idTransaccion===p){
        value=data[i];
        break;
      }
    }
    return {value:value,index:i}
  }

  getEnvios(){
    try{
      let datos;
      let self=this;
      let pagos=[];
      this.setState({
        searching:true,
      })
      axios.post(Direccion+`/tomarEnviosVendedor`,{data:{usuario:firebase.auth().currentUser.uid,inicio:this.state.fechaFormulario1,fin:this.state.fechaFormulario2}})
        .then(res => {
          datos=res.data;
          pagos=FinalizadoProceso.cantidadDiferencia(datos);
          self.setState({
            todosEnvios:datos,
            pagados:pagos.terminados,
            proceso:pagos.proceso,
            cancelados:pagos.cancelados,
            open:false,
            searching:false,
          })
        })
        .then(()=>{
          const valorParametrosVentana=self.props.location.pathname;
          let busquedaParam=self.BuscarParm(valorParametrosVentana);
          let EnvioAbrir=busquedaParam?self.ExistenciaDelParametro(busquedaParam,pagos.proceso):false;
          if(EnvioAbrir.value){
            pagos.proceso.splice(EnvioAbrir.index,1);
            self.setState({valueTabs:0,abrirVentanaIndividual:EnvioAbrir.value,proceso:pagos.proceso})
          }
          else{
              let EnvioAbrir2=busquedaParam?self.ExistenciaDelParametro(busquedaParam,pagos.terminados):false;
              if(EnvioAbrir2.value){
              pagos.terminados.splice(EnvioAbrir2.index,1);
                self.setState({valueTabs:1,abrirVentanaIndividual:EnvioAbrir2.value,pagados:pagos.terminados})
              }
              else{
                let EnvioAbrir3=busquedaParam?self.ExistenciaDelParametro(busquedaParam,pagos.cancelados):false;
                if(EnvioAbrir3.value){
                  pagos.cancelados.splice(EnvioAbrir3.index,1);
                  self.setState({valueTabs:2,abrirVentanaIndividual:EnvioAbrir3.value,cancelados:pagos.cancelados})
                }
              }
          }
        })
    }catch(e){
      console.log(e);
    }
  }
  handleDateChange=(date)=>{

    let fecha=date.target.value;

     this.setState({
       fechaFormulario1:fecha
     })
   }
  handleDateChange2=(date)=>{
   let fecha=date.target.value;
    this.setState({
      fechaFormulario2:fecha
    })
  }
  handleChangeTab=(event, valueTabs)=>{
   this.setState({ valueTabs });
  };

  buscarEnvio=(e)=>{
    let textToSearch = e.target.value;
    let EnvioEncontrado= [];
    if (e.target.value.length === 0) {
      this.setState({
        busquedaActiva:false,
      })
    }else{
      this.state.todosEnvios.forEach((it)=>{
        let contenedor = it.contenedor=== 'Aereo'?'Air': it.contenedor;

       if(textToSearch===it.idTransaccion || it.origen.includes(textToSearch) || it.destino.includes(textToSearch) || contenedor.includes(textToSearch) ||textToSearch===it.origen||textToSearch===it.destino ||textToSearch===contenedor ){
         EnvioEncontrado.push(it);
       }
       else if(this.compareTwoStrings(textToSearch,it.origen)>0.3 || this.compareTwoStrings(textToSearch,it.destino)>0.3 || this.compareTwoStrings(textToSearch,contenedor)>0.3){
         EnvioEncontrado.push(it);
       }
      })
      this.setState({
        resultadoBusqueda:EnvioEncontrado,
        busquedaActiva:true,
      })
    }
  }

  compareTwoStrings=(strA,strB)=>{
      for(var result = 0, i = strA.length; i--;){
          if(typeof strB[i] === 'undefined' || strA[i] === strB[i]);
          else if(strA[i].toLowerCase() === strB[i].toLowerCase())
              result++;
          else
              result += 4;
      }
      return 1 - (result + 4*Math.abs(strA.length - strB.length))/(2*(strA.length+strB.length));
  }
  render(){
    const { classes } = this.props;

    return(

      <div className="div-principal-envios-vendedor" >

        <BarraLateral/>
        <div className='paticulasContent' >
          <Particles id='Paticulas' style={{backgroundImage:'url(../imagenes/envios.jpg)', backgroundSize:'cover',backgroundPosition:'center',height:'600px',position:'absolute'}}
               params={{
                 "particles": {
                     "number": {
                         "value": 40
                     },
                     "size": {
                         "value": 2
                     }
                 },
                 "interactivity": {
                     "events": {
                         "onhover": {
                             "enable": true,
                             "mode": "repulse"
                         }
                     }
                 }
             }} />
           <div className='contentTitulo'>
             <div className='titulo'>
                 <i class="material-icons" style={{ fontSize: '2em' }}>directions_boat </i>
                 <h3>Operations</h3>
             </div>
           </div>
          <div>
            <div className="contentBuscador">
              <div className="Buscador" >
                <input type="text" onChange={this.handleDateChange} placeholder='From' onFocus={(e)=> e.currentTarget.type = "date"} onBlur={(e)=> {e.currentTarget.type = "text"; e.currentTarget.placeholder = "From"}}/>
                <input type="text" onChange={this.handleDateChange2} placeholder='to' onFocus={(e)=> e.currentTarget.type = "date"} onBlur={(e)=> {e.currentTarget.type = "text"; e.currentTarget.placeholder = "to"}}/>
                <div  className='btnBuscarFecha' onClick={this.getEnvios.bind(this)} >Search</div>
              </div>
              <div className='buscadorPorID'>
                <input  onChange={this.buscarEnvio} type="text" placeholder="Search..."/>
              </div>
            </div>
          </div>
        </div>



        <Tabs value={this.state.valueTabs} onChange={this.handleChangeTab}variant="fullWidth"  indicatorColor="secondary" textColor="primary">
            <Tab icon={<i class="material-icons">compare_arrows</i>} label="In transit" ></Tab>
            <Tab icon ={<i class="material-icons">done_outline</i>} label="Completed"></Tab>
            <Tab icon ={<i class="material-icons">highlight_off</i>} label="Cancelled"></Tab>
        </Tabs>
        {this.state.searching?
          <div>
            <div className="spinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          </div>
          :
          <div>
            {this.state.resultadoBusqueda && this.state.busquedaActiva?
              <div>
                {
                  this.state.resultadoBusqueda.map((it,key)=>{
                    return(<CardEnviosVendedor  usuario={this.state.usuario} totalData={this.state.resultadoBusqueda} notificaciones={this.state.notificaciones} recargarDatos={this.getEnvios.bind(this)} datos={it} key={key} />)
                  })
                }
              </div>:
              <div>
                {this.state.abrirVentanaIndividual!=false ?
                  <CardEnviosVendedor usuario={this.state.usuario} totalData={this.state.proceso} notificaciones={this.state.notificaciones} recargarDatos={this.getEnvios.bind(this)} datos={this.state.abrirVentanaIndividual} key={0} individual={true}/>
                  :<div></div>
                }
                {this.state.valueTabs===0?
                  <div>
                  {
                    this.state.proceso.map((it,key)=>{
                      return(<CardEnviosVendedor  notificaciones={this.state.notificaciones} fecha={this.state.fechaFormulario2} recargarDatos={this.getEnvios.bind(this)} datos={it} key={key}/>)
                    })

                  }
                  </div>
                  :
                  <div></div>
                }
                {this.state.valueTabs===1?
                  <div>
                  {
                    this.state.pagados.map((it,key)=>{
                      return(<CardEnviosVendedor  notificaciones={this.state.notificaciones} fecha={this.state.fechaFormulario2} recargarDatos={this.getEnvios.bind(this)} datos={it} key={key}/>)
                    })
                  }

                  </div>

                  :
                  <div></div>
                }
                {this.state.valueTabs===2?
                  <div>
                  {
                    this.state.cancelados.map((it,key)=>{
                      return(<CardEnviosVendedor  notificaciones={this.state.notificaciones} fecha={this.state.fechaFormulario2} recargarDatos={this.getEnvios.bind(this)} datos={it} key={key}/>)
                    })
                  }
                  </div>
                  :
                  <div></div>
                }
              </div>
            }
          </div>
        }

      </div>

    )
  }
}

class CardEnviosVendedor extends Component{

  constructor(){
    super();

    this.state={
      open: false,
      openPayModal:false,
      dimmer:true,
      drimmerActivo:false,
      dimmerPayment:false,
      files: [],
      statusString:'',
      causa:'',
      observaciones:'',
      numeroBL:'',
      nombreBarco:'',
      numeroViaje:'',
      numeroBooking:'',
      fechaEmbarque:'',
      bank:[],
      ceroCuentasBanco:false,
      pasoPago:1,
      badge:true,
      num:0,
      DiasPago:15,
      confirmRate:false,
      rate:0,
      Vencido:true,
      Cancelacion:'',
      MontoCancelacion:'',
      cancelSeccion:false,
      openModalStatus:false,
      openAlert:false,
      AlertMessage:'',
      AlertType:'',
      actualizadoCancelacion:false,
      openFormEmail:false,
      NombreSupport:'',
      CorreoSupport:'',
      TelefonoSupport:'',
      PaisSupport:'',
      MensajeSupport:'',
      ready:false,
    }
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);

  }

  UNSAFE_componentWillMount(){

    if(this.props.individual){
      this.setState({  open: true})
      this.searchId();
    }
    let a = ExpiracionPago.Expiracion(this.props.datos.fechaSolicitudPago,this.props.datos.DiasPago,this.props.datos.pagado);

    this.setState({
      Vencido: a,
      Pagado:this.props.datos.solicitudPago,

    })
    var num=0;
    if (this.props.notificaciones) {
      this.props.notificaciones.forEach((it)=>{
        if(it.idTransaccion===this.props.datos.idTransaccion){
          num++;
        }
      });
      if(num>0){
        this.setState({
          badge:false,
          num:num
        })
      }
    }
  }
  componentWillReceiveProps(){
    if(this.props.individual){
      this.setState({  open: true})
      this.searchId();
    }
  }
  searchId(){
    if (this.props.notificaciones) {
      this.props.notificaciones.forEach((it)=>{
        if(it.idTransaccion===this.props.datos.idTransaccion){
          axios.post(Direccion+`/RefreshNotificacion`,{idNotificacion:it.key,tipo:'Vendedor'})
            .then(res => {
              var datos=res.data;
              if(datos!='OK'){
                alert('error');
              }
            })
        }
      })
    }

  }
  closePayModal(){
    this.setState({openPayModal:false, dimmerPayment:false,})
  }
  show = dimmer => () =>{
    this.setState({ dimmer, open: true,badge:true })
    this.searchId();
  }
  close = () =>{
    if(this.props.individual){

      window.location='/seller/Envios';
    }
    else{
      this.setState({ open: false });

    }

  }
  SolicitarPago(){
    var bank;
    var self=this;
    axios.post(Direccion+'/tomarBanks',{tipo:'Vendedor',idUser:firebase.auth().currentUser.uid})
    .then(res=>{
        bank=res.data;
        if(bank){
          self.setState({bank:bank,pasoPago:3});

        }
        else{
        self.setState({ceroCuentasBanco:true,pasoPago:2,})
      }
    })
    this.setState({openPayModal:true,dimmerPayment:true,});

  }
  actualizarDatos(){

    if(this.state.files.length>0){
    this.setState({
      drimmerActivo:true
    })

    var self=this;
    var archivo;
    this.state.files.forEach((it,index,array)=>{
      archivo=SubirDato('documentos/envios/'+this.props.datos.idTransaccion+'/'+it.name,firebase.storage(),it);
      archivo.then(function(done){
        axios.post(Direccion+'/actualizarEnvio',{tipo:"Vendedor",idUser:firebase.auth().currentUser.uid,datos:self.props.datos,archivo:done,nombre:it.name,idCliente:self.props.datos.cliente})
        .then(res=>{
          if(res.data==='OK'){
            if(index>=array.length-1){
              self.setState({
                drimmerActivo:false,
                openAlert:true,
                 AlertMessage:'update finished',
                 AlertType:'positive',
              },()=>{
                self.close();
                self.props.recargarDatos();
                //recargar pagina
              })
            }
          }else if(res.data==='340'){
            self.setState({
              openAlert:true,
               AlertMessage:'something went wrong',
               AlertType:'error',
            })
          }
          else{
            self.setState({
              openAlert:true,
               AlertMessage:'something went wrong in the file '+index,
               AlertType:'error',
            })
          }
        })
      })

    })

    }//if
    else{
      this.close();
    }

  }
  escribirSolicitudPago(){
    var self=this;
    if (this.state.DiasPago) {
      axios.post(Direccion+'/solicitudPago',{idUser:firebase.auth().currentUser.uid,tipo:"Vendedor", DiasPago:this.state.DiasPago,cuenta:this.state.bank,datos:this.props.datos})
      .then(res=>{
        if(res.data==='OK'){
          this.setState({
            open:false,
            openPayModal:false,
            dimmerPayment:false
          });
          this.props.recargarDatos();
        }else if(res.data==='101'){
          this.setState({
            openAlert:true,
            AlertMessage:'You need to accept the shipment before requiring payment',
            AlertType:'positive',
          })
        }
        else if(res.data==='340'){
          window.location.reload();
        }
      })
    }

  }

  handleForm=(tipo,e)=>{
    if(tipo==='status'){
      this.setState({statusString:e.target.value})
    }
    else if(tipo==='causa'){
      this.setState({causa:e.target.value})
    }
    else if(tipo==='observaciones'){
      this.setState({observaciones:e.target.value})
    }
  }
  handleRefDiasPago=(e)=>{
    var valor=e.target.value;
    this.setState({
      DiasPago:valor
    })
  }
  handleRefMontoCancelacion=(e)=>{
    var valor=e.target.value;
    this.setState({
      MontoCancelacion:valor
    })
  }
  handleRefCancelacion=(e)=>{
    var valor=e.target.value;
    this.setState({
      Cancelacion:valor
    })
  }
  actualizarCancelacion=()=>{

      let self=this;

      if (parseInt(this.state.Cancelacion)>=10 && parseInt(this.state.MontoCancelacion)>=5) {
        this.setState({
          drimmerActivo:true,
          actualizadoCancelacion:true,
        })
        axios.post(Direccion+`/actualizarCancelacion`,{tipo:"Vendedor",idUser:firebase.auth().currentUser.uid,data:{Monto:self.state.MontoCancelacion,Cancelacion:self.state.Cancelacion, id:self.props.datos.idTransaccion,datos:self.props.datos}})
      .then(res => {
          let datos=res.data;
          if(datos==='OK'){
            self.setState({
               open:false,
               openPayModal:false,
               drimmerActivo:false,
               actualizadoCancelacion:false,
               openAlert:true,
               AlertMessage:'Cancellation details updated',
               AlertType:'positive',

            })
            this.props.recargarDatos();
          }else if(res.data==='340'){
              this.setState({
                openAlert:true,
                titleAlert:'Error',
                AlertMessage:'something went wrong',
                AlertType:'error',
              })
              window.location.reload();
          }
          else{
            this.setState({
              openAlert:true,
              titleAlert:'Error',
              AlertMessage:'something went wrong',
              AlertType:'error',
            })
          }
        })
      }
      else{
        this.setState({
          openAlert:true,
          titleAlert:'Error',
          AlertMessage:'enter values ​​greater than 10hr and 5 USD',
          AlertType:'error',
        })
      }
  }
  handleFormBl=(tipo,e)=>{
    if(tipo==='bl'){
      this.setState({numeroBl:e.target.value})
    }
    else if(tipo==='nombreBarco'){
      this.setState({nombreBarco:e.target.value})
    }
    else if(tipo==='numeroViaje'){
      this.setState({numeroViaje:e.target.value})
    }
    else if(tipo==='numeroBooking'){
      this.setState({numeroBooking:e.target.value})
    }
    else if(tipo==='fechaEmbarque'){
      this.setState({fechaEmbarque:e.target.value})
    }
  }
  subirBlDetails(){
    try{
      let datos;
        this.setState({
          drimmerActivo:true
        })
      let self=this;
      axios.post(Direccion+`/actualizarBL`,{tipo:"Vendedor",idUser:firebase.auth().currentUser.uid,data:{numeroBl:self.state.numeroBl,nombreBarco:self.state.nombreBarco,numeroViaje:self.state.numeroViaje,numeroBooking:self.state.numeroBooking,fechaEmbarque:self.state.fechaEmbarque,id:self.props.datos.idTransaccion,data:self.props.datos}})
        .then(res => {
          datos=res.data;
          if(datos==='OK'){
            self.setState({
               open:false,
               openPayModal:false,
              drimmerActivo:false,
              openAlert:true,
              AlertMessage:'BL details updated',
              AlertType:'positive',

            })
            this.props.recargarDatos();

          }else if(res.data==='340'){
              window.location.reload();
          }
          else{
            alert('Error');
          }
        })
    }catch(e){
      console.log(e);
    }

  }
  subirStatus(){

    try{
      let datos;
        this.setState({
          drimmerActivo:true
        })
      let self=this;
      axios.post(Direccion+`/actualizarStatusEnvio`,{tipo:"Vendedor",idUser:firebase.auth().currentUser.uid,data:{tipo:self.props.datos.contenedor,id:self.props.datos.idTransaccion,causa:self.state.causa,status:self.state.statusString,observaciones:self.state.observaciones,data:self.props.datos}})
        .then(res => {
          datos=res.data;
          if(datos==='OK'){
            self.setState({
               open:false,
               openPayModal:false,
              drimmerActivo:false,
              openAlert:true,
              titleAlert:'Success',
              AlertMessage:'the status is updated',
              AlertType:'positive',
              drimmerActivo:false,
            })
            this.props.recargarDatos();

          }else if(res.data==='340'){
              window.location.reload();
          }
          else{
            alert('Error');
          }
        })
    }catch(e){
      console.log(e);
    }

  }
  handleRate =(e, { rating, maxRating })=>{
    this.setState({ confirmRate: true, rate:rating});
  }
  closeRate= () =>{
     this.setState({  confirmRate: false,});
  }
  Calificar=()=>{
    let self = this;
    this.setState({  dimmerActive: true,confirmRate:false, open:false })

    axios.post(Direccion+`/EnvioCalificado`,{DatosEnvio:this.props.datos})
      .then(res => {
        if (res.data === "OK") {
          this.setState({  dimmerActive: false, })
          self.props.recargarDatos();
        }
        else {
          this.setState({  dimmerActive: false, })
          self.props.recargarDatos();

        }
    })

      axios.post(Direccion+`/CalificarUsuario`,
      {idUser:this.props.datos.cliente,tipo:"Cliente",userRate:this.state.rate})
      .then(res => {
        if (res.data === "OK") {
        }
    })
  }
  resetAlert = ()=> this.setState({ openAlert:false,titleAlert: '', AlertMessage:'', AlertType:'',});

  openCancelar = () => this.setState({ cancelSeccion: !this.state.cancelSeccion });
  HandlemodalStatus = () => this.setState({ openModalStatus: !this.state.openModalStatus });
  sendEmailSupport=()=>{
    let self = this;

    if(this.state.ready){
      if (this.state.NombreSupport && this.state.CorreoSupport && this.state.TelefonoSupport && this.state.PaisSupport && this.state.MensajeSupport) {
        self.setState({
          drimmerActivo:true,
        })
        axios.post(Direccion+`/supportEmail`,{idUser:firebase.auth().currentUser.uid,nombre:this.state.NombreSupport,correo:this.state.CorreoSupport,telefono:this.state.TelefonoSupport,pais:this.state.PaisSupport,mensaje:this.state.MensajeSupport,})
          .then(res => {
              if (res.data === 'OK') {
                  self.setState({
                    openFormEmail : false,
                    open : false,
                    NombreSupport:'',
                    CorreoSupport:'',
                    TelefonoSupport:'',
                    PaisSupport:'',
                    MensajeSupport:'',
                    openAlert:true,
                    AlertMessage:'email sent correctly',
                    AlertType:'positive',
                    drimmerActivo:false,

                  })
              }
              else{
                 self.setState({
                    openFormEmail:false,
                    openAlert:true,
                    AlertMessage:'something went wrong',
                    AlertType:'Error',
                    drimmerActivo:false,

                  })
              }
          })
      }else{
        self.setState({
           openAlert:true,
           AlertMessage:'fill all the fields ',
           AlertType:'warning',

         })
      }
    }
    else{
      self.setState({
         openAlert:true,
         AlertMessage:'you need to complete the reCAPTCHA',
         AlertType:'warning',
       })
    }
  }
  handleNombreSupport = (e) => this.setState({ NombreSupport: e.target.value });
  handleCorreoSupport = (e) => this.setState({ CorreoSupport: e.target.value });
  handleTelefonoSupport = (e) => this.setState({ TelefonoSupport: e.target.value });
  handlePaisSupport = (e) => this.setState({ PaisSupport: e.target.value });
  handleMensajeSupport = (e) => this.setState({ MensajeSupport: e.target.value });
  handleEmailSendSupport = ()=>{
    this.setState({ openFormEmail: !this.state.openFormEmail });
  }
  componentDidMount() {
    loadReCaptcha();
    // console.log(window.grecaptcha);
    // this.recaptcha.current.reset();
  }
  onLoadRecaptcha() {
    if (this.captchaDemo) {
          this.captchaDemo.reset();
      }
  }

  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!
    console.log(recaptchaToken);
    console.log(this.state);
    this.setState({ready:true})
  }

  render(){
    let datos=this.props.datos;
    let archivos=datos.archivos;
    let statusList=datos.StatusList;
    let solicitudPago=datos.solicitudPago===true?true:false;
    let arrayArchivos;
    let ListaStatus;
    if (datos.Comisiones === undefined) {datos.Comisiones = 0}
    var PrecioSNComision = (parseFloat(datos.precio) - parseFloat(datos.Comisiones)).toFixed(2);

    if(archivos!=null){
      arrayArchivos = Object.keys(archivos).map(key => {
        let ar = archivos[key]
        ar.key = key
        return ar
      })
    }
    else{
      arrayArchivos=[];
    }

    if(statusList!=null){
      ListaStatus = Object.keys(statusList).map(key => {
        let ar = statusList[key]
        ar.key = key
        return ar
      })
    }
    else{
      ListaStatus=[];
    }

    let TipoMedida = "";
    if(datos.Medidas){
      TipoMedida = datos.Medidas;
    }else{
      if(datos.containerSize != "none"){
        TipoMedida = datos.containerSize;
      }else{
        datos.ArrayUnidades.forEach((it)=>{
          TipoMedida=TipoMedida+ it.Height + ' x ' +it.Lenght+' x '+it.Width +'\n';
        })
      }
    }

  
    let respuestaCalculador = CalculadorCBM.CalcularCBMTotal(this.props.datos.ArrayUnidades);

    let imagen=DeterminarImagen.DeterminarImagen(datos.contenedor,TipoMedida,datos.containerType);
    return(

      <div>
      <Modal  id={"ModalPay"}  open={this.state.confirmRate} onClose={this.closeRate}>
        <Modal.Header id={"ModalPayHeader"}>
          <Icon name='archive' />
          Rate the transaction
        </Modal.Header>
        <Modal.Content id={"ModalPayContent"}>
        <p style={{marginLeft:"25%"}}>
          Are you sure of your rating?
        </p>
        <Rating style={{marginLeft:'33%'}} icon='star' size='huge' maxRating={5} rating={this.state.rate}  />
        </Modal.Content>
        <Modal.Actions>
          <Button id={'ModalPayButtonCancel'} color='red' inverted onClick={this.closeRate}>
            <Icon name='remove' />Cancel
          </Button>
          <Button positive id={'ModalPayButtonAcept'}  onClick={this.Calificar}>
            <Icon name='checkmark' /> Accept
          </Button>
        </Modal.Actions>
      </Modal>
      <AlertSnack openAlert={this.state.openAlert} titleAlert={this.state.titleAlert} resetAlert={this.resetAlert} AlertMessage={this.state.AlertMessage} AlertType={this.state.AlertType}/>




      <Card onClick={this.show(true)} style={{textDecoration:'none',fontSize:'.9em', borderRadius:'2vh', width:'95%', minWidth:'250px', margin:' 1em 2.5em'}}>
          <div className='TransaccionID'>
            <p>Operation : {datos.idTransaccion}</p>
          </div>
          <Card.Content id='contentCardVenddor'>

            <div className='contentImgEnvio'>
              {datos.contenedor==='LCL'||datos.contenedor==='LTL-Truck'||datos.contenedor==='Aereo'?
                <img src={DireccionFront+'/imagenes/pallet.png'} />
                :
                <img src={DireccionFront+'/imagenes/'+imagen} />
              }
            </div>

            <div className='InfoContent'>
              <div className='TitleEnvio'>
                <span>
                  {datos.contenedor === 'Aereo'?'Air':datos.contenedor==='FCL-Ferro'?'FCL-FFCC':datos.contenedor}
                </span>
                <span>
                  Freigth
                </span>
                <span>
                  {datos.origen+ '   ->  '}
                </span>
                <span>
                  {datos.destino}
                </span>
              </div>
              <div className='descriptionContent'>
                <p className='ContenidoEnvio'>Contain: {datos.contenido} {datos.IMOCargo?<span className="CargoIMO" >IMO</span>:''} </p>
                <div className='ContentPriceEnvio'>

                  <span className='PagoEnvio'>{'$'+PrecioSNComision+' USD'}</span>
                  <span className='TipoPagoEnvio'>{datos.Payment}</span>
                </div>
                <div className='MedidasEnvio'>
                  <span className='CantidadEnvio'>
                  Quantity/Measure:
                  </span>
                  <span className='ContenidoPeso'>
                    {datos.cantidad}
                  </span>
                  {datos.contenedor!='LCL'&&datos.contenedor!='Aereo'&&datos.contenedor!='BBulk'&&datos.contenedor!='LTL-Truck'?<span className='PesoEnvio'>Container Size/Type:</span>:''}
                  {datos.contenedor!='LCL'&&datos.contenedor!='Aereo'&&datos.contenedor!='BBulk'&&datos.contenedor!='LTL-Truck'?<span className='ContenidoPeso'>{datos.containerSize+' '+datos.containerType}</span>:''}

                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='PesoEnvio'>Weight:</span>:''}
                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='ContenidoPeso'>{datos.Peso+' kg'}</span>:''}
                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='PesoEnvio'>CBM:</span>:''}
                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='ContenidoPeso'>{parseFloat(datos.CBM).toFixed(2)+' CBMs'}</span>:''}

                </div>
              </div>
            </div>
            <div className='StatusContentEnvio'>
                {datos.Status === 'Peticion'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon  color='grey' size='big'  name='payment' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>Pending</h4>
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                  </div>:<div></div>
                }
                {datos.Status === 'Aceptado'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon  color='blue' size='big'  name='check' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>Accepted</h4>
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                  </div>:<div></div>
                }
                {datos.Status === 'Proceso'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon  color='yellow' size='big'  name='ship' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>in Process</h4>
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                    <div className="clear"></div>
                    {datos.solicitudPago?
                      <div style={{margin:'0px auto',textAlign:'center'}}>
                      <Icon  color='blue' size='big'  name='money' />
                      <h4 style={{marginTop:'0px', color:'grey'}}>Payment required</h4>
                      </div>:<div></div>
                    }

                  </div>:<div></div>
                }
                {datos.Status === 'Pagado'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon color='green' size='big'  name='payment' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>Paid out</h4>
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                  </div>:<div></div>
                }
                {datos.Status === 'Finalizado'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon  color='green' size='big'  name='computer' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>Payment released</h4>
                    {!datos.pagado?
                      <div>
                      <Icon  color='yellow' size='large'  name='clock outline' />
                      <h4 style={{marginTop:'0px', color:'grey'}}>CEIF is preparing your payment</h4>
                      </div>:<div></div>
                    }
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                  </div>:<div></div>
                }
                {datos.Status === 'Cancelado'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon  color='red' size='big'  name='ban' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>Cancelled</h4>
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                  </div>:<div></div>
                }
            </div>

          </Card.Content>
          {datos.Cancelacion?
            <div className='CancelarcionMsj'>
              <Card.Header>You have {datos.Cancelacion} hrs for cancellation without any cancellation charge</Card.Header>
              <Card.Meta>otherwise you will be charged {datos.MontoCancelacion} dlls</Card.Meta>
            </div>:
            <div className='CancelarcionMsj'>
            <Card.Meta style={{fontSize:'15px'}}>Your shipment has not yet been accepted, you can cancel without charges</Card.Meta>
            </div>
          }
      </Card>

        <Modal  open={this.state.open} onClose={this.close} style={{width:'80%'}}>
          <div className='TransaccionID'>
            <p>Operation : {datos.idTransaccion}</p>
          </div>
          <Card.Content id='contentCardModalVendedor'>

            <div className='contentImgEnvio'>
            {datos.contenedor==='LCL'||datos.contenedor==='LTL-Truck'||datos.contenedor==='Aereo'?
              <img src={DireccionFront+'/imagenes/pallet.png'} />
                :
                <img src={DireccionFront+'/imagenes/'+imagen} />
              }
            </div>

            <div className='InfoContent'>
              <div className='TitleEnvio'>
                <span>
                {datos.contenedor === 'Aereo'?'Air':datos.contenedor==='FCL-Ferro'?'FCL-FFCC':datos.contenedor}
                </span>
                <span>
                  Freigth
                </span>
                <span>
                  {datos.origen+ '   ->  '}
                </span>
                <span>
                  {datos.destino}
                </span>
              </div>
              <div className='descriptionContent'>
                <div>
                  <p className='ContenidoEnvio'>Contain: {datos.contenido}</p>{datos.IMOCargo?<span className='CargoIMO'>IMO</span>:''}
                </div>
                <div className='ContentPriceEnvioModal'>
                  <span className='PagoEnvioModal'>{'$'+PrecioSNComision+' USD'}</span>
                  <span className='TipoPagoEnvio'>{datos.Payment}</span>
                </div>
                <div className='MedidasEnvio'>

                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck' && datos.ArrayUnidades?
                    <div className='TablaMedidas'>
                        <div className='HeadersContent'>
                          <div className='HeaderItem'>
                            <h4>Quantity</h4>
                          </div>
                          <div className='HeaderItem'>
                            <h4>Measurement (cm)</h4>
                          </div>
                          <div className='HeaderItem'>
                            <h4>CBMs</h4>

                          </div>
                          <div className='HeaderItem'>
                            <h4>Weight (kg)</h4>
                          </div>
                        </div>
                        <div className='MedidasContent'>

                          {datos.ArrayUnidades?
                            datos.ArrayUnidades.map((it,index)=>{
                              return(
                                <div className='itemCellRow'>
                                  <div className='itemCell'>

                                    <p>{it.Unidades}</p>
                                  </div>
                                  <div className='itemCell'>
                                    <p>{it.Lenght} x {it.Width} x {it.Height} </p>
                                  </div>
                                  <div className='itemCell'>
                                      <p>{parseFloat(it.CBM).toFixed(2)}</p>
                                  </div>
                                  <div className='itemCell'>
                                      <p>{it.Weight?parseInt(it.Weight):'none'}</p>
                                  </div>
                                </div>
                              )
                            })
                            :
                            <div></div>
                          }
                          <div className='itemCellRow'>
                            <div className='itemCell'>
                              <p style={{textAlign:'left'}} ><span>Total</span> {respuestaCalculador.UnidadesTotal}</p>
                            </div>
                            <div className='itemCell'>
                              <p style={{textAlign:'left'}} >none</p>
                            </div>
                            <div className='itemCell'>
                              <p style={{textAlign:'left'}} ><span>Total</span> {respuestaCalculador.CBMTotal}</p>
                            </div>
                            <div className='itemCell'>
                              <p style={{textAlign:'left'}} ><span>Total</span> {respuestaCalculador.PesoTotal}</p>
                            </div>
                          </div>
                        </div>
                    </div>
                    :
                    <div>

                    </div>
                  }
                </div>
                </div>
            </div>
          </Card.Content>
          <Divider/>
          <div className="StatusEnvioUsuario">
            <div className="statusContainer">
                <span className='routeShipping'>
                <span className='routeIcon'>
                  <Icon  name='shipping'className={datos.objServicios.PO?'icono active':'icono'}  />
                </span>
                <span className={datos.objServicios.PO?'line active':'line'}>
                  <Icon id={datos.objServicios.PO?'dot-left-active':'dot-left'} name='dot circle outline' />
                  <Icon id={datos.objServicios.CO?'dot-right-active-blue':'dot-right'} name='dot circle outline' />
                </span>
                </span>
                <span className='routeShipping'>
                  <span className='routeIcon'>
                  <Icon  name='ship' className='icono activeBlue'  />
                  </span>
                  <span className='line activeBlue'></span>
                </span>
                <span className='routeShipping'>
                  <span className='routeIcon'>
                  <Icon  name='shipping'className={datos.objServicios.PD?'icono active':'icono'}  />
                  </span>
                  <span className={datos.objServicios.PD?'line active':'line'}>
                 <Icon id={datos.objServicios.CD?'dot-left-active-blue':'dot-left'} name='dot circle outline' />
                 <Icon id={datos.objServicios.PD?'dot-right-active':'dot-right'} name='dot circle outline' />
                 </span>
                </span>
            </div>
            <div className="StatusTags">
              <div className={datos.objServicios.PO?'itemStatusUsuario activo':'itemStatusUsuario'}>
                <p>Local Pick Up</p>
              </div>
              <div className={datos.objServicios.CO?'itemStatusUsuario activo':'itemStatusUsuario'}>
                <p>Origin Charges</p>
              </div>
              <div className={datos.objServicios.CO?'itemStatusUsuario activo':'itemStatusUsuario'}>
                <p>Rate</p>
              </div>
              <div className={datos.objServicios.CD?'itemStatusUsuario activo':'itemStatusUsuario'}>
                <p>Dest. Charges</p>
              </div>
              <div className={datos.objServicios.PD?'itemStatusUsuario activo':'itemStatusUsuario'}>
                <p>Local Delivery</p>
              </div>
            </div>
          </div>


          {datos.Status != 'Peticion'?
            <div>
              <Divider/>
              <Card.Content>
                <div>
                 {
                   !datos.numeroBL?
                   <Form style={{width:'95%'}}>
                     <Form.Group style={{marginTop:'-40px'}} inline width='equal'>
                       <Form.Input error={this.state.statusStringError} onChange={this.handleFormBl.bind(this,'bl')} value={this.state.numeroBl} style={{width: "120px"}}label='BL number '  placeholder="BL number"  />
                       <Form.Input error={this.state.causaError}onChange={this.handleFormBl.bind(this,'nombreBarco')} label='Vessel name'  id="Nombre de barco" placeholder="Vessel name"  />
                       <Form.Input error={this.state.observacionesError} onChange={this.handleFormBl.bind(this,'numeroViaje')}label='Voyage'  id="autocompleteDestino" placeholder="Voyage"/>

                     </Form.Group>
                     <Form.Group  inline width='equal'>
                         <Form.Input error={this.state.observacionesError} onChange={this.handleFormBl.bind(this,'numeroBooking')}label='Booking ref'  placeholder="booking ref"/>
                         <label>Shipment date</label>
                         <TextField
                           id="date"
                           type="date"
                           defaultValue={this.props.fecha}
                           onChange={this.handleFormBl.bind(this,'fechaEmbarque')}
                           InputLabelProps={{
                             shrink: true,
                           }}
                         />
                         <Button  style={{marginLeft:'4vh'}}secondary onClick={this.subirBlDetails.bind(this)}
                           disabled={!this.state.numeroBl||!this.state.nombreBarco||!this.state.numeroViaje||!this.state.numeroBooking} >Update BL</Button>
                     </Form.Group>
                   </Form>
                   :
                   <div style={{width:"100%",minWidth:'900px',maxHeight:'20vh', display:"inline-flex"}}>
                     <div style={{paddingLeft:"9%",minWidth:'20vh'}}>
                     <Card.Meta className='labelMini'>BL Number</Card.Meta>
                     <Card.Header>{datos.numeroBL}</Card.Header><br/>
                     </div>

                     <div style={{paddingLeft:"9%",minWidth:'20vh'}}>
                       <Card.Meta className='labelMini'>Vessel name</Card.Meta>
                       <Card.Header>{datos.nombreBarco}</Card.Header><br/>
                     </div>

                     <div style={{paddingLeft:"9%",minWidth:'25vh'}}>
                     <Card.Meta className='labelMini'>Voyage</Card.Meta>
                     <Card.Header>{datos.numeroViaje}</Card.Header><br/>
                     </div>

                     <div style={{paddingLeft:"9%",minWidth:'25vh'}}>
                     <Card.Meta className='labelMini'>Booking ref</Card.Meta>
                     <Card.Header>{datos.numeroBooking}</Card.Header><br/>
                     </div>

                   </div>
                 }
                </div>
              </Card.Content>
              <Divider/>
            </div>
            :<div></div>
          }

          <div className="content-section implementation">
                <Card.Content style={{marginLeft:'2%'}}>
                  <div className="Booking-details"  style={{width:"100%",maxHeight:'20vh'}}>
                    <h4>Booking details</h4>
                    <div style={{width:"100%",maxHeight:'20vh',display:"inline-flex"}}>
                      <div style={{paddingLeft:"4%",width:'22%'}}>
                        <Card.Meta className='labelMini'>Cargo description</Card.Meta>
                        <Card.Header>{datos.nombreProducto}</Card.Header><br/>
                      </div>
                      <div style={{paddingLeft:"4%",width:'22%'}}>
                        <Card.Meta className='labelMini'>Pick-up date</Card.Meta>
                        <Card.Header>{datos.fechaRecoleccion}</Card.Header><br/>
                      </div>
                      <div style={{paddingLeft:"4%",width:'22%'}}>
                        <Card.Meta className='labelMini'>Comments</Card.Meta>
                        <Card.Header>{datos.observacionesContacto}</Card.Header><br/>
                      </div>
                      <div style={{paddingLeft:"4%",width:'22%'}}>
                        <Card.Meta className='labelMini'>Hs Code</Card.Meta>
                        <Card.Header>{datos.hsCode}</Card.Header><br/>
                      </div>
                    </div>
                  </div>
                  <div className="Shipper-details" style={{width:"100%",maxHeight:'20vh'}}>
                    <h4>Shipper details</h4>
                    <div style={{width:"100%",maxHeight:'20vh', display:"inline-flex"}}>
                      <div style={{paddingLeft:"4%",width:'22%'}}>
                        <Card.Meta className='labelMini'>Company´s name</Card.Meta>
                        <Card.Header>{datos.NombreShipper}</Card.Header><br/>

                        <Card.Meta className='labelMini'>Contact name</Card.Meta>
                        <Card.Header>{datos.contactNameShipper}</Card.Header><br/>
                      </div>
                      <div style={{paddingLeft:"4%",width:'22%'}}>
                        <Card.Meta className='labelMini'>Phone </Card.Meta>
                        <Card.Header>{datos.TelefonoShipper}</Card.Header><br/>

                        <Card.Meta className='labelMini'>Email</Card.Meta>
                        <Card.Header>{datos.EmailShipper}</Card.Header><br/>
                      </div>
                      <div style={{paddingLeft:"4%",width:'22%'}}>
                        <Card.Meta className='labelMini'>Address</Card.Meta>
                        <Card.Header>{datos.addressShipper}</Card.Header><br/>

                        <Card.Meta className='labelMini'>Vat/TAX ID/RFC</Card.Meta>
                        <Card.Header>{datos.taxIdShipper}</Card.Header><br/>
                      </div>
                    </div>
                  </div>
                  <div className="Consignee-details" style={{width:"100%",maxHeight:'20vh'}}>
                    <h4>Consignee details</h4>
                    <div style={{width:"100%",maxHeight:'20vh', display:"inline-flex"}}>
                      <div style={{paddingLeft:"4%",width:'22%'}}>
                        <Card.Meta className='labelMini'>Company´s name</Card.Meta>
                        <Card.Header>{datos.NombreConsig}</Card.Header><br/>
                      </div>
                      <div style={{paddingLeft:"4%",width:'22%'}}>
                        <Card.Meta className='labelMini'>Contact name</Card.Meta>
                        <Card.Header>{datos.contactNameConsignee}</Card.Header><br/>
                      </div>
                      <div style={{paddingLeft:"4%",width:'22%'}}>
                        <Card.Meta className='labelMini'>Phone </Card.Meta>
                        <Card.Header>{datos.TelefonoConsig}</Card.Header><br/>
                      </div>
                      <div style={{paddingLeft:"4%",width:'22%'}}>
                        <Card.Meta className='labelMini'>Email</Card.Meta>
                        <Card.Header>{datos.EmailConsig}</Card.Header><br/>
                      </div>
                      <div style={{paddingLeft:"4%",width:'22%'}}>
                        <Card.Meta className='labelMini'>Address</Card.Meta>
                        <Card.Header>{datos.addressConsignee}</Card.Header><br/>
                      </div>
                    </div>
                  </div>
                  <div className="Notify" style={{width:"100%",maxHeight:'20vh'}}>
                    <h4>Notify:</h4>
                    <div style={{width:"100%",maxHeight:'20vh', display:"inline-flex"}}>
                      <div style={{paddingLeft:"4%",width:'22%'}}>
                        <Card.Meta className='labelMini'>Name</Card.Meta>
                        <Card.Header>{datos.NombreNotificar}</Card.Header><br/>
                      </div>
                      <div style={{paddingLeft:"4%",width:'22%'}}>
                        <Card.Meta className='labelMini'>Phone </Card.Meta>
                        <Card.Header>{datos.TelefonoNotificar}</Card.Header><br/>
                      </div>
                      <div style={{paddingLeft:"4%",width:'22%'}}>
                        <Card.Meta className='labelMini'>Email</Card.Meta>
                        <Card.Header>{datos.EmailNotificar}</Card.Header><br/>
                      </div>
                    </div>
                  </div>
                  <Divider/>
                  <div className="cancelContent">
                    {datos.Status === 'Peticion'?
                      <div>
                        {this.state.cancelSeccion?
                          <div>
                              <div className="CancelCard">
                                  <Message id='cardCancelacion' icon>
                                    <Icon name='circle notched' loading />
                                    <Message.Content>
                                      <Message.Header>Payment Will be requested</Message.Header>
                                        Payment deadline <input  type="number"  onChange={this.handleRefCancelacion} required name="cancelacion" min="1" step="1" />Hrs to change without cancellation charge.
                                    </Message.Content>
                                  </Message>
                                  <Message id='cardCancelacion' icon>
                                    <Icon name='circle notched' loading />
                                    <Message.Content>
                                    <Message.Header>Cancellation Charge</Message.Header>
                                          User must pay <input type="number" onChange={this.handleRefMontoCancelacion} required name="cancelacion" min="1" step="1"/> “USD for cancellation after deadline.

                                    </Message.Content>
                                  </Message>
                              </div>
                              <div className="clear"></div>
                              <div className='contentBtnCancelacion'>
                                <Button id="btnCancelacion" secondary loading={this.state.actualizadoCancelacion} onClick={this.actualizarCancelacion.bind(this)} disabled={!this.state.Cancelacion && !this.state.MontoCancelacion} >Update cancellation</Button>
                              </div>
                          </div>
                          :
                          <div>
                            <div className='contentBtnCancelacion'>
                              <Button id="btnAceptBooking"  primary onClick={this.openCancelar} >Accept Booking</Button>
                            </div>
                          </div>
                        }
                      </div>:
                      <div>
                      </div>
                    }
                    {datos.Status === 'Aceptado'?
                        <div>
                          <Message icon>
                            <Icon name='check' color='green' accept />
                            <Message.Content>
                              <Message.Header>lets do it</Message.Header>
                              you can start managing the shipment
                            </Message.Content>
                          </Message>
                        </div>:<div></div>
                    }
                    {datos.solicitudPago?
                      <div style={{width:'90%'}}>
                      <Divider></Divider>
                        <Grid columns={2} stackable textAlign='center'>
                          <Grid.Row verticalAlign='middle'>
                            <Grid.Column>
                              <img src={DireccionFront+'/imagenes/bank.png'} style={{width:'60%'}}/>
                            </Grid.Column>
                            <Grid.Column>
                              <div>

                                {datos.Status === 'Proceso'?
                                    <div>
                                      <Card style={{width:'100%'}}>
                                        <Card.Content>
                                          <Card.Meta>{datos.nombreBanco}</Card.Meta>
                                          <Card.Header>{datos.bankPago}</Card.Header>
                                          <Card.Header>{datos.holderCuenta}</Card.Header>
                                          <Card.Description><p><strong>Amount:</strong> ${PrecioSNComision} USD<br/></p></Card.Description>
                                        </Card.Content>
                                      </Card>
                                      <Message icon>
                                        <Icon name='circle notched' loading />
                                        <Message.Content>
                                          <Message.Header>Payment Will be requested {datos.fechaSolicitudPago}</Message.Header>
                                            Payment deadline {datos.DiasPago} days to complete your payment.
                                        </Message.Content>
                                      </Message>
                                      <Button onClick={this.handleEmailSendSupport} color='black'style={{width:'100%',marginBottom:'2vh'}}>Doubts?</Button>
                                    </div>:<div></div>
                                }
                                {datos.Status === 'Pagado'?
                                  <div>
                                    <Card style={{width:'100%'}}>
                                      <Card.Content>
                                        <Card.Meta>CEIF</Card.Meta>
                                        <Card.Header>Your money is safe with us</Card.Header>
                                        <Card.Description><p><strong> we are waiting for the users rating </strong></p></Card.Description>
                                        <Card.Description><p><strong> To release the payment </strong></p></Card.Description>

                                      </Card.Content>
                                      <Button onClick={this.handleEmailSendSupport} color='black'style={{width:'100%',marginBottom:'2vh'}}>Doubts?</Button>
                                    </Card>
                                  </div>:<div></div>
                                }
                                {datos.Status === 'Finalizado'?
                                  <div>
                                    {this.props.datos.Rate?
                                      <div>
                                      {datos.pagado?
                                        <Card style={{width:'100%'}}>
                                          <Card.Content>
                                            <Card.Meta>CEIF</Card.Meta>
                                            <Card.Header>Your payment has been successfully transferred</Card.Header>
                                            <Card.Description><p><strong>Remember that payments take 3 to 5 business days to be reflected in your account.</strong></p></Card.Description>
                                            <Card.Description><p>For more information about deposits and commissions visit:</p></Card.Description>
                                            <Card.Description><p><a>Deposits and Commissions</a></p></Card.Description>

                                              <Icon color='teal' size='huge' style={{marginLeft:'2%'}} name='checkmark' />

                                          </Card.Content>
                                        </Card>:
                                         <Card style={{width:'100%'}}>
                                          <Card.Content>
                                            <Card.Meta>CEIF</Card.Meta>
                                            <Card.Header>Your payment has succesfuly confirmed</Card.Header>
                                            <Card.Description><p><strong>The customer has qualified and your payment will be released</strong></p></Card.Description>
                                            <Card.Description><p>For more information about deposits and commissions visit:</p></Card.Description>
                                            <Card.Description><p><a>Deposits and Commissions</a></p></Card.Description>

                                              <Icon color='teal' size='huge' style={{marginLeft:'2%'}} name='checkmark' />

                                          </Card.Content>
                                        </Card>
                                      }
                                      </div>:
                                      <div>
                                        <Card style={{width:'100%'}}>
                                          <Card.Content>
                                            <Card.Meta>CEIF</Card.Meta>
                                            <Card.Header>Your money is safe with us</Card.Header>
                                            <Card.Description><p><strong>Rank the operation</strong></p></Card.Description>
                                            <Rating icon='star' size='huge' maxRating={5} onRate={this.handleRate} />

                                          </Card.Content>
                                          <Button onClick={this.handleEmailSendSupport} color='black'style={{width:'100%',marginBottom:'2vh'}}>Doubts?</Button>
                                        </Card>
                                      </div>
                                    }
                                  </div>:<div></div>
                                }
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                          {this.state.openFormEmail?
                            <div className="FormInputsEmail">
                              <h2>Send us an email and tell us what your problem is</h2>
                              <div className='inputsMitad'>
                                <input onChange={this.handleNombreSupport} type="text" name="nombre" placeholder='Name'/>
                                <input onChange={this.handleCorreoSupport} type="email" name="correo" placeholder='Email'/>
                              </div>
                              <div className='inputsMitad'>
                              <input onChange={this.handleTelefonoSupport} type="number" name="telefono" placeholder='Mobil'/>
                              <input onChange={this.handlePaisSupport} type="text" name="country" placeholder='Country'/>
                              </div>
                              <textarea onChange={this.handleMensajeSupport} type="text" name="nombre" placeholder='Message'/>
                              <div className='ContentreCAPTCHA'>
                                <ReCaptcha
                                  ref={(el) => {this.captchaDemo = el;}}
                                  sitekey={'6LenA-YUAAAAAO8qlHk2Q7hqYwkIv-YeniKKHl26'}
                                  onloadCallback={this.onLoadRecaptcha}
                                  verifyCallback={this.verifyCallback}
                                />
                              </div>
                              <div className='btnSend' onClick={this.sendEmailSupport}><p>Send email</p></div>
                            </div>:<div></div>
                          }
                        </Grid>
                      </div>
                      :<div></div>
                    }
                  </div>
                </Card.Content>

          </div>

          {datos.Status != 'Peticion'?
            <div style={{width:'95%',padding:'3vh'}}>
              <Form style={{width:'95%'}}>
                <Form.Group style={{marginTop:'-40px'}} inline width='equal'>
                  <Form.Input error={this.state.statusStringError} onChange={this.handleForm.bind(this,'status')} value={this.state.statusString} style={{width: "120px"}}  placeholder="Write status"  />
                  <Form.Input error={this.state.causaError}onChange={this.handleForm.bind(this,'causa')}   id="autocompleteOrigen" placeholder="Reason"  />
                  <Form.Input error={this.state.observacionesError} onChange={this.handleForm.bind(this,'observaciones')}  id="autocompleteDestino" placeholder="Comments"/>
                  <Button  style={{marginTop:'1.6vh'}}secondary onClick={this.subirStatus.bind(this)}
                    disabled={!this.state.statusString||!this.state.causa||!this.state.observaciones} >New Status</Button>
                </Form.Group>
              </Form>

              <AcordionStatus data={ListaStatus}/>
              <label style={{textAlign:'left',margin:'20px 99px 0'}}> Files</label>
              <div style={{width:'85%',padding:'3vh'}}>
               <Grid columns={2}>

                 <Grid.Column style={{overflowY:'scroll'}} width={12}>

                   {
                     arrayArchivos.map((it,key)=>{
                       return(<Chips data={it}/>)
                     })

                   }

                   <div className='div-uploader-docs-envios' style={{width:'100%'}}>

                       <FilePond ref={ref => this.pond = ref}
                                 files={this.state.files}
                                 allowMultiple={true}
                                 maxFiles={3}
                                 labelIdle="Add files"
                                 onupdatefiles={(fileItems) => {
                                     // Set current file objects to this.state
                                     this.setState({
                                         files: fileItems.map(fileItem => fileItem.file)
                                     });
                                 }}>
                       </FilePond>
                   </div>

                 </Grid.Column>
                 <Grid.Column width={4}>
                 <Image style={{width:'100%',paddingLeft:'10%'}}  src={DireccionFront+'/imagenes/world.png'} />
                 </Grid.Column>
               </Grid>
              </div>
            </div>
            :
            <div></div>
          }






          <Modal.Actions>
          {!this.state.Pagado && datos.Status === 'Aceptado'?
          <div>
            <Button
              primary
              icon='payment'
              labelPosition='right'
              content="Request payment"
              onClick={this.SolicitarPago.bind(this)}
              disabled={solicitudPago}
            />
          </div>
            :
          <div>
          </div>
          }

            <Button color='red' onClick={this.close}>
              Cancel
            </Button>
            <Button
              positive
              icon='checkmark'
              labelPosition='right'
              content="Accept"
              onClick={this.actualizarDatos.bind(this)}
            />
          </Modal.Actions>
        </Modal>
        <Modal open={this.state.openPayModal} onClose={this.closePayModal.bind(this)}>
          <Modal.Header>Payment Method</Modal.Header>
          <Modal.Content>
              { this.state.pasoPago===2?
                <div>
                  <Grid columns={2} stackable textAlign='center'>
                  <Divider vertical></Divider>
                    <Grid.Row verticalAlign='middle'>
                      <Grid.Column>
                        <img src={DireccionFront+'/imagenes/bank.png'} style={{width:'90%'}}/>
                      </Grid.Column>
                      <Grid.Column>
                        <Message negative>
                           <Message.Header>You havent registered a bank account yet</Message.Header>
                           <p>It is important that you do it, because there you will receive your payments</p>
                        </Message>
                        <Link to="/seller/Perfil">
                        <Button color='black' style={{width:'100%'}}>Add new Bank account</Button>
                        </Link>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
                :this.state.pasoPago===3?
                <div>
                  <Grid columns={2} stackable textAlign='center'>
                  <Divider vertical></Divider>
                    <Grid.Row verticalAlign='middle'>
                      <Grid.Column>
                        <img src={DireccionFront+'/imagenes/bank.png'} style={{width:'90%'}}/>
                      </Grid.Column>
                      <Grid.Column>
                        <Card style={{width:'100%'}}>
                          <Card.Content>
                            <Card.Meta>{this.state.bank.BancoName}</Card.Meta>
                            <Card.Header>{this.state.bank.Iban}</Card.Header>
                            <Card.Header>{this.state.bank.NombreTitular}</Card.Header>
                            <Card.Description><p><strong>Amount:</strong> ${PrecioSNComision} USD<br/></p></Card.Description>
                          </Card.Content>
                        </Card>
                        <Message icon>
                          <Icon name='circle notched' loading />
                          <Message.Content>
                            <Message.Header>Payment Will be requested</Message.Header>
                              Payment deadline <input type="number" onChange={this.handleRefDiasPago} value={this.state.DiasPago} required name="price" min="15" step="1"/> days to complete your payment.
                          </Message.Content>
                        </Message>
                        <Button primary onClick={this.escribirSolicitudPago.bind(this)}style={{width:'100%',marginBottom:'2vh'}}>Accept</Button>
                        <Link to="/seller/Perfil">
                        <Button color='black' style={{width:'100%'}}>Change Paymeny Account</Button>
                        </Link>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
                :<div></div>
              }
          </Modal.Content>
        </Modal>

        <Modal   open={this.state.openModalStatus} onClose={this.HandlemodalStatus}>
          <Modal.Header id={"ModalPayHeader"}>
            <Icon name='archive' />
            CEIF status system
          </Modal.Header>
          <Modal.Content id={"ModalPayContent"}>

          <div style={{display:'inline-flex', width:'100%',margin:'10px 0px'}}>
            <div style={{display:'block', width:'30%' , textAlign:'center'}}>
              <Icon  color='grey' size='big'  name='payment' />
              <h4 style={{marginTop:'0px', color:'grey'}}>Pending</h4>
            </div>
            <div style={{display:'block', width:'70%'}}>
              <p>The shipment has been sent by the customer, and you have to set a cancellation date and a cancellation price, and get down to work</p>
            </div>
          </div>

           <div style={{display:'inline-flex', width:'100%',margin:'10px 0px'}}>
            <div style={{display:'block', width:'30%' , textAlign:'center'}}>
              <Icon  color='blue' size='big'  name='check' />
                  <h4 style={{marginTop:'0px', color:'grey'}}>Accepted</h4>
            </div>
            <div style={{display:'block', width:'70%'}}>
              <p>You have accepted the shipment, it is time to get down to work, for the following Status, you must require payment to the customer once it is time</p>
            </div>
          </div>

           <div style={{display:'inline-flex', width:'100%',margin:'10px 0px'}}>
            <div style={{display:'block', width:'30%' , textAlign:'center'}}>
              <Icon  color='blue' size='big'  name='money' />
              <h4 style={{marginTop:'0px', color:'grey'}}>Payment required</h4>
            </div>
            <div style={{display:'block', width:'70%'}}>
              <p>You have requested payment, we are waiting for the client to deposit</p>
            </div>
          </div>

           <div style={{display:'inline-flex', width:'100%',margin:'10px 0px'}}>
            <div style={{display:'block', width:'30%' , textAlign:'center'}}>
              <Icon  color='green' size='big'  name='payment' />
              <h4 style={{marginTop:'0px', color:'grey'}}>Paid out</h4>
            </div>
            <div style={{display:'block', width:'70%'}}>
              <p>The customer has paid, you can release the shipment only if the customer qualifies you and the payment is released</p>
            </div>
          </div>

           <div style={{display:'inline-flex', width:'100%',margin:'10px 0px'}}>
            <div style={{display:'block', width:'30%' , textAlign:'center'}}>
              <Icon  color='green' size='big'  name='computer' />
              <h4 style={{marginTop:'0px', color:'grey'}}>Payment released</h4>
            </div>
            <div style={{display:'block', width:'70%'}}>
              <p>The customer has qualified and CEIF is processing the payment to be deposited to your bank account</p>
            </div>
          </div>
         <div style={{display:'inline-flex', width:'100%',margin:'10px 0px'}}>
            <div style={{display:'block', width:'30%' , textAlign:'center'}}>
              <Icon color='green' size='big'  name='angle up' />
              <h4 style={{marginTop:'0px', color:'grey'}}>CEIF has paid</h4>
            </div>
            <div style={{display:'block', width:'70%'}}>
              <p>CEIF has sent you the transfer of your bank account, remember that it may take 3-5 business days to reflect the transaction</p>
            </div>
          </div>

          </Modal.Content>
          <Modal.Actions>
            <Button positive   onClick={this.HandlemodalStatus}>
              <Icon name='checkmark' /> Accept
            </Button>
          </Modal.Actions>
        </Modal>


        <Dimmer active={this.state.drimmerActivo} page>
        <Loader>
          <Header as='h2' icon inverted>
            Loading ....
          </Header>
        </Loader>
        </Dimmer>
      </div>
    )
  }
}

class AcordionStatus extends Component{
  state = { activeIndex: 1,datos:[{Tipo:'',FechaHora:'',TiempoProceso:'',TiempoAcumulado:'',Status:'',Causa:'',Observaciones:''}]}

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }
  UNSAFE_componentWillMount(){
    let self=this;
    axios.post(Direccion+'/getHours')
    .then(res=>{
      var datos=FiltrarTiemposStatus(this.props.data.reverse(),res.data);
        self.setState({
          datos:datos
        })

    })
  }

  render(){
    const { activeIndex } = this.state
    var data=this.state.datos;
    var length=data.length-1;
    return(
      <Accordion style={{width:'100%', marginTop:'30px'}} styled>
        <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
        <Icon name='dropdown' />

          <Table sortable celled fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Cargo type</Table.HeaderCell>
                <Table.HeaderCell>Update time and date</Table.HeaderCell>
                <Table.HeaderCell>Time in process</Table.HeaderCell>
                <Table.HeaderCell>Elapsed time</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Status comments</Table.HeaderCell>
                <Table.HeaderCell>Comments</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
           <Table.Row>
             <Table.Cell>{data[0].Tipo === 'Aereo'?'Air':data[0].Tipo}</Table.Cell>
             <Table.Cell>{data[0].FechaHora}</Table.Cell>
             <Table.Cell>{data[0].TiempoProceso} Hrs</Table.Cell>
             <Table.Cell>{data[0].TiempoAcumulado} Hrs</Table.Cell>
             <Table.Cell>{data[0].Status}</Table.Cell>
             <Table.Cell>{data[0].Causa}</Table.Cell>
             <Table.Cell>{data[0].Observaciones}</Table.Cell>
           </Table.Row>
           </Table.Body>

          </Table>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>

        <Table sortable celled fixed>
          <Table.Body>
          {
            data.map((it,key)=>{
              if(key>0){
                return(
                  <Table.Row>
                    <Table.Cell>{it.Tipo === 'Aereo'?'Air':it.Tipo}</Table.Cell>
                    <Table.Cell>{it.FechaHora}</Table.Cell>
                    <Table.Cell>{it.TiempoProceso} Hrs</Table.Cell>
                    <Table.Cell>{it.TiempoAcumulado} Hrs</Table.Cell>
                    <Table.Cell>{it.Status}</Table.Cell>
                    <Table.Cell>{it.Causa}</Table.Cell>
                    <Table.Cell>{it.Observaciones}</Table.Cell>
                  </Table.Row>

                )
            }
            })
          }


          </Table.Body>
        </Table>
        </Accordion.Content>

      </Accordion>
    )
  }
}

class Chips extends Component{

  constructor(){
    super();
  }

  handleDelete() {
    window.open(this.props.data.archivoUrl);
  }

  render(){
    let nombre=this.props.data.nombre.length>28?this.props.data.nombre.substring(0,27):this.props.data.nombre;
    return(

      <Chip
        icon={<Icon name='file text' size='large' style={{color:'white'}}/> }
        label={nombre}
        clickable
        style={{width:'44vh',marginBottom:'1vh'}}
        color="primary"
        onDelete={this.handleDelete.bind(this)}
        onClick={this.handleDelete.bind(this)}
        deleteIcon={<Icon name='cloud download' size='large' style={{color:'white'}} />}
      />
    )
  }
}



EnviosVendedor.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnviosVendedor);


function Expiracion(fechaInicial,diasVencimiento,pagado){
if (!pagado) {
  var date = new Date();
  let month = date.getMonth()+1;
  let day = date.getDate();
    if (month <10) {
        month = "0"+month;
      }
    if (day<10) {
      day = "0"+day;
    }

  let FechaActual = date.getFullYear()+'-'+month +'-'+day;
  let timeDiff  = (new Date(fechaInicial)) - (new Date(FechaActual));
  let days      = timeDiff / (1000 * 60 * 60 * 24);

  days *= -1;
  if (days===diasVencimiento ||days>diasVencimiento) {
    return true;
  }
  else {
    return false;
  }

}
else {
  return false;
}


}

exports.Expiracion=Expiracion;

import React, { Component } from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import Warning from "@material-ui/icons/Warning";
import {Image, Statistic } from 'semantic-ui-react'
import Danger from "./card/Danger.jsx";
import Card from "./card/Card.jsx";
import CardHeader from "./card/CardHeader.jsx";
import CardIcon from "./card/CardIcon.jsx";
import CardBody from "./card/CardBody.jsx";
import CardFooter from "./card/CardFooter.jsx";

import dashboardStyle from "./card/dashboardStyle.jsx";

class CardComponent extends React.Component {
  state = {
    value: 0
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    let noti=this.props.notificaciones>1||this.props.notificaciones===0?'Do you have '+this.props.notificaciones+' notifications':'Do you have 1 notification';
    const { classes } = this.props;
    return (
      <div>
        <Card >
          <CardHeader color={this.props.colorheader} stats icon>
           <CardIcon color={this.props.coloricon} >
              <Icon>{this.props.icono}</Icon>
            </CardIcon>
            <Statistic>
              <Statistic.Value style={{fontWeight:'700'}}>{this.props.dato}</Statistic.Value>
            </Statistic>

            <Statistic>
              <Statistic.Value text style={{fontSize:'1.3rem!important'}}>
                {this.props.titulo}

              </Statistic.Value>

            </Statistic>

          </CardHeader>

          <CardFooter stats>
          {
            this.props.saludo===true?

              <Statistic.Value text id='valueEstadisticas'>
                {noti}

              </Statistic.Value>

            :<div></div>
          }
          </CardFooter>
        </Card>
      </div>
    );
  }
}

CardComponent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(CardComponent);

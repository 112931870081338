import React, { Component } from 'react';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import {Router, Route, BrowserRouter, Switch, Link} from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';
import axios from 'axios';
import {Direccion,DireccionFront} from '../../strings/peticiones.js';
import FaceIcon from '@material-ui/icons/Face';
import Particles from 'react-particles-js';
import Badge from '@material-ui/core/Badge';
import ExpiracionPago from '../scripts/ExpiracionPago.js';
import PdfNormalizacion from '../scripts/documentosPdf.js';
import PenalizacionCancelacion from '../scripts/PenalizacionCancelacion.js';
import DeterminarImagen from '../scripts/imagenes.js';
import {SubirDato} from '../uploadData.js';
import FinalizadoProceso from '../scripts/filtrarProcesoFinalizados.js';
import {Fieldset} from 'primereact/fieldset';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Divider, Header, Icon,Rating,Dimmer,List,Loader,Table,Accordion,Modal,Button,Popup,Label,Grid,Form,Input,Image,Segment,Message} from 'semantic-ui-react';
import { Card } from 'semantic-ui-react'
import { DatePicker } from 'material-ui-pickers';
import BarraLateral from './sideBar.js';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

import './styleSeller/enviosVendedor.css';
import firebase from 'firebase'
import FiltrarTiemposStatus from '../scripts/fechasStatus.js'
import AlertSnack from '../notificacionCard.js';




const styles = theme=>({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  }

})


class EnviosUsuario extends Component{
  constructor(){
    super()
    var date = new Date();
    let month = date.getMonth()+1;
    let day = date.getDate();
      if (month <10) {
          month = "0"+month;
        }
      if (day<10) {
        day = "0"+day;
      }

    this.state={
      valueTabs:0,
      open: false,
      dimmer:true,
      selectedDate: date,
      fechaFormulario1:'2019-01-01',
      fechaFormulario2:date.getFullYear()+'-'+month +'-'+day,
      rutas:[],
      usuario:{},
      pagados:[],
      proceso:[],
      todosEnvios:[],
      cancelados:[],
      resultadoBusqueda:[],
      notificaciones:[],
      cvc: '',
      expiry: '',
      abrirVentanaIndividual:false,
      urlAnterior:'',
      urlInicial:'',
      individual:true,
      searching:false,

    }
  }
  UNSAFE_componentWillMount(){
    let self =this;
    let perfil={};
    var notificaciones=JSON.parse(localStorage.getItem('notificaciones'));

    axios.post(Direccion+`/tomarUsuario`,
      {id:firebase.auth().currentUser.uid,tipo:'Vendedor'})
      .then(res => {
        perfil=res.data;
          self.setState({
            notificaciones:notificaciones,
          })
        })
    this.getEnvios();
  }
  componentWillReceiveProps(e){
      let self=this;
      const valorParametrosVentana=e.location.pathname;
      let busquedaParam=self.BuscarParm(valorParametrosVentana);
      let EnvioAbrir=busquedaParam?self.ExistenciaDelParametro(busquedaParam,self.state.proceso):false;
      if(EnvioAbrir.value){
        let pagados=self.state.proceso;
        pagados.splice(EnvioAbrir.index,1);
        self.setState({abrirVentanaIndividual:EnvioAbrir.value,proceso:pagados})
      }
      else{
          let EnvioAbrir2=busquedaParam?self.ExistenciaDelParametro(busquedaParam,self.state.pagados):false;
          if(EnvioAbrir2.value){
            let pagados2=self.state.pagados;
            pagados2.splice(EnvioAbrir2.index,1);
            self.setState({abrirVentanaIndividual:EnvioAbrir2.value,pagados:pagados2})
          }
          else{
            let EnvioAbrir3=busquedaParam?self.ExistenciaDelParametro(busquedaParam,self.state.cancelados):false;
            if(EnvioAbrir3.value){
              let pagados3=self.state.cancelados;
              pagados3.splice(EnvioAbrir3.index,1);
              self.setState({abrirVentanaIndividual:EnvioAbrir3.value,cancelados:pagados3})
            }
          }
      }

  }
  BuscarParm=(parametroVentana)=>{
    let valor=parseInt(0);
    let i;
    let ban=false;
    for(i=0;i<parametroVentana.length;i++){

      if(parametroVentana[i]==='/'){
        valor=valor+parseInt(1);
      }
      if(valor===3){
        ban=true;
        break;
      }
    }
    if(ban){
      return parametroVentana.substring(i+parseInt(1),parametroVentana.length);
    }
    else return false
  }
  ExistenciaDelParametro=(p,data)=>{
    var value=false;
    for (var i=0;i<data.length;i++){
      if(data[i].idTransaccion===p){
        value=data[i];
        break;
      }
    }
    return {value:value,index:i}
  }
  getEnvios(){
    try{
    let datos;
    let self=this;
    let pagos=[];
    this.setState({
      open: false,
      searching:true,
    })
    axios.post(Direccion+`/tomarEnvios`,{data:{usuario:firebase.auth().currentUser.uid,inicio:this.state.fechaFormulario1,fin:this.state.fechaFormulario2}})
      .then(res => {
        datos=res.data;
        //revisar algoritmos
        pagos=FinalizadoProceso.cantidadDiferencia(datos);
        self.setState({
          todosEnvios:datos,
          pagados:pagos.terminados,
          proceso:pagos.proceso,
          cancelados:pagos.cancelados,
          searching:false,
        })
      })
      .then(()=>{
        const valorParametrosVentana=self.props.location.pathname;
        let busquedaParam=self.BuscarParm(valorParametrosVentana);
        let EnvioAbrir=busquedaParam?self.ExistenciaDelParametro(busquedaParam,pagos.proceso):false;
        if(EnvioAbrir.value){
          pagos.proceso.splice(EnvioAbrir.index,1);
          self.setState({valueTabs:0,abrirVentanaIndividual:EnvioAbrir.value,proceso:pagos.proceso,urlInicial:valorParametrosVentana})
        }
        else{
            let EnvioAbrir2=busquedaParam?self.ExistenciaDelParametro(busquedaParam,pagos.terminados):false;
            if(EnvioAbrir2.value){
            pagos.terminados.splice(EnvioAbrir2.index,1);
              self.setState({valueTabs:1,abrirVentanaIndividual:EnvioAbrir2.value,pagados:pagos.terminados,urlInicial:valorParametrosVentana})
            }
            else{
              let EnvioAbrir3=busquedaParam?self.ExistenciaDelParametro(busquedaParam,pagos.cancelados):false;
              if(EnvioAbrir3.value){
                pagos.cancelados.splice(EnvioAbrir3.index,1);
                self.setState({valueTabs:2,abrirVentanaIndividual:EnvioAbrir3.value,cancelados:pagos.cancelados,urlInicial:valorParametrosVentana})
              }
            }
        }
      })
    }catch(e){
      console.log(e);
    }
  }
  handleDateChange=(date)=>{
    let fecha=date.target.value;
     this.setState({
       fechaFormulario1:fecha
     })
   }
  handleDateChange2=(date)=>{
     let fecha=date.target.value;
      this.setState({
        fechaFormulario2:fecha
      })
  }
  handleChangeTab=(event, valueTabs)=>{
    this.setState({ valueTabs,individual:false });
  };
  buscarEnvio=(e)=>{
    let textToSearch = e.target.value;
    let EnvioEncontrado= [];

    if (e.target.value.length === 0) {
      this.setState({
        busquedaActiva:false,
      })
    }else{
      this.state.todosEnvios.forEach((it)=>{
        let contenedor = it.contenedor=== 'Aereo'?'Air': it.contenedor;

        if (it.idTransaccion === '-M2ArsPkqQNnVe3UOu3J') {
          console.log(this.compareTwoStrings(textToSearch,it.destino));
        }
       if(textToSearch===it.idTransaccion || it.origen.includes(textToSearch) || it.destino.includes(textToSearch) || contenedor.includes(textToSearch) ||textToSearch===it.origen||textToSearch===it.destino ||textToSearch===contenedor ){
         EnvioEncontrado.push(it);
       }
       else if(this.compareTwoStrings(textToSearch,it.origen)>0.3 || this.compareTwoStrings(textToSearch,it.destino)>0.3 || this.compareTwoStrings(textToSearch,contenedor)>0.3){
         EnvioEncontrado.push(it);
       }
      })
      this.setState({
        resultadoBusqueda:EnvioEncontrado,
        busquedaActiva:true,
      })
    }
  }

  compareTwoStrings=(strA,strB)=>{
      for(var result = 0, i = strA.length; i--;){
          if(typeof strB[i] === 'undefined' || strA[i] === strB[i]);
          else if(strA[i].toLowerCase() === strB[i].toLowerCase())
              result++;
          else
              result += 4;
      }
      return 1 - (result + 4*Math.abs(strA.length - strB.length))/(2*(strA.length+strB.length));
  }

  render(){
    const { classes } = this.props;
    const background='url('+DireccionFront+'/imagenes/envios.jpg)'

    return(


      <div className="div-principal-envios-usuario">

          <BarraLateral/>
          <div className='paticulasContent' >
            <Particles id='Paticulas' style={{backgroundImage:'url(https://ceif.app/imagenes/truck.jpg)', backgroundSize:'cover',backgroundPosition:'center',height:'600px',position:'absolute'}}
                 params={{
                   "particles": {
                       "number": {
                           "value": 40
                       },
                       "size": {
                           "value": 2
                       }
                   },
                   "interactivity": {
                       "events": {
                           "onhover": {
                               "enable": true,
                               "mode": "repulse"
                           }
                       }
                   }
               }} />
             <div className='contentTitulo'>
               <div className='titulo'>
                   <i class="material-icons" style={{ fontSize: '2em' }}>directions_boat </i>
                   <h3>Bookings</h3>
               </div>
             </div>
            <div>
            <div className="contentBuscador">
              <div className="Buscador" >
                <input type="text" onChange={this.handleDateChange} placeholder='From' onFocus={(e)=> e.currentTarget.type = "date"} onBlur={(e)=> {e.currentTarget.type = "text"; e.currentTarget.placeholder = "From"}}/>
                <input type="text" onChange={this.handleDateChange2} placeholder='to' onFocus={(e)=> e.currentTarget.type = "date"} onBlur={(e)=> {e.currentTarget.type = "text"; e.currentTarget.placeholder = "to"}}/>
                <div  className='btnBuscarFecha' onClick={this.getEnvios.bind(this)} >Search</div>
              </div>
              <div className='buscadorPorID'>
                <input  onChange={this.buscarEnvio} type="text" placeholder="Search..."/>
              </div>
            </div>
          </div>
        </div>




        <Tabs value={this.state.valueTabs} onChange={this.handleChangeTab}variant="fullWidth"  indicatorColor="secondary" textColor="primary">
            <Tab icon={<i class="material-icons">compare_arrows</i>} label="In transit" ></Tab>
            <Tab icon ={<i class="material-icons">done_outline</i>} label="Completed"></Tab>
            <Tab icon ={<i class="material-icons">highlight_off</i>} label="Cancelled"></Tab>
        </Tabs>
        {this.state.searching?
          <div>
            <div className="spinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          </div>
          :
          <div>
            {this.state.resultadoBusqueda && this.state.busquedaActiva?
              <div>
                {
                  this.state.resultadoBusqueda.map((it,key)=>{
                    return(<CardEnviosUser  usuario={this.state.usuario} totalData={this.state.resultadoBusqueda} notificaciones={this.state.notificaciones} recargarDatos={this.getEnvios.bind(this)} datos={it} key={key} parametrosVentana={this.state.parametrosVentana}/>)
                  })
                }
              </div>:
              <div>
                {this.state.abrirVentanaIndividual!=false ?
                  <CardEnviosUser  usuario={this.state.usuario} totalData={this.state.proceso} notificaciones={this.state.notificaciones} recargarDatos={this.getEnvios.bind(this)} datos={this.state.abrirVentanaIndividual} key={0} individual={this.state.individual}/>
                  :<div></div>
                }
                {this.state.valueTabs===0?
                  <div>
                  {
                    this.state.proceso.map((it,key)=>{
                      return(<CardEnviosUser  usuario={this.state.usuario} totalData={this.state.proceso} notificaciones={this.state.notificaciones} recargarDatos={this.getEnvios.bind(this)} datos={it} key={key} parametrosVentana={this.state.parametrosVentana}/>)
                    })

                  }
                  </div>
                  :
                  <div></div>
                }
                {this.state.valueTabs===1?
                  <div>
                    {
                      this.state.pagados.map((it,key)=>{
                        return(<CardEnviosUser usuario={this.state.usuario} totalData={this.state.pagados} notificaciones={this.state.notificaciones} recargarDatos={this.getEnvios.bind(this)} datos={it} key={key} parametrosVentana={this.state.parametrosVentana}/>)
                      })

                    }

                  </div>

                  :
                  <div></div>
                }
                {this.state.valueTabs===2?
                  <div>
                    {
                      this.state.cancelados.map((it,key)=>{
                        return(<CardEnviosUser usuario={this.state.usuario} totalData={this.state.cancelados} notificaciones={this.state.notificaciones} recargarDatos={this.getEnvios.bind(this)} datos={it} key={key} parametrosVentana={this.state.parametrosVentana}/>)
                      })
                    }
                  </div>
                  :
                  <div></div>
                }
              </div>
            }
          </div>
        }
      </div>

    )
  }
}


class CardEnviosUser extends Component{

  constructor(){
    super();

    this.state={
      open: false,
      openfinalizado: false,
      dimmer:true,
      files: [],
      badge:true,
      num:0,
      Cards:[],
      usuario:{},
      openconfirm:false,
      CardIndex:0,
      confirmRate:false,
      rate:0,
      Pagado:false,
      Vencido:false,
      mensajePop:'',
      confirmCancelar:false,
      Penalizacion:false,
      CancelarBooking:false,
      selectCard:false,
      selectCardPenalizacion:'',
      tipoPago:'',
      pasoPago:1,
      upload:false,
      LoaderStatus:0,
      openAlert:false,
      AlertMessage:'',
      AlertType:'',
      openModalStatus:false,
      paymentUotside:false,

    }
    this.UsingCard=this.UsingCard.bind(this);

  }

  UNSAFE_componentWillMount(){
    //revisar algoritmo
    if(this.props.individual){
      this.setState({  open: true})
      this.searchId();
    }
    let a = ExpiracionPago.Expiracion(this.props.datos.fechaSolicitudPago,this.props.datos.DiasPago,this.props.datos.pagado);
    let mensaje ="You have" +this.props.datos.Cancelacion+ "hours to cancellation without any cancellation charge";
    this.setState({
      Vencido: a,
      mensajePop:mensaje,

    })
  this.Inicio();
  }
  componentWillReceiveProps(){
    if(this.props.individual){
      this.setState({  open: true})
      this.searchId();
    }
  }




  handleInputChange = (e) => {
    const target = e.target;
    if (target.name === 'expiry') {
      var value=target.value;
      if (/^([0-9-/])*$/.test(target.value) ){
          if(value.length===2 ){
            this.setState({
              [target.name]:value.replace(/ |\//g, '')+'/',
            });
          }
          else{
            this.setState({
              [target.name]:value,
            });
          }
      }
    }else if (target.name === 'cvc'){
      var value=target.value;
      if (/^([0-9])*$/.test(target.value)){
        this.setState({
          [target.name]:value,
        });
      }

    }
  }//cierre handle

   Inicio=()=>{
    var num=0;
    let self = this;
    axios.post(Direccion+`/tomarCards`,
      {idUser:firebase.auth().currentUser.uid,tipo:"Vendedor",})
      .then(res => {
        if (res.data.length !=0 && res.data.length >0) {
          self.setState({
            Cards:res.data,
          })
        }
        else {
          self.setState({
            Cards:[],
          })
        }
    })

  if (this.props.notificaciones) {
    this.props.notificaciones.forEach((it)=>{
        if(it.idTransaccion===this.props.datos.idTransaccion){
          num++;
        }
    });
  }


    if(num>0){
      this.setState({
        badge:false,
        num:num
      })
    }
  }

  searchId(){
    if (this.props.notificaciones) {
       this.props.notificaciones.forEach((it)=>{
        if(it.idTransaccion===this.props.datos.idTransaccion){
          axios.post(Direccion+`/RefreshNotificacion`,{idNotificacion:it.key,tipo:'Vendedor'})
            .then(res => {
              var datos=res.data;
              if(datos!='OK'){
                alert('error');
              }
            })
        }
      })
    }

  }

  upLoadFile=()=>{
    var self =this;
      this.setState({
        LoaderStatus:1,
      })
      let FotoBancaria=SubirDato('usuarios/'+firebase.auth().currentUser.uid+'/FotoReciboBancario/'+this.props.datos.idTransaccion,firebase.storage(),this.state.files[0]);
      FotoBancaria.then(function(done){
        axios.post(Direccion+`/actualizarReciboTransferia`,{idEnvio:self.props.datos.idTransaccion,FotoRecibo:done})
          .then(res => {
            if (res.data === 'OK') {
             self.setState({
                LoaderStatus:2,
                fileOK:false,
                upload:false,
                openAlert:true,
                AlertMessage:'your file was uploaded correctly',
                AlertType:'positive',
              })
            }else{
              self.setState({
                openAlert:true,
                AlertMessage:'something went wrong',
                AlertType:'warning',
              })

            }
            setTimeout(function() {
              self.setState({
                LoaderStatus:0,
                fileOK:false,
                close:false,
              })
              self.props.recargarDatos();
            }, 1000);
          })

      });

    }

  confimar=()=>{
    this.setState({  openconfirm: true, })
  }


  UsingCard=(key)=>{
    this.setState({  CardIndex: key, selectCard:true, })
  }

  Pagar=()=>{
    this.setState({  dimmerActive: true,openconfirm:false,open:false })
    let self =this;
    if (this.state.CardIndex && this.state.expiry && this.state.cvc) {
      axios.post(Direccion+`/PagarEnvio`,
        {idUser:firebase.auth().currentUser.uid,tipo:"Vendedor",idEnvio:this.props.datos.idTransaccion,idVendedor:this.props.datos.Vendedor,idCard:this.state.CardIndex,Expiry:this.state.expiry,CVV:this.state.cvc})
        .then(res => {
          let errorMessage = '';

          if (res.data.error)  errorMessage = res.data.error.dataError.message;
          if(res.data==='OK'){
            self.setState({
              dimmerActive: false,
              loader: false,
               openAlert:true,
               titleAlert:'Success',
               AlertMessage:'your booking has been paid successfully',
               AlertType:'success',
            });
            setTimeout(function() {window.location.reload();}, 3000);
          }
          else if(res.data === "P101"){
            self.setState({
              dimmerActive: false,
              loader: false,
               openAlert:true,
               titleAlert:'Warning',
               AlertMessage:'complete your profile information',
               AlertType:'warning',
            });
          }
          else if(res.data === "340"){
            self.setState({
              dimmerActive: false,
              loader: false,
               openAlert:true,
               titleAlert:'Error',
               AlertMessage:'you are Banned',
               AlertType:'error',
            });
          }
          else if(res.data === "902"){
            self.setState({
              dimmerActive: false,
              loader: false,
               openAlert:true,
               titleAlert:'Error',
               AlertMessage:'CVV or Date is Incorrect',
               AlertType:'error',
            });
          }
          else if(res.data === "P1"){
            self.setState({
              dimmerActive: false,
              loader: false,
               openAlert:true,
               titleAlert:'Error',
               AlertMessage:'your country is misspelled',
               AlertType:'error',
            });
          }
          else if(res.data.statusCode === "202" && res.data.error){
            self.setState({
              dimmerActive: false,
              loader: false,
               openAlert:true,
               titleAlert:'Error',
               AlertMessage:'something went wrong,try another card, or another means',
               AlertType:'error',
            });
          }
          else{
            self.setState({
              dimmerActive: false,
              loader: false,
               openAlert:true,
               titleAlert:'Error',
               AlertMessage:'something went wrong,try another card, or another means',
               AlertType:'error',
            });
          }
      })
    }
  }



  ActualizarCancelacion=()=>{
    this.setState({  dimmerActive: true, CancelarBooking:false})
    let self= this;
    axios.post(Direccion+`/PagarCancelacion`,
      {Penalizacion:self.state.Penalizacion,tipo:'Vendedor',CantidadPenalizacion:self.props.datos.MontoCancelacion,idUser:firebase.auth().currentUser.uid,idEnvio:this.props.datos.idTransaccion ,idVendedor:this.props.datos.Vendedor, idCard:this.state.CardIndex,Expiry:this.state.expiry,CVV:this.state.cvc})
      .then(res => {
        if (res.data === "OK") {
          this.setState({
            dimmerActive: false,
            expiry:'',CVV:'',
            idCard:'',
            selectCard:false,
            openAlert:true,
            titleAlert:'Success',
            AlertMessage:'Successful cancellation',
            AlertType:'success',
            open:false,
          });
          window.location.reload();
        } else if(res.data === "P101"){
          self.setState({
            dimmerActive: false,
            loader: false,
             openAlert:true,
             titleAlert:'Warning',
            AlertMessage:'complete your profile information',
            AlertType:'warning',
            open:false,

          });
        }else if(res.data === "340"){
          window.location.reload();
        }
        else if (res.data === '902') {
          self.setState({
            dimmerActive: false,
            loader: false,
             openAlert:true,
            titleAlert:'Error',
            AlertMessage:'incorrect expiration date and cvv',
            AlertType:'error',
            open:false,

          });
        }
        else {
          this.setState({
             dimmerActive: false,
             openAlert:true,
             titleAlert:'Error',
             AlertMessage:'something went wrong,try another card, or another means',
            AlertType:'error',
            open:false,
            })

        }
    })
  }

  Calificar=()=>{
    let self = this;
    this.setState({  dimmerActive: true,confirmRate:false, open:false })
    axios.post(Direccion+`/ConfirmarPago`,
      {idVendedor:this.props.datos.Vendedor,Rate:this.state.rate, idUser:firebase.auth().currentUser.uid, idEnvio:this.props.datos.idTransaccion})
      .then(res => {
        axios.post(Direccion+`/CalificarUsuario`,
            {idUser:this.props.datos.Vendedor,tipo:"Vendedor",userRate:this.state.rate})
            .then(res => {
              if (res.data === "OK") {
                this.setState({
                 dimmerActive: false,
                 openAlert:true,
                  AlertMessage:'the rating updated successfully',
                  AlertType:'warning',
                  })
                self.props.recargarDatos();
              }
              else {
                this.setState({
                  dimmerActive: false,
                  openAlert:true,
                  AlertMessage:'something went wrong',
                  AlertType:'warning',
                })
                self.props.recargarDatos();
              }
          })
        })
    }

getPDF=()=>{
   let self = this;
   this.setState({paymentUotside:true})
   let pdfNew = PdfNormalizacion(this.props.datos.idTransaccion,this.props.datos.precio);
}

  CancelarBookingPenalizacion=()=>{
    this.setState({  dimmerActive: true,confirmCancelar:false });
    axios.post(Direccion+`/CalPenalizacion`,
        {DiasCancelacion:this.props.datos.Cancelacion,FechaOrigen:this.props.datos.FechaFijadaCancelacion})
        .then(res => {
          this.setState({
           dimmerActive: false,
           Penalizacion:res.data,
           CancelarBooking:true });
      })
  }
  show = dimmer => () =>{
    this.setState({ dimmer, open: true,badge:true})
    this.searchId();
  }
  showFinalizado = dimmer => () => this.setState({ dimmer, openfinalizado: true })
  UsingCardPenalizacion= (key) =>{this.setState({ selectCardPenalizacion: key});}
  AfterCard= () =>{this.setState({ selectCardPenalizacion: ''});}

  close = () =>{
    if(this.props.individual){

      window.location='/user/Envios';
    }
    else{
      this.setState({ open: false, openfinalizado: false, dimmerActive:false,dimmerDos:false});

    }
    }
  handleRate =(e, { rating, maxRating })=>{this.setState({ confirmRate: true, rate:rating});}
  generarPago=(tipoPago)=>{this.setState({  tipoPago: tipoPago,pasoPago:2, })}
  close2 = () =>{this.setState({tipoPago: "",pasoPago:1, openconfirm: false,dimmerDos:false,dimmerActive:false,});}
  handleCancelarBooking = () =>{this.setState({ confirmCancelar: true,});}
  closeCancelar= () =>{this.setState({ confirmCancelar: false,dimmerDos:false,dimmerActive:false,});}
  closeBookingCancelado= () =>{this.setState({ CancelarBooking: false,dimmerDos:false,dimmerActive:false,open:false,dimmer:false,});}
  fadeInFilePond= () =>{this.setState({ upload: !this.state.upload})}
  closeRate= () =>{this.setState({  confirmRate: false,})}
  handleCloseAlert= () =>{this.setState({  openAlert: false,});}
  HandlemodalStatus = () => this.setState({ openModalStatus: !this.state.openModalStatus });
  resetAlert = ()=> this.setState({ openAlert:false,titleAlert: '', AlertMessage:'', AlertType:'',});

  render(){
    const { open, dimmer } = this.state


    let datos=this.props.datos;
    let archivos=datos.archivos;
    let statusList=datos.StatusList;
    let arrayArchivos;
    let ListaStatus;
    if(archivos!=null){
    arrayArchivos = Object.keys(archivos).map(key => {
       let ar = archivos[key]
       ar.key = key
       return ar
     })
   }
   else{
     arrayArchivos=[];
   }

   if(statusList!=null){
   ListaStatus = Object.keys(statusList).map(key => {
      let ar = statusList[key]
      ar.key = key
      return ar
    })
  }
  else{
    ListaStatus=[];
  }

  let TipoMedida = datos.Medidas?datos.Medidas:datos.containerSize;

  let imagen=DeterminarImagen.DeterminarImagen(datos.contenedor,TipoMedida,datos.containerType);
    return(

      <div>

      <Dimmer page style={{height:'200%'}} id="DimmerSuscripcion" active={this.state.dimmerActive}>
       <Loader size='large'>Loading...</Loader>
       </Dimmer>
       <AlertSnack openAlert={this.state.openAlert} titleAlert={this.state.titleAlert} resetAlert={this.resetAlert} AlertMessage={this.state.AlertMessage} AlertType={this.state.AlertType}/>


       <Modal  size='small'  open={this.state.confirmCancelar} onClose={this.closeCancelar}>
            <Header>Are you sure you want to cancel?</Header>
            <p>"If you cancel after {datos.Cancelacion} hrs, you will be charged a penalty of {datos.MontoCancelacion} dlls"</p>
         <Modal.Actions>
           <Button  color='red' inverted onClick={this.closeCancelar}>
             <Icon name='remove' />'Cancel'
           </Button>
           <Button color='green' inverted  onClick={this.CancelarBookingPenalizacion}>
             <Icon name='checkmark' /> "Accept"
           </Button>
         </Modal.Actions>
       </Modal>


       <Modal   open={this.state.openconfirm} onClose={this.close2}>
         <Header icon='archive' content='Use existing card' />
          <Modal.Content>
              { this.state.pasoPago===1?
                <div>
                 <Grid columns={2} stackable textAlign='center'>
                   <Divider vertical>Or</Divider>

                   <Grid.Row verticalAlign='middle'>
                     <Grid.Column>
                       {parseInt(this.props.datos.precio) >= 3000?
                         <div>
                           <p className='onlyDolars'>at the moment, this option is only enabled for payments under 3000 USD.</p>
                         </div>:
                         <div>
                           <img src={DireccionFront+'/imagenes/pay1.png'} style={{width:'80%',margin:'0px auto'}}/>
                           <Button primary onClick={this.generarPago.bind(this,'inside')}>Inside</Button>

                         </div>
                       }
                     </Grid.Column>

                     <Grid.Column>
                     <img src={DireccionFront+'/imagenes/pay2.png' }style={{width:'80%'}}/>
                       <Button primary onClick={this.generarPago.bind(this,'outside')}>Outside</Button>
                     </Grid.Column>
                   </Grid.Row>
                 </Grid>
                </div>
                :this.state.pasoPago===2?
                <div>
                  <Grid columns={2} stackable textAlign='center'>
                  <Divider vertical></Divider>
                    <Grid.Row verticalAlign='middle'>
                      <Grid.Column>
                        <img src={DireccionFront+'/imagenes/bank.png'} style={{width:'90%'}}/>
                      </Grid.Column>
                      <Grid.Column>
                        {this.state.tipoPago === 'inside'?
                        <div>
                          {this.state.Cards.length>0?
                            <div>
                                 {this.state.selectCard?
                                   <div>
                                      <h1 id='TitleExpiry'>Confirm your payment</h1>
                                      <p>Confirm your payment by entering your expiration date and your CVV, remember that CEIF for security reasons does not save your expiration date and your CVV.</p>
                                     <Form.Group id="FormExpiry" >
                                     <Form.Input
                                         id='inputExpiry'
                                         type='tel'
                                         name="expiry"
                                         placeholder='Expiration date'
                                         value={this.state.expiry}
                                         onChange={this.handleInputChange}
                                         maxLength={5}
                                       onFocus={this.handleInputFocus}
                                       />
                                     <Form.Input
                                           id='inputExpiry'
                                           type='tel'
                                           name="cvc"
                                           placeholder='Security code'
                                           value={this.state.cvc}
                                           onChange={this.handleInputChange}
                                           maxLength={3}
                                         onFocus={this.handleInputFocus}/>
                                     </Form.Group>
                                   </div>:

                                   <div>
                                     {  this.state.Cards.map((it,index,key)=>{
                                       return(<ListaCard  GetCardsCallback={this.UsingCard} datos={it}/>)
                                       })
                                     }
                                   </div>
                                 }
                           </div>
                           :<div>
                             <p id={"ModalPayP"}>You must register a card if you want to pay by this means, this in your profile section</p>
                           </div>
                          }
                         </div>
                         :this.state.tipoPago === 'outside'?
                            <div>
                            <Header>Download the transfer data</Header>
                              <Icon id='iconDownloadFoto' onClick={this.getPDF}  name='download' color="orange" size="massive" />
                            <p style={{margin:"10px"}} >In the section of your shipment you can upload your bank transfer receipt, this usually takes longer than if the payment is within the platform</p>
                            </div>
                          :<div></div>
                        }
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>:<div></div>

              }
          </Modal.Content>
         <Modal.Actions>
         <Button  color='red' inverted onClick={this.close2}>
           <Icon name='remove' />'Cancel'
         </Button>
         {this.state.expiry && this.state.cvc?
          <Button color='green' inverted   disabled={!this.state.expiry||!this.state.cvc} onClick={this.Pagar}>
           <Icon name='checkmark' /> "Accept"
         </Button>:
         <div></div>
         }
         {this.state.download?
          <Button color='green' inverted  onClick={this.ActualizarEnvioPdf}>
           <Icon name='checkmark' /> "Accept"
         </Button>:
         <div></div>
         }

         </Modal.Actions>
       </Modal>

      <Modal  id={"ModalPay"}  open={this.state.CancelarBooking} onClose={this.closeBookingCancelado}>
        <Modal.Header id={"ModalPayHeader"}>
          <Icon name='archive' />
         Booking cancel
        </Modal.Header>
        <Modal.Content id={"ModalPayContent"}>
        {this.state.Penalizacion?
          <div>
            <p style={{marginLeft:"25%",marginBottom:"50px"}}>
              Cancellation charge: {this.props.datos.MontoCancelacion} dlls
            </p>

            {this.state.selectCard?
              <div>
                <Form.Group id="ModalExpiracion">
                <Form.Input
                    type='tel'
                    name="expiry"
                    placeholder='Expiration date'
                    value={this.state.expiry}
                    onChange={this.handleInputChange}
                    maxLength={5}
                  onFocus={this.handleInputFocus}
                  />

                <Form.Input
                      type='tel'
                      name="cvc"
                      placeholder='Security code'
                      value={this.state.cvc}
                      onChange={this.handleInputChange}
                      maxLength={3}
                    onFocus={this.handleInputFocus}/>
                </Form.Group>
              </div>:
              <div>
                {  this.state.Cards.map((it,index,key)=>{
                  return(<ListaCard  GetCardsCallback={this.UsingCard} datos={it}/>)
                  })
                }
              </div>
            }
          </div>:
          <div>
          <p style={{marginLeft:"25%"}}>
            You can cancel free.
          </p>
          </div>
        }
        </Modal.Content>
        <Modal.Actions>
          <Button id={'ModalPayButtonCancel'} color='red' inverted onClick={this.closeBookingCancelado}>
            <Icon name='remove' />Cancel
          </Button>

          {this.state.Penalizacion?
            <Button positive id={'ModalPayButtonAcept'} disabled={!this.state.expiry||!this.state.cvc}  onClick={this.ActualizarCancelacion}>
              <Icon name='checkmark' /> Accept
            </Button>:
            <Button positive id={'ModalPayButtonAcept'}   onClick={this.ActualizarCancelacion}>
              <Icon name='checkmark' /> Accept
            </Button>
          }

        </Modal.Actions>
      </Modal>

      <Modal  id={"ModalPay"}  open={this.state.confirmRate} onClose={this.closeRate}>
        <Modal.Header id={"ModalPayHeader"}>
          <Icon name='archive' />
          Rate this operation
        </Modal.Header>
        <Modal.Content id={"ModalPayContent"}>
          <p style={{marginLeft:"25%"}}>
            Are you sure of your rate?
          </p>
          <Rating style={{marginLeft:'33%'}} icon='star' size='huge' maxRating={5} rating={this.state.rate}  />
        </Modal.Content>
        <Modal.Actions>
          <Button id={'ModalPayButtonCancel'} color='red' inverted onClick={this.closeRate}>
            <Icon name='remove' />Cancel
          </Button>
          <Button positive id={'ModalPayButtonAcept'}  onClick={this.Calificar}>
            <Icon name='checkmark' /> Accept
          </Button>
        </Modal.Actions>
      </Modal>



        <Card onClick={this.show(true)} style={{textDecoration:'none',fontSize:'.9em', borderRadius:'2vh', width:'95%', minWidth:'250px', margin:' 1em 2.5em'}}>
          <div className='TransaccionID red'>
            <p>Booking : {datos.idTransaccion}</p>
          </div>
          <Card.Content id='contentCard'>

            <div className='contentImgEnvio'>
              {datos.contenedor==='LCL'||datos.contenedor==='LTL-Truck'||datos.contenedor==='Aereo'?
                <img src={DireccionFront+'/imagenes/pallet.png'} />
                :
                <img src={DireccionFront+'/imagenes/'+imagen} />
              }
            </div>

            <div className='InfoContent'>
              <div className='TitleEnvio'>
                <span>
                {datos.contenedor === 'Aereo'?'Air':datos.contenedor==='FCL-Ferro'?'FCL-FFCC':datos.contenedor}
                </span>
                <span>
                  Freigth
                </span>
                <span>
                  {datos.origen+ '   ->  '}
                </span>
                <span>
                  {datos.destino}
                </span>
              </div>
              <div className='descriptionContent'>
                <p className='ContenidoEnvio'>Contain: {datos.contenido}</p>
                <div className='ContentPriceEnvio'>

                  <span className='PagoEnvio'>{'$'+datos.precio+' USD'}</span>
                  <span className='TipoPagoEnvio'>{datos.Payment}</span>
                </div>
                <div className='MedidasEnvio'>
                  <span className='CantidadEnvio'>
                  Quality/Measure:
                  </span>
                  <span className='ContenidoPeso'>
                    {datos.cantidad} x {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?datos.medidasLargo+'cm  + '+datos.medidasAncho+'cm  + '+datos.medidasAlto+'cm':datos.containerSize+'  '+datos.containerType}
                  </span>
                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='PesoEnvio'>Weight:</span>:''}
                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='ContenidoPeso'>{datos.Peso+' kg'}</span>:''}
                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='PesoEnvio'>CBM:</span>:''}
                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='ContenidoPeso'>{parseFloat(datos.CBM).toFixed(2)+' CBMs'}</span>:''}
                </div>
              </div>
            </div>
            <div className='StatusContentEnvio'>
                {datos.Status === 'Peticion'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon  color='grey' size='big'  name='payment' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>Pending</h4>
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                  </div>:<div></div>
                }
                {datos.Status === 'Aceptado'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon  color='blue' size='big'  name='check' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>Accepted</h4>
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                  </div>:<div></div>
                }
                {datos.Status === 'Proceso'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon  color='yellow' size='big'  name='ship' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>in Process</h4>
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                    <div className="clear"></div>
                    {datos.SolicitudPago?
                      <div style={{margin:'0px auto',textAlign:'center'}}>
                      <Icon  color='blue' size='big'  name='money' />
                      <h4 style={{marginTop:'0px', color:'grey'}}>Payment required</h4>
                       <a onClick={this.HandlemodalStatus}>What is this?</a>

                      </div>:<div></div>
                    }

                  </div>:<div></div>
                }
                {datos.Status === 'Pagado'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon color='green' size='big'  name='payment' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>Paid out</h4>
                    <Icon color='yellow' size='big'  name='star' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>Rate is required</h4>
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                  </div>:<div></div>
                }
                {datos.Status === 'Finalizado'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon  color='green' size='big'  name='check' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>Payment released</h4>
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                  </div>:<div></div>
                }
                {datos.Status === 'Cancelado'?
                  <div style={{margin:'0px auto',textAlign:'center'}}>
                    <Icon  color='red' size='big'  name='ban' />
                    <h4 style={{marginTop:'0px', color:'grey'}}>Cancelled</h4>
                     <a onClick={this.HandlemodalStatus}>What is this?</a>

                  </div>:<div></div>
                }
            </div>

          </Card.Content>
          {datos.Cancelacion?
            <div className='CancelarcionMsj'>
              <Card.Header>You have {datos.Cancelacion} hrs for cancellation without any cancellation charge</Card.Header>
              <Card.Meta>otherwise you will be charged {datos.MontoCancelacion} dlls</Card.Meta>
            </div>:
            <div className='CancelarcionMsj'>
            <Card.Meta style={{fontSize:'15px'}}>Your shipment has not yet been accepted, you can cancel without charges</Card.Meta>
            </div>
          }
        </Card>

        <Modal  open={this.state.open} onClose={this.close} style={{width:'80%'}} >
          <div className='TransaccionID red'>
            <p>Bookings : {datos.idTransaccion}</p>
          </div>
          <Card.Content id='contentCardModal'>

            <div className='contentImgEnvio'>
              {datos.contenedor==='LCL'||datos.contenedor==='LTL-Truck'||datos.contenedor==='Aereo'?
                <img src={DireccionFront+'/imagenes/pallet.png'} />
                :
                <img src={DireccionFront+'/imagenes/'+imagen} />
              }
            </div>

            <div className='InfoContent'>
              <div className='TitleEnvio'>
                <span>
                {datos.contenedor === 'Aereo'?'Air':datos.contenedor==='FCL-Ferro'?'FCL-FFCC':datos.contenedor}
                </span>
                <span>
                  Freigth
                </span>
                <span>
                  {datos.origen+ '   ->  '}
                </span>
                <span>
                  {datos.destino}
                </span>
              </div>
              <div className='descriptionContent'>
                <p className='ContenidoEnvio'>Contain: {datos.contenido}</p>
                <div className='ContentPriceEnvioModal'>
                  <span className='PagoEnvioModal'>{'$'+datos.precio+' USD'}</span>
                  <span className='TipoPagoEnvio'>{datos.Payment}</span>
                </div>
                <div className='MedidasEnvio'>
                  <span className='CantidadEnvio'>
                  Quality/Measure:
                  </span>
                  <span className='ContenidoPeso'>
                    {datos.cantidad} x {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?datos.medidasLargo+'cm  + '+datos.medidasAncho+'cm  + '+datos.medidasAlto+'cm':datos.containerSize+'  '+datos.containerType}
                  </span>
                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='PesoEnvio'>Weight:</span>:''}
                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='ContenidoPeso'>{datos.Peso+' kg'}</span>:''}
                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='PesoEnvio'>CBM:</span>:''}
                  {datos.contenedor==='LCL'||datos.contenedor==='Aereo'||datos.contenedor==='BBulk'||datos.contenedor==='LTL-Truck'?<span className='ContenidoPeso'>{parseFloat(datos.CBM).toFixed(2)+' CBMs'}</span>:''}
                </div>
                </div>
            </div>

          </Card.Content>

          {datos.numeroBL!=null?
            <Fieldset legend="BL details" toggleable={true}>
                <div style={{width:"100%",minWidth:'900px',maxHeight:'20vh', display:"inline-flex"}}>
                 <div style={{paddingLeft:"9%",minWidth:'20vh'}}>
                 <Card.Meta className='labelMini'>BL Number</Card.Meta>
                 <Card.Header>{datos.numeroBL}</Card.Header><br/>
                 </div>

                 <div style={{paddingLeft:"9%",minWidth:'20vh'}}>
                   <Card.Meta className='labelMini'>Vessel name</Card.Meta>
                   <Card.Header>{datos.nombreBarco}</Card.Header><br/>
                 </div>

                 <div style={{paddingLeft:"9%",minWidth:'25vh'}}>
                 <Card.Meta className='labelMini'>Voyage</Card.Meta>
                 <Card.Header>{datos.numeroViaje}</Card.Header><br/>
                 </div>

                 <div style={{paddingLeft:"9%",minWidth:'25vh'}}>
                 <Card.Meta className='labelMini'>Booking ref.</Card.Meta>
                 <Card.Header>{datos.numeroBooking}</Card.Header><br/>
                 </div>

               </div>
             </Fieldset>
             :<div></div>
          }
          {datos.Status === 'Aceptado'?
              <div>
                <Message icon>
                  <Icon name='check' color='green' accept />
                  <Message.Content>
                    <Message.Header>lets do it</Message.Header>
                    you can start managing the shipment
                  </Message.Content>
                </Message>
              </div>:<div></div>
          }
          {datos.SolicitudPago===true?
            <div style={{width:'90%'}}>
              <Divider></Divider>
              <Grid columns={2} stackable textAlign='center'>
                <Grid.Row verticalAlign='middle'>
                  <Grid.Column>
                    <img src={DireccionFront+'/imagenes/bank.png' }style={{width:'60%'}}/>
                  </Grid.Column>
                  <Grid.Column>
                    <div>
                      {datos.Status === 'Proceso'?
                        <div className="Vencido">
                          {this.state.Vencido?
                            <div>
                              <Card style={{width:'100%'}}>
                                <Card.Content>
                                  <Card.Meta>CEIF</Card.Meta>
                                  <Card.Header>Your payment has expired</Card.Header>
                                  <Card.Description><p><strong>The limit date has expired </strong></p></Card.Description>
                                      <Icon color='teal' size='huge' style={{marginLeft:'2%'}} name='ban' />

                                </Card.Content>
                                <Button color='black'style={{width:'100%',marginBottom:'2vh'}}>Doubts on this payment?</Button>
                              </Card>
                            </div>:
                            <div>
                            <Card style={{width:'100%'}}>
                              <Card.Content>
                                <Card.Meta>{datos.nombreBanco}</Card.Meta>
                                <Card.Header>{datos.bankPago}</Card.Header>
                                <Card.Header>{datos.holderCuenta}</Card.Header>
                                <Card.Description><p><strong>Due amount:</strong> ${datos.precio} USD<br/><strong>Type:</strong>{datos.tipoPagoSolicitado}</p></Card.Description>
                              </Card.Content>
                            </Card>
                              <Message icon>
                                <Icon name='circle notched' loading />
                                <Message.Content>
                                  <Message.Header>The due amount must be paid on {datos.fechaSolicitudPago}</Message.Header>
                                    Due amount must be paid within the next 5 natural days from the due date.
                                </Message.Content>
                              </Message>
                              <Button primary disabled={datos.FotoReciboPago === 'none'} onClick={this.confimar} style={{width:'100%',marginBottom:'2vh'}}>Pay invoice</Button>
                              {!datos.FotoReciboPago?
                                <Button color="orange" onClick={this.fadeInFilePond} style={{width:'100%',marginBottom:'2vh'}}>Upload transfer receipt</Button>:
                                <div>
                                  <p style={{margin:"10px"}}>We are reviewing your receipt, as soon as it is accepted the payment will be updated</p>
                                </div>
                              }

                              {this.state.upload?
                                 <FilePond ref={ref => this.pond = ref}
                                 files={this.state.files}
                                 allowMultiple={true}
                                 maxFiles={1}
                                 onaddfile={() => {
                                     // Set current file objects to this.state
                                     this.setState({
                                         fileOK: true,
                                     });
                                 }}
                                 labelIdle="Add files"
                                 onupdatefiles={(fileItems) => {
                                     // Set current file objects to this.state
                                     this.setState({
                                         files: fileItems.map(fileItem => fileItem.file)
                                     });
                                 }}>
                               </FilePond>:<div></div>
                              }
                              {this.state.fileOK?
                                <div className='btnUploadContent' onClick={this.upLoadFile}>
                                <div  className={this.state.LoaderStatus===0?'loaderBank':this.state.LoaderStatus===1?'loaderBankSpiner':'loaderBankActive'} ref={this.loaderBank}></div>
                                {this.state.LoaderStatus===2?
                                  <div className='iconDone'>
                                  <i class="material-icons" style={{ fontSize: '2em', color:'#21ba45' }}>done_outline</i>
                                  </div>
                                  :
                                  <div>
                                  <i class="material-icons" style={{ fontSize: '2em' }}>cloud_upload</i><p>Upload file</p>
                                  </div>
                                }
                                </div>
                                :
                                <div></div>
                              }
                              <Button color='black'style={{width:'100%',marginBottom:'2vh'}}>Doubts on this payment?</Button>
                            </div>
                          }
                        </div>:<div></div>
                      }
                      {datos.Status === 'Pagado'?
                        <div>
                          <Card style={{width:'100%'}}>
                            <Card.Content>
                              <Card.Meta>CEIF</Card.Meta>
                              <Card.Header>Your money is safe with us</Card.Header>
                              <Card.Description><p><strong>Rate the operation to release the payment</strong></p></Card.Description>
                              <Rating icon='star' size='huge' maxRating={5} onRate={this.handleRate} />
                            </Card.Content>
                            <Button color='black'style={{width:'100%',marginBottom:'2vh'}}>Doubts on this payment?</Button>

                          </Card>
                        </div>:<div></div>
                      }
                      {datos.Status === 'Finalizado'?
                      <div>
                        {datos.Pagado?
                            <Card style={{width:'100%'}}>
                              <Card.Content>
                                <Card.Meta>CEIF</Card.Meta>
                                <Card.Header>The seller has received your payment successfully</Card.Header>
                                <Card.Description><p><strong>Thank you for your payment</strong></p></Card.Description>
                                  <Icon color='teal' size='huge' style={{marginLeft:'2%'}} name='checkmark' />
                              </Card.Content>
                            </Card>:
                            <Card style={{width:'100%'}}>
                              <Card.Content>
                                <Card.Meta>CEIF</Card.Meta>
                                <Card.Header>Your payment has succesfuly confirmed</Card.Header>
                                <Card.Description><p><strong>Thank you for your payment</strong></p></Card.Description>
                                  <Icon color='teal' size='huge' style={{marginLeft:'2%'}} name='checkmark' />
                              </Card.Content>
                            </Card>
                          }
                      </div>:<div> </div>
                    }
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            :<div></div>
          }
          <Divider/>
          <div className="StatusEnvioUsuario">
            <div className="statusContainer">
                <span className='routeShipping'>
                <span className='routeIcon'>
                  <Icon  name='shipping'className={datos.objServicios.PO?'icono active':'icono'}  />
                </span>
                <span className={datos.objServicios.PO?'line active':'line'}>
                  <Icon id={datos.objServicios.PO?'dot-left-active':'dot-left'} name='dot circle outline' />
                  <Icon id={datos.objServicios.CO?'dot-right-active-blue':'dot-right'} name='dot circle outline' />
                </span>
                </span>
                <span className='routeShipping'>
                  <span className='routeIcon'>
                  <Icon  name='ship' className='icono activeBlue'  />
                  </span>
                  <span className='line activeBlue'></span>
                </span>
                <span className='routeShipping'>
                  <span className='routeIcon'>
                  <Icon  name='shipping'className={datos.objServicios.PD?'icono active':'icono'}  />
                  </span>
                  <span className={datos.objServicios.PD?'line active':'line'}>
                 <Icon id={datos.objServicios.CD?'dot-left-active-blue':'dot-left'} name='dot circle outline' />
                 <Icon id={datos.objServicios.PD?'dot-right-active':'dot-right'} name='dot circle outline' />
                 </span>
                </span>
            </div>
            <div className="StatusTags">
              <div className={datos.objServicios.PO?'itemStatusUsuario activo':'itemStatusUsuario'}>
                <p>Local Pick Up</p>
              </div>
              <div className={datos.objServicios.CO?'itemStatusUsuario activo':'itemStatusUsuario'}>
                <p>Origin Charges</p>
              </div>
              <div className={datos.objServicios.CD?'itemStatusUsuario activo':'itemStatusUsuario'}>
                <p>Dest. Charges</p>
              </div>
              <div className={datos.objServicios.PD?'itemStatusUsuario activo':'itemStatusUsuario'}>
                <p>Local Delivery</p>
              </div>
            </div>
          </div>
          <Divider/>
          <div style={{width:'95%',padding:'3vh'}}>
            <AcordionStatus data={ListaStatus}/>
            <label style={{textAlign:'left',margin:'20px 99px 0'}}> Files</label>
            <div style={{width:'85%',padding:'3vh'}}>
              <Grid columns={2}>
                <Grid.Column style={{overflowY:'scroll'}} width={10}>
                  {
                    arrayArchivos.map((it,key)=>{
                      return(<Chips data={it}/>)
                    })

                  }
                </Grid.Column>
                <Grid.Column width={5}>
                  <Image style={{width:'100%',paddingLeft:'20%'}}  src={DireccionFront+'/imagenes/world.png'} />
                </Grid.Column>
              </Grid>
            </div>
          </div>
          <Modal.Actions>
            {datos.Pagado || datos.Status === 'Cancelado'|| datos.Status === 'Pagado'|| datos.Status === 'Finalizado'?
              <div>
              </div>:
              <Popup
                trigger={<Button
                    color='orange'
                    icon='cancel'
                    labelPosition='right'
                    content="Cancel booking"
                    onClick={this.handleCancelarBooking}
                  />}
                  content={this.state.mensajePop}
                  on='hover'
                  inverted
                />
            }

            <Button
              positive
              icon='checkmark'
              labelPosition='right'
              content="Accept"
              onClick={this.close}
            />
          </Modal.Actions>
        </Modal>

        <Modal   open={this.state.openModalStatus} onClose={this.HandlemodalStatus}>
          <Modal.Header id={"ModalPayHeader"}>
            <Icon name='archive' />
            CEIF status system
          </Modal.Header>
          <Modal.Content id={"ModalPayContent"}>

          <div style={{display:'inline-flex', width:'100%',margin:'10px 0px'}}>
            <div style={{display:'block', width:'30%' , textAlign:'center'}}>
              <Icon  color='grey' size='big'  name='payment' />
              <h4 style={{marginTop:'0px', color:'grey'}}>Pending</h4>
            </div>
            <div style={{display:'block', width:'70%'}}>
              <p>The seller still does not accept the shipment, you can cancel without a cost</p>
            </div>
          </div>

           <div style={{display:'inline-flex', width:'100%',margin:'10px 0px'}}>
            <div style={{display:'block', width:'30%' , textAlign:'center'}}>
              <Icon  color='blue' size='big'  name='check' />
                  <h4 style={{marginTop:'0px', color:'grey'}}>Accepted</h4>
            </div>
            <div style={{display:'block', width:'70%'}}>
              <p>The seller has accepted the shipment and is already working on your shipment, the cancellation date has also been set at no cost, after that date if you cancel you will be charged a penalty</p>
            </div>
          </div>

           <div style={{display:'inline-flex', width:'100%',margin:'10px 0px'}}>
            <div style={{display:'block', width:'30%' , textAlign:'center'}}>
              <Icon  color='yellow' size='big'  name='ship' />
              <h4 style={{marginTop:'0px', color:'grey'}}>in Process</h4>
            </div>
            <div style={{display:'block', width:'70%'}}>
              <p>The seller has required the payment of your shipment, when you pay the money it is in the CEIF system and is released until you qualify, so your money is safe.</p>
            </div>
          </div>

           <div style={{display:'inline-flex', width:'100%',margin:'10px 0px'}}>
            <div style={{display:'block', width:'30%' , textAlign:'center'}}>
              <Icon  color='green' size='big'  name='payment' />
              <h4 style={{marginTop:'0px', color:'grey'}}>Paid out</h4>
            </div>
            <div style={{display:'block', width:'70%'}}>
              <p>Has been paid successfully, just lack of your qualification to release the payment</p>
            </div>
          </div>

           <div style={{display:'inline-flex', width:'100%',margin:'10px 0px'}}>
            <div style={{display:'block', width:'30%' , textAlign:'center'}}>
              <Icon  color='green' size='big'  name='computer' />
              <h4 style={{marginTop:'0px', color:'grey'}}>Payment released</h4>
            </div>
            <div style={{display:'block', width:'70%'}}>
              <p>You have confirmed that the payment is released and the seller will receive your payment</p>
            </div>
          </div>
         <div style={{display:'inline-flex', width:'100%',margin:'10px 0px'}}>
            <div style={{display:'block', width:'30%' , textAlign:'center'}}>
              <Icon  color='red' size='big'  name='ban' />
              <h4 style={{marginTop:'0px', color:'grey'}}>Cancelled</h4>
            </div>
            <div style={{display:'block', width:'70%'}}>
              <p>Your payment has been successfully canceled</p>
            </div>
          </div>

          </Modal.Content>
          <Modal.Actions>
            <Button positive   onClick={this.HandlemodalStatus}>
              <Icon name='checkmark' /> Accept
            </Button>
          </Modal.Actions>
        </Modal>

      </div>
    )
  }
}

class AcordionStatus extends Component{
  state = { activeIndex: 1 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }
  UNSAFE_componentWillMount(){
    var datos=FiltrarTiemposStatus(this.props.data.reverse());
    this.setState({
      datos:datos
    })
  }

  render(){
    const { activeIndex } = this.state
    var data=this.state.datos;
    var length=data.length-1;
    return(
      <Accordion style={{width:'100%'}} styled>
        <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
        <Icon name='dropdown' />

          <Table sortable celled fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Cargo type</Table.HeaderCell>
                <Table.HeaderCell>Update time and date</Table.HeaderCell>
                <Table.HeaderCell>Time in process</Table.HeaderCell>
                <Table.HeaderCell>Elapsed time</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Status comments</Table.HeaderCell>
                <Table.HeaderCell>Comments</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
           <Table.Row>
             <Table.Cell>{data[0].Tipo === 'Aereo'?'Air':data[0].Tipo}</Table.Cell>
             <Table.Cell>{data[0].FechaHora}</Table.Cell>
             <Table.Cell>{data[0].TiempoProceso}</Table.Cell>
             <Table.Cell>{data[0].TiempoAcumulado}</Table.Cell>
             <Table.Cell>{data[0].Status}</Table.Cell>
             <Table.Cell>{data[0].Causa}</Table.Cell>
             <Table.Cell>{data[0].Observaciones}</Table.Cell>
           </Table.Row>
           </Table.Body>

          </Table>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>

        <Table sortable celled fixed>
          <Table.Body>
          {
            data.map((it,key)=>{
              if(key>0){
                return(
                  <Table.Row>
                    <Table.Cell>{it.Tipo === 'Aereo'?'Air':it.Tipo}</Table.Cell>
                    <Table.Cell>{it.FechaHora}</Table.Cell>
                    <Table.Cell>{it.TiempoProceso}</Table.Cell>
                    <Table.Cell>{it.TiempoAcumulado}</Table.Cell>
                    <Table.Cell>{it.Status}</Table.Cell>
                    <Table.Cell>{it.Causa}</Table.Cell>
                    <Table.Cell>{it.Observaciones}</Table.Cell>
                  </Table.Row>

                )
            }
            })
          }
          </Table.Body>
        </Table>
        </Accordion.Content>

      </Accordion>
    )
  }
}



class Chips extends Component{

  constructor(){
    super();
  }

  handleDelete() {
    window.open(this.props.data.archivoUrl);
  }

  render(){
    let nombre=this.props.data.nombre.length>28?this.props.data.nombre.substring(0,27):this.props.data.nombre;
    return(

      <Chip
        icon={<Icon name='file text' size='large' style={{color:'white'}}/> }
        label={nombre}
        clickable
        style={{width:'40vh',marginBottom:'1vh'}}
        color="primary"
        onDelete={this.handleDelete.bind(this)}
        onClick={this.handleDelete.bind(this)}
        deleteIcon={<Icon name='cloud download' size='large' style={{color:'white'}} />}
      />
    )
  }
}



EnviosUsuario.propTypes = {
  classes: PropTypes.object.isRequired,
};



class ListaCard extends Component{
  constructor(){
    super()
    this.state = {
    datos:[],
    dimmerActive:false,
    }
  }

  selected=()=>{
    this.props.GetCardsCallback(this.props.datos.key);
  }

  render(){
    return(

      <div>
      <Dimmer page active={this.state.dimmerActive}>
       <Loader size='large'>Loading...</Loader>
       </Dimmer>
      <List  id="listaCards" animated verticalAlign='middle'>

        <List.Item onClick={this.selected} id="ItemCards">

        {this.props.datos.displayNumber.substring(0, 1) === '4'?
          <div>
          <Image  size='tiny' src={DireccionFront+'/imagenes/visa.png'} />
          </div>:
          <div>
          <Image size='tiny' src={DireccionFront+'/imagenes/Mastercard.png'}  />
          </div>
        }
          <List.Content id='ContentCards'>
            <List.Header>{this.props.datos.displayNumber}</List.Header>
            <List.Description>{this.props.datos.HolderCuenta}</List.Description>
          </List.Content>
        </List.Item>
      </List>
      </div>
    )
  }
}

export default withStyles(styles)(EnviosUsuario);

import './MenuPublico.css';
import React, { useState, useEffect } from 'react'
import {Router, Route, BrowserRouter, Switch, Link} from 'react-router-dom'
import { Icon } from 'semantic-ui-react'

export default  function PublicNavbar(props){
    const [windowDimenion, detectHW] = useState({winWidth: window.innerWidth})
    const [openBar,setOpenBar]=useState('none');
    const detectSize = () => {detectHW({ winWidth: window.innerWidth,})}
    const backgroundBar1='rgb(255 255 255 / 0%)'
    const backgroundBar2='rgb(255 255 255 / 90%)'
      useEffect(() => {
        window.addEventListener('resize', detectSize)
        return () => {
          window.removeEventListener('resize', detectSize)
        }
      }, [windowDimenion])

    return(
        <div>
            {windowDimenion.winWidth>990?
                <section className='navbar'>
                    <div className="links">
                    <Link to="/"><img src='../imagenes/LandingPage/logo_blanco.png'/></Link>
                    <Link to="/About"><p  style={{color:props.color}}>About Us</p></Link>
                    <Link to="/Membership"><p  style={{color:props.color}}>Membership</p></Link>
                    <Link to="/Faqs"> <p  style={{color:props.color}}>FAQ's</p></Link>
                    <Link to="/Contact"><p  style={{color:props.color}}>Contact Us</p></Link>
                    <Link to="/Login"><p  style={{color:props.color}} className='Boton'>Login</p></Link>
                    </div> 
                </section>
                :
               
                    <div class="topnav" style={{backgroundColor:openBar=='none'?backgroundBar1:backgroundBar2}}>
                    <Link to="/"><a class="active"><img src={openBar=='none'?'../imagenes/LandingPage/logo_blanco.png':'../imagenes/LandingPage/logo.png'}/></a></Link>
                    <div id="myLinks" style={{display:openBar}}>
                            <Link to="/About"><p>About Us</p></Link>
                            <Link to="/Membership"><p  >Membership</p></Link>
                            <Link to="/Faqs"> <p >FAQ's</p></Link>
                            <Link to="/Contact"><p >Contact Us</p></Link>
                            <Link to="/Contact" ><p >Login</p></Link>

                    </div>
                    <a  class="icon" style={{color:openBar!='none'?'#737476':'#ffffff'}} onClick={()=>setOpenBar(openBar=='none'?'block':'none')}>
                    <Icon name='sidebar' size='large' color={props.color}/>
                    </a>
                    </div>
                    
            }
            
        </div>

    );
}
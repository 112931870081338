import React, { Component } from 'react';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import {Router, Route, BrowserRouter, Switch, Link} from 'react-router-dom'
import { Divider,Dimmer,Loader, Header,List, Icon, Table, Card, Image,Button,Modal, Form } from 'semantic-ui-react';
import './styleSeller/paquetesVendedor.css';
import firebase from 'firebase'
import BarraLateral from './sideBar.js';
import {Direccion} from '../../strings/peticiones.js';
import axios from 'axios';
import AlertSnack from '../notificacionCard.js';


class PaquetesVendedor extends Component{
  constructor(){
    super()

  }

  render(){
    return(

      <div className='profile-content'>
      <BarraLateral/>
      <PaquetesDetalle/>
      </div>
    )
  }
}


class PaquetesDetalle extends Component{
  constructor(){
    super()
      this.state = {
        comprar:false,
        idPaquete:'',
        Paquete1Enable:false,
        Paquete2Enable:false,
        Paquete3Enable:false,
        Cards:[],
        existCard:false,
        idActivo1:'imgPaquete1',
        idActivo2:'imgPaquete2',
        idActivo3:'imgPaquete3',
        idActivo4:'imgPaquete4',
        cardSelect:false,
        idCard:'',
        cvc: '',
        expiry: '',
        openAlert:false,
        AlertMessage:'',
        AlertType:'',
        Paqueteid:'',
      }
  }

  state = { open: false }
  UNSAFE_componentWillMount=()=>{
    let perfil=[];
    var self=this;
    axios.post(Direccion+`/tomarSuscripcion`,
      {idUser:firebase.auth().currentUser.uid})
      .then(res => {
        if (res.data.suscripcionName === 'Basic') {
          self.setState({
            Paquete1Enable:true,
            Paqueteid:res.data.suscripcionId,
            idActivo1:'imgPaquete1Activo',
            GetFree:res.data.GetFree,
            })
        }
        else if (res.data.suscripcionName ==='Standard') {
          self.setState({
            Paquete2Enable:true,
            Paqueteid:res.data.suscripcionId,
            idActivo2:'imgPaquete2Activo',
            GetFree:res.data.GetFree,

            })
        }
        else if (res.data.suscripcionName === 'Gold') {
          self.setState({
            Paquete3Enable:true,
            Paqueteid:res.data.suscripcionId,
            idActivo3:'imgPaquete3Activo',
            GetFree:res.data.GetFree,

            })
        }
        else if (res.data.suscripcionName === 'Diamond') {
          self.setState({
            Paquete4Enable:true,
            Paqueteid:res.data.suscripcionId,
            idActivo4:'imgPaquete4Activo',
            GetFree:res.data.GetFree,

            })
        }
      })
  }
  Suscribir=()=>{
    let self =this;
    self.setState({
      loader: true,
      dimmerActive:true,
      existCard:false,
    });
    axios.post(Direccion+'/pagarSuscripcion',{NuevaSuscripcion:true,idUser:firebase.auth().currentUser.uid,tipo:"Vendedor",idCard:this.state.idCard,
    expiry:this.state.expiry,cvv:this.state.cvc,idPaquete:parseInt(this.state.idPaquete)})
    .then(res=>{
      let errorMessage = '';

      if(res.data==='OK'){
        self.setState({
          dimmerActive: false,
          loader: false,
           openAlert:true,
           titleAlert:'Success',
           AlertMessage:'subscription updated successfully',
           AlertType:'success',
        });
        setTimeout(function() {window.location.reload();}, 3000);
      }
      else if(res.data === "P101"){
        self.setState({
          dimmerActive: false,
          loader: false,
           openAlert:true,
           titleAlert:'Warning',
           AlertMessage:'complete your profile information',
           AlertType:'warning',
        });
      }
      else if(res.data === "340"){
        self.setState({
          dimmerActive: false,
          loader: false,
           openAlert:true,
           titleAlert:'Error',
           AlertMessage:'you are Banned',
           AlertType:'error',
        });
      }
      else if(res.data === "902"){
        self.setState({
          dimmerActive: false,
          loader: false,
           openAlert:true,
           titleAlert:'Error',
           AlertMessage:'CVV or Date is Incorrect',
           AlertType:'error',
        });
      }
      else if(res.data === "P1"){
        self.setState({
          dimmerActive: false,
          loader: false,
           openAlert:true,
           titleAlert:'Error',
           AlertMessage:'Your country is misspelled',
           AlertType:'error',
        });
      }
      else if(res.data.statusCode === "202" && res.data.error){
        self.setState({
          dimmerActive: false,
          loader: false,
           openAlert:true,
           titleAlert:'Error',
           AlertMessage:'Payment error, try another debit card',
           AlertType:'error',
        });
      }
      else{
        self.setState({
          dimmerActive: false,
          loader: false,
           openAlert:true,
           titleAlert:'Error',
           AlertMessage:'something went wrong',
           AlertType:'error',
        });
      }
    })
  }

  tomarFree=()=>{
    let self =this;
    self.setState({
      loader: true,
      dimmerActive:true,
      existCard:false,
    });
    axios.post(Direccion+'/FreeSuscripcion',{NuevaSuscripcion:true,idUser:firebase.auth().currentUser.uid,tipo:"Vendedor",idPaquete:1})
    .then(res=>{
      if(res.data==='OK'){
        self.setState({
          dimmerActive: false,
          loader: false,
           openAlert:true,
           titleAlert:'Success',
           AlertMessage:'subscription updated successfully',
           AlertType:'success',
        });
        setTimeout(function() {window.location.reload();}, 3000);
      }
      else if(res.data === "P101"){
        self.setState({
          dimmerActive: false,
          loader: false,
           openAlert:true,
           titleAlert:'Warning',
           AlertMessage:'complete your profile information',
           AlertType:'warning',
        });
      }else if(res.data === "340"){
        self.setState({
          dimmerActive: false,
          loader: false,
           openAlert:true,
           titleAlert:'Error',
           AlertMessage:'you are Banned',
           AlertType:'error',
        });
      }
      else{
        self.setState({
          dimmerActive: false,
          loader: false,
           openAlert:true,
           titleAlert:'Error',
           AlertMessage:'something went wrong',
           AlertType:'error',
        });
      }
    })
  }
  show=(paquete,e)=>{
    var self=this;
    if (paquete === 3 && !this.state.GetFree) {
      let self =this;
      self.setState({
        loader: true,
        dimmerActive:true,
      });
      if (!this.state.paqueteId  || this.state.paqueteId != 3  || this.state.paqueteId === 'none') {
        axios.post(Direccion+'/FreeSuscripcion',{meses:6,NuevaSuscripcion:true,idUser:firebase.auth().currentUser.uid,tipo:"Vendedor",idPaquete:3})
        .then(res=>{
          if(res.data==='OK'){
            self.setState({
              dimmerActive: false,
              loader: false,
               openAlert:true,
               titleAlert:'Success',
               AlertMessage:'subscription updated successfully',
               AlertType:'success',
            });
            setTimeout(function() {window.location.reload();}, 3000);
          }
          else if(res.data === "P101"){
            self.setState({
              dimmerActive: false,
              loader: false,
               openAlert:true,
               titleAlert:'Warning',
               AlertMessage:'complete your profile information',
               AlertType:'warning',
            });
          }else if(res.data === "340"){
            self.setState({
              dimmerActive: false,
              loader: false,
               openAlert:true,
               titleAlert:'Error',
               AlertMessage:'you are Banned',
               AlertType:'error',
            });
          }
          else{
            self.setState({
              dimmerActive: false,
              loader: false,
               openAlert:true,
               titleAlert:'Error',
               AlertMessage:'something went wrong',
               AlertType:'error',
            });
          }
        })
      }else{
        let self =this;
        self.setState({
            dimmerActive: false,
            loader: false,
           openAlert:true,
           titleAlert:'Warning',
           AlertMessage:'you had already taken your 60 days for free',
           AlertType:'warning',
        });
      }

    }else{
      axios.post(Direccion+`/tomarCards`,
        {idUser:firebase.auth().currentUser.uid,tipo:"Vendedor"})
        .then(res => {
          if (res.data !=0 && res.data.length >0) {
            self.setState({
              Cards:res.data,
              existCard:true,
              idPaquete:paquete,
            })
          }
          else {
            self.setState({
              comprar: true,
            })
          }
      })
    }

  }
  handleInputChange = (e) => {
    const target = e.target;
    if (target.name === 'expiry') {
      var value=target.value;
      if (/^([0-9-/])*$/.test(target.value) ){
          if(value.length===2 ){
            this.setState({
              [target.name]:value.replace(/ |\//g, '')+'/',
            });
          }
          else{
            this.setState({
              [target.name]:value,
            });
          }
      }
    }else if (target.name === 'cvc'){
      var value=target.value;
      if (/^([0-9])*$/.test(target.value)){
        this.setState({
          [target.name]:value,
        });
      }

    }
  }//cierre handle
  selectCard = (key) => this.setState({ idCard:key,cardSelect: true });
  returnCard = () => this.setState({ cardSelect: false });
   close = () => this.setState({ comprar: false });
   close2 = () => this.setState({ existCard: false });
   comprar = () => this.setState({ comprar: !this.state.comprar,existCard: false });
resetAlert = ()=> this.setState({ openAlert:false,titleAlert: '', AlertMessage:'', AlertType:'',});
  render(){
    const { open, dimmer } = this.state
    let idActivo1 = this.state.idActivo1;
    let idActivo2 = this.state.idActivo2;
    let idActivo3 = this.state.idActivo3;
    let idActivo4 = this.state.idActivo4;

    return(

      <div>
        <Dimmer page id="DimmerSuscripcion" active={this.state.dimmerActive}>
          <Loader size='large'>Loading...</Loader>
        </Dimmer>
        <AlertSnack openAlert={this.state.openAlert} titleAlert={this.state.titleAlert} resetAlert={this.resetAlert} AlertMessage={this.state.AlertMessage} AlertType={this.state.AlertType}/>

        <Divider horizontal style={{paddingTop:'4vh', }}>
          <Header as='h3'>
            <i class="material-icons" style={{ fontSize: '2em' }}>card_membership</i>
            CEIF Premium Plans
          </Header>
        </Divider>

        <Modal  size='small' dimmer={this.state.dimmerDos} open={this.state.existCard} onClose={this.close2}>
          <Header icon='archive' content='Use existing card' />
          <Modal.Content>
            {this.state.cardSelect?
              <div>
                <Form.Group id="ModalExpiracion">
                  <Form.Input
                      type='tel'
                      name="expiry"
                      placeholder='Expiration date'
                      value={this.state.expiry}
                      onChange={this.handleInputChange}
                      maxLength={5}
                    onFocus={this.handleInputFocus}
                    />

                  <Form.Input
                        type='tel'
                        name="cvc"
                        placeholder='Security code'
                        value={this.state.cvc}
                        onChange={this.handleInputChange}
                        maxLength={3}
                      onFocus={this.handleInputFocus}/>
                </Form.Group>
              </div>:
              <div>
                {  this.state.Cards.map((it,index,key)=>{
                  return(<ListaCard  GetCardsCallback={this.selectCard} datos={it}/>)
                  })
                }
              </div>
            }

          </Modal.Content>
          <Modal.Actions>
          <Button  color='red' inverted onClick={this.comprar}>
            <Icon name='remove' />'Cancel'
          </Button>
          <Button color='green' inverted  disabled={!this.state.expiry||!this.state.cvc} onClick={this.Suscribir}>
            <Icon name='checkmark' /> "Accept"
          </Button>
          </Modal.Actions>
        </Modal>

        <Modal  size='small' dimmer={this.state.dimmerDos} open={this.state.comprar} onClose={this.comprar}>
          <Header icon='archive' content='Register a card' />
          <Modal.Content>
            <p>in order to buy a membership, you must first register a credit or debit card in you Profile section</p>
          </Modal.Content>
          <Modal.Actions>
          <Button color='green' inverted   onClick={this.comprar}>
            <Icon name='checkmark' /> "Accept"
          </Button>
          </Modal.Actions>
        </Modal>

        <div className='contenedor-paquetes'>
          <ul className='col4'>
            <li>
              <Card>
                <Card.Content>
                  <div className="ImgPaqueteContent">
                    <Image id={idActivo1} size='small' style={{display:'block', marginLeft: 'auto', marginRight: 'auto',textAlign: 'lef'}}src='../imagenes/Premium1.png' />
                  </div>
                  <Card.Header id="TituloPaquete" style={{textAlign:'center'}}>Basic</Card.Header>
                  <Card.Meta id="DescPaquete" style={{marginLeft:'5%'}} >
                      <p>1 Branch office</p>
                      <p>Members Directory Full Access</p>
                      <p>Full Access to Certifield Members</p>
                      <p>Use of CEIF Logo</p>
                      <p>CEIF Members Certificate</p>
                      <p>Newsletter Updates</p>
                      <p>Invitation to CEIF Annual Conference</p>
                      <p>Free Access to CEIF Management Tool for Instant Rates</p>
                      <p className="red">***CEIF Protection Plan (Available only  from Standard Plan)</p>
                  </Card.Meta>
                  <div className="contentPrice1">
                    <Card.Header id="PricePaquete" >$00.00 </Card.Header>
                    <Card.Header id="PriceDivisa" >USD/year*</Card.Header>

                  </div>

                </Card.Content>
                <Card.Content extra>
                  <Button id='ButtonCompra'  style={{width:'100%',backgroundColor:'rgb(232, 133, 10)'}}secondary disabled={this.state.Paquete1Enable} circular={true} onClick={this.tomarFree}>Buy now</Button>
                </Card.Content>
              </Card>

            </li>
            <li>
              <Card >
                <Card.Content>
                  <div className="ImgPaqueteContent">
                   <Image id={idActivo2} size='small' style={{display:'block', marginLeft: 'auto', marginRight: 'auto',textAlign: 'center'}}src='../imagenes/Premium2.png' />
                  </div>
                  <Card.Header id="TituloPaquete" style={{textAlign:'center'}}>Standard</Card.Header>
                  <Card.Meta id="DescPaquete" style={{marginLeft:'5%'}} >
                      <p>1 Branch office</p>
                      <p>Members Directory Full Access</p>
                      <p>Full Access to Certifield Members</p>
                      <p>Use of CEIF Logo</p>
                      <p className="red">CEIF Protection Plan</p>
                      <p>CEIF Members Certificate</p>
                      <p className="red">Payment Collection Support</p>
                      <p>Newsletter Updates</p>
                      <p>Invitation to CEIF Annual Conference</p>
                      <p>Free Access to CEIF Management Tool for Instant Rates</p>
                  </Card.Meta>
                  <div className="contentPrice2">
                    <Card.Header id="PricePaquete" >$1,100.00 </Card.Header>
                    <Card.Header id="PriceDivisa" >USD/year*</Card.Header>

                  </div>
                </Card.Content>
                <Card.Content extra>
                  <Button id='ButtonCompra'  style={{width:'100%', backgroundColor:'rgb(232, 133, 10)'}}secondary disabled={this.state.Paquete2Enable} circular={true} onClick={this.show.bind(this,"2")}>Buy now</Button>
                </Card.Content>
              </Card>
            </li>
            <li>
              <Card >
                <Card.Content>
                  <div className="ImgPaqueteContent">
                    <Image id={idActivo3} size='small' style={{display:'block', marginLeft: 'auto', marginRight: 'auto',textAlign: 'center'}}src='../imagenes/Premium3.png' />
                  </div>
                  <Card.Header id="TituloPaquete" style={{textAlign:"center"}}>Gold</Card.Header>
                  <Card.Meta id="DescPaquete" style={{marginLeft:'5%'}} >
                    <p>1 Branch office</p>
                    <p>Members Directory Full Access</p>
                    <p>Full Access to Certifield Members</p>
                    <p>Use of CEIF Logo</p>
                    <p className="red">CEIF Rates and Business Management</p>
                    <p>CEIF Protection Plan</p>
                    <p className="red">CEIF Payment Plataform</p>
                    <p>CEIF Members Certificate</p>
                    <p>Payment Collection Support</p>
                    <p>Newsletter Updates</p>
                    <p>Invitation to CEIF Annual Conference</p>
                    <p>Free Access to CEIF Management Tool for Instant Rates</p>
                    <p className="red">** 60 Days Free Trial doesnt includes Protection Plan</p>
                  </Card.Meta>
                  <div className="contentPrice3">
                    <Card.Header id="PricePaquete" >$1,450.00 </Card.Header>
                    <Card.Header id="PriceDivisa" >USD/year*</Card.Header>

                  </div>

                </Card.Content>
                <Card.Content extra>
                  <Button id='ButtonCompra'  style={{width:'100%',backgroundColor:'rgb(232, 133, 10)'}}secondary disabled={this.state.Paquete3Enable} circular={true} onClick={this.show.bind(this,"3")}>Buy now</Button>
                </Card.Content>
              </Card>
            </li>
            <li>
              <Card >
                <Card.Content>
                  <div className="ImgPaqueteContent">
                    <Image id={idActivo4} size='small' style={{display:'block', marginLeft: 'auto', marginRight: 'auto',textAlign: 'center'}}src='../imagenes/Premium4.png' />
                  </div>
                  <Card.Header id="TituloPaquete" style={{textAlign:'center'}}>Diamond</Card.Header>
                  <Card.Meta id="DescPaquete" style={{marginLeft:'5%'}} >
                    <p className="red">1 Headquarter office</p>
                    <p className="red">3 Branch office</p>
                    <p>Members Directory Full Access</p>
                    <p>Full Access to Certifield Members</p>
                    <p>Use of CEIF Logo</p>
                    <p>CEIF Rates and Business Management</p>
                    <p>CEIF Protection Plan</p>
                    <p>CEIF Payment Plataform</p>
                    <p>CEIF Members Certificate</p>
                    <p>Payment Collection Support</p>
                    <p>Newsletter Updates</p>
                    <p>Invitation to CEIF Annual Conference</p>
                    <p>Free Access to CEIF Management Tool for Instant Rates</p>
                  </Card.Meta>
                  <div className="contentPrice4">
                    <Card.Header id="PricePaquete" >$2,650.00</Card.Header>
                    <Card.Header id="PriceDivisa" >USD/year*</Card.Header>
                  </div>
                </Card.Content>
                <Card.Content extra>
                  <Button id='ButtonCompra'  style={{width:'100%', backgroundColor:'rgb(232, 133, 10)'}}secondary disabled={this.state.Paquete4Enable} circular={true} onClick={this.show.bind(this,"4")}>Buy now</Button>
                </Card.Content>
              </Card>
            </li>
            <div className="clear"/>
          </ul>
        </div>
      </div>
    )
  }
}


class ListaCard extends Component{
  constructor(){
    super()

  }

  selected=()=>{
  this.props.GetCardsCallback(this.props.datos.key);
  }

  render(){
    return(

      <div>
        <List  id="listaCards" animated verticalAlign='middle'>

          <List.Item onClick={this.selected} id="ItemCards">

            {this.props.datos.displayNumber.substring(0, 1) === '4'?
              <div>
              <Image  size='tiny' src='../imagenes/visa.png' />
              </div>:
              <div>
              <Image size='tiny' src={'../imagenes/Mastercard.png'}  />
              </div>
            }
            <List.Content id='ContentCards'>
              <List.Header>{this.props.datos.displayNumber}</List.Header>
              <List.Description>{this.props.datos.HolderCuenta}</List.Description>
            </List.Content>
          </List.Item>
        </List>
      </div>
    )
  }
}

export default PaquetesVendedor;


function organizacionArray(rutasArray,user,shippingType){

  let arrayOrganizado=[];


  /*aqui hay un problema, para acceder a los datos del arreglo hay que poner el nombre de la hoja(sheet) que se quiere
  recorer, en este caso es 'tariffs'*/
  let date = new Date();
  let mes = date.getMonth()+1;
  let dia = date.getDate();
  let ano = date.getFullYear();
  if (mes<=9) {
    mes = "0"+mes.toString();
  }
  if (dia<=9) {
    dia = "0"+dia.toString();

  }
  if (shippingType === "LCL") {
        for(var i=0; i<=rutasArray.tariffs.length; i++){
        if(rutasArray.tariffs[i] != undefined && rutasArray.tariffs[i].length>0){

            arrayOrganizado.push({
              FechaActual:ano+'-'+mes+'-'+dia,
              User:user,
              Pais:rutasArray.tariffs[i][0],
              Origen:rutasArray.tariffs[i][1],
              Destino:rutasArray.tariffs[i][2],
              Trafico:rutasArray.tariffs[i][3],
              Servicio:rutasArray.tariffs[i][4],
              Frecuencia:rutasArray.tariffs[i][5],
              Tiempo:rutasArray.tariffs[i][6],
              Tarifa:rutasArray.tariffs[i][7],
              Insurance:rutasArray.tariffs[i][8],
              PickUp:rutasArray.tariffs[i][9],
              EbsWM:rutasArray.tariffs[i][10],
              TsVgm:rutasArray.tariffs[i][11],
              RecargoIMO:rutasArray.tariffs[i][12],
              Stuffing:rutasArray.tariffs[i][13],
              Ams:rutasArray.tariffs[i][14],
              PtfWM:rutasArray.tariffs[i][15],
              Vgm:rutasArray.tariffs[i][16],
              DocFee:rutasArray.tariffs[i][17],
              Unstuffing:rutasArray.tariffs[i][18],
              InlandTransport:rutasArray.tariffs[i][19],
              Active:true,
            })
          }
        }
        return(arrayOrganizado);
      }
  else if (shippingType === "FCL") {
      for(var i=0; i<=rutasArray.tariffs.length; i++){
      if(rutasArray.tariffs[i] != undefined  && rutasArray.tariffs[i].length>0){

          arrayOrganizado.push({
            FechaActual:ano+'-'+mes+'-'+dia,
            User:user,
            Pais:rutasArray.tariffs[i][0],
            Origen:rutasArray.tariffs[i][1],
            Destino:rutasArray.tariffs[i][2],
            Trafico:rutasArray.tariffs[i][3],
            Servicio:rutasArray.tariffs[i][4],
            ContainerType:rutasArray.tariffs[i][5],
            ContainerSize:rutasArray.tariffs[i][6],
            Tarifa:rutasArray.tariffs[i][7],
            Insurance:rutasArray.tariffs[i][8],
            RecargoIMO:rutasArray.tariffs[i][9],
            PickUp:rutasArray.tariffs[i][10],
            BlFee:rutasArray.tariffs[i][11],
            Thc:rutasArray.tariffs[i][12],
            Buc:rutasArray.tariffs[i][13],
            Dre:rutasArray.tariffs[i][14],
            Cnr:rutasArray.tariffs[i][15],
            Isp:rutasArray.tariffs[i][16],
            Ams:rutasArray.tariffs[i][17],
            TerminalHandling:rutasArray.tariffs[i][18],
            ContainerCleaning:rutasArray.tariffs[i][19],
            Inland:rutasArray.tariffs[i][20],
            Active:true,


          })
        }
      }
      return(arrayOrganizado);
    }
  else if (shippingType === "BBulk") {
      for(var i=0; i<=rutasArray.tariffs.length; i++){
      if(rutasArray.tariffs[i] != undefined && rutasArray.tariffs[i].length>0){

          arrayOrganizado.push({
            FechaActual:ano+'-'+mes+'-'+dia,
            User:user,
            Pais:rutasArray.tariffs[i][0],
            Origen:rutasArray.tariffs[i][1],
            Destino:rutasArray.tariffs[i][2],
            Trafico:rutasArray.tariffs[i][3],
            Servicio:rutasArray.tariffs[i][4],
            PickUp:rutasArray.tariffs[i][5],
            Tarifa:rutasArray.tariffs[i][6],
            Insurance:rutasArray.tariffs[i][7],
            RecargoIMO:rutasArray.tariffs[i][8],
            DocFee:rutasArray.tariffs[i][9],
            ThcOrigen:rutasArray.tariffs[i][10],
            ExportDocs:rutasArray.tariffs[i][11],
            ThcDestino:rutasArray.tariffs[i][12],
            StoragePday:rutasArray.tariffs[i][13],
            CraneTariff:rutasArray.tariffs[i][14],
            Inland:rutasArray.tariffs[i][15],
            Active:true,

          })
        }
      }
      return(arrayOrganizado);
    }
  else if (shippingType === "Aereo") {
        for(var i=0; i<=rutasArray.tariffs.length; i++){
        if(rutasArray.tariffs[i] != undefined && rutasArray.tariffs[i].length>0){
            arrayOrganizado.push({
              FechaActual:ano+'-'+mes+'-'+dia,
              User:user,
              Pais:rutasArray.tariffs[i][0],
              Origen:rutasArray.tariffs[i][1],
              Destino:rutasArray.tariffs[i][2],
              Trafico:rutasArray.tariffs[i][3],
              Servicio:rutasArray.tariffs[i][4],
              TarifaAerea45:rutasArray.tariffs[i][5],
              TarifaAerea100:rutasArray.tariffs[i][6],
              TarifaAerea300:rutasArray.tariffs[i][7],
              TarifaAerea500:rutasArray.tariffs[i][8],
              TarifaAerea1000:rutasArray.tariffs[i][9],
              Insurance:rutasArray.tariffs[i][10],
              RecargoIMO:rutasArray.tariffs[i][11],
              PickUp:rutasArray.tariffs[i][12],
              Ams:rutasArray.tariffs[i][13],
              Fsc:rutasArray.tariffs[i][14],
              Tax:rutasArray.tariffs[i][15],
              HandlingOrigen:rutasArray.tariffs[i][16],
              DocFee:rutasArray.tariffs[i][17],
              AirportTaxes:rutasArray.tariffs[i][18],
              HandlingDestino:rutasArray.tariffs[i][19],
              Unstuffing:rutasArray.tariffs[i][20],
              CargoDelivery:rutasArray.tariffs[i][21],
              Active:true,

            })
          }
        }
        console.log(arrayOrganizado);
        return(arrayOrganizado);
      }
  else if (shippingType === "LTL-Truck") {
        for(var i=0; i<=rutasArray.tariffs.length; i++){
        if(rutasArray.tariffs[i] != undefined && rutasArray.tariffs[i].length>0){
            arrayOrganizado.push({
              FechaActual:ano+'-'+mes+'-'+dia,
              User:user,
              Pais:rutasArray.tariffs[i][0],
              Origen:rutasArray.tariffs[i][1],
              Destino:rutasArray.tariffs[i][2],
              Trafico:rutasArray.tariffs[i][3],
              Servicio:rutasArray.tariffs[i][4],
              Frecuencia:rutasArray.tariffs[i][5],
              Tiempo:rutasArray.tariffs[i][6],
              Tarifa:rutasArray.tariffs[i][7],
              Insurance:rutasArray.tariffs[i][8],
              PickUp:rutasArray.tariffs[i][9],
              HandlingOrigen:rutasArray.tariffs[i][10],
              HandlingDestino:rutasArray.tariffs[i][11],
              RecargoIMO:rutasArray.tariffs[i][12],
              DocFee:rutasArray.tariffs[i][13],
              LocalDelivery:rutasArray.tariffs[i][14],
              Active:true,
            })
          }
        }
        return(arrayOrganizado);
      }
  else if (shippingType === "FCL-Truck") {
      for(var i=0; i<=rutasArray.tariffs.length; i++){
      if(rutasArray.tariffs[i] != undefined  && rutasArray.tariffs[i].length>0){

          arrayOrganizado.push({
            FechaActual:ano+'-'+mes+'-'+dia,
            User:user,
            Pais:rutasArray.tariffs[i][0],
            Origen:rutasArray.tariffs[i][1],
            Destino:rutasArray.tariffs[i][2],
            Trafico:rutasArray.tariffs[i][3],
            Servicio:rutasArray.tariffs[i][4],
            ContainerType:rutasArray.tariffs[i][5],
            ContainerSize:rutasArray.tariffs[i][6],
            Frecuencia:rutasArray.tariffs[i][7],
            TTiempo:rutasArray.tariffs[i][8],
            Tarifa:rutasArray.tariffs[i][9],
            Insurance:rutasArray.tariffs[i][10],
            HandlingOrigen:rutasArray.tariffs[i][11],
            HandlingDestino:rutasArray.tariffs[i][12],
            RecargoIMO:rutasArray.tariffs[i][13],
            DocFee:rutasArray.tariffs[i][14],
            Inland:rutasArray.tariffs[i][15],

            Active:true,
          })
        }
      }
      return(arrayOrganizado);
    }
  else if (shippingType === "FTL-Truck") {
    console.log(rutasArray);

      for(var i=0; i<=rutasArray.tariffs.length; i++){
        if(rutasArray.tariffs[i] != undefined  && rutasArray.tariffs[i].length>0){

            arrayOrganizado.push({
              FechaActual:ano+'-'+mes+'-'+dia,
              User:user,
              Pais:rutasArray.tariffs[i][0],
              Origen:rutasArray.tariffs[i][1],
              Destino:rutasArray.tariffs[i][2],
              Trafico:rutasArray.tariffs[i][3],
              Servicio:rutasArray.tariffs[i][4],
              ContainerType:rutasArray.tariffs[i][5],
              ContainerSize:rutasArray.tariffs[i][6],
              Frecuencia:rutasArray.tariffs[i][7],
              TTime:rutasArray.tariffs[i][8],
              TarifaSingle:rutasArray.tariffs[i][9],
              TarifaFull:rutasArray.tariffs[i][10],
              Insurance:rutasArray.tariffs[i][11],
              PickUp:rutasArray.tariffs[i][12],
              HandlingOrigen:rutasArray.tariffs[i][13],
              HandlingDestino:rutasArray.tariffs[i][14],
              RecargoIMO:rutasArray.tariffs[i][15],
              DocFee:rutasArray.tariffs[i][16],
              LocalDelivery:rutasArray.tariffs[i][17],
              Active:true,
            })
          }
        }
        return(arrayOrganizado);
      }
  else if (shippingType === "FCL-Ferro") {
      for(var i=0; i<=rutasArray.tariffs.length; i++){
        if(rutasArray.tariffs[i] != undefined  && rutasArray.tariffs[i].length>0){
              arrayOrganizado.push({
                FechaActual:ano+'-'+mes+'-'+dia,
                User:user,
                Pais:rutasArray.tariffs[i][0],
                  Origen:rutasArray.tariffs[i][1],
                  Destino:rutasArray.tariffs[i][2],
                  Trafico:rutasArray.tariffs[i][3],
                  Servicio:rutasArray.tariffs[i][4],
                  ContainerType:rutasArray.tariffs[i][5],
                  ContainerSize:rutasArray.tariffs[i][6],
                  Frecuencia:rutasArray.tariffs[i][7],
                  TTime:rutasArray.tariffs[i][8],
                  Tarifa:rutasArray.tariffs[i][9],
                  Insurance:rutasArray.tariffs[i][10],
                  PickUp:rutasArray.tariffs[i][11],
                  HandlingOrigen:rutasArray.tariffs[i][12],
                  HandlingDestino:rutasArray.tariffs[i][13],
                  RecargoIMO:rutasArray.tariffs[i][14],
                  DocFee:rutasArray.tariffs[i][15],
                  LocalDelivery:rutasArray.tariffs[i][16],
                  Active:true,
                })
              }
            }
            return(arrayOrganizado);
      }
  else if (shippingType === "Wagon") {
        for(var i=0; i<=rutasArray.tariffs.length; i++){
          if(rutasArray.tariffs[i] != undefined  && rutasArray.tariffs[i].length>0){
                arrayOrganizado.push({
                  FechaActual:ano+'-'+mes+'-'+dia,
                    User:user,
                    Pais:rutasArray.tariffs[i][0],
                      Origen:rutasArray.tariffs[i][1],
                      Destino:rutasArray.tariffs[i][2],
                      Trafico:rutasArray.tariffs[i][3],
                      Servicio:rutasArray.tariffs[i][4],
                      ContainerType:rutasArray.tariffs[i][5],
                      Frecuencia:rutasArray.tariffs[i][6],
                      TTime:rutasArray.tariffs[i][7],
                      Tarifa:rutasArray.tariffs[i][8],
                      Insurance:rutasArray.tariffs[i][9],
                      PickUp:rutasArray.tariffs[i][10],
                      HandlingOrigen:rutasArray.tariffs[i][11],
                      HandlingDestino:rutasArray.tariffs[i][12],
                      RecargoIMO:rutasArray.tariffs[i][13],
                      DocFee:rutasArray.tariffs[i][14],
                      LocalDelivery:rutasArray.tariffs[i][15],
                      Active:true,

                    })
                  }
                }
                return(arrayOrganizado);
              }



}


export default organizacionArray;

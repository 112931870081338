import React, { Component } from 'react';
import EnviosUsuario from '../componentes/ComponentsUser/EnviosUsuario.js'
import PagosUsuario from '../componentes/ComponentsUser/PagosUsuario.js'
import PerfilUsuario from '../componentes/ComponentsUser/PerfilUsuario.js'
import PaquetesUsuario from '../componentes/ComponentsUser/PaquetesUsuario.js'

import {Route, Switch} from 'react-router-dom'
class UserSide extends Component{

  render(){
    return(
      <div>
            <Switch>
              <Route exact path="/user" component={PerfilUsuario}/>
              <Route path="/user/Pagos" component={PagosUsuario}/>
              <Route path="/user/Envios" component={EnviosUsuario}/>
              <Route path="/user/Envios/:id" component={EnviosUsuario}/>
              <Route path="/user/Paquetes" component={PaquetesUsuario}/>
            </Switch>
      </div>
    )
  }
}

export default UserSide;

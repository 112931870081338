
import React, { Component } from 'react';
import {Router, Route, BrowserRouter, Switch, Link} from 'react-router-dom'
import FinalizadoProceso from '../scripts/filtrarProcesoFinalizados.js';

import { Divider, Header, Icon,Table,Rating,Modal,Dimmer,Loader,List,Image,Button,Form,Input,Grid,Message} from 'semantic-ui-react';
import { Card } from 'semantic-ui-react'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import firebase from 'firebase'
import BarraLateral from './sideBar.js';
import './styleUser/pagosUser.css'
import Particles from 'react-particles-js';

import axios from 'axios';
import {Direccion} from '../../strings/peticiones.js';

const styles = theme=>({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  }

})

class PagosUsuario extends Component{

  constructor(){
    super()
    var date = new Date();
    let month = date.getMonth()+1;
    let day = date.getDate();
      if (month <10) {
          month = "0"+month;
        }
      if (day<10) {
        day = "0"+day;
      }
    this.state={
      type:0,
      selectedDate: date,
      fechaFormulario1:'2019-01-01',
      fechaFormulario2:date.getFullYear()+'-'+month +'-'+day,
      pagos:[],
      pagados:[],
      proceso:[],


    }
  }

  UNSAFE_componentWillMount(){
      this.getEnvios();
    }

  getEnvios(){
    try{
      let datos;
      let self=this;
      let pagos=[];
      var valoresGraph;
      var date = new Date();
      let month = date.getMonth()+1;
      let year=date.getFullYear();

      axios.post(Direccion+`/tomarPagos`,{data:{usuario:firebase.auth().currentUser.uid,inicio:this.state.fechaFormulario1,fin:this.state.fechaFormulario2}})
        .then(res => {
          datos=res.data;
          pagos=FinalizadoProceso.cantidadDiferenciaPagos(datos);
          valoresGraph=FinalizadoProceso.Cuentas(pagos.proceso,pagos.terminados,month,year);

          self.setState({
            pagados:pagos.terminados,
            proceso:pagos.proceso,
            mesGrafica:month,
            anioGrafica:year,
            valoresGrafica:valoresGraph
          })
        })
    }catch(e){
      console.log(e);
    }
  }

  handleDateChange=(date)=>{

      let fecha=date.target.value;

       this.setState({
         fechaFormulario1:fecha
       })
     }

  handleDateChange2=(date)=>{
       let fecha=date.target.value;

        this.setState({
          fechaFormulario2:fecha
        })
    }

handleChangeTab=(event, type)=>{
  this.setState({ type });
};

  render(){
    const { classes } = this.props;
    var valores=[];
    var total=parseInt(0);
    if(this.state.proceso!=undefined){
      this.state.proceso.forEach((it)=>{
        valores.push(it.precio);
        total=total+parseInt(it.precio);

      })
    }
    return(

      <div className="div-principal-pagos-vendedor">

        <BarraLateral/>
        <div style={{position:'relative'}}>
          <Particles style={{backgroundImage:'url(../imagenes/accounting.jpg)',backgroundSize:'cover',backgroundPosition:'100% -19%', position:'absolute',height:'500px'}}
               params={{
                 "particles": {
                     "number": {
                         "value": 40
                     },
                     "size": {
                         "value": 2
                     }
                 },
                 "interactivity": {
                     "events": {
                         "onhover": {
                             "enable": true,
                             "mode": "repulse"
                         }
                     }
                 }
             }} />
          <div>
            <Divider horizontal style={{paddingTop:'100px',width:'100%'}}>
              <Header as='h3'style={{position:'relative',color:'white'}}>
                  <i class="material-icons" style={{ fontSize: '2em' }}>payment</i>
                  My accounting
              </Header>
            </Divider>
            <div className="contentBuscador">
              <div className="Buscador" >
                <input type="text" onChange={this.handleDateChange} placeholder='From' onFocus={(e)=> e.currentTarget.type = "date"} onBlur={(e)=> {e.currentTarget.type = "text"; e.currentTarget.placeholder = "From"}}/>
                <input type="text" onChange={this.handleDateChange2} placeholder='to' onFocus={(e)=> e.currentTarget.type = "date"} onBlur={(e)=> {e.currentTarget.type = "text"; e.currentTarget.placeholder = "to"}}/>
                <div  className='btnBuscarFecha' onClick={this.getEnvios.bind(this)} >Search</div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Tabs value={this.state.type} onChange={this.handleChangeTab}variant="fullWidth"  indicatorColor="secondary" textColor="primary">
              <Tab icon={<i class="material-icons">compare_arrows</i>} label="Due payments" ></Tab>
              <Tab icon ={<i class="material-icons">done_outline</i>} label="Paid invoices"></Tab>
          </Tabs>

          {this.state.type===0?
            <div>
            {
                this.state.proceso.map((it,key)=>{
                  return(<CardPagosUser recargarDatos={this.getEnvios}  datos={it}/>)
                })

              }
            </div>
            :
            <div></div>
          }

          {this.state.type===1?
            <div>
            {
              this.state.pagados.map((it,key)=>{
                return(<CardPagosUser recargarDatos={this.getEnvios} datos={it}/>)
              })

            }
            </div>
            :
            <div></div>
          }

        </div>
      </div>
    )
  }
}


class CardPagosUser extends Component{

  constructor(){
    super();

    this.state={
      open: false,
      openfinalizado: false,
      dimmer:true,
      Cards:[],
      confirmRate:false,
      rate:0,
      openconfirm:false,
      CardIndex:0,
      diasRestantes:0,
      fechaCaducada:false,
      tipoPago:'',
      pasoPago:1,
      selectCard:false,
    }
    this.UsingCard=this.UsingCard.bind(this);

  }

  UNSAFE_componentWillMount(){
  this.getDiasRestantes();
  }

  getDiasRestantes=()=>{
    let self = this;
    axios.post(Direccion+`/getDiasRestantes`,
      {DeadLinePago:this.props.datos.DeadLinePago,FechaSolicitudPago:this.props.datos.FechaSolicitudPago,})
      .then(res => {
        if (res.data) {
          self.setState({
            diasRestantes:res.data,
          })
        }else if (res.data === 'fecha_caducada'){
             self.setState({
                fechaCaducada:true,
              })
        }
    })

  }


  // Inicio=()=>{
  //   var num=0;
  //   let self = this;
  //   let perfil ={};
  //   self.setState({Pagado:this.props.datos.pagado,})

  //         axios.post(Direccion+`/tomarCards`,
  //            {idUser:firebase.auth().currentUser.uid,tipo:"Cliente",})
  //           .then(res => {
  //             if (res.data.length !=0 && res.data.length >0) {
  //               self.setState({
  //                 Cards:res.data,
  //               })
  //             }
  //             else {
  //               self.setState({
  //                 Cards:[],
  //               })
  //             }
  //         })

  // }

 // Pagar=()=>{
 //    this.setState({  dimmerActive: true,openconfirm:false,open:false })
 //    let self =this;
 //    console.log(this.state.CardIndex);
 //    axios.post(Direccion+`/PagarEnvio`,
 //      {idUser:firebase.auth().currentUser.uid,tipo:"Cliente",idEnvio:this.props.datos.idTransaccion,idVendedor:this.props.datos.Vendedor,idCard:this.state.CardIndex,Expiry:this.state.expiry,CVV:this.state.cvc})
 //      .then(res => {
 //        if (res.data === "OK") {
 //          self.Inicio();
 //          this.setState({  dimmerActive: false,expiry:'',cvc:'', idCard:'',selectCard:false});
 //          self.props.recargarDatos();
 //        }
 //        else if(res.data === "101"){
 //          this.setState({  dimmerActive: false,expiry:'',cvc:'', idCard:'',selectCard:false});
 //          alert("expiration date or CVV are wrong");
 //        }
 //        else {
 //          this.setState({  dimmerActive: false,expiry:'',cvc:'', idCard:'',selectCard:false});
 //        }
 //    })
 //  }

  // Calificar=()=>{
  //   let self = this;
  //   this.setState({  dimmerActive: true,confirmRate:false, open:false })
  //   axios.post(Direccion+`/ConfirmarPago`,
  //     {idVendedor:this.props.datos.Vendedor,Rate:this.state.rate, idUser:firebase.auth().currentUser.uid, idEnvio:this.state.datos.idTransaccion})
  //     .then(res => {
  //       axios.post(Direccion+`/CalificarUsuario`,
  //           {idUser:this.props.datos.Vendedor,tipo:"Vendedor",userRate:this.state.rate})
  //           .then(res => {
  //             if (res.data === "OK") {
  //               this.setState({  dimmerActive: false, })
  //               self.props.recargarDatos();
  //             }
  //             else {
  //               this.setState({  dimmerActive: false, })
  //               self.props.recargarDatos();
  //               console.log(res.data);
  //             }
  //         })
  //       })
  //   }

    handleInputChange = (e) => {
    const target = e.target;
    if (target.name === 'expiry') {
      var value=target.value;
      if (/^([0-9-/])*$/.test(target.value) ){
          if(value.length===2 ){
            this.setState({
              [target.name]:value.replace(/ |\//g, '')+'/',
            });
          }
          else{
            this.setState({
              [target.name]:value,
            });
          }
      }
    }else if (target.name === 'cvc'){
      var value=target.value;
      if (/^([0-9])*$/.test(target.value)){
        this.setState({
          [target.name]:value,
        });
      }

    }
  }//cierre handle

  handleRate =(e, { rating, maxRating })=>{
    this.setState({ confirmRate: true, rate:rating});
  }

  close2 = () =>{
     this.setState({ openconfirm: false,pasoPago:1,expiry:'',cvc:'',selectCard:false,});
  }



  generarPago=(tipoPago)=>{
    this.setState({  tipoPago: tipoPago,pasoPago:2, })
  }

  closeRate= () =>{
     this.setState({  confirmRate: false,});
  }
  confimar=()=>{
    this.setState({  openconfirm: true, })
  }

  UsingCard=(key)=>{
    this.setState({  CardIndex: key, selectCard:true, })
  }
  show = dimmer => () => this.setState({ dimmer, open: true })
  showFinalizado = dimmer => () => this.setState({ dimmer, openfinalizado: true })

  close = () => this.setState({ open: false, openfinalizado: false })

  render(){
    var datos=this.props.datos;
    return(

      <div>
        <Card  style={{borderRadius:'2vh', width:'90%', minWidth:'250px', margin:' 1em 2.5em'}}>
          <Card.Content>

          <div style={{width:"100%", display:"inline-flex"}}>

            <div style={{ width:"25%"}}>
              <Card.Meta>ID Transaction</Card.Meta>
              <Card.Header>{datos.key}</Card.Header><br/>

              <Card.Meta>Date</Card.Meta>
              <Card.Header>{datos.dateTime}</Card.Header><br/>

            </div>

            <div style={{marginLeft:"10%", width:"30%"}}>
              <Card.Meta>Amount</Card.Meta>
              <Card.Header>${datos.Amount} USD</Card.Header><br/>

              <Card.Meta>Reason</Card.Meta>
              {datos.PagoOrigen === 1?
                <div>
                 <Card.Header>rate payment</Card.Header><br/>
                </div>
                :datos.PagoOrigen === 2?
                <div>
                 <Card.Header>subscription payment</Card.Header><br/>
                </div>
                :datos.PagoOrigen === 3?
                <div>
                 <Card.Header>rate cancellation payment</Card.Header><br/>
                </div>
                :
                <div>
                 <Card.Header></Card.Header><br/>
                </div>

              }
            </div>

            <div style={{marginLeft:"10%"}}>
              <Card.Meta>Card number</Card.Meta>
              <Card.Header>{datos.displayNumber}</Card.Header><br/>

              <Card.Meta>Description</Card.Meta>
              <Card.Header>{datos.Descripcion}</Card.Header><br/>
            </div>

            <div className='IconoRight'>
            {datos.PagoType === 'abono'?
              <Icon style={{marginLeft:'23%'}} color='teal' size='big'  name='angle up' />:<Icon style={{marginLeft:'23%'}} color='red' size='big'  name='angle down' />
            }

            </div>

          </div>

          </Card.Content>

        </Card>
      </div>
    )
  }
}


PagosUsuario.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default  withStyles(styles)(PagosUsuario);

import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';

async function ModifyPdf(id,monto) {
  try{
  const url = 'https://drive.google.com/file/d/1z66At6IHApl8s93THBZZ6PstdcQD8xMY/view?usp=share_link';
  const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

  const pdfDoc = await PDFDocument.load(existingPdfBytes)
  const arial = await pdfDoc.embedFont(StandardFonts.Helvetica)

  const pages = pdfDoc.getPages()
  const firstPage = pages[0]
  const { width, height } = firstPage.getSize()
  firstPage.drawText('Transaction ID :' +id , {
    x: 30,
    y: 180,
    size: 16,
    font: arial,
    color: rgb(0.30, 0.26, 0.26),
    rotate: degrees(0),
  })
  firstPage.drawText('Amount : $' +monto , {
    x: 30,
    y: 200,
    size: 16,
    font: arial,
    color: rgb(0.30, 0.26, 0.26),
    rotate: degrees(0),
  })

  const pdfBytes = await pdfDoc.save();
  //window.open(pdfBytes, '_blank');
  var byteArray = new Uint8Array(pdfBytes);
  var a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
  a.download = 'bank.pdf';
  document.body.appendChild(a)
  a.click();
}catch(err){
  console.log(err);
}
}

export default ModifyPdf;

import React, { Component,useState, useEffect } from 'react';
import PublicNavbar from '../ComponentesGenerales/Menus/MenuPublicov2.js';

import Footer from '../ComponentesGenerales/Footer/Footer.js';
import {Icon} from 'semantic-ui-react';
import './LandingStyle.css';
import Header from '../ComponentesGenerales/landing/header-landing.js';
export default function About (){
  return(
    <div className='About'>
      <PublicNavbar/>
      <Header video='./imagenes/LandingPage/video/about.mp4' texto1='Our story'texto2='Search from over 120K Ocean, Air and Truck Freight quotes and get the latest international shipping rates from any carrier in seconds.'/>
      <section classNam='seccion1'>
        <div className='itemAbout'>
          <div className='itemTitulo'>
            <div className='circleGris'></div>
            <p>Our history</p>
          </div>
          <div className='itemDescripcion'>
            <p>
              We are an organization created by Freight Forwarders, with more than 20 years of experience
              within the International Freight Transportation industry. This organization was founded in 2017
              from our need as Freight Forwarders to expand our services beyond geographic limitations. ﻿
              Despite the existence of Freight Networks, we find that there are many deficiencies with the
              traditional system, since, in most cases, obtaining a rate or quote can take up to 72 hours,
              reducing competitiveness, and in many cases, making us lose the project.
              <br/>
              <br/>
              Another important issue that we identified is the fulfillment of payments between operations
              by importers or exporters, and Freight Forwarders, where in many cases the payment times
               were extended for longer than authorized or even worse, the payment never arrived.
            </p>
          </div>
        </div>
        <div className='itemAbout'>
          <div className='itemTitulo'>
            <div className='circleGris'></div>
            <p>About CEIF Freight App</p>
          </div>
          <div className='itemDescripcion'>
            <p>
              After conducting a market study, we created a new Freight Network Digital model, to which
              we incorporated our Multi-Cooperative Digital Platform “CEIF Freight App”, a friendly Platform
              that intuitively integrates a Marketplace to quote in real time, Administration Shipping,
              Document Management, Rate Management, Payment Management, etc.
            </p>
          </div>
        </div>
        <div className='itemAbout'>
          <div className='itemTitulo'>
            <div className='circleGris'></div>
            <p>Who can use CEIF Freight App?</p>
          </div>
          <div className='itemDescripcion'>
            <p>
              This platform is aimed at Importers & Exporters, Freight Forwarders, and Freight Networks, in any
              case, what we offer is to simplify their operational processes and help expand the range of
              businesses of our users without geographic, time or language limitations.﻿

            </p>
          </div>
        </div>
        <div className='itemAbout'>
          <div className='itemTitulo'>
            <div className='circleGris'></div>
            <p>Our mission</p>
          </div>
          <div className='itemDescripcion'>
            <p>
              Help Importers & Exporters, and Freight Forwarders to understand the benefits to switch to Digital
              Freight Forwarding, Simplify and reduce the cost of shipping to them.
            </p>
          </div>
        </div>
      </section>
      <Footer/>

    </div>

  )
}

import React, { Component } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Link} from 'react-router-dom'
import {Direccion,DireccionFront} from '../../strings/peticiones.js';

class ListaNotificaciones extends Component{
  constructor(){
    super()
    this.state = {
    }

  }

  render(){
    return(
      <List>

      {
        this.props.datos.map((it,key)=>{
          return(<React.Fragment><Notificacion datos={it} key={key}/>
          <Divider variant="inset" component="li" /></React.Fragment>)
        })

      }



    </List>

    )
  }
}

class Notificacion extends Component{

  constructor(){
    super();
  }


  render(){
    var datos=this.props.datos;
    var imagen;
    var direccion;
    if(datos.tipo==='pago'){
        imagen=DireccionFront+'/imagenes/payReq.png';
        direccion='/user/Envios/'+datos.idTransaccion;
    }
    else if(datos.tipo==='archivo'){
      imagen=DireccionFront+'/imagenes/files.png';
      direccion='/user/Envios/'+datos.idTransaccion;

    }
    else if(datos.tipo==='status'){
      imagen=DireccionFront+'/imagenes/status.png';
      direccion='/user/Envios/'+datos.idTransaccion;

    }
    else if(datos.tipo==='bl'){
      imagen=DireccionFront+'/imagenes/bldetails.png';
      direccion='/user/Envios/'+datos.idTransaccion;

    }
    else if(datos.tipo==='Aceptado'){
      imagen=DireccionFront+'/imagenes/bldetails.png';
      direccion='/user/Envios/'+datos.idTransaccion;
    }
    else{
       imagen=DireccionFront+'/imagenes/bldetails.png';
      direccion='/user/Envios/'+datos.idTransaccion;
    }
    return(
      <Link to={direccion}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Remy Sharp" src={imagen} />
          </ListItemAvatar>
          <ListItemText
            primary={datos.descripcionEspañol}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"

                  color="textPrimary"
                >
                { datos.hora+' '+datos.fecha}
                </Typography>
                {" — Booking reference "+datos.idTransaccion}
              </React.Fragment>
            }
          />
        </ListItem>
      </Link>

    )
  }
}


export default ListaNotificaciones;

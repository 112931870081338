
const espanol={
  registro:{
    tipoCliente:'Cliente',
    tipoVendedor:'Vendedor',
    RegistroForm:{
      CompanyName:'Company Name',
      FirstName:'First Name',
      LastName:'Last Name',
      Title:'Title',
      Mobil:'Mobil',
      Email:'Email',
      Phone:'Phone',
      Fax:'Fax',
      Website:'Website',
      EstablishmentYear:'Establishment Year',
      NoEmployees:'No. of employees',
      NameOwners:'Name of company owner(s)',
      Address:'Address',
      City:'City',
      PostaleCode:'Zip Code',
      State:'Province/State',
      Contry:'Country',
      CompanyRef1:'Company Ref (#1)',
      ContactRef1:'Contact Name (#1)',
      EmailRef1:'Email (#1)',
      CompanyRef2:'Company Ref (#2)',
      ContactRef2:'Contact Name (#2)',
      EmailRef2:'Email (#2)',
      CompanyRef3:'Company Ref (#3)',
      ContactRef3:'Contact Name (#3)',
      EmailRef3:'Email (#3)',
      RelationShip:'We have commercial relationship with above mentioned companies',
    },
  }

};

export function Idioma(idioma){
  if(idioma==='ES'){
    return espanol;
  }
}

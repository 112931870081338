import React, { Component } from 'react';
import { Dropdown,Divider,Header, Icon, Table, Card,Radio, Image,Button,Modal, Form,Segment,Menu,Checkbox , Input, Label} from 'semantic-ui-react';
import {Idioma} from '../../../../strings/BuscarRutaText.js';
import Switch from '@material-ui/core/Switch';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';



const medidas =[
  {key:'cm',text:'cm',value:'cm'}
];
const medidasT =[
  {key:'mfc',text:'mfc',value:'mfc'},
  {key:'cft',text:'cft',value:'cft'}
];



const pesos =[
  {key:'kg',text:'kg',value:'kg'}
]

const valoresCbm=[
  {key:'CBM',text:'CBM',value:'CBM'}
]

class TipoFerro extends Component{
  constructor(props){
    super(props)
    this.state={
      open:this.props.open,
      activeItem:'FCL',
      tipoContainer:'Dry',
      TipoContenido:'',
      Payment:'prepaid',
      listo:false,
      incoterm:'',
      seguro:false,
      IMOCargo:false,
      openSeguro:false,
      montoSeguro:'',
      peso:'',
      container:'ferro20.png',
      container1:'wagon01.png',
      opcionFTL:'Single',
      WagonSelect:false,
      ServicioCO:false,
      ServicioCD:false,
      AccordionSeccion1:true,
      AccordionSeccion2:true,
      AccordionSeccion2:false,
    }
  }



  callbackItem=(value)=>{this.setState({TipoContenido:value})}


  RegresarObjeto=()=>{

    var objPeticion = {}

    let objServicios = {PO:this.state.ServicioPO,CO:this.state.ServicioCO,CD:this.state.ServicioCD,PD:this.state.ServicioPD,}

    if (this.state.TipoContenido) {
      switch (this.state.activeItem) {
        case "FCL":
            switch (this.state.tipoContainer) {
              case "20":
                objPeticion={objServicios:objServicios,IMOCargo:this.state.IMOCargo,Seguro:this.state.seguro,payment:this.state.Payment,montoSeguro:this.state.montoSeguro,contenido:this.state.TipoContenido, Contenedor:"FCL-Ferro",Medidas:"20",ContainerType:'ST'}
                this.setState({
                  listo:true
                })
                  this.props.callBackVentana(objPeticion);
                break;
              case "40":
              objPeticion={objServicios:objServicios,IMOCargo:this.state.IMOCargo,Seguro:this.state.seguro,payment:this.state.Payment,montoSeguro:this.state.montoSeguro,contenido:this.state.TipoContenido, Contenedor:"FCL-Ferro",Medidas:"40",ContainerType:'ST'}
              this.setState({
                listo:true
              })
                this.props.callBackVentana(objPeticion);
                break;
              case "40H":
              objPeticion={objServicios:objServicios,IMOCargo:this.state.IMOCargo,Seguro:this.state.seguro,payment:this.state.Payment,montoSeguro:this.state.montoSeguro,contenido:this.state.TipoContenido, Contenedor:"FCL-Ferro",Medidas:"40",ContainerType:'HQ'}
              this.setState({
                listo:true
              })
                this.props.callBackVentana(objPeticion);
                break;
            }

          break;
        case "Wagons":
                  objPeticion={objServicios:objServicios,IMOCargo:this.state.IMOCargo,Seguro:this.state.seguro,payment:this.state.Payment,montoSeguro:this.state.montoSeguro,contenido:this.state.TipoContenido, Contenedor:"Wagon",ContainerType:this.state.tipoContainer,Incoterm:this.state.incoterm}
                  this.setState({
                    listo:true
                  })
                  this.props.callBackVentana(objPeticion);

          break;
    }
    }
    else {
      alert("Please select cargo category");
    }
  }

  handleItemClick = (e, name ) => this.setState({ activeItem: name })

  tipoContenedor=(e,tipo)=>{
    this.setState({
      container:e,
      tipoContainer:tipo
    })
  }
  tipoContenedor2=(e,tipo)=>{
    this.setState({
      container1:e,
      tipoContainer:tipo,
      WagonSelect:true,
    })
  }


  close = () => {
    this.setState({
      open:false
    })
      this.props.callBackVentana(false);
 }

  handleCbm= (e, { value }) => this.setState({ CBM: value })

  handlePeso = (e, { value }) => this.setState({ peso: value })
  handleChangeOpcionFTL= (e, { value }) => this.setState({ opcionFTL: value })
  handleIncoterm=(e,{value})=>{
    if (value==="DDP") {
      this.setState({incoterm:value,openSeguro:true})
    }
    else {
      this.setState({incoterm:value})

    }
  }
  handleChangeSeguro = name => event => {
   if(this.state.seguro){
     this.setState({seguro:false,openSeguro:true});
   }
   else{
   this.setState({seguro:true,openSeguro:true});
    }
  }

  handleCloseSeguro=()=>{
    this.setState({openSeguro:false});
  }

  handleChangePayment=(e,index)=>{
    this.setState({
      Payment: e,
    })
  }

  CargoServicios=(Cargo,e)=>{
    if (Cargo === 'PO') {
      this.setState({ServicioPO:!this.state.ServicioPO})
    }else if (Cargo === 'CO') {
      this.setState({ServicioCO:!this.state.ServicioCO})
    }else if (Cargo === 'CD') {
      this.setState({ServicioCD:!this.state.ServicioCD})
    }else if (Cargo === 'PD') {
      this.setState({ServicioPD:!this.state.ServicioPD})
    }
  }

  handleMontoSeguro = (e, { value }) => this.setState({ montoSeguro: value })
  handleBorrarSeguro=()=>{
    this.setState({montoSeguro:'',openSeguro:false});
  }
  handleAcceptSeguro=()=>{
    this.setState({openSeguro:false,seguro:true,});
  }



  render(){
    const { activeItem } = this.state;
    const { checkBox } = this.state
    let text=Idioma('ES').textos;
    let lista=Idioma('ES').lista;

    return(

      <Modal id="ModalFerro" open={this.props.open} onClose={this.close}>
        <div className='ContenidoPadre'>
          <div  className='AccordionItem'>
            <div onClick={()=>this.setState({AccordionSeccion1:!this.state.AccordionSeccion1})} className='AccordionHeader'>
                <div className='tituloModal'>Cargo categories and measurement</div>
                <Icon className='AccordionIcon' name='dropdown' />
              </div>
              <div className={this.state.AccordionSeccion1?'divider':'divider collapse'}/>

              {this.state.AccordionSeccion1?
                <section className='seccion1'>
                  <div className='dropdownVentana'>
                    <Dropdown value={this.state.TipoContenido}
                      onChange={(e,{ name, value })=>this.setState({TipoContenido:value,errorCargo:false})}
                      placeholder='Cargo categories'
                      clearable
                      selection
                      error={!this.state.TipoContenido && this.state.errorCargo}
                      style={!this.state.errorCargo?{border:'1px solid #ff9347'}:{textAlign:'left'}}
                      fluid
                      className='icon'
                      options={lista}
                    />
                  </div>

                  <div  className='MenuVentana dos'>
                      <div className={activeItem === 'ConteFCL'?'itemMenu Fcl Activo':'itemMenu Fcl'} onClick={e => this.setState({activeItem: "FCL"})} >
                        <h4>FCL</h4>
                      </div>
                      <div className={activeItem === 'CajasLcl'?'itemMenu  Wagons Activo':'itemMenu Wagons'} onClick={e => this.setState({activeItem: "Wagons"})} >
                        <h4>Wagons</h4>
                      </div>
                  </div>
                  <div className='contenido-menu'>
                    {activeItem=== 'FCL'?
                      <div className='uiForm'>
                        <img className='imgContenido Contenedor' src={'../imagenes/camiones/'+this.state.container}/>
                        <div className='botonesFcl'>
                          <div className={this.state.tipoContainer === '20'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ container:'ferro20.png',tipoContainer:'20'})}>{text.botones.veinte}</div>
                          <div className={this.state.tipoContainer === '40'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ container:'ferro40.png',tipoContainer:'40'})}>{text.botones.cuarenta}</div>
                          <div className={this.state.tipoContainer === '40hq'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ container:'ferro40.png',tipoContainer:'40hq'})}>{text.botones.cuarentaHQ}</div>
                        </div>
                      </div>
                    : activeItem==='Wagons'?
                      <div className='uiForm'>
                        <img className='imgContenido Contenedor' src={'../imagenes/camiones/'+this.state.container1}/>
                        <div className='botonesFcl'>
                          <div className={this.state.tipoContainer === 'Dry'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ container1:'wagon01.png',tipoContainer:'Dry'})}>Dry</div>
                          <div className={this.state.tipoContainer === 'Tank'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ container1:'wagon02.png',tipoContainer:'Tank'})}>Tank</div>
                          <div className={this.state.tipoContainer === 'Flat'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ container1:'wagon03.png',tipoContainer:'Flat'})}>Flat</div>
                          <div className={this.state.tipoContainer === 'Hopper'?'btnFCL Activo':'btnFCL'} onClick={()=>this.setState({ container1:'wagon04.png',tipoContainer:'Hopper'})}>Hopper</div>
                        </div>

                      </div>
                     :
                     <div>
                    </div>
                    }
                  </div>
                </section>
                :<div></div>
              }

          </div>
          <div  className='AccordionItem'>
              <div onClick={()=>this.setState({AccordionSeccion3:!this.state.AccordionSeccion3})} className='AccordionHeader'>
                  <div className='tituloModal'>Shipment</div>
                  <Icon className='AccordionIcon' name='dropdown' />
                </div>
                <div className={this.state.AccordionSeccion3?'divider':'divider collapse'}/>
                {this.state.AccordionSeccion3?
                  <section className='seccion3'>

                    <div className='containerContenido'>

                      <div className='CargoShipping'>
                        <div onClick={this.CargoServicios.bind(this,"PO")} className={this.state.ServicioPO?'CardCargo active':'CardCargo'}>
                          <Icon  name='shipping' />
                          <Card.Content>
                            Local PickUp
                          </Card.Content>
                        </div>
                        <div onClick={this.CargoServicios.bind(this,"CO")}  className={this.state.ServicioCO?'CardCargo active':'CardCargo'}>
                          <Icon  name='shipping' />
                          <Card.Content>
                            Origin Charges
                          </Card.Content>
                        </div>
                        <div  className='CardCargo active'>
                          <Icon  name='shipping' />
                          <Card.Content>
                            Ocean Freight
                          </Card.Content>
                        </div>
                        <div onClick={this.CargoServicios.bind(this,"CD")}  className={this.state.ServicioCD?'CardCargo active':'CardCargo'}>
                          <Icon  name='shipping' />
                          <Card.Content>
                            Destination Charges
                          </Card.Content>
                        </div>
                        <div onClick={this.CargoServicios.bind(this,"PD")}  className={this.state.ServicioPD?'CardCargo active':'CardCargo'}>
                          <Icon  name='shipping' />
                          <Card.Content>
                            Local Delivery
                          </Card.Content>
                        </div>
                      </div>
                      <div className="statusContainer">
                          <span className='routeShipping'>
                          <span className='routeIcon'>
                            <Icon  name='shipping' className={this.state.ServicioPO?'icono active':'icono'}  />
                          </span>
                          <span className={this.state.ServicioPO?'line active':'line'}>
                            <Icon id={this.state.ServicioPO?'dot-left-active':'dot-left'} name='dot circle outline' />
                            <Icon id={this.state.ServicioCO?'dot-right-active-blue':'dot-right'} name='dot circle outline' />
                          </span>
                          </span>
                          <span className='routeShipping'>
                            <span className='routeIcon'>
                            <Icon  name='ship' className='icono activeBlue'  />
                            </span>
                            <span className='line activeBlue'></span>
                          </span>
                          <span className='routeShipping'>
                            <span className='routeIcon'>
                            <Icon  name='shipping' className={this.state.ServicioPD?'icono active':'icono'}  />
                            </span>
                            <span className={this.state.ServicioPD?'line active':'line'}>
                             <Icon id={this.state.ServicioCD?'dot-left-active-blue':'dot-left'} name='dot circle outline' />
                             <Icon id={this.state.ServicioPD?'dot-right-active':'dot-right'} name='dot circle outline' />
                           </span>
                          </span>
                      </div>
                      <div className='CargoInsurace'>
                        <Button.Group  >
                          <Button onClick={this.handleChangePayment.bind(this,'prepaid')} positive={this.state.Payment==='prepaid'? true: false} style={{width:'40%'}}>prepaid</Button>
                          <Button.Or />
                          <Button onClick={this.handleChangePayment.bind(this,'collect')} positive={this.state.Payment==='collect'? true: false}  style={{width:'40%'}}>collect</Button>
                        </Button.Group>
                          <FormControlLabel
                            id='SwitchInsurace'
                            control={<Switch checked={this.state.seguro}
                            onChange={this.handleChangeSeguro('checked')}
                            value="checked"/>}
                            label={text.ventanaCarga.seguro}
                            labelPlacement="bottom"
                         />
                         <FormControlLabel
                           id='SwitchImo'
                           control={<Switch checked={this.state.IMOCargo}
                           onChange={()=>this.setState({IMOCargo:!this.state.IMOCargo})}
                           value="checked"/>}
                           label={"Cargo IMO"}
                           labelPlacement="bottom"
                        />
                      </div>
                    </div>
                  </section>
                  :<div></div>
                }
            </div>

        </div>

        <Modal.Actions>

        <Button
          negative
          style={{width:'35%'}}
          icon='cancel'
          labelPosition='left'
          content={text.botones.cancelar}
          onClick={this.close}
        />

          <Button
            positive
            style={{width:'35%'}}
            icon='checkmark'
            labelPosition='right'
            content={text.botones.aceptar}
            onClick={this.RegresarObjeto}
          />
        </Modal.Actions>
        <Dialog
          open={this.state.openSeguro}
          onClose={this.handleCloseSeguro}
          aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">{text.ventanaCarga.colocaMonto}</DialogTitle>
              <DialogContent>
              <Form>
              <Form.Input onChange={this.handleMontoSeguro} value={this.state.montoSeguro} type='number' placeholder='Amount' width={10}  />
              </Form>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleBorrarSeguro} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleAcceptSeguro} color="primary" autoFocus>
                  Accept
                </Button>
              </DialogActions>
        </Dialog>
      </Modal>
    )
  }
}

class ItemLista extends Component{
  constructor(props){
    super(props)
    this.state={
      container:"",
      TipoContenido:"",
      active:false,
    }

  }




  callBackItem=(e,value)=>{
    this.setState({
      TipoContenido:value,
      active: !this.state.active,
    })
    this.props.callBackItem(value);


  }


  render(){
    return(
      <ListItem selected={this.state.active} onClick={e => this.callBackItem(e,this.props.datos.nombre)} button>
        <ListItemIcon>
        <Icon name={this.props.datos.icon} size='large' />
        </ListItemIcon>
        <ListItemText inset primary={this.props.datos.nombre} />
      </ListItem>

    )
  }
}


export default TipoFerro;

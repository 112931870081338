import React, { Component } from 'react';
import BarraLateral from './sideBar.js';
import Icon from "@material-ui/core/Icon";
// core components
import GridItem from "../card/GridItem.jsx";
import GridContainer from "../card/GridContainer.jsx";
import {ref,firebaseAuth} from '../../const.js';
import {Direccion} from '../../strings/peticiones.js';
import axios from 'axios';

import firebase from 'firebase'

import CardComponent from "../card.js"
import './styleSeller/analitics.css';


class Analitics extends Component{
  constructor(){
    super()
    this.state={
      notificaciones:[],
      RutasActivas:0,
      RutasNoActivas:0,
      RutasListas:false,
      BookingsTerminados:0,
      BookingsProceso:0,
      BookingListos:false,
    }
  }
  componentDidMount(){
    this.getRutas();
    this.getBooking();

  }

  getRutas=()=>{
    let self = this;
    axios.post(Direccion+`/tomarRutas-analitics`,{idUser:firebase.auth().currentUser.uid})
    .then(res => {
      if (res.data.status === 'OK') {
        self.setState({
          RutasActivas:res.data.RutasActivas,
          RutasNoActivas:res.data.RutasNoActivas,
          RutasListas:true,
        })
      }else{
        console.log('error');
      }
    }).catch(err=>{
      console.log(err);
    })
  }
  getBooking=()=>{
    let self = this;

    axios.post(Direccion+`/tomarBooking-analitics`,{idUser:firebase.auth().currentUser.uid})
    .then(res => {
      console.log(res.data);
      if (res.data.status === 'OK') {
        self.setState({
          BookingsTerminados:res.data.BookingsTerminados,
          BookingsProceso:res.data.BookingsProceso,
          BookingListos:true
        })
      }else{
        console.log('error');
      }
    }).catch(err=>{
      console.log(err);
    })
  }
  getOperaciones=()=>{
    let self = this;

    axios.post(Direccion+`/tomarOperaciones-analitics`,{idUser:firebase.auth().currentUser.uid})
    .then(res => {

      if (res.data.status === 'OK') {
        self.setState({
          OperacionesActivas:res.data.OperacionesActivas,
          OperacionesNoActivas:res.data.OperacionesNoActivas,
        })
      }else{
        console.log('error');
      }
    }).catch(err=>{
      console.log(err);
    })
  }


  render(){
    return(
      <div>
        <BarraLateral/>
        <div style={{padding:'5vh'}}>
          <GridContainer>
            <GridItem xs={120} sm={6} md={5}>
              <CardComponent icono='mail'
              colorheader="warning" coloricon="rose"
              titulo={this.state.nombre}
              dato='Hello'
              saludo={true}
              notificaciones={this.state.notificaciones}/>
            </GridItem>
            {this.state.RutasListas?
              <GridItem xs={12} sm={6} md={3}>
                <CardComponent icono='swap_calls'
                colorheader="warning" coloricon="success"
                titulo='Active rates packages'
                dato={this.state.RutasActivas}/>
              </GridItem>

              :
              <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            }
            {this.state.RutasListas?
              <GridItem xs={12} sm={6} md={3}>
                <CardComponent colorheader="warning" coloricon="danger"
                icono='priority_high'
                titulo='Expired rates packages'
                dato={this.state.RutasNoActivas}/>
              </GridItem>

              :
              <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            }

            {this.state.BookingListos?
              <GridItem xs={12} sm={6} md={3}>
                <CardComponent colorheader="warning" coloricon="info"
                icono='directions_boat'
                titulo='Operations in transit'
                dato={this.state.BookingsTerminados}/>
              </GridItem>

              :
              <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            }

            {this.state.BookingListos?
              <GridItem xs={12} sm={6} md={3}>
                <CardComponent colorheader="warning" coloricon="primary"
                icono='edit_attributes'
                titulo='Operations completed'
                dato={this.state.BookingsProceso}/>
              </GridItem>

              :
              <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            }
          </GridContainer>
        </div>
      </div>
    )
  }
}


export default Analitics;

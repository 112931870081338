import React, { Component,useState, useEffect } from 'react';
import { Icon } from 'semantic-ui-react'

import './Footer.css';


export default function Footer (){
  return(
    <div className='contentFooterBackground'>
        
      <div className='itemFooterContent'>
        <div className='logo_footer'>
          <img src='../imagenes/LandingPage/logo.png'/>
        </div>
          <div className='itemFooter'>
            <p>CEIF Logistic Services LLC </p>
            <p>2803 Philadelphia Pike , Suite B #214 </p>
            <p>Claymont, DE 19703 , USA</p>
          </div>
          <div className='itemFooter'>
            <div className='redes_sociales'>
             <a href='https://www.facebook.com/CEIFNETWORK' Target="_blank" ><Icon name='facebook' size='large' color='red'/></a>
             <a href='https://www.linkedin.com/in/ceif-freight-12508119a/' Target="_blank" ><Icon name='linkedin' size='large' color='red'/></a>
             <a href='https://www.instagram.com/ceiflogistics/' Target="_blank" ><Icon name='instagram' size='large' color='red'/></a>
             <a href='https://twitter.com/CeifNetwork/' Target="_blank" ><Icon name='twitter' size='large' color='red'/></a>
            </div>
            <p style={{marginTop:'30px'}}>Support email: info@ceif.app</p>
          </div>
      </div>
      <div className='FooterDown'>
          <a>Terms and Conditions | Privacy Policy</a>
          <p>Copyright © 2017-2020 CEIF Logistics Network. All rights reserved.</p>
      </div>
    </div>

  )
}

import React, { Component,useState,useEffect } from 'react';
import axios from 'axios';
import {Direccion,DireccionFront} from '../../strings/peticiones.js';
import {Idioma} from '../../strings/loginText.js';
import { Menu,Loader,Confirm, Segment,Divider,Image,Checkbox,Dimmer,Item,Header, Dropdown,Icon,Label,Modal,Rating,Grid,Card,Button,Transition,Form } from 'semantic-ui-react'
import BarraLateral from './sideBarAdmin.js';
import Particles from 'react-particles-js';
import './styleAdmin/usuariosAdmin.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import NavigationIcon from '@material-ui/icons/Navigation';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import '../login.css';

const opcionesPaquetes = [
  {
    key: 1,
    text: 'Basic',
    value: 1,
    image: { avatar: true, src: '/imagenes/Premium1.png' },
  },
  {
    key: 2,
    text: 'Standard',
    value: 2,
    image: { avatar: true, src: '/imagenes/Premium2.png' },
  },
  {
    key: 3,
    text: 'Gold',
    value: 3,
    image: { avatar: true, src: '/imagenes/Premium3.png' },
  },
  {
    key: 4,
    text: 'Diamond',
    value: 4,
    image: { avatar: true, src: '/imagenes/Premium4.png' },
  }
]
class Usuarios extends Component{
  constructor(){
    super()
    this.state = {
      usuariosVendedores:[],
      usuariosClientes:[],
      activeItem:'Vendedores',
      solicitudes:[],
      notificaciones:[],
      ventanaRegistro:false,
      nuevoBooking:false,
    }
    this.tomarClientes = this.tomarClientes.bind(this);
  }

  UNSAFE_componentWillMount(){
    this.tomarClientes();
  }

  tomarClientes(){
      let self =this;
      let perfiles=[];
      var notificaciones=JSON.parse(localStorage.getItem('notificaciones'));
      self.setState({notificaciones:notificaciones})
      axios.post(Direccion+`/totalUsuarios`,
        {tipoPeticion:'Raw',tipo:'Cliente'})
        .then(res => {
          perfiles=res.data;
          this.setState({usuariosClientes:perfiles,})
            self.tomarUsuarios();
          })
    }

    tomarUsuarios(){
      let self =this;
      let vendedores=[];
      axios.post(Direccion+`/totalUsuarios`,
        {tipoPeticion:'Raw',tipo:'Vendedor'})
        .then(res => {
          vendedores=res.data;
          this.setState({usuariosVendedores:vendedores})
          self.TomarSolicitudes();
          })
    }

    TomarSolicitudes(){
        var solicitudes;
        var self=this;
        console.log();
        axios.post(Direccion+`/tomar-solicitudes`)
          .then(res => {
              solicitudes=res.data;
              self.setState({solicitudes:solicitudes})

            })

      }


handleItemClick = (e, { name }) => this.setState({ activeItem: name })
  CloseForm =()=>{
    this.setState({
       ventanaRegistro:false,
    })
  }
  openForm=()=>{
     this.setState({
       ventanaRegistro:true
     })
   }
render(){
  const { activeItem } = this.state

  return(
    <div className='UsuariosAdmin'>
      <BarraLateral/>
      <div style={{position:'relative',height:'40vh',marginBottom:'3vh'}}>
        <Particles style={{backgroundImage:'url(../imagenes/admin.jpg)', backgroundSize:'cover',backgroundPosition:'center', position:'absolute',height:'500px'}}
             params={{
               "particles": {
                   "number": {
                       "value": 80
                   },
                   "size": {
                       "value": 2
                   }
               },
               "interactivity": {
                   "events": {
                       "onhover": {
                           "enable": true,
                           "mode": "repulse"
                       }
                   }
               }
           }} />
        <div>
        <Header as='h2' icon textAlign='center' style={{position:'relative',color:'white'}}>
            <Icon  style={{color:'white'}}name='users' circular />
            <Header.Content>Usuarios</Header.Content>
        </Header>
        </div>
      </div>

      <div className='contentUsuarios'>
        <div className='grid horizontal'>
          {this.state.nuevoBooking?
            <div className='column siete vertical'>

              <div className='Acciones header'>
                <Fab color='secondary' aria-label="add" variant='extended' style={{margin:'10px'}}   onClick={()=>this.setState({nuevoBooking:!this.state.nuevoBooking})}>
                  <ArrowBack />
                  Regresar
                </Fab>
              </div>
              <FormularioBooking Vendedores={this.state.usuariosVendedores} Clientes={this.state.usuariosClientes} />
            </div>
            :
            <div className='column siete vertical'>
              <div className='Menu header'>
                <Menu pointing secondary >
                    <Menu.Item name='Vendedores' active={activeItem === 'Vendedores'} onClick={this.handleItemClick} />
                    <Menu.Item
                      name='Clientes'
                      active={activeItem === 'Clientes'}
                      onClick={this.handleItemClick}
                    />
                </Menu>
              </div>
              <div className='Acciones header'>
                <Fab color='secondary' aria-label="add" variant='extended' style={{margin:'10px'}}   onClick={()=>this.setState({nuevoBooking:!this.state.nuevoBooking})}>
                  <AddIcon />
                  Nuevo Booking
                </Fab>
                <Fab color="primary" aria-label="add" variant='extended' style={{margin:'10px'}}   onClick={this.openForm}>
                  <AddIcon />
                  Nuevo usuario
                </Fab>
              </div>
              <div style={{width:'100%'}}>
                {activeItem==='Vendedores'?
                  <div style={{width:'100%'}}>
                    {this.state.usuariosVendedores.map((it)=>{
                        return<Items callBackUsuarios={this.tomarClientes} datos={it} tipo={1}/>
                      })
                    }
                  </div>
                  :

                  <div style={{width:'100%'}}>
                    {this.state.usuariosClientes.map((it)=>{
                        return<Items callBackUsuarios={this.tomarClientes} datos={it} tipo={2}/>
                      })
                    }
                  </div>
                }
              </div>
            </div>
          }


          <div className='column tres'>
            <div className='SolicitudesContent'>
              <Header as='h4' icon textAlign='center'>
                  <Header.Content>Solicitudes</Header.Content>
              </Header>
              <div className='solicitudes'>
                {this.state.solicitudes.map((it)=>{
                    return(<React.Fragment><Solicitud getSolicitudes={this.tomarClientes} datos={it} notificaciones={this.state.notificaciones}/><Divider/></React.Fragment>)
                  })
                }
                {this.state.solicitudes.length<1?
                    <React.Fragment><img style={{maxWidth:'100%'}}src='../imagenes/no-notifi.png'/><p style={{width:'60%',marginLeft:'auto',marginRight:'auto'}}>No hay solicitudes nuevas</p></React.Fragment>
                  :<div></div>
                }
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  ) }
}


class Items extends Component{
  constructor(){
    super()
    this.state = {
      open: false,
      dimmer:true,
      bank:[],
      notificaciones:[],
      tipo:'',
      openCatalogo:false,
      openAlert:false,
      AlertMessage:'',
      AlertType:'',
      showComprobanteBancario:false,
      SuscripcionData:{},

    }


  }
  UNSAFE_componentWillMount(){
    this.setState({
      checked: this.props.datos.Catalogo,
    })
    this.tomarCuenta(1,false);
    this.getSuscriptionUser();
  }
  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    this.tomarCuenta(2,nextProps.tipo,nextProps.datos.key);
  }


  tomarCuenta(tiempo,tipoUsuario,keyUsuario){
    let self =this;
    let bank=[];
    var notificaciones=JSON.parse(localStorage.getItem('notificaciones'));
    var valorTipo = tiempo===2?tipoUsuario:1;
    var valorKey = tiempo===2?keyUsuario:this.props.datos.key;
    var tipo=valorTipo===2?'Cliente':'Vendedor';
    axios.post(Direccion+`/tomarBanks`,
      {idUser:valorKey,tipo:tipo})
      .then(res => {
        bank=res.data;
          self.setState({
            bank:bank,
            notificaciones:notificaciones,
            tipo:tipo,
            checked:self.props.datos.Catalogo==='none'?false:self.props.datos.Catalogo
          })
          this.searchId();
        })
  }

  searchId(){
    var self=this;
    if (this.state.notificaciones) {
      this.state.notificaciones.forEach((it)=>{
        if(it.keyUsuario===self.props.datos.key){
          axios.post(Direccion+`/RefreshNotificacion`,{idNotificacion:it.key,tipo:'Admin'})
            .then(res => {
              var datos=res.data;
              if(datos!='OK'){
                alert('error');
              }
            })
        }
      })
    }

  }

  aceptar(){
    var resp;
    let self =  this;

    axios.post(Direccion+`/actualizarStatusBank`,
      {idUser:this.props.datos.key,valor:'Accepted',tipo:this.state.tipo})
      .then(res => {
        resp=res.data
        if(resp==='OK'){
          self.setState({
            openAlert:true,
            AlertMessage:'updated successfully',
            AlertType:'positive',
          })
          self.props.callBackUsuarios();
        }
        })
  }

  rechazar(){
    var resp
    let self =  this;

    axios.post(Direccion+`/actualizarStatusBank`,
      {idUser:this.props.datos.key,valor:'Rejected',tipo:this.state.tipo})
      .then(res => {
        resp=res.data
        self.setState({
          openAlert:true,
          AlertMessage:'updated successfully',
          AlertType:'positive',
        })
        self.props.callBackUsuarios();
        })
  }
  show = dimmer => () =>{
     this.setState({ dimmer, open: true })

   }

  close = () => this.setState({ open: false })
  Bloquear(valor){
    var resp
    let self =  this;
    axios.post(Direccion+`/bloquearUsuario`,
      {id:this.props.datos.key,valor:valor,tipo:this.state.tipo})
      .then(res => {
        resp=res.data
        if(resp==='OK'){
          self.setState({
            openAlert:true,
            AlertMessage:'updated successfully',
            AlertType:'positive',
          })
          self.props.callBackUsuarios();
        }
        else{
           self.setState({
            openAlert:true,
            AlertMessage:'something went wrong',
            AlertType:'error',
          })
          self.props.callBackUsuarios();
        }
        })
  }

  handleChange=()=> {this.setState({checked:!this.state.checked,openCatalogo:true});};



  handleCloseCatalogo=()=>{ //cerrar y volver switch a estado original
      this.setState({checked:!this.state.checked,openCatalogo:false});
  }

  modificacionDirectorio(){
    let self = this;
    axios.post(Direccion+`/modificarDirectorio`,
      {idUser:this.props.datos.key,valor:this.state.checked})
      .then(res => {
        if(res.data==='OK'){
          this.setState({
            openAlert:true,
            AlertMessage:'updated successfully',
            AlertType:'positive',
            openCatalogo:false,
            open:false,
          })
         console.log(self.props);

          self.props.callBackUsuarios();

        }
        else{
          this.setState({
            openAlert:true,
            AlertMessage:'something went wrong',
            AlertType:'error',
            openCatalogo:false,
            open:false,
          })
          self.props.callBackUsuarios();
        }
        })
  }

   handleCloseAlert=()=>{this.setState({openAlert:false,})}

   getSuscriptionUser=()=>{
     axios.post(Direccion+`/tomarSuscripcion`,
       {idUser:this.props.datos.key})
       .then(res => {
         this.setState({
           SuscripcionData:res.data
         })
       })
   }

  render(){
    var peticionCambioCuenta=this.state.bank.Status==='Pending';
    var datos=this.props.datos;

    var imagen=datos.avatar!='none'?datos.avatar :this.props.tipo===1? '../imagenes/logo-default.png':'../imagenes/usuario.png';
    let descripcion=datos.descripcion;
    var nombre=datos.CompanyName!='none'?datos.CompanyName:datos.FirstName+' '+datos.LastName;
    if(descripcion.length>140){
      descripcion=descripcion.substring(0,139);
      descripcion=descripcion+'...';
    }
    var cuenta=this.state.bank;
    return(
      <div  style={{width:'100%',}}>
        <div className='item' onClick={ this.show(true)}>
          {peticionCambioCuenta ?
              <div className='label ribbon right' >
                Solicitud cambio Cuenta
              </div>
             :null
           }
           {this.props.datos.Catalogo?
              <div className='label ribbon right' >
                Directory ON
              </div>:null
            }
          <div className='imgContent'>
            <img  src={imagen} />
          </div>
          <div className='itemContent'>
            <div className='itemHeader'>{nombre}</div>
            <div className='itemDescripcion'>
              <span className='price'>{datos.Contry}</span><br/>
              <span className='stay'>{datos.Website}</span>
            </div>
            <div>{descripcion}</div>
          </div>
        </div>


        {this.props.datos.bloqueo&&
          <div className='label ribbon right bloqueado' >
           Bloqueado
         </div>
         }
        <Modal style={{width:'58%'}} dimmer={this.state.dimmer} open={this.state.open} onClose={this.close}>
          <Modal.Content image>
            <div style={{textAlign:'center'}}>
            <Image id="ImgCompany" wrapped  size='small' src={imagen} />
            <h4><a target="_blank" href={datos.Website}> {datos.Website}</a></h4>
            </div>
            <div className='InfoCompany'>
              <h2 style={{textAlign: 'center',width:'50%',margin:'0px auto'}}>{nombre}</h2>
              <p style={{fontSize: '14px',textAlign: 'center',width:'50%',margin:'0px auto'}}>{this.props.datos.key}</p><br/>

              <h3 style={{textAlign: 'center',width:'50%',margin:'0px auto'}}>{datos.Contry}</h3>
              <p style={{fontSize: '14px',textAlign: 'center',width:'50%',margin:'0px auto'}}>{this.props.datos.descripcion}</p><br/>

            </div>
          <Rating maxRating={5} defaultRating={3} icon='star' size='huge' />
          </Modal.Content>
            <Divider/>
            <h3 style={{textAlign: 'center',width:'50%',margin:'0px auto'}}>Datos de suscripcion</h3>
            <div className='formData'>
              <div>
                <div className='itemSencillo'>
                  <label>Status</label>
                  <p>{this.state.SuscripcionData.status}</p>
                </div>
                <div className='itemSencillo'>
                  <label>Suscripcion</label>
                  <p>{this.state.SuscripcionData.suscripcionName}</p>
                </div>
              </div>
              <div>
                <div className='itemSencillo'>
                  <label>Fecha Inicio</label>
                  <p>{this.state.SuscripcionData.dateInicio}</p>
                </div>
                <div className='itemSencillo'>
                  <label>Fecha Fin</label>
                  <p>{this.state.SuscripcionData.dateFin}</p>
                </div>
              </div>
            </div>
            <Divider/>
          <Modal.Content  className='InfoCompany'>
            <Header  style={{marginBottom:'0'}}as='h2'>Company Info</Header>

          <Grid id='gridInfo' columns={4} stackable style={{padding:'2vh'}}>
            <Grid.Row verticalAlign='middle' style={{paddingTop:'0'}}>
              <Grid.Column>
              <p>Company Name</p>
              <h4>{this.props.datos.CompanyName}</h4>
              </Grid.Column>
              <Grid.Column>
              <p>State</p>
              <h4>{this.props.datos.State.toUpperCase()}</h4>
              </Grid.Column>
              <Grid.Column>
              <p>City</p>
              <h4>{this.props.datos.City.toUpperCase()}</h4>
              </Grid.Column>
              <Grid.Column>
              <p>Address</p>
              <h4>{this.props.datos.Address.toUpperCase()}</h4>
              </Grid.Column>
              <Grid.Column>
              <p>Postal Code</p>
              <h4>{this.props.datos.PostaleCode.toUpperCase()}</h4>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row verticalAlign='middle'>

              <Grid.Column>
              <p>Establishment year</p>
              <h4>{this.props.datos.EstablishmentYear.toUpperCase()}</h4>
              </Grid.Column>
              <Grid.Column>
              <p>N° Employees</p>
              <h4>{this.props.datos.NoEmployees.toUpperCase()}</h4>
              </Grid.Column>
              <Grid.Column>
              <p>Name owners</p>
              <h4>{this.props.datos.NameOwners.toUpperCase()}</h4>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          </Modal.Content>
          <Divider/>

          <Modal.Content>
            <div className='InfoCompany'>
                <Header  style={{marginBottom:'0'}}as='h2'>Contact Info</Header>
              <Grid id='gridInfo' columns={4} stackable style={{padding:'2vh'}}>

                <Grid.Row >
                  <Grid.Column>
                    <p>Name</p>
                    <h4>{this.props.datos.FirstName} {this.props.datos.LastName} </h4>
                  </Grid.Column>
                  <Grid.Column>
                    <p>Title</p>
                    <h4>{this.props.datos.Title}</h4>
                  </Grid.Column>
                  <Grid.Column>
                    <p>Mobil</p>
                    <h4>{this.props.datos.Mobil}</h4>
                  </Grid.Column>
                  <Grid.Column>
                    <p>Email</p>
                    <h4>{this.props.datos.correo}</h4>
                  </Grid.Column>

                </Grid.Row>
                <Grid.Row >
                  <Grid.Column>
                  <p>Phone</p>
                  <h4>{this.props.datos.Phone.toUpperCase()}</h4>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>

            <div className='InfoCompany'>
                <Header  style={{marginBottom:'0'}}as='h2'>Ref contact info</Header>
              <Grid id='gridInfo' columns={4} stackable style={{padding:'2vh'}}>

                <Grid.Row >
                  <Grid.Column>
                    <p>Name</p>
                    <h4>{this.props.datos.ContactRef1}</h4>
                  </Grid.Column>
                  <Grid.Column>
                    <p>Title</p>
                    <h4>{this.props.datos.CompanyRef1}</h4>
                  </Grid.Column>

                  <Grid.Column>
                    <p>Email</p>
                    <h4>{this.props.datos.EmailRef1}</h4>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row >
                  <Grid.Column>
                    <p>Name</p>
                    <h4>{this.props.datos.ContactRef2}</h4>
                  </Grid.Column>
                  <Grid.Column>
                    <p>Title</p>
                    <h4>{this.props.datos.CompanyRef2}</h4>
                  </Grid.Column>

                  <Grid.Column>
                    <p>Email</p>
                    <h4>{this.props.datos.EmailRef2}</h4>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row >
                  <Grid.Column>
                    <p>Name</p>
                    <h4>{this.props.datos.ContactRef3}</h4>
                  </Grid.Column>
                  <Grid.Column>
                    <p>Title</p>
                    <h4>{this.props.datos.CompanyRef3}</h4>
                  </Grid.Column>

                  <Grid.Column>
                    <p>Email</p>
                    <h4>{this.props.datos.EmailRef3}</h4>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>



            <div>
              <Divider/>

                  <Header  style={{marginBottom:'0'}}as='h2'>Banking information</Header>
                  <Label as='a' color='teal' ribbon='right'>
                       {this.state.bank.Status?'Active':'No Active'}
                   </Label>
                  {cuenta ?
                  <div>
                    <ul className="col3" id="rowBank">
                      <li>
                        <Card.Meta style={{color:'#918F8F'}}>Account Beneficiary</Card.Meta>
                        <Card.Description><strong>{this.state.bank.NombreTitular}</strong></Card.Description>
                      </li>
                      <li>
                        <Card.Meta  style={{color:'#918F8F'}}>Country of Origin</Card.Meta>
                        <Card.Description><strong>{this.state.bank.PaisOrigen}</strong></Card.Description>
                      </li>
                      <li>
                        <Card.Meta  style={{color:'#918F8F'}}>Address of Beneficiary</Card.Meta>
                        <Card.Description><strong>{this.state.bank.DireccionTitular}</strong></Card.Description>
                      </li>
                      <div className="clear"></div>
                    </ul>

                    <ul className="col3" id="rowBank">
                      <li>
                        <Card.Meta  style={{color:'#918F8F'}}>SWIFT code</Card.Meta>
                        <Card.Description><strong>{this.state.bank.CodigoSwift}</strong></Card.Description>
                      </li>
                      <li>
                        <Card.Meta  style={{color:'#918F8F'}}>Iban code</Card.Meta>
                        <Card.Description><strong>{this.state.bank.Iban}</strong></Card.Description>
                      </li>
                      <li>
                        <Card.Meta  style={{color:'#918F8F'}}>Routing code</Card.Meta>
                        <Card.Description><strong>{this.state.bank.Routing}</strong></Card.Description>
                      </li>
                      <div className="clear"></div>
                    </ul>
                    <ul className="col3" id="rowBank">
                      <li>
                        <Card.Meta  style={{color:'#918F8F'}}>Bank </Card.Meta>
                        <Card.Description><strong>{this.state.bank.BancoName}</strong></Card.Description>
                      </li>
                      <li>
                      <Card.Meta  style={{color:'#918F8F'}}>Address of Bank</Card.Meta>
                      <Card.Description><strong>{this.state.bank.DireccionBanco}</strong></Card.Description>
                      </li>
                      <div className="clear"></div>
                    </ul>
                      {
                        peticionCambioCuenta ?
                        <div style={{paddingTop:'5vh',}}>
                          <Button content='Ver comprobante' onClick={()=>this.setState({showComprobanteBancario : !this.state.showComprobanteBancario})} color='orange' />
                          <Button content='Accept' onClick={this.aceptar.bind(this)} primary />
                          <Button content='Reject' onClick={this.rechazar.bind(this)}secondary />
                        </div>
                        :
                        <div></div>
                      }
                  </div>
                    :
                    <div>
                     <h4 style={{paddingTop:'2vh'}}>That bank account is not active yet</h4>
                    </div>

                  }
            </div>
          </Modal.Content>
          <Modal.Actions>

          {this.props.tipo ===1?
            <div style={{margin:'0px 30px',display:'block'}}>
             <Switch
                checked={this.state.checked}
                onChange={this.handleChange.bind(this,'checked')}
                value="checked"
                />
              <h4 style={{margin:'0px'}}>Directorio</h4>
            </div>:<div></div>
          }
            <Confirm
                  header='¿Estas seguro de modificar la presencia en directorio?'
                  open={this.state.openCatalogo}
                  onCancel={this.handleCloseCatalogo.bind(this)}
                  onConfirm={this.modificacionDirectorio.bind(this)}
                  size='large'
                />

          {
            !this.props.datos.bloqueo?
            <Fab
                variant="extended"
                size="medium"
                color="secondary"
                aria-label="add"
                onClick={this.Bloquear.bind(this,true)}
              >
                <NavigationIcon />
                Bloquear
              </Fab>
          :

           <Fab
               variant="extended"
               size="medium"
               color="primary"
               aria-label="add"
               onClick={this.Bloquear.bind(this,false)}
             >
               <NavigationIcon />
               Desbloquear
             </Fab>

          }

          </Modal.Actions>
        </Modal>

        <AlertSnack open={this.state.openAlert} handleClose={this.handleCloseAlert} message={this.state.AlertMessage} type={this.state.AlertType}/>
        <Modal open={this.state.showComprobanteBancario} onClose={()=>this.setState({showComprobanteBancario:false})}>
          <Image src={this.state.bank.FotoCuenta}></Image>
          <Modal.Actions>
            <Button content='Accept' onClick={()=>this.setState({showComprobanteBancario:false})} primary />
          </Modal.Actions>
        </Modal>
      </div>
    )
   }
}

class Solicitud extends Component{
  constructor(){
    super()
    this.state = {
      abrir: false,
      dimmer:true,
    }

  }

  componentDidMount(){
    if (this.props.notificaciones) {
       this.props.notificaciones.forEach((it)=>{
        if(it.idTransaccion===this.props.datos.idTransaccion){
          axios.post(Direccion+`/RefreshNotificacion`,{idNotificacion:it.key,tipo:'Admin'})
            .then(res => {
              var datos=res.data;
              if(datos!='OK'){
                alert('error');
              }
            })
        }
      })
    }
  }

  show = dimmer => () =>{
     this.setState({ dimmer, open: true })

   }

  close = () => this.setState({ open: false })

  registrar(){
    let self= this;

    self.setState({
      dimmerActive:true,
    })
    console.log(this.props.datos);
    axios.post(Direccion+`/registro`,
      {mailRegistro:this.props.datos.correo,clave:this.props.datos.clave,tipo:this.props.datos.tipo,CompanyName:this.props.datos.CompanyName,
        FirstName:this.props.datos.FirstName, LastName:this.props.datos.LastName, Title:this.props.datos.Title,  Mobil:this.props.datos.Mobil,
        Email:this.props.datos.Email, Phone:this.props.datos.Phone, Fax:this.props.datos.Fax, Website:this.props.datos.Website,
        EstablishmentYear:this.props.datos.EstablishmentYear, NoEmployees:this.props.datos.NoEmployees, NameOwners:this.props.datos.NameOwners,
        Address:this.props.datos.Address, City:this.props.datos.City, PostaleCode:this.props.datos.PostaleCode, State:this.props.datos.State,
        Contry: this.props.datos.Contry, CompanyRef1:this.props.datos.CompanyRef1,ContactRef1:this.props.datos.ContactRef1,
        EmailRef1:this.props.datos.EmailRef1, CompanyRef2:this.props.datos.CompanyRef2, ContactRef2:this.props.datos.ContactRef2,
        EmailRef2:this.props.datos.EmailRef2, CompanyRef3:this.props.datos.CompanyRef3, ContactRef3:this.props.datos.ContactRef3,
        EmailRef3:this.props.datos.EmailRef3, RelationShip:this.props.datos.RelationShip, Plataforma: this.props.datos.Plataforma, Catalogo:this.props.datos.Directorio,
        checkedPoliticas:this.props.datos.checkedPoliticas, checkedCondiciones:this.props.datos.checkedCondiciones,
        Cuenta:"Free",
      })


      .then(res => {
        if(res.data === "OK"){
          self.ModificarPreregistro();
          this.setState({
            openAlert:true,
            AlertMessage:'updated successfully',
            AlertType:'positive',
            open:false,
          })
        }
        else{
          this.rechazar();
          this.setState({
            openAlert:true,
            AlertMessage:res.data.errorMessage? res.data.errorMessage:'something went wrong',
            AlertType:'error',
            open:false,
          })
          console.log(res.data);
        }
      })
  }
  rechazar(){
    axios.post(Direccion+`/modificar-preregistro`,
      {
        key:this.props.datos.key,tipo:'Aprobacion',aprobado:'Rechazado'
      })


      .then(res => {
        if(res.data === "OK"){
          this.setState({
            openAlert:true,
            AlertMessage:'updated successfully',
            AlertType:'positive',
            open:false,
          })
          this.props.getSolicitudes();
        }
        else{
          this.setState({
            openAlert:true,
            AlertMessage:'something went wrong',
            AlertType:'error',
            open:false,
          })
          console.log(res.data);
        }
      })
  }
  ModificarPreregistro(){
    axios.post(Direccion+`/modificar-preregistro`,{key:this.props.datos.key,tipo:'Aprobacion',aprobado:'Aprobado'})
      .then(res => {
        if(res.data === "OK"){
          this.setState({
            openAlert:true,
            AlertMessage:'updated successfully',
            AlertType:'positive',
            open:false,
          })
          this.props.getSolicitudes();
        }
        else{
          this.setState({
            openAlert:true,
            AlertMessage:'something went wrong',
            AlertType:'error',
            open:false,
          })
          console.log(res.data);
        }
      })
  }
  render(){
    var datos=this.props.datos;
    var nombre=datos.CompanyName!='none'?datos.CompanyName:datos.FirstName+' '+datos.LastName;
    var numero=Math.round(Math.random()*(3-1)+parseInt(1));
    var imagen='../imagenes/random'+numero+'.png';
    return(
      <React.Fragment style={{position:'relative'}}>
          <Card>
          <Image src={imagen} wrapped ui={false} />
          <Card.Content>
            <Card.Header>{nombre}</Card.Header>
            <Card.Meta>
              <span className='date'>{datos.Contry}</span>
            </Card.Meta>
            <Card.Description>
            {datos.Website}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button primary onClick={this.show(true)}>
                  Ver
            </Button>
          </Card.Content>
        </Card>


      <Modal style={{width:'58%'}}  dimmer={this.state.dimmer} open={this.state.open} onClose={this.close}>
        <Modal.Content image>
        <div style={{textAlign:'center'}}>
        <Image id="ImgCompany" wrapped  size='small' src={imagen} />
        <h4><a target="_blank" href={datos.Website}> {datos.Website}</a></h4>
        </div>
        <div className='InfoCompany'>
                <Header as='h2'>
                  {nombre}
                  <h3>
                  {datos.Contry}
                  </h3>
                </Header>
                <div>
                  <button class="ui primary button"  onClick={this.registrar.bind(this)} style={{marginTop:'9vh',width:'35%',marginLeft:'12%'}}>Accept</button>
                  <button class="ui secondary button" onClick={this.rechazar.bind(this)} style={{marginTop:'9vh',width:'35%'}}>Reject</button>
                </div>
              </div>

        </Modal.Content>
          <Divider/>
        <Modal.Content  className='InfoCompany'>
        <Grid id='gridInfo' columns={4} stackable style={{padding:'2vh'}}>

          <Grid.Row verticalAlign='middle' style={{paddingTop:'0'}}>
            <Grid.Column>
            <p>State</p>
            <h4>{datos.State.toUpperCase()}</h4>
            </Grid.Column>
            <Grid.Column>
            <p>City</p>
            <h4>{datos.City.toUpperCase()}</h4>
            </Grid.Column>
            <Grid.Column>
            <p>Address</p>
            <h4>{datos.Address.toUpperCase()}</h4>
            </Grid.Column>
            <Grid.Column>
            <p>Postal Code</p>
            <h4>{datos.PostaleCode.toUpperCase()}</h4>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row verticalAlign='middle'>
            <Grid.Column>
            <p>Phone</p>
            <h4>{datos.Phone.toUpperCase()}</h4>
            </Grid.Column>
            <Grid.Column>
            <p>Establishment year</p>
            <h4>{datos.EstablishmentYear.toUpperCase()}</h4>
            </Grid.Column>
            <Grid.Column>
            <p>N° Employees</p>
            <h4>{datos.NoEmployees.toUpperCase()}</h4>
            </Grid.Column>
            <Grid.Column>
            <p>Name owners</p>
            <h4>{datos.NameOwners.toUpperCase()}</h4>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </Modal.Content>
        <Divider/>

        <Modal.Content>
        <div className='InfoCompany'>
                <Header  style={{marginBottom:'0'}}as='h2'>Contact Info</Header>
              <Grid id='gridInfo' columns={4} stackable style={{padding:'2vh'}}>

                      <Grid.Row >
                        <Grid.Column>
                          <p>Name</p>
                          <h4>{datos.FirstName} {datos.LastName} </h4>
                        </Grid.Column>
                        <Grid.Column>
                          <p>Title</p>
                          <h4>{datos.Title}</h4>
                        </Grid.Column>
                        <Grid.Column>
                          <p>Mobil</p>
                          <h4>{datos.Mobil}</h4>
                        </Grid.Column>
                        <Grid.Column>
                          <p>Email</p>
                          <h4>{datos.correo}</h4>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row >
                        <Grid.Column>
                          <p>Name</p>
                          <h4>{datos.ContactRef1}</h4>
                        </Grid.Column>
                        <Grid.Column>
                          <p>Title</p>
                          <h4>{datos.CompanyRef1}</h4>
                        </Grid.Column>

                        <Grid.Column>
                          <p>Email</p>
                          <h4>{datos.EmailRef1}</h4>
                        </Grid.Column>
                      </Grid.Row>
                      </Grid>
                  </div>
        </Modal.Content>
      </Modal>
      <AlertSnack open={this.state.openAlert} handleClose={this.handleCloseAlert} message={this.state.AlertMessage} type={this.state.AlertType}/>

      </React.Fragment>
    )
  }
}

class Registro extends Component{
  constructor(){
    super()
    this.state = {
      tipo:'',
      etapa:1,
      email:'',
      password:'',
      suscripcion:'',
      paquetesName:opcionesPaquetes

    }

  }



  show = dimmer => () =>{
     this.setState({ dimmer, open: true })

   }

   TipoRegistro =(param,event)=>{
     this.setState({
       tipo:param
     })
   }
   SiguienteEtapa(){
     this.setState({
       etapa:2
     })
   }

   handleChangePoliticas =()=>{
     this.setState({ checkedPoliticas:!this.state.checkedPoliticas })
   }
   handleChangeCondiciones =()=>{
     this.setState({ checkedCondiciones:!this.state.checkedCondiciones })
   }
   close=()=>{
     this.setState({
       etapa:1
     })
     this.props.close();
   }

      handleRefCompanyName=(e)=>{
        var valor=e.target.value;
        this.setState({
          CompanyName:valor
        })
      }
      handleRefFirstName=(e)=>{
        var valor=e.target.value;
        this.setState({
          FirstName:valor
        })
      }
      handleRefLastName=(e)=>{
        var valor=e.target.value;
        this.setState({
          LastName:valor
        })
      }
      handleRefTitle=(e)=>{
        var valor=e.target.value;
        this.setState({
          Title:valor
        })
      }
      handleRefMobil=(e)=>{
        var valor=e.target.value;
        this.setState({
          Mobil:valor
        })
      }
      handleRefEmail=(e)=>{
        var valor=e.target.value;
        this.setState({
          Email:valor
        })
      }
      handleRefPhone=(e)=>{
        var valor=e.target.value;
        this.setState({
          Phone:valor
        })
      }
      handleRefFax=(e)=>{
        var valor=e.target.value;
        this.setState({
          Fax:valor
        })
      }
      handleRefWebsite=(e)=>{
        var valor=e.target.value;
        this.setState({
          Website:valor
        })
      }
      handleRefEstablishmentYear=(e)=>{
        var valor=e.target.value;
        this.setState({
          EstablishmentYear:valor
        })
      }
      handleRefNoEmployees=(e)=>{
        var valor=e.target.value;
        this.setState({
          NoEmployees:valor
        })
      }
      handleRefNameOwners=(e)=>{
        var valor=e.target.value;
        this.setState({
          NameOwners:valor
        })
      }
      handleRefAddress=(e)=>{
        var valor=e.target.value;
        this.setState({
          Address:valor
        })
      }
      handleRefCity=(e)=>{
        var valor=e.target.value;
        this.setState({
          City:valor
        })
      }
      handleRefPostaleCode=(e)=>{
        var valor=e.target.value;
        this.setState({
          PostaleCode:valor
        })
      }
      handleRefState=(e)=>{
        var valor=e.target.value;
        this.setState({
          State:valor
        })
      }
      handleRefContry=(e)=>{
        var valor=e.target.value;
        this.setState({
          Contry:valor
        })
      }

      handleRefCompanyRef1=(e)=>{
        var valor=e.target.value;
        this.setState({
          CompanyRef1:valor
        })
      }
      handleRefContactRef1=(e)=>{
        var valor=e.target.value;
        this.setState({
          ContactRef1:valor
        })
      }
      handleRefEmailRef1=(e)=>{
        var valor=e.target.value;
        this.setState({
          EmailRef1:valor
        })
      }

      handleRefCompanyRef2=(e)=>{
        var valor=e.target.value;
        this.setState({
          CompanyRef2:valor
        })
      }
      handleRefContactRef2=(e)=>{
        var valor=e.target.value;
        this.setState({
          ContactRef2:valor
        })
      }
      handleRefEmailRef2=(e)=>{
        var valor=e.target.value;
        this.setState({
          EmailRef2:valor
        })
      }

      handleRefCompanyRef3=(e)=>{
        var valor=e.target.value;
        this.setState({
          CompanyRef3:valor
        })
      }
      handleRefContactRef3=(e)=>{
        var valor=e.target.value;
        this.setState({
          ContactRef3:valor
        })
      }
      handleRefEmailRef3=(e)=>{
        var valor=e.target.value;
        this.setState({
          EmailRef3:valor
        })
      }
      user =(event) =>{
        this.setState({
          email:event.target.value
        })
      }
      pass =(event) =>{
        this.setState({
          password:event.target.value
        })
      }

  registrar(){
    let self= this;
    axios.post(Direccion+`/registro`,
      {mailRegistro:this.state.email,clave:this.state.password,tipo:this.state.tipo,CompanyName:this.state.CompanyName,
        FirstName:this.state.FirstName, LastName:this.state.LastName, Title:this.state.Title,  Mobil:this.state.Mobil,
        Email:this.state.Email, Phone:this.state.Phone, Fax:this.state.Fax, Website:this.state.Website,
        EstablishmentYear:this.state.EstablishmentYear, NoEmployees:this.state.NoEmployees, NameOwners:this.state.NameOwners,
        Address:this.state.Address, City:this.state.City, PostaleCode:this.state.PostaleCode, State:this.state.State,
        Contry: this.state.Contry, CompanyRef1:this.state.CompanyRef1,ContactRef1:this.state.ContactRef1,
        EmailRef1:this.state.EmailRef1, CompanyRef2:this.state.CompanyRef2, ContactRef2:this.state.ContactRef2,
        EmailRef2:this.state.EmailRef2, CompanyRef3:this.state.CompanyRef3, ContactRef3:this.state.ContactRef3,
        EmailRef3:this.state.EmailRef3, RelationShip:this.state.RelationShip, Plataforma: this.state.Plataforma, Catalogo:this.state.Directorio,
        checkedPoliticas:this.state.checkedPoliticas, checkedCondiciones:this.state.checkedCondiciones,
        Cuenta:"Free",tipoRegistro:'admin',idPaquete:this.state.suscripcion
      })
      .then(res => {
        if(res.data === "OK"){
          alert(res.data);
          window.location.href=DireccionFront+'/admin/usuarios';
        }
        else if(res.data){
          alert(res.data);
        }
        else{
          alert(res.data);
        }
      })
  }
  selectSuscription=(e,{value,key})=>{
    console.log(e);
    console.log(key);
    this.setState({suscripcion:value},()=>{
    })
  }

  render(){
    let text=Idioma('ES');
    let etapa=this.state.etapa;
    let tipoRegistro=this.state.tipo;
    let filtro=this.state.tipoUsuario==='Cliente'?'none':'grayscale(100%)';
    let filtro2=this.state.tipoUsuario==='Vendedor'?'none':'grayscale(100%)';
    return(
      <React.Fragment style={{position:'relative'}}>


      <Modal style={{width:'75%'}}  dimmer={this.state.dimmer} open={this.props.open} onClose={this.close}>
        <Modal.Content image>
          <div style={{textAlign:'center'}}>
            {
              etapa===1?
              <React.Fragment style={{margin:'2vh'}}>
                <Grid columns={2}>
                  <Grid.Column width={8}>
                    <label>
                      <span>Email</span>
                      <input type="email"  onChange={this.user} value={this.state.email}/>
                    </label>
                    <label>
                      <span>Password</span>
                      <input type="password" onChange={this.pass} value={this.state.password}/>
                    </label>
                  </Grid.Column>
                  <Grid.Column>
                      <Grid columns={2}>
                        <Grid.Column width={8}>
                          <Card className="cardButton" onClick={this.TipoRegistro.bind(this,'Cliente')} style={{width:'140px', minWidth:'140px', margin:' 0em 2.5em'}}>
                            <Image src='../imagenes/comprador.png' style={{filter: filtro}} />
                            <Card.Content>
                              <Card.Header>CEIF Cargo User</Card.Header>
                            </Card.Content>
                          </Card>
                        </Grid.Column>
                        <Grid.Column >
                          <Card className="cardButton" onClick={this.TipoRegistro.bind(this,'Vendedor')} style={{width:'140px', minWidth:'140px', margin:' 0em 2.5em'}}>
                            <Image src='../imagenes/vendedor.png' style={{filter: filtro2}}/>
                            <Card.Content>
                              <Card.Header>CEIF Forwarder</Card.Header>
                            </Card.Content>
                          </Card>
                        </Grid.Column>
                      </Grid>
                  </Grid.Column>
                </Grid>
                <Button  style={{width:'100%',margin:'4vh'}} primary onClick={this.SiguienteEtapa.bind(this)} disabled={!this.state.tipo||!this.state.password||!this.state.email}>Siguiente</Button>
            </React.Fragment>
            :
            <React.Fragment>
              {
                this.state.tipo==='Vendedor'?
              <div>
                <Dimmer id="DimmerRegistro" active={this.state.dimmerActive}>
                 <Loader size='large'>Upload data</Loader>
                 </Dimmer>


                <div>
                <div >
                  <h2>Before Login, please tell us more about you</h2>
                <div className="FormInputs">
                <Form id="FormRegistro" >
                  <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
                    <Form.Input  onChange={this.handleRefCompanyName} value={this.state.CompanyName}  label={text.registro.RegistroForm.CompanyName}  placeholder="" />
                    <Form.Input  onChange={this.handleRefFirstName} value={this.state.FirstName}  label={text.registro.RegistroForm.FirstName}  placeholder="" />
                    <Form.Input  onChange={this.handleRefLastName} value={this.state.LastName}  label={text.registro.RegistroForm.LastName}  placeholder="" />
                  </Form.Group>
                  <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
                    <Form.Input  onChange={this.handleRefTitle} value={this.state.Title}  label={text.registro.RegistroForm.Title}  placeholder="" />
                    <Form.Input  onChange={this.handleRefMobil} value={this.state.Mobil}  label={text.registro.RegistroForm.Mobil}  placeholder="" />
                    <Form.Input  value={this.state.email}  label={text.registro.RegistroForm.Email} disable placeholder="" />
                  </Form.Group>
                  <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
                    <Form.Input  onChange={this.handleRefPhone} value={this.state.Phone}  label={text.registro.RegistroForm.Phone}  placeholder="" />
                    <Form.Input  onChange={this.handleRefFax} value={this.state.Fax}  label={text.registro.RegistroForm.Fax}  placeholder="" />
                    <Form.Input  onChange={this.handleRefWebsite} value={this.state.Website}  label={text.registro.RegistroForm.Website}  placeholder="" />
                  </Form.Group>
                  <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
                    <Form.Input  onChange={this.handleRefEstablishmentYear} value={this.state.EstablishmentYear}  label={text.registro.RegistroForm.EstablishmentYear}  placeholder="" />
                    <Form.Input  onChange={this.handleRefNoEmployees} value={this.state.NoEmployees}  label={text.registro.RegistroForm.NoEmployees}  placeholder="" />
                    <Form.Input  onChange={this.handleRefNameOwners} value={this.state.NameOwners}  label={text.registro.RegistroForm.NameOwners}  placeholder="" />
                  </Form.Group>
                  <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
                    <Form.Input  onChange={this.handleRefAddress} value={this.state.Address}  label={text.registro.RegistroForm.Address}  placeholder="" />
                    <Form.Input  onChange={this.handleRefCity} value={this.state.City}  label={text.registro.RegistroForm.City}  placeholder="" />
                    <Form.Input  onChange={this.handleRefPostaleCode} value={this.state.PostaleCode}  label={text.registro.RegistroForm.PostaleCode}  placeholder="" />
                  </Form.Group>
                  <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
                    <Form.Input  onChange={this.handleRefState} value={this.state.State}  label={text.registro.RegistroForm.State}  placeholder="" />
                    <div className='field'>
                      <label>Country</label>
                      <div className='ui fluid input'>
                        <select value={this.state.Contry} onChange={this.handleRefContry} className='selectCountry'>
                          <option value="AF">Afghanistan</option>
                          <option value="AX">Åland Islands</option>
                          <option value="AL">Albania</option>
                          <option value="DZ">Algeria</option>
                          <option value="AS">American Samoa</option>
                          <option value="AD">Andorra</option>
                          <option value="AO">Angola</option>
                          <option value="AI">Anguilla</option>
                          <option value="AQ">Antarctica</option>
                          <option value="AG">Antigua and Barbuda</option>
                          <option value="AR">Argentina</option>
                          <option value="AM">Armenia</option>
                          <option value="AW">Aruba</option>
                          <option value="AU">Australia</option>
                          <option value="AT">Austria</option>
                          <option value="AZ">Azerbaijan</option>
                          <option value="BS">Bahamas</option>
                          <option value="BH">Bahrain</option>
                          <option value="BD">Bangladesh</option>
                          <option value="BB">Barbados</option>
                          <option value="BY">Belarus</option>
                          <option value="BE">Belgium</option>
                          <option value="BZ">Belize</option>
                          <option value="BJ">Benin</option>
                          <option value="BM">Bermuda</option>
                          <option value="BT">Bhutan</option>
                          <option value="BO">Bolivia, Plurinational State of</option>
                          <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                          <option value="BA">Bosnia and Herzegovina</option>
                          <option value="BW">Botswana</option>
                          <option value="BV">Bouvet Island</option>
                          <option value="BR">Brazil</option>
                          <option value="IO">British Indian Ocean Territory</option>
                          <option value="BN">Brunei Darussalam</option>
                          <option value="BG">Bulgaria</option>
                          <option value="BF">Burkina Faso</option>
                          <option value="BI">Burundi</option>
                          <option value="KH">Cambodia</option>
                          <option value="CM">Cameroon</option>
                          <option value="CA">Canada</option>
                          <option value="CV">Cape Verde</option>
                          <option value="KY">Cayman Islands</option>
                          <option value="CF">Central African Republic</option>
                          <option value="TD">Chad</option>
                          <option value="CL">Chile</option>
                          <option value="CN">China</option>
                          <option value="CX">Christmas Island</option>
                          <option value="CC">Cocos (Keeling) Islands</option>
                          <option value="CO">Colombia</option>
                          <option value="KM">Comoros</option>
                          <option value="CG">Congo</option>
                          <option value="CD">Congo, the Democratic Republic of the</option>
                          <option value="CK">Cook Islands</option>
                          <option value="CR">Costa Rica</option>
                          <option value="CI">Côte d'Ivoire</option>
                          <option value="HR">Croatia</option>
                          <option value="CU">Cuba</option>
                          <option value="CW">Curaçao</option>
                          <option value="CY">Cyprus</option>
                          <option value="CZ">Czech Republic</option>
                          <option value="DK">Denmark</option>
                          <option value="DJ">Djibouti</option>
                          <option value="DM">Dominica</option>
                          <option value="DO">Dominican Republic</option>
                          <option value="EC">Ecuador</option>
                          <option value="EG">Egypt</option>
                          <option value="SV">El Salvador</option>
                          <option value="GQ">Equatorial Guinea</option>
                          <option value="ER">Eritrea</option>
                          <option value="EE">Estonia</option>
                          <option value="ET">Ethiopia</option>
                          <option value="FK">Falkland Islands (Malvinas)</option>
                          <option value="FO">Faroe Islands</option>
                          <option value="FJ">Fiji</option>
                          <option value="FI">Finland</option>
                          <option value="FR">France</option>
                          <option value="GF">French Guiana</option>
                          <option value="PF">French Polynesia</option>
                          <option value="TF">French Southern Territories</option>
                          <option value="GA">Gabon</option>
                          <option value="GM">Gambia</option>
                          <option value="GE">Georgia</option>
                          <option value="DE">Germany</option>
                          <option value="GH">Ghana</option>
                          <option value="GI">Gibraltar</option>
                          <option value="GR">Greece</option>
                          <option value="GL">Greenland</option>
                          <option value="GD">Grenada</option>
                          <option value="GP">Guadeloupe</option>
                          <option value="GU">Guam</option>
                          <option value="GT">Guatemala</option>
                          <option value="GG">Guernsey</option>
                          <option value="GN">Guinea</option>
                          <option value="GW">Guinea-Bissau</option>
                          <option value="GY">Guyana</option>
                          <option value="HT">Haiti</option>
                          <option value="HM">Heard Island and McDonald Islands</option>
                          <option value="VA">Holy See (Vatican City State)</option>
                          <option value="HN">Honduras</option>
                          <option value="HK">Hong Kong</option>
                          <option value="HU">Hungary</option>
                          <option value="IS">Iceland</option>
                          <option value="IN">India</option>
                          <option value="ID">Indonesia</option>
                          <option value="IR">Iran, Islamic Republic of</option>
                          <option value="IQ">Iraq</option>
                          <option value="IE">Ireland</option>
                          <option value="IM">Isle of Man</option>
                          <option value="IL">Israel</option>
                          <option value="IT">Italy</option>
                          <option value="JM">Jamaica</option>
                          <option value="JP">Japan</option>
                          <option value="JE">Jersey</option>
                          <option value="JO">Jordan</option>
                          <option value="KZ">Kazakhstan</option>
                          <option value="KE">Kenya</option>
                          <option value="KI">Kiribati</option>
                          <option value="KP">Korea, Democratic People's Republic of</option>
                          <option value="KR">Korea, Republic of</option>
                          <option value="KW">Kuwait</option>
                          <option value="KG">Kyrgyzstan</option>
                          <option value="LA">Lao People's Democratic Republic</option>
                          <option value="LV">Latvia</option>
                          <option value="LB">Lebanon</option>
                          <option value="LS">Lesotho</option>
                          <option value="LR">Liberia</option>
                          <option value="LY">Libya</option>
                          <option value="LI">Liechtenstein</option>
                          <option value="LT">Lithuania</option>
                          <option value="LU">Luxembourg</option>
                          <option value="MO">Macao</option>
                          <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                          <option value="MG">Madagascar</option>
                          <option value="MW">Malawi</option>
                          <option value="MY">Malaysia</option>
                          <option value="MV">Maldives</option>
                          <option value="ML">Mali</option>
                          <option value="MT">Malta</option>
                          <option value="MH">Marshall Islands</option>
                          <option value="MQ">Martinique</option>
                          <option value="MR">Mauritania</option>
                          <option value="MU">Mauritius</option>
                          <option value="YT">Mayotte</option>
                          <option value="MX">Mexico</option>
                          <option value="FM">Micronesia, Federated States of</option>
                          <option value="MD">Moldova, Republic of</option>
                          <option value="MC">Monaco</option>
                          <option value="MN">Mongolia</option>
                          <option value="ME">Montenegro</option>
                          <option value="MS">Montserrat</option>
                          <option value="MA">Morocco</option>
                          <option value="MZ">Mozambique</option>
                          <option value="MM">Myanmar</option>
                          <option value="NA">Namibia</option>
                          <option value="NR">Nauru</option>
                          <option value="NP">Nepal</option>
                          <option value="NL">Netherlands</option>
                          <option value="NC">New Caledonia</option>
                          <option value="NZ">New Zealand</option>
                          <option value="NI">Nicaragua</option>
                          <option value="NE">Niger</option>
                          <option value="NG">Nigeria</option>
                          <option value="NU">Niue</option>
                          <option value="NF">Norfolk Island</option>
                          <option value="MP">Northern Mariana Islands</option>
                          <option value="NO">Norway</option>
                          <option value="OM">Oman</option>
                          <option value="PK">Pakistan</option>
                          <option value="PW">Palau</option>
                          <option value="PS">Palestinian Territory, Occupied</option>
                          <option value="PA">Panama</option>
                          <option value="PG">Papua New Guinea</option>
                          <option value="PY">Paraguay</option>
                          <option value="PE">Peru</option>
                          <option value="PH">Philippines</option>
                          <option value="PN">Pitcairn</option>
                          <option value="PL">Poland</option>
                          <option value="PT">Portugal</option>
                          <option value="PR">Puerto Rico</option>
                          <option value="QA">Qatar</option>
                          <option value="RE">Réunion</option>
                          <option value="RO">Romania</option>
                          <option value="RU">Russian Federation</option>
                          <option value="RW">Rwanda</option>
                          <option value="BL">Saint Barthélemy</option>
                          <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                          <option value="KN">Saint Kitts and Nevis</option>
                          <option value="LC">Saint Lucia</option>
                          <option value="MF">Saint Martin (French part)</option>
                          <option value="PM">Saint Pierre and Miquelon</option>
                          <option value="VC">Saint Vincent and the Grenadines</option>
                          <option value="WS">Samoa</option>
                          <option value="SM">San Marino</option>
                          <option value="ST">Sao Tome and Principe</option>
                          <option value="SA">Saudi Arabia</option>
                          <option value="SN">Senegal</option>
                          <option value="RS">Serbia</option>
                          <option value="SC">Seychelles</option>
                          <option value="SL">Sierra Leone</option>
                          <option value="SG">Singapore</option>
                          <option value="SX">Sint Maarten (Dutch part)</option>
                          <option value="SK">Slovakia</option>
                          <option value="SI">Slovenia</option>
                          <option value="SB">Solomon Islands</option>
                          <option value="SO">Somalia</option>
                          <option value="ZA">South Africa</option>
                          <option value="GS">South Georgia and the South Sandwich Islands</option>
                          <option value="SS">South Sudan</option>
                          <option value="ES">Spain</option>
                          <option value="LK">Sri Lanka</option>
                          <option value="SD">Sudan</option>
                          <option value="SR">Suriname</option>
                          <option value="SJ">Svalbard and Jan Mayen</option>
                          <option value="SZ">Swaziland</option>
                          <option value="SE">Sweden</option>
                          <option value="CH">Switzerland</option>
                          <option value="SY">Syrian Arab Republic</option>
                          <option value="TW">Taiwan, Province of China</option>
                          <option value="TJ">Tajikistan</option>
                          <option value="TZ">Tanzania, United Republic of</option>
                          <option value="TH">Thailand</option>
                          <option value="TL">Timor-Leste</option>
                          <option value="TG">Togo</option>
                          <option value="TK">Tokelau</option>
                          <option value="TO">Tonga</option>
                          <option value="TT">Trinidad and Tobago</option>
                          <option value="TN">Tunisia</option>
                          <option value="TR">Turkey</option>
                          <option value="TM">Turkmenistan</option>
                          <option value="TC">Turks and Caicos Islands</option>
                          <option value="TV">Tuvalu</option>
                          <option value="UG">Uganda</option>
                          <option value="UA">Ukraine</option>
                          <option value="AE">United Arab Emirates</option>
                          <option value="GB">United Kingdom</option>
                          <option value="US">United States</option>
                          <option value="UM">United States Minor Outlying Islands</option>
                          <option value="UY">Uruguay</option>
                          <option value="UZ">Uzbekistan</option>
                          <option value="VU">Vanuatu</option>
                          <option value="VE">Venezuela, Bolivarian Republic of</option>
                          <option value="VN">Viet Nam</option>
                          <option value="VG">Virgin Islands, British</option>
                          <option value="VI">Virgin Islands, U.S.</option>
                          <option value="WF">Wallis and Futuna</option>
                          <option value="EH">Western Sahara</option>
                          <option value="YE">Yemen</option>
                          <option value="ZM">Zambia</option>
                          <option value="ZW">Zimbabwe</option>
                        </select>
                      </div>
                    </div>

                    <Form.Input  onChange={this.handleRefCompanyRef1} value={this.state.CompanyRef1}  label={text.registro.RegistroForm.CompanyRef1}  placeholder="" />
                  </Form.Group>
                  <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
                    <Form.Input  onChange={this.handleRefContactRef1} value={this.state.ContactRef1}  label={text.registro.RegistroForm.ContactRef1}  placeholder="" />
                    <Form.Input  onChange={this.handleRefEmailRef1} value={this.state.EmailRef1}  label={text.registro.RegistroForm.EmailRef1}  placeholder="" />
                    <Form.Input  onChange={this.handleRefCompanyRef2} value={this.state.CompanyRef2}  label={text.registro.RegistroForm.CompanyRef2}  placeholder="" />
                  </Form.Group>
                  <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
                    <Form.Input  onChange={this.handleRefContactRef2} value={this.state.ContactRef2}  label={text.registro.RegistroForm.ContactRef2}  placeholder="" />
                    <Form.Input  onChange={this.handleRefEmailRef2} value={this.state.EmailRef2}  label={text.registro.RegistroForm.EmailRef2}  placeholder="" />
                    <Form.Input  onChange={this.handleRefCompanyRef3} value={this.state.CompanyRef3}  label={text.registro.RegistroForm.CompanyRef3}  placeholder="" />
                  </Form.Group>
                  <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
                    <Form.Input  onChange={this.handleRefContactRef3} value={this.state.ContactRef3}  label={text.registro.RegistroForm.ContactRef3}  placeholder="" />
                    <Form.Input  onChange={this.handleRefEmailRef3} value={this.state.EmailRef3}  label={text.registro.RegistroForm.EmailRef3}  placeholder="" />
                  </Form.Group>

                  </Form>
                  </div>

                </div>
                <div style={{marginLeft:'30%'}}>
                <div style={{display:'inline-flex',marginTop:'30px',marginBottom:'20px'}} >
                  <Checkbox
                    checked={this.state.checkedCondiciones}
                    onChange={this.handleChangeCondiciones}
                    value="Plataforma"
                    color="primary"
                    style={{width:'10%',marginTop:'-23px'}}

                    />
                    <p style={{display:'inline-flex'}}>  I accept the <a style={{marginRight:'4px',marginLeft:'4px'}}>Terms and Condition </a> of use of CEIF Logistics Network  </p>
                  </div>
                <div style={{display:'inline-flex',marginBottom:'50px'}} >
                  <Checkbox
                  style={{width:'10%',marginTop:'-23px'}}
                    checked={this.state.checkedPoliticas}
                    onChange={this.handleChangePoliticas}
                    value="Plataforma"
                    color="primary"
                    />
                  <p style={{display:'inline-flex'}}>I understand and Accept the<a style={{marginRight:'4px',marginLeft:'4px'}}>Terms of the Privacy Policy</a></p>

                </div>
              </div>
                <Modal.Actions>
                <Dropdown
                    placeholder='Select CEIF plan'
                    fluid
                    selection
                    options={this.state.paquetesName}
                    style={{width:'80%'}}
                    onChange={this.selectSuscription}
                    value={this.state.suscripcion}
                  />
                <Button onClick={this.registrar.bind(this)}   positive
                disabled={!this.state.CompanyName||!this.state.checkedPoliticas||!this.state.FirstName||!this.state.LastName||!this.state.Title
                ||!this.state.Mobil||!this.state.Title||!this.state.Phone
                ||!this.state.Fax||!this.state.Website||!this.state.EstablishmentYear||!this.state.NoEmployees
                ||!this.state.NameOwners||!this.state.Address||!this.state.City||!this.state.PostaleCode
                ||!this.state.State||!this.state.Contry||!this.state.CompanyRef1||!this.state.ContactRef1||!this.state.EmailRef1||!this.state.suscripcion
                ||!this.state.CompanyRef2||!this.state.ContactRef2||!this.state.EmailRef2||!this.state.CompanyRef3||!this.state.ContactRef3||!this.state.EmailRef3||!this.state.checkedCondiciones||!this.state.checkedPoliticas}>Sign in</Button>
                <Button  negative onClick={this.close}>Cancel</Button>
                </Modal.Actions>
                </div>
              </div>
                ///////////////////////////////////////////////
                :
                <div>
                <div >
                  <h2>Before Login, please tell us more about you</h2>
                <div className="FormInputsCliente">
                <Form id="FormRegistro" >
                  <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
                    <Form.Input  onChange={this.handleRefCompanyName} value={this.state.CompanyName}  label={text.registro.RegistroForm.CompanyName}  placeholder="" />
                    <Form.Input  onChange={this.handleRefFirstName} value={this.state.FirstName}  label={text.registro.RegistroForm.FirstName}  placeholder="" />
                    <Form.Input  onChange={this.handleRefLastName} value={this.state.LastName}  label={text.registro.RegistroForm.LastName}  placeholder="" />
                  </Form.Group>
                  <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
                    <Form.Input  onChange={this.handleRefMobil} value={this.state.Mobil}  label={text.registro.RegistroForm.Mobil}  placeholder="" />
                    <Form.Input  onChange={this.handleRefPostaleCode} value={this.state.PostaleCode}  label={text.registro.RegistroForm.PostaleCode}  placeholder="" />
                    <Form.Input  onChange={this.handleRefAddress} value={this.state.Address}  label={text.registro.RegistroForm.Address}  placeholder="" />
                  </Form.Group>

                  <Form.Group widths='equal' style={{paddingTop:'4vh'}} style={{width:'100%'}}>
                    <Form.Input  onChange={this.handleRefCity} value={this.state.City}  label={text.registro.RegistroForm.City}  placeholder="" />
                    <Form.Input  onChange={this.handleRefState} value={this.state.State}  label={text.registro.RegistroForm.State}  placeholder="" />
                    <div className='field' >
                      <label>Country</label>
                      <div className='ui fluid input'>
                        <select value={this.state.Contry} onChange={this.handleRefContry} className='selectCountry'>
                          <option value="AF">Afghanistan</option>
                          <option value="AX">Åland Islands</option>
                          <option value="AL">Albania</option>
                          <option value="DZ">Algeria</option>
                          <option value="AS">American Samoa</option>
                          <option value="AD">Andorra</option>
                          <option value="AO">Angola</option>
                          <option value="AI">Anguilla</option>
                          <option value="AQ">Antarctica</option>
                          <option value="AG">Antigua and Barbuda</option>
                          <option value="AR">Argentina</option>
                          <option value="AM">Armenia</option>
                          <option value="AW">Aruba</option>
                          <option value="AU">Australia</option>
                          <option value="AT">Austria</option>
                          <option value="AZ">Azerbaijan</option>
                          <option value="BS">Bahamas</option>
                          <option value="BH">Bahrain</option>
                          <option value="BD">Bangladesh</option>
                          <option value="BB">Barbados</option>
                          <option value="BY">Belarus</option>
                          <option value="BE">Belgium</option>
                          <option value="BZ">Belize</option>
                          <option value="BJ">Benin</option>
                          <option value="BM">Bermuda</option>
                          <option value="BT">Bhutan</option>
                          <option value="BO">Bolivia, Plurinational State of</option>
                          <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                          <option value="BA">Bosnia and Herzegovina</option>
                          <option value="BW">Botswana</option>
                          <option value="BV">Bouvet Island</option>
                          <option value="BR">Brazil</option>
                          <option value="IO">British Indian Ocean Territory</option>
                          <option value="BN">Brunei Darussalam</option>
                          <option value="BG">Bulgaria</option>
                          <option value="BF">Burkina Faso</option>
                          <option value="BI">Burundi</option>
                          <option value="KH">Cambodia</option>
                          <option value="CM">Cameroon</option>
                          <option value="CA">Canada</option>
                          <option value="CV">Cape Verde</option>
                          <option value="KY">Cayman Islands</option>
                          <option value="CF">Central African Republic</option>
                          <option value="TD">Chad</option>
                          <option value="CL">Chile</option>
                          <option value="CN">China</option>
                          <option value="CX">Christmas Island</option>
                          <option value="CC">Cocos (Keeling) Islands</option>
                          <option value="CO">Colombia</option>
                          <option value="KM">Comoros</option>
                          <option value="CG">Congo</option>
                          <option value="CD">Congo, the Democratic Republic of the</option>
                          <option value="CK">Cook Islands</option>
                          <option value="CR">Costa Rica</option>
                          <option value="CI">Côte d'Ivoire</option>
                          <option value="HR">Croatia</option>
                          <option value="CU">Cuba</option>
                          <option value="CW">Curaçao</option>
                          <option value="CY">Cyprus</option>
                          <option value="CZ">Czech Republic</option>
                          <option value="DK">Denmark</option>
                          <option value="DJ">Djibouti</option>
                          <option value="DM">Dominica</option>
                          <option value="DO">Dominican Republic</option>
                          <option value="EC">Ecuador</option>
                          <option value="EG">Egypt</option>
                          <option value="SV">El Salvador</option>
                          <option value="GQ">Equatorial Guinea</option>
                          <option value="ER">Eritrea</option>
                          <option value="EE">Estonia</option>
                          <option value="ET">Ethiopia</option>
                          <option value="FK">Falkland Islands (Malvinas)</option>
                          <option value="FO">Faroe Islands</option>
                          <option value="FJ">Fiji</option>
                          <option value="FI">Finland</option>
                          <option value="FR">France</option>
                          <option value="GF">French Guiana</option>
                          <option value="PF">French Polynesia</option>
                          <option value="TF">French Southern Territories</option>
                          <option value="GA">Gabon</option>
                          <option value="GM">Gambia</option>
                          <option value="GE">Georgia</option>
                          <option value="DE">Germany</option>
                          <option value="GH">Ghana</option>
                          <option value="GI">Gibraltar</option>
                          <option value="GR">Greece</option>
                          <option value="GL">Greenland</option>
                          <option value="GD">Grenada</option>
                          <option value="GP">Guadeloupe</option>
                          <option value="GU">Guam</option>
                          <option value="GT">Guatemala</option>
                          <option value="GG">Guernsey</option>
                          <option value="GN">Guinea</option>
                          <option value="GW">Guinea-Bissau</option>
                          <option value="GY">Guyana</option>
                          <option value="HT">Haiti</option>
                          <option value="HM">Heard Island and McDonald Islands</option>
                          <option value="VA">Holy See (Vatican City State)</option>
                          <option value="HN">Honduras</option>
                          <option value="HK">Hong Kong</option>
                          <option value="HU">Hungary</option>
                          <option value="IS">Iceland</option>
                          <option value="IN">India</option>
                          <option value="ID">Indonesia</option>
                          <option value="IR">Iran, Islamic Republic of</option>
                          <option value="IQ">Iraq</option>
                          <option value="IE">Ireland</option>
                          <option value="IM">Isle of Man</option>
                          <option value="IL">Israel</option>
                          <option value="IT">Italy</option>
                          <option value="JM">Jamaica</option>
                          <option value="JP">Japan</option>
                          <option value="JE">Jersey</option>
                          <option value="JO">Jordan</option>
                          <option value="KZ">Kazakhstan</option>
                          <option value="KE">Kenya</option>
                          <option value="KI">Kiribati</option>
                          <option value="KP">Korea, Democratic People's Republic of</option>
                          <option value="KR">Korea, Republic of</option>
                          <option value="KW">Kuwait</option>
                          <option value="KG">Kyrgyzstan</option>
                          <option value="LA">Lao People's Democratic Republic</option>
                          <option value="LV">Latvia</option>
                          <option value="LB">Lebanon</option>
                          <option value="LS">Lesotho</option>
                          <option value="LR">Liberia</option>
                          <option value="LY">Libya</option>
                          <option value="LI">Liechtenstein</option>
                          <option value="LT">Lithuania</option>
                          <option value="LU">Luxembourg</option>
                          <option value="MO">Macao</option>
                          <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                          <option value="MG">Madagascar</option>
                          <option value="MW">Malawi</option>
                          <option value="MY">Malaysia</option>
                          <option value="MV">Maldives</option>
                          <option value="ML">Mali</option>
                          <option value="MT">Malta</option>
                          <option value="MH">Marshall Islands</option>
                          <option value="MQ">Martinique</option>
                          <option value="MR">Mauritania</option>
                          <option value="MU">Mauritius</option>
                          <option value="YT">Mayotte</option>
                          <option value="MX">Mexico</option>
                          <option value="FM">Micronesia, Federated States of</option>
                          <option value="MD">Moldova, Republic of</option>
                          <option value="MC">Monaco</option>
                          <option value="MN">Mongolia</option>
                          <option value="ME">Montenegro</option>
                          <option value="MS">Montserrat</option>
                          <option value="MA">Morocco</option>
                          <option value="MZ">Mozambique</option>
                          <option value="MM">Myanmar</option>
                          <option value="NA">Namibia</option>
                          <option value="NR">Nauru</option>
                          <option value="NP">Nepal</option>
                          <option value="NL">Netherlands</option>
                          <option value="NC">New Caledonia</option>
                          <option value="NZ">New Zealand</option>
                          <option value="NI">Nicaragua</option>
                          <option value="NE">Niger</option>
                          <option value="NG">Nigeria</option>
                          <option value="NU">Niue</option>
                          <option value="NF">Norfolk Island</option>
                          <option value="MP">Northern Mariana Islands</option>
                          <option value="NO">Norway</option>
                          <option value="OM">Oman</option>
                          <option value="PK">Pakistan</option>
                          <option value="PW">Palau</option>
                          <option value="PS">Palestinian Territory, Occupied</option>
                          <option value="PA">Panama</option>
                          <option value="PG">Papua New Guinea</option>
                          <option value="PY">Paraguay</option>
                          <option value="PE">Peru</option>
                          <option value="PH">Philippines</option>
                          <option value="PN">Pitcairn</option>
                          <option value="PL">Poland</option>
                          <option value="PT">Portugal</option>
                          <option value="PR">Puerto Rico</option>
                          <option value="QA">Qatar</option>
                          <option value="RE">Réunion</option>
                          <option value="RO">Romania</option>
                          <option value="RU">Russian Federation</option>
                          <option value="RW">Rwanda</option>
                          <option value="BL">Saint Barthélemy</option>
                          <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                          <option value="KN">Saint Kitts and Nevis</option>
                          <option value="LC">Saint Lucia</option>
                          <option value="MF">Saint Martin (French part)</option>
                          <option value="PM">Saint Pierre and Miquelon</option>
                          <option value="VC">Saint Vincent and the Grenadines</option>
                          <option value="WS">Samoa</option>
                          <option value="SM">San Marino</option>
                          <option value="ST">Sao Tome and Principe</option>
                          <option value="SA">Saudi Arabia</option>
                          <option value="SN">Senegal</option>
                          <option value="RS">Serbia</option>
                          <option value="SC">Seychelles</option>
                          <option value="SL">Sierra Leone</option>
                          <option value="SG">Singapore</option>
                          <option value="SX">Sint Maarten (Dutch part)</option>
                          <option value="SK">Slovakia</option>
                          <option value="SI">Slovenia</option>
                          <option value="SB">Solomon Islands</option>
                          <option value="SO">Somalia</option>
                          <option value="ZA">South Africa</option>
                          <option value="GS">South Georgia and the South Sandwich Islands</option>
                          <option value="SS">South Sudan</option>
                          <option value="ES">Spain</option>
                          <option value="LK">Sri Lanka</option>
                          <option value="SD">Sudan</option>
                          <option value="SR">Suriname</option>
                          <option value="SJ">Svalbard and Jan Mayen</option>
                          <option value="SZ">Swaziland</option>
                          <option value="SE">Sweden</option>
                          <option value="CH">Switzerland</option>
                          <option value="SY">Syrian Arab Republic</option>
                          <option value="TW">Taiwan, Province of China</option>
                          <option value="TJ">Tajikistan</option>
                          <option value="TZ">Tanzania, United Republic of</option>
                          <option value="TH">Thailand</option>
                          <option value="TL">Timor-Leste</option>
                          <option value="TG">Togo</option>
                          <option value="TK">Tokelau</option>
                          <option value="TO">Tonga</option>
                          <option value="TT">Trinidad and Tobago</option>
                          <option value="TN">Tunisia</option>
                          <option value="TR">Turkey</option>
                          <option value="TM">Turkmenistan</option>
                          <option value="TC">Turks and Caicos Islands</option>
                          <option value="TV">Tuvalu</option>
                          <option value="UG">Uganda</option>
                          <option value="UA">Ukraine</option>
                          <option value="AE">United Arab Emirates</option>
                          <option value="GB">United Kingdom</option>
                          <option value="US">United States</option>
                          <option value="UM">United States Minor Outlying Islands</option>
                          <option value="UY">Uruguay</option>
                          <option value="UZ">Uzbekistan</option>
                          <option value="VU">Vanuatu</option>
                          <option value="VE">Venezuela, Bolivarian Republic of</option>
                          <option value="VN">Viet Nam</option>
                          <option value="VG">Virgin Islands, British</option>
                          <option value="VI">Virgin Islands, U.S.</option>
                          <option value="WF">Wallis and Futuna</option>
                          <option value="EH">Western Sahara</option>
                          <option value="YE">Yemen</option>
                          <option value="ZM">Zambia</option>
                          <option value="ZW">Zimbabwe</option>
                        </select>
                      </div>
                    </div>
                  </Form.Group>



                  <div>
                  <div style={{display:'inline-flex',marginTop:'30px',marginBottom:'20px'}} >
                    <Checkbox
                      checked={this.state.checkedCondiciones}
                      onChange={this.handleChangeCondiciones}
                      value="Plataforma"
                      color="primary"
                      style={{width:'10%',marginTop:'-23px'}}

                      />
                      <p style={{display:'inline-flex'}}>  I accept the <a style={{marginRight:'4px',marginLeft:'4px'}}>Terms and Condition </a> of use of CEIF Logistics Network  </p>
                    </div>


                  <div style={{display:'inline-flex',marginBottom:'50px'}} >
                    <Checkbox
                    style={{width:'10%',marginTop:'-23px'}}
                      checked={this.state.checkedPoliticas}
                      onChange={this.handleChangePoliticas}
                      value="Plataforma"
                      color="primary"
                      />
                    <p style={{display:'inline-flex'}}>I understand and accept the <a style={{marginRight:'4px',marginLeft:'4px'}}>Terms of the Privacy Policy </a></p>
                  </div>
                </div>

                <div className="btnModalClientes">

                  <Button positive type="button" onClick={this.registrar.bind(this)}className="submit2"
                  disabled={!this.state.FirstName||!this.state.checkedPoliticas||!this.state.CompanyName||!this.state.LastName
                  ||!this.state.Mobil||!this.state.Address||!this.state.City||!this.state.PostaleCode
                  ||!this.state.State||!this.state.Contry||!this.state.checkedCondiciones||!this.state.checkedPoliticas}>Sign In</Button>
                  <Button  negative onClick={this.close}>Cancelar</Button>
                </div>
                  </Form>
                  </div>
                </div>
                </div>

              }
            </React.Fragment>

              }

          </div>
        </Modal.Content>
      </Modal>
      <AlertSnack open={this.state.openAlert} handleClose={this.handleCloseAlert} message={this.state.AlertMessage} type={this.state.AlertType}/>

      </React.Fragment>
    )
   }
}

function AlertSnack(props) {
  const { open,handleClose, message,type } = props;
  return (
    <div>
      {type === 'positive'?
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        key={'bottom','right'}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <SnackbarContent
          style={{
            backgroundColor:' #4fbf86',
            border: 'solid 2px #35ad75',
            fontSize: '13px',
          }}
          message={
            <div>
            <Icon color='white' size='big' name='check circle outline' />
            <span id="message-id">{message}</span>
            </div>
          }
        />
     </Snackbar>
      :type === 'warning'?
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          key={'bottom','right'}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
        >
          <SnackbarContent
            style={{
              backgroundColor:' #d2c41f',
              border: '2px solid rgb(165, 167, 45)',
              fontSize: '13px',
            }}
            message={
              <div>
              <Icon color='white' size='big' name='warning circle' />
              <span id="message-id">{message}</span>
              <Icon style={{position:'absolute', top:'4px', right:'4px'}} onClick={handleClose} color='white' size='small' name='close' />

              </div>
            }
          />
       </Snackbar>

      :
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        key={'bottom','right'}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <SnackbarContent
          style={{
            backgroundColor:' #e83232',
            border: 'solid 2px #8e1d1d',
            fontSize: '13px',
          }}
          message={
            <div>
            <Icon color='white' size='big' name='ban' />
            <span id="message-id">{message}</span>
            <Icon id='iconCloseAlert' onClick={handleClose} color='white' size='tiny' name='close' />

            </div>
          }
        />
     </Snackbar>

    }
    </div>
  )
}

function FormularioBooking(props) {
  const [Vendedor,setVendedor] = useState({});
  const [Cliente,setCliente] = useState({});
  const [totalUsuarios,setTotalUsuarios] = useState('');
  const [nuevosUsuarios,setNuevosUsuarios] = useState('');
  const [escribiendoVendedor,setEscribiendoVendedor] = useState(false);
  const [escribiendoCliente,setEscribiendoCliente] = useState(false);
  useEffect(()=>{
    let usuarios = [...props.Vendedores, ...props.Clientes];
    setTotalUsuarios(usuarios)
  },[])

  const filtroResidentes=(valueToFind,origen)=>{
    if (valueToFind) {
      origen===0?  setEscribiendoVendedor(true):  setEscribiendoCliente(true);
      var nuevoArray = totalUsuarios.filter(function (task) {
          return (task.CompanyName === valueToFind || task.CompanyName.toUpperCase().includes(valueToFind.toUpperCase()) ||task.correo === valueToFind || task.correo.toUpperCase().includes(valueToFind.toUpperCase()) );
      });
      setNuevosUsuarios(nuevoArray)
    }else{
      origen===0?  setEscribiendoVendedor(false):  setEscribiendoCliente(false);

      setNuevosUsuarios(totalUsuarios)
    }
  }
  const SeleccionVendedor=(it)=>{
    setVendedor(it);
    setEscribiendoVendedor(false);
    setEscribiendoCliente(false);
  }
  const SeleccionCliente=(it)=>{
    setCliente(it);
    setEscribiendoVendedor(false);
    setEscribiendoCliente(false);
  }
  return (
    <div>
      <div className='Acciones header' style={{marginTop:'0px'}}>
        <div className='input label' style={{position:'relative'}}>
          <label>Vendedor</label>
          <input value={Vendedor.CompanyName} onChange={(e)=>filtroResidentes(e.target.value,0)} type='text' placeholder='Vendedor'/>
          {escribiendoVendedor?
            <div className='dropdownUsuarios'>
              {nuevosUsuarios.map((it)=>{
                  return(
                    <div className='dropdownUsuarios-item' onClick={()=>SeleccionVendedor(it)}>
                      <div style={{color:'#3b3b3', textAlign:'left',fontSize:'16px',fontWeight:'600'}}>{it.CompanyName}</div>
                      <div style={{color:'#3b3b3', textAlign:'left',fontSize:'13px',fontWeight:'300'}}>{it.correo}</div>
                    </div>
                  )
                })
              }
            </div>
            :null
          }
        </div>
        <div className='input label' style={{position:'relative'}}>
          <label>Cliente</label>
          <input value={Cliente.CompanyName} onChange={(e)=>filtroResidentes(e.target.value,1)} type='text' placeholder='Cliente'/>
          {escribiendoCliente?
            <div className='dropdown'>
              {nuevosUsuarios.map((it)=>{
                  return(
                    <div className='dropdown-item' onClick={()=>SeleccionCliente(it)}>
                      <div style={{color:'#3b3b3', textAlign:'left',fontSize:'16px',fontWeight:'600'}}>{it.CompanyName}</div>
                      <div style={{color:'#3b3b3', textAlign:'left',fontSize:'13px',fontWeight:'300'}}>{it.correo}</div>
                    </div>
                  )
                })
              }
            </div>
            :null
          }
        </div>
      </div>
      <div className='Formulario'>

      </div>
    </div>
  )
}
export default Usuarios;

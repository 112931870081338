

 function CalcularCBMTotal(ArrayItems){
   let CBMTotal = 0.0;
   var PesoTotal = 0.0;
   var UnidadesTotal = 0;
   if (ArrayItems) {
     ArrayItems.map((it,index)=>{
      CBMTotal += (parseFloat(it.Height?it.Height:0).toFixed(2)*parseFloat(it.Width?it.Width:0).toFixed(2)*parseFloat(it.Lenght?it.Lenght:0).toFixed(2))*parseFloat(it.Unidades?it.Unidades:0).toFixed(2)/1000000;
      PesoTotal += parseFloat(it.Weight?it.Weight:0);
      UnidadesTotal += parseInt(it.Unidades?it.Unidades:0);
     })
     CBMTotal = CBMTotal?CBMTotal: 0;
    PesoTotal = PesoTotal?PesoTotal: 0;

     return{status:'OK',UnidadesTotal:UnidadesTotal,CBMTotal:parseFloat(CBMTotal).toFixed(2),PesoTotal:parseFloat(PesoTotal).toFixed(2)}
   }
  return {status:'505'};

 }

 function CalcularCBMArray(ArrayItems){
   let ArrayUnidadesAux = [];
   if (ArrayItems) {
     ArrayItems.map((it,index)=>{
       ArrayUnidadesAux.push({Height:it.Height,Unidades:it.Unidades,Width:it.Width,Lenght:it.Lenght,Weight:it.Weight,CBM:(parseFloat(it.Height).toFixed(2)*parseFloat(it.Width).toFixed(2)*parseFloat(it.Lenght).toFixed(2))*parseFloat(it.Unidades).toFixed(2)/1000000,})
     })
     return{status:'OK',ArrayCBM:ArrayUnidadesAux}
   }
   return {status:'505'};
 }




exports.CalcularCBMTotal=CalcularCBMTotal;
exports.CalcularCBMArray=CalcularCBMArray;

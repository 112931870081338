
const espanol={
  titulo:'Search Rates',
  tipoEnvioBotones:{
    aire:'Air',
    mar:'Ocean',
    carretera:'Truck',
    ferrocaril:'F.F.C.C'
  },
  inputs:{
    origen:'Origin..',
    destino:'Destination..',
    tipo:'Cargo type..'
  },
  botones:{
    buscar:'Search',
    aceptar:'Accept',
    cancelar:'Cancel',
    veinte:'20´ST',
    cuarenta:'40´ST',
    cuarentaHQ:'40´HQ',
    agendar:'Book now',
    singup:'Sign in/Sign up',
    radios:{
      calculo:'By total shipment',
      total:'By units'
    }
  },
  ventanaCarga:{
    header:'Cargo categories',
    lcl:{
      cero:'0',
      largo:'Lenght',
      ancho:'Width',
      alto:'Height',
      peso:'Weight',
      cantidad:'cantidad'
    },
    menu:{
      fcl:'Containers FCL',
      lcl:'Boxes LCL',
      masivo:'Bulk'
    },
    seguro:'Insurance surcharge',
    colocaMonto:"Invoice amount must be in USD for insurance"
  },
  CardRutaPublica:{
    expiracion:'Expiration date',
    trafico:'Shipment whit trafic ',
    mas:'More info',
    transporte:'Freight',
    tipo:'Mode',
    PaisOrigen: 'Country of origin',
    PaisDestino: 'Country of destination',
    Servicio:'Service'
  }
};

const tiposCargaEspanol=[
  {value:'Agriculture & food',text:'Agriculture & food', icon:'food'},
  {value:'Apparel, textiles & accesories',text:'Apparel, textiles & accesories',icon:'child'},
  {value:'ElectricalEquipment, Components and Telecoms',text:'ElectricalEquipment, Components and Telecoms',icon:'plug'},
  {value:'Electronics',text:'Electronics',icon:'microchip'},
  {value:'Gifts, Sports & Toys',text:'Gifts, Sports & Toys',icon:'futbol'},
  {value:'Healt & Beauty',text:'Healt & Beauty',icon:'heart'},
  {value:'Home Lights & Construction',text:'Home Lights & Construction',icon:'bed'},
  {value:'Machinery, Industrial Parts & Tools',text:'Machinery, Industrial Parts & Tools',icon:'cogs'},
  {value:'Metallurgy, Chemicals, Rubber & Plastics ',text:'Metallurgy, Chemicals, Rubber & Plastics ',icon:'flask'},
  {value:'Moving Abroad',text:'Moving Abroad',icon:'warehouse'},
  {value:'Packaging, Advertising & Office',text:'Packaging, Advertising & Office',icon:'bullhorn'},

];

export function Idioma(idioma){
  if(idioma==='ES'){
    return {textos:espanol,lista:tiposCargaEspanol};
  }
}
